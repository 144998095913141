import { memo } from 'react'

import Svg, { G, Path, Defs, ClipPath, Rect } from 'react-native-svg'

const SearchIllustration = () => (
  <Svg width={241} height={240} viewBox="0 0 241 240" fill="none">
    <G clipPath="url(#clip0_8680_163608)">
      <Path
        d="M53.99 78.19C53.99 78.73 53.8299 79.26 53.5299 79.71C53.2299 80.16 52.7999 80.51 52.2999 80.72C51.7999 80.93 51.25 80.98 50.72 80.88C50.19 80.77 49.6999 80.51 49.3199 80.13C48.9399 79.75 48.6799 79.26 48.5699 78.73C48.4599 78.2 48.5199 77.65 48.7299 77.15C48.9399 76.65 49.29 76.22 49.74 75.92C50.19 75.62 50.7199 75.46 51.2599 75.46C51.9799 75.46 52.6799 75.75 53.1899 76.26C53.6999 76.77 53.99 77.47 53.99 78.19Z"
        fill="#404040"
      />
      <Path
        d="M53.1899 76.26C52.6799 75.75 51.9799 75.46 51.2599 75.46C50.7199 75.46 50.19 75.62 49.74 75.92C49.29 76.22 48.9399 76.65 48.7299 77.15C48.5199 77.65 48.4599 78.2 48.5699 78.73C48.6799 79.26 48.9399 79.75 49.3199 80.13C49.6999 80.51 50.19 80.77 50.72 80.88C51.25 80.98 51.7999 80.93 52.2999 80.72C52.7999 80.51 53.2299 80.16 53.5299 79.71C53.8299 79.26 53.99 78.73 53.99 78.19C53.99 77.47 53.6999 76.77 53.1899 76.26Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M198.45 69.71C197.06 68.32 195.18 67.54 193.21 67.54H47.9601C46.9901 67.54 46.0201 67.73 45.1301 68.11C44.2301 68.48 43.4201 69.03 42.7301 69.72C42.0401 70.41 41.5001 71.23 41.1301 72.13C40.7601 73.03 40.5701 73.99 40.5701 74.97V179.4C40.5701 181.37 41.3601 183.25 42.7501 184.64C44.1401 186.03 46.0301 186.81 47.9901 186.81H193.23C194.2 186.81 195.16 186.62 196.06 186.25C196.96 185.88 197.78 185.33 198.46 184.64C199.15 183.95 199.69 183.14 200.06 182.24C200.43 181.34 200.62 180.38 200.62 179.41V152.45L200.63 74.95C200.62 72.99 199.84 71.1 198.45 69.71ZM65.2801 77.15C65.4901 76.65 65.8401 76.22 66.2901 75.92C66.7401 75.62 67.2701 75.46 67.8101 75.46C68.5301 75.46 69.2301 75.75 69.7401 76.26C70.2501 76.77 70.5401 77.47 70.5401 78.19C70.5401 78.73 70.3801 79.26 70.0801 79.71C69.7801 80.16 69.3501 80.51 68.8501 80.72C68.3501 80.93 67.8001 80.98 67.2701 80.88C66.7401 80.78 66.2501 80.51 65.8701 80.13C65.4901 79.75 65.2301 79.26 65.1201 78.73C65.0101 78.2 65.0701 77.65 65.2801 77.15ZM53.5301 79.71C53.2301 80.16 52.8001 80.51 52.3001 80.72C51.8001 80.93 51.2501 80.98 50.7201 80.88C50.1901 80.77 49.7001 80.51 49.3201 80.13C48.9401 79.75 48.6801 79.26 48.5701 78.73C48.4601 78.2 48.5201 77.65 48.7301 77.15C48.9401 76.65 49.2901 76.22 49.7401 75.92C50.1901 75.62 50.7201 75.46 51.2601 75.46C51.9801 75.46 52.6801 75.75 53.1901 76.26C53.7001 76.77 53.9901 77.47 53.9901 78.19C53.9901 78.73 53.8301 79.26 53.5301 79.71ZM56.9801 77.15C57.1901 76.65 57.5401 76.22 57.9901 75.92C58.4401 75.62 58.9701 75.46 59.5101 75.46C59.8701 75.46 60.2301 75.53 60.5601 75.66C60.8901 75.8 61.2001 76 61.4501 76.25C61.7001 76.5 61.9001 76.81 62.0401 77.14C62.1801 77.47 62.2401 77.83 62.2401 78.19C62.2401 78.73 62.0801 79.26 61.7801 79.71C61.4801 80.16 61.0501 80.51 60.5501 80.72C60.0501 80.93 59.5001 80.98 58.9701 80.88C58.4401 80.77 57.9501 80.51 57.5701 80.13C57.1901 79.75 56.9301 79.26 56.8201 78.73C56.7101 78.2 56.7701 77.65 56.9801 77.15ZM178.03 146.63H56.7701V128.9H178.03V146.63Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M200.61 88.74H40.5801"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M53.99 78.19C53.99 78.73 53.8299 79.26 53.5299 79.71C53.2299 80.16 52.7999 80.51 52.2999 80.72C51.7999 80.93 51.25 80.98 50.72 80.88C50.19 80.77 49.6999 80.51 49.3199 80.13C48.9399 79.75 48.6799 79.26 48.5699 78.73C48.4599 78.2 48.5199 77.65 48.7299 77.15C48.9399 76.65 49.29 76.22 49.74 75.92C50.19 75.62 50.7199 75.46 51.2599 75.46C51.9799 75.46 52.6799 75.75 53.1899 76.26C53.6999 76.77 53.99 77.47 53.99 78.19Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M62.24 78.19C62.24 78.73 62.0799 79.26 61.7799 79.71C61.4799 80.16 61.0499 80.51 60.5499 80.72C60.0499 80.93 59.5 80.98 58.97 80.88C58.44 80.77 57.9499 80.51 57.5699 80.13C57.1899 79.75 56.9299 79.26 56.8199 78.73C56.7099 78.2 56.7699 77.65 56.9799 77.15C57.1899 76.65 57.54 76.22 57.99 75.92C58.44 75.62 58.9699 75.46 59.5099 75.46C59.8699 75.46 60.23 75.53 60.56 75.66C60.89 75.8 61.2 76 61.45 76.25C61.7 76.5 61.9 76.81 62.04 77.14C62.18 77.47 62.24 77.83 62.24 78.19Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M70.54 78.19C70.54 78.73 70.38 79.26 70.08 79.71C69.78 80.16 69.35 80.51 68.85 80.72C68.35 80.93 67.8 80.98 67.27 80.88C66.74 80.78 66.25 80.51 65.87 80.13C65.49 79.75 65.23 79.26 65.12 78.73C65.01 78.2 65.07 77.65 65.28 77.15C65.49 76.65 65.84 76.22 66.29 75.92C66.74 75.62 67.27 75.46 67.81 75.46C68.53 75.46 69.23 75.75 69.74 76.26C70.25 76.77 70.54 77.47 70.54 78.19Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M178.03 128.9H56.77V146.63H178.03V128.9Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M234.42 177.84C234.26 176.92 233.75 176.1 232.99 175.56L196 149.3C196.38 148.54 196.72 147.76 197.03 146.96C198.32 143.6 198.91 140.01 198.75 136.41C198.59 132.81 197.69 129.29 196.1 126.05C194.51 122.82 192.27 119.95 189.52 117.62C186.77 115.29 183.56 113.56 180.11 112.54C176.66 111.51 173.03 111.21 169.45 111.65C165.87 112.09 162.43 113.27 159.33 115.11C156.23 116.95 153.55 119.41 151.45 122.33C149.35 125.26 147.87 128.58 147.12 132.11C144.25 145.48 151.95 160.95 169.6 163.51C173.74 164.05 177.94 163.56 181.85 162.1C185.76 160.64 189.25 158.24 192.01 155.12L228.91 181.3C229.67 181.84 230.62 182.06 231.54 181.9C232.46 181.74 233.28 181.23 233.82 180.47C234.36 179.71 234.58 178.76 234.42 177.84ZM195.11 139.8C194.73 143.47 193.45 146.99 191.39 150.05H191.4C189.11 153.45 185.94 156.16 182.23 157.89C178.52 159.62 174.41 160.31 170.33 159.89C166.26 159.46 162.38 157.94 159.1 155.47C155.83 153.01 153.29 149.7 151.75 145.9C150.22 142.1 149.75 137.96 150.39 133.91C151.03 129.87 152.76 126.07 155.4 122.94C158.03 119.8 161.47 117.44 165.35 116.11C169.22 114.78 173.39 114.53 177.39 115.39C181 116.16 184.36 117.81 187.18 120.18C190 122.55 192.2 125.58 193.59 129C194.97 132.42 195.49 136.13 195.11 139.8Z"
        fill="#404040"
      />
      <Path
        d="M234.42 177.84C234.26 176.92 233.75 176.1 232.99 175.56L196 149.3C196.38 148.54 196.72 147.76 197.03 146.96C198.32 143.6 198.91 140.01 198.75 136.41C198.59 132.81 197.69 129.29 196.1 126.05C194.51 122.82 192.27 119.95 189.52 117.62C186.77 115.29 183.56 113.56 180.11 112.54C176.66 111.51 173.03 111.21 169.45 111.65C165.87 112.09 162.43 113.27 159.33 115.11C156.23 116.95 153.55 119.41 151.45 122.33C149.35 125.26 147.87 128.58 147.12 132.11C144.25 145.48 151.95 160.95 169.6 163.51C173.74 164.05 177.94 163.56 181.85 162.1C185.76 160.64 189.25 158.24 192.01 155.12L228.91 181.3C229.67 181.84 230.62 182.06 231.54 181.9C232.46 181.74 233.28 181.23 233.82 180.47C234.36 179.71 234.58 178.76 234.42 177.84V177.84ZM195.11 139.8C194.73 143.47 193.45 146.99 191.39 150.05H191.4C189.11 153.45 185.94 156.16 182.23 157.89C178.52 159.62 174.41 160.31 170.33 159.89C166.26 159.46 162.38 157.94 159.1 155.47C155.83 153.01 153.29 149.7 151.75 145.9C150.22 142.1 149.75 137.96 150.39 133.91C151.03 129.87 152.76 126.07 155.4 122.94C158.03 119.8 161.47 117.44 165.35 116.11C169.22 114.78 173.39 114.53 177.39 115.39C181 116.16 184.36 117.81 187.18 120.18C190 122.55 192.2 125.58 193.59 129C194.97 132.42 195.49 136.13 195.11 139.8V139.8Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinejoin="round"
      />
      <Path
        d="M195.11 139.8C194.73 143.47 193.45 146.99 191.39 150.05H191.4C189.11 153.45 185.94 156.16 182.23 157.89C178.52 159.62 174.41 160.31 170.33 159.89C166.26 159.46 162.38 157.94 159.1 155.47C155.83 153.01 153.29 149.7 151.75 145.9C150.22 142.1 149.75 137.96 150.39 133.91C151.03 129.87 152.76 126.07 155.4 122.94C158.03 119.8 161.47 117.44 165.35 116.11C169.22 114.78 173.39 114.53 177.39 115.39C181 116.16 184.36 117.81 187.18 120.18C190 122.55 192.2 125.58 193.59 129C194.97 132.42 195.49 136.13 195.11 139.8Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.37 133.72C177.33 132.92 177.11 132.12 176.69 131.4L173 125.04C172.72 124.55 172.52 124.04 172.42 123.5L172.27 123.77C171.36 125.34 171.36 127.28 172.27 128.86L175.22 133.95C176.13 135.52 176.13 137.46 175.22 139.04L175.07 139.31C174.96 138.78 174.77 138.26 174.49 137.77L170.06 130.14C169.78 129.66 169.58 129.14 169.48 128.6L169.33 128.87C168.42 130.44 168.42 132.37 169.33 133.94L172.29 139.04C173.2 140.61 173.2 142.55 172.29 144.13L172.14 144.4C172.03 143.87 171.84 143.35 171.56 142.86L167.87 136.5C167.38 135.65 167.15 134.69 167.19 133.74C163.24 134.77 160.52 136.95 160.52 139.47C160.52 142.98 165.79 145.83 172.3 145.83C178.81 145.83 184.08 142.98 184.08 139.47C184.08 136.94 181.36 134.76 177.41 133.74L177.37 133.72Z"
        fill="#F3123C"
      />
      <Path
        d="M177.37 133.72C177.33 132.92 177.11 132.12 176.69 131.4L173 125.04C172.72 124.55 172.52 124.04 172.42 123.5L172.27 123.77C171.36 125.34 171.36 127.28 172.27 128.86L175.22 133.95C176.13 135.52 176.13 137.46 175.22 139.04L175.07 139.31C174.96 138.78 174.77 138.26 174.49 137.77L170.06 130.14C169.78 129.66 169.58 129.14 169.48 128.6L169.33 128.87C168.42 130.44 168.42 132.37 169.33 133.94L172.29 139.04C173.2 140.61 173.2 142.55 172.29 144.13L172.14 144.4C172.03 143.87 171.84 143.35 171.56 142.86L167.87 136.5C167.38 135.65 167.15 134.69 167.19 133.74C163.24 134.77 160.52 136.95 160.52 139.47C160.52 142.98 165.79 145.83 172.3 145.83C178.81 145.83 184.08 142.98 184.08 139.47C184.08 136.94 181.36 134.76 177.41 133.74L177.37 133.72V133.72Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M107.44 55.8C107.32 56.85 107.21 57.89 107.12 58.94C107.08 59.28 107.05 59.62 107.02 59.96C106.5 66.06 106.36 72.18 106.6 78.3C106.6 78.3 106.59 78.31 106.58 78.31C106.04 78.53 105.51 78.77 104.99 79.02C104.28 79.36 103.59 79.72 102.92 80.1C102.11 80.54 101.33 81.02 100.57 81.53C99.6499 82.14 98.7699 82.79 97.9299 83.47C97.9299 83.48 97.9199 83.48 97.9199 83.48C96.2199 84.84 94.6699 86.34 93.2099 87.94C93.2099 87.94 93.2199 87.95 93.2099 87.95C91.0099 90.34 89.0399 92.97 87.1599 95.75C87.1499 95.76 87.1399 95.78 87.1299 95.79C88.0199 89.05 89.5799 82.79 91.9199 77.07C94.6099 70.47 98.3299 64.62 103.26 59.61C104.57 58.28 105.97 57.01 107.45 55.8H107.44Z"
        fill="#F3123C"
      />
      <Path
        d="M154.33 95.83C149.27 88.37 143.7 81.97 134.93 78.32C135.04 75.32 135.06 72.31 134.97 69.31C134.93 67.49 134.85 65.67 134.73 63.86C134.65 62.64 134.56 61.43 134.45 60.22C134.43 59.97 134.41 59.72 134.38 59.47C134.33 58.94 134.28 58.42 134.22 57.9C134.17 57.38 134.11 56.87 134.04 56.36C134.02 56.16 134 55.95 133.97 55.75C140.66 61.21 145.59 67.96 149 75.77C151.08 80.55 152.59 85.72 153.61 91.24C153.89 92.74 154.13 94.27 154.33 95.83Z"
        fill="#F3123C"
      />
      <Path
        d="M129.69 36.29H111.7C113.83 29.64 116.85 23.32 120.68 17.48C121.01 17.98 121.33 18.49 121.65 19C121.97 19.51 122.28 20.02 122.59 20.53C122.64 20.63 122.7 20.72 122.75 20.81C123.01 21.24 123.25 21.68 123.5 22.11C123.79 22.64 124.08 23.17 124.36 23.7C126.51 27.73 128.29 31.94 129.69 36.29Z"
        fill="#F3123C"
      />
      <Path
        d="M129.7 100.9V104.66C129.71 104.98 129.64 105.3 129.52 105.6C129.4 105.9 129.22 106.17 128.99 106.4C128.76 106.63 128.49 106.81 128.19 106.93C128.04 106.99 127.89 107.03 127.74 107.07C127.73 107.07 127.73 107.07 127.73 107.07C127.57 107.09 127.41 107.11 127.25 107.11H114.19C114.03 107.11 113.87 107.09 113.71 107.07C113.63 107.06 113.56 107.04 113.48 107.01C113.4 106.99 113.32 106.96 113.25 106.93C113.21 106.91 113.16 106.89 113.12 106.87C112.87 106.76 112.64 106.6 112.45 106.4C112.23 106.17 112.04 105.9 111.92 105.6C111.8 105.3 111.74 104.98 111.74 104.66V100.9H129.7Z"
        fill="#404040"
      />
      <Path
        d="M127.97 59.6C127.97 61.02 127.55 62.42 126.76 63.6C125.97 64.78 124.84 65.71 123.53 66.25C122.21 66.79 120.77 66.94 119.37 66.66C117.97 66.38 116.69 65.7 115.68 64.69C114.67 63.68 113.99 62.4 113.71 61C113.43 59.6 113.57 58.16 114.12 56.84C114.67 55.52 115.59 54.4 116.77 53.61C117.95 52.82 119.35 52.4 120.77 52.4C122.68 52.4 124.51 53.16 125.86 54.51C127.21 55.86 127.97 57.69 127.97 59.6Z"
        fill="#404040"
      />
      <Path
        d="M129.68 36.29H129.67"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M111.7 36.29H111.69"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M134.97 69.31C134.92 67.5 134.84 65.68 134.73 63.86C134.65 62.64 134.56 61.43 134.45 60.22C134.43 59.97 134.41 59.72 134.38 59.47C134.33 58.94 134.28 58.42 134.22 57.9C134.17 57.38 134.11 56.87 134.04 56.36C133.21 49.53 131.81 42.77 129.67 36.29H111.7C113.83 29.64 116.85 23.32 120.68 17.48C120.67 17.48 120.67 17.47 120.67 17.47C112.21 30.24 108.68 43.82 107.12 58.94C107.08 59.28 107.05 59.62 107.02 59.96C106.5 66.06 106.36 72.18 106.6 78.3C106.6 78.3 106.59 78.31 106.58 78.31C106.57 78.32 106.58 78.32 106.58 78.32C106.83 85.22 107.54 92.11 108.49 98.89C108.56 99.45 108.84 99.96 109.26 100.33C109.68 100.7 110.23 100.91 110.79 100.91H111.73L129.71 100.9L130.76 100.91C131.32 100.91 131.87 100.71 132.29 100.34C132.71 99.97 133 99.47 133.08 98.91C134.33 90.31 134.97 81.63 135.02 72.94C135.02 71.73 135.01 70.52 134.98 69.31H134.97ZM126.76 63.6C125.97 64.78 124.84 65.71 123.53 66.25C122.21 66.79 120.77 66.94 119.37 66.66C117.97 66.38 116.69 65.7 115.68 64.69C114.67 63.68 113.99 62.4 113.71 61C113.43 59.6 113.57 58.16 114.12 56.84C114.67 55.52 115.59 54.4 116.77 53.61C117.95 52.82 119.35 52.4 120.77 52.4C122.68 52.4 124.51 53.16 125.86 54.51C127.21 55.86 127.97 57.69 127.97 59.6C127.97 61.02 127.55 62.42 126.76 63.6Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M107.02 59.96C106.5 66.06 106.36 72.18 106.6 78.3C106.6 78.3 106.59 78.31 106.58 78.31C106.34 72.46 106.43 66.59 106.96 60.77H106.95C106.97 60.5 107 60.23 107.03 59.96H107.02Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M129.7 100.9H111.72V100.91H129.7V100.9Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M120.77 52.4C122.68 52.4 124.51 53.16 125.86 54.51C127.21 55.86 127.97 57.69 127.97 59.6C127.97 61.02 127.55 62.42 126.76 63.6C125.97 64.78 124.84 65.71 123.53 66.25C122.21 66.79 120.77 66.94 119.37 66.66C117.97 66.38 116.69 65.7 115.68 64.69C114.67 63.68 113.99 62.4 113.71 61C113.43 59.6 113.57 58.16 114.12 56.84C114.67 55.52 115.59 54.4 116.77 53.61C117.95 52.82 119.35 52.4 120.77 52.4V52.4Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M129.67 36.29H111.7C113.83 29.64 116.85 23.32 120.68 17.48C121.01 17.98 121.33 18.49 121.65 19C121.97 19.51 122.28 20.02 122.59 20.53C122.64 20.63 122.7 20.72 122.75 20.81C123.01 21.24 123.25 21.68 123.5 22.11C123.79 22.64 124.08 23.17 124.36 23.7C126.49 27.73 128.24 31.95 129.67 36.29Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M127.97 59.6C127.97 61.02 127.55 62.42 126.76 63.6C125.97 64.78 124.84 65.71 123.53 66.25C122.21 66.79 120.77 66.94 119.37 66.66C117.97 66.38 116.69 65.7 115.68 64.69C114.67 63.68 113.99 62.4 113.71 61C113.43 59.6 113.57 58.16 114.12 56.84C114.67 55.52 115.59 54.4 116.77 53.61C117.95 52.82 119.35 52.4 120.77 52.4C122.68 52.4 124.51 53.16 125.86 54.51C127.21 55.86 127.97 57.69 127.97 59.6V59.6Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M129.71 100.93V104.67C129.71 104.99 129.65 105.31 129.53 105.61C129.41 105.91 129.23 106.18 129 106.41C128.77 106.64 128.5 106.82 128.2 106.94C128.05 107 127.9 107.05 127.74 107.07C127.73 107.07 127.73 107.07 127.73 107.07C127.57 107.11 127.42 107.12 127.26 107.12H114.17C114.02 107.12 113.86 107.1 113.71 107.07C113.63 107.06 113.56 107.04 113.48 107.01C113.39 106.99 113.31 106.96 113.23 106.93C113.19 106.91 113.15 106.9 113.12 106.87C112.87 106.76 112.64 106.6 112.45 106.4C112.45 106.4 112.443 106.397 112.43 106.39C112.2 106.16 112.02 105.89 111.9 105.59C111.78 105.29 111.72 104.96 111.72 104.64V100.91"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M107.45 55.78C95.5499 65.47 89.3199 79.2 87.1399 95.8C92.1999 88.34 97.8099 81.96 106.59 78.31"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M133.99 55.75C145.89 65.46 152.16 79.24 154.33 95.83C149.27 88.37 143.7 81.97 134.93 78.32"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M168.24 210.47V221.76"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M173.89 216.12H162.58"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M202.62 50.12C202.71 50.52 202.68 50.93 202.54 51.31C202.39 51.69 202.14 52.02 201.81 52.25C201.48 52.49 201.08 52.62 200.68 52.63C200.27 52.63 199.87 52.53 199.53 52.31C199.19 52.09 198.91 51.78 198.75 51.41C198.58 51.04 198.53 50.63 198.6 50.22C198.67 49.82 198.86 49.45 199.14 49.15C199.42 48.85 199.78 48.65 200.18 48.56C200.71 48.44 201.27 48.54 201.73 48.83C202.19 49.12 202.52 49.58 202.64 50.11H202.62V50.12Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M97.53 50.58L76.55 40.21L97.18 30.25"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M144.3 50.55L164.93 39.88L143.95 30.24"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.46997 96.75H10.44"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M17.46 96.72H21.43"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.95 89.25V93.22"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.96 100.26V104.23"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.65991 91.46L11.4599 94.26"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M16.4299 99.21L19.2599 102.01"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M19.2399 91.43L16.4399 94.23"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.4599 99.23L8.67993 102.03"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_8680_163608">
        <Rect width={228.94} height={205.23} fill="white" transform="translate(6 17)" />
      </ClipPath>
    </Defs>
  </Svg>
)

export const Search = memo(SearchIllustration)
