import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path, G, Defs, ClipPath } from 'react-native-svg'

export const AttentionIcon: FunctionComponent<IIconProps & { triangleShape?: boolean }> = ({
  triangleShape,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 18 18', triangleShape, ...props }}>
    {!triangleShape ? (
      <>
        <G fill="currentColor" clipPath="url(#a)">
          <Path
            fillRule="evenodd"
            d="M2.742 2.743a8.85 8.85 0 1 1 12.516 12.515A8.85 8.85 0 0 1 2.742 2.743ZM9 1.65a7.35 7.35 0 1 0 0 14.7 7.35 7.35 0 0 0 0-14.7Z"
            clipRule="evenodd"
          />
          <Path
            fillRule="evenodd"
            d="M9 3.75a.75.75 0 0 1 .75.75v3.6a.75.75 0 1 1-1.5 0V4.5A.75.75 0 0 1 9 3.75Z"
            clipRule="evenodd"
          />
          <Path d="M9 12.818a.675.675 0 1 1 0-1.35" />
          <Path
            fillRule="evenodd"
            d="M9 12.218a.075.075 0 1 0 0-.15v-1.35a1.425 1.425 0 1 0 0 2.85v-1.35Z"
            clipRule="evenodd"
          />
          <Path d="M9 12.818a.675.675 0 1 0 0-1.35" />
          <Path
            fillRule="evenodd"
            d="M9 10.718a1.425 1.425 0 1 1 0 2.85v-1.35a.075.075 0 1 1 0-.15v-1.35Z"
            clipRule="evenodd"
          />
        </G>
        <Defs>
          <ClipPath id="a">
            <Path fill="currentColor" d="M0 0h18v18H0z" />
          </ClipPath>
        </Defs>
      </>
    ) : (
      <>
        <G fill="currentColor" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <Path d="M9 4.201a.75.75 0 0 1 .75.75v3.24a.75.75 0 1 1-1.5 0v-3.24a.75.75 0 0 1 .75-.75Z" />
          <Path d="M7.1 1.749a2.694 2.694 0 0 1 3.807 0l.002.002 5.352 5.386a2.67 2.67 0 0 1 0 3.769l-5.309 5.326a2.757 2.757 0 0 1-3.898.001m0 0-5.305-5.307a2.702 2.702 0 0 1 0-3.812l5.35-5.364v-.001M8.16 2.81 2.813 8.172a1.203 1.203 0 0 0 0 1.696l5.303 5.304a1.257 1.257 0 0 0 1.368.273c.152-.063.29-.155.407-.272l5.308-5.326a1.17 1.17 0 0 0 0-1.652L9.848 2.81h-.002a1.194 1.194 0 0 0-1.686 0Z" />
          <Path d="M9.631 11.424A.75.75 0 0 0 9 10.27a1.155 1.155 0 0 0 0 2.31.75.75 0 0 0 .631-1.155Z" />
          <Path d="M8.25 11.02a.75.75 0 0 1 .75-.75 1.155 1.155 0 0 1 0 2.31.75.75 0 0 1-.631-1.156.746.746 0 0 1-.119-.405Z" />
        </G>
        <Defs>
          <ClipPath id="a">
            <Path fill="transparent" d="M0 0h18v18H0z" />
          </ClipPath>
        </Defs>
      </>
    )}
  </Icon>
)
