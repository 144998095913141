import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { NotificationDetailScreenComponent } from './NotificationDetailScreen.types'
import { useNotificationDetailScreen } from './useNotificationDetailScreen'

const NotificationDetailScreenDesktop = lazy(() => import('./NotificationDetailScreen.desktop'))

const NotificationDetailScreenMobile = lazy(() => import('./NotificationDetailScreen.mobile'))

export const NotificationDetailScreen: NotificationDetailScreenComponent = ({
  navigation,
  route
}) => {
  const props = useNotificationDetailScreen({ navigation, route })

  const Screen = props.isMobile ? NotificationDetailScreenMobile : NotificationDetailScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
