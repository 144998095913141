import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import { useAuthAtom } from 'src/store/auth'
import { LogEventUserId } from 'src/utils/logEvents'

import { UseResetPasswordReSentEmailScreen } from './ResetPasswordReSentEmailScreen.types'

export const useResetPasswordReSentEmailScreen: UseResetPasswordReSentEmailScreen = ({
  navigation,
  route
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { email } = route.params

  const handleGoToEditEmail = () => navigation.navigate('ResetPasswordSendEmail')

  const [authAtom, setAuthAtom] = useAuthAtom()

  const queryClient = useQueryClient()

  const handleGoToLoginScreen = () => {
    if (authAtom) {
      Promise.all([
        LogEventUserId(null),
        setAuthAtom(null),
        navigation.reset({
          index: 0,
          routes: [
            {
              name: 'Login'
            },
            {
              name: 'ResetPasswordSendEmail'
            },
            {
              name: 'ResetPasswordReSentEmail',
              params: {
                email
              }
            },
            {
              name: 'LoginBlocked'
            }
          ]
        })
      ]).then(() => {
        queryClient.removeQueries()
      })
    }

    navigation.navigate('Login')
  }

  return {
    isMobile,
    email,
    handleGoToEditEmail,
    handleGoToLoginScreen
  }
}
