import { FunctionComponent, Suspense, lazy } from 'react'

import { Skeleton } from 'native-base'

import { IrsInfosProps } from './IrsInfos.types'
import { useIrsInfos } from './useIrsInfos'

const IrsInfosDesktop = lazy(() => import('./IrsInfos.desktop'))

const IrsInfosMobile = lazy(() => import('./IrsInfos.mobile'))

export const IrsInfos: FunctionComponent<IrsInfosProps> = (props) => {
  const irsInfos = useIrsInfos(props)

  const Component = irsInfos.isMobile ? IrsInfosMobile : IrsInfosDesktop

  return (
    <Suspense fallback={<Skeleton minHeight="104px" mb={4} borderRadius="20px" />}>
      <Component {...irsInfos} />
    </Suspense>
  )
}
