import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const MoneyTurnoverIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 19', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8629 10.6868C17.0336 10.7891 17.1566 10.9551 17.2048 11.1482L17.8276 13.6412C17.928 14.0431 17.6836 14.4502 17.2817 14.5506C16.8799 14.651 16.4727 14.4066 16.3723 14.0047L15.9314 12.2399L14.1664 12.6821C13.7646 12.7828 13.3573 12.5386 13.2566 12.1368C13.156 11.735 13.4001 11.3277 13.8019 11.2271L16.2949 10.6025C16.4879 10.5541 16.6923 10.5844 16.8629 10.6868Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4176 10.6708C16.8174 10.7791 17.0538 11.191 16.9455 11.5908C16.4726 13.3372 15.4391 14.8798 14.004 15.9816C12.5688 17.0833 10.8115 17.6831 9.0023 17.6888L8.9972 17.6888C7.31124 17.6826 5.66759 17.1604 4.28722 16.1923C2.90685 15.2243 1.85594 13.8569 1.27576 12.2739C1.13322 11.8849 1.33295 11.4541 1.72187 11.3116C2.11079 11.169 2.54161 11.3688 2.68415 11.7577C3.15892 13.0531 4.01889 14.1721 5.14847 14.9642C6.27735 15.7559 7.62141 16.1832 9.00016 16.1888C10.4798 16.1836 11.9168 15.6928 13.0905 14.7917C14.2649 13.8902 15.1106 12.6278 15.4976 11.1987C15.6059 10.7989 16.0178 10.5626 16.4176 10.6708Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.718179 4.37253C1.12004 4.27214 1.5272 4.51653 1.6276 4.91839L2.06849 6.68326L3.83348 6.24106C4.23528 6.14039 4.64261 6.3845 4.74327 6.7863C4.84394 7.18809 4.59982 7.59542 4.19803 7.69608L1.70503 8.32068C1.512 8.36905 1.30765 8.33871 1.137 8.23636C0.966336 8.134 0.843352 7.96802 0.79512 7.77495L0.17232 5.28195C0.0719265 4.88009 0.316316 4.47293 0.718179 4.37253Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00271 1.23438C10.6887 1.24057 12.3323 1.76279 13.7127 2.73082C15.0931 3.69884 16.144 5.06628 16.7242 6.64928C16.8667 7.0382 16.667 7.46903 16.278 7.61157C15.8891 7.7541 15.4583 7.55438 15.3158 7.16546C14.841 5.87007 13.981 4.75107 12.8514 3.95892C11.7226 3.16726 10.3785 2.73996 8.99975 2.73438C7.52012 2.73956 6.08307 3.23035 4.90937 4.1314C3.73499 5.03296 2.88928 6.29534 2.50228 7.72441C2.39401 8.12422 1.98213 8.36057 1.58232 8.2523C1.1825 8.14403 0.946162 7.73214 1.05443 7.33233C1.52735 5.58598 2.56083 4.04332 3.99595 2.94158C5.43107 1.83984 7.18837 1.24003 8.99761 1.23438L9.00271 1.23438Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99996 11.6366C9.41417 11.6366 9.74996 11.9724 9.74996 12.3866V13.6916C9.74996 14.1058 9.41417 14.4416 8.99996 14.4416C8.58574 14.4416 8.24996 14.1058 8.24996 13.6916V12.3866C8.24996 11.9724 8.58574 11.6366 8.99996 11.6366Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99996 4.48157C9.41417 4.48157 9.74996 4.81736 9.74996 5.23157V6.53657C9.74996 6.95078 9.41417 7.28657 8.99996 7.28657C8.58574 7.28657 8.24996 6.95078 8.24996 6.53657V5.23157C8.24996 4.81736 8.58574 4.48157 8.99996 4.48157Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.44996 7.84157C6.44996 6.76443 7.27089 5.78657 8.40596 5.78657H9.45596C9.76067 5.78901 10.061 5.85926 10.3352 5.99219C10.6094 6.12512 10.8506 6.31741 11.0412 6.55513C11.3004 6.87825 11.2485 7.35029 10.9254 7.60944C10.6023 7.8686 10.1302 7.81674 9.87108 7.49361C9.81972 7.42957 9.75474 7.37776 9.68086 7.34194C9.60769 7.30647 9.52761 7.28757 9.44633 7.28657H8.40596C8.20903 7.28657 7.94996 7.47871 7.94996 7.84157C7.94996 8.14602 8.14309 8.34568 8.3205 8.38817C8.3206 8.3882 8.3204 8.38815 8.3205 8.38817L9.90955 8.76566C10.9089 8.99852 11.55 9.92975 11.55 10.9232C11.55 12.0876 10.6651 13.1366 9.44996 13.1366H8.54996C7.7763 13.1366 7.12289 12.6997 6.76418 12.0902C6.5541 11.7332 6.67318 11.2735 7.03017 11.0634C7.38715 10.8533 7.84685 10.9724 8.05693 11.3294C8.17703 11.5334 8.36602 11.6366 8.54996 11.6366H9.44996C9.72526 11.6366 10.05 11.3751 10.05 10.9232C10.05 10.5421 9.80996 10.2824 9.56854 10.2264L9.56461 10.2255L7.97341 9.84746C7.04931 9.62701 6.44996 8.76455 6.44996 7.84157Z"
      fill="currentColor"
    />
  </Icon>
)
