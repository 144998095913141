import { FunctionComponent, useState } from 'react'

import { Calendar, Icons } from 'atoms'
import { format, parseISO } from 'date-fns'
import { Box, Button, Pressable, Text, useDisclose } from 'native-base'
import { Keyboard } from 'react-native'
import { formatDateStringToMask } from 'src/utils'

type TDate = {
  value?: string
  scheduleDateStart?: string
  currentDate?: string
  setCurrentDate(day?: string): void
  startingDay?: string
  setStartingDay(day?: string): void
  endingDay?: string
  setEndingDay(day?: string): void
  handleSelectDate(selectedDate: string): void
  handleAddedDate(dateOne: string, dateTwo?: string): void
}

export const InputDate: FunctionComponent<TDate> = ({
  value,
  scheduleDateStart,
  handleAddedDate,
  handleSelectDate,
  setEndingDay,
  setStartingDay,
  currentDate,
  setCurrentDate,
  endingDay,
  startingDay
}) => {
  const { isOpen, onToggle } = useDisclose()

  const [currentTab, setCurrentTab] = useState(2)

  const handleChangeTab = (tab: number) => {
    handleResetPeriod()

    handleResetDate()

    setCurrentTab(tab)
  }

  const handleResetPeriod = () => {
    setStartingDay(undefined)

    setEndingDay(undefined)
  }

  const handleResetDate = () => {
    setCurrentDate(undefined)
  }

  return (
    <>
      <Pressable
        onPress={() => {
          Keyboard.dismiss()

          onToggle()
        }}
        flexDir="row"
        alignItems="center"
        h="50px"
        bg="gray.30"
        borderRadius="40px"
        p={4}
        borderColor="gray.30"
        borderWidth="1px">
        <Icons.Calendar />
        <Text ml={2} fontSize="16px" lineHeight="24px" color="gray.700">
          {value
            ? scheduleDateStart
              ? `${format(parseISO(scheduleDateStart.replace('Z', '')), 'dd/MM/yyyy')} até ${format(
                  parseISO(value.replace('Z', '')),
                  'dd/MM/yyyy'
                )}`
              : formatDateStringToMask(value, "dd 'de' MMMM yyyy")
            : 'Hoje'}
        </Text>
      </Pressable>

      {isOpen && (
        <Box maxW="100%">
          <Calendar.Root>
            <Box mt={6}>
              <Calendar.Tab index={currentTab - 1} setCurrentIndex={handleChangeTab} />
            </Box>
            {currentTab === 2 && (
              <Calendar.Header startingDay={startingDay} endingDay={endingDay} />
            )}
            <Calendar.Title title={`Selecione um${currentTab === 1 ? 'a data' : ' período'}`} />
            <Calendar.Body
              daySelected={currentTab === 1 ? currentDate : undefined}
              onDaySelected={currentTab === 1 ? handleSelectDate : undefined}
              startingDay={startingDay}
              endingDay={endingDay}
              onStartingDaySelect={setStartingDay}
              onEndingDaySelect={setEndingDay}
              markingType={currentTab === 1 ? 'dot' : 'period'}
              enableSelectYear
            />
            <Calendar.Footer>
              <Button
                flex={1}
                colorScheme="gray"
                isDisabled={currentTab === 1 ? !currentDate : !startingDay || !endingDay}
                onPress={() => {
                  if (currentTab === 1) {
                    handleResetPeriod()

                    currentDate && handleAddedDate(currentDate)
                  } else {
                    handleResetDate()

                    startingDay && endingDay && handleAddedDate(startingDay, endingDay)
                  }

                  onToggle()
                }}>
                Confirmar
              </Button>
            </Calendar.Footer>
          </Calendar.Root>
        </Box>
      )}
    </>
  )
}
