import { useCallback, useMemo } from 'react'

import {
  ActivitiesResponse,
  useGetActivityListInfiniteQuery
} from 'integration/resources/activities'

import { UseActivityContainerList } from './types'

const defaultReturn = {
  items: [],
  total_activities: 0,
  total_opportunities: 0
}

const mergeAllDataInfiniteQuery = (
  data: ReturnType<typeof useGetActivityListInfiniteQuery>['data']
) =>
  data?.pages.reduce<ActivitiesResponse>(
    (previousValue, currentValue) => ({
      ...previousValue,
      ...currentValue.data.data,
      items: [...previousValue.items, ...currentValue.data.data.items]
    }),
    defaultReturn
  )

export const useActivityContainerList: UseActivityContainerList = ({ store_tab_numbers }) => {
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetActivityListInfiniteQuery({
      store_tab_numbers,
      group_by: 2
    })

  const onScrollEndDragActivityList = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const activities = useMemo(() => mergeAllDataInfiniteQuery(data), [data])

  return {
    activities,
    isLoading,
    onScrollEndDragActivityList
  }
}
