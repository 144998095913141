import { useCallback } from 'react'

import { useBreakpointValue } from 'native-base'

import { UsePreferencesProfileScreen } from './PreferencesProfileScreen.types'

export const usePreferencesProfileScreen: UsePreferencesProfileScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  /**
   * @TODO change navigation navigate to useLinkTo
   * define custom names cause this name used in url in version web
   */

  const goBack = useCallback(() => navigation.goBack(), [navigation])

  const goToTerms = useCallback(() => navigation.navigate('Terms'), [navigation])

  const goToDeviceManagement = useCallback(
    () => navigation.navigate('DeviceManagement'),
    [navigation]
  )

  return { isMobile, goBack, goToTerms, goToDeviceManagement }
}
