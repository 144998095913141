import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ChargeIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 19', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.05 2.11133C3.77152 2.11133 3.50445 2.22195 3.30754 2.41887C3.11062 2.61578 3 2.88285 3 3.16133V16.1874L5.44437 14.6157C5.70575 14.4476 6.04371 14.4581 6.29412 14.6422L9 16.6306L11.7059 14.6422C11.9563 14.4581 12.2942 14.4476 12.5556 14.6157L15 16.1874V3.16133C15 2.88285 14.8894 2.61578 14.6925 2.41887C14.4955 2.22195 14.2285 2.11133 13.95 2.11133H4.05ZM2.24688 1.35821C2.7251 0.879988 3.3737 0.611328 4.05 0.611328H13.95C14.6263 0.611328 15.2749 0.879988 15.7531 1.35821C16.2313 1.83642 16.5 2.48503 16.5 3.16133V17.5613C16.5 17.8357 16.3502 18.0882 16.1094 18.2196C15.8685 18.3511 15.5751 18.3406 15.3444 18.1922L12.1784 16.1564L9.44412 18.1657C9.17988 18.3599 8.82012 18.3599 8.55588 18.1657L5.82165 16.1564L2.65563 18.1922C2.42485 18.3406 2.13147 18.3511 1.89064 18.2196C1.64982 18.0882 1.5 17.8357 1.5 17.5613V3.16133C1.5 2.48503 1.76866 1.83642 2.24688 1.35821Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 10.8065C9.41421 10.8065 9.75 11.1423 9.75 11.5565V12.8597C9.75 13.2739 9.41421 13.6097 9 13.6097C8.58579 13.6097 8.25 13.2739 8.25 12.8597V11.5565C8.25 11.1423 8.58579 10.8065 9 10.8065Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3.65333C9.41421 3.65333 9.75 3.98911 9.75 4.40333V5.70653C9.75 6.12074 9.41421 6.45653 9 6.45653C8.58579 6.45653 8.25 6.12074 8.25 5.70653V4.40333C8.25 3.98911 8.58579 3.65333 9 3.65333Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.45 7.01153C6.45 5.93439 7.27093 4.95653 8.406 4.95653H9.456C9.76071 4.95897 10.0611 5.02922 10.3353 5.16215C10.6095 5.29508 10.8506 5.48737 11.0413 5.72508C11.3004 6.04821 11.2486 6.52025 10.9254 6.7794C10.6023 7.03856 10.1303 6.9867 9.87113 6.66357C9.81976 6.59952 9.75478 6.54771 9.6809 6.5119C9.60774 6.47643 9.52766 6.45753 9.44637 6.45653H8.406C8.20907 6.45653 7.95 6.64867 7.95 7.01153C7.95 7.31602 8.14318 7.51568 8.3206 7.55814V7.55814L9.90957 7.93561C10.9089 8.16847 11.55 9.0997 11.55 10.0931C11.55 11.2576 10.6651 12.3065 9.45 12.3065H8.55C7.77594 12.3065 7.12213 11.8692 6.76349 11.2571C6.5541 10.8997 6.67407 10.4402 7.03146 10.2308C7.38885 10.0214 7.84831 10.1414 8.05771 10.4988C8.17787 10.7039 8.36646 10.8065 8.55 10.8065H9.45C9.72531 10.8065 10.05 10.5451 10.05 10.0931C10.05 9.71207 9.81 9.45233 9.56858 9.39634L9.56465 9.39543L7.97346 9.01742C7.04935 8.79696 6.45 7.93451 6.45 7.01153Z"
      fill="currentColor"
    />
  </Icon>
)
