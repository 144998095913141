export const Input = {
  defaultProps: {
    h: '50px',
    bg: 'gray.30',
    borderRadius: '40px',
    pr: 4,
    py: 3,
    borderWidth: 0,
    placeholderTextColor: 'gray.400',
    focusOutlineColor: 'none',
    _invalid: {
      borderColor: 'error.500',
      focusOutlineColor: 'error.500'
    },
    _focus: {
      bg: 'gray.30',
      shadow: 'none'
    },
    _stack: {
      pl: '17px'
    },
    pl: '9px',
    fontSize: '16px',
    lineHeight: '24px'
  },
  sizes: {},
  variants: {}
}
