import { Box, useTheme } from 'native-base'
import MaskInput from 'react-native-mask-input'
import PhoneInput, { PhoneInputProps } from 'react-native-phone-number-input'

export const INPUT_MASK_PHONE_CUSTOM = [
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  `-`,
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const InputPhone = ({
  onChangeText,
  value,
  isInvalid,
  ...props
}: JSX.IntrinsicAttributes &
  Readonly<PhoneInputProps & { isInvalid?: boolean } & { fontSize?: number }>) => {
  const theme = useTheme()

  return (
    <Box position="relative" w="full">
      <PhoneInput
        layout="first"
        defaultCode="BR"
        countryPickerProps={{ withAlphaFilter: true }}
        codeTextStyle={{ display: 'none' }}
        textInputStyle={{
          display: 'none'
        }}
        containerStyle={{
          backgroundColor: theme.colors.gray[30],
          borderRadius: 40,
          height: 50,
          maxWidth: '100%',
          borderColor: isInvalid
            ? 'rgb(178, 90, 90) 0px 0px 0px 1px'
            : theme.colors.background.base,
          borderWidth: isInvalid ? 1 : 0,
          width: '100%'
        }}
        textContainerStyle={{
          display: 'none',
          backgroundColor: theme.colors.gray[30],
          borderRadius: 40,
          height: 50
        }}
        countryPickerButtonStyle={{
          backgroundColor: 'none',
          maxWidth: '20%'
        }}
        {...props}
      />
      <Box h="24px" w="1px" bg="gray.100" position="absolute" top={4} bottom={0} left={20} />
      <MaskInput
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 90,
          fontSize: 16,
          fontFamily: 'OpenSans_400Regular',
          backgroundColor: 'transparent',
          color: theme.colors.gray[800],
          borderTopRightRadius: 40,
          borderBottomRightRadius: 40,
          paddingLeft: 10,
          //@ts-ignore
          outline: 'none'
        }}
        placeholderTextColor={theme.colors.gray[400]}
        selectionColor="#C2C2C2"
        keyboardType="number-pad"
        placeholder="Telefone"
        value={value}
        onChangeText={onChangeText}
        placeholderFillCharacter=""
        mask={INPUT_MASK_PHONE_CUSTOM}
      />
    </Box>
  )
}
