import { useState } from 'react'

import { useBreakpointValue } from 'native-base'

import { UseTabSearchScreen } from './TabSearchScreen.types'

export const useTabSearchScreen: UseTabSearchScreen = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [title, setTitle] = useState('')

  return {
    isMobile,
    title,
    setTitle
  }
}
