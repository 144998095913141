import axios from 'axios'
import { Platform } from 'react-native'

if (!process.env.API_BASE_URL) {
  throw new Error('API_BASE_URL variable missing .env')
}

if (!process.env.X_API_KEY) {
  throw new Error('X_API_KEY variable missing .env')
}

const url =
  Platform.OS === 'web' && window.location.host.includes('www')
    ? process.env.API_BASE_URL_WWW
    : process.env.API_BASE_URL

const client = axios.create({
  baseURL: url,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': Platform.OS === 'web' ? process.env.X_API_KEY_WEB : process.env.X_API_KEY
  }
})

export default client
