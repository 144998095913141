import { FunctionComponent, memo } from 'react'

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Icons, Illustrations } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { Center, Heading, HStack, ScrollView, Text, VStack } from 'native-base'
import { RootStackParamList } from 'navigation/types'
import { TouchableOpacity } from 'react-native'
import { formatDateStringToMask } from 'src/utils'

import { useActivityContainerList } from './useActivityContainerList'

type ActivityContainerListProps = {
  tabNumber: string
}

export const ActivityContainerList: FunctionComponent<ActivityContainerListProps> = memo(
  ({ tabNumber }) => {
    const { isLoading, activities, onScrollEndDragActivityList } = useActivityContainerList({
      store_tab_numbers: [tabNumber]
    })

    const activitySkeleton = isLoading && !activities?.items.length

    const noResults = !activities?.items.length && !isLoading

    const contentPreview = new Array<number>(5).fill(0)

    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        onScrollEndDrag={onScrollEndDragActivityList}>
        {activitySkeleton &&
          contentPreview.map((_, i) => <SkeletonCard key={`key-skeleton-${i}`} />)}

        {activities?.items.map(({ id, store, title, schedule_date }) => (
          <VStack space={4} mb={2} key={id}>
            <VStack bg="white" borderRadius="20px" p={4}>
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate('OrganizationActivity', {
                    id: String(id)
                  })
                }>
                <HStack space={2} alignItems="center">
                  <Center
                    bg="white"
                    borderWidth={1}
                    borderColor="gray.50"
                    borderRadius="full"
                    mr={2}
                    h={12}
                    w={12}>
                    <Icons.Check color="gray.700" size={20} />
                  </Center>
                  <VStack flex={1}>
                    <Heading
                      fontSize="14px"
                      lineHeight="18px"
                      fontWeight="bold"
                      color="gray.700"
                      noOfLines={1}
                      mb={1}>
                      {title}
                    </Heading>
                    {store && (
                      <Text
                        fontSize="12px"
                        lineHeight="16px"
                        fontWeight="normal"
                        color="gray.400"
                        mb={1}>
                        {store.name_fantasy}
                      </Text>
                    )}

                    <Text fontSize="12px" lineHeight="16px" fontWeight="normal" color="gray.400">
                      {formatDateStringToMask(schedule_date, 'dd/MM/yyyy')}
                    </Text>
                  </VStack>
                </HStack>
              </TouchableOpacity>
            </VStack>
          </VStack>
        ))}

        {noResults && (
          <Center>
            <Illustrations.ActivitiesEmpty />
            <Text fontSize="16px" lineHeight="24px" color="gray.500" textAlign="center" mt={6}>
              Ainda não há tarefas relacionadas para este TAB.
            </Text>
          </Center>
        )}
      </ScrollView>
    )
  }
)
