import { FunctionComponent } from 'react'

import { Path, Svg } from 'react-native-svg'
import { resize } from 'src/utils'

type PPTIconProps = {
  size?: number
}

export const PPTIcon: FunctionComponent<PPTIconProps> = ({ size = 35 }) => {
  const { height, width } = resize({ height: 35, size, width: 27 })

  return (
    <Svg {...{ viewBox: '0 0 27 35', width, height }}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8382 0.333374H3.33333C2.44928 0.333374 1.60143 0.684563 0.97631 1.30968C0.351189 1.93481 0 2.78265 0 3.66671V30.3334C0 31.2174 0.351189 32.0653 0.97631 32.6904C1.60143 33.3155 2.44928 33.6667 3.33333 33.6667H23.3333C24.2174 33.6667 25.0652 33.3155 25.6904 32.6904C26.3155 32.0653 26.6667 31.2174 26.6667 30.3334V11.1618C26.6667 10.6314 26.4559 10.1227 26.0809 9.74759L17.2525 0.91916C16.8774 0.544087 16.3687 0.333374 15.8382 0.333374ZM15 12.0001V3.66675L23.3333 12.0001H15Z"
        fill="#CB4A32"
      />
      <Path
        d="M3.87257 26.7301C3.87257 26.5449 3.93623 26.3901 4.06354 26.2657C4.19375 26.1412 4.36591 26.079 4.58003 26.079C4.79415 26.079 4.96487 26.1412 5.09219 26.2657C5.2224 26.3901 5.2875 26.5449 5.2875 26.7301C5.2875 26.9153 5.2224 27.0701 5.09219 27.1945C4.96487 27.3189 4.79415 27.3811 4.58003 27.3811C4.36591 27.3811 4.19375 27.3189 4.06354 27.1945C3.93623 27.0701 3.87257 26.9153 3.87257 26.7301ZM9.19566 25.0808H7.58542V24.0651H9.19566C9.4445 24.0651 9.64705 24.0246 9.8033 23.9436C9.95955 23.8597 10.0738 23.744 10.1462 23.5964C10.2185 23.4488 10.2547 23.2824 10.2547 23.0973C10.2547 22.9092 10.2185 22.7341 10.1462 22.5721C10.0738 22.4101 9.95955 22.2798 9.8033 22.1815C9.64705 22.0831 9.4445 22.0339 9.19566 22.0339H8.03681V27.3334H6.73472V21.0139H9.19566C9.69045 21.0139 10.1144 21.1036 10.4674 21.283C10.8233 21.4595 11.0953 21.704 11.2833 22.0165C11.4714 22.329 11.5655 22.6864 11.5655 23.0886C11.5655 23.4966 11.4714 23.8496 11.2833 24.1476C11.0953 24.4456 10.8233 24.6757 10.4674 24.8377C10.1144 24.9998 9.69045 25.0808 9.19566 25.0808ZM15.1915 25.0808H13.5813V24.0651H15.1915C15.4403 24.0651 15.6429 24.0246 15.7991 23.9436C15.9554 23.8597 16.0697 23.744 16.142 23.5964C16.2144 23.4488 16.2505 23.2824 16.2505 23.0973C16.2505 22.9092 16.2144 22.7341 16.142 22.5721C16.0697 22.4101 15.9554 22.2798 15.7991 22.1815C15.6429 22.0831 15.4403 22.0339 15.1915 22.0339H14.0326V27.3334H12.7306V21.0139H15.1915C15.6863 21.0139 16.1102 21.1036 16.4632 21.283C16.8191 21.4595 17.0911 21.704 17.2792 22.0165C17.4672 22.329 17.5613 22.6864 17.5613 23.0886C17.5613 23.4966 17.4672 23.8496 17.2792 24.1476C17.0911 24.4456 16.8191 24.6757 16.4632 24.8377C16.1102 24.9998 15.6863 25.0808 15.1915 25.0808ZM21.5519 21.0139V27.3334H20.2542V21.0139H21.5519ZM23.4964 21.0139V22.0339H18.3401V21.0139H23.4964Z"
        fill="white"
      />
    </Svg>
  )
}
