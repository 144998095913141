import { createIcon } from 'native-base'
import { Path } from 'react-native-svg'

export const WindowsIcon = createIcon({
  viewBox: '0 0 25 25',
  path: [
    <Path
      key={Math.random()}
      d="M21.2699 15.0949C20.0947 15.5334 18.8539 15.7701 17.5999 15.7949C16.1216 15.8427 14.6726 15.3762 13.4999 14.4749C13.4367 14.4216 13.361 14.3851 13.2799 14.3689C13.1988 14.3527 13.1148 14.3573 13.036 14.3822C12.9571 14.4072 12.8859 14.4517 12.8288 14.5116C12.7718 14.5715 12.7309 14.6449 12.7099 14.7249L10.7099 21.7249C10.6748 21.8287 10.6748 21.9412 10.7099 22.0449C10.8899 22.4949 13.7099 23.8249 15.3299 23.8249C16.8345 23.7787 18.3155 23.439 19.6899 22.8249C19.7576 22.7944 19.8184 22.7503 19.8683 22.6952C19.9182 22.6401 19.9562 22.5754 19.9799 22.5049L21.9799 15.6649C22.0054 15.5753 22.0058 15.4803 21.9812 15.3904C21.9565 15.3005 21.9076 15.219 21.8399 15.1549C21.7625 15.0877 21.6663 15.0459 21.5643 15.0352C21.4624 15.0244 21.3596 15.0453 21.2699 15.0949Z"
      fill="currentColor"
    />,
    <Path
      key={Math.random()}
      d="M11.2397 13.545C10.0173 12.6762 8.56817 12.1827 7.06965 12.125C5.61367 12.1975 4.18334 12.5364 2.84965 13.125C2.77925 13.1513 2.71618 13.194 2.66572 13.2497C2.61526 13.3054 2.57889 13.3724 2.55965 13.445L0.499653 20.475C0.473917 20.5652 0.474078 20.6608 0.500118 20.751C0.526157 20.8411 0.577026 20.9221 0.646909 20.9846C0.716792 21.0472 0.802872 21.0889 0.89531 21.1049C0.987749 21.1208 1.08282 21.1105 1.16965 21.075C2.3189 20.5686 3.55447 20.2868 4.80965 20.245C6.1789 20.2758 7.50282 20.7416 8.58965 21.575C8.67157 21.633 8.76931 21.6644 8.86965 21.665C8.92585 21.6753 8.98346 21.6753 9.03965 21.665C9.11325 21.6376 9.17951 21.5935 9.23328 21.5363C9.28705 21.479 9.32688 21.4102 9.34965 21.335C9.76965 19.875 11.1897 15.035 11.4997 14.115C11.5259 14.0046 11.5146 13.8886 11.4675 13.7854C11.4204 13.6821 11.3402 13.5975 11.2397 13.545Z"
      fill="currentColor"
    />,
    <Path
      key={Math.random()}
      d="M3.68837 11.0358C4.86953 10.5022 6.14315 10.2033 7.43837 10.1558C8.75889 10.2094 10.0304 10.6705 11.0784 11.4758C11.1632 11.5263 11.2597 11.5538 11.3584 11.5558C11.4146 11.5661 11.4722 11.5661 11.5284 11.5558C11.6012 11.5271 11.6668 11.4826 11.7204 11.4256C11.774 11.3685 11.8143 11.3003 11.8384 11.2258L13.9084 4.1358C13.9139 4.0893 13.9139 4.04231 13.9084 3.9958C13.9089 3.90951 13.887 3.82456 13.845 3.74921C13.8029 3.67386 13.7421 3.61068 13.6684 3.5658C12.3812 2.65767 10.8436 2.17194 9.26837 2.1758C7.88912 2.2288 6.53217 2.5409 5.26837 3.0958C5.19809 3.1251 5.13544 3.17006 5.08518 3.22725C5.03492 3.28444 4.99839 3.35235 4.97837 3.4258L2.97837 10.4258C2.95406 10.5164 2.9552 10.6119 2.98166 10.7019C3.00812 10.7919 3.05889 10.8728 3.12837 10.9358C3.19795 11.0114 3.29038 11.0621 3.39152 11.0801C3.49265 11.0982 3.59692 11.0826 3.68837 11.0358Z"
      fill="currentColor"
    />,
    <Path
      key={Math.random()}
      d="M24.3293 5.05457C24.2571 4.99336 24.1693 4.95345 24.0757 4.93932C23.9821 4.92519 23.8864 4.9374 23.7993 4.97457C22.632 5.53058 21.3617 5.83711 20.0693 5.87457C18.6076 5.89235 17.1819 5.42065 16.0193 4.53457C15.9571 4.48085 15.8821 4.44403 15.8015 4.42762C15.7209 4.41122 15.6375 4.41578 15.5592 4.44087C15.4809 4.46597 15.4104 4.51075 15.3544 4.57093C15.2983 4.63111 15.2587 4.70468 15.2393 4.78457L13.1793 11.8746C13.1626 11.9805 13.1742 12.089 13.2128 12.1891C13.2514 12.2892 13.3157 12.3773 13.3993 12.4446C14.7742 13.3775 16.3978 13.8757 18.0593 13.8746C19.4862 13.848 20.8897 13.5065 22.1693 12.8746C22.2363 12.842 22.2945 12.794 22.3393 12.7346C22.3911 12.6813 22.4289 12.616 22.4493 12.5446L24.4493 5.54457C24.4735 5.4595 24.4752 5.3696 24.4541 5.28369C24.4331 5.19778 24.3901 5.11882 24.3293 5.05457Z"
      fill="currentColor"
    />
  ]
})
