import { FunctionComponent, memo } from 'react'

import { useLinkTo } from '@react-navigation/native'
import Icons from 'atoms/Icons'
import {
  HighlightedFeaturesItem,
  useRemoveHighlightedFeatureMutation
} from 'integration/resources/menu'
import { Button, Center, HStack, Heading, Pressable, VStack, Box, Text, Circle } from 'native-base'

import { Breadcrumb } from '../Breadcrumb'

type HeadPageProps = {
  title: string
  badge?: string
  highlightItem?: HighlightedFeaturesItem
  pages: {
    id: number
    title: string
    link: any
    params: any
  }[]
  actionButtonTitle?: string
  leftIcon?: JSX.Element
  actionButtonPress?: () => void
  actionBackButton?: () => void
}

export const HeadPage: FunctionComponent<HeadPageProps> = memo(
  ({
    pages,
    title,
    actionButtonPress,
    actionButtonTitle,
    leftIcon,
    actionBackButton,
    badge,
    highlightItem
  }) => {
    const linkTo = useLinkTo()

    const removeHighlight = useRemoveHighlightedFeatureMutation('dashboardsHasHighlight')

    const handleGoToHighlight = (link: string) => {
      removeHighlight.mutate({ id: highlightItem?.id ?? '' })

      return linkTo(link)
    }

    return (
      <HStack flex={1} justifyContent="space-between">
        <HStack>
          {actionBackButton && (
            <Pressable onPress={actionBackButton}>
              <Center bgColor="white" h={8} w={8} rounded="full" mr={4}>
                <Icons.AccordionArrow direction="left" size={6} />
              </Center>
            </Pressable>
          )}
          <VStack space={2}>
            <Box flexDir="row" alignItems="center">
              <Heading fontSize={24} fontWeight="bold" color="gray.700">
                {title}
              </Heading>
              {highlightItem?.id && (
                <Pressable
                  position="relative"
                  ml={1}
                  pt={1}
                  onPress={() => handleGoToHighlight(highlightItem?.target ?? '/conteudos')}>
                  <Icons.Attention size={3} color="gray.700" />
                  {!highlightItem.read && (
                    <Circle
                      bgColor="primary.500"
                      position="absolute"
                      size={2}
                      top="2px"
                      right="-4px"
                    />
                  )}
                </Pressable>
              )}
              {badge && (
                <Center
                  h={6}
                  py={1}
                  px={4}
                  ml="8px"
                  backgroundColor="primary.300"
                  borderRadius="full">
                  <Text
                    fontSize="12px"
                    color="white"
                    textTransform="uppercase"
                    lineHeight="16px"
                    letterSpacing={0.8}
                    fontWeight={700}>
                    {badge}
                  </Text>
                </Center>
              )}
            </Box>

            <Breadcrumb pages={pages} />
          </VStack>
        </HStack>

        {actionButtonTitle ? (
          <Center>
            <Button
              colorScheme="gray"
              width="162px"
              height="44px"
              onPress={actionButtonPress}
              px={6}
              leftIcon={leftIcon}>
              <Text fontSize="12px" fontWeight={700} lineHeight="16px" color="white">
                {actionButtonTitle}
              </Text>
            </Button>
          </Center>
        ) : null}
      </HStack>
    )
  }
)
