import { useCallback, useState } from 'react'

import { Icons } from 'atoms'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import {
  DeviceSerialized,
  useDeleteDeviceMutation,
  useGetDevicesQuery
} from 'integration/resources/device'
import { useBreakpointValue } from 'native-base'

import { UseDeviceManagementScreen } from './DeviceManagementScreen.types'

export enum DeviceManagementBottomsheet {
  NONE = 0,
  DISCONNECT = 1,
  CONFIRM = 2,
  SUCESSFULLY_DISCONNECTED = 3
}

export const useDeviceManagementScreen: UseDeviceManagementScreen = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { data: devicesList, isFetching } = useGetDevicesQuery()

  const deleteDeviceMutation = useDeleteDeviceMutation()

  const deleteIsLoading = deleteDeviceMutation.isLoading

  const bottomSheetContext = useBottomSheetContext()

  const devices = devicesList?.data.data

  const [deviceToDisconnectInfo, setDeviceToDisconnectInfo] = useState<
    DeviceSerialized | undefined
  >()

  const [deviceToDisconnectId, setDeviceToDisconnectId] = useState<string | undefined>()

  const [showDeviceManagementBottomsheet, setShowDeviceManagementBottomsheet] =
    useState<DeviceManagementBottomsheet>(DeviceManagementBottomsheet.NONE)

  const handleOpenDisconnect = useCallback((item: DeviceSerialized) => {
    setDeviceToDisconnectInfo(item)

    setShowDeviceManagementBottomsheet(DeviceManagementBottomsheet.DISCONNECT)
  }, [])

  const handleConfirmDisconnect = (deviceId: string) => {
    setShowDeviceManagementBottomsheet(DeviceManagementBottomsheet.NONE)

    deleteDeviceMutation.mutate(deviceToDisconnectId ?? deviceId, {
      onError: () => {
        bottomSheetContext.open({
          title: `Erro ao desconectar dispositivo`,
          description: 'Não foi possível desconectar o dispositivo, tente novamente mais tarde.'
        })
      },
      onSuccess: () =>
        setShowDeviceManagementBottomsheet(DeviceManagementBottomsheet.SUCESSFULLY_DISCONNECTED)
    })
  }

  const handleDisconnectDevice = (deviceId: string, desktop = false) => {
    setDeviceToDisconnectId(deviceId)

    if (!desktop) {
      setShowDeviceManagementBottomsheet(DeviceManagementBottomsheet.CONFIRM)
    } else {
      handleConfirmDisconnect(deviceId)
    }
  }

  const switchIcons = (deviceType: string, color: string, size: string) => {
    // EXAMPLE: "Platform: ios - Device: Apple iPhone - iPhone 14 Pro Max"

    const deviceTypeFormatted = deviceType.split('Device: ')[0].split(' ')[1]
    // RESULT: "ios" || "Chrome"

    switch (deviceTypeFormatted) {
      case 'ios':
        return <Icons.AppleLogo size={size} color={color} />

      case 'android':
        return <Icons.AndroidLogo size={size} color={color} />

      default:
        return <Icons.Windows size={size} color={color} />
    }
  }

  const switchTitle = (deviceType: string) => {
    if (!deviceType) {
      return 'Desconhecido'
    }

    // EXAMPLE: "Platform: ios - Device: Apple iPhone - iPhone 14 Pro Max"
    const platform = deviceType.split(' - ')[0].split('Platform: ')[1] || 'Desconhecido'

    const deviceInfo = deviceType.split('Device: ')[1]?.split(' - ')[1]

    // If deviceInfo contains the string "null", return only the platform
    if (deviceInfo?.includes('null')) {
      return platform
    }

    const formattedDeviceTitle = deviceInfo || platform

    return formattedDeviceTitle.charAt(0).toUpperCase() + formattedDeviceTitle.slice(1)
  }

  return {
    isMobile,
    deviceToDisconnectInfo,
    setDeviceToDisconnectInfo,
    handleOpenDisconnect,
    handleDisconnectDevice,
    handleConfirmDisconnect,
    devices,
    deleteIsLoading,
    isFetching,
    switchIcons,
    switchTitle,
    showDeviceManagementBottomsheet,
    setShowDeviceManagementBottomsheet
  }
}
