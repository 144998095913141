import { useCallback, useEffect, useRef, useState } from 'react'

import AsyncStorage from '@react-native-async-storage/async-storage'
import { useSendEmailResetPasswordMutation } from 'integration/resources/resetPassword'
import { BottomSheetModal } from 'molecules'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { REMEMBER_EMAIL_STORAGE_KEY } from 'src/screens/Login/LoginScreen/useLoginScreen'

import { UseSecurityProfileScreen } from './SecurityProfileScreen.types'

export const useSecurityProfileScreen: UseSecurityProfileScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [email, setEmail] = useState('')

  const toast = useToast()

  /**
   * @TODO change navigation navigate to useLinkTo
   * define custom names cause this name used in url in version web
   */

  const goToLockscreen = useCallback(() => navigation.navigate('Lockscreen'), [navigation])

  const goToPasswordUpdate = useCallback(
    () => navigation.navigate('ResetPasswordSendEmail'),
    [navigation]
  )

  const { mutate, isLoading } = useSendEmailResetPasswordMutation()

  const confirmPassChangeSheetRef = useRef<BottomSheetModal>(null)

  const openConfirmPassChangeSheet = useCallback(() => {
    confirmPassChangeSheetRef.current?.present()
  }, [])

  const closeConfirmPassChangeSheet = useCallback(() => {
    confirmPassChangeSheetRef.current?.close()

    confirmPassChangeSheetRef.current?.dismiss()
  }, [])

  useEffect(() => {
    const getEmailFromStorage = async () => {
      const savedEmail = await AsyncStorage.getItem(REMEMBER_EMAIL_STORAGE_KEY)

      savedEmail && setEmail(savedEmail)
    }

    getEmailFromStorage()
  }, [])

  const resetPassword = () => {
    email &&
      mutate(
        { email },
        {
          onError: () => {
            closeConfirmPassChangeSheet()

            toast.show({
              render: () => <Toast type="error" text="Não foi possível completar a ação" />
            })
          },
          onSuccess: () => {
            closeConfirmPassChangeSheet()

            toast.show({
              render: () => <Toast type="success" text="E-mail enviado com sucesso" />
            })
          }
        }
      )
  }

  return {
    email,
    isMobile,
    isLoading,
    resetPassword,
    goToLockscreen,
    goToPasswordUpdate,
    confirmPassChangeSheetRef,
    openConfirmPassChangeSheet
  }
}
