import { createRef, useCallback } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { useBreakpointValue } from 'native-base'
import { Dimensions, TextInput } from 'react-native'
import { RootStackScreenComponentProps } from 'src/navigation'

import { UseLoginAccountReviewScreen } from './LoginAccountReviewScreen.types'

export const refInputPassword = createRef<TextInput>()

export const handleInputPasswordFocus = () => refInputPassword.current?.focus()

export const useLoginAccountReviewScreen: UseLoginAccountReviewScreen = ({
  navigation
}: Pick<RootStackScreenComponentProps<'LoginAccountReview'>, 'navigation'>) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const goToEntryScreen = useCallback(
    () =>
      navigation.reset({
        index: 0,
        routes: [{ name: 'Entry' }]
      }),
    [navigation]
  )

  const screenHeight = Dimensions.get('screen').height

  const linkTo = useLinkTo()

  return {
    isMobile,
    goToEntryScreen,
    screenHeight,
    linkTo
  }
}
