import { FunctionComponent } from 'react'

import {
  TManageWalletParams,
  TStaffListItem,
  TStoresListItem,
  TWalletsManagementDetails
} from 'integration/resources/walletsManagement'
import { RootStackScreenComponentProps } from 'src/navigation'

export type StaffListScreenComponent = FunctionComponent<StaffListScreenProps>

export type StaffListScreenProps = RootStackScreenComponentProps<'StaffList'>

export type ResponsiveStaffListScreenComponent = FunctionComponent<ReturnType<UseStaffListScreen>>

export type CardProps = TStaffListItem

export enum Tab {
  Specialists,
  AssignedTabs,
  UnassignedTabs
}

export type TabButtonProps = {
  tab: Tab
  label: string
  currentTab: Tab
  onPress: (tab: Tab) => void
}

export type OrderOption = {
  id: 'asc' | 'desc'
  name: string
}

export type OrderField = {
  label: string
  options: OrderOption[]
}

export type OrderByButtonProps = {
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear(): void
  handleApply(): void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen(): void
  orderByOnClose(): void
}

export type StaffListProps = {
  search?: string
  staffData?: TStaffListItem[]
  staffListIsLoading: boolean
  staffListIsFetchingNextPage: boolean
}

export type StoresListProps = {
  search?: string
  storesData?: TStoresListItem[]
  storesListIsLoading: boolean
  storesListIsFetchingNextPage: boolean
  handleFlagAsExternal: (tab_number: number, status: boolean) => void
  flagAsExternalIsLoading: boolean
}

export type UseStaffListScreen = ({
  navigation,
  route
}: Pick<StaffListScreenProps, 'navigation' | 'route'>) => {
  isMobile: boolean
  onSearchManageStaff?(search: string): void
  searchManageStaff?: string
  onSearchManageStore?(search: string): void
  searchManageStore?: string
  onSearchStaff?(search: string): void
  searchStaff?: string
  onSearchStore?(search: string): void
  searchStore?: string
  onPressSearch?(): void
  onPressSearchClear?(): void
  staffData?: TStaffListItem[]
  staffListIsLoading: boolean
  storesData?: TStoresListItem[]
  storesListIsLoading: boolean
  detailsData?: TWalletsManagementDetails
  detailsIsLoading: boolean
  handleTabChange: (tab: Tab) => void
  currentTab: number
  concludeModalIsOpen: boolean
  concludeModalOnClose(): void
  concludeModalOnOpen(): void
  handleConclude(): void
  concludeIsLoading: boolean
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear(): void
  handleApply(): void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen(): void
  orderByOnClose(): void
  manageWalletModalIsOpen: boolean
  manageWalletModalOnClose(): void
  manageWalletModalOnOpen(): void
  unassignedStores: number
  handleGoToConclude(): void
  walletManagementIsOpen: boolean
  isAdmin: boolean
  handleGoBack(): void
  handleManageWallet: (item: TManageWalletParams) => void
  confirmManageWalletModalIsOpen: boolean
  confirmManageWalletModalOnClose(): void
  confirmManageWalletModalOnOpen(): void
  manageWalletIsLoading: boolean
  reactivateModalIsOpen: boolean
  reactivateModalOnClose(): void
  reactivateModalOnOpen(): void
  handleGoToOpenWalletsPeriod(): void
  onEndReachedStaff(): void
  onEndReachedStores(): void
  staffTotalItems: number
  storesTotalItems: number
  staffListIsFetchingNextPage: boolean
  storesListIsFetchingNextPage: boolean
  manageFilters: {
    id: number
    name: string
  }[]
  selectedManageFilter: {
    id: number
    name: string
  }
  handleSelectManageFilter: (filter: { id: number; name: string }) => void
  handleFlagAsExternal: (tab_number: number, status: boolean) => void
  flagAsExternalIsLoading: boolean
  handleSelectStaff: (item: Partial<TStaffListItem>) => void
  handleToggleTab: (tabId: number) => void
  selectedTabs: Set<number>
  selectedStaff?: Partial<TStaffListItem>
  notInManagement: boolean
  handleReactivate: () => void
  reactivateIsLoading: boolean
  anyHookIsLoading: boolean
  handleDownloadReport: () => Promise<void>
  walletsManagementReportIsLoading: boolean
}
