import { FunctionComponent, PropsWithChildren } from 'react'

import { HStack } from 'native-base'
import { InterfaceHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack'

export const CalendarFooter: FunctionComponent<PropsWithChildren<InterfaceHStackProps>> = ({
  children,
  ...props
}) => {
  return (
    <HStack mt={8} px={4} {...props}>
      {children}
    </HStack>
  )
}
