import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { G, Path } from 'react-native-svg'

export const LocationIcon: FunctionComponent<
  IIconProps & { opacity?: number; filled?: boolean }
> = ({ opacity, filled = false, ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    {filled ? (
      <Path
        key={Math.random()}
        d="M12.2609 0C10.07 0 7.9688 0.870346 6.41957 2.41957C4.87035 3.9688 4 6.07 4 8.26093C4 14.5251 11.0643 22.8403 11.3664 23.1896C11.4772 23.3184 11.6145 23.4217 11.7688 23.4925C11.9232 23.5633 12.0911 23.6 12.2609 23.6C12.4308 23.6 12.5986 23.5633 12.753 23.4925C12.9074 23.4217 13.0447 23.3184 13.1555 23.1896C13.4576 22.8403 20.5219 14.5251 20.5219 8.26093C20.5219 6.07 19.6515 3.9688 18.1023 2.41957C16.5531 0.870346 14.4519 0 12.2609 0ZM12.2609 11.2113C11.6774 11.2113 11.107 11.0382 10.6218 10.714C10.1366 10.3899 9.75848 9.92908 9.53518 9.38997C9.31188 8.85087 9.25345 8.25766 9.36729 7.68535C9.48113 7.11304 9.76212 6.58734 10.1747 6.17473C10.5873 5.76212 11.113 5.48113 11.6853 5.36729C12.2577 5.25345 12.8509 5.31188 13.39 5.53518C13.9291 5.75848 14.3899 6.13663 14.714 6.62181C15.0382 7.10699 15.2113 7.67741 15.2113 8.26093C15.2113 9.04341 14.9004 9.79384 14.3471 10.3471C13.7938 10.9004 13.0434 11.2113 12.2609 11.2113Z"
        fill="currentColor"
      />
    ) : (
      <G fill="currentColor">
        <Path d="M12 2.4a6 6 0 0 0-6 6c0 1.279.389 2.763 1.034 4.322.64 1.548 1.503 3.098 2.38 4.487A47.42 47.42 0 0 0 12 20.888l.172-.224a47.415 47.415 0 0 0 2.413-3.455c.878-1.39 1.741-2.94 2.381-4.487C17.612 11.163 18 9.68 18 8.4a6 6 0 0 0-6-6Zm0 20.4-.911.78-.007-.008-.018-.02a18.887 18.887 0 0 1-.303-.366 49.827 49.827 0 0 1-3.375-4.695c-.923-1.46-1.86-3.136-2.57-4.85C4.112 11.936 3.6 10.12 3.6 8.4a8.4 8.4 0 1 1 16.8 0c0 1.721-.511 3.537-1.216 5.24-.71 1.715-1.647 3.39-2.57 4.85a49.828 49.828 0 0 1-3.613 4.985l-.065.076-.017.021-.005.006-.002.002L12 22.8Zm0 0 .911.781a1.2 1.2 0 0 1-1.822 0L12 22.8Z" />
        <Path d="M12 6v4.8A2.4 2.4 0 1 1 12 6ZM12 6a2.4 2.4 0 1 1 0 4.8V6Z" />
      </G>
    )}
  </Icon>
)
