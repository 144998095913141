import { FunctionComponent } from 'react'

import Svg, { G, Path, Defs, ClipPath, Rect } from 'react-native-svg'
import { resize } from 'src/utils'

type IconProps = {
  size?: number
}

export const RemoveAccountIcon: FunctionComponent<IconProps> = ({ size = 24, ...props }) => {
  const { height, width } = resize({ height: 24, width: 24, size })

  return (
    <Svg {...{ viewBox: '0 0 18 18', width, height }}>
      <G clipPath="url(#clip0_6505_43057)">
        <Path
          d="M6.91369 6.91375C8.44048 6.91375 9.67819 5.67605 9.67819 4.14926C9.67819 2.62247 8.44048 1.38477 6.91369 1.38477C5.38691 1.38477 4.1492 2.62247 4.1492 4.14926C4.1492 5.67605 5.38691 6.91375 6.91369 6.91375Z"
          stroke="#404040"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M6.9136 16.1286H1.38461V14.2856C1.38431 13.3173 1.6383 12.3659 2.12117 11.5266C2.60404 10.6873 3.29886 9.98958 4.1361 9.50316C4.97334 9.01674 5.92364 8.75872 6.89191 8.75493C7.86019 8.75113 8.81249 9.00168 9.65351 9.48152"
          stroke="#404040"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M17.3572 12.1479L12.1477 17.3575"
          stroke="#404040"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12.1477 12.1479L17.3572 17.3575"
          stroke="#404040"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_6505_43057">
          <Rect width={18} height={18} fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
