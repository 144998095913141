/* eslint-disable no-fallthrough */
import { format, formatRelative, parseISO, set, subDays } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export const getRecentKnowledgeData = () => {
  const previousData = subDays(new Date(), 7)

  return format(previousData, 'yyyy-MM-dd', {
    locale: ptBR
  })
}

export const getMonthName = (monthNumber: number) => {
  const date = set(new Date(), { month: monthNumber - 1 })

  const month = format(date, 'MMMM', {
    locale: ptBR
  })

  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1)

  return capitalizedMonth
}

export const getShortMonthName = (yearMonth: string) => {
  const monthNumber = yearMonth.slice(1)

  const monthName = getMonthName(Number(monthNumber)).substring(0, 3)

  return monthName
}

export const getShortMonthAndYear = (yearMonth: string, isMobile = true) => {
  // example: 202212
  const yearNumber = yearMonth.slice(0, 4)

  const monthNumber = yearMonth.slice(4)

  const monthName = isMobile
    ? getMonthName(Number(monthNumber)).substring(0, 3)
    : getMonthName(Number(monthNumber))

  // example: Dez-2022
  return isMobile ? `${monthName}-${yearNumber}` : `${monthName}`
}

export const getNumberYearAndMonth = (yearMonth: string) => {
  // example: Dez-2022

  const [monthShortName, yearNumber] = yearMonth.split('-')

  let monthNumber = ''

  let monthFullName = ''

  switch (monthShortName) {
    case 'Janeiro':

    case 'Jan':
      monthNumber = '01'

      monthFullName = 'Janeiro'

      break

    case 'Fevereiro':

    case 'Fev':
      monthNumber = '02'

      monthFullName = 'Fevereiro'

      break

    case 'Março':

    case 'Mar':
      monthNumber = '03'

      monthFullName = 'Março'

      break

    case 'Abril':

    case 'Abr':
      monthNumber = '04'

      monthFullName = 'Abril'

      break

    case 'Maio':

    case 'Mai':
      monthNumber = '05'

      monthFullName = 'Maio'

      break

    case 'Junnho':

    case 'Jun':
      monthNumber = '06'

      monthFullName = 'Junho'

      break

    case 'Jul':

    case 'Julho':
      monthNumber = '07'

      monthFullName = 'Julho'

      break

    case 'Ago':

    case 'Agosto':
      monthNumber = '08'

      monthFullName = 'Agosto'

      break

    case 'Set':

    case 'Setembro':
      monthNumber = '09'

      monthFullName = 'Setembro'

      break

    case 'Out':

    case 'Outubro':
      monthNumber = '10'

      monthFullName = 'Outubro'

      break

    case 'Nov':

    case 'Novembro':
      monthNumber = '11'

      monthFullName = 'Novembro'

      break

    case 'Dez':

    case 'Dezembro':
      monthNumber = '12'

      monthFullName = 'Dezembro'

      break
  }

  return {
    monthFullName,
    monthNumber: `${yearNumber}${monthNumber}`
  }
}

export const formatDateStringToMask = (
  date: string | null,
  mask: string = 'yyyy-MM-dd HH:mm:ss'
) => {
  let parsed = new Date()

  if (date) {
    parsed = parseISO(date?.replace('Z', ''))
  }

  return format(parsed, mask, {
    locale: ptBR
  })
}

const formatRelativeLocale = {
  yesterday: "'Ontem'",
  today: "'Hoje'",
  tomorrow: "'Amanhã'",
  nextWeek: 'dd MMM',
  other: 'dd MMM'
}

const locale = {
  ...ptBR,
  formatRelative: (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token]
}

export const getRelativeDay = (day: string) =>
  formatRelative(parseISO(day?.replace('Z', '')), new Date(), {
    locale,
    weekStartsOn: 0
  })

export const months = [
  {
    id: '01',
    name: 'Janeiro'
  },
  {
    id: '02',
    name: 'Fevereiro'
  },
  {
    id: '03',
    name: 'Março'
  },
  {
    id: '04',
    name: 'Abril'
  },
  {
    id: '05',
    name: 'Maio'
  },
  {
    id: '06',
    name: 'Junho'
  },
  {
    id: '07',
    name: 'Julho'
  },
  {
    id: '08',
    name: 'Agosto'
  },
  {
    id: '09',
    name: 'Setembro'
  },
  {
    id: '10',
    name: 'Outubro'
  },
  {
    id: '11',
    name: 'Novembro'
  },
  {
    id: '12',
    name: 'Dezembro'
  }
]
