import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { RecommendationsScreenComponent } from './RecommendationsScreen.types'
import { useRecommendationsScreen } from './useRecommendationsScreen'

const RecommendationsScreenDesktop = lazy(() => import('./RecommendationsScreen.desktop'))

const RecommendationsScreenMobile = lazy(() => import('./RecommendationsScreen.mobile'))

export const RecommendationsScreen: RecommendationsScreenComponent = ({ navigation, route }) => {
  const props = useRecommendationsScreen({ navigation, route })

  const Screen = props.isMobile ? RecommendationsScreenMobile : RecommendationsScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
