import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { format } from 'date-fns'
import { TOpportunityFilterParams } from 'integration/resources/newOpportunities'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { BottomSheetModal } from 'molecules'
import { useBreakpointValue } from 'native-base'
import { calculateActiveFilterCount } from 'src/utils/filters'
import { LogEvent, LogEventScreen } from 'src/utils/logEvents'

import {
  ActivitiesGroup,
  OpportunitiesGroup,
  UseOrganizationScreen,
  ViewType
} from './OrganizationScreen.types'

const organization = atom({
  total_activities: -1,
  total_opportunities: -1
})

const resetFilters: TOpportunityFilterParams = {
  offer: undefined,
  public_rating: undefined,
  segment: undefined,
  network: undefined,
  subsidiary: undefined,
  opportunity_type: undefined,
  order_by: undefined,
  order_by_direction: undefined
}

export const useOrganization = () => useAtom(organization)

export const useOrganizationValue = () => useAtomValue(organization)

export const useSetOrganizationAtom = () => useSetAtom(organization)

export const activityViewTypes: (Omit<ViewType, 'group'> & ActivitiesGroup)[] = [
  {
    group: 1,
    title: 'Visão da semana',
    icon: 'CalendarWeek'
  },
  {
    group: 3,
    title: 'Visão por data',
    icon: 'CalendarPlus'
  },
  {
    group: 11,
    title: 'Visão por período',
    icon: 'CalendarPeriod'
  },
  {
    group: 4,
    title: 'Tarefas Concluídas',
    icon: 'CheckConcluded'
  },

  {
    group: 12,
    title: 'Tarefas Pendentes',
    icon: 'CalendarAttention'
  },
  {
    group: 2,
    title: 'Todas as tarefas',
    icon: 'ViewAll'
  }
]

export const opportunityViewTypes: (Omit<ViewType, 'group'> & OpportunitiesGroup)[] = [
  {
    group: 5,
    title: 'Oportunidades',
    icon: 'Chart'
  },
  {
    group: 6,
    title: 'Base Ativa',
    icon: 'MoneyTurnover'
  },
  {
    group: 7,
    title: 'Seguro Auto',
    icon: 'Car'
  },
  {
    group: 8,
    title: 'Cobrança',
    icon: 'Charge'
  },
  {
    group: 9,
    title: 'Destacadas',
    icon: 'Star'
  },
  {
    group: 10,
    title: 'Concluídas',
    icon: 'Concluded'
  },
  {
    group: 13,
    title: 'Pendentes',
    icon: 'Attention'
  }
]

export const useOrganizationScreen: UseOrganizationScreen = ({ navigation, route }) => {
  const viewTypeSheetRef = useRef<BottomSheetModal>(null)

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [isLoading, setIsLoading] = useState(false)

  const [currentTab, setCurrentTab] = useState<1 | 2>(
    route?.params?.tabId ?? route.path ? (!route.path?.includes('tarefas') ? 2 : 1) : 1
  )

  const [currentSelectedDate, setCurrentSelectedDate] = useState<string>()

  const [currentSelectedPeriod, setCurrentSelectedPeriod] = useState<{
    startingDay: string | undefined
    endingDay: string | undefined
  }>()

  const opportunityViewTypeGroup = opportunityViewTypes.filter(
    (item) => item.group === Number(route?.params?.listType)
  )?.[0]

  const [viewType, setViewType] = useState<ViewType>(
    Number(currentTab) === 1
      ? activityViewTypes[0]
      : route?.params?.listType
      ? opportunityViewTypeGroup
      : opportunityViewTypes[0]
  )

  const openViewTypeSheet = useCallback(async () => {
    LogEvent(
      `organizacao_abriu_filtros_${Number(currentTab) === 1 ? 'atividades' : 'oportunidades'}`,
      {
        item_id: ''
      }
    )

    viewTypeSheetRef.current?.present()
  }, [currentTab])

  const closeViewTypeSheet = useCallback(async () => {
    LogEvent(
      `organizacao_fechou_filtros_${Number(currentTab) === 1 ? 'atividades' : 'oportunidades'}`,
      {
        item_id: ''
      }
    )

    viewTypeSheetRef.current?.close()
  }, [currentTab, viewTypeSheetRef])

  useEffect(() => {
    if (!route?.params?.tabId) return

    setCurrentTab(route?.params?.tabId ?? 1)

    setViewType(
      (Number(route?.params?.tabId) ?? 1) === 1
        ? activityViewTypes[0]
        : route?.params?.listType
        ? opportunityViewTypeGroup
        : opportunityViewTypes[0]
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityViewTypeGroup, route?.params])

  const viewTypes = Number(currentTab) === 1 ? activityViewTypes : opportunityViewTypes

  const [ascOrDesc, setAscOrDesc] = useState<'asc' | 'desc'>('asc')

  const handleToggleAscOrDesc = () => {
    setAscOrDesc(ascOrDesc === 'asc' ? 'desc' : 'asc')
  }

  const handleToggleActivitiesOrOpportunities = (id: 1 | 2 | number) => {
    setIsLoading(true)

    setAscOrDesc('asc')

    setCurrentTab(id === 1 ? 1 : 2)

    navigation.setParams({ ...resetFilters })

    LogEvent(`organizacao_acessar_${id === 1 ? 'atividades' : 'oportunidades'}`, {
      item_id: ''
    })

    setViewType(
      id === 1
        ? activityViewTypes[0]
        : route?.params?.listType
        ? opportunityViewTypeGroup
        : opportunityViewTypes[0]
    )

    setTimeout(() => {
      setIsLoading(false)
    }, 200)
  }

  const handleToggleActivitiesTypeView = async (
    typeView: ViewType,
    date: string,
    period?: {
      startingDay: string | undefined
      endingDay: string | undefined
    }
  ) => {
    LogEvent(
      `organizacao_aplicou_filtro_${Number(currentTab) === 1 ? 'atividades' : 'oportunidades'}`,
      {
        item_id: ''
      }
    )

    LogEvent(`organizacao_filtro_${typeView.title.replace(' ', '_')}`, {
      item_id: ''
    })

    setAscOrDesc('asc')

    setViewType(typeView)

    navigation.setParams({ ...resetFilters })

    if (currentTab === 2) {
      navigation.setParams({ listType: typeView.group })
    }

    if (period) {
      setCurrentSelectedPeriod(period)
    } else {
      setCurrentSelectedDate(date)
    }

    closeViewTypeSheet()
  }

  const tabs = [
    {
      id: 1,
      title: 'Tarefas'
    },
    {
      id: 2,
      title: 'Oportunidades'
    }
  ]

  const organizationValue = useOrganizationValue()

  useEffect(() => {
    const logAnalitcs = async () => {
      const date = new Date()

      const formated = format(date, 'dd-MM-yyyy HH:mm')

      LogEventScreen({
        screen_class:
          Number(currentTab) === 1 ? 'OrganizationListActivities' : 'OrganizationListOpportunities',
        screen_name:
          Number(currentTab) === 1
            ? 'Organização - Lista Tarefas'
            : 'Organização - Lista Oportunidades',
        date: formated,
        hora: format(date, 'HH'),
        minutos: format(date, 'mm')
      })
    }

    logAnalitcs()
  }, [currentTab])

  const modalFilters = useMemo(() => {
    return {
      offer: route?.params?.offer,
      public_rating: route?.params?.public_rating,
      segment: route?.params?.segment,
      network: route?.params?.network,
      subsidiary: route?.params?.subsidiary,
      opportunity_type: route?.params?.opportunity_type,
      user_std_code: route?.params?.user_std_code
    }
  }, [route?.params])

  const orderByFilters = useMemo(() => {
    return {
      order_by: route?.params?.order_by,
      order_by_direction: route?.params?.order_by_direction
    }
  }, [route?.params])

  const activeFilterCount = calculateActiveFilterCount(modalFilters)

  const activeOrderByFilterCount = calculateActiveFilterCount({ order_by: route?.params?.order_by })

  const handleGoToFilters = () => {
    LogEvent(`organizacao_filtros_oportunidades`, {
      item_id: ''
    })

    navigation.navigate('OpportunityFilter', {
      listType: route?.params?.listType ?? viewType.group,
      ...modalFilters,
      ...orderByFilters
    })
  }

  const handleGoToOrderByFilter = () => {
    LogEvent(`organizacao_ordenacao_oportunidades`, {
      item_id: ''
    })

    navigation.navigate('OpportunityOrderByFilter', {
      listType: route?.params?.listType ?? viewType.group,
      ...modalFilters,
      ...orderByFilters
    })
  }

  return {
    tabs,
    isMobile,
    viewType,
    viewTypes,
    ascOrDesc,
    isLoading,
    currentTab,
    navigation,
    route,
    setCurrentTab,
    viewTypeSheetRef,
    openViewTypeSheet,
    organizationValue,
    currentSelectedDate,
    currentSelectedPeriod,
    handleToggleAscOrDesc,
    handleToggleActivitiesTypeView,
    handleToggleActivitiesOrOpportunities,
    handleGoToFilters,
    activeFilterCount,
    activeOrderByFilterCount,
    handleGoToOrderByFilter
  }
}
