import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ProfileScreenComponent } from './ProfileScreen.types'
import { useProfileScreen } from './useProfileScreen'

const ProfileScreenDesktop = lazy(() => import('./ProfileScreen.desktop'))

const ProfileScreenMobile = lazy(() => import('./ProfileScreen.mobile'))

export const ProfileScreen: ProfileScreenComponent = ({ navigation, route }) => {
  const profileScreen = useProfileScreen({ navigation, route })

  const Screen = profileScreen.isMobile ? ProfileScreenMobile : ProfileScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...profileScreen} />
    </Suspense>
  )
}
