import { FunctionComponent } from 'react'

import { Icons } from 'atoms'
import { Button, Center, HStack, IButtonProps, Text, useBreakpointValue } from 'native-base'

type TButtonFilter = {
  onPress?: () => void
  hasFilter?: boolean
  activeFilterCount?: number
  icon?: JSX.Element
  label?: string
}

export const ButtonFilter: FunctionComponent<TButtonFilter & IButtonProps> = ({
  onPress,
  hasFilter = false,
  activeFilterCount,
  icon: Icon = <Icons.Filter color="gray.700" size="18px" />,
  label = 'Filtros',
  ...props
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <Button
      {...props}
      position="relative"
      key="filter"
      onPress={onPress ?? undefined}
      colorScheme="white"
      borderRadius="full"
      w={isMobile ? 10 : undefined}
      px={!isMobile ? 6 : 0}
      size="sm"
      _text={{
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px'
      }}
      h={10}
      bgColor={hasFilter ? 'primary.50' : undefined}
      rightIcon={hasFilter || isMobile ? undefined : Icon}>
      <HStack>
        {!isMobile && (
          <Text
            fontSize="14px"
            bold
            lineHeight="20px"
            color="gray.700"
            {...(hasFilter && { mr: 3, color: 'primary.500' })}>
            {label}
          </Text>
        )}
        {isMobile && !hasFilter && <>{Icon}</>}
        {hasFilter && isMobile && (
          <>
            {Icon}
            <Icons.CloseTab
              color="primary.500"
              size="12px"
              position="absolute"
              top={-12}
              right={-8}
            />
          </>
        )}
        {hasFilter && !isMobile && (
          <Center bg="primary.500" borderRadius="full" size="18px">
            <Text fontSize="12px" bold lineHeight="20px" color="white">
              {activeFilterCount}
            </Text>
          </Center>
        )}
      </HStack>
    </Button>
  )
}
