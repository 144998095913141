import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { RefuseTermsScreenComponent } from './RefuseTermsScreen.types'
import { useRefuseTermsScreen } from './useRefuseTermsScreen'

const RefuseTermsScreenDesktop = lazy(() => import('./RefuseTermsScreen.desktop'))

const RefuseTermsScreenMobile = lazy(() => import('./RefuseTermsScreen.mobile'))

export const RefuseTermsScreen: RefuseTermsScreenComponent = ({ navigation }) => {
  const props = useRefuseTermsScreen({ navigation })

  const Screen = props.isMobile ? RefuseTermsScreenMobile : RefuseTermsScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
