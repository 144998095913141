import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { OrganizationActivityScreenComponent } from './types'
import { useOrganizationActivityDetails } from './useActivityDetails'

const ScreenDesktop = lazy(() => import('./desktop'))

const ScreenMobile = lazy(() => import('./mobile'))

export const OrganizationActivityScreen: OrganizationActivityScreenComponent = ({
  navigation,
  route
}) => {
  const walletScreen = useOrganizationActivityDetails({ navigation, route })

  const Screen = walletScreen.isMobile ? ScreenMobile : ScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...walletScreen} />
    </Suspense>
  )
}
