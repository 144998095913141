import { useCallback, useEffect, useMemo, useState } from 'react'

import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import { useUploadMutation } from 'integration/resources/upload'
import {
  TLeadersListItem,
  useFinishWalletsPeriodMutation,
  useGetLeadersListInfinityQuery,
  useGetWalletsManagementPeriodQuery,
  useGetWalletsManagementReportQuery,
  useSaveReportFileMutation
} from 'integration/resources/walletsManagement'
import { useBreakpointValue, useDisclose } from 'native-base'
import useDebounce from 'src/hooks/useDebounce'

import { OrderField, UseLeadersListScreen } from './LeadersListScreen.types'
import { ReportPDF } from '../Components/ReportPDF/ReportPDF'

export const orderFields: OrderField[] = [
  {
    label: 'Nome',
    options: [
      { id: 'asc', name: 'A-Z' },
      { id: 'desc', name: 'Z-A' }
    ]
  },
  {
    label: 'Número de TABs',
    options: [
      { id: 'asc', name: 'Menor para o Maior' },
      { id: 'desc', name: 'Maior para o Menor' }
    ]
  }
]

const mergeLeadersInfiniteQuery = (
  data: ReturnType<typeof useGetLeadersListInfinityQuery>['data']
) =>
  data?.pages.reduce<TLeadersListItem[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const useLeadersListScreen: UseLeadersListScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [search, onSearch] = useState<string>('')

  const debouncedSearch = useDebounce(search, 500)

  const handleSearchClear = () => {
    onSearch('')
  }

  const [selectedOrderBy, setSelectedOrderBy] = useState<{
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }>({
    field: undefined,
    direction: undefined
  })

  const { data: periodData, isLoading: periodIsLoading } = useGetWalletsManagementPeriodQuery()

  const adminPeriodData = periodData?.data.data

  const [appliedOrderBy, setAppliedOrderBy] = useState<
    'leader_user_name' | 'total_stores' | undefined
  >()

  const [appliedOrderByDirection, setAppliedOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >()

  const {
    data: leadersListData,
    isLoading: leadersListLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useGetLeadersListInfinityQuery({
    order_by: appliedOrderBy,
    order_by_direction: appliedOrderByDirection,
    ...(debouncedSearch?.length && { search: debouncedSearch })
  })

  const leadersList = useMemo(() => mergeLeadersInfiniteQuery(leadersListData), [leadersListData])

  const totalItems = leadersListData?.pages[0].data.metadata.pagination.total ?? 0

  const unassignedSpecialists = Number(adminPeriodData?.total_leader_active) ?? 0

  const onEndReached = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const { isOpen: orderByIsOpen, onOpen: orderByOnOpen, onClose: orderByOnClose } = useDisclose()

  const { mutate: finishWalletsPeriod, isLoading: finishWalletsPeriodIsLoading } =
    useFinishWalletsPeriodMutation()

  const handleFinishPeriod = () => {
    adminPeriodData?.id && finishWalletsPeriod({ period_id: adminPeriodData?.id })
  }

  const handleCheckboxChange = (field: string, direction: 'asc' | 'desc') => {
    setSelectedOrderBy({ field, direction })
  }

  const handleApply = () => {
    setAppliedOrderBy(
      selectedOrderBy?.field
        ? selectedOrderBy?.field === 'Nome'
          ? 'leader_user_name'
          : 'total_stores'
        : undefined
    )

    setAppliedOrderByDirection(selectedOrderBy?.direction)
  }

  const handleClear = () => {
    setSelectedOrderBy({ field: undefined, direction: undefined })
  }

  const handleGoToStaffList = (userStdCode: string) => {
    navigation.navigate('StaffList', { userStdCode })
  }

  const handleGoToOpenWalletsPeriod = () => {
    navigation.navigate('OpenWalletsPeriod', {})
  }

  const [reportLeaderStdCode, setReportLeaderStdCode] = useState('')

  const { data: walletsManagementReportData, isLoading: walletsManagementReportIsLoading } =
    useGetWalletsManagementReportQuery({
      leader_std_code: Number(reportLeaderStdCode),
      enabled: !!reportLeaderStdCode
    })

  const reportData = walletsManagementReportData?.data.data

  const handleDownloadReport = (leaderStdCode: string) => {
    setReportLeaderStdCode(leaderStdCode)
  }

  const { isLoading: saveFileIsLoading } = useSaveReportFileMutation()

  const { isLoading: isUploadLoading } = useUploadMutation()

  const [downloadReportIsLoading, setDownloadReportIsLoading] = useState(false)

  const handleCreatePDF = useCallback(async () => {
    setDownloadReportIsLoading(true)

    if (!reportData?.header?.fileName) {
      try {
        const blob = await pdf(<ReportPDF {...reportData} />).toBlob()

        const file = blob

        // TO DO: INTEGRATE THE PRE SIGN
        // if (file.type && file.type.split('/').length > 0) {
        //   const type = file.type.split('/')[1]

        //   const files = await uploadMutationAsync({
        //     directory_type: DIRECTORY_TYPE.WALLETS_MANAGEMENT_REPORTS,
        //     extension_type: type.toUpperCase() as keyof typeof EXTENSION_TYPE,
        //     files: [file]
        //   })

        //   console.log('🚀 ~ handleDownloadReport ~ files:', files)

        //   if (files.length) {
        //     if (files[0].status !== 200) {
        //       console.log('erro')

        //       return
        //     }

        //     const fileName = files[0].name

        //     saveFileMutationAsync({ leaderStdCode: Number(reportLeaderStdCode), fileName })
        //   }
        // }

        saveAs(file, 'Relatorio Carteirizacao.pdf')
      } catch (error) {
        console.error('Erro ao gerar o PDF:', error)
      }
    } else {
      const response = await fetch(reportData?.header?.fileName)

      const file = await response.blob()

      saveAs(file, 'Relatorio Carteirizacao.pdf')
    }

    setDownloadReportIsLoading(false)
  }, [reportData])

  useEffect(() => {
    if (reportLeaderStdCode && reportData?.header?.leaderStdCode) {
      handleCreatePDF()
    }
  }, [reportLeaderStdCode, reportData, handleCreatePDF])

  const anyHookIsLoading =
    leadersListLoading ||
    finishWalletsPeriodIsLoading ||
    periodIsLoading ||
    walletsManagementReportIsLoading ||
    isUploadLoading ||
    saveFileIsLoading ||
    downloadReportIsLoading

  return {
    isMobile,
    leadersListData: leadersList,
    leadersListIsLoading: leadersListLoading,
    leadersListIsFetchingNextPage: isFetchingNextPage,
    totalItems,
    onEndReached,
    search,
    onSearch,
    onPressSearchClear: handleSearchClear,
    handleClear,
    handleApply,
    handleCheckboxChange,
    selectedOrderBy,
    orderByIsOpen,
    orderByOnOpen,
    orderByOnClose,
    handleGoToStaffList,
    handleGoToOpenWalletsPeriod,
    handleFinishPeriod,
    finishWalletsPeriodIsLoading,
    adminPeriodData,
    periodIsLoading,
    unassignedSpecialists,
    anyHookIsLoading,
    handleDownloadReport,
    walletsManagementReportIsLoading
  }
}
