import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { TabDetailsScreenComponent } from './TabDetailsScreen.types'
import { useTabDetailsScreen } from './useTabDetailsScreen'

const TabDetailsScreenDesktop = lazy(() => import('./TabDetailsScreen.desktop'))

const TabDetailsScreenMobile = lazy(() => import('./TabDetailsScreen.mobile'))

export const TabDetailsScreen: TabDetailsScreenComponent = ({ route }) => {
  const tabDetailsScreen = useTabDetailsScreen({ route })

  const Screen = tabDetailsScreen.isMobile ? TabDetailsScreenMobile : TabDetailsScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...tabDetailsScreen} />
    </Suspense>
  )
}
