/* eslint-disable padding-line-between-statements */
import { FunctionComponent, memo } from 'react'

import { useNavigation, useRoute } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Heading, Text, VStack, HStack } from 'native-base'
import { RootStackParamList } from 'navigation/types'
import { Pressable } from 'react-native'
import { Category, CategoryCode } from 'src/integration/resources/knowledgeBase'
import { LogEvent } from 'src/utils/logEvents'

import Icons from '../Icons'

type CategoryContentCardProps = Category & {
  onPress(): void
  fromCategoryDetail?: boolean
}

const selectIcon = (code: number) => {
  switch (code) {
    case CategoryCode.CAMPAIGN:
      return 'Campaign'

    case CategoryCode.COMMUNICATION:
      return 'Communication'

    case CategoryCode.PROCESS:
      return 'Process'

    case CategoryCode.TRAINING:
      return 'Training'

    case CategoryCode.COMISSION:
      return 'Cash'

    case CategoryCode.ACADEMY:
      return 'Academy'

    case CategoryCode.REGULATORY:
      return 'Communication'

    case CategoryCode.PEOPLE:
      return 'CategoryUser'

    default:
      return 'Product'
  }
}

export const CategoryContentCard: FunctionComponent<CategoryContentCardProps> = memo(
  ({ code, name, total, fromCategoryDetail, onPress, id }) => {
    const route = useRoute()
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

    const IconComponent = Icons[selectIcon(code)]

    return fromCategoryDetail && route.params ? (
      <Pressable
        onPress={() => {
          LogEvent(`conteudo_categoria`, {
            item_id: id
          })

          // eslint-disable-next-line @babel/no-unused-expressions
          onPress ? onPress() : navigation.navigate('ContentByCategory', { category_id: id })
        }}>
        <HStack
          space={4}
          alignItems="center"
          justifyContent="center"
          // @ts-ignore
          bg={route.params.category_id === id ? 'primary.50' : 'white'}
          borderRadius="26px"
          h="38px"
          py="8px">
          <Heading
            fontSize="16px"
            lineHeight="22px"
            fontWeight="normal"
            // @ts-ignore
            color={route.params.category_id === id ? 'primary.500' : 'gray.700'}>
            {name}
          </Heading>
          <IconComponent color="primary.500" size={5} />
        </HStack>
      </Pressable>
    ) : (
      <Pressable onPress={onPress}>
        <VStack
          bg="white"
          borderRadius="20px"
          h={{ base: '90px', lg: 50 }}
          px={{ base: 4, lg: 6 }}
          py={{ base: '14px', lg: 6 }}>
          <VStack space={2} justifyContent={{ lg: 'space-between' }} h={{ lg: 'full' }}>
            <IconComponent color="primary.500" size={{ base: 5, lg: 8 }} />

            <VStack>
              <Heading
                fontSize={{ base: '12px', lg: '18px' }}
                lineHeight={{ base: '16px', lg: '24px' }}
                color="gray.700"
                fontWeight="bold"
                letterSpacing={0.2}>
                {name}
              </Heading>
              <Text
                fontSize={{ base: '10px', lg: '14px' }}
                lineHeight={{ base: '16px', lg: '20px' }}
                fontWeight={{ base: 'semibold', lg: 'normal' }}
                color="gray.500">
                {total} Artigos
              </Text>
            </VStack>
          </VStack>
        </VStack>
      </Pressable>
    )
  }
)
