import { useCallback, useMemo } from 'react'

import {
  useGetOpportunityListInfiniteQuery,
  OpportunitiesResponse
} from 'integration/resources/opportunities'

import { UseBottomSheetOpportunities } from './types'

const defaultReturn = {
  items: [],
  total_activities: 0,
  total_opportunities: 0,
  highlights: {
    total_opportunities: 0,
    items: []
  }
}

const mergeDataInfiniteQuery = (
  data: ReturnType<typeof useGetOpportunityListInfiniteQuery>['data']
) =>
  data?.pages.reduce<OpportunitiesResponse>((previousValue, currentValue) => {
    return { ...previousValue, ...currentValue.data.data }
  }, defaultReturn)

export const useBottomSheetOpportunities: UseBottomSheetOpportunities = ({
  storeTabNumber,
  enabled
}) => {
  const {
    data: opportunitiesData,
    isLoading: opportunitiesIsLoading,
    isFetchingNextPage: storesIsFetchingNextPage,
    hasNextPage: storesHasNextPage,
    fetchNextPage: storesFetchNextPage
  } = useGetOpportunityListInfiniteQuery(
    {
      store_tab_numbers: storeTabNumber ? [storeTabNumber] : undefined
    },
    enabled
  )

  const opportunityResponse = useMemo(
    () => mergeDataInfiniteQuery(opportunitiesData),
    [opportunitiesData]
  )

  const onScrollEndDragOpportunityList = useCallback(() => {
    if (!storesIsFetchingNextPage && storesHasNextPage) {
      storesFetchNextPage()
    }
  }, [storesFetchNextPage, storesIsFetchingNextPage, storesHasNextPage])

  return {
    opportunityResponse,
    isLoadingOpportunities: opportunitiesIsLoading,
    onScrollEndDragOpportunityList
  }
}
