import { FunctionComponent, PropsWithChildren } from 'react'

import { useNavigation } from '@react-navigation/native'
import { CustomStatusBar, Icons } from 'atoms'
import { Box, HStack, IconButton, useTheme, VStack } from 'native-base'
import { Platform } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export type TExternalLayoutProps = PropsWithChildren<{
  statusBarColor?: 'dark-content' | 'light-content'
}>

export const ExternalLayout: FunctionComponent<TExternalLayoutProps> = ({
  children,
  statusBarColor = 'dark-content'
}) => {
  const navigation = useNavigation()

  const theme = useTheme()

  const isAndroid = Platform.OS === 'android'

  return (
    <KeyboardAwareScrollView
      showsVerticalScrollIndicator={false}
      style={{ flex: 1, backgroundColor: theme.colors.background.base }}>
      <CustomStatusBar barStyle={statusBarColor} />
      <Box safeAreaTop bg="background.base" />

      <HStack bg="background.base" p={5} pl={3} pt={isAndroid ? 10 : 8} pb={8}>
        <IconButton
          borderRadius="full"
          w={10}
          h={10}
          bg="white"
          _pressed={{
            bg: 'gray.200'
          }}
          p={1}
          icon={<Icons.Chevron direction="left" color="gray.700" size={9} />}
          onPress={
            navigation.canGoBack()
              ? navigation.goBack
              : () =>
                  navigation.reset({
                    index: 0,
                    // @ts-ignore
                    routes: [{ name: 'Entry' }]
                  })
          }
        />
      </HStack>

      <VStack flex={1} justifyContent="center" p={5} pt={0} bg="background.base">
        {children}
      </VStack>
    </KeyboardAwareScrollView>
  )
}
