import React from 'react'

import { animated, useSpring } from '@react-spring/web'

type TSlideInRightView = {
  children: JSX.Element | JSX.Element[]
  delay?: number
  style?: React.CSSProperties
}

export const SlideInRightView = ({ children, delay = 0, style }: TSlideInRightView) => {
  const animation = useSpring({
    from: {
      x: 100,
      opacity: 0
    },
    to: {
      opacity: 1,
      x: 0
    },
    delay,
    config: {
      duration: 400
    }
  })

  return <animated.div style={{ ...animation, ...style }}>{children}</animated.div>
}
