// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ReturnIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 20 20', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6804 1.23143C12.9918 1.10244 13.3502 1.17374 13.5885 1.41207L19.5885 7.41207C19.914 7.73751 19.914 8.26515 19.5885 8.59058L13.5885 14.5906C13.4323 14.7469 13.2203 14.8347 12.9993 14.8347H7.83265V18.0013C7.83265 18.3384 7.62962 18.6422 7.31822 18.7712C7.00683 18.9002 6.6484 18.8289 6.41007 18.5906L0.410092 12.5906C0.253813 12.4343 0.166016 12.2223 0.166016 12.0013C0.166016 11.7803 0.253813 11.5684 0.410092 11.4121L6.41007 5.41207C6.56635 5.25579 6.77831 5.16799 6.99932 5.16799H12.166V2.00133C12.166 1.66427 12.369 1.36041 12.6804 1.23143ZM13.8326 4.01317V6.00133C13.8326 6.46156 13.4595 6.83466 12.9993 6.83466H7.83265V9.16799H12.9993C13.4595 9.16799 13.8326 9.54109 13.8326 10.0013V11.9895L17.8208 8.00133L13.8326 4.01317ZM12.166 13.168V10.8347H6.99932C6.53909 10.8347 6.16599 10.4616 6.16599 10.0013V8.01317L2.17785 12.0013L6.16599 15.9895V14.0013C6.16599 13.5411 6.53909 13.168 6.99932 13.168H12.166Z"
      fill="currentColor"
    />
  </Icon>
)
