import { FunctionComponent, useCallback, useMemo } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { LoadingTurbo } from 'atoms'
import { LinearGradient } from 'expo-linear-gradient'
import { UnconnectedMessage } from 'molecules'
import {
  Box,
  Center,
  Heading,
  HStack,
  Skeleton as NativeSkeleton,
  Text,
  useTheme,
  VStack
} from 'native-base'
import Chart from 'organisms/Chart'
import Layouts from 'organisms/Layouts'
import Skeleton from 'organisms/Skeleton'
import { Platform } from 'react-native'
import { formatDateStringToMask } from 'src/utils'
import { currencyFormat } from 'src/utils/currencyFormat'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory-native'

import { PanelsTypes, ProposalFunnelProps } from './constants'
import { useTabPanels } from './hook'

type TabPanelsProps = {
  handleGoToFullChartScreen(type: PanelsTypes): void
  tabNumber: string
}

export const TabPanelsDesktop: FunctionComponent<TabPanelsProps> = ({
  handleGoToFullChartScreen,
  tabNumber
}) => {
  const linkTo = useLinkTo()

  const {
    apnefData,
    apnefDataIsLoading,
    proposalFunnelData,
    proposalFunnelDataIsLoading,
    productionData,
    productionDataIsLoading,
    contractsAmountData,
    contractsAmountDataIsLoading,
    spreadPercentageData,
    spreadPercentageDataIsLoading,
    potentialData,
    potentialDataIsLoading,
    marketShareData,
    marketShareDataIsLoading,
    creditInsuranceData,
    creditInsuranceDataIsLoading,
    santanderAutoInsuranceData,
    santanderAutoInsuranceDataIsLoading,
    santanderAutoTypeData,
    santanderAutoTypeDataIsLoading,
    webmotorsData,
    webmotorsDataIsLoading,
    isGoodsAndServices
  } = useTabPanels({ tabNumber })

  const anyHookIsLoading = useMemo(
    () =>
      apnefDataIsLoading ||
      proposalFunnelDataIsLoading ||
      productionDataIsLoading ||
      contractsAmountDataIsLoading ||
      spreadPercentageDataIsLoading ||
      potentialDataIsLoading ||
      marketShareDataIsLoading ||
      creditInsuranceDataIsLoading ||
      santanderAutoInsuranceDataIsLoading ||
      santanderAutoTypeDataIsLoading ||
      webmotorsDataIsLoading,
    [
      apnefDataIsLoading,
      proposalFunnelDataIsLoading,
      productionDataIsLoading,
      contractsAmountDataIsLoading,
      spreadPercentageDataIsLoading,
      potentialDataIsLoading,
      marketShareDataIsLoading,
      creditInsuranceDataIsLoading,
      santanderAutoInsuranceDataIsLoading,
      santanderAutoTypeDataIsLoading,
      webmotorsDataIsLoading
    ]
  )

  const theme = useTheme()

  const changeColor = (type: ProposalFunnelProps) => {
    switch (type) {
      case ProposalFunnelProps.SIMULATION_QUANTITY:
        return theme.colors.secondary[800]

      case ProposalFunnelProps.PROPOSAL_QUANTITY:
        return theme.colors.secondary[500]

      case ProposalFunnelProps.APPROVED_QUANTITY:
        return theme.colors.secondary[300]

      case ProposalFunnelProps.EFFECTIVE_QUANTITY:
        return theme.colors.secondary[100]

      case ProposalFunnelProps.CONTRACTS_QUANTITY:
        return theme.colors.secondary[50]
    }
  }

  const handleGoToContractListScreen = useCallback(
    (type: PanelsTypes, monthYear: string) => {
      linkTo(`/contratos/${tabNumber}/${monthYear}/${type}`)
    },
    [linkTo, tabNumber]
  )

  const verifyHasData = (key: string, data: any[]) => {
    const INITIAL_VALUE_REDUCE = 0

    const values = data.map((item) => item[key])

    const sum = values.reduce((acc, value) => acc + value, INITIAL_VALUE_REDUCE)

    return sum > 0
  }

  return (
    <VStack mt={Platform.OS === 'ios' ? 4 : 8} safeAreaBottom mb={10} space={4}>
      {anyHookIsLoading ? (
        <Center h={360} w="full" position="absolute" zIndex={9}>
          <LoadingTurbo size={100} />
        </Center>
      ) : (
        <>
          <HStack justifyContent="space-between" space={6}>
            <VStack flex={1} space={6}>
              <VStack bg="white" borderRadius="20px" flex={1} px={4} py={6} h={426}>
                <Heading color="gray.700" fontSize="16px" mb={6}>
                  Funil de Propostas (Qtd)
                </Heading>

                {proposalFunnelDataIsLoading ? (
                  <VStack space={1} flex={1} mb={7}>
                    <NativeSkeleton borderRadius="full" h={6} w="70%" />
                    <NativeSkeleton borderRadius="full" h={6} w="50%" />
                    <NativeSkeleton borderRadius="full" h={6} w="90%" />
                    <NativeSkeleton borderRadius="full" h={6} w="70%" />
                    <NativeSkeleton borderRadius="full" h={6} w="40%" />
                  </VStack>
                ) : (
                  <Box
                    {...(proposalFunnelData?.dataSerialized &&
                      verifyHasData('value', proposalFunnelData?.dataSerialized) && {
                        h: '150px'
                      })}
                    flex={1}
                    mb={4}>
                    {proposalFunnelData?.dataSerialized &&
                    verifyHasData('value', proposalFunnelData?.dataSerialized) ? (
                      <VictoryChart
                        theme={VictoryTheme.material}
                        height={500}
                        width={900}
                        padding={{ bottom: 40, left: 0, right: 50, top: 40 }}>
                        <VictoryAxis
                          dependentAxis
                          style={{
                            tickLabels: {
                              fontSize: 1
                            },
                            ticks: { size: 0 },
                            axis: { stroke: 0 },
                            grid: {
                              stroke: 0
                            }
                          }}
                        />
                        <VictoryBar
                          horizontal
                          data={
                            isGoodsAndServices
                              ? proposalFunnelData?.dataSerialized.filter(
                                  (item) => item.type !== ProposalFunnelProps.CONTRACTS_QUANTITY
                                )
                              : proposalFunnelData?.dataSerialized
                          }
                          x="type"
                          y="value"
                          name="prodBar"
                          barWidth={60}
                          style={{
                            //@ts-ignore
                            data: {
                              fill: ({ datum }) => changeColor(datum.type)
                            },
                            //@ts-ignore
                            labels: {
                              fontSize: 32,
                              //@ts-ignore
                              fill: ({ datum }) =>
                                datum.value === 0 ? changeColor(datum.type) : theme.colors.gray[400]
                            }
                          }}
                          labels={({ datum }) => datum.value}
                        />
                      </VictoryChart>
                    ) : (
                      <UnconnectedMessage noAnimation />
                    )}
                  </Box>
                )}

                {proposalFunnelData?.dataSerialized && proposalFunnelData?.hasData ? (
                  <VStack space={2} mb={4}>
                    <HStack flex={1} space={3}>
                      <HStack alignItems="center" flex={1}>
                        <Center bg="secondary.800" borderRadius="full" size={3} mr="6px" />
                        <Text color="gray.700" fontSize="14px" bold>
                          Qtd Simulação
                        </Text>
                      </HStack>
                      <HStack alignItems="center" flex={1}>
                        <Center bg="secondary.500" borderRadius="full" size={3} mr="6px" />
                        <Text color="gray.700" fontSize="14px" bold>
                          Qtd Proposta
                        </Text>
                      </HStack>
                      <HStack alignItems="center" flex={1}>
                        <Center bg="secondary.300" borderRadius="full" size={3} mr="6px" />
                        <Text color="gray.700" fontSize="14px" bold>
                          Qtd Aprovado
                        </Text>
                      </HStack>
                    </HStack>

                    <HStack flex={1} space={3}>
                      <HStack alignItems="center" flex={1}>
                        <Center bg="secondary.100" borderRadius="full" size={3} mr="6px" />
                        <Text color="gray.700" fontSize="14px" bold>
                          Qtd Efetivado
                        </Text>
                      </HStack>
                      {!isGoodsAndServices ? (
                        <HStack alignItems="center" flex={1}>
                          <Center bg="secondary.50" borderRadius="full" size={3} mr="6px" />
                          <Text color="gray.700" fontSize="14px" bold>
                            Qtd Contratos
                          </Text>
                        </HStack>
                      ) : (
                        <HStack flex={1} />
                      )}
                      <HStack flex={1} />
                    </HStack>
                  </VStack>
                ) : (
                  <></>
                )}

                {proposalFunnelData?.processed_at && (
                  <Text color="gray.200" fontSize="10px" fontWeight="semibold">
                    Referência{' '}
                    {formatDateStringToMask(
                      proposalFunnelData?.processed_at,
                      proposalFunnelData?.is_short_format ? 'MM/yyyy' : 'dd/MM/yyyy'
                    )}
                  </Text>
                )}
              </VStack>
            </VStack>

            <VStack flex={1} space={6}>
              <NativeSkeleton isLoaded={!apnefDataIsLoading} borderRadius={20} h={124}>
                <LinearGradient
                  colors={[
                    theme.colors['header-gradient'][50],
                    theme.colors['header-gradient'][100],
                    theme.colors['header-gradient'][200]
                  ]}
                  style={{ justifyContent: 'center', borderRadius: 20, padding: 16, height: 200 }}>
                  <VStack space={3}>
                    <Text color="white" bold fontSize="18px" lineHeight={24} mb={2}>
                      Produção
                    </Text>
                    <Text fontSize="18px" color="white">
                      R${' '}
                      <Heading
                        style={{ fontFamily: 'Assistant_700Bold', fontSize: 24, color: 'white' }}>
                        {currencyFormat(
                          apnefData?.data.data.projected_production
                            ? String(apnefData?.data.data.projected_production.toFixed(2))
                            : '000'
                        )}
                      </Heading>
                    </Text>
                    {apnefData?.data.data.processed_at && (
                      <Text color="white" fontSize="10px" fontWeight="semibold">
                        Referência{' '}
                        {formatDateStringToMask(
                          apnefData?.data.data.processed_at,
                          apnefData?.data.data?.is_short_format ? 'MM/yyyy' : 'dd/MM/yyyy'
                        )}
                      </Text>
                    )}
                  </VStack>
                </LinearGradient>
              </NativeSkeleton>

              <HStack space={6}>
                <NativeSkeleton isLoaded={!apnefDataIsLoading} borderRadius={20} h={124} flex={1}>
                  <VStack
                    bg="white"
                    h={200}
                    borderRadius="20px"
                    flex={1}
                    px="18px"
                    py="11px"
                    space={3}>
                    <Text bold fontSize="12px" color="gray.400">
                      APÑEF (Qtd){'\n'}
                    </Text>
                    <Heading fontSize="24px" color="gray.700">
                      {apnefData?.data.data.apnef ?? '0'}
                    </Heading>
                    {apnefData?.data.data.processed_at && (
                      <Text fontWeight="semibold" fontSize="10px" color="gray.200">
                        Referência{' '}
                        {formatDateStringToMask(
                          apnefData?.data.data.processed_at,
                          apnefData?.data.data?.is_short_format ? 'MM/yyyy' : 'dd/MM/yyyy'
                        )}
                      </Text>
                    )}
                  </VStack>
                </NativeSkeleton>

                <NativeSkeleton isLoaded={!apnefDataIsLoading} borderRadius={20} h={124} flex={1}>
                  <VStack
                    bg="white"
                    h={200}
                    borderRadius="20px"
                    flex={1}
                    px="18px"
                    py="11px"
                    space={3}>
                    <Text bold fontSize="12px" color="gray.400">
                      Aprovados (Qtd){'\n'}
                    </Text>
                    <Heading fontSize="24px" color="gray.700">
                      {apnefData?.data.data.approved_quantity ?? '0'}
                    </Heading>
                    {apnefData?.data.data.processed_at && (
                      <Text fontWeight="semibold" fontSize="10px" color="gray.200">
                        Referência{' '}
                        {formatDateStringToMask(
                          apnefData?.data.data.processed_at,
                          apnefData?.data.data?.is_short_format ? 'MM/yyyy' : 'dd/MM/yyyy'
                        )}
                      </Text>
                    )}
                  </VStack>
                </NativeSkeleton>
              </HStack>
            </VStack>
          </HStack>

          <HStack flex={1} space={6} justifyContent="space-between">
            <Layouts.ResumedChart
              isLoading={anyHookIsLoading}
              //@ts-ignore
              type={
                isGoodsAndServices
                  ? `${PanelsTypes.CONTRACTS_AMOUNT}GoodsAndServices`
                  : PanelsTypes.CONTRACTS_AMOUNT
              }
              updated_at={contractsAmountData?.processed_at}
              is_short_format={contractsAmountData?.is_short_format ?? false}
              handleGoToFullChartScreen={handleGoToFullChartScreen}
              noLabels={
                !anyHookIsLoading && !contractsAmountDataIsLoading && !contractsAmountData?.hasData
              }
              chart={
                contractsAmountDataIsLoading ? (
                  <Skeleton.ResumedChart />
                ) : contractsAmountData?.hasData &&
                  contractsAmountData?.contracts_quantity &&
                  contractsAmountData?.insurance_contracts_quantity &&
                  contractsAmountData?.insurance_auto_contracts_quantity ? (
                  <Chart.PortraitMultipleBars
                    colors={[
                      theme.colors.primary[800],
                      theme.colors.primary[500],
                      theme.colors.primary[300]
                    ]}
                    tickValues={contractsAmountData?.tickValues ?? undefined}
                    chartsData={
                      isGoodsAndServices
                        ? [
                            contractsAmountData?.contracts_quantity,
                            contractsAmountData?.insurance_contracts_quantity
                          ]
                        : [
                            contractsAmountData?.contracts_quantity,
                            contractsAmountData?.insurance_contracts_quantity,
                            contractsAmountData?.insurance_auto_contracts_quantity
                          ]
                    }
                  />
                ) : (
                  <UnconnectedMessage noAnimation />
                )
              }
            />

            <Layouts.ResumedChart
              isLoading={anyHookIsLoading}
              type={PanelsTypes.PRODUCTION}
              updated_at={productionData?.processed_at}
              is_short_format={productionData?.is_short_format ?? false}
              handleGoToFullChartScreen={handleGoToFullChartScreen}
              horizontalLabels
              noLabels={
                !anyHookIsLoading && !contractsAmountDataIsLoading && !productionData?.hasData
              }
              chart={
                productionDataIsLoading ? (
                  <Skeleton.ResumedChart />
                ) : productionData?.hasData && productionData?.production ? (
                  <Chart.PortraitSingleBar
                    horizontalLabels
                    higherValue={productionData?.higherValue ?? 0}
                    chartData={productionData?.production}
                    onClickChart={(monthYear) =>
                      handleGoToContractListScreen(PanelsTypes.PRODUCTION, monthYear)
                    }
                    type="currency"
                  />
                ) : (
                  <UnconnectedMessage noAnimation />
                )
              }
            />
          </HStack>
          <HStack flex={1} space={6} justifyContent="space-between">
            <Layouts.ResumedChart
              isLoading={anyHookIsLoading}
              type={PanelsTypes.SPREAD}
              updated_at={spreadPercentageData?.processed_at}
              is_short_format={spreadPercentageData?.is_short_format ?? false}
              handleGoToFullChartScreen={handleGoToFullChartScreen}
              noLabels={
                !anyHookIsLoading &&
                !spreadPercentageDataIsLoading &&
                !spreadPercentageData?.hasData
              }
              chart={
                spreadPercentageDataIsLoading ? (
                  <Skeleton.ResumedChart />
                ) : spreadPercentageData?.hasData &&
                  spreadPercentageData?.net_spread &&
                  spreadPercentageData?.gross_spread ? (
                  <Chart.PortraitMultipleBars
                    horizontalLabels
                    colors={[theme.colors.primary[500], theme.colors.primary[300]]}
                    tickValues={spreadPercentageData?.tickValues ?? undefined}
                    chartsData={[
                      spreadPercentageData?.net_spread,
                      spreadPercentageData?.gross_spread
                    ]}
                    type="percentage"
                  />
                ) : (
                  <UnconnectedMessage noAnimation />
                )
              }
            />

            {!isGoodsAndServices && (
              <Layouts.ResumedChart
                isLoading={anyHookIsLoading}
                type={PanelsTypes.POTENTIAL}
                updated_at={potentialData?.processed_at}
                is_short_format={potentialData?.is_short_format ?? false}
                handleGoToFullChartScreen={handleGoToFullChartScreen}
                horizontalLabels
                noLabels={!anyHookIsLoading && !potentialDataIsLoading && !potentialData?.hasData}
                chart={
                  potentialDataIsLoading ? (
                    <Skeleton.ResumedChart />
                  ) : potentialData?.hasData && potentialData?.potential ? (
                    <Chart.PortraitSingleBar
                      horizontalLabels
                      higherValue={potentialData?.higherValue ?? 0}
                      chartData={potentialData?.potential}
                      type="currency"
                    />
                  ) : (
                    <UnconnectedMessage noAnimation />
                  )
                }
              />
            )}
          </HStack>
          <HStack flex={1} space={6} justifyContent="space-between">
            {!isGoodsAndServices && (
              <Layouts.ResumedChart
                isLoading={anyHookIsLoading}
                type={PanelsTypes.MARKETING_SHARE}
                updated_at={marketShareData?.processed_at}
                is_short_format={marketShareData?.is_short_format ?? false}
                handleGoToFullChartScreen={handleGoToFullChartScreen}
                noLabels={
                  !anyHookIsLoading &&
                  !marketShareDataIsLoading &&
                  !marketShareData?.actual_share_hasData
                }
                chart={
                  marketShareDataIsLoading ? (
                    <Skeleton.ResumedChart />
                  ) : marketShareData?.actual_share_hasData && marketShareData?.actual_share ? (
                    <Chart.PortraitSingleBar
                      horizontalLabels
                      higherValue={marketShareData?.higherValueShare ?? 0}
                      chartData={marketShareData?.actual_share}
                      onClickChart={(monthYear) =>
                        handleGoToContractListScreen(PanelsTypes.MARKETING_SHARE, monthYear)
                      }
                      type="percentage"
                    />
                  ) : (
                    <UnconnectedMessage noAnimation />
                  )
                }
              />
            )}

            <Layouts.ResumedChart
              isLoading={anyHookIsLoading}
              type={PanelsTypes.CREDIT_LIFE_INSURANCE}
              updated_at={creditInsuranceData?.processed_at}
              is_short_format={creditInsuranceData?.is_short_format ?? false}
              handleGoToFullChartScreen={handleGoToFullChartScreen}
              noLabels={
                !anyHookIsLoading && !creditInsuranceDataIsLoading && !creditInsuranceData?.hasData
              }
              chart={
                creditInsuranceDataIsLoading ? (
                  <Skeleton.ResumedChart />
                ) : creditInsuranceData?.hasData &&
                  creditInsuranceData?.ip_insurance_spf_qty &&
                  creditInsuranceData?.ip_insurance_spf_volume ? (
                  <Chart.PortraitMultipleBars
                    colors={[theme.colors.primary[500], theme.colors.primary[300]]}
                    tickValues={creditInsuranceData?.tickValues ?? undefined}
                    chartsData={[
                      creditInsuranceData?.ip_insurance_spf_qty,
                      creditInsuranceData?.ip_insurance_spf_volume
                    ]}
                    onClickChart={(monthYear) =>
                      handleGoToContractListScreen(PanelsTypes.CREDIT_LIFE_INSURANCE, monthYear)
                    }
                  />
                ) : (
                  <UnconnectedMessage noAnimation />
                )
              }
            />
          </HStack>
          <HStack flex={1} space={6} justifyContent="space-between">
            {!isGoodsAndServices && (
              <Layouts.ResumedChart
                isLoading={anyHookIsLoading}
                type={PanelsTypes.SANTANDER_AUTO_INSURANCE}
                updated_at={santanderAutoInsuranceData?.processed_at}
                is_short_format={santanderAutoInsuranceData?.is_short_format ?? false}
                handleGoToFullChartScreen={handleGoToFullChartScreen}
                noLabels={
                  !anyHookIsLoading &&
                  !santanderAutoInsuranceDataIsLoading &&
                  !santanderAutoInsuranceData?.hasData
                }
                chart={
                  santanderAutoInsuranceDataIsLoading ? (
                    <Skeleton.ResumedChart />
                  ) : santanderAutoInsuranceData?.hasData &&
                    santanderAutoInsuranceData?.ip_auto_insurance_qty ? (
                    <Chart.PortraitSingleBar
                      horizontalLabels
                      higherValue={santanderAutoInsuranceData?.higherValue ?? 0}
                      chartData={santanderAutoInsuranceData?.ip_auto_insurance_qty}
                      onClickChart={(monthYear) =>
                        handleGoToContractListScreen(
                          PanelsTypes.SANTANDER_AUTO_INSURANCE,
                          monthYear
                        )
                      }
                      type="percentage"
                    />
                  ) : (
                    <UnconnectedMessage noAnimation />
                  )
                }
              />
            )}
            {!isGoodsAndServices && (
              <Layouts.ResumedChart
                isLoading={anyHookIsLoading}
                type={PanelsTypes.TYPES_SANTANDER_AUTO}
                updated_at={santanderAutoTypeData?.processed_at}
                is_short_format={santanderAutoTypeData?.is_short_format ?? false}
                handleGoToFullChartScreen={handleGoToFullChartScreen}
                noLabels={
                  !anyHookIsLoading &&
                  !santanderAutoTypeDataIsLoading &&
                  !santanderAutoTypeData?.hasData
                }
                chart={
                  santanderAutoTypeDataIsLoading || !santanderAutoTypeData ? (
                    <Skeleton.ResumedChart />
                  ) : santanderAutoTypeData?.hasData &&
                    santanderAutoTypeData?.complete_insurance &&
                    santanderAutoTypeData?.fire_theft_insurance &&
                    santanderAutoTypeData?.third_party_insurance &&
                    santanderAutoTypeData?.loose_insurance ? (
                    <Chart.PortraitMultipleBars
                      colors={[
                        theme.colors.primary[800],
                        theme.colors.primary[500],
                        theme.colors.gray[200],
                        theme.colors.primary[300]
                      ]}
                      tickValues={santanderAutoTypeData?.tickValues ?? undefined}
                      chartsData={[
                        santanderAutoTypeData?.complete_insurance,
                        santanderAutoTypeData?.fire_theft_insurance,
                        santanderAutoTypeData?.third_party_insurance,
                        santanderAutoTypeData?.loose_insurance
                      ]}
                    />
                  ) : (
                    <UnconnectedMessage noAnimation />
                  )
                }
              />
            )}
          </HStack>
          <HStack flex={1} space={6} justifyContent="space-between">
            {!isGoodsAndServices && (
              <Layouts.ResumedChart
                isLoading={anyHookIsLoading}
                type={PanelsTypes.WEBMOTORS}
                updated_at={webmotorsData?.processed_at}
                is_short_format={webmotorsData?.is_short_format ?? false}
                handleGoToFullChartScreen={handleGoToFullChartScreen}
                noLabels={!anyHookIsLoading && !webmotorsDataIsLoading && !webmotorsData?.hasData}
                horizontalLabels
                chart={
                  webmotorsDataIsLoading ? (
                    <Skeleton.ResumedChart />
                  ) : webmotorsData?.hasData && webmotorsData?.web_realized_value ? (
                    <Chart.PortraitSingleBar
                      horizontalLabels
                      higherValue={webmotorsData?.higherValue ?? 0}
                      chartData={webmotorsData?.web_realized_value}
                      type="currency"
                    />
                  ) : (
                    <UnconnectedMessage noAnimation />
                  )
                }
              />
            )}
            <HStack flex={1} />
          </HStack>
        </>
      )}
    </VStack>
  )
}
