import { FunctionComponent } from 'react'

import { Box, Heading, HStack, Pressable } from 'native-base'
import { Controller } from 'react-hook-form'

import { TSelectField } from './types'
import { Icons } from '../../../atoms'

export const SelectField: FunctionComponent<TSelectField> = ({
  name,
  control,
  onSelect,
  handleResetField,
  placeholder,
  highlightSelected = false,
  icon
}) => {
  const Icon = Icons[icon]

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => (
        <HStack alignItems="center" h="56px" bg="white" borderRadius="full">
          <Pressable pl={4} justifyContent="center" flex={1} h="full" onPress={onSelect}>
            <HStack alignItems="center">
              {/* @ts-ignore */}
              <Icon color="gray.700" mr={4} size={5} />
              {value ? (
                <Box
                  {...(highlightSelected && { bg: 'tertiary.800', px: 4, py: 1 })}
                  borderRadius="full">
                  <Heading
                    color={highlightSelected ? 'white' : 'gray.400'}
                    fontWeight={highlightSelected ? 700 : 400}
                    fontSize={highlightSelected ? '12px' : '16px'}>
                    {value}
                  </Heading>
                </Box>
              ) : (
                <Heading color="gray.400" fontWeight={400} fontSize="16px">
                  {placeholder}
                </Heading>
              )}
            </HStack>
          </Pressable>
          {value ? (
            <Pressable onPress={handleResetField} justifyContent="center" alignItems="center" p={2}>
              <Icons.CloseTab color="gray.700" size={6} />
            </Pressable>
          ) : (
            <></>
          )}

          <Icons.ArrowDropDown color="gray.700" mr={4} size={5} />
        </HStack>
      )}
    />
  )
}
