import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { OrganizationScreenComponent } from './OrganizationScreen.types'
import { useOrganizationScreen } from './useOrganizationScreen'

const OrganizationScreenMobile = lazy(() => import('./OrganizationScreen.mobile'))

export const OrganizationScreen: OrganizationScreenComponent = ({ navigation, route }) => {
  const organizationScreen = useOrganizationScreen({ navigation, route })

  const Screen = OrganizationScreenMobile

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...organizationScreen} />
    </Suspense>
  )
}
