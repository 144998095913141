import { FunctionComponent, memo } from 'react'

import Icons from 'atoms/Icons'
import { Center, Heading, HStack, Text, VStack } from 'native-base'
import { TouchableOpacity } from 'react-native'

type ContentCardProps = {
  onPress(): void
  title: string
  subtitle: string
}

export const AttachmentCard: FunctionComponent<ContentCardProps> = memo(
  ({ title, subtitle, onPress }) => {
    return (
      <TouchableOpacity onPress={onPress}>
        <VStack bg="white" borderRadius="20px" h="83px" p={4}>
          <HStack space={2} alignItems="center">
            <Center bg="gray.50" borderRadius="full" h={12} w={12}>
              <Icons.Attachment color="gray.700" size={5} my={2} />
            </Center>
            <VStack flex={1}>
              <Heading
                fontSize="14px"
                lineHeight="18px"
                fontWeight="bold"
                color="gray.700"
                noOfLines={1}
                mb={1}>
                {title}
              </Heading>
              <Text fontSize="12px" lineHeight="16px" fontWeight="normal" color="gray.400">
                {subtitle}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </TouchableOpacity>
    )
  }
)
