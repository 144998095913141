import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const TabIcon: FunctionComponent<IIconProps & { filled?: boolean; closed?: boolean }> = (
  props
) => (
  <Icon {...{ viewBox: '0 0 20 20', ...props }}>
    <Path
      d="M3.99985 8.83335C3.99985 8.37311 4.37295 8.00001 4.83319 8.00001H8.83321C9.29345 8.00001 9.66654 8.37311 9.66654 8.83335C9.66654 9.29359 9.29345 9.66669 8.83321 9.66669H4.83319C4.37295 9.66669 3.99985 9.29359 3.99985 8.83335Z"
      fill="currentColor"
    />
    <Path
      d="M4.83319 12C4.37295 12 3.99985 12.3731 3.99985 12.8334C3.99985 13.2936 4.37295 13.6667 4.83319 13.6667H8.83321C9.29345 13.6667 9.66654 13.2936 9.66654 12.8334C9.66654 12.3731 9.29345 12 8.83321 12H4.83319Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.36685 0.19315C7.05781 -0.0643832 6.60891 -0.0643832 6.29987 0.19315L0.299848 5.19317C0.109853 5.3515 0 5.58604 0 5.83336V18.8334C0 19.2937 0.373098 19.6667 0.833337 19.6667H12.8334L12.8393 19.6667H18.8333C19.2936 19.6667 19.6667 19.2936 19.6667 18.8334V8.83335C19.6667 8.37311 19.2936 8.00001 18.8333 8.00001H13.6667V5.83336C13.6667 5.58604 13.5569 5.3515 13.3669 5.19317L7.36685 0.19315ZM13.6667 18.0001V9.66669H18V18.0001H13.6667ZM12.0001 8.82155V6.22367L6.83336 1.9181L1.66667 6.22367V18.0001H12V8.83335L12.0001 8.82155Z"
      fill="currentColor"
    />
  </Icon>
)
