import { useBreakpointValue } from 'native-base'

import { UseTermsScreen } from './TermsScreen.types'

export const useTermsScreen: UseTermsScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const hasNavigation = navigation.canGoBack()

  const goBack = navigation.goBack

  return { isMobile, hasNavigation, goBack }
}
