import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'

import { RVCalculatorsProps } from './RVCalculators.types'
import { useRVCalculators } from './useRVCalculators'

const RVCalculatorsDesktop = lazy(() => import('./RVCalculators.desktop'))

export const RVCalculators: FunctionComponent<RVCalculatorsProps> = (props) => {
  const rvCalculators = useRVCalculators(props)

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <RVCalculatorsDesktop {...rvCalculators} />
    </Suspense>
  )
}
