import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Circle } from 'react-native-svg'

export const ItemValidatorIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 8 8', ...props }}>
    <Circle cx="4" cy="4" r="4" fill="currentColor" />
  </Icon>
)
