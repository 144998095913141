import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ContactDetailsScreenComponent } from './ContactDetailsScreen.types'
import { useContactDetailsScreen } from './useContactDetailsScreen'

const ContactDetailsScreenDesktop = lazy(() => import('./ContactDetailsScreen.desktop'))

const ContactDetailsScreenMobile = lazy(() => import('./ContactDetailsScreen.mobile'))

export const ContactDetailsScreen: ContactDetailsScreenComponent = ({ route, navigation }) => {
  const contactDetailsScreen = useContactDetailsScreen({ route, navigation })

  const Screen = contactDetailsScreen.isMobile
    ? ContactDetailsScreenMobile
    : ContactDetailsScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...contactDetailsScreen} />
    </Suspense>
  )
}
