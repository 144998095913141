import { useCallback } from 'react'

import { useBreakpointValue } from 'native-base'
import { CreatePasswordFormData } from 'src/components/templates/CreatePasswordScreenTemplate/CreatePasswordScreenTemplate'
import { useBottomSheetContext } from 'src/contexts/BottomSheetContext'
import { useResetPasswordV2Mutation } from 'src/integration/resources/resetPassword'

import { UseRecoveryPasswordCreatePasswordScreen } from './RecoveryPasswordCreatePasswordScreen.types'

export const useResetPasswordCreatePasswordScreen: UseRecoveryPasswordCreatePasswordScreen = ({
  navigation,
  route
}) => {
  const { mutate, isLoading } = useResetPasswordV2Mutation()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const bottomSheetContext = useBottomSheetContext()

  const submit = useCallback(
    (formData: CreatePasswordFormData) => {
      mutate(
        {
          ...formData,
          ...route.params
        },
        {
          onError: () => {
            bottomSheetContext.open({
              description: 'Não foi possível redefinir sua senha.',
              title: 'Erro ao redefinir senha'
            })
          },
          onSuccess: () => {
            navigation.navigate('ResetPasswordSuccessfully')
          }
        }
      )
    },
    [bottomSheetContext, navigation, mutate, route.params]
  )

  return { isMobile, isLoading, submit }
}
