import { Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'
import { useSendAuthCode } from 'src/hooks/useSendAuthCode'
import { RootStackScreenComponent } from 'src/navigation'

import { useResetPasswordAuthCodeScreen } from './useResetPasswordAuthCodeScreen'

const ScreenDesktop = lazy(() => import('./ResetPasswordAuthCodeScreen.desktop'))

const ScreenMobile = lazy(() => import('./ResetPasswordAuthCodeScreen.mobile'))

export const ResetPasswordAuthCodeScreen: RootStackScreenComponent<'ResetPasswordAuthCode'> = ({
  navigation,
  route
}) => {
  const props = useResetPasswordAuthCodeScreen({
    navigation,
    route
  })

  const { handleSendAuthCodeSms, isLoadingAuthSendCodeSms } = useSendAuthCode()

  const Screen = props.isMobile ? ScreenMobile : ScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      {/* @ts-ignore */}
      <Screen
        {...props}
        handleSendAuthCodeSms={handleSendAuthCodeSms}
        isLoadingAuthSendCodeSms={isLoadingAuthSendCodeSms}
      />
    </Suspense>
  )
}
