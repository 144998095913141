import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import { SimulationPayload, SimulationResponseData, SimulationSerialized } from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_SIMULATION_OPTIONS_QUERY = 'getSimulationOptions'

export const useGetSimulationOptionsQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<SimulationSerialized>>, AxiosError<ResponseError>>(
    [KEY_SIMULATION_OPTIONS_QUERY],
    () => requests.getSimulationOptions()
  )

export const useSendSimulationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<SimulationResponseData>>,
    AxiosError<ResponseError>,
    SimulationPayload
  >(requests.sendSimulation, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_SIMULATION_OPTIONS_QUERY])
    }
  })
}
