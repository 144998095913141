import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'
import { useBreakpointValue } from 'native-base'

import { TTabEcosystemProps } from './TabEcosystem.types'

const TabEcosystemMobile = lazy(() => import('./TabEcosystem.mobile'))

const TabEcosystemDesktop = lazy(() => import('./TabEcosystem.desktop'))

export const TabEcosystem: FunctionComponent<TTabEcosystemProps> = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const Component = isMobile ? TabEcosystemMobile : TabEcosystemDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Component {...props} />
    </Suspense>
  )
}
