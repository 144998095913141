import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Circle } from 'react-native-svg'

export const AwaitingIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Circle cx={8.5} cy={8.219} r={7.75} stroke="#96CFAC" strokeWidth={0.5} />
    <Circle cx={8.5} cy={8.219} r={5.813} stroke="#96CFAC" strokeWidth={0.375} />
    <Circle cx={8.5} cy={8.219} r={6} fill="#CEE9D8" />
    <Circle cx={8.501} cy={8.216} r={3.2} fill="#4FAE73" />
  </Icon>
)
