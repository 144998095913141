import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ContentScreenComponent } from './ContentScreen.types'
import { useContentScreen } from './useContentScreen'

const ContentScreenDesktop = lazy(() => import('./ContentScreen.desktop'))

const ContentScreenMobile = lazy(() => import('./ContentScreen.mobile'))

export const ContentScreen: ContentScreenComponent = ({ navigation, route }) => {
  const ContentScreen = useContentScreen({ navigation, route })

  const Screen = ContentScreen.isMobile ? ContentScreenMobile : ContentScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...ContentScreen} />
    </Suspense>
  )
}
