import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path, ClipPath, G, Defs } from 'react-native-svg'

export const CameraIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 25 24', ...props }}>
    <G fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <Path d="M9.246 10.546a4.6 4.6 0 1 1 6.505 6.506 4.6 4.6 0 0 1-6.505-6.505Zm3.252.653a2.6 2.6 0 1 0 0 5.2 2.6 2.6 0 0 0 0-5.2Z" />
      <Path d="M7.944 2.644A2.2 2.2 0 0 1 9.499 2h6a2.2 2.2 0 0 1 2.2 2.2v1.4h3.2A3.4 3.4 0 0 1 24.3 9v9.6a3.4 3.4 0 0 1-3.4 3.4H4.1a3.4 3.4 0 0 1-3.4-3.4V9a3.4 3.4 0 0 1 3.4-3.4h3.2V4.2a2.2 2.2 0 0 1 .645-1.556ZM9.499 4a.2.2 0 0 0-.2.2v2.4a1 1 0 0 1-1 1H4.1A1.4 1.4 0 0 0 2.7 9v9.6A1.4 1.4 0 0 0 4.1 20h16.8a1.4 1.4 0 0 0 1.4-1.4V9a1.4 1.4 0 0 0-1.4-1.4h-4.2a1 1 0 0 1-1-1V4.2a.2.2 0 0 0-.2-.2h-6Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Icon>
)
