import { FunctionComponent, Suspense, lazy } from 'react'

import { Skeleton } from 'native-base'

import { CommentsProps } from './Comments.types'
import { useComments } from './useComments'

const CommentsDesktop = lazy(() => import('./Comments.desktop'))

const CommentsMobile = lazy(() => import('./Comments.mobile'))

export const Comments: FunctionComponent<CommentsProps> = (props) => {
  const comments = useComments(props)

  const Component = comments.isMobile ? CommentsMobile : CommentsDesktop

  return (
    <Suspense fallback={<Skeleton minHeight="104px" mb={4} borderRadius="20px" />}>
      <Component {...comments} />
    </Suspense>
  )
}
