import { FunctionComponent, PropsWithChildren } from 'react'

import { Icons, SlideInRightView } from 'atoms'
import { Box, HStack, VStack } from 'native-base'
import { Dimensions, ImageBackground, ImageSourcePropType } from 'react-native'

export type TExternalDesktopLayoutProps = PropsWithChildren<{
  image: ImageSourcePropType
  maxW?: string
  imageW?: string
}>

export const ExternalDesktopLayout: FunctionComponent<TExternalDesktopLayoutProps> = ({
  children,
  image,
  maxW = '440px',
  imageW = '600px'
}) => {
  return (
    <Box flex={1} bg="white">
      <HStack w="100%" h="100%" mx="auto" maxW={1600} position="relative">
        <VStack pl="7%" pr="7%" flex={1}>
          <VStack flex={1} mx="auto" w="full" justifyContent="center" maxW={maxW}>
            {children}
          </VStack>
        </VStack>
        <VStack w="45%" flex={1} position="relative" alignItems="flex-end">
          <SlideInRightView style={{ position: 'relative', flex: 1, alignItems: 'flex-end' }}>
            <ImageBackground
              source={image}
              resizeMode="cover"
              testID="externalLayoutDesktopImage"
              style={{
                width: imageW,
                height: Dimensions.get('window').height
              }}
            />
            <Box position="absolute" right={8} bottom={8} zIndex={1}>
              <Icons.LogoTurbo color="white" size={196} />
            </Box>
          </SlideInRightView>
        </VStack>
      </HStack>
    </Box>
  )
}
