import { useCallback } from 'react'

import { NavigationContainerRefWithCurrent } from '@react-navigation/native'
import { differenceInMinutes, parseISO } from 'date-fns'
import { loggedScreens } from 'navigation/loggedScreens'
import { RootStackParamList } from 'navigation/types'
import { useQueryClient } from 'react-query'
import { useSetAuthAtom } from 'src/store/auth'
import { LogEventUserId } from 'src/utils/logEvents'

import { Storage } from '../utils'

export const DEVICE_CONTROL_BLOCK_LAST_TIME = 'security_privacy_config_last_time_used'

export const DEVICE_CONTROL_BLOCK_ENABLE = 'security_privacy_config_block_enable'

export const DEVICE_CONTROL_TIME_SELECTED = 'security_privacy_config_time_selected'

export const SCREEN_LIST_NOT_APPLY_BLOCK = Object.keys(loggedScreens)

type TUseDeviceBlockControl = {
  refNavigation?: NavigationContainerRefWithCurrent<RootStackParamList>
}

export const useDeviceBlockControl = ({ refNavigation }: TUseDeviceBlockControl) => {
  const setAuthAtom = useSetAuthAtom()

  const queryClient = useQueryClient()

  const setLastTimeToUsed = useCallback(async () => {
    await Storage.setItem(DEVICE_CONTROL_BLOCK_LAST_TIME, new Date().toISOString())
  }, [])

  const getLastTimeToUsed = useCallback(async () => {
    const lastTimeString = await Storage.getItem(DEVICE_CONTROL_BLOCK_LAST_TIME)

    return lastTimeString ? parseISO(lastTimeString) : undefined
  }, [])

  const toggleToEnableBlock = useCallback(async (value: boolean) => {
    const enabled = value ? '1' : '0'

    await Storage.setItem(DEVICE_CONTROL_BLOCK_ENABLE, enabled)

    if (enabled === '0') {
      await setTimeToBlockDevice(0)
    }
  }, [])

  const getDeviceControlEnable = useCallback(async () => {
    const item = await Storage.getItem(DEVICE_CONTROL_BLOCK_ENABLE)

    if (!item) return false

    const enabled = JSON.parse(item ?? '')

    return Boolean(parseInt(enabled ?? '0', 10))
  }, [])

  const setTimeToBlockDevice = async (value: number) => {
    await Storage.setItem(DEVICE_CONTROL_TIME_SELECTED, String(value))
  }

  const getTimeBlockDevice = useCallback(async () => {
    const item = await Storage.getItem(DEVICE_CONTROL_TIME_SELECTED)

    const time = item ? JSON.parse(item) : 0

    return parseInt(time, 10)
  }, [])

  const verifyListNotApplyBlock = (
    refNavigation?: NavigationContainerRefWithCurrent<RootStackParamList>
  ) => {
    if (!refNavigation?.current) return true

    return SCREEN_LIST_NOT_APPLY_BLOCK.includes(refNavigation!.current!.getCurrentRoute()!.name)
  }

  const verifyIfUserCanBeContinueLogged = useCallback(async () => {
    const lastTime = await getLastTimeToUsed()

    const enabledBlocked = await getDeviceControlEnable()

    const timeSelected = await getTimeBlockDevice()

    console.log(lastTime, enabledBlocked, timeSelected)

    if (enabledBlocked && lastTime) {
      const diffInMinutes = differenceInMinutes(new Date(), lastTime)

      if (diffInMinutes >= timeSelected && verifyListNotApplyBlock(refNavigation)) {
        LogEventUserId(null)

        setAuthAtom(null)

        queryClient.invalidateQueries()
      }
    }
  }, [
    getDeviceControlEnable,
    getLastTimeToUsed,
    getTimeBlockDevice,
    queryClient,
    refNavigation,
    setAuthAtom
  ])

  return {
    getDeviceControlEnable,
    getTimeBlockDevice,
    toggleToEnableBlock,
    setLastTimeToUsed,
    setTimeToBlockDevice,
    verifyIfUserCanBeContinueLogged
  }
}
