import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ArrowUpIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 12 14', ...props }}>
    <Path
      d="M5.99716 0.333993C5.79082 0.332983 5.58414 0.417446 5.43569 0.587339L0.257894 6.51326C-0.0112829 6.82133 0.0202458 7.28928 0.328315 7.55846C0.636384 7.82764 1.10433 7.79611 1.37351 7.48804L5.26015 3.03983V12.9266C5.26015 13.3357 5.59179 13.6673 6.00089 13.6673C6.40999 13.6673 6.74163 13.3357 6.74163 12.9266V3.05249L10.629 7.48883C10.8986 7.79651 11.3666 7.82738 11.6742 7.55777C11.9819 7.28816 12.0128 6.82017 11.7432 6.51248L6.61389 0.65876C6.48062 0.462739 6.25579 0.333985 6.00089 0.333985L5.99716 0.333993Z"
      fill="currentColor"
    />
  </Icon>
)
