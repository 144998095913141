import { useEffect, useState } from 'react'

import * as Notifications from 'expo-notifications'
import { useBreakpointValue } from 'native-base'

import { UseManageNotificationsScreen } from './ManageNotificationsScreen.types'

export const useManageNotificationsScreen: UseManageNotificationsScreen = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [notification, setNotification] = useState(false)

  const [displayNotification, setDisplayNotification] = useState(false)

  const allowsNotificationsAsync = async () => {
    const settings = await Notifications.getPermissionsAsync()

    if (settings.granted) {
      setNotification(true)

      setDisplayNotification(true)
    } else {
      const request = await Notifications.requestPermissionsAsync()

      if (request.granted) {
        setNotification(true)

        setDisplayNotification(true)
      }
    }
  }

  useEffect(() => {
    allowsNotificationsAsync()
  }, [])

  return {
    isMobile,
    notification,
    displayNotification
  }
}
