import { useGetEcosystemByTabNumberQuery } from 'integration/resources/wallet'

import { UseTabComponent } from './types'

export const useTabComponent: UseTabComponent = ({ tabNumber }) => {
  const { data: ecosystemData, isLoading } = useGetEcosystemByTabNumberQuery(tabNumber)

  return {
    ecosystemData,
    isLoading
  }
}
