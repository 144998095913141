import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const DropdownIcon: FunctionComponent<IIconProps & { needsCenter?: boolean }> = ({
  needsCenter,
  ...props
}) =>
  needsCenter ? (
    <Icon {...{ viewBox: '0 0 24 24', ...props }}>
      <Path
        d="M6.45418 10.6356L10.4219 14.5624C11.0193 15.1536 11.9844 15.1536 12.5819 14.5624L16.5496 10.6356C17.5147 9.6804 16.8253 8.04297 15.4619 8.04297H7.52653C6.16312 8.04297 5.48907 9.6804 6.45418 10.6356Z"
        fill="currentColor"
      />
    </Icon>
  ) : (
    <Icon {...{ viewBox: '0 0 11 17', ...props }}>
      <Path
        d="m.454 2.632 3.968 3.926a1.537 1.537 0 0 0 2.16 0l3.968-3.926c.965-.956.275-2.593-1.088-2.593H1.527C.163.04-.511 1.676.454 2.632Z"
        fill="currentColor"
      />
    </Icon>
  )
