import { useCallback } from 'react'

import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { useSendEmailResetPasswordMutation } from 'integration/resources/resetPassword'
import { useBreakpointValue } from 'native-base'
import { Dimensions } from 'react-native'
import { useQueryClient } from 'react-query'
import { useAuthAtom } from 'src/store/auth'
import { formatEmail } from 'src/utils/formatters'
import { LogEventUserId } from 'src/utils/logEvents'

import { UseResetPasswordEmailSentSuccessfullyScreen } from './ResetPasswordEmailSentSuccessfullyScreen.types'

export const useResetPasswordEmailSentSuccessfullyScreen: UseResetPasswordEmailSentSuccessfullyScreen =
  ({ navigation, route }) => {
    const email = formatEmail(route.params.email)

    const screenHeight = Dimensions.get('screen').height

    const sendEmailResetPasswordMutation = useSendEmailResetPasswordMutation()

    const bottomSheetContext = useBottomSheetContext()

    const isMobile = useBreakpointValue({ base: true, lg: false })

    const handleReSentEmail = () => {
      sendEmailResetPasswordMutation.mutate(
        {
          email: route.params.email
        },
        {
          onError: () =>
            bottomSheetContext.open({
              description: 'Não foi possível reenviar o e-mail de redefinição de senha.',
              title: 'Atenção'
            }),
          onSuccess: () => {
            navigation.navigate('ResetPasswordReSentEmail', { email })
          }
        }
      )
    }

    const [authAtom, setAuthAtom] = useAuthAtom()

    const queryClient = useQueryClient()

    const handleGoToLoginScreen = useCallback(() => {
      if (authAtom) {
        Promise.all([
          LogEventUserId(null),
          setAuthAtom(null),
          navigation.reset({
            index: 0,
            routes: [
              {
                name: 'Login'
              },
              {
                name: 'ResetPasswordSendEmail'
              },
              {
                name: 'ResetPasswordReSentEmail',
                params: {
                  email
                }
              },
              {
                name: 'LoginBlocked'
              }
            ]
          })
        ]).then(() => queryClient.removeQueries())
      }

      navigation.navigate('Login')
    }, [authAtom, navigation, setAuthAtom, email, queryClient])

    return {
      email,
      isMobile,
      screenHeight,
      handleReSentEmail,
      handleGoToLoginScreen,
      isLoading: sendEmailResetPasswordMutation.isLoading
    }
  }
