import { FunctionComponent, Suspense, lazy, memo } from 'react'

import { Icons } from 'atoms'
import { useBreakpointValue, Spinner } from 'native-base'

import { TToastProp } from './Toast.types'

const ToastDesktop = lazy(() => import('./Toast.desktop'))

const ToastMobile = lazy(() => import('./Toast.mobile'))

export const Toast: FunctionComponent<TToastProp> = memo(({ type, text, placement }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const colors = {
    success: 'secondary.500',
    warning: '#F2B064',
    error: '#6C4E8B'
  }

  const currentColor = colors[type]

  const icons = {
    success: Icons.ThumbsUp,
    warning: Icons.Exclamation,
    error: Icons.ThumbsDown
  }

  const CurrentIcon = icons[type]

  const Component = isMobile ? ToastMobile : ToastDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Component text={text} currentColor={currentColor} placement={placement}>
        <CurrentIcon size="12px" color="white" />
      </Component>
    </Suspense>
  )
})
