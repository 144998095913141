export type Menu = {
  children: Menu[]
  name: string
  option_id: number
  sequencial_id: number
  url?: string
}

export const MENU_FEATURES_ENUM = {
  DASHBOARDS: 1,
  SURVEYS: 2
} as const

export type MenuFeaturesKeys = (typeof MENU_FEATURES_ENUM)[keyof typeof MENU_FEATURES_ENUM]

export type HighlightedFeaturesItem = {
  created_at: string
  description: string
  id: string
  target: string
  title: string
  updated_at: string
  menu_key: MenuFeaturesKeys
  read: boolean
}

export type HighlightedFeatures = HighlightedFeaturesItem[]
