import { FunctionComponent, PropsWithChildren } from 'react'

import { LoadingTurbo } from 'atoms/LoadingTurbo/LoadingTurbo'
import { Center, VStack } from 'native-base'

export const CalendarRoot: FunctionComponent<PropsWithChildren<{ isLoading?: boolean }>> = ({
  isLoading,
  children
}) => {
  return (
    <VStack position="relative">
      {isLoading && (
        <Center h="full" w="full" position="absolute" zIndex={9} bg="white" opacity={0.75}>
          <LoadingTurbo size={48} />
        </Center>
      )}
      {children}
    </VStack>
  )
}
