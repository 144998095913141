import { memo } from 'react'

import Svg, { ClipPath, Defs, G, Path, Rect, SvgProps } from 'react-native-svg'

const PageNoConnectionIllustration = (props: SvgProps) => (
  <Svg width={201} height={155} viewBox="0 0 201 155" fill="none" {...props}>
    <G clipPath="url(#clip0_10403_64218)">
      <Path
        d="M125.885 132.06H35.5848C33.7704 132.06 32.0316 131.337 30.7464 130.052C29.4612 128.767 28.7388 127.028 28.7388 125.214V93.6463V28.7731C28.7388 26.9587 29.4612 25.2199 30.7464 23.9431C32.0316 22.6579 33.762 21.9355 35.5764 21.9355H169.708C171.522 21.9355 173.261 22.6579 174.546 23.9431C175.831 25.2283 176.554 26.9671 176.554 28.7815V72.1927V125.214C176.554 127.028 175.831 128.767 174.546 130.052C173.261 131.337 171.522 132.06 169.708 132.06H125.876H125.885Z"
        fill="#F5F5F5"
      />
      <Path
        d="M41.1373 31.7716C41.1373 32.2756 40.9861 32.7628 40.7089 33.1828C40.4317 33.6028 40.0369 33.922 39.5749 34.1152C39.1129 34.3084 38.6005 34.3588 38.1133 34.258C37.6261 34.1572 37.1725 33.922 36.8197 33.5608C36.4669 33.208 36.2233 32.7544 36.1225 32.2672C36.0217 31.7716 36.0721 31.2676 36.2653 30.8056C36.4585 30.3436 36.7777 29.9488 37.1977 29.6716C37.6177 29.3944 38.1049 29.2432 38.6089 29.2432C39.2809 29.2432 39.9277 29.512 40.3981 29.9824C40.8769 30.4612 41.1373 31.0996 41.1373 31.7716Z"
        fill="#F3123C"
      />
      <Path
        d="M176.553 41.5332H28.7471"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M41.1373 31.7716C41.1373 32.2756 40.9861 32.7628 40.7089 33.1828C40.4317 33.6028 40.0369 33.922 39.5749 34.1152C39.1129 34.3084 38.6005 34.3588 38.1133 34.258C37.6261 34.1572 37.1725 33.922 36.8197 33.5608C36.4669 33.208 36.2233 32.7544 36.1225 32.2672C36.0217 31.7716 36.0721 31.2676 36.2653 30.8056C36.4585 30.3436 36.7777 29.9488 37.1977 29.6716C37.6177 29.3944 38.1049 29.2432 38.6089 29.2432C39.2809 29.2432 39.9277 29.512 40.3981 29.9824C40.8769 30.4612 41.1373 31.0996 41.1373 31.7716V31.7716Z"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M48.7728 31.7716C48.7728 32.2756 48.6216 32.7628 48.3444 33.1828C48.0672 33.6028 47.6724 33.922 47.2104 34.1152C46.7484 34.3084 46.236 34.3588 45.7488 34.258C45.2532 34.1572 44.808 33.922 44.4552 33.5608C44.1024 33.208 43.8588 32.7544 43.758 32.2672C43.6572 31.7716 43.7076 31.2676 43.9008 30.8056C44.094 30.3436 44.4132 29.9488 44.8332 29.6716C45.2532 29.3944 45.7404 29.2432 46.2444 29.2432C46.9164 29.2432 47.5632 29.512 48.0336 29.9824C48.5124 30.4612 48.7728 31.0996 48.7728 31.7716V31.7716Z"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M56.4337 31.7716C56.4337 32.2756 56.2825 32.7628 56.0053 33.1828C55.7281 33.6028 55.3333 33.922 54.8713 34.1152C54.4093 34.3084 53.8969 34.3588 53.4097 34.258C52.9225 34.1572 52.4689 33.922 52.1161 33.5608C51.7633 33.1996 51.5197 32.7544 51.4189 32.2672C51.3181 31.7716 51.3685 31.2676 51.5617 30.8056C51.7549 30.3436 52.0741 29.9488 52.4941 29.6716C52.9141 29.3944 53.4013 29.2432 53.9053 29.2432C54.5773 29.2432 55.2241 29.512 55.6945 29.9824C56.1733 30.4612 56.4337 31.0996 56.4337 31.7716V31.7716Z"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M174.546 130.052C175.831 128.767 176.553 127.028 176.553 125.214V40.9955V28.7903C176.553 26.9759 175.831 25.2371 174.546 23.9519C173.261 22.6667 171.522 21.9443 169.707 21.9443H35.5847C33.7703 21.9443 32.0315 22.6667 30.7547 23.9519C29.4695 25.2371 28.7555 26.9759 28.7471 28.7819V115.882V125.222C28.7471 127.037 29.4695 128.776 30.7547 130.061C32.0399 131.346 33.7787 132.06 35.5931 132.068H121.29"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M147.548 65.0703H55.1819V99.7791H147.548V65.0703Z" fill="#404040" />
      <Path
        d="M106.808 77.0234L95.9387 87.8342"
        stroke="white"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M106.783 87.8508L95.947 76.998"
        stroke="white"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M147.557 79.3171V99.7879H144.39M140.744 65.0791H55.1902V99.7879H137.25"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M153.143 116.091C153.269 116.285 153.336 116.511 153.336 116.747C153.336 116.982 153.26 117.209 153.134 117.402C153.008 117.595 152.815 117.746 152.605 117.83C152.387 117.923 152.152 117.939 151.925 117.889C151.698 117.839 151.488 117.729 151.32 117.561C151.152 117.393 151.043 117.183 151.001 116.957C150.959 116.73 150.984 116.495 151.076 116.276C151.169 116.058 151.32 115.881 151.513 115.747C151.639 115.663 151.79 115.604 151.942 115.571C152.093 115.545 152.252 115.545 152.404 115.571C152.555 115.604 152.698 115.663 152.832 115.747C152.958 115.831 153.067 115.949 153.151 116.075L153.143 116.091Z"
        fill="#F3123C"
      />
      <Path
        d="M161.064 120.686C161.19 120.88 161.257 121.106 161.257 121.342C161.257 121.577 161.19 121.804 161.056 121.997C160.93 122.19 160.736 122.341 160.526 122.425C160.308 122.509 160.073 122.534 159.846 122.492C159.619 122.442 159.409 122.333 159.25 122.165C159.082 121.997 158.972 121.787 158.93 121.56C158.888 121.333 158.914 121.098 159.006 120.88C159.098 120.67 159.25 120.485 159.443 120.35C159.703 120.182 160.022 120.124 160.325 120.182C160.627 120.241 160.896 120.426 161.072 120.678L161.064 120.686Z"
        fill="#F3123C"
      />
      <Path
        d="M145.163 108.322C145.625 109.027 145.877 109.851 145.868 110.691C145.868 111.539 145.608 112.354 145.138 113.059C144.667 113.757 143.995 114.303 143.214 114.622C142.433 114.941 141.576 115.025 140.753 114.857C139.93 114.689 139.165 114.277 138.577 113.681C137.981 113.085 137.578 112.32 137.418 111.489C137.258 110.657 137.342 109.8 137.67 109.027C137.998 108.246 138.544 107.583 139.249 107.121C140.19 106.499 141.349 106.272 142.458 106.499C143.567 106.726 144.541 107.381 145.163 108.322Z"
        fill="#F3123C"
      />
      <Path
        d="M142.164 123.383C142.29 123.576 142.475 123.727 142.693 123.82C142.912 123.912 143.147 123.937 143.374 123.887C143.6 123.845 143.81 123.736 143.978 123.568C144.146 123.408 144.256 123.198 144.306 122.963C144.356 122.736 144.331 122.501 144.247 122.283C144.163 122.064 144.012 121.879 143.819 121.753C143.626 121.619 143.399 121.552 143.164 121.552C142.928 121.552 142.702 121.619 142.508 121.745C142.382 121.829 142.265 121.938 142.181 122.064C142.097 122.19 142.03 122.333 142.004 122.493C141.971 122.644 141.971 122.803 142.004 122.955C142.038 123.106 142.088 123.249 142.181 123.383H142.164Z"
        fill="#F3123C"
      />
      <Path
        d="M143.315 132.455C143.441 132.648 143.626 132.8 143.844 132.892C144.063 132.984 144.298 133.01 144.525 132.959C144.751 132.917 144.961 132.808 145.129 132.64C145.297 132.472 145.407 132.27 145.457 132.035C145.507 131.808 145.482 131.573 145.398 131.355C145.314 131.136 145.163 130.952 144.97 130.826C144.777 130.691 144.55 130.624 144.315 130.624C144.079 130.624 143.853 130.691 143.659 130.817C143.533 130.901 143.416 131.01 143.332 131.136C143.248 131.262 143.181 131.405 143.155 131.565C143.122 131.716 143.122 131.876 143.155 132.027C143.181 132.178 143.239 132.321 143.332 132.455H143.315Z"
        fill="#F3123C"
      />
      <Path
        d="M161.694 117.83C163.097 119.938 163.836 122.408 163.828 124.936C163.828 127.465 163.063 129.934 161.652 132.034C160.241 134.134 158.233 135.764 155.898 136.722C153.554 137.679 150.984 137.923 148.506 137.427C146.028 136.923 143.752 135.697 141.971 133.908C140.19 132.118 138.98 129.834 138.493 127.347C138.006 124.861 138.275 122.299 139.249 119.964C140.224 117.628 141.87 115.638 143.978 114.243C145.373 113.311 146.944 112.672 148.59 112.345C150.236 112.026 151.933 112.026 153.58 112.362C155.226 112.698 156.788 113.353 158.183 114.285C159.577 115.226 160.77 116.427 161.694 117.822V117.83Z"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M145.163 108.322C145.625 109.027 145.877 109.851 145.868 110.691C145.868 111.539 145.608 112.354 145.138 113.059C144.667 113.757 143.995 114.303 143.214 114.622C142.433 114.941 141.576 115.025 140.753 114.857C139.93 114.689 139.165 114.277 138.577 113.681C137.981 113.085 137.578 112.32 137.418 111.489C137.258 110.657 137.342 109.8 137.67 109.027C137.998 108.246 138.544 107.583 139.249 107.121C140.19 106.499 141.349 106.272 142.458 106.499C143.567 106.726 144.541 107.381 145.163 108.322V108.322Z"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M139.249 107.121C137.931 106.088 136.335 105.466 134.671 105.332C133 105.197 131.328 105.55 129.858 106.365"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M139.249 107.12C138.812 105.499 138.854 103.794 139.384 102.198C139.913 100.61 140.896 99.2076 142.206 98.166"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M138.964 120.67C138.964 120.67 131.857 116.881 127.985 121.644"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M138.476 127.356C138.476 127.356 130.48 126.784 128.858 132.723"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M141.618 133.563C141.618 133.563 134.209 136.722 135.494 142.736"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M151.824 112.135C151.824 112.135 151.102 104.139 156.999 102.408"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M158.175 114.286C158.175 114.286 160.753 106.692 166.843 107.516"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M162.668 119.603C162.668 119.603 168.464 114.008 173.504 117.528"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M143.953 114.235C149.212 122.165 155.562 130.12 148.372 137.403"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M144.012 114.227C149.27 122.156 154.084 131.136 163.592 127.322"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M153.143 116.091C153.269 116.285 153.336 116.511 153.336 116.747C153.336 116.982 153.26 117.209 153.134 117.402C153.008 117.595 152.815 117.746 152.605 117.83C152.387 117.923 152.152 117.939 151.925 117.889C151.698 117.839 151.488 117.729 151.32 117.561C151.152 117.393 151.043 117.183 151.001 116.957C150.959 116.73 150.984 116.495 151.076 116.276C151.169 116.058 151.32 115.881 151.513 115.747C151.639 115.663 151.79 115.604 151.942 115.571C152.093 115.545 152.252 115.545 152.404 115.571C152.555 115.604 152.698 115.663 152.832 115.747C152.958 115.831 153.067 115.949 153.151 116.075L153.143 116.091Z"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M161.064 120.686C161.19 120.88 161.257 121.106 161.257 121.342C161.257 121.577 161.19 121.804 161.056 121.997C160.93 122.19 160.736 122.341 160.526 122.425C160.308 122.509 160.073 122.534 159.846 122.492C159.619 122.442 159.409 122.333 159.25 122.165C159.082 121.997 158.972 121.787 158.93 121.56C158.888 121.333 158.914 121.098 159.006 120.88C159.098 120.67 159.25 120.485 159.443 120.35C159.703 120.182 160.022 120.124 160.325 120.182C160.627 120.241 160.896 120.426 161.072 120.678L161.064 120.686Z"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M142.164 123.383C142.29 123.576 142.475 123.727 142.693 123.82C142.912 123.912 143.147 123.937 143.374 123.887C143.6 123.845 143.81 123.736 143.978 123.568C144.146 123.408 144.256 123.198 144.306 122.963C144.356 122.736 144.331 122.501 144.247 122.283C144.163 122.064 144.012 121.879 143.819 121.753C143.626 121.619 143.399 121.552 143.164 121.552C142.928 121.552 142.702 121.619 142.508 121.745C142.382 121.829 142.265 121.938 142.181 122.064C142.097 122.19 142.03 122.333 142.004 122.493C141.971 122.644 141.971 122.803 142.004 122.955C142.038 123.106 142.088 123.249 142.181 123.383H142.164Z"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M143.315 132.455C143.441 132.648 143.626 132.8 143.844 132.892C144.062 132.984 144.298 133.01 144.524 132.959C144.751 132.917 144.961 132.808 145.129 132.64C145.297 132.472 145.406 132.262 145.457 132.035C145.507 131.808 145.482 131.573 145.398 131.355C145.314 131.136 145.163 130.952 144.97 130.826C144.776 130.7 144.55 130.624 144.314 130.624C144.079 130.624 143.852 130.691 143.659 130.817C143.533 130.901 143.416 131.01 143.332 131.136C143.248 131.262 143.18 131.405 143.155 131.556C143.13 131.708 143.122 131.867 143.155 132.018C143.18 132.17 143.239 132.312 143.332 132.447L143.315 132.455Z"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M182.005 56.5941C182.005 61.5501 180.535 66.4053 177.78 70.5297C175.025 74.6541 171.11 77.8713 166.524 79.7613C161.938 81.6597 156.898 82.1553 152.034 81.1893C147.17 80.2233 142.702 77.8377 139.19 74.3265C135.688 70.8153 133.294 66.3465 132.328 61.4829C131.362 56.6193 131.857 51.5793 133.756 46.9929C135.654 42.4065 138.871 38.4921 142.987 35.7369C147.112 32.9817 151.958 31.5117 156.923 31.5117C163.576 31.5117 169.951 34.1577 174.655 38.8617C179.359 43.5657 181.997 49.9413 182.005 56.5941Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M171.329 64.8264H142.483V58.4424H171.329V64.8264Z" fill="#404040" />
      <Path
        d="M171.329 64.8264H142.483V58.4424H171.329V64.8264Z"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M167.725 50.5293V54.7797"
        stroke="#F3123C"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M167.725 46.9346V47.5898"
        stroke="#F3123C"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M157.477 50.5293V54.7797"
        stroke="#F3123C"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M157.477 46.9346V47.5898"
        stroke="#F3123C"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M160.636 54.7798V46.9258H164.559"
        stroke="#F3123C"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M160.695 50.5293H163.912"
        stroke="#F3123C"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M146.465 46.9346L147.716 54.4442C147.75 54.6374 147.918 54.7802 148.12 54.7802C148.304 54.7802 148.464 54.6542 148.514 54.4778L150.186 48.3878C150.211 48.2954 150.295 48.2282 150.396 48.2282C150.497 48.2282 150.581 48.2954 150.606 48.3878L152.278 54.4778C152.328 54.6542 152.488 54.7802 152.672 54.7802C152.874 54.7802 153.042 54.6374 153.076 54.4442L154.327 46.9346"
        stroke="#F3123C"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M55.8372 103.576C55.8372 103.962 55.7196 104.332 55.5096 104.651C55.2996 104.97 54.9972 105.214 54.6444 105.365C54.2916 105.508 53.9052 105.55 53.5272 105.474C53.1492 105.398 52.8048 105.214 52.536 104.945C52.2672 104.676 52.0824 104.332 52.0068 103.954C51.9312 103.576 51.9732 103.189 52.116 102.836C52.2588 102.484 52.5108 102.181 52.83 101.971C53.1492 101.761 53.5188 101.644 53.9052 101.644C54.1572 101.644 54.4092 101.694 54.6444 101.786C54.8796 101.887 55.098 102.03 55.2744 102.206C55.4508 102.383 55.5936 102.601 55.6944 102.836C55.7952 103.072 55.8372 103.324 55.8372 103.576Z"
        fill="#404040"
      />
      <Path
        d="M24.5725 115.882H83.2297C84.1033 115.882 84.9685 115.655 85.7329 115.21C86.4973 114.773 87.1273 114.143 87.5641 113.379C88.0009 112.623 88.2361 111.757 88.2361 110.875C88.2361 109.993 88.0009 109.137 87.5641 108.372L58.2565 57.5774C57.8113 56.8214 57.1813 56.183 56.4169 55.7462C55.6525 55.3094 54.7957 55.0742 53.9137 55.0742C53.0317 55.0742 52.1749 55.301 51.4105 55.7462C50.6461 56.183 50.0161 56.813 49.5709 57.5774L20.2465 108.372C19.8097 109.128 19.5745 109.993 19.5745 110.875C19.5745 111.757 19.8013 112.614 20.2465 113.379C20.6833 114.135 21.3133 114.773 22.0777 115.21C22.8421 115.647 23.6989 115.882 24.5809 115.882H24.5725Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M55.8372 103.576C55.8372 103.962 55.7196 104.332 55.5096 104.651C55.2996 104.97 54.9972 105.214 54.6444 105.365C54.2916 105.508 53.9052 105.55 53.5272 105.474C53.1492 105.398 52.8048 105.214 52.536 104.945C52.2672 104.676 52.0824 104.332 52.0068 103.954C51.9312 103.576 51.9732 103.189 52.116 102.836C52.2588 102.484 52.5108 102.181 52.83 101.971C53.1492 101.761 53.5188 101.644 53.9052 101.644C54.1572 101.644 54.4092 101.694 54.6444 101.786C54.8796 101.887 55.098 102.03 55.2744 102.206C55.4508 102.383 55.5936 102.601 55.6944 102.836C55.7952 103.072 55.8372 103.324 55.8372 103.576V103.576Z"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M58.6596 81.585C55.1904 80.5602 51.5112 80.4846 48.0084 81.375C44.5056 82.2654 41.3052 84.0882 38.7432 86.6418"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M53.6449 87.9518C51.6457 87.851 49.6465 88.1702 47.7817 88.8926C45.9169 89.615 44.2201 90.7238 42.8005 92.135"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M67.1521 87.3642C66.5137 86.7258 65.8417 86.1378 65.1277 85.5918"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M63.095 92.1349C62.3222 91.3621 61.4654 90.6734 60.533 90.0938"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M59.0376 98.3514C57.9456 97.251 56.5764 96.4782 55.0728 96.0918"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M66.5809 78.6025L39.3145 110.17"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M200.855 46.498C200.855 46.6828 200.779 46.8592 200.653 46.9936C200.519 47.128 200.342 47.1952 200.158 47.1952C199.973 47.1952 199.796 47.1196 199.662 46.9936C199.528 46.8592 199.46 46.6828 199.46 46.498C199.46 46.3132 199.536 46.1368 199.662 46.0024C199.788 45.868 199.973 45.8008 200.158 45.8008C200.342 45.8008 200.519 45.8764 200.653 46.0024C200.788 46.1368 200.855 46.3132 200.855 46.498Z"
        fill="#404040"
      />
      <Path
        d="M192.471 99.7873C192.471 100.249 192.337 100.703 192.077 101.081C191.816 101.467 191.455 101.761 191.027 101.938C190.598 102.114 190.128 102.156 189.674 102.072C189.221 101.98 188.809 101.761 188.481 101.434C188.154 101.106 187.935 100.695 187.843 100.241C187.751 99.7873 187.801 99.3169 187.977 98.8969C188.154 98.4685 188.456 98.1073 188.834 97.8469C189.221 97.5865 189.666 97.4521 190.128 97.4521C190.749 97.4521 191.337 97.6958 191.774 98.1326C192.211 98.5694 192.455 99.1657 192.455 99.7873H192.471Z"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M106.052 2.12882C106.052 2.45642 105.952 2.77562 105.775 3.05282C105.59 3.32162 105.338 3.54002 105.036 3.66602C104.734 3.79202 104.398 3.82562 104.078 3.75842C103.759 3.69122 103.465 3.54002 103.23 3.30482C102.995 3.06962 102.844 2.77562 102.776 2.45642C102.709 2.13722 102.743 1.80122 102.869 1.49882C102.995 1.19642 103.205 0.936022 103.482 0.759622C103.751 0.574822 104.078 0.482422 104.406 0.482422C104.624 0.482422 104.843 0.524422 105.036 0.608422C105.238 0.692422 105.422 0.810022 105.574 0.969622C105.725 1.12082 105.851 1.30562 105.935 1.50722C106.019 1.70882 106.061 1.92722 106.061 2.14562L106.052 2.12882Z"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M1.35474 58.8457H4.66434"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10.5107 58.8369H13.8035"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M7.5791 52.6211V55.9139"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M7.58765 61.7773V65.0701"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M3.16919 54.4355L5.50439 56.7707"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M9.65405 60.9033L11.9893 63.2385"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.9893 54.4355L9.65405 56.7707"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5.52124 60.9209L3.18604 63.2561"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M200.157 34.292V43.6664"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M67.866 142.417V145.357"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M73.5948 148.146H70.6548"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M67.866 153.867V150.927"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M62.1455 148.146H65.0855"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M70.6547 148.146C70.2851 148.146 69.9238 148.07 69.5878 147.936C69.2518 147.801 68.9411 147.591 68.6807 147.331C68.4203 147.07 68.2187 146.768 68.0759 146.432C67.9331 146.096 67.8659 145.735 67.8659 145.365C67.8659 145.735 67.7987 146.096 67.6559 146.432C67.5131 146.768 67.3115 147.079 67.0511 147.331C66.7907 147.591 66.4883 147.793 66.1523 147.936C65.8163 148.078 65.455 148.146 65.0854 148.146C65.455 148.146 65.8163 148.213 66.1523 148.356C66.4883 148.498 66.7991 148.7 67.0511 148.96C67.3115 149.221 67.5131 149.523 67.6559 149.859C67.7987 150.195 67.8659 150.556 67.8659 150.926C67.8659 150.565 67.9331 150.195 68.0759 149.859C68.2187 149.523 68.4203 149.212 68.6807 148.96C68.9411 148.7 69.2434 148.498 69.5878 148.356C69.9238 148.213 70.2851 148.146 70.6547 148.146Z"
        stroke="#2E2E2E"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_10403_64218">
        <Rect
          width={199.895}
          height={154.182}
          fill="white"
          transform="translate(0.959961 0.0791016)"
        />
      </ClipPath>
    </Defs>
  </Svg>
)

export const PageNoConnection = memo(PageNoConnectionIllustration)
