import { forwardRef, useCallback, useRef, useState } from 'react'

import { Calendar, Icons } from 'atoms'
import { Dropdown, DropdownRefProps } from 'atoms/web/Dropdown'
import { CustomBottomSheet, BottomSheetModal } from 'molecules'
import { VStack, Text, HStack, useTheme, FormControl, Button } from 'native-base'
import { TouchableOpacity, Platform } from 'react-native'

import { useViewTypeSheet } from './hook'
import { BottomSheetOrganizationMenuProps } from './types'
import { ViewType } from '../../OrganizationScreen.types'

export const ViewTypeSheet = forwardRef<BottomSheetModal, BottomSheetOrganizationMenuProps>(
  ({ handleSelect, currentViewType, viewTypes }, ref) => {
    const theme = useTheme()

    const calendarRef = useRef<BottomSheetModal>(null)

    const calendarPeriodRef = useRef<BottomSheetModal>(null)

    const menuCalendarRef = useRef<DropdownRefProps>(null)

    const closeMenu = () => {
      menuCalendarRef?.current?.close()
    }

    const handleOpenCalendar = useCallback(() => {
      calendarRef.current?.present()
    }, [])

    const handleCloseCalendar = useCallback(() => {
      calendarRef.current?.close()
    }, [])

    const handleOpenCalendarPeriod = useCallback(() => {
      calendarPeriodRef.current?.present()
    }, [])

    const handleCloseCalendarPeriod = useCallback(() => {
      calendarPeriodRef.current?.close()
    }, [])

    const [currentDate, setCurrentDate] = useState<string>()

    const [startingDay, setStartingDay] = useState<string | undefined>()

    const [endingDay, setEndingDay] = useState<string | undefined>()

    const handleAddedDate = (vision: ViewType, selectedDate?: Date) => {
      if (endingDay && vision.group === 11) {
        // @ts-ignore
        handleSelect(vision, selectedDate?.toISOString() ?? currentDate, {
          startingDay,
          endingDay
        })
      } else {
        setStartingDay(undefined)

        setEndingDay(undefined)

        handleSelect(vision, selectedDate?.toISOString() ?? currentDate)
      }

      handleCloseCalendar()

      handleCloseCalendarPeriod()
    }

    const { isMobile, getActivitiesByPeriod, datesWithActivities, isLoading } = useViewTypeSheet()

    return (
      <>
        {!isMobile ? (
          <HStack space={2}>
            {viewTypes.map((vision) => {
              return (
                <HStack>
                  <FormControl>
                    {vision.group === 3 ? (
                      Platform.OS === 'web' ? (
                        <Button
                          bg={
                            currentViewType.group === vision.group
                              ? theme.colors.primary[50]
                              : 'white'
                          }
                          px={5}
                          _hover={{
                            bg: theme.colors.primary[50]
                          }}
                          _pressed={{
                            bg: theme.colors.primary[50]
                          }}
                          h={10}
                          maxW="md"
                          justifyContent="center"
                          borderRadius="full"
                          onPress={() => {
                            if (vision.group === 3) {
                              handleOpenCalendar()

                              getActivitiesByPeriod()
                            } else {
                              handleSelect(vision)

                              setStartingDay(undefined)

                              setEndingDay(undefined)
                            }
                          }}
                          key={vision.title}>
                          <HStack alignItems="center">
                            <Text
                              fontSize="16px"
                              lineHeight="22px"
                              color={
                                currentViewType.group === vision.group ? 'primary.500' : 'black'
                              }
                              fontWeight="normal">
                              {vision.title}
                            </Text>
                          </HStack>
                        </Button>
                      ) : (
                        <Dropdown
                          ref={menuCalendarRef}
                          label={vision.title}
                          active={currentViewType.group === vision.group}
                          w={484}
                          renderItems={
                            <Calendar.Root
                              isLoading={isLoading || datesWithActivities === undefined}>
                              <Calendar.Title title="Definir uma data" />
                              <Calendar.Body
                                daySelected={currentDate}
                                onDaySelected={setCurrentDate}
                                markedDates={datesWithActivities}
                              />
                              <Calendar.Footer>
                                <Button
                                  ml={2}
                                  flex={1}
                                  style={{
                                    opacity: !currentDate ? 0.3 : 1
                                  }}
                                  colorScheme="gray"
                                  disabled={!currentDate}
                                  onPress={() => {
                                    handleAddedDate({
                                      group: 3,
                                      title: 'Visão por data',
                                      icon: 'CalendarPlus'
                                    })

                                    closeMenu()
                                  }}>
                                  Confirmar
                                </Button>
                              </Calendar.Footer>
                            </Calendar.Root>
                          }
                        />
                      )
                    ) : (
                      <Button
                        bg={
                          currentViewType.group === vision.group
                            ? theme.colors.primary[50]
                            : 'white'
                        }
                        px={5}
                        _hover={{
                          bg: theme.colors.primary[50]
                        }}
                        _pressed={{
                          bg: theme.colors.primary[50]
                        }}
                        h={10}
                        maxW="md"
                        justifyContent="center"
                        borderRadius="full"
                        onPress={() => {
                          if (vision.group === 11) {
                            handleOpenCalendarPeriod()

                            getActivitiesByPeriod()
                          } else {
                            handleSelect(vision)

                            setCurrentDate(undefined)

                            setStartingDay(undefined)

                            setEndingDay(undefined)
                          }
                        }}
                        key={vision.title}>
                        <HStack alignItems="center">
                          <Text
                            fontSize="16px"
                            lineHeight="22px"
                            color={currentViewType.group === vision.group ? 'primary.500' : 'black'}
                            fontWeight="normal">
                            {vision.title}
                          </Text>
                        </HStack>
                      </Button>
                    )}
                  </FormControl>
                </HStack>
              )
            })}
          </HStack>
        ) : (
          <CustomBottomSheet
            ref={ref}
            snapPoints={[viewTypes.length < 4 ? viewTypes.length * 75 : viewTypes.length * 65]}>
            <VStack py={6} p={0} w="full">
              {viewTypes.map((vision) => {
                const IconComponent = Icons[vision.icon] as typeof Icons.Add

                return (
                  <>
                    <FormControl>
                      <TouchableOpacity
                        onPress={() => {
                          if (vision.group === 3) {
                            handleOpenCalendar()

                            getActivitiesByPeriod()
                          } else if (vision.group === 11) {
                            handleOpenCalendarPeriod()

                            getActivitiesByPeriod()

                            setCurrentDate(undefined)
                          } else {
                            handleSelect(vision)

                            setStartingDay(undefined)

                            setEndingDay(undefined)
                          }
                        }}
                        key={vision.title}>
                        <HStack px={6} pb={8}>
                          <IconComponent
                            color={
                              currentViewType.group === vision.group
                                ? theme.colors.primary[500]
                                : theme.colors.gray[700]
                            }
                            size={4}
                            m={0.5}
                            mr={4}
                          />
                          <Text
                            fontSize="14px"
                            lineHeight="20px"
                            color="black"
                            fontWeight={currentViewType.group === vision.group ? 'bold' : 'normal'}>
                            {vision.title}
                          </Text>
                        </HStack>
                      </TouchableOpacity>
                    </FormControl>
                  </>
                )
              })}
            </VStack>
          </CustomBottomSheet>
        )}

        <Calendar.Apresentation ref={calendarRef}>
          <Calendar.Title title="Definir uma data" />
          <Calendar.Body
            markedDates={datesWithActivities}
            daySelected={currentDate}
            onDaySelected={setCurrentDate}
          />
          <Calendar.Footer>
            <Button
              flex={1}
              colorScheme="gray"
              isDisabled={!currentDate}
              onPress={() => {
                handleAddedDate({
                  group: 3,
                  title: 'Visão por data',
                  icon: 'CalendarPlus'
                })
              }}>
              Confirmar
            </Button>
          </Calendar.Footer>
        </Calendar.Apresentation>

        <Calendar.Apresentation
          ref={calendarPeriodRef}
          isLoading={isLoading || datesWithActivities === undefined}>
          <Calendar.Title title="Selecione um período" />
          <Calendar.Header startingDay={startingDay} endingDay={endingDay} />
          <Calendar.Body
            markedDates={datesWithActivities}
            startingDay={startingDay}
            endingDay={endingDay}
            onStartingDaySelect={setStartingDay}
            onEndingDaySelect={setEndingDay}
            markingType="period"
          />
          <Calendar.Footer>
            <Button
              flex={1}
              colorScheme="gray"
              isDisabled={!startingDay || !endingDay}
              onPress={() => {
                handleAddedDate({
                  group: 11,
                  title: 'Visão por período',
                  icon: 'CalendarPeriod'
                })
              }}>
              Confirmar
            </Button>
          </Calendar.Footer>
        </Calendar.Apresentation>
      </>
    )
  }
)
