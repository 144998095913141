export type TNotReadResponse = {
  not_read_count: number
  wallet: number
  content: number
  organization: number
  opportunity: number
  communication: number
  birthdate: number
  activity: number
  new_wallet: number
}

export type TNotificationChoicesType =
  | 'WALLET'
  | 'CONTENT'
  | 'ORGANIZATION'
  | 'OPPORTUNITY'
  | 'COMMUNICATION'
  | 'BIRTHDATE'
  | 'SIMULATION'
  | 'ACTIVITY'
  | 'NEW_WALLET'

export enum NOTIFICATION_CHOICES {
  WALLET = 'WALLET',
  CONTENT = 'CONTENT',
  ORGANIZATION = 'ORGANIZATION',
  OPPORTUNITY = 'OPPORTUNITY',
  COMMUNICATION = 'COMMUNICATION',
  BIRTHDATE = 'BIRTHDATE',
  SIMULATION = 'SIMULATION',
  ACTIVITY = 'ACTIVITY',
  NEW_WALLET = 'NEW_WALLET'
}

export type TParams<T = Record<string, unknown>> = T & {
  authorSlugs?: string[]
  categorySlugs?: string[]
  mostRead?: boolean
  firstPage?: number
  lastPage?: number
  notificationType?: TNotificationChoicesType
  currentPage?: number
  perPage?: number
  search?: string
}

export type TNotificationType =
  | 'WALLET'
  | 'CONTENT'
  | 'ORGANIZATION'
  | 'OPPORTUNITY'
  | 'COMMUNICATION'
  | 'BIRTHDATE'
  | 'APP_VERSION'

export type TNotification = {
  id: string
  title: string
  description: string
  body: string
  extras: {
    contentId?: string
    surveyId?: string
    contactId?: string
  }
  notification_type_enum: TNotificationType
  is_read: boolean
  created_at: string
  updated_at: string
}

export enum NOTIFICATION_TYPES {
  WALLET = 'WALLET',
  CONTENT = 'CONTENT',
  ORGANIZATION = 'ORGANIZATION',
  OPPORTUNITY = 'OPPORTUNITY',
  COMMUNICATION = 'COMMUNICATION',
  BIRTHDAY = 'BIRTHDATE'
}
