import { createRef, FunctionComponent, Ref, useCallback, useMemo, useRef } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Icons } from 'atoms'
import { CreateAccountPayload } from 'integration/resources/createAccount'
import { ButtonZendesk } from 'molecules'
import { Button, FormControl, Heading, HStack, Text } from 'native-base'
import { BottomSheetConfirmData, MessageActionSheet } from 'organisms'
import { TBottomSheetHandleRef } from 'organisms/BottomSheetConfirmData'
import Layouts from 'organisms/Layouts'
import { TMessageActionSheetHandleRef } from 'organisms/MessageActionSheet'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard, TextInput } from 'react-native'
import { validatePassword } from 'src/utils/validators'
import * as yup from 'yup'

import { InputPassword } from '../../atoms/InputPassword/InputPassword'

type CreatePasswordScreenTemplateProps = {
  isSubmitting?: boolean
  userData?: Pick<CreateAccountPayload, 'cpf' | 'email' | 'phone_number_cell'>
  submit(
    values: CreatePasswordFormData,
    refBottomSheetConfirm: Ref<TBottomSheetHandleRef> | undefined,
    refMessageActionSheet: Ref<TMessageActionSheetHandleRef> | undefined
  ): void
  handleButtonOkMessage?(): void
  isResetPassword?: boolean
}

export type CreatePasswordFormData = {
  password: string
  password_confirmation: string
}

const confirmPasswordInputRef = createRef<TextInput>()

const focusOnConfirmPasswordInput = () => confirmPasswordInputRef.current?.focus()

const schema = yup.object().shape({
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'As senhas não conferem')
    .required('Campo confirmar a senha é obrigatório'),
  password: yup
    .string()
    .min(8, 'Senha deve conter no mínimo 8 caracteres')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*@#!+=%^])[0-9a-zA-Z$*@#!+=%^]{8,}$/,
      'Senha não atinge os requisitos mínimos'
    )
    .required('Campo senha é obrigatório')
})

export const CreatePasswordScreenTemplate: FunctionComponent<CreatePasswordScreenTemplateProps> = ({
  isSubmitting,
  submit,
  userData,
  handleButtonOkMessage,
  isResetPassword = false
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid }
  } = useForm<CreatePasswordFormData>({
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  const refBottomSheetConfirm = useRef<TBottomSheetHandleRef>(null)

  const refActionSheetMessage = useRef<TMessageActionSheetHandleRef>(null)

  const passwordValue = watch('password')

  const passwordRules = useMemo(
    () =>
      Object.entries(validatePassword(passwordValue)).map((item) => (
        <HStack alignItems="center" key={Math.random()}>
          <Icons.Validator
            {...(item[1] !== null
              ? { color: item[1] ? 'success.500' : 'error.500' }
              : { color: 'muted.500' })}
            mr={2}
            mt={2}
            size={2}
          />
          <Text
            {...(item[1] !== null
              ? { color: item[1] ? 'success.500' : 'error.500' }
              : { color: 'muted.500' })}
            fontSize="xs"
            key={item[0]}
            mt={2}>
            {item[0]}
          </Text>
        </HStack>
      )),
    [passwordValue]
  )

  const onSubmit = handleSubmit(
    useCallback<SubmitHandler<CreatePasswordFormData>>(
      (formData) => {
        Keyboard.dismiss()

        submit(formData, refBottomSheetConfirm, refActionSheetMessage)
      },
      [submit]
    )
  )

  return (
    <>
      <Layouts.External>
        <Controller
          control={control}
          render={({ field: { onChange: onChangeText, onBlur, value }, formState: { errors } }) => (
            <FormControl isInvalid={!!errors.password} isRequired>
              <Heading mb={6} mt={4}>
                Escolha uma senha
              </Heading>
              <InputPassword
                {...{ onChangeText, onBlur, value }}
                enablesReturnKeyAutomatically
                onSubmitEditing={focusOnConfirmPasswordInput}
                returnKeyType="next"
                testID="inputPassword"
              />
              {errors.password && (
                <FormControl.ErrorMessage>{errors.password.message}</FormControl.ErrorMessage>
              )}
              {passwordRules}
            </FormControl>
          )}
          name="password"
        />
        <Controller
          control={control}
          render={({ field: { onChange: onChangeText, onBlur, value }, formState: { errors } }) => (
            <FormControl isInvalid={!!errors.password_confirmation} isRequired>
              <Text color="gray.500" fontSize="md" lineHeight="md" mt={8} mb={2}>
                Confirme a senha
              </Text>
              <InputPassword
                {...{ onChangeText, onBlur, value }}
                enablesReturnKeyAutomatically
                onSubmitEditing={onSubmit}
                returnKeyType="next"
                ref={confirmPasswordInputRef}
                testID="inputConfirmPassword"
              />
              {errors.password_confirmation && (
                <FormControl.ErrorMessage>
                  {errors.password_confirmation.message}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
          )}
          name="password_confirmation"
        />
        <Button
          colorScheme="gray"
          isDisabled={!isValid}
          isLoading={isSubmitting}
          mt={8}
          onPress={isResetPassword ? onSubmit : refBottomSheetConfirm.current?.onToggle}
          testID="confirmButton">
          Confirmar senha
        </Button>

        <BottomSheetConfirmData
          ref={refBottomSheetConfirm}
          userData={userData}
          isLoading={isSubmitting}
          handleConfirmButton={onSubmit}
        />

        {handleButtonOkMessage && (
          <MessageActionSheet
            ref={refActionSheetMessage}
            title="Perfil cadastrado"
            description="Este CPF já está em uso. Recupere sua senha ou faça o login."
            titleButtonOk="Voltar para o login"
            handleButtonOk={handleButtonOkMessage}
            hiddenBackButton
          />
        )}
      </Layouts.External>

      <ButtonZendesk />
    </>
  )
}
