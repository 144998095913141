import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const EyeIcon: FunctionComponent<IIconProps & { closed?: boolean }> = ({
  closed,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    {closed ? (
      <>
        <Path
          d="M11.8002 17.8001C7.48023 17.8001 3.63023 14.1501 2.17023 12.5701C2.06023 12.4601 2.00023 12.3101 2.00023 12.1501C2.00023 11.9901 2.06023 11.8401 2.17023 11.7201C2.75023 11.0901 3.38023 10.4901 4.04023 9.94006C4.46023 9.59006 4.52023 8.95006 4.16023 8.53006C3.81023 8.11006 3.18023 8.05006 2.75023 8.41006C2.03023 9.01006 1.34023 9.67006 0.710234 10.3501C0.250234 10.8401 -0.00976562 11.4701 -0.00976562 12.1401C-0.00976562 12.8101 0.240234 13.4501 0.700234 13.9301C2.73023 16.1201 6.86023 19.7901 11.7902 19.7901C12.3402 19.7901 12.7902 19.3401 12.7902 18.7901C12.7902 18.2401 12.3402 17.7901 11.7902 17.7901L11.8002 17.8001Z"
          fill="currentColor"
        />
        <Path
          d="M18.4302 17.4201C19.1802 17.0201 20.1302 16.4701 20.8202 15.9301C21.6802 15.2501 22.2402 14.6501 22.8802 13.9501C23.3402 13.4601 23.6002 12.8301 23.6002 12.1601C23.6002 11.4901 23.3502 10.8501 22.9202 10.4101C20.7502 7.84006 17.2102 4.45006 11.7702 4.61006C10.0802 4.66006 7.89023 5.42006 6.83023 5.82006L2.31023 1.29006C1.92023 0.900059 1.28023 0.900059 0.890234 1.29006C0.500234 1.68006 0.500234 2.31006 0.890234 2.70006L21.2902 23.1101C21.4902 23.3101 21.7402 23.4001 22.0002 23.4001C22.2602 23.4001 22.5102 23.3001 22.7102 23.1101C23.1002 22.7201 23.1002 22.0901 22.7102 21.7001L18.4302 17.4301V17.4201ZM11.8302 6.60006C15.3602 6.49006 18.3302 8.06006 21.4302 11.7401C21.5402 11.8501 21.6002 12.0001 21.6002 12.1601C21.6002 12.3201 21.5402 12.4601 21.4302 12.5801C21.4302 12.5801 21.3602 12.6601 21.3502 12.6601C20.7702 13.2901 20.3102 13.7801 19.5702 14.3601C18.8202 14.9501 17.6302 15.5901 16.9402 15.9401L8.39023 7.38006C9.41023 7.03006 10.7802 6.63006 11.8302 6.60006Z"
          fill="currentColor"
        />
      </>
    ) : (
      <>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.701 6.00487C11.7665 5.99838 11.8326 5.99838 11.8981 6.00487C16.1786 6.42886 20.1259 8.50129 22.9054 11.7832C23.3511 12.2649 23.5991 12.8973 23.5991 13.5544C23.5991 14.2199 23.3447 14.8602 22.888 15.3442C21.9785 16.3256 20.4824 17.7731 18.5965 18.9816C16.7134 20.1882 14.3734 21.2 11.7995 21.2C9.22568 21.2 6.88574 20.1882 5.00263 18.9816C3.11666 17.7731 1.62064 16.3256 0.711057 15.3442C0.254436 14.8602 0 14.2199 0 13.5544C0 12.8973 0.247997 12.2649 0.693711 11.7832C3.4732 8.5013 7.42051 6.42886 11.701 6.00487ZM11.7995 8.00523C8.06641 8.40064 4.62859 10.2227 2.20525 13.0931C2.19285 13.1078 2.18003 13.1221 2.1668 13.1361C2.0597 13.249 2 13.3987 2 13.5544C2 13.7101 2.0597 13.8598 2.1668 13.9727L2.17479 13.9812C3.01755 14.8909 4.38565 16.2109 6.08167 17.2976C7.78296 18.3878 9.74622 19.2 11.7995 19.2C13.8529 19.2 15.8161 18.3878 17.5174 17.2976C19.2134 16.2109 20.5816 14.8909 21.4244 13.9812L21.4322 13.9727C21.5393 13.8597 21.5991 13.7101 21.5991 13.5544C21.5991 13.3987 21.5394 13.249 21.4323 13.1361C21.4191 13.1221 21.4062 13.1078 21.3938 13.0931C18.9705 10.2227 15.5327 8.40064 11.7995 8.00523Z"
          fill="currentColor"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.97112 10.7716C9.72127 10.0214 10.7387 9.6 11.7995 9.6C12.8604 9.6 13.8778 10.0214 14.628 10.7716C15.3781 11.5217 15.7995 12.5391 15.7995 13.6C15.7995 14.6609 15.3781 15.6783 14.628 16.4284C13.8778 17.1786 12.8604 17.6 11.7995 17.6C10.7387 17.6 9.72127 17.1786 8.97112 16.4284C8.22098 15.6783 7.79955 14.6609 7.79955 13.6C7.79955 12.5391 8.22098 11.5217 8.97112 10.7716ZM11.7995 11.6C11.2691 11.6 10.7604 11.8107 10.3853 12.1858C10.0103 12.5609 9.79955 13.0696 9.79955 13.6C9.79955 14.1304 10.0103 14.6391 10.3853 15.0142C10.7604 15.3893 11.2691 15.6 11.7995 15.6C12.33 15.6 12.8387 15.3893 13.2138 15.0142C13.5888 14.6391 13.7995 14.1304 13.7995 13.6C13.7995 13.0696 13.5888 12.5609 13.2138 12.1858C12.8387 11.8107 12.33 11.6 11.7995 11.6Z"
          fill="currentColor"
        />
      </>
    )}
  </Icon>
)
