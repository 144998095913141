import { Icons, LoadingTurbo } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { AccordionCard } from 'atoms/web/Card/AccordionCard'
import { HeadPage } from 'atoms/web/HeadPage'
import { WalletItem } from 'integration/resources/wallet'
import {
  Center,
  HStack,
  Heading,
  Skeleton,
  Text,
  VStack,
  View,
  useBreakpointValue
} from 'native-base'
import Layouts from 'organisms/Layouts'
import { FlatList, Linking, Platform, RefreshControl, TouchableOpacity } from 'react-native'
import { formatPhoneNumberWithMask } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

import { WalletTabFilters } from './Tab/Filters'
import { useWalletScreen } from './useWalletScreen'
import { WalletTabScreenComponent } from './WalletScreen.types'

const WalletTabScreenDesktop: WalletTabScreenComponent = ({ navigation, route }) => {
  const {
    list,
    params,
    totalTabs,
    isLoading,
    onEndReached,
    onRefresh,
    filtersSize,
    isFetchingNextPage,
    handleChangeFilters,
    handleToggleFavorite,
    handleGoToDetailsScreen
    // @ts-ignore
  } = useWalletScreen({ navigation, route })

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const emptyCondition = !list.length && !isLoading

  const handleOpenGps = (contact: WalletItem) => {
    if (contact) {
      const fullAddress = contact.secondary_address_street
        ? `${contact.secondary_address_street}, ${contact.secondary_address_number} - ${contact.secondary_address_neighborhood} ${contact.secondary_address_city} - ${contact.secondary_address_uf}, ${contact.secondary_address_postal_code}`
        : `${contact.address_street}, ${contact.address_number} - ${contact.address_neighborhood} ${contact.address_city} - ${contact.address_uf}, ${contact.address_postal_code}`

      const url = Platform.select({
        ios: `maps:0,0?q=${fullAddress}`,
        android: `geo:0,0?q=${fullAddress}`,
        web: `https://www.google.com/maps/search/?api=1&query=${fullAddress}`
      }) as string

      Linking.openURL(url)
    }
  }

  const handleSendWhatsapp = (contact: WalletItem) => {
    const urlWhatsApp = isMobile
      ? `whatsapp://send?phone=+55${contact?.phone_number_ddd}${contact?.phone_number}&text=Olá ${contact?.name}`
      : `https://wa.me/55${contact?.phone_number_ddd}${contact?.phone_number}?text=Olá%20${contact?.name}`

    const urlSms = `sms:${contact?.phone_number_ddd}${contact?.phone_number}`

    Linking.canOpenURL(urlWhatsApp)
      .then((supported) => {
        if (supported) {
          return Linking.openURL(urlWhatsApp)
        } else {
          Linking.openURL(urlSms)
        }
      })
      .catch(() => Linking.openURL(urlSms))
  }

  return (
    <>
      <Layouts.InternalDesktop
        search={params.search}
        onSearch={(search) => {
          handleChangeFilters({
            search
          })
        }}>
        <View h="full" flex={1} px={8}>
          <FlatList
            data={[
              {
                key: 1
              }
            ]}
            onEndReached={onEndReached}
            refreshControl={
              <RefreshControl
                refreshing={isLoading ?? false}
                onRefresh={onRefresh}
                tintColor="white"
              />
            }
            showsVerticalScrollIndicator={false}
            ListHeaderComponent={
              <VStack mb={8}>
                <HeadPage
                  title="Carteira"
                  pages={[
                    {
                      id: 1,
                      title: 'Carteira',
                      link: 'carteira/',
                      params: null
                    },
                    {
                      id: 2,
                      title: 'TABs',
                      link: 'carteira/tabs',
                      params: null
                    }
                  ]}
                />

                <WalletTabFilters
                  initialValues={params}
                  onChange={(filters) => {
                    handleChangeFilters({
                      subsegment_code: filters.subsegment.selected ?? undefined,
                      subSegment_name: filters.subsegment_name.selected ?? undefined,
                      status: filters.status.selected,
                      is_favorite: filters.favorites.selected ? 1 : undefined
                    })
                  }}
                />
              </VStack>
            }
            renderItem={() => (
              <View flex={1} background="background.base">
                {isLoading ? (
                  <Skeleton w={32} h={6} />
                ) : (
                  <Heading
                    fontSize="18px"
                    lineHeight="24px"
                    fontWeight="400"
                    color="gray.700"
                    mb={6}>
                    TABs {!!Number(totalTabs) && totalTabs}
                  </Heading>
                )}

                {isLoading && (
                  <>
                    {new Array(6).fill(0).map((_, i) => (
                      <SkeletonCard key={_ + i} />
                    ))}
                  </>
                )}

                {emptyCondition && (
                  <Center pt={4}>
                    {!!filtersSize && (
                      <>
                        <Text fontSize="16px" lineHeight="24px" color="gray.500">
                          Nenhum resultado encontrado.
                        </Text>
                        <Text fontSize="16px" lineHeight="24px" color="gray.500">
                          Revise o filtro.
                        </Text>
                      </>
                    )}
                  </Center>
                )}

                {!isLoading &&
                  list.map((item) => {
                    return (
                      <AccordionCard
                        key={item.id}
                        id={item.identifier ?? ''}
                        isFavorite={item.is_favorite}
                        onViewMoreClick={() => handleGoToDetailsScreen(item.tab_number)}
                        onFavoriteClick={handleToggleFavorite}
                        coverImageURL={item.profile_image_url ?? item.logo_image_url ?? item.image}
                        title={item.name}
                        subtitle={
                          <HStack alignItems="center" space={4}>
                            <Text fontSize={16}>TAB {item.tab_number}</Text>
                            <View w={1} h={1} bgColor="gray.300" borderRadius={2} />

                            {!!item.potential && (
                              <Text color="gray.700" fontSize={16}>
                                Potencial{' '}
                                <Text color="gray.700" bold fontSize={16}>
                                  R$ {Number(item.potential).toLocaleString('pt-br')}
                                </Text>
                              </Text>
                            )}
                          </HStack>
                        }
                        actions={
                          <>
                            {!!item.web_site && (
                              <TouchableOpacity
                                onPress={() => {
                                  LogEvent(`carteira_tabs_website`, {
                                    item_id: item.id
                                  })

                                  window.open(item.web_site!, '_blank')
                                }}>
                                <Center
                                  borderRadius="full"
                                  borderColor="gray.500"
                                  borderWidth={1}
                                  w={10}
                                  h={10}>
                                  <Icons.Website color="gray.500" size={5} />
                                </Center>
                              </TouchableOpacity>
                            )}

                            <TouchableOpacity
                              onPress={() => {
                                LogEvent(`carteira_tabs_email`, {
                                  item_id: item.id
                                })

                                Linking.openURL(`mailto:${item.email}`)
                              }}>
                              <Center
                                borderRadius="full"
                                borderColor="gray.500"
                                borderWidth={1}
                                w={10}
                                h={10}>
                                <Icons.Mail filled color="gray.500" size={5} />
                              </Center>
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={() => {
                                LogEvent(`carteira_tabs_telefone`, {
                                  item_id: item.id
                                })

                                Linking.openURL(`tel:${item.phone_number_ddd}${item.phone_number}`)
                              }}>
                              <Center
                                borderRadius="full"
                                borderColor="gray.500"
                                borderWidth={1}
                                w={10}
                                h={10}>
                                <Icons.Phone filled color="gray.500" size={5} />
                              </Center>
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={() => {
                                LogEvent(`carteira_tabs_gps`, {
                                  item_id: item.id
                                })

                                handleOpenGps(item)
                              }}>
                              <Center
                                borderRadius="full"
                                borderColor="gray.500"
                                borderWidth={1}
                                w={10}
                                h={10}>
                                <Icons.Location filled color="gray.500" size={5} />
                              </Center>
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={() => {
                                LogEvent(`carteira_tabs_whatsapp`, {
                                  item_id: item.id
                                })

                                handleSendWhatsapp(item)
                              }}>
                              <Center
                                borderRadius="full"
                                borderColor="gray.500"
                                borderWidth={1}
                                w={10}
                                h={10}>
                                <Icons.Chat filled color="gray.500" size={5} />
                              </Center>
                            </TouchableOpacity>
                          </>
                        }
                        details={
                          <>
                            <VStack flex={1} py={6} space={6} w="1/2">
                              <HStack alignItems="center" space={4}>
                                <Icons.Website color="gray.400" size={5} />

                                <Text
                                  fontSize="15px"
                                  lineHeight="24px"
                                  fontWeight="normal"
                                  color="gray.700">
                                  {item.web_site}
                                </Text>
                              </HStack>

                              <HStack alignItems="center" space={4}>
                                <Icons.Phone color="gray.400" size={5} />

                                <Text
                                  fontSize="15px"
                                  lineHeight="24px"
                                  fontWeight="normal"
                                  color="gray.700">
                                  {formatPhoneNumberWithMask(
                                    item?.phone_number_ddd ?? '',
                                    item?.phone_number ?? ''
                                  )}
                                </Text>
                              </HStack>

                              <HStack alignItems="center" space={4}>
                                <Icons.Mail color="gray.400" size={5} />

                                <Text
                                  fontSize="15px"
                                  lineHeight="24px"
                                  fontWeight="normal"
                                  color="gray.700">
                                  {item.email}
                                </Text>
                              </HStack>
                            </VStack>

                            <VStack flex={1} py={6} space={6} w="1/2">
                              <HStack alignItems="center" space={4}>
                                <Icons.Tab color="gray.400" size={5} />

                                <Text
                                  fontSize="15px"
                                  lineHeight="24px"
                                  fontWeight="normal"
                                  color="gray.700">
                                  TAB {item.tab_number} •{' '}
                                  <Text textTransform="capitalize">
                                    {item.name_fantasy?.toLocaleLowerCase()}
                                  </Text>
                                </Text>
                              </HStack>

                              <HStack alignItems="center" space={4}>
                                <Icons.Location color="gray.400" size={5} />

                                <Text
                                  fontSize="15px"
                                  lineHeight="24px"
                                  fontWeight="normal"
                                  color="gray.700">
                                  {item.address_street}, {item.address_number} -{' '}
                                  {item.address_neighborhood} - {item.address_city} -{' '}
                                  {item.address_uf}
                                </Text>
                              </HStack>
                            </VStack>
                          </>
                        }
                      />
                    )
                  })}

                {isFetchingNextPage && (
                  <Center mt={2}>
                    <LoadingTurbo size={48} />
                  </Center>
                )}
              </View>
            )}
            keyExtractor={(item) => `${item.key}`}
          />
        </View>
      </Layouts.InternalDesktop>
    </>
  )
}

export default WalletTabScreenDesktop
