import Svg, { Path, Mask, G } from 'react-native-svg'

export const NewUpdateIllustration = () => {
  return (
    <Svg width={201} height={200} viewBox="0 0 201 200" fill="none">
      <Path
        d="M140.3 7.0375C140.3 7.4375 140.2 7.8375 140 8.1375C139.8 8.4375 139.5 8.7375 139.1 8.8375C138.8 8.9375 138.5 9.0375 138.2 8.9375C137.9 8.9375 137.6 8.8375 137.4 8.7375C137.1 8.6375 136.9 8.4375 136.7 8.1375C136.5 7.9375 136.4 7.6375 136.3 7.3375C136.2 6.9375 136.3 6.5375 136.4 6.2375C136.5 5.8375 136.8 5.5375 137.1 5.3375C137.5 5.0375 137.9 4.9375 138.4 5.0375C138.9 5.0375 139.3 5.2375 139.6 5.6375C140.1 5.9375 140.3 6.4375 140.3 7.0375Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M114.4 142.138H89.0001C91.4001 143.538 104.2 151.038 106.2 155.338C107.9 158.838 109.7 170.538 116.9 181.338C117.3 181.938 117.7 182.538 118.2 183.138C118.4 183.438 118.7 183.738 118.9 184.038C119.2 184.438 119.5 184.738 119.8 185.138C120 185.438 120.3 185.638 120.5 185.938C120.8 186.338 121.2 186.738 121.5 187.138C131.2 184.638 145.6 178.038 148.9 176.438C156.3 172.638 163 168.538 167.2 165.238C166.8 164.238 166.4 163.138 166.1 162.138C165.7 161.138 165.3 160.038 164.9 158.938C161.8 150.238 158.7 140.438 156.2 132.138C154.8 127.538 129.3 83.7377 129.3 83.7377V22.3377C129.3 20.4377 128.5 18.5377 127.2 17.2377C125.8 15.9377 124 15.1377 122.1 15.1377H72.3001C71.8001 15.1377 71.2001 15.2377 70.7001 15.3377C70.5001 15.3377 70.4001 15.4377 70.2001 15.4377C69.8001 15.5377 69.3001 15.7377 68.9001 15.9377C68.7001 16.0377 68.5001 16.1377 68.3001 16.3377C67.9001 16.6377 67.5001 16.9377 67.2001 17.2377C66.1001 18.2377 65.5001 19.6377 65.2001 21.0377C65.1001 21.4377 65.1001 21.9377 65.1001 22.3377V129.838C65.1001 130.038 65.1001 130.238 65.1001 130.438C65.1001 130.538 65.1001 130.738 65.1001 130.838C65.1001 130.938 65.1001 131.038 65.1001 131.038C65.1001 131.138 65.1001 131.238 65.2001 131.438C65.2001 131.638 65.3001 131.838 65.3001 131.938C65.4001 132.138 65.4001 132.338 65.5001 132.538V132.638C65.6001 132.838 65.6001 132.938 65.7001 133.038C65.8001 133.138 65.8001 133.338 65.9001 133.438C66.0001 133.538 66.0001 133.638 66.1001 133.838C66.2001 133.938 66.2001 134.038 66.3001 134.138C66.3001 134.238 66.4001 134.238 66.4001 134.338C66.5001 134.438 66.6001 134.538 66.6001 134.638C66.6001 134.738 66.7001 134.738 66.7001 134.838C66.8001 134.938 66.9001 135.038 67.0001 135.138C67.1001 135.238 67.3001 135.438 67.4001 135.538C67.6001 135.638 67.8001 135.838 67.9001 135.938C67.9001 135.938 68.0001 135.938 68.0001 136.038C68.2001 136.138 68.4001 136.238 68.5001 136.338C68.7001 136.438 68.9001 136.538 69.1001 136.638C70.0001 137.038 70.9001 137.238 71.9001 137.238"
        fill="#F5F5F5"
      />
      <Path
        d="M114.4 142.138H72.3002C69.2002 142.138 66.2002 140.937 64.0002 138.737C61.8002 136.537 60.5002 133.537 60.5002 130.437V24.1375C60.5002 22.9375 60.7002 21.7375 61.2002 20.6375C61.3002 20.3375 61.4002 20.1375 61.6002 19.8375C61.9002 19.3375 62.2002 18.8375 62.6002 18.3375C62.8002 18.1375 63.0002 17.8375 63.2002 17.6375C63.4002 17.4375 63.6002 17.2375 63.8002 17.0375C64.3002 16.6375 64.8002 16.3375 65.3002 16.0375C65.6002 15.9375 65.8002 15.7375 66.1002 15.6375C67.2002 15.1375 68.4002 14.9375 69.6002 14.9375H72.4002C71.9002 14.9375 71.3002 15.0375 70.8002 15.1375C70.6002 15.1375 70.5002 15.2375 70.3002 15.2375C70.1002 15.3375 69.9002 15.3375 69.6002 15.4375C69.4002 15.5375 69.2002 15.6375 69.0002 15.7375C68.8002 15.8375 68.6002 15.9375 68.4002 16.1375C68.0002 16.4375 67.6002 16.7375 67.3002 17.0375C66.2002 18.0375 65.6002 19.4375 65.3002 20.8375C65.2002 21.2375 65.2002 21.7375 65.2002 22.1375V129.638C65.2002 129.838 65.2002 130.037 65.2002 130.237C65.2002 130.337 65.2002 130.538 65.2002 130.638C65.2002 130.738 65.2002 130.838 65.2002 130.838C65.2002 130.938 65.2002 131.037 65.3002 131.237C65.3002 131.437 65.4002 131.637 65.4002 131.737C65.5002 131.937 65.5002 132.138 65.6002 132.338V132.437C65.7002 132.637 65.7002 132.738 65.8002 132.838C65.9002 132.938 65.9002 133.137 66.0002 133.237C66.1002 133.437 66.3002 133.737 66.4002 133.937C66.4002 134.037 66.5002 134.038 66.5002 134.138C66.6002 134.238 66.7002 134.337 66.7002 134.437C66.7002 134.537 66.8002 134.538 66.8002 134.638C66.9002 134.738 67.0002 134.837 67.1002 134.937C67.2002 135.037 67.4002 135.238 67.5002 135.338C67.7002 135.438 67.8002 135.637 68.0002 135.737C68.0002 135.737 68.1002 135.738 68.1002 135.838C68.3002 135.938 68.5002 136.038 68.6002 136.138C68.8002 136.238 69.0002 136.337 69.2002 136.437C70.1002 136.837 71.0002 137.037 72.0002 137.037H111.5C112.6 138.737 113.6 140.338 114.4 142.138Z"
        fill="#F5F5F5"
      />
      <Path
        d="M89.0001 142.138H72.3001C69.2001 142.138 66.2001 140.937 64.0001 138.737C61.8001 136.537 60.6001 133.537 60.6001 130.437V24.1375C60.6001 22.9375 60.8001 21.7375 61.3001 20.6375C61.4001 20.3375 61.5001 20.1375 61.7001 19.8375C62.0001 19.3375 62.3001 18.8375 62.7001 18.3375C62.9001 18.1375 63.1001 17.8375 63.3001 17.6375C63.5001 17.4375 63.7001 17.2375 64.0001 17.0375C64.5001 16.6375 64.9001 16.3375 65.5001 16.0375C65.8001 15.9375 66.0001 15.7375 66.3001 15.6375C67.4001 15.2375 68.6001 14.9375 69.8001 14.9375H72.6001"
        fill="#F5F5F5"
      />
      <Path
        d="M171.5 159.538L177.5 171.438C168.3 186.038 128.7 198.238 117.1 195.138L112.8 187.338"
        fill="#F5F5F5"
      />
      <Path
        d="M46.0002 160.237H37.0002V154.237C37.0002 153.037 37.5002 151.937 38.3002 151.037C39.1002 150.237 40.3002 149.737 41.5002 149.737C42.7002 149.737 43.8002 150.237 44.7002 151.037C45.5002 151.837 46.0002 153.037 46.0002 154.237V160.237Z"
        fill="#F5F5F5"
      />
      <Path
        d="M72.0999 21.4373C71.9999 21.6373 71.8999 21.9373 71.8999 22.2373C71.8999 22.5373 71.8999 22.7373 71.9999 23.0373C72.0999 23.2373 72.1999 23.5373 72.3999 23.7373C72.5999 23.9373 72.7999 24.1373 72.9999 24.2373C73.1999 24.3373 73.4999 24.4373 73.7999 24.4373C74.0999 24.4373 74.2999 24.4373 74.5999 24.3373C74.7999 24.2373 75.0999 24.1373 75.2999 23.9373C75.4999 23.7373 75.6999 23.5373 75.7999 23.3373C75.9999 22.8373 76.0999 22.3373 75.8999 21.8373C75.6999 21.3373 75.2999 20.9373 74.8999 20.7373C74.3999 20.5373 73.8999 20.4373 73.3999 20.6373C72.6999 20.5373 72.2999 20.9373 72.0999 21.4373Z"
        fill="#737373"
      />
      <Path
        d="M129.4 129.638C129.4 130.738 129.2 131.838 128.7 132.838C128.9 133.038 129.2 133.238 129.4 133.438C129.5 132.438 129.4 130.638 129.4 129.638Z"
        fill="#737373"
      />
      <Path
        d="M117 181.337C113.8 183.937 112.2 185.937 112.8 187.137C113.5 188.537 116.8 188.337 121.6 187.237C119.9 185.337 118.4 183.337 117 181.337Z"
        fill="#737373"
      />
      <Path
        d="M165 158.937C165.8 161.037 166.5 163.237 167.3 165.337C173.6 160.237 173.1 157.437 165 158.937Z"
        fill="#737373"
      />
      <Path
        d="M111.5 137.037H72.2999C71.2999 137.037 70.3999 136.838 69.4999 136.537C68.1999 136.037 66.9999 135.037 66.1999 133.838C65.3999 132.638 64.9999 131.238 64.9999 129.838V22.3375C64.9999 21.3375 65.1999 20.4375 65.4999 19.5375C65.8999 18.6375 66.3999 17.8375 67.0999 17.1375C67.7999 16.4375 68.5999 15.9375 69.3999 15.5375C70.2999 15.1375 71.1999 14.9375 72.1999 14.9375H69.4999C68.2999 14.9375 67.0999 15.1375 65.9999 15.6375C64.8999 16.1375 63.8999 16.7375 63.0999 17.6375C62.2999 18.4375 61.5999 19.4375 61.0999 20.5375C60.5999 21.6375 60.3999 22.8375 60.3999 24.0375V130.237C60.3999 133.337 61.5999 136.338 63.7999 138.537C65.9999 140.737 68.9999 141.937 72.0999 141.937H114.3C113.6 140.337 112.6 138.637 111.5 137.037Z"
        fill="#737373"
      />
      <Path
        d="M104.9 125.738C101.9 127.538 98.4 128.238 95 127.738C91.6 127.238 88.4 125.538 86 122.938C83.7 120.338 82.3 117.038 82.1 113.638C81.9 110.138 82.9 106.738 85 103.938C87 101.138 90 99.1377 93.4 98.2377C96.8 97.3377 100.3 97.6377 103.5 99.0377C106.7 100.438 109.2 102.938 110.8 106.038C112.3 109.138 112.8 112.738 112 116.138"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M129.4 129.638V22.3377C129.4 20.4377 128.6 18.5377 127.3 17.2377C126 15.9377 124.1 15.1377 122.2 15.1377H72.3"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M74.7 20.4373C74.5 20.3373 74.2 20.2373 73.9 20.2373C73.6 20.2373 73.4 20.2373 73.1 20.3373C72.9 20.4373 72.6 20.5373 72.4 20.7373C72.2 20.9373 72 21.1373 71.9 21.3373C71.8 21.5373 71.7 21.8373 71.7 22.1373C71.7 22.4373 71.7 22.6373 71.8 22.9373C71.9 23.1373 72 23.4373 72.2 23.6373C72.4 23.8373 72.6 24.0373 72.8 24.1373C73.3 24.3373 73.8 24.3373 74.3 24.2373C74.8 24.0373 75.2 23.6373 75.4 23.2373C75.6 22.7373 75.6 22.2373 75.5 21.7373C75.6 21.0373 75.2 20.6373 74.7 20.4373Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M111.5 137.038H72.2999C71.2999 137.038 70.3999 136.838 69.4999 136.538C68.5999 136.138 67.7999 135.638 67.0999 134.938C66.3999 134.238 65.8999 133.438 65.4999 132.538C65.0999 131.638 64.9999 130.738 64.9999 129.738V22.3377C64.9999 20.4377 65.7999 18.5377 67.0999 17.2377C68.4999 15.8377 70.2999 15.1377 72.1999 15.1377H69.4999C68.2999 15.1377 67.0999 15.3377 65.9999 15.8377C64.8999 16.3377 63.8999 16.9377 63.0999 17.8377C62.2999 18.6377 61.5999 19.6377 61.0999 20.7377C60.5999 21.8377 60.3999 23.0377 60.3999 24.2377V130.438C60.3999 133.538 61.5999 136.538 63.7999 138.738C65.9999 140.938 68.9999 142.138 72.0999 142.138H114.3"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M129.4 129.638C129.4 130.738 129.2 131.838 128.7 132.838C128.9 133.038 129.2 133.238 129.4 133.438C129.4 133.138 129.4 132.838 129.4 132.538V129.638Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M120.3 83.5377H74V37.1377H120.3V83.5377Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M90.6 32.5377H74V29.1377H90.6V32.5377Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M119.777 92H85V89H120V92H119.777Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M81.0001 87.5376C80.8001 87.3376 80.6001 87.2376 80.4001 87.1376C80.2001 87.0376 79.9001 87.0376 79.7001 87.0376C79.4001 87.0376 79.2001 87.1376 79.0001 87.1376C78.8001 87.2376 78.6001 87.3376 78.4001 87.5376L77.8001 88.0376L77.3001 87.5376C77.1001 87.3376 76.9001 87.2376 76.7001 87.1376C76.4001 87.0376 76.2001 87.0376 75.9001 87.0376C75.6001 87.0376 75.4001 87.0376 75.2001 87.1376C75.0001 87.2376 74.7001 87.3376 74.6001 87.5376C74.4001 87.7376 74.3001 87.9376 74.2001 88.1376C74.1001 88.3376 74.1001 88.6376 74.1001 88.8376C74.1001 89.1376 74.2001 89.3376 74.3001 89.5376C74.4001 89.7376 74.5001 89.9376 74.7001 90.1376L77.9001 93.3376L81.1001 90.1376C81.5001 89.7376 81.7001 89.2376 81.7001 88.7376C81.6001 88.4376 81.4001 87.9376 81.0001 87.5376Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M31.3 83.1374L42.6 71.8374"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M31.2 40.6377L42.5 51.8377"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M22.5 61.8374H38.5"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M52.5 31.7373V47.7373"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M52.6001 76.0376V92.0376"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M96.7 120.037V105.938"
        stroke="#F3123C"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M100.1 113.737L96.7 120.037L93.3 113.737"
        stroke="#F3123C"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M141.1 141.537C130.5 136.337 119.3 123.937 117 121.337C112.7 116.637 108.2 112.437 105.1 110.437C102.9 109.037 101.3 109.237 100.8 111.237C100 115.037 102.7 121.337 104.6 125.037C106.7 129.237 109.1 133.237 111.8 137.137C114.1 140.737 116 144.737 117.3 148.837C117.3 148.837 121.6 172.337 129.7 179.637"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M121.6 187.137C110.4 175.037 108.2 159.437 106.2 155.237C103.9 150.437 88.6001 141.837 88.6001 141.837"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M107 142.138C107.8 142.638 109.2 143.538 110 144.038"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M107.3 148.138C108.5 145.938 109.9 143.938 111.6 142.138"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M167.3 165.337C163.5 155.237 159.4 142.537 156.3 132.137C154.9 127.537 129.4 83.7373 129.4 83.7373"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M138.3 121.038C138.3 121.038 133.2 125.938 129.4 130.238"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M165 158.937C180.4 156.137 167.1 167.437 149 176.537C144.2 178.937 115.7 191.737 112.8 187.237C111.8 185.537 115.4 182.537 116.9 181.337"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M117.2 195.037L112.9 187.237"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M171.5 159.538L177.5 171.438"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M135.1 124.238L129.4 112.038"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M133.1 119.838L129.4 114.638"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M140.3 7.0375C140.3 7.4375 140.2 7.8375 140 8.1375C139.8 8.4375 139.5 8.7375 139.1 8.8375C138.8 8.9375 138.5 9.0375 138.2 8.9375C137.9 8.9375 137.6 8.8375 137.4 8.7375C137.1 8.6375 136.9 8.4375 136.7 8.1375C136.5 7.9375 136.4 7.6375 136.3 7.3375C136.2 6.9375 136.3 6.5375 136.4 6.2375C136.5 5.8375 136.8 5.5375 137.1 5.3375C137.5 5.0375 137.9 4.9375 138.4 5.0375C138.9 5.0375 139.3 5.2375 139.6 5.6375C140.1 5.9375 140.3 6.4375 140.3 7.0375Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.7 58.7373C104.7 57.6373 104.4 56.6373 103.8 55.6373L98.9 47.2373C98.5 46.6373 98.3 45.9373 98.1 45.2373L97.9 45.5373C96.7 47.6373 96.7 50.1373 97.9 52.2373L101.8 58.9373C103 61.0373 103 63.5373 101.8 65.6373L101.6 65.9373C101.5 65.2373 101.2 64.5373 100.8 63.9373L95 53.9373C94.6 53.3373 94.4 52.6373 94.2 51.9373L94 52.2373C92.8 54.3373 92.8 56.8373 94 58.9373L97.9 65.6373C99.1 67.7373 99.1 70.2373 97.9 72.3373L97.7 72.6373C97.6 71.9373 97.3 71.2373 96.9 70.6373L92 62.2373C91.3 61.1373 91.1 59.8373 91.1 58.6373C86.1 60.1373 82.5 63.0373 82.5 66.3373C82.5 70.9373 89.4 74.7373 98 74.7373C106.6 74.7373 113.5 71.0373 113.5 66.3373C113.5 63.0373 109.9 60.1373 104.7 58.7373Z"
        fill="#F3123C"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Mask
        id="mask0_14311_158942"
        maskUnits="userSpaceOnUse"
        x="34"
        y="149"
        width="15"
        height="16">
        <Path d="M49 149.038H34V164.038H49V149.038Z" fill="white" />
      </Mask>
      <G mask="url(#mask0_14311_158942)">
        <Path
          d="M46 160.238H37V154.238C37 153.038 37.5 151.938 38.3 151.038C39.1 150.238 40.3 149.738 41.5 149.738C42.7 149.738 43.8 150.238 44.7 151.038C45.5 151.838 46 153.038 46 154.238V160.238Z"
          stroke="#595959"
          strokeWidth="0.9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M40.7 163.238H42.2"
          stroke="#595959"
          strokeWidth="0.9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M34.7 160.238H48.2"
          stroke="#595959"
          strokeWidth="0.9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  )
}
