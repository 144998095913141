import LottieView from 'lottie-react-native'
import { Center } from 'native-base'

export const LoadingTurbo = ({ size = 128 }) => {
  return (
    <Center flex={1}>
      <LottieView
        autoPlay
        loop
        style={{
          height: size,
          width: size
        }}
        source={require('assets/loading.json')}
      />
    </Center>
  )
}
