import { useCallback, useEffect, useMemo } from 'react'

import {
  KEY_OPPORTUNITY_LIST_BY_BUSINESS_QUERY,
  KEY_OPPORTUNITY_LIST_QUERY,
  OPPORTUNITY_LIST_FILTER_BY_ENUM,
  OpportunitiesResponse,
  useGetOpportunityListInfiniteQuery,
  useSetOpportunityHighlightedMutation,
  useSetOpportunityUnHighlightedMutation
} from 'integration/resources/opportunities'
import { useQueryClient } from 'react-query'
import { FAVORITE_TYPE } from 'src/screens/ContentScreen/ContentScreen.types'

import { UseOpportunityContainerList } from './types'

const defaultReturn = {
  items: [],
  total_activities: 0,
  total_opportunities: 0,
  highlights: {
    total_opportunities: 0,
    items: []
  }
}

const mergeDataInfiniteQuery = (
  data: ReturnType<typeof useGetOpportunityListInfiniteQuery>['data']
) =>
  data?.pages.reduce<OpportunitiesResponse>(
    (previousValue, currentValue) => ({
      ...previousValue,
      ...currentValue.data.data,
      items: [...previousValue.items, ...currentValue.data.data.items]
    }),
    defaultReturn
  )

export const useOpportunityContainerList: UseOpportunityContainerList = ({
  store_tab_numbers,
  business_block_id
}) => {
  const queryClient = useQueryClient()

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetOpportunityListInfiniteQuery({
      store_tab_numbers,
      business_block_id,
      filter_by: OPPORTUNITY_LIST_FILTER_BY_ENUM.SIMULATED,
      order_by: 'earning_potential',
      order_by_direction: 'desc'
    })

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(KEY_OPPORTUNITY_LIST_QUERY)
    }
  }, [queryClient])

  const onScrollEndDragOpportunityList = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const opportunities = useMemo(() => mergeDataInfiniteQuery(data), [data])

  const { mutateAsync: setOpportunityHighlighted } = useSetOpportunityHighlightedMutation()

  const { mutateAsync: setOpportunityUnHighlighted } = useSetOpportunityUnHighlightedMutation()

  const handlePressFavorite = (isHighlighted: FAVORITE_TYPE, id: string) => {
    queryClient.invalidateQueries([
      KEY_OPPORTUNITY_LIST_QUERY,
      KEY_OPPORTUNITY_LIST_BY_BUSINESS_QUERY
    ])

    return (
      isHighlighted === FAVORITE_TYPE.UNFAVORITE
        ? setOpportunityUnHighlighted
        : setOpportunityHighlighted
    )({
      id
    })
  }

  return {
    opportunities: opportunities?.items ?? [],
    isLoading,
    onScrollEndDragOpportunityList,
    handlePressFavorite
  }
}
