import { FunctionComponent } from 'react'

import { IIconProps } from 'native-base'
import { Path, Circle, Svg } from 'react-native-svg'

export const AlertIcon: FunctionComponent<IIconProps> = ({ ...props }) => {
  return (
    <Svg width={24} height={25} fill="none">
      <Path
        d="M7 5.56c0-.368.306-.667.683-.667.378 0 .684.299.684.667v14.666a.675.675 0 0 1-.684.667.675.675 0 0 1-.683-.667V5.56Z"
        fill="#fff"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9 14.227H7v-8h4.1c.893 0 1.653.556 1.934 1.333h3.373c1.016 0 1.677 1.043 1.223 1.93l-1.062 2.07 1.061 2.07c.455.887-.206 1.93-1.222 1.93h-2.572a2.048 2.048 0 0 1-1.934-1.333ZM8.368 7.56H11.1c.377 0 .683.298.683.667v4.666H8.367V7.56Zm4.784 6c0 .368.306.667.684.667h2.572L15.04 11.56l1.367-2.667H13.15v4.667Z"
        fill="#fff"
      />
      <Circle cx={12} cy={12.893} r={11.5} stroke="#fff" />
    </Svg>
  )
}
