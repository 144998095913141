import { useCallback, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosResponse } from 'axios'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { getAddressByZipCode } from 'integration/resources/address/requests'
import { DIRECTORY_TYPE, EXTENSION_TYPE, useUploadMutation } from 'integration/resources/upload'
import { TabDetail, useEditTabMutation } from 'integration/resources/wallet'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard } from 'react-native'
import fieldsValidation from 'src/constants/fields-validation'
import { useCamera } from 'src/hooks/useCamera'
import { isBase64File } from 'src/utils'
import * as Yup from 'yup'

import { UseTabEditScreen } from './TabEditScreen.types'

const schema = Yup.object().shape({
  email: Yup.string().nullable().email('Informe um email válido'),
  phone_number: Yup.string().required(fieldsValidation.common.required),
  secondary_address_complement: Yup.string()
    .nullable()
    .max(120, 'Complemento deve ter no máximo 120 caracteres'),
  secondary_address_name: Yup.string()
    .nullable()
    .max(120, 'Nome da localização deve ter no máximo 120 caracteres'),
  secondary_address_neighborhood: Yup.string()
    .nullable()
    .max(60, 'Bairro deve ter no máximo 60 caracteres'),
  secondary_address_number: Yup.string()
    .nullable()
    .max(10, 'Informe um número com até 10 caracteres'),
  secondary_address_postal_code: Yup.string()
    .nullable()
    .matches(/^\d{5}-?\d{3}$/, 'CEP inválido'),
  secondary_address_street: Yup.string()
    .nullable()
    .max(120, 'Logradouro deve ter no máximo 120 caracteres'),
  secondary_address_city: Yup.string()
    .nullable()
    .max(120, 'Cidade deve ter no máximo 120 caracteres'),
  secondary_address_uf: Yup.string()
    .nullable()
    .length(2, 'Unidade Federativa deve conter apenas 2 letras'),

  website: Yup.string().url('Deve ser uma URL válida: https://meusite.com').nullable()
})

export const useTabEditScreen: UseTabEditScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const bottomSheetContext = useBottomSheetContext()

  const { tabDetail } = route.params

  const { mutateAsync: uploadMutationAsync, isLoading: isLoadingUploadPhoto } = useUploadMutation()

  const { control, handleSubmit, setValue } = useForm<TabDetail>({
    resolver: yupResolver(schema)
  })

  const closeRightSheet = useCallback(() => {
    navigation.goBack()
  }, [navigation])

  const { isLoading, mutate } = useEditTabMutation(tabDetail.tab_number)

  const handleGoToTabDetails = useCallback(() => {
    if (tabDetail?.tab_number)
      navigation.navigate('WalletTab', { tabNumber: tabDetail?.tab_number })
  }, [navigation, tabDetail])

  const toast = useToast()

  const handleEditStore = useCallback(() => {
    bottomSheetContext.open({
      description: 'Não foi possível editar a loja, tente novamente!',
      title: 'Erro ao editar loja'
    })
  }, [bottomSheetContext])

  const submit = handleSubmit(
    useCallback<SubmitHandler<TabDetail>>(
      async (formData) => {
        Keyboard.dismiss()

        const { phone_number, website } = formData

        const sslWebsite = website?.replace('http://', 'https://')

        const cleanPhone = phone_number?.replace(/\D/g, '')

        const formattedFields = {
          ...formData,
          phone_number_ddd: cleanPhone?.substring(0, 2),
          phone_number: cleanPhone?.substring(2),
          website: sslWebsite
        }

        if (
          formData?.logo_image_url &&
          (formData?.logo_image_url.includes('file://') ||
            isBase64File(formData?.logo_image_url ?? ''))
        ) {
          const response = await fetch(formData.logo_image_url)

          const file = await response.blob()

          if (file.type && file.type.split('/').length > 0) {
            const type = file.type.split('/')[1]

            const files = await uploadMutationAsync({
              directory_type: DIRECTORY_TYPE.STORE_COVER,
              extension_type: type.toUpperCase() as keyof typeof EXTENSION_TYPE,
              files: [file]
            })

            if (files.length) {
              formattedFields.logo_image_file_name = files[0].name

              formattedFields.logo_image_url = null
            }
          }
        }

        if (formData?.logo_image_url === undefined) {
          formattedFields.logo_image_url = null

          formattedFields.logo_image_file_name = null
        }

        mutate(formattedFields, {
          onError: handleEditStore,
          onSuccess: () => {
            toast.show({
              render: () => <Toast type="success" text="Informações salvas com sucesso" />
            })

            handleGoToTabDetails()
          }
        })
      },
      [mutate, handleEditStore, uploadMutationAsync, toast, handleGoToTabDetails]
    )
  )

  useEffect(() => {
    if (tabDetail) {
      Object.entries(tabDetail).forEach(([name, value]: any) => setValue(name, value))
    }

    if (tabDetail?.phone_number) {
      setValue('phone_number', `${tabDetail?.phone_number_ddd}${tabDetail?.phone_number}`)
    }

    if (typeof tabDetail === 'string') navigation.navigate('WalletTabList', {})
  }, [navigation, setValue, tabDetail])

  // camera

  const { handleChooseImage, handleTakePicture } = useCamera()

  const [showOptions, setShowOptions] = useState(false)

  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false)

  const handleShowOptionsCamera = () => setShowOptions(true)

  const handleCloseOptionsCamera = () => setShowOptions(false)

  const handleSelectPicture = useCallback(async () => {
    setIsLoadingPhoto(true)

    await handleChooseImage((photoSelected) => {
      setValue('logo_image_url', photoSelected)

      setTimeout(() => {
        setShowOptions(false)

        setIsLoadingPhoto(false)
      }, 300)
    })
  }, [handleChooseImage, setValue])

  const handelTakePicture = useCallback(async () => {
    setIsLoadingPhoto(true)

    await handleTakePicture((photoSelected) => {
      setValue('logo_image_url', photoSelected)

      setTimeout(() => {
        setShowOptions(false)

        setIsLoadingPhoto(false)
      }, 300)
    })
  }, [handleTakePicture, setValue])

  const handleRemovePicture = useCallback(async () => {
    setIsLoadingPhoto(true)

    setValue('logo_image_url', null)

    setValue('logo_image_file_name', null)

    setTimeout(() => {
      setShowOptions(false)

      setIsLoadingPhoto(false)
    }, 300)
  }, [setValue])

  const successAddressResponse = (res: AxiosResponse) => {
    const { data: address } = res

    setValue('secondary_address_street', address.logradouro ?? '')

    setValue('secondary_address_neighborhood', address.bairro ?? '')

    setValue('secondary_address_city', address.localidade ?? '')

    setValue('secondary_address_uf', address.uf ?? '')
  }

  const handleGetAddressByZipCode = (zipCode: string) => {
    getAddressByZipCode(zipCode).then(successAddressResponse)
  }

  return {
    isMobile,
    isLoading: isLoading || isLoadingPhoto || isLoadingUploadPhoto,
    control,
    submit,
    closeRightSheet,
    handleShowOptionsCamera,
    handleCloseOptionsCamera,
    isLoadingPhoto,
    showOptions,
    handelTakePicture,
    handleRemovePicture,
    handleSelectPicture,
    handleGetAddressByZipCode
  }
}
