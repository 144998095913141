import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { G, Path } from 'react-native-svg'

export const BellIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <G fill="currentColor">
      <Path d="M9 1.65A4.65 4.65 0 0 0 4.35 6.3v6.45h9.3V6.3A4.65 4.65 0 0 0 9 1.65Zm-4.349.301A6.15 6.15 0 0 1 15.15 6.3v7.2a.75.75 0 0 1-.75.75H3.6a.75.75 0 0 1-.75-.75V6.3A6.15 6.15 0 0 1 4.65 1.951ZM7.35 17.098a.75.75 0 0 1 .75-.75h1.8a.75.75 0 1 1 0 1.5H8.1a.75.75 0 0 1-.75-.75Z" />
      <Path d="M.15 13.498a.75.75 0 0 1 .75-.75h16.2a.75.75 0 1 1 0 1.5H.9a.75.75 0 0 1-.75-.75Z" />
    </G>
  </Icon>
)
