import { FunctionComponent, useMemo } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Avatar, FadeInView, Icons } from 'atoms'
import { useGetNotReadNotificationsQuery } from 'integration/resources/notifications'
import { useGetProfileQuery } from 'integration/resources/user'
import {
  Badge,
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  Text,
  VStack,
  Divider,
  Flex
} from 'native-base'
import { useAuthAtomValue } from 'src/store/auth'
import { LogEvent } from 'src/utils/logEvents'

type THeader = {
  hiddenSearch?: boolean
  onSearch?(search: string): void
  search?: string
  isContent?: boolean
  onPressSearch?: () => void
  onPressSearchClear?: () => void
}

export const Header: FunctionComponent<THeader> = ({
  hiddenSearch = false,
  onSearch,
  search,
  isContent,
  onPressSearch,
  onPressSearchClear
}) => {
  const linkTo = useLinkTo()

  const authAtom = useAuthAtomValue()

  const getNotReadNotifications = useGetNotReadNotificationsQuery()

  const notificationsCount = useMemo(
    () => getNotReadNotifications?.data?.data?.data,
    [getNotReadNotifications]
  )

  const profile = useGetProfileQuery()

  const profileImage = profile?.data?.data?.data?.profile_image_url

  return (
    <HStack p={8} alignItems="center" space={4}>
      <HStack flex={1}>
        <Input
          opacity={hiddenSearch ? 0 : 1}
          w="full"
          placeholder="Pesquisar"
          placeholderTextColor="gray.400"
          leftElement={
            <Box pl={4}>
              <Icons.Search color="gray.700" size={6} />
            </Box>
          }
          onChangeText={onSearch}
          value={search}
          InputRightElement={
            isContent ? (
              <Flex mr="16px" h="32px" direction="row" alignItems="center">
                {search !== '' && (
                  <>
                    <Button w="32px" h="32px" variant="unstyled" onPress={onPressSearchClear}>
                      <Icons.Close size={3} />
                    </Button>
                    <Divider bg="#DBDBDB" thickness="1" mx="4" orientation="vertical" />
                    <Button w="32px" h="32px" colorScheme="gray" onPress={onPressSearch}>
                      <Icons.Search color="#fff" size={4} />
                    </Button>
                  </>
                )}
              </Flex>
            ) : (
              <></>
            )
          }
        />
      </HStack>
      <VStack position="relative">
        {!!notificationsCount?.not_read_count && (
          <Badge
            colorScheme="primary"
            rounded="full"
            position="absolute"
            zIndex={1}
            right={-10}
            top={-2}
            variant="solid"
            alignSelf="flex-end"
            _text={{
              fontSize: 10
            }}>
            {notificationsCount?.not_read_count}
          </Badge>
        )}
        <IconButton
          testID="global-notifications"
          bg="white"
          borderRadius="full"
          colorScheme="gray"
          w={12}
          h={12}
          icon={<Icons.Bell size={4} color="gray.700" />}
          onPress={() => {
            LogEvent('botao_topo_notificacoes', {
              item_id: ''
            })

            linkTo('/notificacoes')
          }}
        />
      </VStack>

      {authAtom?.user && (
        <Button
          testID="global-profile"
          variant="unstyled"
          _web={{
            _hover: {
              bg: 'gray.200'
            }
          }}
          w="274px"
          bg="white"
          justifyContent="flex-start"
          leftIcon={
            <FadeInView delay={600}>
              <Avatar uri={profileImage ?? ''} size="sm" title={authAtom?.user.name} type="dark" />
            </FadeInView>
          }
          onPress={() => linkTo('/perfil')}>
          <Text
            maxW="190px"
            isTruncated
            ml={2}
            fontWeight={700}
            fontSize="16px"
            lineHeight="24px"
            letterSpacing={0.5}>
            {authAtom?.user?.name}
          </Text>
        </Button>
      )}
    </HStack>
  )
}
