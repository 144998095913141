import { AxiosResponse } from 'axios'
import { ResponseSuccess } from 'integration/types'
import { RevenueCalculatorFormFields } from 'organisms/RVCalculators/Calculators/Revenue/RevenueCalculator.types'

import {
  TCalculatorData,
  TCalculatorDataSerialized,
  TRevenueCalculator,
  TRevenueCalculatorSerialized
} from './types'

export const serializeRevenueCalculatorFormValues = (formData: RevenueCalculatorFormFields) => ({
  target: formData.targetAccount,
  weight: {
    production: formData.weightProduction,
    spf: formData.weightSPF,
    sa: formData.weightSA,
    charge: formData.weightCharge,
    webmotors: formData.weightWebmotors,
    more_accounts: formData.weightMoreAccounts
  },
  goal: {
    production: formData.goalProduction,
    spf: formData.goalSPF,
    sa: formData.goalSA,
    charge: formData.goalCharge,
    webmotors: formData.goalWebmotors,
    more_accounts: formData.goalMoreAccounts
  },
  realized: {
    production: formData.realizedProduction,
    spf: formData.realizedSPF,
    sa: formData.realizedSA,
    charge: formData.realizedCharge,
    webmotors: formData.realizedWebmotors,
    more_accounts: formData.realizedMoreAccounts
  },
  accelerators: {
    active_bases: formData.activeBasesProduction,
    loyalty: formData.loyaltyProduction,
    expenses: formData.expensesProduction,
    chargeGoal: formData.chargeGoal,
    performance: formData.performance
  },
  total: formData.rvTotal
})

export const getRevenueData: (
  response: AxiosResponse<ResponseSuccess<TRevenueCalculator>>
) => AxiosResponse<ResponseSuccess<TRevenueCalculatorSerialized>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: {
      targetAccount: response.data.data.target,
      weightProduction: response.data.data.weight.production,
      weightSPF: response.data.data.weight.spf,
      weightSA: response.data.data.weight.sa,
      weightCharge: response.data.data.weight.charge,
      weightWebmotors: response.data.data.weight.webmotors,
      weightMoreAccounts: response.data.data.weight.more_accounts,
      goalProduction: response.data.data.goal.production,
      goalSPF: response.data.data.goal.spf,
      goalSA: response.data.data.goal.sa,
      goalCharge: response.data.data.goal.charge,
      goalWebmotors: response.data.data.goal.webmotors,
      goalMoreAccounts: response.data.data.goal.more_accounts,
      realizedProduction: response.data.data.realized.production,
      realizedSPF: response.data.data.realized.spf,
      realizedSA: response.data.data.realized.sa,
      realizedCharge: response.data.data.realized.charge,
      realizedWebmotors: response.data.data.realized.webmotors,
      realizedMoreAccounts: response.data.data.realized.more_accounts,
      activeBasesProduction: response.data.data.accelerators.active_bases,
      loyaltyProduction: response.data.data.accelerators.loyalty,
      expensesProduction: response.data.data.accelerators.expenses,
      chargeGoal: response.data.data.accelerators.chargeGoal,
      performance: response.data.data.accelerators.performance,
      rvTotal: response.data.data.total
    }
  }
})

// Other calculators

export const getCalculatorData: (
  response: AxiosResponse<ResponseSuccess<TCalculatorData>>
) => AxiosResponse<ResponseSuccess<TCalculatorDataSerialized>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: {
      ...(response.data.data?.calculator_json ?? {})
    }
  }
})
