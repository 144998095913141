/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useCallback, useMemo, useState } from 'react'

import { OPPORTUNITY_ORDER_BY_SCHEMAS } from './constants'
import { TOrderByFieldNames, UseOpportunityOrderByFilter } from './OrderByFilterScreen.types'

export const useOpportunityOrderByFilter: UseOpportunityOrderByFilter = ({ navigation, route }) => {
  const goBack = useCallback(() => {
    navigation.goBack()
  }, [navigation])

  const currentFilters = useMemo(() => {
    return {
      offer: route?.params?.offer,
      public_rating: route?.params?.public_rating,
      segment: route?.params?.segment,
      network: route?.params?.network,
      subsidiary: route?.params?.subsidiary,
      opportunity_type: route?.params?.opportunity_type,
      listType: route?.params?.listType,
      user_std_code: route?.params?.user_std_code
    }
  }, [route?.params])

  const currentSchema = OPPORTUNITY_ORDER_BY_SCHEMAS[currentFilters?.listType]

  const [selectedOrderBy, setSelectedOrderBy] = useState({
    order_by: route?.params?.order_by || undefined,
    order_by_direction: route?.params?.order_by_direction || undefined
  })

  const handleCheckboxChange = (fieldName: TOrderByFieldNames, direction: 'asc' | 'desc') => {
    setSelectedOrderBy({
      order_by: fieldName,
      order_by_direction: direction
    })
  }

  const handleApply = () => {
    navigation.navigate('Tab', {
      screen: 'Organization',
      params: {
        ...currentFilters,
        order_by: selectedOrderBy?.order_by,
        order_by_direction: selectedOrderBy?.order_by_direction
      }
    })
  }

  const handleClear = () => {
    setSelectedOrderBy({
      order_by: undefined,
      order_by_direction: undefined
    })
  }

  const fieldNames = (Object.keys(currentSchema?.fields ?? {}) as TOrderByFieldNames[]) ?? []

  return {
    handleCheckboxChange,
    handleApply,
    handleClear,
    fieldNames,
    selectedOrderBy,
    goBack
  }
}
