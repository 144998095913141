import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { OpportunityScreenComponent } from './OpportunityScreen.types'
import { useOpportunityScreen } from './useOpportunityScreen'

const OpportunityScreenDesktop = lazy(() => import('./OpportunityScreen.desktop'))

const OpportunityScreenMobile = lazy(() => import('./OpportunityScreen.mobile'))

export const OpportunityScreen: OpportunityScreenComponent = ({ navigation, route }) => {
  const props = useOpportunityScreen({ navigation, route })

  const Screen = props.isMobile ? OpportunityScreenMobile : OpportunityScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
