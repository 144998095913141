import { FunctionComponent, memo } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Cards } from 'atoms'
import { OpportunitySerialized } from 'integration/resources/opportunities'
import { Box, Pressable, ScrollView, VStack } from 'native-base'

type pportunitiesListOfCardProps = {
  data: OpportunitySerialized[]
}

export const OpportunitiesListOfCard: FunctionComponent<pportunitiesListOfCardProps> = memo(
  ({ data }) => {
    const linkTo = useLinkTo()

    return (
      <VStack space={2}>
        <Box position="relative">
          <ScrollView pagingEnabled scrollEventThrottle={32} showsVerticalScrollIndicator={false}>
            {data.map((item) => (
              <Pressable key={item.id} onPress={() => linkTo(`/oportunidade/${item?.id}`)}>
                <Box key={Math.random()} mb={2}>
                  <Cards.OpportunityOfCard data={item} key={item.id} />
                </Box>
              </Pressable>
            ))}
          </ScrollView>
        </Box>
      </VStack>
    )
  }
)
