import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path, G, Defs, ClipPath, Rect } from 'react-native-svg'

export const FullScreenIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 21 21', ...props }}>
    <G clipPath="url(#clip0_14374_226653)">
      <Path
        d="M1.05005 7.34688V1.04688H7.35005"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M1.05005 1.04688L7.35005 7.34688"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M19.9499 7.34688V1.04688H13.6499"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M19.9499 1.04688L13.6499 7.34688"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M1.05005 13.6484V19.9484H7.35005"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M1.05005 19.9484L7.35005 13.6484"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M19.9499 13.6484V19.9484H13.6499"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M19.9499 19.9484L13.6499 13.6484"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_14374_226653">
        <Rect width="21" height="21" fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)
