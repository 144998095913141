import { FunctionComponent, memo } from 'react'

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Avatar, Icons } from 'atoms'
import { format, parseISO } from 'date-fns'
import { Activity } from 'integration/resources/activities'
import { Button, Center, HStack, Heading, Text, VStack } from 'native-base'
import { RootStackParamList } from 'navigation/types'
import { TouchableOpacity } from 'react-native'
import { formatDateStringToMask } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

type CardBaseProps = Activity & {
  title: string
  id: string
}

/**
 * TODO
 * anexos e checklist faltantes na API
 * estao mocados nesse componente
 **/

export const ActivityCard: FunctionComponent<CardBaseProps> = memo(
  ({
    id,
    title,
    store,
    schedule_date,
    schedule_date_start,
    schedule_date_end,
    is_finished,
    ...rest
  }) => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

    return (
      <VStack space={4} key={id}>
        <VStack bg="white" borderRadius="20px" px={6} py={4}>
          <TouchableOpacity
            onPress={() => {
              LogEvent('organizacao_atividade', {
                item_id: id
              })

              navigation.navigate('OrganizationActivity', {
                id: String(id)
              })
            }}>
            <HStack space={2} alignItems="center">
              <Center
                bg={is_finished ? 'tertiary.50' : 'white'}
                borderWidth={1}
                borderColor={is_finished ? 'tertiary.50' : 'gray.50'}
                borderRadius="full"
                mr={2}
                h={12}
                w={12}>
                <Icons.Check color={is_finished ? 'tertiary.500' : 'gray.700'} size={20} />
              </Center>

              <VStack flex={1}>
                <Text fontSize="18px" lineHeight="24px" fontWeight="normal" color="gray.400">
                  {schedule_date_start
                    ? `${format(
                        parseISO(schedule_date_start.replace('Z', '')),
                        'dd/MM/yyyy'
                      )} até ${format(parseISO(schedule_date_end.replace('Z', '')), 'dd/MM/yyyy')}`
                    : formatDateStringToMask(schedule_date, 'dd/MM/yyyy hh:mm')}
                </Text>
              </VStack>

              <HStack flex={1} space={4} alignItems="center">
                <Heading
                  fontSize="16px"
                  lineHeight="21px"
                  fontWeight="normal"
                  color="gray.700"
                  mb={1}>
                  {title}
                </Heading>
              </HStack>

              <HStack
                flex={1}
                space={4}
                alignItems="center"
                display={{
                  xl: 'flex',
                  lg: 'none',
                  md: 'none'
                }}>
                <Center bg="white" borderRadius="full" h={10} w={10}>
                  <Avatar uri={undefined} title={title} type="light" h={10} w={10} />
                </Center>
                <VStack>
                  <Text
                    fontSize="16px"
                    lineHeight="21px"
                    fontWeight="normal"
                    color="gray.400"
                    maxWidth={200}>
                    {store.name_fantasy}
                  </Text>
                  <Text fontSize="14px" lineHeight="21px" fontWeight="semibold" color="gray.400">
                    TAB {store?.tab_number}
                  </Text>
                </VStack>
              </HStack>

              <HStack flex={1} space={4} ml={2} justifyContent="center">
                <Button
                  h="24px"
                  p={0}
                  px={4}
                  bg="tertiary.800"
                  _hover={{ bg: 'tertiary.800' }}
                  _text={{
                    color: 'white',
                    fontSize: '12px',
                    lineHeight: '24px',
                    textTransform: 'uppercase'
                  }}>
                  oportunidade
                </Button>
              </HStack>

              <HStack space={6}>
                <HStack space={2} alignItems="center">
                  <Icons.Attachment color="gray.400" />

                  <Text fontSize="14px" lineHeight="20px" fontWeight="semibold" color="gray.400">
                    2
                  </Text>
                </HStack>

                <HStack space={2} alignItems="center">
                  <Icons.CheckConcluded color="gray.400" />

                  <Text fontSize="14px" lineHeight="20px" fontWeight="semibold" color="gray.400">
                    2/4
                  </Text>
                </HStack>
              </HStack>
            </HStack>
          </TouchableOpacity>
        </VStack>
      </VStack>
    )
  }
)
