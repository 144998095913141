import { useCallback } from 'react'

import { useBreakpointValue } from 'native-base'

import { UseFirstLoginSuccessScreen } from './FirstLoginSuccessScreen.types'

export const useFirstLoginSuccessScreen: UseFirstLoginSuccessScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const handleGoToFirstLoginPasswordScreen = useCallback(
    () =>
      navigation.reset({
        index: 0,
        routes: [{ name: 'Entry' }, { name: 'FirstLoginPassword' }]
      }),
    [navigation]
  )

  return {
    handleGoToFirstLoginPasswordScreen,
    isMobile
  }
}
