import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const MailIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled = false,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    {filled ? (
      <>
        <Path
          key={Math.random()}
          d="M11.805 10.6545C11.509 10.6545 11.2201 10.5641 10.977 10.3953L1.01936 2.93848C0.94857 2.88586 0.890313 2.81821 0.848765 2.7404C0.807217 2.66259 0.78341 2.57655 0.779051 2.48845C0.774691 2.40035 0.789889 2.31238 0.823553 2.23084C0.857217 2.14931 0.908512 2.07625 0.973756 2.01688C1.63526 1.40359 2.5029 1.06099 3.40496 1.05688H20.205C21.107 1.06099 21.9747 1.40359 22.6362 2.01688C22.6985 2.07663 22.7473 2.1491 22.7793 2.22937C22.8112 2.30964 22.8255 2.39583 22.8211 2.4821C22.8168 2.56837 22.794 2.65271 22.7542 2.72938C22.7144 2.80605 22.6586 2.87328 22.5906 2.92648L12.633 10.3953C12.3898 10.5641 12.1009 10.6545 11.805 10.6545Z"
          fill="currentColor"
        />
        <Path
          key={Math.random()}
          d="M11.8052 13.0525C12.6197 13.0525 13.4131 12.7935 14.0708 12.3129L22.8452 5.7345C22.9343 5.66765 23.0403 5.62693 23.1513 5.61693C23.2623 5.60692 23.3738 5.62801 23.4735 5.67785C23.5732 5.72768 23.657 5.80428 23.7156 5.89906C23.7741 5.99385 23.8052 6.10308 23.8052 6.2145V19.0569C23.8052 20.0117 23.4259 20.9274 22.7508 21.6025C22.0756 22.2776 21.16 22.6569 20.2052 22.6569H3.40518C2.4504 22.6569 1.53472 22.2776 0.859591 21.6025C0.18446 20.9274 -0.194824 20.0117 -0.194824 19.0569V6.2145C-0.194824 6.10308 -0.163795 5.99385 -0.105215 5.89906C-0.046634 5.80428 0.0371841 5.72768 0.136848 5.67785C0.236511 5.62801 0.348082 5.60692 0.459059 5.61693C0.570036 5.62693 0.676034 5.66765 0.765176 5.7345L9.53958 12.3129C10.1972 12.7935 10.9907 13.0525 11.8052 13.0525Z"
          fill="currentColor"
        />
      </>
    ) : (
      <>
        <Path
          key={Math.random()}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6 3.4a1.4 1.4 0 0 0-1.4 1.4v14.4a1.4 1.4 0 0 0 1.4 1.4h16.8a1.4 1.4 0 0 0 1.4-1.4V4.8a1.4 1.4 0 0 0-1.4-1.4H3.6ZM.2 4.8a3.4 3.4 0 0 1 3.4-3.4h16.8a3.4 3.4 0 0 1 3.4 3.4v14.4a3.4 3.4 0 0 1-3.4 3.4H3.6a3.4 3.4 0 0 1-3.4-3.4V4.8Z"
          fill="currentColor"
        />
        <Path
          key={Math.random()}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.436 4.155a1 1 0 0 1 1.409-.12l9.252 7.808a1.4 1.4 0 0 0 1.806 0l9.252-7.807a1 1 0 1 1 1.29 1.528l-9.252 7.807a3.4 3.4 0 0 1-4.386 0L.555 5.564a1 1 0 0 1-.12-1.409Z"
          fill="currentColor"
        />
      </>
    )}
  </Icon>
)
