import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ContactEditBirthdayScreenComponent } from './ContactEditBirthdayScreen.types'
import { useContactEditBirthdayScreen } from './useContactEditBirthdayScreen'

const ContactEditBirthdayScreenDesktop = lazy(() => import('./ContactEditBirthdayScreen.desktop'))

const ContactEditBirthdayScreenMobile = lazy(() => import('./ContactEditBirthdayScreen.mobile'))

export const ContactEditBirthdayScreen: ContactEditBirthdayScreenComponent = ({
  navigation,
  route
}) => {
  const contactEditBirthdayScreen = useContactEditBirthdayScreen({ navigation, route })

  const Screen = contactEditBirthdayScreen.isMobile
    ? ContactEditBirthdayScreenMobile
    : ContactEditBirthdayScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...contactEditBirthdayScreen} />
    </Suspense>
  )
}
