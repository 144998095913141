import { FunctionComponent } from 'react'

import { TGoalsStaffListItem, TGoalsLeaderDetails } from 'integration/resources/goals'
import { RootStackScreenComponentProps } from 'src/navigation'

export type GoalsLeaderDetailsScreenComponent = FunctionComponent<GoalsLeaderDetailsScreenProps>

export type GoalsLeaderDetailsScreenProps = RootStackScreenComponentProps<'GoalsLeaderDetails'>

export type ResponsiveGoalsLeaderDetailsScreenComponent = FunctionComponent<
  ReturnType<UseGoalsLeaderDetailsScreen>
>

export enum Lists {
  Production,
  Insurance,
  InsuranceAuto,
  Webmotors,
  MoreAccounts,
  Charge
}

export type ListButtonProps = {
  list: Lists
  label: string
  currentList: Lists
  onPress: (list: Lists) => void
}

export type SearchBarProps = {
  onSearch?(search: string): void
  search?: string
  onPressSearch?: () => void
  onPressSearchClear?: () => void
  placeholder: string
}

export type OrderOption = {
  id: 'asc' | 'desc'
  name: string
}

export type OrderField = {
  label: string
  options: OrderOption[]
}

export type StaffListProps = {
  search?: string
  staffListData?: TGoalsStaffListItem[]
  staffListIsLoading: boolean
  staffListIsFetchingNextPage: boolean
  goalsIsOpen: boolean
}

export type StaffCardProps = TGoalsStaffListItem

export type OrderByButtonProps = {
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear(): void
  handleApply(): void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen(): void
  orderByOnClose(): void
}

export type UseGoalsLeaderDetailsScreen = ({
  navigation,
  route
}: Pick<GoalsLeaderDetailsScreenProps, 'navigation' | 'route'>) => {
  isMobile: boolean
  isAdmin: boolean
  handleGoBack: () => void
  detailsData?: TGoalsLeaderDetails
  leaderDetailsIsLoading: boolean
  goalsIsOpen: boolean
  currentList: Lists
  handleListChange: (list: Lists) => void
  staffListData?: TGoalsStaffListItem[]
  staffListIsLoading: boolean
  staffListIsFetchingNextPage: boolean
  staffListTotalItems: number
  onEndReached: () => void
  onSearch?(search: string): void
  search?: string
  onPressSearch?: () => void
  onPressSearchClear?: () => void
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear: () => void
  handleApply: () => void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen: () => void
  orderByOnClose: () => void
}
