import { memo } from 'react'

import Svg, { Path } from 'react-native-svg'

const MobileIcon = () => (
  <Svg width={16} height={18} fill="none" viewBox="0 0 16 18">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.95.9A.75.75 0 0 1 1.7.15h12.6a.75.75 0 0 1 .75.75v16.2a.75.75 0 0 1-.75.75H1.7a.75.75 0 0 1-.75-.75V.9Zm1.5.75v14.7h11.1V1.65H2.45Z"
      fill="#404040"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.35 13.5a.75.75 0 0 1 .75-.75h1.8a.75.75 0 0 1 0 1.5H7.1a.75.75 0 0 1-.75-.75Z"
      fill="#404040"
    />
  </Svg>
)

export const Mobile = memo(MobileIcon)
