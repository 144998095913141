import { useBreakpointValue } from 'native-base'

import { UseChatScreen } from './ChatScreen.types'

export const useChatScreen: UseChatScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return {
    isMobile
  }
}
