import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const FlatAddIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.931 14.0666C23.0737 14.0666 24 13.1403 24 11.9977C24 10.855 23.0737 9.92871 21.931 9.92871L14.0713 9.92871L14.0713 2.06897C14.0713 0.926307 13.145 -4.74487e-07 12.0023 -5.24434e-07C10.8597 -5.74381e-07 9.93336 0.926307 9.93336 2.06896L9.93336 9.92871L2.06897 9.92871C0.926305 9.92871 -4.74488e-07 10.855 -5.24435e-07 11.9977C-5.74382e-07 13.1403 0.926305 14.0666 2.06897 14.0666L9.93336 14.0666L9.93336 21.931C9.93336 23.0737 10.8597 24 12.0023 24C13.145 24 14.0713 23.0737 14.0713 21.931L14.0713 14.0666L21.931 14.0666Z"
      fill="currentColor"
    />
  </Icon>
)
