// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const FlashlightIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 20 20', ...props }}>
    <G clipPath="url(#clip0_14047_199211)">
      <Path
        d="M4 19.5C3.25 19.5 2.5 19.1667 2 18.6667L0.833333 17.5C0.333333 17 0 16.25 0 15.5C0 14.75 0.333333 14 0.833333 13.5L6.16667 8.16667V4C6.16667 3.75 6.25 3.58333 6.41667 3.41667L7.41667 2.41667L9 0.833333C9.5 0.333333 10.25 0 11 0C11.75 0 12.5 0.333333 13 0.833333L18.6667 6.5C19.1667 7 19.5 7.75 19.5 8.5C19.5 9.25 19.1667 10 18.6667 10.5L17.0833 12.0833L16.0833 13.0833C15.9167 13.25 15.75 13.3333 15.5 13.3333H11.3333L6 18.6667C5.5 19.25 4.75 19.5 4 19.5ZM7.83333 4.33333V8.5C7.83333 8.75 7.75 8.91667 7.58333 9.08333L2 14.6667C1.75 14.9167 1.66667 15.1667 1.66667 15.5C1.66667 15.8333 1.75 16.0833 2 16.3333L3.16667 17.5C3.58333 17.9167 4.41667 17.9167 4.83333 17.5L10.4167 11.9167C10.5833 11.75 10.75 11.6667 11 11.6667H15.1667L15.3333 11.5L8 4.16667L7.83333 4.33333ZM9.16667 3L16.5 10.3333L17.5 9.33333C17.75 9.08333 17.8333 8.83333 17.8333 8.5C17.8333 8.16667 17.75 7.91667 17.5 7.66667L11.8333 2C11.4167 1.58333 10.5833 1.58333 10.1667 2L9.16667 3Z"
        fill="currentColor"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_14047_199211">
        <Rect width={20} height={20} fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)
