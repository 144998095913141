import { ResponseSuccess } from 'integration/types'

import * as serializers from './serializers'
import { Device } from './types'
import client from '../../client'

export const getDevices = async () => {
  const response = await client.get<ResponseSuccess<Device[]>>(`v1/devices`)

  const newResponse = {
    ...response,
    data: {
      ...response.data,
      data: response.data.data
    }
  }

  return serializers.getDevices(newResponse)
}

export const deleteDevice = (deleteDevicePayload: string) =>
  client.delete(`v1/devices/${deleteDevicePayload}`)
