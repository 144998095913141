import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ContactBirthdayScreenComponent } from './ContactBirthdayScreen.types'
import { useContactBirthdayScreen } from './useContactBirthdayScreen'

const ContactBirthdayScreenDesktop = lazy(() => import('./ContactBirthdayScreen.desktop'))

const ContactBirthdayScreenMobile = lazy(() => import('./ContactBirthdayScreen.mobile'))

export const ContactBirthdayScreen: ContactBirthdayScreenComponent = ({ navigation, route }) => {
  const contactBirthdayScreen = useContactBirthdayScreen({ navigation, route })

  const Screen = contactBirthdayScreen.isMobile
    ? ContactBirthdayScreenMobile
    : ContactBirthdayScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...contactBirthdayScreen} />
    </Suspense>
  )
}
