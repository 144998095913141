import { FunctionComponent, useMemo, useState } from 'react'

import Icons from 'atoms/Icons'
import StackedBarChart from 'atoms/StackedBarChart'
import { PointsRvStatementData } from 'integration/resources/rv'
import { VStack, Heading, Text, HStack, Divider, useTheme, Box, Pressable } from 'native-base'
import { Platform, TouchableHighlight } from 'react-native'
import Tooltip from 'react-native-walkthrough-tooltip'
import { formatToReal } from 'src/utils'
import { currencyFormatChart } from 'src/utils/currencyFormat'

type PointsSummaryDetailCardProps = PointsRvStatementData

export const PointsSummaryDetailCard: FunctionComponent<PointsSummaryDetailCardProps> = ({
  title,
  goal_value,
  real_value,
  percentage,
  weight_value,
  real_points_value,
  projection_percent,
  projection_points_value
}) => {
  const isPlusAccounts = useMemo(() => title === '+Contas', [title])

  const theme = useTheme()

  const [openTooltipRealized, setOpenTooltipRealized] = useState(false)

  const [openTooltipProjected, setOpenTooltipProjected] = useState(false)

  const isIos = Platform.OS === 'ios'

  const needsLabel = true

  const needsShortcut = true

  return (
    <VStack bg={{ base: 'white', lg: 'gray.50' }} borderRadius={20} space={3}>
      <Heading fontSize={{ base: '14px', lg: '18px' }} mt={4} px={{ base: 4, lg: 6 }}>
        {title}
      </Heading>

      <HStack space={2} px={{ base: 4, lg: 6 }} alignItems="center">
        <Text fontSize={{ base: '12px', lg: '16px' }} color="gray.700">
          Realizado:
        </Text>
        <Text fontSize={{ base: '12px', lg: '16px' }} color="gray.700" fontWeight="bold">
          {!isPlusAccounts
            ? formatToReal(Number(real_value))
            : currencyFormatChart(real_value, needsLabel, !needsShortcut, 1)}
        </Text>
        <Text fontSize={{ base: '12px', lg: '16px' }} color="gray.300">
          {percentage}% ({real_points_value}Pts)
        </Text>
      </HStack>

      <HStack space={2} px={{ base: 4, lg: 6 }} alignItems="center">
        <Text fontSize={{ base: '12px', lg: '16px' }} color="gray.700">
          Meta:
        </Text>
        <Text fontSize={{ base: '12px', lg: '16px' }} color="gray.700" fontWeight="bold">
          {!isPlusAccounts
            ? formatToReal(Number(goal_value))
            : currencyFormatChart(goal_value, needsLabel, !needsShortcut, 1)}
        </Text>
      </HStack>

      <VStack flex={1} space={2} mx={{ base: 4, lg: 6 }}>
        <StackedBarChart
          expectedValue={Number(projection_percent)}
          currentValue={Number(percentage)}
          realValue={currencyFormatChart(real_value, needsLabel, !needsShortcut, 1)}
          realPointsValue={real_points_value}
          goalValue={currencyFormatChart(goal_value, needsLabel, !needsShortcut, 1)}
          projectionPointsValue={projection_points_value}
          currentIsStriped={false}
          expectedIsStriped
          maxValue={200}
          isPlusAccounts={isPlusAccounts}
        />
      </VStack>

      <Box px={{ base: 4, lg: 6 }}>
        <Divider mb={3} bg="gray.100" opacity={0.6} />
      </Box>

      <HStack
        space={2}
        px={{ base: 4, lg: 6 }}
        pb={{ base: 4, lg: 6 }}
        alignItems="center"
        justifyContent="space-between">
        <HStack space={4}>
          <Tooltip
            isVisible={openTooltipRealized}
            tooltipStyle={{ marginTop: isIos ? -4 : -24 }}
            contentStyle={{ borderRadius: 15, position: 'absolute', padding: 10 }}
            content={
              <Box w="90px">
                <HStack alignItems="center">
                  <Box
                    w="10px"
                    h="10px"
                    bg={Number(percentage) >= 100 ? 'secondary.500' : 'error.500'}
                    rounded="full"
                  />
                  <Text ml={1} fontSize="10px">
                    Realizado Meta
                  </Text>
                </HStack>
                <HStack alignItems="center">
                  {!isPlusAccounts && (
                    <Text color="gray.300" fontSize="10px">
                      R${` `}
                    </Text>
                  )}
                  <Text fontSize="10px">{formatToReal(Number(real_value))}</Text>
                </HStack>
                <HStack alignItems="center">
                  <Text color="gray.300" fontSize="10px">
                    Pts{` `}
                  </Text>
                  <Text fontSize="10px">{real_points_value}</Text>
                </HStack>
                <HStack alignItems="center">
                  <Text color="gray.300" fontSize="10px">
                    %{` `}
                  </Text>
                  <Text fontSize="10px">{Number(percentage)}</Text>
                </HStack>
              </Box>
            }
            placement="top"
            onClose={() => setOpenTooltipRealized(false)}>
            <TouchableHighlight>
              <Text opacity={0}>Click</Text>
            </TouchableHighlight>
          </Tooltip>

          <Pressable left={-46} position="relative" onPress={() => setOpenTooltipRealized(true)}>
            <HStack alignItems="center" space={1}>
              <Icons.SummaryStatus
                color={Number(percentage) >= 100 ? 'secondary.500' : 'error.500'}
              />
              <Text fontSize="14px" color="gray.700" fontWeight={600} lineHeight="20px" mt="-2px">
                Realizado (%)
              </Text>
            </HStack>
          </Pressable>

          <Tooltip
            isVisible={openTooltipProjected}
            tooltipStyle={{ marginTop: isIos ? -4 : -24 }}
            contentStyle={{ borderRadius: 15, position: 'absolute', padding: 10 }}
            content={
              <Box w="90px">
                <HStack alignItems="center">
                  <Box
                    w="10px"
                    h="10px"
                    bg={
                      Number(projection_percent) >= 100
                        ? theme.colors.secondary[300]
                        : theme.colors.error[500]
                    }
                    rounded="full"
                  />
                  <Text ml={1} fontSize="10px">
                    Projetado Meta
                  </Text>
                </HStack>
                <HStack alignItems="center">
                  {!isPlusAccounts && (
                    <Text color="gray.300" fontSize="10px">
                      R${` `}
                    </Text>
                  )}
                  <Text fontSize="10px">{formatToReal(Number(goal_value))}</Text>
                </HStack>
                <HStack alignItems="center">
                  <Text color="gray.300" fontSize="10px">
                    Pts{` `}
                  </Text>
                  <Text fontSize="10px">{projection_points_value}</Text>
                </HStack>
                <HStack alignItems="center">
                  <Text color="gray.300" fontSize="10px">
                    %{` `}
                  </Text>
                  <Text fontSize="10px">{Number(projection_percent).toFixed(2)}</Text>
                </HStack>
              </Box>
            }
            placement="top"
            onClose={() => setOpenTooltipProjected(false)}>
            <TouchableHighlight>
              <Text opacity={0}>Click</Text>
            </TouchableHighlight>
          </Tooltip>

          <Pressable left={-84} position="relative" onPress={() => setOpenTooltipProjected(true)}>
            <HStack alignItems="center" space={1}>
              <Icons.SummaryStatus color={theme.colors.secondary[300]} projected />
              <Text fontSize="14px" color="gray.700" fontWeight={600} lineHeight="20px" mt="-2px">
                Projetado (%)
              </Text>
            </HStack>
          </Pressable>
        </HStack>

        <Text fontSize="10px" color="gray.300" fontWeight={600}>
          peso ({weight_value}%)
        </Text>
      </HStack>
    </VStack>
  )
}
