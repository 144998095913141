import { ResponseSuccess } from 'integration/types'

import { TNotReadResponse, TNotification, TParams } from './types'
import client from '../../client'

export const getNotReadNotifications = () =>
  client.get<ResponseSuccess<TNotReadResponse>>(`v1/notifications/not-read-count`)

export const getAllNotifications = (params?: TParams) =>
  client.get<ResponseSuccess<TNotification[]>>('v1/notifications', {
    params: { ...params, page: params?.currentPage ?? 1 }
  })

export const getDetailNotification = ({ id }: Pick<TNotification, 'id'>) =>
  client.get<ResponseSuccess<TNotification>>(`v1/notifications/${id}`)

export const pathMarkToUnreadNotification = ({ id }: Pick<TNotification, 'id'>) =>
  client.patch<ResponseSuccess<TNotification>>(`v1/notifications/${id}`, { is_read: false })

export const pathMarkToReadNotification = ({ id }: Pick<TNotification, 'id'>) =>
  client.patch<ResponseSuccess<TNotification>>(`v1/notifications/${id}`, { is_read: true })
