import { FunctionComponent, Suspense, lazy } from 'react'

import { Skeleton } from 'native-base'

import { ChargesProps } from './Charges.types'
import { useCharges } from './useCharges'

const ChargesDesktop = lazy(() => import('./Charges.desktop'))

const ChargesMobile = lazy(() => import('./Charges.mobile'))

export const Charges: FunctionComponent<ChargesProps> = (props) => {
  const charges = useCharges(props)

  const Component = charges.isMobile ? ChargesMobile : ChargesDesktop

  return (
    <Suspense fallback={<Skeleton minHeight="104px" mb={4} borderRadius="20px" />}>
      <Component {...charges} />
    </Suspense>
  )
}
