import { FunctionComponent } from 'react'

import { Icons } from 'atoms'
import LottieView from 'lottie-react-native'
import { Pressable, Button } from 'native-base'
import { Platform } from 'react-native'
import Animated, {
  Easing,
  useAnimatedProps,
  useSharedValue,
  withTiming
} from 'react-native-reanimated'
import { FAVORITE_TYPE } from 'src/screens/ContentScreen/ContentScreen.types'

type SavedContentProps = {
  handleTogglefavorite: (type: FAVORITE_TYPE) => void
  isLoading: boolean
  favorite: boolean
}

const AnimatedLottieView = Animated.createAnimatedComponent(LottieView)

export const SavedContent: FunctionComponent<SavedContentProps> = ({
  handleTogglefavorite,
  isLoading,
  favorite
}) => {
  const animatedProgress = useSharedValue(favorite ? 1 : 0)

  const animatedProps = useAnimatedProps(() => {
    return {
      progress: withTiming(animatedProgress.value, {
        duration: 2000,
        easing: Easing.linear
      })
    }
  })

  return Platform.OS === 'web' ? (
    <Button
      mb={{ base: 10, lg: 0 }}
      colorScheme="gray"
      isLoading={isLoading}
      variant={favorite ? 'outline' : 'solid'}
      _stack={{ justifyContent: 'center' }}
      onPress={() =>
        handleTogglefavorite(favorite ? FAVORITE_TYPE.UNFAVORITE : FAVORITE_TYPE.FAVORITE)
      }
      w="full"
      borderColor="gray.700"
      leftIcon={
        <Icons.Bookmark size="18px" color={favorite ? 'gray.700' : 'white'} filled={favorite} />
      }
      _text={{
        color: favorite ? 'gray.700' : 'white'
      }}>
      {favorite ? 'Salvo!' : 'Salvar conteúdo'}
    </Button>
  ) : (
    <Pressable
      isDisabled={isLoading}
      _pressed={{ opacity: 0.6 }}
      _disabled={{ opacity: 0.6 }}
      onPress={() => {
        animatedProgress.value = favorite ? 0 : 1

        handleTogglefavorite(favorite ? FAVORITE_TYPE.UNFAVORITE : FAVORITE_TYPE.FAVORITE)
      }}>
      <AnimatedLottieView
        autoPlay={false}
        animatedProps={animatedProps}
        source={require('assets/saved-content.json')}
        style={{
          width: '100%'
        }}
      />
    </Pressable>
  )
}
