import { useCallback, useEffect, useState } from 'react'

import { format } from 'date-fns'
import { useGetOpportunitiesSummaryQuery } from 'integration/resources/opportunities'
import { useOpportunityContainerList } from 'molecules/OpportunityContainerList/useOpportunityContainerList'
import { useBreakpointValue } from 'native-base'
import { LogEventScreen } from 'src/utils/logEvents'

import { UseOpportunityScreen } from './OpportunityScreen.types'

export const useOpportunityScreen: UseOpportunityScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [businessBlock, setBusinessBlock] = useState<string | undefined>()

  const { data: summaryOpportunities, isLoading: isLoadingOptionsOpportunities } =
    useGetOpportunitiesSummaryQuery()

  const opportunitiesOptions = summaryOpportunities?.data?.data
    ? summaryOpportunities?.data?.data?.map((item) => ({
        label: `${item.name.charAt(0).toUpperCase()}${item.name
          .slice(1, item.name.length)
          .toLowerCase()}`,
        value: String(item.id)
      }))
    : []

  const totalOpportunities: number[] = []

  summaryOpportunities?.data?.data.map((item) => (totalOpportunities[item.id] = item.total))

  const { isLoading, opportunities, onScrollEndDragOpportunityList } = useOpportunityContainerList({
    // @ts-ignore
    business_block_id: businessBlock
  })

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  const handleSelectBlock = (value: string) => setBusinessBlock(value)

  useEffect(() => {
    const date = new Date()

    const formated = format(date, 'dd-MM-yyyy HH:mm')

    LogEventScreen({
      screen_name: `Oportunidade - ${route?.params?.title}`,
      screen_class: `Oportunidade${route?.params?.title}`,
      date: formated,
      hora: format(date, 'HH'),
      minutos: format(date, 'mm')
    })

    if (route?.params?.business_block_id) {
      setBusinessBlock(route?.params?.business_block_id)
    }
  }, [route?.params?.business_block_id, route])

  return {
    isMobile,
    isLoading,
    opportunities,
    onScrollEndDragOpportunityList,
    handleGoBack,
    isLoadingOptionsOpportunities,
    opportunitiesOptions,
    totalOpportunities,
    handleSelectBlock,
    businessBlock
  }
}
