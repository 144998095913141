import { FunctionComponent } from 'react'

import { Cards } from 'atoms'
import { Category } from 'integration/resources/knowledgeBase'
import { Box, FlatList, HStack, Skeleton, useBreakpointValue } from 'native-base'

type CategoryListProps = {
  categoryList: Category[]
  onPressCategoryCard(_: Category): void
  isLoading?: boolean
  noTitle?: boolean
  fromCategoryDetail?: boolean
}

export const CategoryList: FunctionComponent<CategoryListProps> = ({
  categoryList = [],
  onPressCategoryCard,
  isLoading = false,
  fromCategoryDetail = false
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <Box>
      {!isLoading ? (
        <>
          {isMobile ? (
            <FlatList
              contentContainerStyle={{ paddingTop: fromCategoryDetail ? 0 : 16, paddingRight: 8 }}
              data={categoryList}
              horizontal
              scrollEnabled
              showsHorizontalScrollIndicator={false}
              keyExtractor={(item) => `${item.id}`}
              onEndReachedThreshold={0.1}
              renderItem={({ item, index }) => {
                if (!item.total) return <></>

                return (
                  <Box
                    w={{ base: '116px', lg: '158px' }}
                    {...(index === 0 && { ml: 4 })}
                    mr={4}
                    key={item.id}>
                    <Cards.CategoryContent
                      {...item}
                      onPress={() => onPressCategoryCard(item)}
                      fromCategoryDetail={fromCategoryDetail}
                    />
                  </Box>
                )
              }}
            />
          ) : (
            <HStack space={3} flexWrap="wrap">
              {categoryList?.map((item, index) => (
                <Box w={{ base: '116px', lg: '158px' }} mr={4} key={item.id} pb={3}>
                  <Cards.CategoryContent
                    {...item}
                    onPress={() => onPressCategoryCard(item)}
                    fromCategoryDetail={fromCategoryDetail}
                  />
                </Box>
              ))}
            </HStack>
          )}
        </>
      ) : (
        <HStack pl={4} paddingTop="16px">
          <Skeleton borderRadius="20px" w="116px" h="90px" ml={0} mr={2} />
          <Skeleton borderRadius="20px" w="116px" h="90px" ml={2} mr={2} />
          <Skeleton borderRadius="20px" w="116px" h="90px" ml={2} mr={2} />
        </HStack>
      )}
    </Box>
  )
}
