import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const GoOutIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 20', ...props }}>
    <G clipPath="url(#clip0_12302_219966)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.79999 1.83268C1.76021 1.83268 1.72206 1.85024 1.69393 1.8815C1.6658 1.91275 1.64999 1.95515 1.64999 1.99935V17.9994C1.64999 18.0436 1.6658 18.0859 1.69393 18.1172C1.72206 18.1485 1.76021 18.166 1.79999 18.166H9.89999C9.93978 18.166 9.97793 18.1485 10.0061 18.1172C10.0342 18.0859 10.05 18.0436 10.05 17.9994V14.1534C10.05 13.6931 10.3858 13.32 10.8 13.32C11.2142 13.32 11.55 13.6931 11.55 14.1534V17.9994C11.55 18.4856 11.3762 18.9519 11.0667 19.2957C10.7573 19.6395 10.3376 19.8327 9.89999 19.8327H1.79999C1.36239 19.8327 0.942704 19.6395 0.633268 19.2957C0.323832 18.9519 0.149994 18.4856 0.149994 17.9994V1.99935C0.149994 1.51312 0.323833 1.0468 0.633268 0.702987C0.942703 0.35917 1.36239 0.166016 1.79999 0.166016H9.89999C10.3376 0.166016 10.7573 0.35917 11.0667 0.702987C11.3762 1.0468 11.55 1.51312 11.55 1.99935V5.84535C11.55 6.30559 11.2142 6.67868 10.8 6.67868C10.3858 6.67868 10.05 6.30559 10.05 5.84535V1.99935C10.05 1.95515 10.0342 1.91275 10.0061 1.8815C9.97793 1.85024 9.93978 1.83268 9.89999 1.83268H1.79999Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54999 9.99935C5.54999 9.53911 5.88577 9.16602 6.29999 9.16602H17.1C17.5142 9.16602 17.85 9.53911 17.85 9.99935C17.85 10.4596 17.5142 10.8327 17.1 10.8327H6.29999C5.88577 10.8327 5.54999 10.4596 5.54999 9.99935Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8697 6.41009C14.1626 6.08466 14.6375 6.08466 14.9304 6.41009L17.6304 9.41009C17.9232 9.73553 17.9232 10.2632 17.6304 10.5886L14.9304 13.5886C14.6375 13.914 14.1626 13.914 13.8697 13.5886C13.5768 13.2632 13.5768 12.7355 13.8697 12.4101L16.0394 9.99935L13.8697 7.5886C13.5768 7.26317 13.5768 6.73553 13.8697 6.41009Z"
        fill="currentColor"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_12302_219966">
        <Rect width={18} height={20} fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)
