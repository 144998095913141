import { forwardRef } from 'react'

import { CustomBottomSheet, BottomSheetModal } from 'molecules'
import { VStack } from 'native-base'
import { useAuthAtomValue } from 'src/store/auth'

import { TypeformEmbed } from './embedTypeForm'

type TBottomSheetNps = {
  onClose(): void
  onSubmit(): void
  id: string
}

export const BottomSheetNps = forwardRef<BottomSheetModal, TBottomSheetNps>(
  ({ onClose, id, onSubmit }, ref) => {
    const authAtom = useAuthAtomValue()

    return (
      <CustomBottomSheet ref={ref} snapPoints={[450]} enableTapToClose={false}>
        <VStack h="100%">
          <TypeformEmbed
            url={`https://research.typeform.com/to/${id}#name=${authAtom?.user.name}&std=${authAtom?.user.std_code}`}
            onClose={onClose}
            onSubmit={onSubmit}
          />
        </VStack>
      </CustomBottomSheet>
    )
  }
)
