import { FunctionComponent, Suspense, lazy } from 'react'

import { useBreakpointValue, Skeleton } from 'native-base'

import { WalletCardProps } from './types'

const WalletCardDesktop = lazy(() => import('./WalletCard.desktop'))

const WalletCardMobile = lazy(() => import('./WalletCard.mobile'))

export const WalletCard: FunctionComponent<WalletCardProps> = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const Component = isMobile ? WalletCardMobile : WalletCardDesktop

  return (
    <Suspense fallback={<Skeleton minHeight="104px" mb={4} borderRadius="20px" />}>
      <Component {...props} />
    </Suspense>
  )
}
