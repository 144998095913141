import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  KEY_ALL_SURVEYS_QUERY,
  useGetAllSurveysInfinityQuery
} from 'integration/resources/surveys/hooks'
import { Survey } from 'integration/resources/surveys/types'
import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import useDebounce from 'src/hooks/useDebounce'

import { UseSurveyScreen } from './SurveyScreen.types'

export const useSurveyScreen: UseSurveyScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [searchVisible, setSearchVisible] = useState(false)

  const [search, setSearch] = useState<string>()

  const debouncedSearch = useDebounce(search, 500)

  const {
    data: surveysData,
    isLoading,
    refetch,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useGetAllSurveysInfinityQuery({ ...(debouncedSearch?.length && { search: debouncedSearch }) })

  const surveysList = useMemo(
    () =>
      surveysData?.pages.reduce<Survey[]>((previousValue, currentValue) => {
        return [...previousValue, ...currentValue.data.data] as Survey[]
      }, []),
    [surveysData?.pages]
  )

  const newSurveysQuantity = useMemo(
    () => surveysList?.filter((item) => !item.answered).length,
    [surveysList]
  )

  const onEndReached = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  const pagination = surveysData?.pages[0].data.metadata.pagination

  const queryClient = useQueryClient()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRefresh = () => queryClient.invalidateQueries([KEY_ALL_SURVEYS_QUERY])

  const handleGoBack = useCallback(() => {
    navigation.canGoBack() && navigation.goBack()
  }, [navigation])

  const handleGoToDetails = useCallback(
    (surveyId: string, surveyIsAnswered?: boolean) => {
      navigation.navigate('SurveyDetail', { id: surveyId, isAnswered: surveyIsAnswered })
    },
    [navigation]
  )

  useEffect(() => {
    onRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route])

  useEffect(() => {
    refetch()
  }, [debouncedSearch, refetch])

  return {
    isMobile,
    navigation,
    isLoading: isLoading || isFetching,
    isRefetching,
    surveysList,
    newSurveysQuantity,
    onEndReached,
    onRefresh,
    pagination,
    setSearchVisible,
    searchVisible,
    isFetchingNextPage,
    setSearch,
    search,
    handleGoBack,
    handleGoToDetails
  }
}
