import * as serializers from './serializers'
import {
  Apnef,
  ContractsAmountDataSerialized,
  CreditInsuranceDataSerialized,
  Fpd10DataSerialized,
  GetChartDataParams,
  MarketShareDataSerialized,
  OpenedAccountQuantityDataSerialized,
  Over30M3DataSerialized,
  PotentialDataSerialized,
  ProductionDataSerialized,
  ProposalFunnelSerialized,
  SantanderAutoInsuranceDataSerialized,
  SantanderAutoTypeDataSerialized,
  SpreadPercentageDataSerialized,
  WebmotorsDataSerialized
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const getProposalFunnel = async (tab_number: string) =>
  serializers.getProposalFunnel(
    await client.get<ResponseSuccess<ProposalFunnelSerialized>>(
      `v1/stores/${tab_number}/panels/proposal-funnel`
    )
  )

export const getApnef = async (tab_number: string) =>
  await client.get<ResponseSuccess<Apnef>>(`v1/stores/${tab_number}/panels/apnef`)

export const getProduction = async (params: GetChartDataParams) =>
  serializers.getProduction(
    await client.get<ResponseSuccess<ProductionDataSerialized>>(
      `v1/stores/${params.tab_number}/panels/production`,
      {
        params: {
          year_month_qty: params.year_month_qty
        }
      }
    )
  )

export const getPotential = async (params: GetChartDataParams) =>
  serializers.getPotential(
    await client.get<ResponseSuccess<PotentialDataSerialized>>(
      `v1/stores/${params.tab_number}/panels/potential`,
      {
        params: {
          year_month_qty: params.year_month_qty
        }
      }
    )
  )

export const getMarketShare = async (params: GetChartDataParams) =>
  serializers.getMarketShare(
    await client.get<ResponseSuccess<MarketShareDataSerialized>>(
      `v1/stores/${params.tab_number}/panels/market-share`,
      {
        params: {
          year_month_qty: params.year_month_qty
        }
      }
    )
  )

export const getSantanderAutoInsurance = async (params: GetChartDataParams) =>
  serializers.getSantanderAutoInsurance(
    await client.get<ResponseSuccess<SantanderAutoInsuranceDataSerialized>>(
      `v1/stores/${params.tab_number}/panels/santander-auto-insurance`,
      {
        params: {
          year_month_qty: params.year_month_qty
        }
      }
    )
  )

export const getOpenedAccountQuantity = async (params: GetChartDataParams) =>
  serializers.getOpenedAccountQuantity(
    await client.get<ResponseSuccess<OpenedAccountQuantityDataSerialized>>(
      `v1/stores/${params.tab_number}/panels/opened-accounts-quantity`,
      {
        params: {
          year_month_qty: params.year_month_qty
        }
      }
    )
  )

export const getWebmotors = async (params: GetChartDataParams) =>
  serializers.getWebmotors(
    await client.get<ResponseSuccess<WebmotorsDataSerialized>>(
      `v1/stores/${params.tab_number}/panels/webmotors`,
      {
        params: {
          year_month_qty: params.year_month_qty
        }
      }
    )
  )

export const getFpd10 = async (params: GetChartDataParams) =>
  serializers.getFpd10(
    await client.get<ResponseSuccess<Fpd10DataSerialized>>(
      `v1/stores/${params.tab_number}/panels/fpd10`,
      {
        params: {
          year_month_qty: params.year_month_qty
        }
      }
    )
  )

export const getOver30M3 = async (params: GetChartDataParams) =>
  serializers.getOver30M3(
    await client.get<ResponseSuccess<Over30M3DataSerialized>>(
      `v1/stores/${params.tab_number}/panels/over30-m3`,
      {
        params: {
          year_month_qty: params.year_month_qty
        }
      }
    )
  )

export const getContractsAmount = async (params: GetChartDataParams) =>
  serializers.getContractsAmount(
    await client.get<ResponseSuccess<ContractsAmountDataSerialized>>(
      `v1/stores/${params.tab_number}/panels/contracts-quantity`,
      {
        params: {
          year_month_qty: params.year_month_qty
        }
      }
    )
  )

export const getSpreadPercentage = async (params: GetChartDataParams) =>
  serializers.getSpreadPercentage(
    await client.get<ResponseSuccess<SpreadPercentageDataSerialized>>(
      `v1/stores/${params.tab_number}/panels/spread`,
      {
        params: {
          year_month_qty: params.year_month_qty
        }
      }
    )
  )

export const getCreditInsurance = async (params: GetChartDataParams) =>
  serializers.getCreditInsurance(
    await client.get<ResponseSuccess<CreditInsuranceDataSerialized>>(
      `v1/stores/${params.tab_number}/panels/credit-insurance`,
      {
        params: {
          year_month_qty: params.year_month_qty
        }
      }
    )
  )

export const getSantanderAutoType = async (params: GetChartDataParams) =>
  serializers.getSantanderAutoType(
    await client.get<ResponseSuccess<SantanderAutoTypeDataSerialized>>(
      `v1/stores/${params.tab_number}/panels/santander-auto-type`,
      {
        params: {
          year_month_qty: params.year_month_qty
        }
      }
    )
  )
