import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const CloseIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Path
      d="M1.707.512A1 1 0 0 0 .293 1.926L6.657 8.29.293 14.654a1 1 0 1 0 1.414 1.414l6.364-6.364 6.364 6.364a1 1 0 0 0 1.414-1.414L9.485 8.29l6.364-6.364A1 1 0 1 0 14.435.512L8.071 6.876 1.707.512Z"
      fill="currentColor"
    />
  </Icon>
)
