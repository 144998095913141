import { FunctionComponent } from 'react'

import Icons from 'atoms/Icons'
import {
  VStack,
  Heading,
  Text,
  Box,
  Divider,
  HStack,
  Circle,
  useBreakpointValue
} from 'native-base'
import { formatDateStringToMask } from 'src/utils'

type SummaryCardProps = {
  title: string
  subtitle: string
  riseUp: boolean
  description: {
    title: string
    value: string
  }[]
  updatedAt: string
  is_short_format?: boolean
}

export const SummaryCard: FunctionComponent<SummaryCardProps> = ({
  title,
  subtitle,
  description,
  updatedAt,
  riseUp,
  is_short_format = false
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <VStack
      mb={{
        base: 4,
        lg: 0
      }}
      py={{
        base: '14px',
        lg: 6
      }}
      px={{ base: 4, lg: 6 }}
      bg="white"
      borderRadius={20}
      minH={{
        base: 210,
        lg: 294
      }}>
      <VStack flex={1}>
        <HStack justifyContent="space-between">
          <Heading
            fontSize="24px"
            lineHeight="28px"
            fontWeight="bold"
            color="gray.700"
            numberOfLines={1}>
            {title}
          </Heading>

          <Circle
            style={{ transform: [{ rotate: riseUp ? '0deg' : '180deg' }] }}
            w={{
              base: 6,
              lg: 10
            }}
            h={{
              base: 6,
              lg: 10
            }}
            bg={riseUp ? 'secondary.50' : 'primary.50'}
            borderColor="background.base"
            borderWidth="1px">
            <Icons.ArrowUp color={riseUp ? 'secondary.500' : 'primary.500'} size={4} />
          </Circle>
        </HStack>

        <Text
          fontSize={{ base: '14px', lg: '16px' }}
          color="gray.400"
          lineHeight={{ base: '18px', lg: '22px' }}
          fontWeight="normal"
          mt={1}
          numberOfLines={1}>
          {subtitle}
        </Text>

        <Divider bg="gray.50" my={3} />

        {description.map((item) => (
          <Box key={`SummaryCard-${item.value}-${Math.random()}`} mb={{ base: 0, lg: 2 }}>
            <HStack justifyContent="space-between">
              <Text
                fontSize={{ base: '12px', lg: '14px' }}
                letterSpacing="0.2px"
                lineHeight={{ base: '18px', lg: '20px' }}
                color={{ base: 'gray.600', lg: 'gray.700' }}
                fontWeight={700}
                numberOfLines={1}>
                {item.title}
              </Text>
              <Text
                fontSize={{ base: '12px', lg: '14px' }}
                color="gray.500"
                lineHeight={{ base: '18px', lg: '20px' }}
                fontWeight="normal"
                numberOfLines={1}>
                {item.value}
              </Text>
            </HStack>
          </Box>
        ))}

        {updatedAt && (
          <Text
            fontSize="10px"
            fontWeight="normal"
            color="gray.200"
            mt="auto"
            letterSpacing={0.3}
            numberOfLines={1}>
            {isMobile ? 'Referência' : 'Data de Ref.'}{' '}
            {formatDateStringToMask(updatedAt, is_short_format ? 'MM/yyyy' : 'dd/MM/yyyy')}
          </Text>
        )}

        {subtitle.includes('Produção') && (
          <Heading
            pt={1}
            fontWeight={700}
            fontSize="10px"
            lineHeight="16px"
            color="tertiary.800"
            textTransform="uppercase">
            Veja mais
          </Heading>
        )}
      </VStack>
    </VStack>
  )
}
