import * as serializers from './serializers'
import { Category, Content, ContentSerialized, GetKnowledgeBasesParams } from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const getKnowledgeBases = async (params?: GetKnowledgeBasesParams) => {
  const response = await client.get<ResponseSuccess<Content[]>>(`v1/knowledge-bases`, {
    params: {
      ...params,
      order_by: params?.order_by ?? 'updated_at',
      per_page: params?.per_page ?? 3,
      page: params?.page ?? 1
    }
  })

  return serializers.getKnowledgeBases(response)
}

export const getKnowledge = async (id: string) =>
  serializers.getKnowledge(
    await client.get<ResponseSuccess<ContentSerialized>>(`v1/knowledge-bases/${id}`)
  )

export const getCategories = () =>
  client.get<ResponseSuccess<Category[]>>(`v1/knowledge-bases/categories`)

export const favoriteKnowledge = async ({ id }: Pick<Content, 'id'>) =>
  await client.post<ResponseSuccess<Pick<Content, 'id'>>>(`v1/knowledge-bases/${id}/favorite`)

export const unFavoriteKnowledge = async ({ id }: Pick<Content, 'id'>) =>
  await client.delete<ResponseSuccess<Pick<Content, 'id'>>>(`v1/knowledge-bases/${id}/favorite`)
