import { FunctionComponent } from 'react'

import { Icons } from 'atoms'
import { Center, HStack, Text } from 'native-base'

export const DeletedOpportunity: FunctionComponent = () => (
  <HStack
    borderWidth="1px"
    borderRadius="20px"
    borderColor="white"
    p={4}
    space={{ base: '10px', lg: 6 }}
    alignItems="center">
    <Center
      borderWidth="1px"
      borderRadius="full"
      borderColor="white"
      p={1}
      size={{ base: 6, lg: 12 }}>
      <Icons.Warning size={{ base: 3, lg: 6 }} color="white" />
    </Center>
    <Text
      fontSize={{ base: '14px', lg: '16px' }}
      fontWeight={400}
      lineHeight={{ base: '18px', lg: '24px' }}
      color="white"
      flexWrap="wrap"
      maxW={{ base: '90%', lg: undefined }}>
      Esta oportunidade não está mais ativa. Vá em “Editar” e vincule uma nova oportunidade.
    </Text>
  </HStack>
)
