import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ThumbsUpIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled = false,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 12 12', ...props }}>
    <Path
      key={Math.random()}
      d="M11.4801 4.36602C11.1934 4.02602 10.7801 3.83268 10.3334 3.83268L7.64675 3.83268L7.64675 1.99935C7.64675 0.986016 6.82675 0.166016 5.76008 0.166016C5.29342 0.166016 4.87341 0.446016 4.68675 0.87935L2.99341 4.83268L1.74675 4.83268C0.873414 4.83268 0.166748 5.53935 0.166748 6.41268L0.166747 10.2593C0.166747 11.126 0.88008 11.8327 1.74675 11.8327L9.45341 11.8327C10.1801 11.8327 10.8001 11.3127 10.9267 10.5927L11.8067 5.59268C11.8801 5.15268 11.7601 4.70602 11.4734 4.36602L11.4801 4.36602ZM1.74675 10.8327C1.42675 10.8327 1.16675 10.5727 1.16675 10.2593L1.16675 6.41268C1.16675 6.09268 1.42675 5.83268 1.74675 5.83268L2.82008 5.83268L2.82008 10.8327L1.74675 10.8327ZM9.94675 10.4193C9.90675 10.6593 9.70008 10.8327 9.45341 10.8327L3.82675 10.8327L3.82675 5.43268L5.60675 1.26602C5.63341 1.20602 5.69341 1.15935 5.82008 1.15935C6.28008 1.15935 6.64675 1.53268 6.64675 1.99268L6.64675 4.82602L10.3334 4.82602C10.4867 4.82602 10.6201 4.88602 10.7134 4.99935C10.8067 5.11268 10.8467 5.25935 10.8201 5.41268L9.94008 10.4127L9.94675 10.4193Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </Icon>
)
