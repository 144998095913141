import { FunctionComponent } from 'react'

import { Path, Svg } from 'react-native-svg'
import { resize } from 'src/utils'

type FileIconProps = {
  size?: number
}

export const FileIcon: FunctionComponent<FileIconProps> = ({ size = 35 }) => {
  const { height, width } = resize({ height: 35, size, width: 27 })

  return (
    <Svg {...{ viewBox: '0 0 27 35', width, height }}>
      <Path
        d="M15.1666 12V12.5H15.6666H24H25.2071L24.3535 11.6464L16.0202 3.31307L15.1666 2.45952V3.66663V12ZM3.99996 0.833252H16.5049C16.9027 0.833252 17.2842 0.991287 17.5655 1.27259L26.3939 10.101C26.6753 10.3823 26.8333 10.7639 26.8333 11.1617V30.3333C26.8333 31.0847 26.5348 31.8054 26.0034 32.3367C25.4721 32.8681 24.7514 33.1666 24 33.1666H3.99996C3.24851 33.1666 2.52784 32.8681 1.99649 32.3367C1.46514 31.8054 1.16663 31.0847 1.16663 30.3333V3.66659C1.16663 2.91514 1.46514 2.19447 1.99649 1.66312C2.52784 1.13176 3.24851 0.833252 3.99996 0.833252Z"
        fill="white"
        stroke="#4D4D4D"
      />
    </Svg>
  )
}
