import { FunctionComponent, useEffect, useState } from 'react'

import { HStack, Button } from 'native-base'

type InsightsProps = {
  handleSelectInsght(insght?: string): void
  isOpen: boolean
}

export const INSIGHTS = [
  {
    title: 'Potencial baixo',
    value: '1'
  },
  {
    title: 'Crédito restrito',
    value: '2'
  },
  {
    title: 'Potencial irreal',
    value: '3'
  },
  {
    title: 'Falta de dados',
    value: '4'
  }
]

export const Insights: FunctionComponent<InsightsProps> = ({ handleSelectInsght, isOpen }) => {
  const [insightSelected, setInsightSelected] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!isOpen) {
      setInsightSelected(undefined)
    }
  }, [isOpen])

  return (
    <HStack flexWrap="wrap" w="full" space={4}>
      {INSIGHTS.map(({ title, value }, index) => (
        <Button
          key={`${title}.${value}`}
          h={10}
          w="full"
          maxW="163px"
          mt={4}
          onPress={() => {
            setInsightSelected(value)

            handleSelectInsght(value)
          }}
          _pressed={{
            bg: 'transparent',
            opacity: 0.5
          }}
          {...(insightSelected !== value
            ? {
                bg: 'gray.50'
              }
            : { bg: 'primary.500' })}
          _text={{
            color: insightSelected !== value ? 'gray.500' : 'white',
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 400
          }}>
          {title}
        </Button>
      ))}
    </HStack>
  )
}
