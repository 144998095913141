import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const OrderByIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 24 25', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0862 21.2462C14.534 21.2542 14.0799 20.813 14.0719 20.2607L13.9118 9.18046C13.9038 8.62824 14.345 8.1741 14.8972 8.16612C15.4495 8.15814 15.9036 8.59934 15.9116 9.15157L16.0717 20.2318C16.0797 20.7841 15.6385 21.2382 15.0862 21.2462Z"
      fill="#C2C2C2"
    />
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7306 16.5871C19.1211 16.9776 19.1211 17.6108 18.7306 18.0013L15.7786 20.9532C15.3881 21.3437 14.755 21.3437 14.3644 20.9532L11.4125 18.0013C11.022 17.6108 11.022 16.9776 11.4125 16.5871C11.803 16.1966 12.4362 16.1966 12.8267 16.5871L15.0715 18.8319L17.3163 16.5871C17.7069 16.1966 18.34 16.1966 18.7306 16.5871Z"
      fill="#C2C2C2"
    />
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.95536 3.16602C9.50764 3.16804 9.95372 3.61738 9.95171 4.16966L9.91161 15.1697C9.90959 15.7219 9.46025 16.168 8.90797 16.166C8.35569 16.164 7.90961 15.7147 7.91162 15.1624L7.95172 4.16237C7.95373 3.61009 8.40308 3.16401 8.95536 3.16602Z"
      fill="#404040"
    />
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29289 7.82519C4.90237 7.43467 4.90237 6.8015 5.29289 6.41098L8.2448 3.45907C8.63533 3.06855 9.26849 3.06855 9.65902 3.45907L12.6109 6.41098C13.0014 6.8015 13.0014 7.43467 12.6109 7.82519C12.2204 8.21572 11.5872 8.21572 11.1967 7.82519L8.95191 5.58039L6.70711 7.82519C6.31658 8.21572 5.68342 8.21572 5.29289 7.82519Z"
      fill="#404040"
    />
  </Icon>
)
