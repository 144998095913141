import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const AndroidLogoIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      d="M12 7.04395C5.7135 7.04395 0.554 11.8579 0 17.9999H24C23.446 11.8579 18.2865 7.04395 12 7.04395ZM16.5895 13.5394C16.5895 12.9874 17.037 12.5399 17.589 12.5399C18.141 12.5399 18.5885 12.9874 18.5885 13.5394C18.5885 14.0914 18.141 14.5389 17.589 14.5389C17.037 14.5389 16.5895 14.0914 16.5895 13.5394ZM6.411 14.5389C5.859 14.5389 5.4115 14.0914 5.4115 13.5394C5.4115 12.9874 5.859 12.5399 6.411 12.5399C6.963 12.5399 7.4105 12.9874 7.4105 13.5394C7.4105 14.0914 6.963 14.5389 6.411 14.5389Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      d="M17.019 9.6565C16.949 9.6565 16.8785 9.639 16.814 9.6015C16.6175 9.488 16.5505 9.237 16.6635 9.0405L19.262 4.5365C19.3755 4.3395 19.6265 4.272 19.823 4.386C20.0195 4.4995 20.0865 4.7505 19.9735 4.947L17.375 9.451C17.299 9.583 17.161 9.6565 17.019 9.6565Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      d="M6.98104 9.6565C6.83904 9.6565 6.70104 9.5825 6.62504 9.451L4.02704 4.947C3.91354 4.7505 3.98104 4.4995 4.17704 4.386C4.37304 4.272 4.62454 4.3395 4.73804 4.5365L7.33654 9.0405C7.45004 9.2375 7.38254 9.4885 7.18604 9.6015C7.12154 9.639 7.05104 9.6565 6.98104 9.6565Z"
      fill="currentColor"
    />
  </Icon>
)
