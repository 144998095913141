import { useCallback } from 'react'

import { useBreakpointValue } from 'native-base'
import { CreatePasswordFormData } from 'src/components/templates/CreatePasswordScreenTemplate/CreatePasswordScreenTemplate'
import { useBottomSheetContext } from 'src/contexts/BottomSheetContext'
import { useSendAuthCode } from 'src/hooks/useSendAuthCode'
import { usePreResetPasswordMutation } from 'src/integration/resources/resetPassword'
import { useSetAuthCodeAtom } from 'src/store/authCode'

import { UseFirstLoginPasswordScreen } from './FirstLoginPasswordScreen.types'

export const useFirstLoginPasswordScreen: UseFirstLoginPasswordScreen = ({ navigation, route }) => {
  const { mutate, isLoading } = usePreResetPasswordMutation()

  const setAuthCodeAtom = useSetAuthCodeAtom()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const bottomSheetContext = useBottomSheetContext()

  const { handleSendAuthCode, isLoadingAuthCode } = useSendAuthCode()

  const submit = useCallback(
    (formData: CreatePasswordFormData) => {
      mutate(
        {
          ...formData,
          ...route.params
        },
        {
          onError: () => {
            bottomSheetContext.open({
              description: 'Não foi possível redefinir sua senha.',
              title: 'Erro ao redefinir senha'
            })
          },
          onSuccess: (responseData) => {
            setAuthCodeAtom({
              user_id: route.params.id,
              slt: responseData.headers['x-slt'],
              email: '',
              phone_number_cell: responseData.data.data?.phone_number_cell ?? '(xx) xxxxx-xxxx'
            })

            handleSendAuthCode('sms', {
              user_id: route.params.id,
              slt: responseData.headers['x-slt']
            })
              .then(() => {
                // redirect to authcode
                navigation.navigate('FirstLoginAuthCode', {
                  ...formData,
                  ...route.params,
                  slt: responseData.headers['x-slt']
                })
              })
              .catch(() => {
                bottomSheetContext.open({
                  illustrationName: 'TimeInForWork',
                  description:
                    'Esta ação está indisponível por excesso de tentativas com falha. Tente novamente em algumas horas.',
                  title: 'Atenção'
                })
              })
          }
        }
      )
    },
    [bottomSheetContext, navigation, mutate, route.params, setAuthCodeAtom, handleSendAuthCode]
  )

  return { isMobile, isLoading: isLoading || isLoadingAuthCode, submit }
}
