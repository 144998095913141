import { FunctionComponent, memo, useEffect, useState } from 'react'

import { Avatar, Icons } from 'atoms'
import {
  Center,
  Heading,
  HStack,
  Text,
  useTheme,
  VStack,
  useBreakpointValue,
  Box,
  Button,
  Progress
} from 'native-base'
import { TouchableOpacity, ActivityIndicator, View, Dimensions } from 'react-native'

type OrganizationCardProps = {
  topText: string
  midText?: string
  bottomText?: string
  userName?: string
  nameFantasy?: string
  tabNumber?: string
  favorite?: boolean
  icon: 'Dollar' | 'Check'
  active?: boolean
  onPress?: () => void
  onCheckPress?: (fn: (a: boolean) => void, a: boolean) => void
  bgColor?: string
  organizationType?: string
  isDesktop?: boolean
  expandedDetail?: boolean
  cover?: string
  handleFavorite?: () => void
  disableHandleFavorite?: boolean
  isLoadingFavorite?: boolean
  points?: number
  opportunityTags?: { offer: string; action: string }
  attachments?: string
  planActions?: {
    is_finished: string
    total: string
  }
  hour?: string
  period_infos?: {
    percentage?: number
    schedule_date_start?: string
    schedule_date_end?: string
    total_days?: number
    past_days?: number
  }
}

export const OrganizationCard: FunctionComponent<OrganizationCardProps> = memo(
  ({
    topText,
    midText,
    bottomText,
    userName,
    nameFantasy,
    tabNumber,
    favorite,
    icon,
    hour,
    expandedDetail = false,
    active,
    cover,
    onPress,
    bgColor = 'white',
    onCheckPress,
    handleFavorite,
    isDesktop,
    isLoadingFavorite,
    disableHandleFavorite,
    points,
    organizationType,
    attachments,
    planActions,
    period_infos,
    opportunityTags
  }) => {
    const [checked, setChecked] = useState(false)

    const shortWidth = Dimensions.get('window').width - 223 < 1233

    const progressBarColor =
      period_infos?.percentage || period_infos?.percentage === 0
        ? period_infos?.percentage > 66
          ? 'tertiary.800'
          : period_infos?.percentage > 33
          ? 'tabs-yellow.50'
          : 'error.500'
        : 'white'

    const theme = useTheme()

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
      active !== undefined && setChecked(active)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active])

    const IconComponent = Icons[icon]

    const iconColor = icon === 'Check' ? 'tertiary.500' : 'secondary.500'

    const isMobile = useBreakpointValue({ base: true, lg: false })

    return (
      <>
        {isMobile || !expandedDetail ? (
          <VStack
            bgColor={bgColor}
            overflow="hidden"
            borderBottomRadius={26}
            borderTopRadius={!period_infos?.schedule_date_start ? 26 : 0}
            pt={!period_infos?.schedule_date_start ? 4 : 0}
            pb={4}>
            {period_infos?.schedule_date_start && (
              <VStack w="full">
                <Progress
                  flex={1}
                  borderRadius={0}
                  h={1}
                  _filledTrack={{
                    bg: progressBarColor
                  }}
                  value={period_infos?.percentage}
                />
                <Box justifyContent="flex-end" w="full" alignItems="flex-end" px={4} pt={1}>
                  <Text fontSize="10px" fontWeight={600} lineHeight="14px" color="gray.400">
                    <Text color={progressBarColor}>{period_infos?.past_days}</Text>/
                    {period_infos?.total_days} dia
                    {period_infos?.total_days && period_infos?.total_days > 1 ? 's' : ''}
                  </Text>
                </Box>
              </VStack>
            )}

            <HStack space={4} alignItems="center" w="full" px={4}>
              <TouchableOpacity
                onPress={() => {
                  if (!onCheckPress) {
                    return
                  }

                  setIsLoading(true)

                  onCheckPress?.((value: boolean) => {
                    setChecked(value)

                    setIsLoading(false)
                  }, active ?? false)
                }}>
                <Center h={12} w={12}>
                  <View
                    style={[
                      {
                        backgroundColor: checked ? theme.colors.tertiary[50] : theme.colors.white,
                        borderColor: checked
                          ? theme.colors.tertiary[50]
                          : theme.colors.background.base,
                        borderWidth: 1,
                        borderRadius: 30,
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 48,
                        width: 48,
                        zIndex: 1,
                        top: 0,
                        left: 0,
                        opacity: 1
                      }
                    ]}>
                    {isLoading ? (
                      <ActivityIndicator />
                    ) : (
                      <IconComponent color={checked ? iconColor : 'gray.700'} />
                    )}
                  </View>
                </Center>
              </TouchableOpacity>

              <VStack flex={1}>
                <TouchableOpacity onPress={onPress}>
                  <Heading
                    fontSize="14px"
                    lineHeight="18px"
                    fontWeight="bold"
                    color="gray.700"
                    noOfLines={1}
                    mb={{ base: 1, lg: 2 }}>
                    {topText}
                  </Heading>

                  {icon === 'Check' && !isDesktop ? (
                    <>
                      {(!!midText || !!bottomText) && (
                        <HStack alignItems="center" mb={{ base: 1, lg: 2 }}>
                          <Text
                            fontSize="12px"
                            lineHeight="16px"
                            fontWeight="normal"
                            color="gray.400"
                            noOfLines={1}>
                            {midText} •{' '}
                          </Text>

                          <Text
                            fontSize="12px"
                            lineHeight="16px"
                            fontWeight="normal"
                            color="gray.400"
                            noOfLines={1}>
                            {bottomText}
                          </Text>
                        </HStack>
                      )}

                      {!!userName && (
                        <Text
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="normal"
                          color="gray.400"
                          mb={{ base: 1, lg: 2 }}
                          noOfLines={1}
                          textTransform="capitalize"
                          isTruncated
                          maxW="60%">
                          {userName}
                        </Text>
                      )}
                    </>
                  ) : (
                    <>
                      {(!!midText || !!bottomText) && (
                        <Text
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="normal"
                          color="gray.400"
                          mb={{ base: 1, lg: 2 }}
                          noOfLines={1}>
                          {midText}
                        </Text>
                      )}

                      <HStack alignItems="center" mb={{ base: 1, lg: 2 }}>
                        <Text
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="normal"
                          color="gray.400"
                          noOfLines={1}>
                          {bottomText}
                        </Text>

                        {!!userName && (
                          <>
                            <Text
                              fontSize="12px"
                              lineHeight="16px"
                              fontWeight="normal"
                              color="gray.400"
                              noOfLines={1}
                              textTransform="capitalize"
                              isTruncated
                              maxW="60%">
                              {' '}
                              • {userName}
                            </Text>
                          </>
                        )}
                      </HStack>
                      <HStack space={1} alignItems="center" mb={{ base: 1, lg: 2 }}>
                        <Text
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="normal"
                          color="gray.400"
                          noOfLines={1}>
                          Pontos na RV:
                        </Text>
                        <Text
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="normal"
                          color={points ? 'secondary.500' : 'gray.400'}
                          noOfLines={1}>
                          {points ? `+${points}` : 'Não atribuído'}
                        </Text>
                      </HStack>
                    </>
                  )}
                </TouchableOpacity>
              </VStack>
            </HStack>
          </VStack>
        ) : (
          <VStack
            bgColor={bgColor}
            overflow="hidden"
            borderBottomRadius={26}
            borderTopRadius={!period_infos?.schedule_date_start ? 26 : 0}
            pt={!period_infos?.schedule_date_start ? 4 : 0}
            pb={4}>
            {period_infos?.schedule_date_start && (
              <VStack w="full">
                <Progress
                  borderRadius={0}
                  h={2}
                  _filledTrack={{
                    bg: progressBarColor
                  }}
                  value={period_infos?.percentage}
                />
                <Box justifyContent="flex-end" w="full" alignItems="flex-end" px={6}>
                  <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
                    <Text color={progressBarColor}>{period_infos?.past_days}</Text>/
                    {period_infos?.total_days} dia
                    {period_infos?.total_days && period_infos?.total_days > 1 ? 's' : ''}
                  </Text>
                </Box>
              </VStack>
            )}
            <HStack space={4} alignItems="center" w="full" px={6}>
              <TouchableOpacity
                onPress={() => {
                  if (!onCheckPress) {
                    return
                  }

                  setIsLoading(true)

                  onCheckPress?.((value: boolean) => {
                    setChecked(value)

                    setIsLoading(false)
                  }, active ?? false)
                }}>
                <Center h={12} w={12}>
                  <View
                    style={[
                      {
                        backgroundColor: checked ? theme.colors.tertiary[50] : theme.colors.white,
                        borderColor: checked
                          ? theme.colors.tertiary[50]
                          : theme.colors.background.base,
                        borderWidth: 1,
                        borderRadius: 30,
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 48,
                        width: 48,
                        zIndex: 1,
                        top: 0,
                        left: 0,
                        opacity: 1
                      }
                    ]}>
                    {isLoading ? (
                      <ActivityIndicator />
                    ) : (
                      <IconComponent color={checked ? iconColor : 'gray.700'} />
                    )}
                  </View>
                </Center>
              </TouchableOpacity>

              <VStack flex={1}>
                <TouchableOpacity onPress={onPress}>
                  <Box flexDir="row" justifyContent="space-between" alignItems="center">
                    {/* This section is temporarily disabled
                    {!!hour && (
                      <Box>
                        <Text
                          fontSize="18px"
                          lineHeight="24px"
                          mr={6}
                          fontWeight="normal"
                          color="gray.400">
                          {hour} h
                        </Text>
                      </Box>
                    )} */}

                    <HStack space={6} alignItems="center">
                      <Heading
                        fontSize="16px"
                        lineHeight="22px"
                        fontWeight={400}
                        color="gray.700"
                        numberOfLines={2}
                        w={shortWidth ? '120px' : '217px'}>
                        {topText}
                      </Heading>

                      <HStack>
                        <Box
                          w="40px"
                          h="40px"
                          mr="16px"
                          flexDir="row"
                          alignItems="center"
                          justifyContent="center"
                          borderWidth={1}
                          borderColor="#ECEDF2"
                          borderRadius={100}
                          overflow="hidden"
                          display={{
                            base: 'none',
                            xl: 'flex'
                          }}>
                          <Avatar
                            uri={cover}
                            size="sm"
                            title={nameFantasy ?? 'minha tarefa'}
                            type="light"
                            variant="white"
                          />
                        </Box>
                        <Box w={shortWidth ? '160px' : '224px'}>
                          <Text
                            fontSize={{
                              base: '13px',
                              xl: '16px'
                            }}
                            lineHeight="22px"
                            fontWeight={400}
                            color="gray.400"
                            textTransform="capitalize"
                            isTruncated>
                            {nameFantasy ?? 'minha tarefa'}
                          </Text>
                          {!!tabNumber && (
                            <Text
                              fontSize="14px"
                              lineHeight="20px"
                              fontWeight={600}
                              color="gray.400">
                              TAB {tabNumber}
                            </Text>
                          )}
                        </Box>
                      </HStack>

                      {!!bottomText && !organizationType && (
                        <Box flex={1} w={shortWidth ? '92px' : '120px'}>
                          <Text fontSize="16px" fontWeight={400} lineHeight="22px" color="gray.400">
                            Potencial
                          </Text>
                          <Text
                            fontSize="14px"
                            lineHeight="20px"
                            fontWeight="normal"
                            color="gray.400">
                            {bottomText}
                          </Text>
                        </Box>
                      )}
                    </HStack>

                    <HStack alignItems="center" space={4}>
                      {organizationType ? (
                        <Center
                          h={6}
                          py={1}
                          px={4}
                          mr="16px"
                          borderWidth={1}
                          borderColor="tertiary.800"
                          backgroundColor="tertiary.800"
                          display={{
                            base: 'none',
                            xl: 'flex'
                          }}
                          borderRadius="full">
                          <Heading fontSize="12px" color="white" textTransform="uppercase">
                            {organizationType}
                          </Heading>
                        </Center>
                      ) : (
                        <HStack space={1} alignItems="center">
                          {(opportunityTags?.offer || opportunityTags?.action) && (
                            <Center
                              h={6}
                              py={1}
                              px={4}
                              display={{
                                base: 'none',
                                xl: 'flex'
                              }}
                              borderRadius="full"
                              borderWidth="1px"
                              bg={`${opportunityTags?.offer ? 'second' : 'prim'}ary.50`}
                              borderColor={`${opportunityTags?.offer ? 'second' : 'prim'}ary.800`}>
                              <Heading
                                maxW={shortWidth ? '80px' : '150px'}
                                fontSize="12px"
                                isTruncated
                                color={`${opportunityTags?.offer ? 'second' : 'prim'}ary.${
                                  opportunityTags?.offer ? '8' : '3'
                                }00`}
                                textTransform="uppercase">
                                {opportunityTags?.offer ?? opportunityTags?.action}
                              </Heading>
                            </Center>
                          )}
                          {opportunityTags?.offer && opportunityTags?.action && (
                            <Center
                              w="22px"
                              h={5}
                              p="2px"
                              display={{
                                base: 'none',
                                xl: 'flex'
                              }}
                              backgroundColor="tertiary.800"
                              borderRadius="full">
                              <Text fontSize="12px" color="white" bold>
                                +1
                              </Text>
                            </Center>
                          )}
                        </HStack>
                      )}
                      <Center
                        h={6}
                        py={1}
                        px={4}
                        borderWidth={1}
                        borderColor="gray.700"
                        borderRadius="full">
                        <Heading fontSize="12px" color="gray.700" textTransform="uppercase">
                          {points} PTS
                        </Heading>
                      </Center>

                      {!!planActions && (
                        <Box flexDir="row" alignItems="center" justifyContent="flex-end">
                          <Box flexDir="row">
                            <Icons.Attachment color="gray.400" size={4} mt={0.5} mr={1} />
                            <Text
                              fontSize="16px"
                              lineHeight="21px"
                              fontWeight={400}
                              color="gray.400">
                              {attachments}
                            </Text>
                          </Box>
                          <Box flexDir="row" ml={5}>
                            <Icons.CheckConcluded color="gray.400" mt={0.5} mr={1} />
                            <Text
                              fontSize="16px"
                              lineHeight="21px"
                              fontWeight={400}
                              color="gray.400">
                              {`${planActions?.is_finished}/${planActions?.total}`}
                            </Text>
                          </Box>
                        </Box>
                      )}

                      {!!handleFavorite && (
                        <Button
                          isDisabled={disableHandleFavorite}
                          onPress={handleFavorite}
                          h={10}
                          w={10}
                          borderRadius="full"
                          isLoading={isLoadingFavorite}
                          colorScheme="dark"
                          _spinner={{
                            color: 'gray.700'
                          }}
                          _loading={{
                            bgColor: 'gray.70'
                          }}
                          bgColor="gray.30">
                          <Center>
                            <Icons.Star
                              size={4}
                              filled={favorite}
                              color={favorite ? 'tabs-yellow.50' : 'gray.200'}
                            />
                          </Center>
                        </Button>
                      )}
                    </HStack>
                  </Box>
                </TouchableOpacity>
              </VStack>
            </HStack>
          </VStack>
        )}
      </>
    )
  }
)
