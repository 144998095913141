import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { SurveyScreenComponent } from './SurveyScreen.types'
import { useSurveyScreen } from './useSurveyScreen'

const SurveyScreenDesktop = lazy(() => import('./SurveyScreen.desktop'))

const SurveyScreenMobile = lazy(() => import('./SurveyScreen.mobile'))

export const SurveyScreen: SurveyScreenComponent = ({ navigation, route }) => {
  const props = useSurveyScreen({ navigation, route })

  const Screen = props.isMobile ? SurveyScreenMobile : SurveyScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
