import { ActiveOffers } from './ActiveOffers/ActiveOffers'
import { Charges } from './Charges/Charges'
import { Comments } from './Comments/Comments'
import { Header } from './Header/Header'
import { IrsInfos } from './IrsInfos/IrsInfos'
import { OpportunityInfos } from './OpportunityInfos/OpportunityInfos'
import { Policies } from './Policies/Policies'
import { Recommendations } from './Recommendations/Recommendations'
import { RelatedContents } from './RelatedContents'
import { RelatedTasks } from './RelatedTasks/RelatedTasks'
import { TabInfos } from './TabInfos/TabInfos'
import { XRay } from './XRay/XRay'

export default {
  ActiveOffers,
  Charges,
  Comments,
  Header,
  IrsInfos,
  Policies,
  Recommendations,
  RelatedContents,
  RelatedTasks,
  OpportunityInfos,
  TabInfos,
  XRay
}
