import { useState } from 'react'

import { useDisclose } from 'native-base'

import { UseComments } from './Comments.types'

export const useComments: UseComments = ({
  opportunityDetails,
  isLoading,
  expandCommentDetails,
  handleExpandCommentDetails,
  handleOpenCommentListModal,
  commentListModalIsOpen,
  handleCloseCommentListModal,
  isMobile
}) => {
  //@ts-ignore
  const commentsData = opportunityDetails?.comments

  const sliderDots = commentsData.slice(0, 3)

  const { isOpen, onClose, onOpen } = useDisclose()

  const [detailsToOpen, setDetailsToOpen] = useState<number | undefined>()

  const handleOpenDetails = (id?: number) => setDetailsToOpen(id)

  return {
    opportunityDetails,
    isLoading,
    expandCommentDetails,
    handleExpandCommentDetails,
    handleOpenCommentListModal,
    commentListModalIsOpen,
    handleCloseCommentListModal,
    isMobile,
    commentsData,
    sliderDots,
    isOpen,
    onClose,
    onOpen,
    detailsToOpen,
    handleOpenDetails
  }
}
