import { FunctionComponent, useState } from 'react'

import { Button, HStack } from 'native-base'

type TBottomSheetJob = {
  value: string | undefined
  onSelect?(): void
  onChange(value: string): void
  items: string[]
}

export const JobsDropdown: FunctionComponent<TBottomSheetJob> = ({
  onSelect,
  value,
  onChange,
  items
}) => {
  const [jobSelected, setJobSelected] = useState(value)

  return (
    <HStack flexWrap="wrap" maxW="full">
      {items.map((_job) => {
        return (
          <Button
            key={_job}
            h="24px"
            p={0}
            px={4}
            mb={4}
            mr={3}
            onPress={() => [setJobSelected(_job), onChange(_job)]}
            _pressed={{
              bg: 'gray.200'
            }}
            bg={jobSelected !== _job ? 'gray.200' : 'tertiary.800'}
            _text={{
              color: 'white',
              fontSize: '12px',
              lineHeight: '16px'
            }}>
            {_job}
          </Button>
        )
      })}
    </HStack>
  )
}
