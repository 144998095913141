import { FunctionComponent } from 'react'

import { Icons, SlideInRightView } from 'atoms'
import { Button, Heading, HStack, Text, VStack } from 'native-base'
import Layouts from 'organisms/Layouts'
import { Dimensions, StyleSheet } from 'react-native'
import { CodeField, Cursor } from 'react-native-confirmation-code-field'

import { useAuthCodeScreen } from './useAuthCodeScreen'

type AuthCodeScreenTemplateProps = {
  errorCode?: string | undefined
  isSubmitting: boolean
  submit(values: FormData): void
  handleSendAuthCodeEmail?(): void
  handleSendAuthCodeSms(): void
  isLoadingAuthSendCodeEmail?: boolean
  isLoadingAuthSendCodeSms: boolean
}

type FormData = {
  code: string
}

export const AuthCodeScreenTemplate: FunctionComponent<AuthCodeScreenTemplateProps> = ({
  errorCode,
  isSubmitting,
  submit,
  handleSendAuthCodeEmail,
  handleSendAuthCodeSms,
  isLoadingAuthSendCodeEmail,
  isLoadingAuthSendCodeSms
}) => {
  const { code, getCellOnLayoutHandler, isSubmited, onSubmit, props, ref, setCode } =
    useAuthCodeScreen({
      submit
    })

  return (
    <>
      <Layouts.ExternalDesktop image={require('assets/login_desktop.jpg')}>
        <VStack space={3}>
          <Icons.FlameSantander color="primary.500" size={8} />
          <SlideInRightView>
            <Heading mt={4} fontSize="24px" bold color="gray.700" lineHeight="38px">
              Autenticação
            </Heading>
          </SlideInRightView>
          <SlideInRightView delay={0.1}>
            <Text color="gray.500" fontSize="21px" lineHeight="30px">
              Enviamos por SMS o código de 6 dígitos. <br />
              Digite abaixo o código para finalizar o cadastro.
            </Text>
          </SlideInRightView>
        </VStack>
        <SlideInRightView delay={0.13}>
          <HStack alignItems="center" justifyContent="space-between" mt={8} mb={2}>
            <CodeField
              testID="login-authenticate-code"
              ref={ref}
              {...props}
              rootStyle={styles.containerCell}
              value={code}
              onChangeText={setCode}
              cellCount={6}
              keyboardType="number-pad"
              textContentType="oneTimeCode"
              renderCell={({ index, symbol, isFocused }) => (
                <Text
                  key={index}
                  style={[
                    styles.cell,
                    isFocused && styles.focusCell,
                    isSubmited && !!errorCode && styles.errorCell,
                    isSubmited && !code[index] && styles.errorCell
                  ]}
                  onLayout={getCellOnLayoutHandler(index)}>
                  {symbol || (isFocused ? <Cursor /> : null)}
                </Text>
              )}
            />
          </HStack>
        </SlideInRightView>
        {isSubmited && errorCode && (
          <Text color="error.500" fontSize="md">
            {errorCode}
          </Text>
        )}

        <SlideInRightView delay={0.16}>
          <Button
            colorScheme="gray"
            isDisabled={code.trim().length < 6}
            isLoading={isSubmitting}
            mt={8}
            onPress={onSubmit}
            testID="confirmButton">
            Confirmar
          </Button>
        </SlideInRightView>

        <SlideInRightView delay={0.19}>
          <HStack alignItems="center" flexWrap="wrap" mt="4">
            <Text color="gray.500" fontSize="16px" lineHeight="24px" mr={2}>
              Não recebeu seu código?
            </Text>

            {handleSendAuthCodeSms && (
              <Button
                isLoading={isLoadingAuthSendCodeSms}
                onPress={handleSendAuthCodeSms}
                testID="resendButton"
                p={0}
                h={5}
                mr={2}
                variant="ghost"
                _important={{
                  outlineColor: 'none'
                }}
                _hover={{
                  bg: 'transparent'
                }}
                _pressed={{
                  bg: 'transparent'
                }}
                _text={{
                  color: 'tertiary.800',
                  fontSize: '16px',
                  fontWeight: 700
                }}>
                Reenviar SMS
              </Button>
            )}

            {handleSendAuthCodeEmail && (
              <>
                <HStack space={1} alignItems="center">
                  <Text color="gray.500" fontSize="md">
                    ou
                  </Text>

                  <Button
                    isLoading={isLoadingAuthSendCodeEmail}
                    onPress={handleSendAuthCodeEmail}
                    testID="resendButton"
                    variant="ghost"
                    _important={{
                      outlineColor: 'none'
                    }}
                    p={0}
                    h={5}
                    _hover={{
                      bg: 'transparent'
                    }}
                    _pressed={{
                      bg: 'transparent'
                    }}
                    _text={{
                      color: 'tertiary.800',
                      fontSize: '16px',
                      fontWeight: 700
                    }}>
                    Reenviar por e-mail
                  </Button>
                </HStack>
              </>
            )}

            <Text mt={8} color="gray.500" fontSize="16px">
              Lembramos que o código possui validade de 5 minutos após o recebimento.
            </Text>
          </HStack>
        </SlideInRightView>
      </Layouts.ExternalDesktop>
    </>
  )
}

const styles = StyleSheet.create({
  containerCell: {
    width: '100%',
    justifyContent: 'space-between'
  },
  cell: {
    width: 40,
    height: 40,
    borderRadius: 12,
    lineHeight: 28,
    fontSize: 24,
    color: '#404040',
    fontWeight: 'bold',
    fontFamily: 'Assistant_700Bold',
    borderWidth: 1,
    borderColor: '#C2C2C2',
    textAlign: 'center',
    paddingTop: 5,
    marginHorizontal: Dimensions.get('screen').width > 350 ? 6 : 4
  },
  focusCell: {
    borderColor: '#C2C2C2'
  },
  errorCell: {
    borderColor: '#D86F6F'
  }
})
