export const spacing = {
  11: 44,
  13: 52,
  14: 56,
  15: 60,
  17: 68,
  18: 72,
  19: 76,
  21: 84,
  22: 88,
  23: 92,
  25: 100,
  30: 120,
  35: 140,
  50: 200
}
