import { FunctionComponent } from 'react'

import { Path, Svg } from 'react-native-svg'
import { resize } from 'src/utils'

type XLSXIconProps = {
  size?: number
}

export const XLSIcon: FunctionComponent<XLSXIconProps> = ({ size = 38 }) => {
  const { height, width } = resize({ height: 38, size, width: 33 })

  return (
    <Svg {...{ viewBox: '0 0 33 38', width, height }}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1712 2.33337H6.66634C5.78229 2.33337 4.93444 2.68456 4.30932 3.30968C3.6842 3.93481 3.33301 4.78265 3.33301 5.66671V32.3334C3.33301 33.2174 3.6842 34.0653 4.30932 34.6904C4.93444 35.3155 5.78229 35.6667 6.66634 35.6667H26.6663C27.5504 35.6667 28.3982 35.3155 29.0234 34.6904C29.6485 34.0653 29.9997 33.2174 29.9997 32.3334V13.1618C29.9997 12.6314 29.789 12.1227 29.4139 11.7476L20.5855 2.91916C20.2104 2.54409 19.7017 2.33337 19.1712 2.33337ZM18.333 14.0001V5.66675L26.6663 14.0001H18.333Z"
        fill="#18714A"
      />
      <Path
        d="M13.1363 28.7301C13.1363 28.5449 13.2 28.3901 13.3273 28.2657C13.4575 28.1412 13.6297 28.079 13.8438 28.079C14.0579 28.079 14.2286 28.1412 14.3559 28.2657C14.4861 28.3901 14.5512 28.5449 14.5512 28.7301C14.5512 28.9153 14.4861 29.0701 14.3559 29.1945C14.2286 29.3189 14.0579 29.3811 13.8438 29.3811C13.6297 29.3811 13.4575 29.3189 13.3273 29.1945C13.2 29.0701 13.1363 28.9153 13.1363 28.7301ZM17.0705 23.0139L18.2597 25.2188L19.449 23.0139H20.9377L19.1018 26.1476L20.9854 29.3334H19.4837L18.2597 27.0851L17.0358 29.3334H15.5254L17.4134 26.1476L15.5731 23.0139H17.0705Z"
        fill="white"
      />
    </Svg>
  )
}
