import { memo } from 'react'

import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

type SvgProps = {
  width?: number | string
  height?: number | string
}

const PageNotFoundIllustration = ({ width = 251, height = 250 }: SvgProps) => (
  <Svg width={width} height={height} viewBox="0 0 477 367" fill="none">
    <G clipPath="url(#clip0_12821_110847)">
      <Path
        d="M410.14 100.602H90.62C80.8114 100.602 72.86 108.553 72.86 118.362V347.482C72.86 357.29 80.8114 365.242 90.62 365.242H410.14C419.949 365.242 427.9 357.29 427.9 347.482V118.362C427.9 108.553 419.949 100.602 410.14 100.602Z"
        fill="#F5F5F5"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M74.12 148.742H198.98"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M427.66 148.742H306.44"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M115.96 131.4C119.3 131.4 122 128.7 122 125.36C122 122.02 119.3 119.32 115.96 119.32C112.62 119.32 109.92 122.02 109.92 125.36C109.92 128.7 112.62 131.4 115.96 131.4Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M134.24 131.4C137.58 131.4 140.28 128.7 140.28 125.36C140.28 122.02 137.58 119.32 134.24 119.32C130.9 119.32 128.2 122.02 128.2 125.36C128.2 128.7 130.9 131.4 134.24 131.4Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M97.66 131.4C101 131.4 103.7 128.7 103.7 125.36C103.7 122.02 101 119.32 97.66 119.32C94.32 119.32 91.62 122.02 91.62 125.36C91.62 128.7 94.32 131.4 97.66 131.4Z"
        fill="#F3123C"
      />
      <Path
        d="M97.66 131.4C101 131.4 103.7 128.7 103.7 125.36C103.7 122.02 101 119.32 97.66 119.32C94.32 119.32 91.62 122.02 91.62 125.36C91.62 128.7 94.32 131.4 97.66 131.4Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M465.22 365.301H36.54"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M283.86 242.078L432.82 266.878C441.38 269.158 446.32 291.298 443.88 317.618C441.46 343.978 432.52 364.918 423.64 365.458L272.06 365.598C283.3 365.738 294.84 339.298 297.94 305.698C301.02 272.458 294.7 244.518 283.84 242.098L283.86 242.078Z"
        fill="#F5F5F5"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M225.2 300.66C222.12 298.24 219.16 295.58 216.12 293.1C195.56 276.48 184.92 283.8 192.7 311.62C199.64 336.42 186.34 329.76 163.58 315.62C149.76 307.02 138.08 298.34 129.34 292.72C108.48 279.32 97.4201 292.38 107.54 318.14C118.9 347.06 108.54 341.56 81.9801 332.2C53.8201 322.32 50.6801 342.22 67.3001 365.3L272.7 365.54C281.06 364.92 289.42 349.86 294.36 328.34C262.92 327.96 238.26 310.86 225.2 300.64V300.66Z"
        fill="#F3123C"
      />
      <Path
        d="M225.2 300.66C222.12 298.24 219.16 295.58 216.12 293.1C195.56 276.48 184.92 283.8 192.7 311.62C199.64 336.42 186.34 329.76 163.58 315.62C149.76 307.02 138.08 298.34 129.34 292.72C108.48 279.32 97.4201 292.38 107.54 318.14C118.9 347.06 108.54 341.56 81.9801 332.2C53.8201 322.32 50.6801 342.22 67.3001 365.3L272.7 365.54C281.06 364.92 289.42 349.86 294.36 328.34C262.92 327.96 238.26 310.86 225.2 300.64V300.66Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M147.06 285.921C143.68 290.101 135.12 283.941 128.58 278.841C122.64 274.201 115.1 268.321 118.46 263.541C125.38 253.681 154.16 277.121 147.06 285.921Z"
        fill="#F3123C"
      />
      <Path
        d="M147.06 285.921C143.68 290.101 135.12 283.941 128.58 278.841C122.64 274.201 115.1 268.321 118.46 263.541C125.38 253.681 154.16 277.121 147.06 285.921Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M283.86 242.08C266.9 239.26 258.1 284.34 256.5 301.84C255.96 307.72 255.7 313.62 255.74 319.5C267.88 325.08 281.04 328.08 294.4 328.34C302.8 291.98 298.6 245.38 283.84 242.08H283.86Z"
        fill="#737373"
      />
      <Path
        d="M283.86 242.08C266.9 239.26 258.1 284.34 256.5 301.84C255.96 307.72 255.7 313.62 255.74 319.5C267.88 325.08 281.04 328.08 294.4 328.34C302.8 291.98 298.6 245.38 283.84 242.08H283.86Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M366.06 300.541C364.42 300.621 362.8 301.081 361.32 301.921L348.36 309.441C347.38 310.021 346.32 310.421 345.22 310.641L345.76 310.961C348.96 312.821 352.92 312.821 356.12 310.961L366.48 304.941C369.68 303.081 373.64 303.081 376.84 304.941L377.38 305.261C376.3 305.481 375.24 305.881 374.24 306.441L358.68 315.461C357.7 316.041 356.64 316.421 355.54 316.641L356.08 316.961C359.28 318.821 363.22 318.821 366.42 316.961L376.82 310.941C380.02 309.081 383.98 309.081 387.18 310.941L387.72 311.261C386.64 311.481 385.58 311.881 384.58 312.441L371.62 319.961C369.88 320.961 367.92 321.421 365.98 321.341C368.08 329.381 372.52 334.941 377.66 334.941C384.82 334.941 390.62 324.201 390.62 310.941C390.62 297.681 384.82 286.941 377.66 286.941C372.52 286.941 368.06 292.501 365.98 300.541H366.06Z"
        fill="#F3123C"
      />
      <Path
        d="M366.06 300.541C364.42 300.621 362.8 301.081 361.32 301.921L348.36 309.441C347.38 310.021 346.32 310.421 345.22 310.641L345.76 310.961C348.96 312.821 352.92 312.821 356.12 310.961L366.48 304.941C369.68 303.081 373.64 303.081 376.84 304.941L377.38 305.261C376.3 305.481 375.24 305.881 374.24 306.441L358.68 315.461C357.7 316.041 356.64 316.421 355.54 316.641L356.08 316.961C359.28 318.821 363.22 318.821 366.42 316.961L376.82 310.941C380.02 309.081 383.98 309.081 387.18 310.941L387.72 311.261C386.64 311.481 385.58 311.881 384.58 312.441L371.62 319.961C369.88 320.961 367.92 321.421 365.98 321.341C368.08 329.381 372.52 334.941 377.66 334.941C384.82 334.941 390.62 324.201 390.62 310.941C390.62 297.681 384.82 286.941 377.66 286.941C372.52 286.941 368.06 292.501 365.98 300.541H366.06Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M244.54 83.8567C245.38 82.4167 246.56 81.2167 248 80.3967C249.44 79.5567 251.08 79.1367 252.74 79.1367C254.4 79.1367 256.04 79.5767 257.48 80.3967C258.92 81.2367 260.12 82.4167 260.94 83.8567L316.42 179.997C317.26 181.437 317.7 183.077 317.7 184.737C317.7 186.397 317.26 188.037 316.42 189.477C315.58 190.917 314.38 192.117 312.94 192.937C311.5 193.777 309.86 194.197 308.2 194.197H197.24C195.58 194.197 193.94 193.757 192.5 192.937C191.06 192.117 189.86 190.917 189.02 189.477C188.18 188.037 187.74 186.397 187.74 184.737C187.74 183.077 188.18 181.437 189.02 179.997L244.54 83.8567V83.8567Z"
        stroke="#F3123C"
        strokeWidth="0.9"
        strokeMiterlimit="10"
      />
      <Path
        d="M244.54 83.8567C245.38 82.4167 246.56 81.2167 248 80.3967C249.44 79.5567 251.08 79.1367 252.74 79.1367C254.4 79.1367 256.04 79.5767 257.48 80.3967C258.92 81.2367 260.12 82.4167 260.94 83.8567L316.42 179.997C317.26 181.437 317.7 183.077 317.7 184.737C317.7 186.397 317.26 188.037 316.42 189.477C315.58 190.917 314.38 192.117 312.94 192.937C311.5 193.777 309.86 194.197 308.2 194.197H197.24C195.58 194.197 193.94 193.757 192.5 192.937C191.06 192.117 189.86 190.917 189.02 189.477C188.18 188.037 187.74 186.397 187.74 184.737C187.74 183.077 188.18 181.437 189.02 179.997L244.54 83.8567Z"
        fill="#F5F5F5"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M252.76 146.598C251.2 146.598 249.7 145.978 248.6 144.878C247.5 143.778 246.88 142.278 246.88 140.718V61.7784C246.88 60.2184 247.5 58.7184 248.6 57.6184C249.7 56.5184 251.2 55.8984 252.76 55.8984C254.32 55.8984 255.82 56.5184 256.92 57.6184C258.02 58.7184 258.64 60.2184 258.64 61.7784V140.718C258.64 142.278 258 143.758 256.9 144.858C255.8 145.958 254.3 146.578 252.76 146.598Z"
        fill="#737373"
      />
      <Path
        d="M252.76 146.598C251.2 146.598 249.7 145.978 248.6 144.878C247.5 143.778 246.88 142.278 246.88 140.718V61.7784C246.88 60.2184 247.5 58.7184 248.6 57.6184C249.7 56.5184 251.2 55.8984 252.76 55.8984C254.32 55.8984 255.82 56.5184 256.92 57.6184C258.02 58.7184 258.64 60.2184 258.64 61.7784V140.718C258.64 142.278 258 143.758 256.9 144.858C255.8 145.958 254.3 146.578 252.76 146.598V146.598Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M252.76 173.26C256.52 173.26 259.56 170.22 259.56 166.46C259.56 162.7 256.52 159.66 252.76 159.66C249 159.66 245.96 162.7 245.96 166.46C245.96 170.22 249 173.26 252.76 173.26Z"
        fill="#737373"
      />
      <Path
        d="M252.76 173.26C256.52 173.26 259.56 170.22 259.56 166.46C259.56 162.7 256.52 159.66 252.76 159.66C249 159.66 245.96 162.7 245.96 166.46C245.96 170.22 249 173.26 252.76 173.26Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M17.6799 207.5L17.6399 240.9"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M34.34 224.22L0.939941 224.18"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M319.26 30.86C322.68 30.86 325.44 28.1 325.44 24.68C325.44 21.26 322.68 18.5 319.26 18.5C315.84 18.5 313.08 21.26 313.08 24.68C313.08 28.1 315.84 30.86 319.26 30.86Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M473.54 162.18V191.96"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M473.54 206.942C474.88 206.942 475.96 205.862 475.96 204.522C475.96 203.182 474.88 202.102 473.54 202.102C472.2 202.102 471.12 203.182 471.12 204.522C471.12 205.862 472.2 206.942 473.54 206.942Z"
        fill="#595959"
      />
      <Path
        d="M473.54 206.942C474.88 206.942 475.96 205.862 475.96 204.522C475.96 203.182 474.88 202.102 473.54 202.102C472.2 202.102 471.12 203.182 471.12 204.522C471.12 205.862 472.2 206.942 473.54 206.942Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M108.24 69.6617V7.42172C108.24 6.68172 107.24 6.42172 106.9 7.08172L81.46 54.8617C81.2 55.3417 81.56 55.9217 82.1 55.9217H115.98"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M209.54 69.6617V7.42172C209.54 6.68172 208.54 6.42172 208.2 7.08172L182.76 54.8617C182.5 55.3417 182.86 55.9217 183.4 55.9217H217.28"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M149.1 0.941406H149.08C138.443 0.941406 129.82 9.5644 129.82 20.2014V53.5014C129.82 64.1384 138.443 72.7614 149.08 72.7614H149.1C159.737 72.7614 168.36 64.1384 168.36 53.5014V20.2014C168.36 9.5644 159.737 0.941406 149.1 0.941406Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_12821_110847">
        <Rect width="476.9" height="366.54" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
)

export const PageNotFound = memo(PageNotFoundIllustration)
