import { FunctionComponent, useState } from 'react'

import Icons from 'atoms/Icons'
import {
  parseISO,
  format,
  eachYearOfInterval,
  startOfYear,
  endOfYear,
  eachDayOfInterval,
  addDays
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Heading, HStack, Menu, Pressable, ScrollView, useTheme } from 'native-base'
import { Calendar as RNCalendar, LocaleConfig } from 'react-native-calendars'
import { MarkedDates } from 'react-native-calendars/src/types'

import { TCalendarBodyProps } from './types'

const formatDateToUSA = (dayIso: Date) => format(dayIso, 'yyyy-MM-dd', { locale: ptBR })

LocaleConfig.locales['pt-BR'] = {
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],
  monthNamesShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez'
  ],
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  dayNamesShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  today: 'Hoje'
}

LocaleConfig.defaultLocale = 'pt-BR'

const selectionColor = { color: '#FEECEF', textColor: '#4d4d4d' }

const selectedDayColor = { color: '#FEECEF', textColor: '#F3123C' }

// Define your date range
const startDate = parseISO('1985-01-01')

const endDate = parseISO('2023-12-31')

// Calculate the start and end of the year for the given dates
const startYear = startOfYear(startDate)

const endYear = endOfYear(endDate)

// Get all the years within the date range
const years = eachYearOfInterval({
  start: startYear,
  end: endYear
})

const yearsParsedAndSorted = years
  .map((year) => format(year, 'yyyy', { locale: ptBR }))
  // @ts-ignore
  .sort((a, b) => parseInt(a, 10) < parseInt(b, 10))

const Header = ({
  date,
  handleYearChange,
  enableSelectYear = false
}: {
  date: Date
  handleYearChange(year: string): void
  enableSelectYear?: boolean
}) => {
  const [formatedDate, year] = format(parseISO(date.toISOString().replace('Z', '')), 'MMMM yyyy', {
    locale: ptBR
  }).split(' ')

  return (
    <HStack>
      <Heading fontSize="md">
        {formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1)}
      </Heading>
      {enableSelectYear ? (
        <Menu
          shadow={1}
          bg="white"
          w="90px"
          h="200px"
          trigger={(triggerProps) => (
            <Pressable accessibilityLabel="More options menu" {...triggerProps}>
              <HStack alignItems="center">
                <Heading fontSize="md"> {year}</Heading>
                <Icons.ArrowDropDown />
              </HStack>
            </Pressable>
          )}>
          <ScrollView>
            {yearsParsedAndSorted.map((year) => (
              <Menu.Item
                onPress={() => handleYearChange(year)}
                _text={{
                  color: 'gray.700',
                  fontSize: 12,
                  fontWeight: 600,
                  lineHeight: 20
                }}>
                {year}
              </Menu.Item>
            ))}
          </ScrollView>
        </Menu>
      ) : (
        <Heading fontSize="md"> {year}</Heading>
      )}
    </HStack>
  )
}

export const CalendarBody: FunctionComponent<TCalendarBodyProps> = ({
  markedDates,
  markingType,
  daySelected,
  startingDay,
  endingDay,
  onDaySelected,
  onStartingDaySelect,
  onEndingDaySelect,
  maxDays = 30,
  enableSelectYear = false,
  minDate: minDateProp
}) => {
  const theme = useTheme()

  const [minDate, setMinDate] = useState<string | undefined>(minDateProp)

  const [maxDate, setMaxDate] = useState<string | undefined>()

  const marked: MarkedDates = {}

  markedDates?.map((date: string) => {
    marked[date] = { marked: true }
  })

  if (markingType !== 'period' && daySelected) {
    marked[daySelected] =
      daySelected in marked
        ? { ...marked[daySelected], selected: true, ...selectedDayColor }
        : { selected: true, ...selectedDayColor }
  }

  if (markingType === 'period') {
    if (startingDay) {
      marked[startingDay] =
        startingDay in marked
          ? { ...marked[startingDay], startingDay: true, ...selectedDayColor }
          : { startingDay: true, ...selectedDayColor }
    }

    if (startingDay && endingDay && startingDay !== endingDay) {
      const start = parseISO(startingDay)

      const end = parseISO(endingDay)

      const days = eachDayOfInterval({
        start: addDays(start, 1),
        end
      })

      days.map((day, index) => {
        const dayParsed = formatDateToUSA(day)

        marked[dayParsed] =
          dayParsed in marked
            ? {
                ...marked[dayParsed],
                ...(days.length === index + 1 ? selectedDayColor : selectionColor),
                endingDay: days.length === index + 1
              }
            : {
                endingDay: days.length === index + 1,
                ...(days.length === index + 1 ? selectedDayColor : selectionColor)
              }
      })
    }
  }

  const handleYearChange = (year: string) => {
    if (onDaySelected && daySelected) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, month, day] = daySelected.split('-')

      onDaySelected(`${year}-${month}-${day}`)
    }

    if (markingType === 'period') {
      if (startingDay && onStartingDaySelect) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, month, day] = startingDay.split('-')

        const newDate = `${year}-${month}-${day}`

        onStartingDaySelect(newDate)

        setMinDate(newDate)

        setMaxDate(undefined)
      }

      if (endingDay && onEndingDaySelect) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, month, day] = endingDay.split('-')

        const newDate = `${year}-${month}-${day}`

        onEndingDaySelect(newDate)

        setMinDate(undefined)

        setMaxDate(formatDateToUSA(addDays(parseISO(newDate), maxDays)))
      }
    }
  }

  return (
    <RNCalendar
      initialDate={daySelected ?? startingDay}
      renderHeader={(date) => <Header {...{ date, handleYearChange, enableSelectYear }} />}
      renderArrow={(dir) => <Icons.AccordionArrow direction={dir} />}
      markedDates={marked}
      markingType={markingType}
      onDayPress={(day) => {
        if (onDaySelected) {
          onDaySelected(day.dateString)
        }

        if (markingType === 'period' && onStartingDaySelect && onEndingDaySelect) {
          const dayIso = parseISO(day.dateString)

          if (!startingDay || (startingDay && endingDay)) {
            onStartingDaySelect(day.dateString)

            onEndingDaySelect(undefined)

            setMinDate(formatDateToUSA(addDays(dayIso, 1)))

            setMaxDate(formatDateToUSA(addDays(dayIso, maxDays)))
          }

          if (startingDay && !endingDay) {
            onEndingDaySelect(day.dateString)

            setMinDate(undefined)
          }
        }
      }}
      minDate={minDate}
      maxDate={maxDate}
      theme={{
        arrowColor: theme.colors.gray[700],
        dotColor: theme.colors.tertiary[800],
        dotStyle: {
          borderRadius: 3,
          height: 6,
          width: 6
        },
        todayTextColor: theme.colors.primary[500],
        selectedDotColor: theme.colors.tertiary[800],
        selectedDayTextColor: theme.colors.primary[500],
        selectedDayBackgroundColor: theme.colors.primary[50]
      }}
    />
  )
}
