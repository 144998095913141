import {
  TParams,
  TGoalsLeadersListItem,
  TGoalsPeriod,
  PeriodType,
  PeriodStatus,
  TGoalsStaffListItem,
  TGoalsLeaderDetails,
  GoalsStatus
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

const USE_MOCK = true

const mockAxiosResponse = (data: any) => ({
  data,
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {},
  request: {}
})

export const mockedGoalsLeadersListResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    pagination: {
      total: 10,
      per_page: 10,
      current_page: 1,
      last_page: 1,
      first_page: 1,
      first_page_url: '/?page=1',
      last_page_url: '/?page=1',
      next_page_url: null,
      previous_page_url: null
    },
    runtime: 42,
    requestId: 'clujqrna8000919kkjld9d60ef'
  },
  data: Array.from({ length: 10 }, (_, index) => {
    return {
      leader_std_code: 134000 + index,
      leader_user_name: `Líder ${index + 1}`,
      profile_image_file_name: `https://randomuser.me/api/portraits/men/${index}.jpg`,
      network: {
        network_code: `CSS${index}`,
        network_name: `CSS ${
          ['Sudeste', 'Centro Oeste', 'Sul', 'SP Interior', 'SP Capital'][index % 5]
        }`
      },
      subsidiary: {
        subsidiary_name: 'Baixada Santista',
        subsidiary_code: `654${100 + index}`
      },
      total_specialists: Math.floor(Math.random() * 100) + 1,
      total_goal: 164000000
    }
  }),
  status: 200
}

export const mockedGoalsPeriodResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    runtime: 27,
    requestId: 'clujqt0b6000a08jt5ct8ffjl'
  },
  data: {
    id: 'period_001',
    year_month: 202309,
    period_type_enum: PeriodType.GENERAL,
    status: PeriodStatus.CLOSED,
    started_at: '2024-01-04T15:23:45.293',
    finished_at: '2024-01-04T15:23:45.293',
    createdAt: '2024-01-04T15:23:45.293',
    updatedAt: '2024-01-04T15:23:45.293',
    total_leader_active: '150'
  },
  status: 200
}

export const mockedGoalsStaffListResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    pagination: {
      total: 10,
      per_page: 10,
      current_page: 1,
      last_page: 1,
      first_page: 1,
      first_page_url: '/?page=1',
      last_page_url: '/?page=1',
      next_page_url: null,
      previous_page_url: null
    },
    runtime: 42,
    requestId: 'clujqrna8000919kkjld9d60ef'
  },
  data: Array.from({ length: 10 }, (_, index) => {
    const user_std_code = 134000 + index

    const goalValue = 160000 + index * 1000

    return {
      user_std_code,
      user_name: `Especialista ${index + 1}`,
      occupation: 'Especialista em Vendas',
      profile_image_file_name: `https://randomuser.me/api/portraits/women/${index}.jpg`,
      goal: `R$ ${goalValue.toLocaleString('pt-BR')}`,
      udpated_goal: `${(goalValue + 10000).toLocaleString('pt-BR')}`,
      udpated_by: `Santander`,
      last_update: '2024-01-04T15:23:45.293'
    }
  }),
  status: 200
}

export const mockedGoalsLeaderDetailsResponse = {
  serviceSuccess: true,
  hasErrors: false,
  errors: [],
  metadata: {
    runtime: 33,
    requestId: 'clujrt5x1000b1djtkbl0zyfk'
  },
  data: {
    started_at: '2024-01-04T15:23:45.293',
    finished_at: '2024-01-04T15:23:45.293',
    leader_std_code: 134567,
    leader_user_name: 'João da Silva',
    occupation: 'Líder Regional de Vendas',
    profile_image_file_name: 'https://randomuser.me/api/portraits/men/7.jpg',
    network: {
      network_code: 'CSS01',
      network_name: 'CSS Sudeste'
    },
    subsidiary: {
      subsidiary_name: 'Baixada Santista',
      subsidiary_code: '654100'
    },
    status: GoalsStatus.CLOSED
  },
  status: 200
}

export const getGoalsLeadersList = async (params: TParams) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedGoalsLeadersListResponse))
  } else {
    await client.get<ResponseSuccess<TGoalsLeadersListItem[]>>(
      `v1/wallets/goals/leaders/${params.leader_std_code}`,
      {
        params: {
          per_page: params?.per_page ?? 10,
          page: params?.page ?? 1,
          order_by: params.order_by ?? undefined,
          order_by_direction: params.order_by_direction ?? 'desc',
          ...params
        }
      }
    )
  }
}

export const getGoalsPeriod = async () => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedGoalsPeriodResponse)) as any
  } else {
    return await client.get<ResponseSuccess<TGoalsPeriod>>(`v1/wallets/goals/period`)
  }
}

export const getGoalsStaffList = async (params: TParams) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedGoalsStaffListResponse))
  } else {
    await client.get<ResponseSuccess<TGoalsStaffListItem[]>>(
      `v1/wallets/goals/leaders/${params.leader_std_code}/staff`,
      {
        params: {
          per_page: params?.per_page ?? 10,
          page: params?.page ?? 1,
          order_by: params.order_by ?? undefined,
          order_by_direction: params.order_by_direction ?? 'desc',
          ...params
        }
      }
    )
  }
}

export const getGoalsLeaderDetails = async (params: TParams) => {
  if (USE_MOCK) {
    return Promise.resolve(mockAxiosResponse(mockedGoalsLeaderDetailsResponse)) as any
  } else {
    return await client.get<ResponseSuccess<TGoalsLeaderDetails>>(
      `v1/wallets/management/leaders/${params.leader_std_code ?? 0}`,
      {}
    )
  }
}
