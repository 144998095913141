import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ExclamationIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled = false,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 1.67 8', ...props }}>
    <Path
      key={Math.random()}
      d="M1.00008 5.33333C1.17689 5.33333 1.34646 5.2631 1.47149 5.13807C1.59651 5.01305 1.66675 4.84348 1.66675 4.66667V0.666667C1.66675 0.489856 1.59651 0.320287 1.47149 0.195262C1.34646 0.070238 1.17689 0 1.00008 0C0.82327 0 0.653701 0.070238 0.528677 0.195262C0.403653 0.320287 0.333415 0.489856 0.333415 0.666667V4.66667C0.333415 4.84348 0.403653 5.01305 0.528677 5.13807C0.653701 5.2631 0.82327 5.33333 1.00008 5.33333ZM1.00008 8C1.1649 8 1.32602 7.95113 1.46306 7.85956C1.6001 7.76799 1.70691 7.63784 1.76998 7.48557C1.83305 7.3333 1.84956 7.16574 1.8174 7.00409C1.78525 6.84244 1.70588 6.69395 1.58934 6.57741C1.47279 6.46087 1.32431 6.3815 1.16266 6.34935C1.00101 6.31719 0.83345 6.33369 0.681178 6.39677C0.528907 6.45984 0.398758 6.56665 0.30719 6.70369C0.215622 6.84073 0.166748 7.00185 0.166748 7.16667C0.166748 7.38768 0.254545 7.59964 0.410826 7.75592C0.567106 7.9122 0.779068 8 1.00008 8V8Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </Icon>
)
