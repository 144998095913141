import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const PreferencesIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled = false,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 19', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.172 1.172a4 4 0 1 1 5.656 5.656 4 4 0 0 1-5.656-5.656ZM4 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM10.343 11.104a4 4 0 1 1 3.062 7.392 4 4 0 0 1-3.062-7.391Zm1.531 1.696a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4a1 1 0 0 1 1-1h15.6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM.148 14.8a1 1 0 0 1 1-1h7.726a1 1 0 0 1 0 2H1.148a1 1 0 0 1-1-1ZM13.874 14.8a1 1 0 0 1 1-1H22.6a1 1 0 0 1 0 2h-7.726a1 1 0 0 1-1-1Z"
      fill="currentColor"
    />
  </Icon>
)
