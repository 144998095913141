import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { EditActivityScreenComponent } from './types'
import { useEditActivity } from './useEditActivity'

const ScreenDesktop = lazy(() => import('./desktop'))

const ScreenMobile = lazy(() => import('./mobile'))

export const EditActivityScreen: EditActivityScreenComponent = ({ navigation, route }) => {
  const editActivity = useEditActivity({ navigation, route })

  const Screen = editActivity.isMobile ? ScreenMobile : ScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...editActivity} />
    </Suspense>
  )
}
