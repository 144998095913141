import { createIcon } from 'native-base'
import { Path } from 'react-native-svg'

export const TrashIcon = createIcon({
  viewBox: '0 0 24 24',
  path: [
    <>
      <Path
        d="M14.25 7.8768H3.45C3.323 7.87603 3.19727 7.90215 3.08108 7.95344C2.9649 8.00472 2.86088 8.08002 2.77587 8.17438C2.69086 8.26874 2.62679 8.38002 2.58786 8.50092C2.54893 8.62181 2.53603 8.74957 2.55 8.8758L3.45 16.9758C3.47447 17.1969 3.57999 17.4012 3.7462 17.5491C3.9124 17.697 4.12751 17.7782 4.35 17.7768H13.35C13.5725 17.7782 13.7876 17.697 13.9538 17.5491C14.12 17.4012 14.2255 17.1969 14.25 16.9758L15.15 8.8758C15.164 8.74957 15.1511 8.62181 15.1121 8.50092C15.0732 8.38002 15.0091 8.26874 14.9241 8.17438C14.8391 8.08002 14.7351 8.00472 14.6189 7.95344C14.5027 7.90215 14.377 7.87603 14.25 7.8768Z"
        fill="currentColor"
      />
      <Path
        d="M15.6 3.3768H13.575C13.4557 3.3768 13.3412 3.32939 13.2568 3.245C13.1724 3.16061 13.125 3.04615 13.125 2.9268V2.0268C13.1245 1.48972 12.9111 0.974741 12.5315 0.594797C12.1519 0.214853 11.6371 0.00095291 11.1 0H6.6C6.06292 0.00095291 5.54813 0.214853 5.16853 0.594797C4.78892 0.974741 4.57548 1.48972 4.575 2.0268V2.9268C4.575 3.04615 4.52759 3.16061 4.4432 3.245C4.35881 3.32939 4.24435 3.3768 4.125 3.3768H2.1C1.74196 3.3768 1.39858 3.51903 1.14541 3.77221C0.892232 4.02538 0.75 4.36876 0.75 4.7268C0.75 5.08484 0.892232 5.42822 1.14541 5.68139C1.39858 5.93457 1.74196 6.0768 2.1 6.0768H15.6C15.958 6.0768 16.3014 5.93457 16.5546 5.68139C16.8078 5.42822 16.95 5.08484 16.95 4.7268C16.95 4.36876 16.8078 4.02538 16.5546 3.77221C16.3014 3.51903 15.958 3.3768 15.6 3.3768ZM6.825 2.7C6.825 2.58065 6.87241 2.46619 6.9568 2.3818C7.04119 2.29741 7.15565 2.25 7.275 2.25H10.425C10.5443 2.25 10.6588 2.29741 10.7432 2.3818C10.8276 2.46619 10.875 2.58065 10.875 2.7V2.9268C10.875 3.04615 10.8276 3.16061 10.7432 3.245C10.6588 3.32939 10.5443 3.3768 10.425 3.3768H7.275C7.15565 3.3768 7.04119 3.32939 6.9568 3.245C6.87241 3.16061 6.825 3.04615 6.825 2.9268V2.7Z"
        fill="currentColor"
      />
    </>
  ]
})
