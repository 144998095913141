import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  ActivitiesByWeekResponse,
  KEY_ACTIVITY_LIST_BY_WEEK_QUERY,
  Week,
  useFinishedActivityMutation,
  useGetActivityListByWeekInfiniteQuery,
  useUnFinishedActivityMutation
} from 'integration/resources/activities'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { Platform } from 'react-native'
import { useQueryClient } from 'react-query'
import { LogEvent } from 'src/utils/logEvents'

import { UseListActivitiesByWeek } from './types'
import { useSetOrganizationAtom } from '../../useOrganizationScreen'

const defaultReturn = {
  items: [],
  total_activities: 0,
  total_opportunities: 0
}

const mergeWeekDataInfiniteQuery = (
  data: ReturnType<typeof useGetActivityListByWeekInfiniteQuery>['data']
) => {
  const finalData = data?.pages.reduce<ActivitiesByWeekResponse>((previousValue, currentValue) => {
    return {
      ...previousValue,
      ...currentValue.data.data,
      items: [...previousValue.items, ...currentValue.data.data.items]
    }
  }, defaultReturn)

  // finalData é o array de Weeks com days duplicados

  const uniqueActivitiesList: Week[] = []

  // uniqueActivitiesList será o array de Weeks sem days duplicados

  finalData?.items.map((item) => {
    const indexExist = uniqueActivitiesList.findIndex((prevItem) => prevItem.day === item.day)

    // indexExist é o index de Week aonde day está duplicado

    if (indexExist > -1) {
      const duplicatedActivities = [
        ...uniqueActivitiesList[indexExist].activities,
        ...item.activities
      ]

      // duplicatedActivities é o index de Week aonde day não está duplicado mas as tarefas estão

      uniqueActivitiesList[indexExist].activities = duplicatedActivities.filter((obj, index) => {
        return index === duplicatedActivities.findIndex((o) => obj.id === o.id)
      })

      // filtro para eliminar tarefas duplicadas no day
    } else {
      uniqueActivitiesList.push(item)
    }
  })

  return {
    total_activities: finalData?.total_activities ?? 0,
    total_opportunities: finalData?.total_opportunities ?? 0,
    items: uniqueActivitiesList
  }
}

export const useListActivitiesByWeek: UseListActivitiesByWeek = ({ search }) => {
  const setOrganizationAtom = useSetOrganizationAtom()

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch: apiRefetch
  } = useGetActivityListByWeekInfiniteQuery(
    {
      group_by: 1,
      per_page: 10,
      expand_details: isDesktop && Platform.OS === 'web',
      search
    },
    (data) => {
      setOrganizationAtom(data.pages[0].data.data)
    }
  )

  const fetchMore = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const [refetchLoading, setRefetchLoading] = useState(false)

  const queryClient = useQueryClient()

  const refetch = () => {
    setRefetchLoading(true)

    setTimeout(() => {
      setRefetchLoading(false)
    }, 300)

    queryClient.invalidateQueries(KEY_ACTIVITY_LIST_BY_WEEK_QUERY)
  }

  const list = useMemo(() => mergeWeekDataInfiniteQuery(data), [data])

  const toast = useToast()

  const finishedActivityMutation = useFinishedActivityMutation()

  const unFinishedActivityMutation = useUnFinishedActivityMutation()

  const handleComplete = (callback: (a: boolean) => void, currentCheck: boolean, id: string) =>
    (currentCheck ? unFinishedActivityMutation : finishedActivityMutation).mutate(
      {
        id
      },
      {
        onError() {
          toast.show({
            render: () => <Toast type="error" text="Não foi possível completar a ação" />,
            duration: 800
          })

          callback(currentCheck)

          apiRefetch()
        },
        onSuccess: async (response) => {
          callback(!currentCheck)

          if (!currentCheck) {
            toast.show({
              render: () => <Toast type="success" text="Tarefa marcada como concluída 🎉" />,
              duration: 800
            })

            LogEvent('atividade_concluída', {
              item_id: response.data.data.id
            })
          }
        }
      }
    )

  useEffect(() => {
    apiRefetch()
  }, [apiRefetch, search])

  return {
    list,
    refetch,
    fetchMore,
    refreshing: refetchLoading,
    isLoading,
    handleComplete,
    isFetchingNextPage
  }
}
