import { memo } from 'react'

import { Illustrations } from 'atoms'
import { Button, Center, HStack, Heading, Text, VStack } from 'native-base'
import type { ContentFunctionComponent } from 'src/contexts/BottomSheetContext'

export type DefaultBottomSheetFeedbackProps = {
  buttonDescription?: string
  description: string
  title: string
  testID?: string
  illustrationName?: keyof typeof Illustrations
}

export const DefaultBottomSheetFeedback: ContentFunctionComponent<DefaultBottomSheetFeedbackProps> =
  memo(({ testID, buttonDescription = 'OK', close, description, title, illustrationName }) => {
    const IllustrationComponent = illustrationName ? Illustrations[illustrationName] : null

    return (
      <VStack
        p={{
          base: 4,
          lg: 0
        }}
        w="full"
        space={{
          base: 6,
          lg: 0
        }}>
        {IllustrationComponent && (
          <Center>
            {/* @ts-ignore */}
            <IllustrationComponent />
          </Center>
        )}
        <Heading
          textAlign="center"
          color="gray.700"
          mb={{
            base: 3,
            lg: 6
          }}
          bold
          fontSize="24px"
          lineHeight={{
            lg: '30px'
          }}>
          {title}
        </Heading>
        <Text
          textAlign="center"
          color="gray.500"
          fontSize={{
            base: '12px',
            lg: '18px'
          }}
          lineHeight={{
            base: '16px',
            lg: '30px'
          }}>
          {description}
        </Text>

        <HStack justifyContent="center">
          <Button
            variant="solid"
            mt="24px"
            w="full"
            maxW={{ base: 'full', lg: '512px' }}
            colorScheme="gray"
            onPress={close}
            testID={testID}>
            {buttonDescription}
          </Button>
        </HStack>
      </VStack>
    )
  })
