import { forwardRef, useImperativeHandle } from 'react'

import {
  Actionsheet,
  Button,
  Heading,
  HStack,
  Modal,
  Text,
  useBreakpointValue,
  useDisclose,
  VStack
} from 'native-base'

type TMessageActionSheet = {
  title: string
  description: string
  titleButtonOk?: string
  titleButtonBack?: string
  handleButtonOk(): void
  backButtonOutline?: boolean
  hiddenBackButton?: boolean
}

export type TMessageActionSheetHandleRef = {
  onToggle(): void
}

const ContentMessage = ({
  title,
  description,
  onToggle,
  handleButtonOk,
  titleButtonOk,
  hiddenBackButton,
  backButtonOutline,
  titleButtonBack,
  onClose
}: TMessageActionSheet & TMessageActionSheetHandleRef & { onClose(): void }) => (
  <>
    <VStack space={4} alignItems="center">
      <Heading textAlign="center" color="gray.700" fontSize="24px" maxW="95%" lineHeight="28px">
        {title}
      </Heading>
      <Text
        color="gray.500"
        fontSize={{
          base: '12px',
          lg: '18px'
        }}
        lineHeight="16px">
        {description}
      </Text>

      <HStack maxW="full" justifyContent="space-between" my={6}>
        <Button
          flex={1}
          mr="8px"
          colorScheme="gray"
          minW={{
            lg: '300px'
          }}
          onPress={() => {
            onToggle()

            handleButtonOk()
          }}>
          {titleButtonOk}
        </Button>
        {!hiddenBackButton ? (
          <>
            {backButtonOutline ? (
              <Button
                flex={1}
                ml="8px"
                onPress={onClose}
                variant="outline"
                borderColor="gray.700"
                bg="gray.50"
                _pressed={{
                  bg: 'transparent'
                }}
                _text={{
                  color: 'gray.400',
                  fontSize: '12px',
                  lineHeight: '16px'
                }}>
                {titleButtonBack}
              </Button>
            ) : (
              <Button
                flex={1}
                ml="8px"
                onPress={onClose}
                bg="gray.50"
                _pressed={{
                  bg: 'gray.100',
                  opacity: 0.8
                }}
                _text={{
                  color: 'gray.400',
                  fontSize: '12px',
                  lineHeight: '16px'
                }}>
                {titleButtonBack}
              </Button>
            )}
          </>
        ) : (
          <></>
        )}
      </HStack>
    </VStack>
  </>
)

export const MessageActionSheet = forwardRef<TMessageActionSheetHandleRef, TMessageActionSheet>(
  (
    {
      title,
      description,
      titleButtonOk = 'OK',
      titleButtonBack = 'Voltar',
      handleButtonOk,
      backButtonOutline = false,
      hiddenBackButton = false
    },
    ref
  ) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true })

    const { isOpen, onToggle, onClose } = useDisclose(false)

    useImperativeHandle(ref, () => ({
      onToggle
    }))

    return isDesktop ? (
      <Modal isOpen={isOpen}>
        <Modal.Content borderRadius="20px" minW="640px" py="64px">
          <Modal.Body>
            <ContentMessage
              {...{
                title,
                description,
                titleButtonBack,
                titleButtonOk,
                handleButtonOk,
                backButtonOutline,
                hiddenBackButton,
                onToggle,
                onClose
              }}
            />
          </Modal.Body>
        </Modal.Content>
      </Modal>
    ) : (
      <Actionsheet isOpen={isOpen} hideDragIndicator onClose={onClose}>
        <Actionsheet.Content p={4}>
          <ContentMessage
            {...{
              title,
              description,
              titleButtonBack,
              titleButtonOk,
              handleButtonOk,
              backButtonOutline,
              hiddenBackButton,
              onToggle,
              onClose
            }}
          />
        </Actionsheet.Content>
      </Actionsheet>
    )
  }
)
