import {
  createContext,
  FunctionComponent,
  useEffect,
  useContext,
  useMemo,
  useState,
  ReactNode,
  PropsWithChildren
} from 'react'

import { DefaultBottomSheetFeedback, DefaultBottomSheetFeedbackProps } from 'molecules'
import { Actionsheet, Modal, useBreakpointValue, useDisclose } from 'native-base'

type BottomSheetContextValues = {
  close(): void
  open(): void
  setContent(content: ReactNode): void
}

export type ContentFunctionComponent<P = object> = FunctionComponent<
  Pick<BottomSheetContextValues, 'close'> & P
>

const BottomSheetContext = createContext<BottomSheetContextValues>({} as BottomSheetContextValues)

let currentKey: string | null

export const useBottomSheetContext = (content?: { [k: string]: ContentFunctionComponent }) => {
  const { close, ...context } = useContext(BottomSheetContext)

  // was used `useMemo` to avoid re-renders
  const cachedContent = useMemo(() => content, [content])

  // whenever the `content` prop has any changes the content of bottom sheet component will be rendered again
  useEffect(() => {
    if (cachedContent && currentKey) {
      context.setContent(cachedContent[currentKey]({ close }))
    }
  }, [cachedContent, close, context])

  return {
    close,
    open: (value?: DefaultBottomSheetFeedbackProps | string) => {
      if (typeof value === 'string' && cachedContent && cachedContent[value]) {
        currentKey = value

        context.setContent(cachedContent[value]({ close }))
      } else {
        currentKey = null

        const content = value ? (
          <DefaultBottomSheetFeedback
            {...{ close, ...(value as DefaultBottomSheetFeedbackProps) }}
          />
        ) : (
          cachedContent
        )

        // @ts-ignore
        context.setContent(content)
      }

      context.open()
    }
  }
}

export const BottomSheetProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [content, setContent] = useState<ReactNode>()

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const { isOpen, onOpen: open, onClose: close } = useDisclose()

  const contextValues = useMemo(
    () => ({
      close,
      open,
      setContent
    }),
    [close, open]
  )

  return (
    <BottomSheetContext.Provider value={contextValues}>
      {children}
      {isDesktop ? (
        <Modal isOpen={isOpen} onClose={close}>
          <Modal.Content py="64px" borderRadius="20px" minW="660px">
            <Modal.Body>{content}</Modal.Body>
          </Modal.Content>
        </Modal>
      ) : (
        <Actionsheet
          testID="login-authenticate-exceeded-ok"
          hideDragIndicator
          isOpen={isOpen}
          onClose={close}>
          <Actionsheet.Content>{content}</Actionsheet.Content>
        </Actionsheet>
      )}
    </BottomSheetContext.Provider>
  )
}
