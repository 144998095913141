import { memo } from 'react'

import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

type SvgProps = {
  width?: number | string
  height?: number | string
}

const PageErrorOccurredIllustration = ({ width = 251, height = 250 }: SvgProps) => (
  <Svg width={width} height={height} viewBox="0 0 478 418" fill="none">
    <G clipPath="url(#clip0_12821_110984)">
      <Path
        d="M98.6102 148.339C98.6102 149.519 98.2502 150.679 97.6102 151.659C96.9502 152.639 96.0102 153.419 94.9302 153.859C93.8302 154.319 92.6302 154.439 91.4702 154.199C90.3102 153.959 89.2502 153.399 88.4102 152.559C87.5702 151.719 87.0102 150.659 86.7702 149.499C86.5302 148.339 86.6502 147.139 87.1102 146.039C87.5702 144.939 88.3302 144.019 89.3102 143.359C90.2902 142.699 91.4502 142.359 92.6302 142.359C94.2102 142.359 95.7302 142.999 96.8502 144.119C97.9702 145.239 98.6102 146.759 98.6102 148.339Z"
        fill="#F3123C"
      />
      <Path
        d="M98.7502 360.458H85.5102C83.3902 360.458 81.2902 360.038 79.3302 359.238C77.3702 358.418 75.5702 357.238 74.0702 355.738C72.5702 354.238 71.3902 352.458 70.5702 350.498C69.7702 348.538 69.3502 346.438 69.3502 344.298V138.058C69.3502 135.938 69.7702 133.838 70.5702 131.878C71.3902 129.918 72.5702 128.138 74.0702 126.638C75.5702 125.138 77.3702 123.938 79.3302 123.138C81.2902 122.318 83.3902 121.898 85.5102 121.898H199.41L307.37 121.938H402.37C404.49 121.938 406.61 122.358 408.55 123.178C410.51 123.978 412.31 125.178 413.81 126.678C415.29 128.178 416.49 129.958 417.29 131.918C418.11 133.878 418.53 135.978 418.53 138.118V344.298C418.53 346.438 418.11 348.538 417.29 350.498C416.49 352.458 415.29 354.238 413.79 355.738C412.29 357.238 410.51 358.418 408.55 359.238C406.59 360.038 404.49 360.458 402.37 360.458H98.7502Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M69.3502 171.418H418.53"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M98.6102 148.339C98.6102 149.519 98.2502 150.679 97.6102 151.659C96.9502 152.639 96.0102 153.419 94.9302 153.859C93.8302 154.319 92.6302 154.439 91.4702 154.199C90.3102 153.959 89.2502 153.399 88.4102 152.559C87.5702 151.719 87.0102 150.659 86.7702 149.499C86.5302 148.339 86.6502 147.139 87.1102 146.039C87.5702 144.939 88.3302 144.019 89.3102 143.359C90.2902 142.699 91.4502 142.359 92.6302 142.359C94.2102 142.359 95.7302 142.999 96.8502 144.119C97.9702 145.239 98.6102 146.759 98.6102 148.339V148.339Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M116.67 148.339C116.67 149.519 116.31 150.679 115.67 151.659C115.01 152.639 114.07 153.419 112.99 153.859C111.89 154.319 110.69 154.439 109.53 154.199C108.37 153.959 107.31 153.399 106.47 152.559C105.63 151.719 105.07 150.659 104.83 149.499C104.61 148.339 104.73 147.139 105.17 146.039C105.63 144.939 106.39 144.019 107.37 143.359C108.35 142.699 109.51 142.359 110.69 142.359C112.27 142.359 113.79 142.999 114.91 144.119C116.03 145.239 116.65 146.759 116.67 148.339V148.339Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M134.77 148.339C134.77 149.519 134.41 150.679 133.77 151.659C133.11 152.639 132.17 153.419 131.09 153.859C129.99 154.319 128.79 154.439 127.63 154.199C126.47 153.959 125.41 153.399 124.57 152.559C123.73 151.719 123.17 150.659 122.93 149.499C122.69 148.339 122.81 147.139 123.27 146.039C123.73 144.939 124.49 144.019 125.47 143.359C126.45 142.699 127.61 142.359 128.79 142.359C130.37 142.359 131.89 142.999 133.01 144.119C134.13 145.239 134.77 146.759 134.77 148.339V148.339Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M260.77 69.2984C260.77 70.7584 260.33 72.1984 259.53 73.4184C258.71 74.6384 257.55 75.5784 256.21 76.1384C254.85 76.6984 253.37 76.8384 251.93 76.5584C250.49 76.2784 249.17 75.5584 248.13 74.5384C247.09 73.4984 246.39 72.1784 246.11 70.7384C245.83 69.2984 245.97 67.8184 246.53 66.4584C247.09 65.0984 248.05 63.9384 249.25 63.1384C250.47 62.3184 251.91 61.8984 253.37 61.8984C255.33 61.8984 257.21 62.6784 258.61 64.0784C259.99 65.4584 260.79 67.3584 260.79 69.3184L260.77 69.2984Z"
        fill="#F3123C"
      />
      <Path d="M271.37 209.457H235.41V241.817H271.37V209.457Z" fill="#F3123C" />
      <Path
        d="M200.35 295.297C200.35 296.317 200.05 297.317 199.47 298.177C198.89 299.037 198.09 299.697 197.15 300.077C196.21 300.477 195.17 300.577 194.15 300.377C193.15 300.177 192.23 299.677 191.51 298.957C190.79 298.237 190.29 297.317 190.09 296.317C189.89 295.317 189.99 294.277 190.39 293.317C190.79 292.377 191.45 291.557 192.29 290.997C193.15 290.417 194.15 290.117 195.17 290.117C196.55 290.117 197.85 290.657 198.83 291.637C199.81 292.617 200.35 293.917 200.35 295.297Z"
        fill="#F3123C"
      />
      <Path
        d="M317.77 338.18C317.77 339.2 317.47 340.2 316.89 341.06C316.31 341.92 315.51 342.58 314.57 342.96C313.63 343.36 312.59 343.46 311.57 343.26C310.57 343.06 309.65 342.56 308.93 341.84C308.21 341.12 307.71 340.2 307.51 339.2C307.31 338.2 307.41 337.16 307.81 336.2C308.21 335.26 308.87 334.44 309.71 333.88C310.57 333.3 311.57 333 312.59 333C313.97 333 315.27 333.56 316.25 334.52C317.23 335.48 317.77 336.8 317.77 338.18Z"
        fill="#F3123C"
      />
      <Path
        d="M230.43 133.74C230.43 134.84 230.09 135.92 229.49 136.84C228.87 137.74 228.01 138.46 226.99 138.88C225.97 139.3 224.85 139.4 223.77 139.2C222.71 138.98 221.71 138.44 220.93 137.68C220.17 136.9 219.63 135.9 219.41 134.82C219.21 133.74 219.31 132.64 219.73 131.62C220.15 130.6 220.87 129.74 221.77 129.12C222.69 128.52 223.77 128.18 224.87 128.18C226.35 128.18 227.75 128.78 228.79 129.82C229.83 130.86 230.43 132.28 230.43 133.74Z"
        fill="#737373"
      />
      <Path
        d="M287.43 133.739C287.43 134.839 287.11 135.919 286.49 136.839C285.87 137.759 285.01 138.459 283.99 138.879C282.99 139.299 281.87 139.419 280.79 139.199C279.71 138.979 278.71 138.459 277.93 137.679C277.17 136.899 276.63 135.919 276.41 134.839C276.21 133.759 276.31 132.639 276.73 131.619C277.15 130.599 277.87 129.739 278.77 129.119C279.69 128.519 280.77 128.199 281.87 128.199C283.33 128.199 284.75 128.779 285.79 129.819C286.83 130.859 287.43 132.279 287.43 133.739Z"
        fill="#737373"
      />
      <Path
        d="M165.59 360.457H151.43"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M304.21 104.66C302.19 102.64 299.45 101.5 296.59 101.5H210.19C207.33 101.5 204.59 102.64 202.57 104.66C200.55 106.68 199.41 109.42 199.41 112.28V198.68C199.41 201.54 200.55 204.28 202.57 206.3C204.59 208.32 207.33 209.46 210.19 209.46H296.59C299.45 209.46 302.19 208.32 304.21 206.3C306.23 204.28 307.37 201.54 307.37 198.68V112.28C307.37 109.42 306.23 106.68 304.21 104.66ZM229.49 136.84C228.87 137.74 228.01 138.46 226.99 138.88C225.97 139.3 224.85 139.4 223.77 139.2C222.71 138.98 221.71 138.44 220.93 137.68C220.17 136.9 219.63 135.9 219.41 134.82C219.21 133.74 219.31 132.64 219.73 131.62C220.15 130.6 220.87 129.74 221.77 129.12C222.69 128.52 223.77 128.18 224.87 128.18C226.35 128.18 227.75 128.78 228.79 129.82C229.83 130.86 230.43 132.28 230.43 133.74C230.43 134.84 230.09 135.92 229.49 136.84ZM286.49 136.84C285.87 137.76 285.01 138.46 283.99 138.88C282.99 139.3 281.87 139.42 280.79 139.2C279.71 138.98 278.71 138.46 277.93 137.68C277.17 136.9 276.63 135.92 276.41 134.84C276.21 133.76 276.31 132.64 276.73 131.62C277.15 130.6 277.87 129.74 278.77 129.12C279.69 128.52 280.77 128.2 281.87 128.2C283.33 128.2 284.75 128.78 285.79 129.82C286.83 130.86 287.43 132.28 287.43 133.74C287.43 134.84 287.11 135.92 286.49 136.84Z"
        fill="#F5F5F5"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M228.79 129.82C229.83 130.86 230.43 132.28 230.43 133.74C230.43 134.84 230.09 135.92 229.49 136.84C228.87 137.74 228.01 138.46 226.99 138.88C225.97 139.3 224.85 139.4 223.77 139.2C222.71 138.98 221.71 138.44 220.93 137.68C220.17 136.9 219.63 135.9 219.41 134.82C219.21 133.74 219.31 132.64 219.73 131.62C220.15 130.6 220.87 129.74 221.77 129.12C222.69 128.52 223.77 128.18 224.87 128.18C226.35 128.18 227.75 128.78 228.79 129.82V129.82Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M287.43 133.739C287.43 134.839 287.11 135.919 286.49 136.839C285.87 137.759 285.01 138.459 283.99 138.879C282.99 139.299 281.87 139.419 280.79 139.199C279.71 138.979 278.71 138.459 277.93 137.679C277.17 136.899 276.63 135.919 276.41 134.839C276.21 133.759 276.31 132.639 276.73 131.619C277.15 130.599 277.87 129.739 278.77 129.119C279.69 128.519 280.77 128.199 281.87 128.199C283.33 128.199 284.75 128.779 285.79 129.819C286.83 130.859 287.43 132.279 287.43 133.739V133.739Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M341.17 257.717V360.457H165.59V257.717C165.59 253.497 167.27 249.457 170.25 246.457C173.23 243.477 177.27 241.797 181.49 241.797H325.23C329.45 241.797 333.51 243.477 336.49 246.457C339.49 249.437 341.17 253.497 341.17 257.717Z"
        fill="#F5F5F5"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M292.89 360.48V294.16C292.89 292.26 292.13 290.44 290.79 289.1C289.45 287.76 287.63 287 285.73 287H221.03C219.13 287 217.31 287.76 215.97 289.1C214.63 290.44 213.87 292.26 213.87 294.16V360.48"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M235.41 241.817V209.477"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M271.37 209.457V241.797"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M151.43 195.839V360.459H98.7502V199.579C98.7502 198.179 99.3102 196.839 100.29 195.859C101.27 194.879 102.63 194.319 104.03 194.319C105.43 194.319 106.77 194.859 107.75 195.859C108.75 196.839 109.31 198.179 109.31 199.579H109.35V220.679H140.89V195.839C140.89 194.439 141.45 193.099 142.43 192.099C143.43 191.119 144.77 190.559 146.15 190.559C147.53 190.559 148.89 191.119 149.89 192.099C150.87 193.099 151.43 194.439 151.43 195.839Z"
        fill="#F5F5F5"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M137.39 185.239V220.678H126.83V185.278C126.83 178.398 137.39 178.119 137.39 185.239Z"
        fill="#F5F5F5"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M151.71 245.479L170.67 227.959C171.17 227.479 171.59 226.919 171.89 226.299C172.17 225.679 172.35 224.999 172.37 224.299C172.39 223.599 172.29 222.919 172.05 222.259C171.81 221.599 171.45 221.019 170.97 220.499C170.49 219.999 169.93 219.579 169.31 219.279C168.69 218.999 167.99 218.819 167.31 218.799C166.61 218.779 165.93 218.879 165.27 219.119C164.61 219.359 164.03 219.719 163.51 220.199L151.45 231.319"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M123.37 192.219V220.679H112.77V192.219C112.79 190.839 113.37 189.519 114.35 188.559C115.35 187.579 116.67 187.039 118.07 187.039C119.47 187.039 120.77 187.579 121.77 188.559C122.75 189.519 123.33 190.839 123.37 192.219Z"
        fill="#F5F5F5"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M98.7502 212.219H109.35"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M112.77 198.5H123.37"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M112.77 212.219H123.37"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M126.93 212.219H137.41"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M126.93 198.5H137.39"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M140.89 198.5H151.43"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M140.89 212.219H151.43"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M144.73 220.68H140.89"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M109.35 220.68H104.83"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M156.75 226.418L163.97 234.158"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M98.7502 260.297H151.43"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M98.7502 278.297H151.43"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M98.7502 296.297H151.43"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M98.7502 314.297H151.43"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M98.7502 332.297H151.43"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M98.7502 350.297H151.43"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M253.37 101.496V76.6562"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M260.77 69.2984C260.77 70.7584 260.33 72.1984 259.53 73.4184C258.71 74.6384 257.55 75.5784 256.21 76.1384C254.85 76.6984 253.37 76.8384 251.93 76.5584C250.49 76.2784 249.17 75.5584 248.13 74.5384C247.09 73.4984 246.39 72.1784 246.11 70.7384C245.83 69.2984 245.97 67.8184 246.53 66.4584C247.09 65.0984 248.05 63.9384 249.25 63.1384C250.47 62.3184 251.91 61.8984 253.37 61.8984C255.33 61.8984 257.21 62.6784 258.61 64.0784C260.01 65.4584 260.79 67.3584 260.79 69.3184L260.77 69.2984Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M230.43 133.74C230.43 134.84 230.09 135.92 229.49 136.84C228.87 137.74 228.01 138.46 226.99 138.88C225.97 139.3 224.85 139.4 223.77 139.2C222.71 138.98 221.71 138.44 220.93 137.68C220.17 136.9 219.63 135.9 219.41 134.82C219.21 133.74 219.31 132.64 219.73 131.62C220.15 130.6 220.87 129.74 221.77 129.12C222.69 128.52 223.77 128.18 224.87 128.18C226.35 128.18 227.75 128.78 228.79 129.82C229.83 130.86 230.43 132.28 230.43 133.74V133.74Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M287.43 133.739C287.43 134.839 287.11 135.919 286.49 136.839C285.87 137.759 285.01 138.459 283.99 138.879C282.99 139.299 281.87 139.419 280.79 139.199C279.71 138.979 278.71 138.459 277.93 137.679C277.17 136.899 276.63 135.919 276.41 134.839C276.21 133.759 276.31 132.639 276.73 131.619C277.15 130.599 277.87 129.739 278.77 129.119C279.69 128.519 280.77 128.199 281.87 128.199C283.33 128.199 284.75 128.779 285.79 129.819C286.83 130.859 287.43 132.279 287.43 133.739V133.739Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M264.01 146.978C256.75 142.278 249.61 141.978 242.73 146.978"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M235.41 219.879H271.37"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M235.41 231.457H271.37"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M195.19 360.477V300.477"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M200.35 295.297C200.35 296.317 200.05 297.317 199.47 298.177C198.89 299.037 198.09 299.697 197.15 300.077C196.21 300.457 195.17 300.577 194.15 300.377C193.15 300.177 192.23 299.677 191.51 298.957C190.79 298.237 190.29 297.317 190.09 296.317C189.89 295.317 189.99 294.277 190.39 293.317C190.79 292.377 191.45 291.557 192.29 290.997C193.15 290.437 194.15 290.117 195.17 290.117C196.55 290.117 197.85 290.657 198.83 291.637C199.81 292.617 200.35 293.917 200.35 295.297Z"
        fill="#F3123C"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M312.55 360.479V343.379"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M317.77 338.18C317.77 339.2 317.47 340.2 316.89 341.06C316.31 341.92 315.51 342.58 314.57 342.96C313.63 343.36 312.59 343.46 311.57 343.26C310.57 343.06 309.65 342.56 308.93 341.84C308.21 341.12 307.71 340.2 307.51 339.2C307.31 338.2 307.41 337.16 307.81 336.2C308.21 335.26 308.87 334.44 309.71 333.88C310.57 333.3 311.57 333 312.59 333C313.97 333 315.27 333.56 316.25 334.52C317.23 335.48 317.77 336.8 317.77 338.18Z"
        fill="#F3123C"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M312.55 332.957V276.297C312.55 274.097 311.67 271.977 310.11 270.417C308.55 268.857 306.43 267.977 304.23 267.977H203.49C201.29 267.977 199.17 268.857 197.61 270.417C196.05 271.977 195.17 274.077 195.17 276.297V290.077"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M239.27 69.2969H228.29"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M243.39 79.2188L235.65 87.0187"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M253.37 55.0381V43.0781"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M243.35 59.2187L234.85 50.7188"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M267.47 69.2969H278.45"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M263.35 79.2188L271.13 87.0187"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M263.43 59.2187L271.93 50.7188"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M262.35 321.958C262.29 320.598 261.91 319.238 261.19 318.018L254.93 307.218C254.45 306.398 254.13 305.518 253.93 304.598L253.67 305.058C252.13 307.738 252.13 311.018 253.67 313.698L258.69 322.338C260.23 325.018 260.23 328.298 258.69 330.978L258.43 331.418C258.25 330.518 257.91 329.638 257.45 328.818L249.93 315.858C249.45 315.038 249.13 314.158 248.95 313.258L248.69 313.698C247.15 316.358 247.15 319.638 248.69 322.318L253.71 330.978C255.25 333.658 255.25 336.938 253.71 339.618L253.45 340.078C253.27 339.178 252.93 338.298 252.45 337.458L246.19 326.658C245.35 325.218 244.97 323.578 245.03 321.958C238.33 323.698 233.69 327.398 233.69 331.698C233.69 337.658 242.65 342.498 253.69 342.498C264.73 342.498 273.69 337.658 273.69 331.698C273.69 327.418 269.07 323.698 262.35 321.958Z"
        fill="#F3123C"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M192.75 409.878C192.75 411.218 192.35 412.538 191.61 413.658C190.87 414.778 189.81 415.638 188.57 416.158C187.33 416.678 185.97 416.798 184.65 416.538C183.33 416.278 182.13 415.618 181.17 414.678C180.23 413.718 179.57 412.518 179.31 411.198C179.05 409.878 179.19 408.518 179.69 407.278C180.21 406.038 181.07 404.978 182.19 404.238C183.31 403.498 184.63 403.098 185.97 403.098C186.87 403.098 187.75 403.278 188.57 403.618C189.39 403.958 190.15 404.458 190.77 405.098C191.41 405.738 191.91 406.478 192.25 407.298C192.59 408.118 192.77 408.998 192.77 409.898L192.75 409.878Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M381.33 209.036C381.33 210.076 381.03 211.076 380.45 211.936C379.87 212.796 379.07 213.456 378.11 213.856C377.15 214.256 376.11 214.356 375.09 214.156C374.07 213.956 373.15 213.456 372.41 212.736C371.69 211.996 371.19 211.076 370.99 210.056C370.79 209.036 370.89 207.996 371.29 207.036C371.69 206.076 372.35 205.256 373.21 204.696C374.07 204.116 375.07 203.816 376.11 203.816C377.49 203.816 378.81 204.376 379.79 205.356C380.77 206.336 381.33 207.656 381.33 209.036V209.036Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M18.5902 138.078V172.898"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M36.0102 155.477H1.19019"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M465.27 233.117L451.87 262.917H476.19L462.79 292.717"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M365.17 60.7214C364.15 60.7214 363.15 60.3214 362.43 59.5814C361.71 58.8614 361.29 57.8614 361.29 56.8414V4.82141C361.29 3.80141 361.71 2.80141 362.43 2.08141C363.15 1.36141 364.15 0.941406 365.17 0.941406C366.19 0.941406 367.19 1.34141 367.91 2.08141C368.63 2.80141 369.05 3.80141 369.05 4.82141V56.8614C369.05 57.8814 368.63 58.8614 367.91 59.5814C367.19 60.3014 366.19 60.7214 365.17 60.7214Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M365.17 60.7228C364.15 60.7228 363.15 60.3228 362.43 59.5828C361.71 58.8628 361.29 57.8628 361.29 56.8428V25.4028C342.65 27.2628 328.11 41.7028 328.11 59.2028C328.11 65.7828 330.21 72.2028 334.05 77.5428L328.11 93.2228L343.71 86.8628C350.15 91.0428 357.67 93.2428 365.35 93.2228C385.91 93.2228 402.59 77.9628 402.59 59.2228C402.59 41.5428 387.87 27.0628 369.03 25.3828V56.8428C369.03 57.8628 368.61 58.8428 367.89 59.5628C367.17 60.2828 366.17 60.7028 365.15 60.7028L365.17 60.7228ZM369.65 73.8228C369.65 76.3028 367.65 78.3028 365.17 78.3028C362.69 78.3028 360.69 76.3028 360.69 73.8228C360.69 71.3428 362.69 69.3428 365.17 69.3428C367.65 69.3428 369.65 71.3428 369.65 73.8228Z"
        fill="#737373"
      />
      <Path
        d="M365.17 78.2999C367.65 78.2999 369.65 76.2998 369.65 73.8198C369.65 71.3398 367.65 69.3398 365.17 69.3398C362.69 69.3398 360.69 71.3398 360.69 73.8198C360.69 76.2998 362.69 78.2999 365.17 78.2999Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M365.37 25.2227C344.81 25.2227 328.13 40.4627 328.13 59.2227C328.13 65.8027 330.23 72.2226 334.07 77.5626L328.13 93.2426L343.73 86.8826C350.17 91.0626 357.69 93.2626 365.37 93.2426C385.93 93.2426 402.61 78.0027 402.61 59.2427C402.61 40.4827 385.93 25.2427 365.37 25.2427V25.2227Z"
        stroke="#595959"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_12821_110984">
        <Rect width="476.88" height="417.6" fill="white" transform="translate(0.250244)" />
      </ClipPath>
    </Defs>
  </Svg>
)

export const PageErrorOccurred = memo(PageErrorOccurredIllustration)
