import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ContactFilterScreenComponent } from './ContactFilterScreen.types'
import { useContactFilterScreen } from './useContactFilterScreen'

const ContactFilterScreenDesktop = lazy(() => import('./ContactFilterScreen.desktop'))

const ContactFilterScreenMobile = lazy(() => import('./ContactFilterScreen.mobile'))

export const ContactFilterScreen: ContactFilterScreenComponent = ({ navigation, route }) => {
  const contactFilterScreen = useContactFilterScreen({ navigation, route })

  const Screen = contactFilterScreen.isMobile
    ? ContactFilterScreenMobile
    : ContactFilterScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...contactFilterScreen} />
    </Suspense>
  )
}
