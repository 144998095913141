import { FunctionComponent, useCallback } from 'react'

import { Icons, Illustrations } from 'atoms'
import * as Linking from 'expo-linking'
import {
  Actionsheet,
  useBreakpointValue,
  VStack,
  Text,
  Button,
  Heading,
  Box,
  HStack
} from 'native-base'
import { Platform } from 'react-native'
import { useQueryClient } from 'react-query'
import { useSetAuthAtom } from 'src/store/auth'
import { useIsNewVersionAtomValue, useSetisNewVersionAtom } from 'src/store/newVersion'
import { useSetTokenAtom } from 'src/store/token'

const NewVersionContent: FunctionComponent = () => {
  const queryClient = useQueryClient()

  const setIsNewVersionAtom = useSetisNewVersionAtom()

  const newVersionAtomValue = useIsNewVersionAtomValue()

  const setAuthAtom = useSetAuthAtom()

  const setAuthToken = useSetTokenAtom()

  const logout = useCallback(() => {
    setAuthAtom(null)

    setAuthToken(null)

    queryClient.removeQueries()
  }, [queryClient, setAuthAtom, setAuthToken])

  const handleOpenStore = useCallback(() => {
    let url = ''

    if (Platform.OS === 'android') {
      url = 'https://play.google.com/store/apps/details?id=com.webmotors.portalmaisturbo&hl=pt-br'
    } else if (Platform.OS === 'ios')
      url = 'https://apps.apple.com/br/app/portal-mais-turbo/id6443906405'

    logout()

    if (url) Linking.openURL(url)

    return setIsNewVersionAtom({ is_required: false, open: false })
  }, [logout, setIsNewVersionAtom])

  const handleRememberLater = useCallback(() => {
    setIsNewVersionAtom({ is_required: false, open: false })
  }, [setIsNewVersionAtom])

  return (
    <VStack space={8} alignItems="center" justifyContent="center">
      <Box>
        <Illustrations.NewUpdate />
      </Box>
      <VStack space={3} justifyContent="center" alignItems="center">
        <Heading fontSize="16px" lineHeight="18px" color="gray.700" fontWeight={700}>
          Nova atualização disponível pra você
        </Heading>

        <VStack>
          <Text
            fontSize="12px"
            lineHeight="16px"
            color="gray.500"
            fontWeight={400}
            textAlign="center">
            O Portal Mais Turbo está cada dia mais turbinado!
          </Text>

          <HStack space={1} flexWrap="wrap" w="full">
            <Text fontSize="12px" lineHeight="16px" color="gray.500" fontWeight={700}>
              Baixe
            </Text>
            <Text fontSize="12px" lineHeight="16px" color="gray.500" fontWeight={400}>
              agora mesmo e aproveite
            </Text>
            <Text fontSize="12px" lineHeight="16px" color="gray.500" fontWeight={700}>
              os novos recursos.
            </Text>
          </HStack>
        </VStack>
      </VStack>

      <HStack w="full" space={2} alignItems="center">
        <Box borderWidth={1} borderRadius={4} borderColor="gray.100" px={4} py={2}>
          <HStack space={1} alignItems="center" justifyContent="center">
            <Icons.GooglePlayLogo size="24px" color="primary.500" />

            <Text fontSize="14px" lineHeight="18px" color="primary.500" fontWeight={600}>
              Google Play
            </Text>
          </HStack>
        </Box>
        <Box borderWidth={1} borderRadius={4} borderColor="gray.100" px={4} py={2}>
          <HStack space={1} alignItems="center" justifyContent="center">
            <Icons.AppleLogo size="24px" color="primary.500" />

            <Text fontSize="14px" lineHeight="18px" color="primary.500" fontWeight={600}>
              App Store
            </Text>
          </HStack>
        </Box>
      </HStack>

      <HStack w="full" space={4} alignItems="center">
        {!newVersionAtomValue.is_required && (
          <Button flex={1} onPress={handleRememberLater} colorScheme="gray" variant="outline">
            Lembrar depois
          </Button>
        )}
        <Button flex={1} colorScheme="gray" onPress={handleOpenStore}>
          Baixar agora!
        </Button>
      </HStack>
    </VStack>
  )
}

export const BottomSheetNewVersion: FunctionComponent = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const { open } = useIsNewVersionAtomValue()

  if (isDesktop) return <></>

  return (
    <Actionsheet hideDragIndicator isOpen={open} onClose={() => {}}>
      <Actionsheet.Content px={4} pt={4} pb={10}>
        <NewVersionContent />
      </Actionsheet.Content>
    </Actionsheet>
  )
}
