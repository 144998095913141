import { useEffect, useState } from 'react'

import { LoadingTurbo } from 'atoms'
import { AuthData } from 'integration/resources/auth'
import { useQueryClient } from 'react-query'
import { useAuthAtom } from 'src/store/auth'
import { useTokenAtom } from 'src/store/token'
import { Storage } from 'src/utils'

import { SSOAuthScreenComponent, TSSOMessageEventData } from './SSOAuth.types'

const ALLOWED_ORIGINS = [
  'https://hml-portal.formacaoturbo.com.br',
  'https://portal.formacaoturbo.com.br'
]

export const SSOAuthScreen: SSOAuthScreenComponent = ({ navigation }) => {
  const [tokenAuthAtom, setTokenAuthAtom] = useTokenAtom()

  const [authAtom, setAuthAtom] = useAuthAtom()

  const queryClient = useQueryClient()

  const [authProcessed, setAuthProcessed] = useState(false)

  const handleRedirect = () => {
    if (authAtom && tokenAuthAtom) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'Tab', params: { screen: 'Home', refetch: true } }]
      })
    } else {
      navigation.reset({
        index: 0,
        routes: [{ name: 'Login' }]
      })
    }
  }

  const handleAuthData = async (event: MessageEvent<TSSOMessageEventData>) => {
    const isOriginAllowed = ALLOWED_ORIGINS.some((origin) =>
      window?.document?.referrer?.includes(origin)
    )

    if (!isOriginAllowed) {
      setAuthProcessed(true)

      console.error('Invalid message received', window?.document?.referrer)

      return
    }

    if (event?.data?.type === 'SSO_AUTH_DATA') {
      const parsedSSOInfo = JSON.parse(event.data.ssoInfo) as AuthData

      try {
        await Storage.setItem('secure_deviceid', event.data.deviceId)

        setTokenAuthAtom(parsedSSOInfo?.auth?.token)

        setAuthAtom(parsedSSOInfo)

        queryClient.invalidateQueries()
      } catch (error) {
        console.error('Error processing SSO data:', error)
      } finally {
        setAuthProcessed(true)
      }
    }
  }

  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener('message', handleAuthData)

      // timeout to mark auth as processed if no SSO message is received
      const timeoutId = setTimeout(() => {
        console.error('No message received.')

        setAuthProcessed(true)
      }, 9000)

      return () => {
        window.removeEventListener('message', handleAuthData)

        clearTimeout(timeoutId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (authProcessed) {
      handleRedirect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authProcessed])

  return <LoadingTurbo />
}
