import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ChatIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled = false,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        filled
          ? 'M15.545 0H3.155C2.45085 0 1.77554 0.279722 1.27763 0.777632C0.779722 1.27554 0.5 1.95085 0.5 2.655L0.5 11.505C0.5 12.2092 0.779722 12.8845 1.27763 13.3824C1.77554 13.8803 2.45085 14.16 3.155 14.16H4.04V16.815C4.03982 16.9817 4.08674 17.1451 4.17536 17.2864C4.26398 17.4276 4.3907 17.5409 4.54091 17.6133C4.66096 17.6702 4.79213 17.6998 4.925 17.7C5.12563 17.7001 5.32035 17.6321 5.47724 17.5071L9.66152 14.16H15.545C16.2492 14.16 16.9245 13.8803 17.4224 13.3824C17.9203 12.8845 18.2 12.2092 18.2 11.505V2.655C18.2 1.95085 17.9203 1.27554 17.4224 0.777632C16.9245 0.279722 16.2492 0 15.545 0V0Z'
          : 'M2.54999 1.5C2.27151 1.5 2.00444 1.61062 1.80753 1.80754C1.61062 2.00445 1.49999 2.27152 1.49999 2.55V11.55C1.49999 11.8285 1.61062 12.0955 1.80753 12.2925C2.00444 12.4894 2.27151 12.6 2.54999 12.6H4.34998C4.76419 12.6 5.09998 12.9358 5.09998 13.35V15.3895L8.38144 12.7644C8.51443 12.658 8.67966 12.6 8.84996 12.6H15.1499C15.4284 12.6 15.6955 12.4894 15.8924 12.2925C16.0893 12.0955 16.1999 11.8285 16.1999 11.55V2.55C16.1999 2.27152 16.0893 2.00445 15.8924 1.80754C15.6955 1.61062 15.4284 1.5 15.1499 1.5H2.54999ZM0.746875 0.746878C1.22509 0.26866 1.87369 0 2.54999 0H15.1499C15.8262 0 16.4748 0.26866 16.9531 0.746878C17.4313 1.2251 17.6999 1.8737 17.6999 2.55V11.55C17.6999 12.2263 17.4313 12.8749 16.9531 13.3531C16.4748 13.8313 15.8262 14.1 15.1499 14.1H9.11305L4.8185 17.5357C4.59337 17.7158 4.28494 17.7509 4.02509 17.626C3.76524 17.5011 3.59998 17.2383 3.59998 16.95V14.1H2.54999C1.87369 14.1 1.22509 13.8313 0.746875 13.3531C0.268659 12.8749 0 12.2263 0 11.55V2.55C0 1.8737 0.268659 1.2251 0.746875 0.746878Z'
      }
      fill="currentColor"
    />
  </Icon>
)
