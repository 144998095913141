// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const MiniArrowRightIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 23 24', ...props }}>
    <Path
      fill="currentColor"
      d="M19.556 11.996a.922.922 0 0 0-.317-.701l-7.407-6.473a.926.926 0 0 0-1.219 1.395l5.56 4.858H3.816a.926.926 0 0 0 0 1.852h12.343l-5.546 4.86a.926.926 0 1 0 1.22 1.392l7.318-6.412a.925.925 0 0 0 .406-.766v-.005Z"
    />
  </Icon>
)
