import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import * as requests from './requests'
import { RvExtracts, RvSummary } from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_GET_RV_EXTRACTS_QUERY = 'useGetRvExtractsQuery'

export const KEY_GET_RV_SUMMARY_QUERY = 'useGetRvSummaryQuery'

export const useGetRvExtractsQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<RvExtracts>>, AxiosError<ResponseError>>(
    [KEY_GET_RV_EXTRACTS_QUERY],
    () => requests.getRvExtracts()
  )

export const useGetRvSummaryQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<RvSummary>>, AxiosError<ResponseError>>(
    [KEY_GET_RV_SUMMARY_QUERY],
    () => requests.getRvSummary()
  )
