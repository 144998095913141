import { forwardRef, useImperativeHandle } from 'react'

import { CreateAccountPayload } from 'integration/resources/createAccount'
import {
  VStack,
  Heading,
  Text,
  HStack,
  Button,
  Actionsheet,
  useDisclose,
  useBreakpointValue,
  Modal,
  Box
} from 'native-base'
import { MaskService } from 'react-native-masked-text'

type TBottomSheetMethodAuthenticate = {
  isLoading: boolean | undefined
  userData?: Pick<CreateAccountPayload, 'cpf' | 'email' | 'phone_number_cell'>
  handleConfirmButton(): void
}

export type TBottomSheetHandleRef = {
  onToggle: () => void
}

const ConfirmContent = ({
  userData,
  isLoading,
  handleConfirmButton,
  onClose
}: TBottomSheetMethodAuthenticate & { onClose(): void }) => {
  return (
    <VStack justifyContent="center">
      <VStack alignItems="center">
        <Heading
          fontSize="24px"
          lineHeight="30px"
          textAlign="center"
          color="gray.700"
          mb={6}
          mt={6}>
          Por favor confirme seus dados
        </Heading>
        <VStack>
          <Text
            textAlign="center"
            fontSize={{
              base: '12px',
              lg: '18px'
            }}
            lineHeight={{
              base: '16px',
              lg: '30px'
            }}
            color="gray.500">
            CPF: {MaskService.toMask('cpf', userData?.cpf ?? '')}
          </Text>
          <Text
            textAlign="center"
            fontSize={{
              base: '12px',
              lg: '18px'
            }}
            lineHeight={{
              base: '16px',
              lg: '30px'
            }}
            color="gray.500">
            Telefone: {MaskService.toMask('cel-phone', userData?.phone_number_cell.slice(2) ?? '')}
          </Text>
          <Text
            textAlign="center"
            fontSize={{
              base: '12px',
              lg: '18px'
            }}
            lineHeight={{
              base: '16px',
              lg: '30px'
            }}
            color="gray.500">
            E-mail: {userData?.email}
          </Text>
        </VStack>
      </VStack>
      <HStack maxW="full" justifyContent="space-between" mt={6} mb={6}>
        <Button flex={1} colorScheme="gray" onPress={onClose} variant="outline">
          Revisar
        </Button>
        <Box w={4} />
        <Button flex={1} colorScheme="gray" isLoading={isLoading} onPress={handleConfirmButton}>
          Confirmar
        </Button>
      </HStack>
    </VStack>
  )
}

export const BottomSheetConfirmData = forwardRef<
  TBottomSheetHandleRef,
  TBottomSheetMethodAuthenticate
>(({ handleConfirmButton, userData, isLoading }, ref) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const { isOpen, onToggle, onClose } = useDisclose(false)

  useImperativeHandle(ref, () => ({
    onToggle
  }))

  return isDesktop ? (
    <Modal isOpen={isOpen} size="xl">
      <Modal.Content px={6} py="64px" minW="640px" borderRadius="20px">
        <Modal.Body>
          <ConfirmContent
            userData={userData}
            isLoading={isLoading}
            onClose={onClose}
            handleConfirmButton={handleConfirmButton}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  ) : (
    <Actionsheet hideDragIndicator isOpen={isOpen}>
      <Actionsheet.Content>
        <ConfirmContent
          userData={userData}
          isLoading={isLoading}
          onClose={onClose}
          handleConfirmButton={handleConfirmButton}
        />
      </Actionsheet.Content>
    </Actionsheet>
  )
})
