import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const DashboardsIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.20002 1.64844C2.92155 1.64844 2.65448 1.75907 2.45756 1.95598C2.26065 2.1529 2.15002 2.41997 2.15002 2.69845V15.2985C2.15002 15.577 2.26065 15.8441 2.45756 16.041C2.65448 16.2379 2.92155 16.3485 3.20002 16.3485H15.8C16.0785 16.3485 16.3456 16.2379 16.5425 16.041C16.7394 15.8441 16.85 15.577 16.85 15.2985V2.69845C16.85 2.41997 16.7394 2.1529 16.5425 1.95598C16.3456 1.75907 16.0785 1.64844 15.8 1.64844H3.20002ZM1.3969 0.895318C1.87512 0.417099 2.52372 0.148438 3.20002 0.148438H15.8C16.4763 0.148438 17.1249 0.417099 17.6031 0.895318C18.0814 1.37354 18.35 2.02214 18.35 2.69845V15.2985C18.35 15.9748 18.0814 16.6234 17.6031 17.1016C17.1249 17.5799 16.4763 17.8485 15.8 17.8485H3.20002C2.52372 17.8485 1.87512 17.5799 1.3969 17.1016C0.918685 16.6234 0.650024 15.9748 0.650024 15.2985V2.69845C0.650024 2.02214 0.918684 1.37354 1.3969 0.895318Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4942 3.52345C13.9084 3.52345 14.2442 3.85924 14.2442 4.27345V13.7235C14.2442 14.1377 13.9084 14.4735 13.4942 14.4735C13.08 14.4735 12.7442 14.1377 12.7442 13.7235V4.27345C12.7442 3.85924 13.08 3.52345 13.4942 3.52345Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.50002 7.40427C9.91424 7.40427 10.25 7.74006 10.25 8.15427V13.7235C10.25 14.1377 9.91424 14.4735 9.50002 14.4735C9.08581 14.4735 8.75002 14.1377 8.75002 13.7235V8.15427C8.75002 7.74006 9.08581 7.40427 9.50002 7.40427Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50582 9.26008C5.92004 9.26008 6.25582 9.59586 6.25582 10.0101V13.7235C6.25582 14.1377 5.92004 14.4735 5.50582 14.4735C5.09161 14.4735 4.75582 14.1377 4.75582 13.7235V10.0101C4.75582 9.59586 5.09161 9.26008 5.50582 9.26008Z"
      fill="currentColor"
    />
  </Icon>
)
