import {
  AuthData,
  AuthPayload,
  LogoutPayload,
  PreAuthData,
  PreAuthPayload,
  PreAuthResponse,
  PreAuthSendCode
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

/**
 * This method aims to facilitate obtaining an authentication code during development
 */
export const fetchAndPrintAuthCode_DEV_MODE = async (id: string) => {
  if (!__DEV__) {
    throw new Error(
      'The fetchAndPrintAuthCode_DEV_MODE method should not be run outside of the DEV environment.'
    )
  }

  const response = await client.get<ResponseSuccess<{ code: string }>>(
    `v1/__develop_/_force-logout_/${id}`
  )

  console.info(`Your Auth Code is: ${JSON.stringify(response.data.data?.code)}`)
}

export const preAuth = async (data: PreAuthPayload) => {
  const response = await client.post<ResponseSuccess<PreAuthResponse>>('v1/pre-auth', data)

  return response
}

export const auth = ({ slt, ...data }: AuthPayload) =>
  client.post<ResponseSuccess<AuthData>>('v1/auth', data, {
    headers: {
      'x-slt': slt
    }
  })

export const logout = ({ ...data }: LogoutPayload) =>
  client.delete<ResponseSuccess<any>>('v1/auth', { data })

export const acceptTerms = async () => {
  const commitmentResponse = client.patch<ResponseSuccess<null>>('v1/users/me/term/1/accept')

  const responsibilityResponse = client.patch<ResponseSuccess<null>>('v1/users/me/term/2/accept')

  const response = await Promise.all([commitmentResponse, responsibilityResponse])

  return response
}

export const acceptNewTerm = async () => {
  const response = await client.patch<ResponseSuccess<null>>('v1/users/me/term/3/accept')

  return response
}

export const authSendCode = async ({ type, slt, user_id }: PreAuthSendCode) => {
  const response = await client.post<ResponseSuccess<PreAuthData>>(
    `v1/auth/${type}-authentication`,
    { user_id },
    {
      headers: {
        'x-slt': slt
      }
    }
  )

  if (__DEV__) {
    await fetchAndPrintAuthCode_DEV_MODE(user_id)
  }

  return response
}
