import { useCallback, useState } from 'react'

import {
  Note,
  useDeleteNoteContact,
  useFavoriteContactMutation,
  useGetContactItemlQuery,
  useUnfavoriteContactMutation
} from 'integration/resources/wallet'
import { useBreakpointValue } from 'native-base'
import { FAVORITE_TYPE } from 'src/screens/ContentScreen/ContentScreen.types'

import { Documents } from './components/Documents'
import { Informations } from './components/Informations'
import { Notes } from './components/Notes'
import { UseContactScreen } from './ContactScreen.types'

export const useContactScreen: UseContactScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { contactId } = route?.params

  const { data, isLoading } = useGetContactItemlQuery(contactId)

  const contact = data?.data.data

  const favoriteContactMutation = useFavoriteContactMutation(contactId)

  const unfavoriteContactMutation = useUnfavoriteContactMutation(contactId)

  const handleToggleFavorite = (type: FAVORITE_TYPE) => {
    const mutation =
      type === FAVORITE_TYPE.FAVORITE ? favoriteContactMutation : unfavoriteContactMutation

    return mutation.mutateAsync({ id: contactId })
  }

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  const handleGoToContactDetails = useCallback(() => {
    if (contactId) navigation.navigate('ContactDetails', { contactId })
  }, [navigation, contactId])

  const handleGoToContactBirthDate = useCallback(() => {
    if (contact) navigation.navigate('ContactBirthday', { contactId })
  }, [contact, contactId, navigation])

  const handleGoToContactEdit = useCallback(() => {
    if (contact)
      navigation.navigate('ContactEdit', { contact: { ...contact, id: contactId }, contactId })
  }, [contact, contactId, navigation])

  const handleGoToNoteCreateDetails = useCallback(
    () =>
      navigation.navigate(isMobile ? 'CreateNoteDetails' : 'ContactCreateNoteDetails', {
        contactId
      }),
    [contactId, isMobile, navigation]
  )

  const handleGoToNoteDetails = useCallback(
    (id: string) => navigation.navigate('NoteDetails', { id, key: 'contacts', contactId }),
    [navigation, contactId]
  )

  const [currentTab, setCurrentTab] = useState<number>(1)

  const handleGoToEditNote = useCallback(
    (note: Note) => {
      navigation.navigate(isMobile ? 'EditNoteDetails' : 'ContactEditNoteDetails', {
        note,
        key: 'contacts',
        contactId
      })
    },
    [contactId, isMobile, navigation]
  )

  const [isConfirmDeleteNoteOpen, setIsConfirmDeleteNoteOpen] = useState(false)

  const { mutate: deleteNoteMutation, isLoading: isLoadingNoteDelete } = useDeleteNoteContact()

  const handleConfirmDelete = (note: Note) => {
    deleteNoteMutation(
      { id: note!.id },
      {
        onSuccess: () => {
          setIsConfirmDeleteNoteOpen(false)
        },
        onError: () => {
          setIsConfirmDeleteNoteOpen(false)
        }
      }
    )
  }

  const mainContent = {
    1: {
      component: Informations,
      name: 'Dados de contato'
    },
    2: {
      component: Notes,
      name: 'Notas'
    },
    3: {
      component: Documents,
      name: 'Documentos'
    }
  }

  const mainContentCommonProps = {
    contact,
    contactId,
    handleGoToNoteDetails,
    handleOpenEditContact: handleGoToContactEdit,
    handleGoToContactDetails,
    handleGoToContactBirthDate,
    handleGoToNoteCreateDetails,
    handleGoToEditNote,
    handleToggleFavorite,
    isConfirmDeleteNoteOpen,
    isLoadingNoteDelete,
    setIsConfirmDeleteNoteOpen,
    handleConfirmDelete
  }

  return {
    contact,
    isMobile,
    currentTab,
    mainContent,
    contactIsFavorite: contact?.is_favorite ?? false,
    mainContentCommonProps,
    contactIsLoading: isLoading,
    favoriteIsLoading: favoriteContactMutation.isLoading || unfavoriteContactMutation.isLoading,
    handleGoBack,
    setCurrentTab,
    handleGoToContactEdit,
    handleToggleFavorite
  }
}
