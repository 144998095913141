import { FunctionComponent, memo } from 'react'

import { Illustrations } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { OpportunityCard } from 'atoms/web/Card/OpportunityCard'
import { Center, ScrollView, Text, VStack } from 'native-base'

import { useOpportunityContainerList } from './useOpportunityContainerList'

type OpportunityContainerListProps = {
  tabNumber: string
}

export const OpportunityContainerListDesktop: FunctionComponent<OpportunityContainerListProps> =
  memo(({ tabNumber }) => {
    const { isLoading, opportunities, onScrollEndDragOpportunityList, handlePressFavorite } =
      useOpportunityContainerList({
        store_tab_numbers: [Number(tabNumber)]
      })

    const activitySkeleton = isLoading && !opportunities.length

    const noResults = !opportunities.length && !isLoading

    const contentPreview = new Array<number>(5).fill(0)

    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        onScrollEndDrag={onScrollEndDragOpportunityList}>
        {activitySkeleton &&
          contentPreview.map((_, i) => <SkeletonCard key={`key-skeleton-${i}`} />)}

        <VStack space={6}>
          {opportunities.map((props) => (
            <OpportunityCard
              onFavoriteClick={handlePressFavorite}
              isFavorite={props.is_highlighted}
              {...props}
            />
          ))}
        </VStack>

        {noResults && (
          <Center>
            <Illustrations.RelatedOpportunitiesEmpty />
            <Text fontSize="16px" lineHeight="24px" color="gray.500" textAlign="center" mt={6}>
              Ainda não há oportunidades relacionadas para este TAB.
            </Text>
          </Center>
        )}
      </ScrollView>
    )
  })
