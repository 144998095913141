import { useCallback, useState } from 'react'

import {
  KEY_DASHBOARDS_QUERY,
  useFavoriteDashboardMutation,
  useGetDashboardQuery,
  useUnfavoriteDashboardMutation
} from 'integration/resources/dashboards/hooks'
import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import { FAVORITE_TYPE } from 'src/screens/ContentScreen/ContentScreen.types'

import { UseDashboardDetailScreen } from './DashboardDetailScreen.types'

export const useDashboardDetailScreen: UseDashboardDetailScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const dashboardId = route.params.dashboardId

  const { data, isLoading } = useGetDashboardQuery(dashboardId)

  const dashboardItem = data?.data.data

  const [isFavorite, setFavorite] = useState(dashboardItem?.is_favorite)

  const [openFullScreen, setOpenFullScreen] = useState(false)

  const [favoriteLoading, setFavoriteLoading] = useState(false)

  const favoriteDashboardMutation = useFavoriteDashboardMutation()

  const unfavoriteDashboardMutation = useUnfavoriteDashboardMutation()

  const queryClient = useQueryClient()

  const handleFavoriteDashboard = (type: FAVORITE_TYPE, id: string) => {
    const toggle = [favoriteDashboardMutation, unfavoriteDashboardMutation]

    const mutation = type === FAVORITE_TYPE.FAVORITE ? toggle[0] : toggle[1]

    return mutation.mutateAsync({ id })
  }

  const handleToggleFavorite = () => {
    setFavoriteLoading(true)

    handleFavoriteDashboard(
      dashboardItem?.is_favorite ? FAVORITE_TYPE.UNFAVORITE : FAVORITE_TYPE.FAVORITE,
      dashboardItem?.id ?? ''
    )
      .then(() => {
        setFavorite(!dashboardItem?.is_favorite)
      })
      .finally(() => {
        setFavoriteLoading(false)
      })
  }

  const handleGoBack = useCallback(() => {
    navigation.canGoBack() && navigation.goBack()

    const onRefresh = () => queryClient.invalidateQueries([KEY_DASHBOARDS_QUERY])

    onRefresh()
  }, [queryClient, navigation])

  return {
    isMobile,
    navigation,
    dashboardItem,
    isLoading,
    isFavorite,
    favoriteLoading,
    handleToggleFavorite,
    handleGoBack,
    openFullScreen,
    setOpenFullScreen
  }
}
