import { useCallback, useEffect, useRef } from 'react'

import { Icons, Avatar, CustomStatusBar } from 'atoms'
import { LinearGradient } from 'expo-linear-gradient'
import * as Linking from 'expo-linking'
import { Contact, TabDetail } from 'integration/resources/wallet'
import LottieView from 'lottie-react-native'
import {
  Box,
  Center,
  Heading,
  HStack,
  IconButton,
  Pressable,
  ScrollView,
  Skeleton,
  Text,
  useBreakpointValue,
  useTheme,
  View,
  VStack
} from 'native-base'
import { Dimensions, FlatList, Platform } from 'react-native'
import Animated, {
  Easing,
  useAnimatedProps,
  useSharedValue,
  withTiming
} from 'react-native-reanimated'
import { FAVORITE_TYPE } from 'src/screens/ContentScreen/ContentScreen.types'

type TNavigation = {
  handleGoBack(): void
  handleGoToContactEdit(): void
}

type TTabs<T> = {
  currentTab: number
  setCurrentTab(index: number): void
  mainContent: {
    [k: number]: {
      component: React.FunctionComponent<Partial<T>>
      name: string
    }
  }
  mainContentCommonProps: T
}

type TFavorite = {
  contactIsFavorite: boolean
  handleToggleFavorite(type: FAVORITE_TYPE): void
}

type ContactScreenTemplateProps<T> = {
  isTab: boolean
  contact?: TabDetail | Contact
  navigation: TNavigation
  tabs: TTabs<T>
  favorite?: TFavorite
  contactIsLoading?: boolean
  favoriteIsLoading?: boolean
}

export type CurrentViewTypesRef = {
  onScrollEndDragContent?: () => void
}

const AnimatedLottieView = Animated.createAnimatedComponent(LottieView)

export const ContactScreenTemplate = <T extends any>({
  isTab,
  contact,
  navigation,
  tabs,
  favorite,
  contactIsLoading,
  favoriteIsLoading
}: ContactScreenTemplateProps<T>) => {
  const currentViewRef = useRef<CurrentViewTypesRef>(null)

  const animateButton = useSharedValue(0)

  const animatedProgress = useSharedValue(favorite?.contactIsFavorite ? 1 : 0)

  const isMobile = useBreakpointValue({ base: true, lg: false })

  useEffect(() => {
    animatedProgress.value = favorite?.contactIsFavorite ? 1 : 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favorite?.contactIsFavorite])

  const handleOpenGps = () => {
    if (contact) {
      const fullAddress = contact.secondary_address_street
        ? `${contact.secondary_address_street}, ${contact.secondary_address_number} - ${contact.secondary_address_neighborhood} ${contact.secondary_address_city} - ${contact.secondary_address_uf}, ${contact.secondary_address_postal_code}`
        : `${contact.address_street}, ${contact.address_number} - ${contact.address_neighborhood} ${contact.address_city} - ${contact.address_uf}, ${contact.address_postal_code}`

      const url = Platform.select({
        ios: `maps:0,0?q=${fullAddress}`,
        android: `geo:0,0?q=${fullAddress}`,
        web: `https://www.google.com/maps/search/?api=1&query=${fullAddress}`
      }) as string

      Linking.openURL(url)
    }
  }

  const handleSendWhatsapp = () => {
    const urlWhatsApp = isMobile
      ? `whatsapp://send?phone=+55${contact?.phone_number_ddd}${contact?.phone_number}&text=Olá ${contact?.name}`
      : `https://wa.me/55${contact?.phone_number_ddd}${contact?.phone_number}?text=Olá%20${contact?.name}`

    const urlSms = `sms:${contact?.phone_number_ddd}${contact?.phone_number}`

    Linking.canOpenURL(urlWhatsApp)
      .then((supported) => {
        if (supported) {
          return Linking.openURL(urlWhatsApp)
        } else {
          Linking.openURL(urlSms)
        }
      })
      .catch(() => Linking.openURL(urlSms))
  }

  const theme = useTheme()

  const keyExtractor = useCallback((item: { key: string }) => item.key, [])

  const CurrentView = tabs.mainContent[tabs.currentTab]?.component

  const data = [
    {
      key: '1'
    }
  ]

  const isIOS = Platform.OS === 'ios'

  const renderItem = useCallback(
    ({ item }: { item: { key: string } }, index: number) => {
      return (
        <View bg="background.base" flex={1} minH={400} key={`${index}-${item.key}`} px={4}>
          {/* @ts-ignore */}
          <CurrentView ref={currentViewRef} {...tabs.mainContentCommonProps} />
        </View>
      )
    },
    [CurrentView, tabs.mainContentCommonProps]
  )

  const animatedProps = useAnimatedProps(() => {
    return {
      progress: animateButton.value
        ? withTiming(animatedProgress.value, {
            duration: 2000,
            easing: Easing.linear
          })
        : animatedProgress.value
    }
  })

  return (
    <View bg="background.base" flex={1}>
      <LinearGradient
        end={{ x: 0.3, y: 1 }}
        start={{ x: 0, y: 0.1 }}
        locations={[0, 0.2, 0.3, 0.9]}
        colors={[
          theme.colors['header-gradient']['50'],
          theme.colors['header-gradient']['200'],
          theme.colors['header-gradient']['200'],
          theme.colors.background.base
        ]}>
        <FlatList
          data={data}
          keyExtractor={keyExtractor}
          ListHeaderComponent={
            <>
              <Box px={4}>
                <HStack alignItems="center" safeAreaTop mt="32px">
                  <Pressable flex={1} onPress={navigation.handleGoBack}>
                    {!isTab ? (
                      <Icons.Chevron direction="left" color="white" size={7} />
                    ) : (
                      <Icons.Close ml={2} color="white" />
                    )}
                  </Pressable>
                  <HStack flex={1} justifyContent="flex-end" position="relative">
                    <Skeleton
                      size={5}
                      rounded="full"
                      mr={8}
                      isLoaded={!contactIsLoading || favoriteIsLoading}>
                      <Pressable
                        mr={8}
                        isDisabled={favoriteIsLoading}
                        _disabled={{ opacity: 0.6 }}
                        onPress={() => {
                          animateButton.value = 1

                          animatedProgress.value = favorite?.contactIsFavorite ? 0 : 1

                          favorite?.handleToggleFavorite(
                            favorite?.contactIsFavorite
                              ? FAVORITE_TYPE.UNFAVORITE
                              : FAVORITE_TYPE.FAVORITE
                          )
                        }}>
                        {Platform.OS === 'web' ? (
                          <Icons.Star
                            filled={favorite?.contactIsFavorite}
                            color={favorite?.contactIsFavorite ? 'tabs-yellow.50' : 'white'}
                            size={5}
                          />
                        ) : (
                          <Box position="absolute" top="-6px" right="-6px">
                            <AnimatedLottieView
                              autoPlay={false}
                              animatedProps={animatedProps}
                              source={require('assets/star.json')}
                              style={{
                                width: 32
                              }}
                            />
                          </Box>
                        )}
                      </Pressable>
                    </Skeleton>

                    <Pressable onPress={navigation.handleGoToContactEdit} mr={4}>
                      <Icons.Edit color="white" size={5} />
                    </Pressable>
                  </HStack>
                </HStack>

                <Center>
                  <Skeleton
                    borderWidth={1}
                    borderColor="coolGray.500"
                    size={25}
                    startColor="gray.700"
                    rounded="full"
                    mt="22px"
                    isLoaded={!contactIsLoading}>
                    <Avatar
                      type="light"
                      mt="22px"
                      size={25}
                      fontSize={37}
                      title={contact?.name}
                      uri={
                        !isTab
                          ? (contact?.profile_image_url as string)
                          : (contact?.logo_image_url as string)
                      }
                    />
                  </Skeleton>
                  <Skeleton.Text
                    lines={2}
                    alignItems="center"
                    mt={7}
                    isLoaded={!contactIsLoading}
                    startColor="gray.700">
                    <Heading textAlign="center" fontSize="24px" bold color="white" mt={2}>
                      {contact?.name}
                    </Heading>

                    <HStack alignItems="center" mt={1}>
                      <Text fontSize="14px" color="white">
                        {!isTab ? contact?.jobTitle : 'TAB'}
                      </Text>
                      <Box bg="white" borderRadius="full" size={1} mx={1} />
                      <Text fontSize="14px" color="white" isTruncated maxW="50%">
                        {!isTab ? contact?.store_name : contact?.tab_number}
                      </Text>
                    </HStack>
                  </Skeleton.Text>
                  <HStack space={4} my={8}>
                    {contact && (
                      <>
                        {!!contact?.website && (
                          <IconButton
                            borderRadius="full"
                            borderWidth={1}
                            borderColor="white"
                            _pressed={{
                              bg: 'transparent'
                            }}
                            icon={<Icons.World color="white" size="18px" />}
                            onPress={() => Linking.openURL(contact?.website ?? '')}
                          />
                        )}
                        {!!contact?.email && (
                          <IconButton
                            borderRadius="full"
                            borderWidth={1}
                            borderColor="white"
                            _pressed={{
                              bg: 'transparent'
                            }}
                            icon={<Icons.Mail filled color="white" size="18px" />}
                            onPress={() => Linking.openURL(`mailto:${contact.email}`)}
                          />
                        )}
                        <IconButton
                          borderRadius="full"
                          borderWidth={1}
                          borderColor="white"
                          _pressed={{
                            bg: 'transparent'
                          }}
                          icon={<Icons.Phone filled color="white" size="18px" />}
                          onPress={() =>
                            Linking.openURL(
                              `tel:${contact.phone_number_ddd}${contact.phone_number}`
                            )
                          }
                        />
                        <IconButton
                          borderRadius="full"
                          borderWidth={1}
                          borderColor="white"
                          _pressed={{
                            bg: 'transparent'
                          }}
                          icon={<Icons.Location filled color="white" size="18px" />}
                          onPress={handleOpenGps}
                        />
                        <IconButton
                          borderRadius="full"
                          borderWidth={1}
                          borderColor="white"
                          _pressed={{
                            bg: 'transparent'
                          }}
                          icon={<Icons.Chat filled color="white" size="18px" />}
                          onPress={handleSendWhatsapp}
                        />
                      </>
                    )}
                  </HStack>
                </Center>
              </Box>

              <VStack
                bg="background.base"
                mt={isIOS ? (Dimensions.get('window').height > 670 ? 0 : 8) : 8}
                borderTopRadius={32}
                pt={7}>
                <HStack>
                  <ScrollView horizontal showsHorizontalScrollIndicator={false} pr={4} pl={4}>
                    {Object.keys(tabs.mainContent).map((tab: string, index) => (
                      <Pressable
                        onPress={() => tabs.setCurrentTab(Number(tab))}
                        key={tab}
                        mr={Object.keys(tabs.mainContent).length - 1 === index ? 3 : 1}>
                        <Center
                          bg={tabs.currentTab === Number(tab) ? 'primary.500' : 'white'}
                          borderRadius="full"
                          px={5}
                          py="10px"
                          mr={2}>
                          <Text color={tabs.currentTab === Number(tab) ? 'white' : 'gray.600'} bold>
                            {tabs.mainContent[Number(tab)].name}
                          </Text>
                        </Center>
                      </Pressable>
                    ))}
                  </ScrollView>
                </HStack>
              </VStack>
            </>
          }
          flexShrink={1}
          scrollEventThrottle={16}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          onScrollEndDrag={() => {
            if (currentViewRef.current) {
              currentViewRef.current?.onScrollEndDragContent?.()
            }
          }}
          // @ts-ignore
          renderItem={renderItem}
        />
      </LinearGradient>

      <CustomStatusBar barStyle="light-content" />
    </View>
  )
}
