import { Box } from 'native-base'

import Icons from './Icons'

export const IndicatorLevel = ({ positive = false }) => {
  return (
    <Box position="relative" borderRadius="full" h={10} w={10}>
      <Box
        opacity={0.1}
        backgroundColor={positive ? 'secondary.500' : 'primary.500'}
        position="absolute"
        borderRadius="full"
        left={0}
        right={0}
        bottom={0}
        top={0}
        zIndex={1}
      />
      <Box
        alignItems="center"
        justifyContent="center"
        position="absolute"
        left={0}
        right={0}
        bottom={0}
        top={0}
        zIndex={2}>
        {positive ? (
          <Icons.ArrowUp color="secondary.500" size="sm" />
        ) : (
          <Icons.ArrowDropDown color="secondary.500" size="sm" />
        )}
      </Box>
    </Box>
  )
}
