import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'

import { ResultsCalculatorProps } from './ResultsCalculator.types'
import { useResultsCalculator } from './useResultsCalculator'

const ResultsCalculatorDesktop = lazy(() => import('./ResultsCalculator.desktop'))

export const ResultsCalculator: FunctionComponent<ResultsCalculatorProps> = (props) => {
  const resultsCalculator = useResultsCalculator(props)

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <ResultsCalculatorDesktop {...resultsCalculator} />
    </Suspense>
  )
}
