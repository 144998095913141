import { FullChartLayout as FullChart } from './Charts/FullChartLayout'
import { ResumedChartLayout as ResumedChart } from './Charts/ResumedChartLayout'
import { CreateAccountLayout as Account } from './CreateAccountLayout'
import { ErrorLayout as Error } from './ErrorLayout'
import { ExternalDesktopLayout as ExternalDesktop } from './ExternalDesktopLayout'
import { ExternalLayout as External } from './ExternalLayout'
import { GradientBackgroundLayout as GradientBackground } from './GradientBackgroundLayout'
import { InternalLayout as Internal } from './InternalLayout'
import { InternalLayoutDesktop as InternalDesktop } from './InternalLayoutDesktop'
import { LoginLayout as Login } from './LoginLayout'

export default {
  Account,
  Error,
  External,
  Login,
  GradientBackground,
  Internal,
  InternalDesktop,
  ResumedChart,
  FullChart,
  ExternalDesktop
}
