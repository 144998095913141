import { FunctionComponent, useState } from 'react'

import {
  VStack,
  Heading,
  Text,
  HStack,
  Button,
  Actionsheet,
  useBreakpointValue,
  Modal
} from 'native-base'

type TBottomSheetMethodAuthenticate = {
  isOpen: boolean
  isLoading: boolean
  handleConfirmButtonMethodAuthentication(method: string): void
}

type TFormMethod = {
  setMethodAuthenticateCode(method: string): void
  methodAuthenticateCode?: string
  isLoading: boolean
  handleConfirmButtonMethodAuthentication(methodAuthenticate: string): void
}

const FormMethod = ({
  setMethodAuthenticateCode,
  methodAuthenticateCode,
  isLoading,
  handleConfirmButtonMethodAuthentication
}: TFormMethod) => (
  <VStack p={4} justifyContent="center">
    <VStack alignItems="center">
      <Heading maxW="90%" fontSize="24px" lineHeight="28px" textAlign="center" color="gray.700">
        Seu dispositivo requer autenticação
      </Heading>
      <Text textAlign="center" mt={4} fontSize="12px" lineHeight="16px" color="gray.500">
        Enviaremos um código de 6 dígitos, por onde prefere recebê-lo?
      </Text>
    </VStack>
    <HStack maxW="full" justifyContent="space-between" mt={6}>
      <Button
        flexGrow="1"
        mr="8px"
        testID="login-authenticate-email"
        colorScheme="gray"
        variant={methodAuthenticateCode !== 'email' ? 'outline' : 'solid'}
        onPress={() => setMethodAuthenticateCode('email')}>
        E-mail
      </Button>

      <Button
        flexGrow="1"
        ml="8px"
        testID="login-authenticate-sms"
        onPress={() => setMethodAuthenticateCode('sms')}
        variant={methodAuthenticateCode !== 'sms' ? 'outline' : 'solid'}
        colorScheme="gray">
        SMS
      </Button>
    </HStack>
    <HStack mt={4} w="full">
      <Button
        w="full"
        colorScheme="gray"
        testID="login-authenticate-confirm"
        isLoading={isLoading}
        isDisabled={!methodAuthenticateCode}
        onPress={() => {
          if (methodAuthenticateCode) {
            handleConfirmButtonMethodAuthentication(methodAuthenticateCode)
          }
        }}>
        Confirmar
      </Button>
    </HStack>
  </VStack>
)

export const BottomSheetMethodAuthenticate: FunctionComponent<TBottomSheetMethodAuthenticate> = ({
  handleConfirmButtonMethodAuthentication,
  isOpen,
  isLoading
}) => {
  const [methodAuthenticateCode, setMethodAuthenticateCode] = useState<string | undefined>(
    undefined
  )

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  return isDesktop ? (
    <Modal isOpen={isOpen}>
      <Modal.Content minWidth="640px" p={8}>
        <Modal.Body>
          <FormMethod
            {...{
              setMethodAuthenticateCode,
              methodAuthenticateCode,
              isLoading,
              handleConfirmButtonMethodAuthentication
            }}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  ) : (
    <Actionsheet hideDragIndicator isOpen={isOpen} onClose={() => {}}>
      <Actionsheet.Content>
        <FormMethod
          {...{
            setMethodAuthenticateCode,
            methodAuthenticateCode,
            isLoading,
            handleConfirmButtonMethodAuthentication
          }}
        />
      </Actionsheet.Content>
    </Actionsheet>
  )
}
