import { useCallback, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { DateTimePickerAndroid } from '@react-native-community/datetimepicker'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { Store } from 'integration/resources/store'
import { DIRECTORY_TYPE, EXTENSION_TYPE, useUploadMutation } from 'integration/resources/upload'
import { Contact, useCreateContactMutation } from 'integration/resources/wallet'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard, Platform } from 'react-native'
import fieldsValidation from 'src/constants/fields-validation'
import { useCamera } from 'src/hooks/useCamera'
import { isBase64File } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'
import * as Yup from 'yup'

import { UseContactCreateScreen } from './ContactCreateScreen.types'

const schema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/, 'Apenas letras ')
    .max(80, 'Nome deve ter no máximo 80 caracteres')
    .required(fieldsValidation.common.required),
  jobTitle: Yup.string().required(fieldsValidation.common.required),
  email: Yup.string()
    .required(fieldsValidation.common.required)
    .email('Informe um email válido')
    .max(80, 'Email deve ter no máximo 80 caracteres'),
  birth_date: Yup.string(),
  tab_number: Yup.string().required(fieldsValidation.common.required),
  phone_number: Yup.string().required(fieldsValidation.common.required),
  store_name: Yup.string().required(fieldsValidation.common.required)
})

export const useContactCreateScreen: UseContactCreateScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const toast = useToast()

  const closeRightSheet = useCallback(() => {
    navigation.goBack()
  }, [navigation])

  const [openAddedJob, setOpenAddedJob] = useState(false)

  const bottomSheetContext = useBottomSheetContext()

  const { isLoading, mutate } = useCreateContactMutation()

  const { mutateAsync: uploadMutationAsync, isLoading: isUploadingMutationLoading } =
    useUploadMutation()

  const { control, handleSubmit, setValue, reset } = useForm<Contact>({
    resolver: yupResolver(schema)
  })

  const handleGoToWallet = useCallback(
    () =>
      navigation.navigate('Tab', {
        screen: 'Wallet',
        params: {
          tabId: 1
        }
      }),
    [navigation]
  )

  const submit = handleSubmit(
    useCallback<SubmitHandler<Contact>>(
      async (formData) => {
        Keyboard.dismiss()

        const { birth_date, phone_number, jobTitle, tab_number = '0', name, email, id } = formData

        const dateFormatted = birth_date ? new Date(birth_date) : ''

        const cleanPhone = phone_number?.replace(/\D/g, '')

        const formattedFields = {
          id,
          name,
          email,
          birth_date: dateFormatted,
          phone_number_ddd: cleanPhone?.substring(0, 2),
          phone_number: cleanPhone?.substring(2),
          job_title: jobTitle?.toUpperCase(),
          store_tab_number: parseInt(tab_number, 10),
          tab_number,
          is_favorite: formData.is_favorite
        } as Contact

        if (
          formData?.profile_image_url &&
          (formData?.profile_image_url.includes('file://') ||
            isBase64File(formData?.profile_image_url ?? ''))
        ) {
          const response = await fetch(formData.profile_image_url)

          const file = await response.blob()

          if (file.type && file.type.split('/').length > 0) {
            const type = file.type.split('/')[1]

            const files = await uploadMutationAsync({
              directory_type: DIRECTORY_TYPE.CONTACT_COVER_IMAGE,
              extension_type: type.toUpperCase() as keyof typeof EXTENSION_TYPE,
              files: [file]
            })

            if (files.length) {
              if (files[0].status !== 200) {
                bottomSheetContext.open({
                  description: 'Não foi possível fazer upload da imagem, tente novamente',
                  title: 'Erro ao tentar submeter a imagem'
                })

                return
              }

              formattedFields.profile_image_file_name = files[0].name
            }
          }
        }

        mutate(formattedFields, {
          onError: (res) => {
            bottomSheetContext.open({
              description: 'Não foi possível criar o contato, tente novamente',
              title: 'Erro ao tentar salvar dados do usuário'
            })

            toast.show({
              render: () => <Toast type="error" text="Não foi possível completar a ação" />,
              duration: 3000
            })
          },
          onSuccess: ({ data: { data } }) => {
            reset()

            LogEvent(`carteira_novo_contato_sucesso`, {
              item_id: data.id
            })

            toast.show({
              render: () => <Toast type="success" text="Contato criado com sucesso " />,
              duration: 3000
            })

            if (!isMobile) return closeRightSheet()

            handleGoToWallet()
          }
        })
      },
      [
        mutate,
        uploadMutationAsync,
        bottomSheetContext,
        reset,
        toast,
        isMobile,
        closeRightSheet,
        handleGoToWallet
      ]
    )
  )

  const handleSetOpenAddedJob = () => setOpenAddedJob(!openAddedJob)

  const handleAddedJob = () => handleSetOpenAddedJob()

  const handleAddedTabNumber = ({ tab_number, name_fantasy }: Store) => {
    if (tab_number) setValue('tab_number', tab_number)

    setValue('store_name', name_fantasy)
  }

  const [showDate, setShowDate] = useState(Platform.OS !== 'android')

  const [date, setDate] = useState(new Date())

  const handleAddedBirthDate = (_: any, selectedDate: any) => {
    const currentDate = selectedDate

    if (Platform.OS !== 'android') setShowDate(true)

    setValue('birth_date', currentDate)

    setDate(currentDate)
  }

  const showMode = (currentMode: any) => {
    if (Platform.OS === 'android') {
      setShowDate(false)

      DateTimePickerAndroid.open({
        value: new Date(),
        onChange: handleAddedBirthDate,
        mode: currentMode || 'date',
        is24Hour: true
      })

      return
    }

    setShowDate(true)
  }

  const showDatePicker = () => {
    showMode('date')
  }

  const { handleChooseImage, handleTakePicture } = useCamera()

  const [showOptions, setShowOptions] = useState(false)

  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false)

  const handleShowOptionsCamera = () => setShowOptions(true)

  const handleCloseOptionsCamera = () => setShowOptions(false)

  const handleSelectPicture = useCallback(async () => {
    setIsLoadingPhoto(true)

    await handleChooseImage((photoSelected) => {
      if (photoSelected) {
        setValue('profile_image_url', photoSelected)
      }

      setTimeout(() => {
        setShowOptions(false)

        setIsLoadingPhoto(false)
      }, 300)
    })
  }, [handleChooseImage, setValue])

  const handelTakePicture = useCallback(async () => {
    setIsLoadingPhoto(true)

    await handleTakePicture((photoSelected) => {
      if (photoSelected) {
        setValue('profile_image_url', photoSelected)
      }

      setTimeout(() => {
        setShowOptions(false)

        setIsLoadingPhoto(false)
      }, 300)
    })
  }, [handleTakePicture, setValue])

  const handleRemovePicture = useCallback(async () => {
    setIsLoadingPhoto(true)

    setValue('profile_image_url', null)

    setTimeout(() => {
      setShowOptions(false)

      setIsLoadingPhoto(false)
    }, 300)
  }, [setValue])

  return {
    closeRightSheet,
    isMobile,
    isLoading: isLoadingPhoto || isLoading || isUploadingMutationLoading,
    control,
    submit,
    handleAddedJob,
    openAddedJob,
    handleSetOpenAddedJob,
    handleAddedTabNumber,
    handleShowOptionsCamera,
    handleCloseOptionsCamera,
    handleSelectPicture,
    handelTakePicture,
    handleRemovePicture,
    handleAddedBirthDate,
    showDatePicker,
    showDate,
    date,
    isLoadingPhoto,
    showOptions
  }
}
