import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { TabScreenComponent } from './TabScreen.types'
import { useTabScreen } from './useTabScreen'

const TabScreenDesktop = lazy(() => import('./TabScreen.desktop'))

const TabScreenMobile = lazy(() => import('./TabScreen.mobile'))

export const TabScreen: TabScreenComponent = ({ navigation, route }) => {
  const tabScreen = useTabScreen({ navigation, route })

  const Screen = tabScreen.isMobile ? TabScreenMobile : TabScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...tabScreen} />
    </Suspense>
  )
}
