import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { TabLocationScreenComponent } from './TabLocationScreen.types'
import { useTabLocationScreen } from './useTabLocationScreen'

const TabLocationScreenDesktop = lazy(() => import('./TabLocationScreen.desktop'))

const TabLocationScreenMobile = lazy(() => import('./TabLocationScreen.mobile'))

export const TabLocationScreen: TabLocationScreenComponent = ({ route }) => {
  const tabLocationScreen = useTabLocationScreen({ route })

  const Screen = tabLocationScreen.isMobile ? TabLocationScreenMobile : TabLocationScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...tabLocationScreen} />
    </Suspense>
  )
}
