import { useCallback, useMemo } from 'react'

import { Note, useGetNoteListInfiniteQuery } from 'integration/resources/wallet'

import { UseTabComponent } from './types'

const notesMergeDataInfiniteQuery = (
  data: ReturnType<typeof useGetNoteListInfiniteQuery>['data']
) =>
  data?.pages.reduce<Note[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const useTabComponent: UseTabComponent = ({ contactId }) => {
  const {
    data: allNotesData,
    isFetchingNextPage: allNotesIsFetchingNextPage,
    hasNextPage: allNotesHasNextPage,
    fetchNextPage: allNotesFetchNextPage,
    isLoading
  } = useGetNoteListInfiniteQuery({
    id: contactId
  })

  const allNotes = useMemo(() => notesMergeDataInfiniteQuery(allNotesData), [allNotesData])

  const onScrollEndDragAllNotesList = useCallback(() => {
    if (!allNotesIsFetchingNextPage && allNotesHasNextPage) {
      allNotesFetchNextPage()
    }
  }, [allNotesIsFetchingNextPage, allNotesHasNextPage, allNotesFetchNextPage])

  return { allNotes, onScrollEndDragAllNotesList, allNotesIsFetchingNextPage, isLoading }
}
