import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import {
  CALCULATOR_TYPE_ENUM,
  TCalculatorData,
  TCalculatorDataSerialized,
  TRevenueCalculator,
  TRevenueCalculatorSerialized
} from './types'
import { ResponseError, ResponseSuccess } from '../../types'
import { KEY_GET_RV_SUMMARY_QUERY } from '../variable-revenue'

export const KEY_GET_REVENUE_ITEM_QUERY = 'useGetRevenueData'

export const KEY_GET_CALCULATOR_ITEM_QUERY = 'useGetCalculatorData'

export const useGetRevenueData = (enabled?: boolean) =>
  useQuery<AxiosResponse<ResponseSuccess<TRevenueCalculatorSerialized>>, AxiosError<ResponseError>>(
    [KEY_GET_REVENUE_ITEM_QUERY],
    () => requests.getRevenueSimulationData(),
    { enabled: enabled ?? true }
  )

export const useSendRevenueSimulationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<TRevenueCalculator>>,
    AxiosError<ResponseError>,
    TRevenueCalculator
  >(requests.sendRevenueSimulation, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_GET_REVENUE_ITEM_QUERY])

      queryClient.invalidateQueries([KEY_GET_RV_SUMMARY_QUERY])
    }
  })
}

export const useResetRevenueSimulationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError<ResponseError>, string>(
    requests.resetRevenueSimulation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_GET_REVENUE_ITEM_QUERY])

        queryClient.invalidateQueries([KEY_GET_RV_SUMMARY_QUERY])
      }
    }
  )
}

// Other calculators

export const useGetCalculatorData = (calculatorType: CALCULATOR_TYPE_ENUM, enabled?: boolean) =>
  useQuery<AxiosResponse<ResponseSuccess<TCalculatorDataSerialized>>, AxiosError<ResponseError>>(
    [`${KEY_GET_CALCULATOR_ITEM_QUERY}.${calculatorType}`],
    () => requests.getCalculatorData(calculatorType),
    { enabled: enabled ?? true }
  )

export const useSendCalculatorSimulationMutation = (calculatorType: CALCULATOR_TYPE_ENUM) => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<TCalculatorData>>,
    AxiosError<ResponseError>,
    TCalculatorData
  >(requests.sendCalculatorSimulation, {
    onSuccess: () => {
      queryClient.invalidateQueries([`${KEY_GET_CALCULATOR_ITEM_QUERY}.${calculatorType}`])
    }
  })
}

export const useResetCalculatorSimulationMutation = (calculatorType: CALCULATOR_TYPE_ENUM) => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<CALCULATOR_TYPE_ENUM>>,
    AxiosError<ResponseError>,
    CALCULATOR_TYPE_ENUM
  >(requests.resetCalculatorSimulation, {
    onSuccess: () => {
      queryClient.invalidateQueries([`${KEY_GET_CALCULATOR_ITEM_QUERY}.${calculatorType}`])
    }
  })
}
