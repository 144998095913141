import { forwardRef, useImperativeHandle, useState } from 'react'

import { Cards, Illustrations, LoadingTurbo } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { LinearGradient } from 'expo-linear-gradient'
import {
  Center,
  Box,
  VStack,
  useTheme,
  ZStack,
  Text,
  View,
  HStack,
  Heading,
  useBreakpointValue
} from 'native-base'
import { useWindowDimensions } from 'react-native'
import { formatDateStringToMask } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

import { useListActivitiesConcluded } from './hook'
import { ListTypesRef, ListTypesView } from '../../OrganizationScreen.types'

export const ListActivitiesConcluded = forwardRef<ListTypesRef, ListTypesView>(
  ({ navigation, isLoading }, ref) => {
    const [search, setSearch] = useState('')

    const isMobile = useBreakpointValue({ base: true, lg: false })

    const {
      list,
      handleComplete,
      isFetchingNextPage,
      isLoading: localIsLoading,
      refreshing,
      fetchMore,
      refetch,
      totalActivities
    } = useListActivitiesConcluded({
      search
    })

    const skeletonArray = new Array(6).fill(0)

    useImperativeHandle(ref, () => ({
      refreshing,
      fetchMore,
      refetch,
      search: (value: string) => {
        setSearch(value)
      },
      clearSearch: () => setSearch('')
    }))

    const { width, height } = useWindowDimensions()

    const theme = useTheme()

    return (
      <Box px={4} mt={10} position="relative">
        {(localIsLoading && !list?.items.length) ||
          (isLoading && (
            <VStack py={4}>
              {skeletonArray.map((_, i) => (
                <SkeletonCard key={`key-skeleton-${i}`} />
              ))}
            </VStack>
          ))}

        <HStack justifyContent="space-between" mb={4}>
          {!isLoading && !localIsLoading && !!list?.items.length && (
            <Heading fontWeight="bold" lineHeight={{ base: '28px', lg: '38px' }} fontSize="24px">
              {isMobile ? 'Concluídas' : 'Tarefas Concluídas'}
            </Heading>
          )}

          {!isLoading && !localIsLoading && !!list?.items.length && (
            <View>
              <Box
                flexDir="row"
                bgColor={{ base: 'tertiary.800', lg: undefined }}
                px={3}
                py={1}
                borderRadius={20}>
                {isMobile ? (
                  <Heading color="white" fontSize={12} fontWeight="bold" fontFamily="heading">
                    {totalActivities} {`Tarefa${Number(totalActivities) > 1 ? 's' : ''}`}
                  </Heading>
                ) : (
                  <Text color="gray.400" fontSize="16px" fontWeight="normal">
                    {totalActivities} {`Tarefa${Number(totalActivities) > 1 ? 's' : ''}`}
                  </Text>
                )}
              </Box>
            </View>
          )}
        </HStack>

        {!list?.items.length && !localIsLoading && (
          <Box mt={-6} alignItems="center" justifyContent="center">
            <Illustrations.ActivitiesEmpty />
            <Text fontSize="md" lineHeight="24px" textAlign="center" color="gray.500">
              Ainda não há tarefas concluídas na sua organização.
            </Text>
          </Box>
        )}

        {!isLoading &&
          list?.items.map((activity, i) => (
            <Box mb={2} key={`key-${i}-${activity.title}`}>
              <Cards.OrganizationCard
                expandedDetail
                isLoadingFavorite={isLoading}
                cover={activity.store?.logo_image?.url}
                topText={activity?.title}
                organizationType={activity?.activity_type?.name}
                nameFantasy={activity?.store?.name_fantasy}
                tabNumber={activity?.store?.tab_number}
                midText={
                  activity?.store?.tab_number
                    ? `TAB ${activity?.store?.tab_number}`
                    : 'Minha tarefa'
                }
                bottomText={
                  activity?.period_infos?.schedule_date_start &&
                  activity?.period_infos?.schedule_date_end
                    ? `${formatDateStringToMask(
                        activity?.period_infos?.schedule_date_start,
                        'dd/MM/yyyy'
                      )} até ${formatDateStringToMask(
                        activity?.period_infos?.schedule_date_end,
                        'dd/MM/yyyy'
                      )}`
                    : activity.schedule_date
                    ? formatDateStringToMask(activity?.schedule_date, 'dd/MM/yyyy')
                    : undefined
                }
                hour={formatDateStringToMask(activity?.schedule_date, 'HH:mm')}
                attachments={activity?.count_activity_attachments}
                planActions={activity?.activity_plan_action}
                userName={activity?.user_name}
                active={activity?.is_finished}
                icon="Check"
                onPress={() => {
                  LogEvent('atividade', {
                    item_id: activity?.id
                  })

                  navigation.navigate('OrganizationActivity', {
                    id: String(activity?.id)
                  })
                }}
                onCheckPress={(callback, currentCheck) =>
                  handleComplete(callback, currentCheck, activity.id)
                }
                period_infos={activity?.period_infos}
              />
            </Box>
          ))}

        {isFetchingNextPage && (
          <Center mt={6}>
            <LoadingTurbo size={48} />
          </Center>
        )}

        {refreshing && !isLoading && (
          <>
            <LinearGradient
              style={{
                position: 'absolute',
                height: 800,
                zIndex: 4,
                flex: 1,
                width
              }}
              colors={['#ffffff05', theme.colors.background.light, '#ffffff05']}
            />
            <ZStack
              position="absolute"
              w={width}
              h={0}
              alignItems="center"
              justifyContent="center"
              top={height / 5}
              zIndex={8}>
              <Center>
                <LoadingTurbo size={48} />
              </Center>
            </ZStack>
          </>
        )}
      </Box>
    )
  }
)
