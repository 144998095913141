import { memo } from 'react'

import Svg, { Path, Defs, LinearGradient, Stop } from 'react-native-svg'

const FlameSantanderTurbo = () => (
  <Svg width="124" height="137" viewBox="0 0 124 137" fill="none">
    <Path
      d="M93.3394 4.13477C86.1297 15.7505 87.286 29.6932 94.4944 41.3089L117.07 78.7373C121.615 85.4705 121.979 91.8618 122.091 95.0859M68.5167 44.8867L71.0236 40.0903C63.84 51.6669 63.8177 65.9202 70.9518 77.5152L70.9506 77.5163L93.6424 114.225C98.2781 121.324 98.7432 125.59 99.1308 127.995M50.3029 71.4856C19.1283 79.0632 0.620117 98.0185 0.620117 116.65C0.620117 142.568 46.7042 169.248 98.0681 169.248"
      stroke="#595959"
      strokeWidth={0.953461}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.55 74.569C140.28 68.803 138.546 63.0829 135.335 57.9124L107.017 12.3017C104.857 8.82261 103.366 5.09616 102.536 1.27344L101.354 3.17976C94.3433 14.4688 94.3433 28.3773 101.353 39.6663L124.008 76.1551C131.017 87.4453 131.017 101.355 124.008 112.644L122.824 114.55C121.996 110.728 120.504 107.001 118.344 103.523L84.3617 48.7894C82.2034 45.3114 80.7107 41.5839 79.8825 37.7622L78.6992 39.6686C71.7138 50.9196 71.6921 64.772 78.6294 76.041L78.6282 76.0421L101.354 112.644C108.362 123.933 108.362 137.843 101.354 149.133L100.17 151.039C99.3413 147.216 97.8499 143.49 95.6903 140.011L67.3718 94.4001C63.5739 88.2826 61.8381 81.3961 62.1559 74.5746C31.8415 81.939 10.9164 97.5781 10.9164 115.685C10.9164 140.875 51.4075 161.295 101.354 161.295C151.299 161.295 191.789 140.875 191.789 115.685C191.789 97.5769 170.867 81.9346 140.55 74.569Z"
      fill="#553D41"
      stroke="url(#paint0_linear_12721_47188)"
      strokeWidth={0.953461}
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_12721_47188"
        x1="78.4423"
        y1="-16.8571"
        x2="113.735"
        y2="103.425"
        gradientUnits="userSpaceOnUse">
        <Stop stopColor="#F3123C" />
        <Stop offset={1} stopColor="#CC0000" />
        <Stop offset={1} stopColor="#FF393C" />
      </LinearGradient>
    </Defs>
  </Svg>
)

export const FlameSantanderTurboIllustration = memo(FlameSantanderTurbo)
