import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ConcludedIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Path
      d="M8.86496 17.7375C3.97498 17.7375 0 13.755 0 8.865C0 3.975 3.97498 0 8.86496 0C11.025 0 13.1099 0.7875 14.7299 2.2125C15.0374 2.49 15.0674 2.9625 14.7974 3.27C14.5274 3.5775 14.0474 3.615 13.7399 3.3375C12.3974 2.1525 10.665 1.5 8.87246 1.5C4.80748 1.5 1.49999 4.8075 1.49999 8.865C1.49999 12.9225 4.80748 16.23 8.86496 16.23C12.2549 16.23 15.1949 13.935 16.0124 10.6425C16.1099 10.2375 16.5224 9.9975 16.9199 10.095C17.3249 10.1925 17.5649 10.605 17.4674 11.0025C16.4849 14.9625 12.9449 17.7225 8.86496 17.7225V17.7375Z"
      fill="currentColor"
    />
    <Path
      d="M9.42746 3.4875V4.3875H10.4775C10.89 4.3875 11.2275 4.725 11.2275 5.1375C11.2275 5.55 10.89 5.8875 10.4775 5.8875H8.67746C8.39997 5.8875 8.12997 6 7.93497 6.195C7.73997 6.39 7.62747 6.66 7.62747 6.9375C7.62747 7.215 7.73997 7.485 7.93497 7.68C8.12997 7.875 8.39997 7.9875 8.67746 7.9875C9.35246 7.9875 10.005 8.2575 10.4775 8.7375C10.9575 9.2175 11.2275 9.8625 11.2275 10.5375C11.2275 11.2125 10.9575 11.865 10.4775 12.3375C10.1775 12.6375 9.81746 12.8475 9.42746 12.975V13.2375C9.42746 13.65 9.08996 13.9875 8.67746 13.9875C8.26497 13.9875 7.92747 13.65 7.92747 13.2375V13.0875H6.87747C6.46497 13.0875 6.12747 12.75 6.12747 12.3375C6.12747 11.925 6.46497 11.5875 6.87747 11.5875H8.67746C8.95496 11.5875 9.22496 11.475 9.41996 11.28C9.61496 11.085 9.72746 10.815 9.72746 10.5375C9.72746 10.26 9.61496 9.99 9.41996 9.795C9.22496 9.6 8.95496 9.4875 8.67746 9.4875C8.00247 9.4875 7.34997 9.2175 6.87747 8.7375C6.39747 8.2575 6.12747 7.6125 6.12747 6.9375C6.12747 6.2625 6.39747 5.61 6.87747 5.1375C7.17747 4.8375 7.53747 4.6275 7.92747 4.5V3.4875C7.92747 3.075 8.26497 2.7375 8.67746 2.7375C9.08996 2.7375 9.42746 3.075 9.42746 3.4875Z"
      fill="currentColor"
    />
    <Path
      d="M13.9649 9.945C13.7699 9.945 13.5749 9.87 13.4324 9.7275L11.835 8.13C11.5425 7.8375 11.5425 7.365 11.835 7.0725C12.1274 6.78 12.5999 6.78 12.8924 7.0725L13.8224 8.0025L15.9824 4.545C16.1999 4.1925 16.6649 4.0875 17.0174 4.305C17.3699 4.5225 17.4749 4.9875 17.2574 5.34L14.5949 9.6C14.4749 9.795 14.2724 9.9225 14.0474 9.945C14.0174 9.945 13.9874 9.945 13.9649 9.945Z"
      fill="currentColor"
    />
  </Icon>
)
