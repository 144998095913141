import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'
import { useBreakpointValue } from 'native-base'

import { ActivityFormTemplateProps } from './types'

const ComponentDesktop = lazy(() => import('./ActivityFormTemplate.desktop'))

const ComponentMobile = lazy(() => import('./ActivityFormTemplate.mobile'))

export const ActivityFormTemplate: FunctionComponent<ActivityFormTemplateProps> = ({
  watch,
  control,
  handleAddItemActionPlan,
  handleUpdateItemActionPlan,
  handleRemoveItemActionPlan,
  setValue,
  isEdit = false
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const Component = isMobile ? ComponentMobile : ComponentDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Component
        {...{
          watch,
          control,
          handleAddItemActionPlan,
          handleUpdateItemActionPlan,
          handleRemoveItemActionPlan,
          setValue,
          isEdit
        }}
      />
    </Suspense>
  )
}
