import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { DashboardScreenComponent } from './DashboardScreen.types'
import { useDashboardScreen } from './useDashboardScreen'

const DashboardScreenDesktop = lazy(() => import('./DashboardScreen.desktop'))

const DashboardScreenMobile = lazy(() => import('./DashboardScreen.mobile'))

export const DashboardScreen: DashboardScreenComponent = ({ navigation, route }) => {
  const props = useDashboardScreen({ navigation, route })

  const Screen = props.isMobile ? DashboardScreenMobile : DashboardScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
