import { AcceleratorsEmpty } from './AcceleratorsEmptyIllustration'
import { ActivitiesEmpty } from './ActivitiesEmptyIllustration'
import { Birthday } from './BirthdayIllustration'
import { ChampionIllustration as Champion } from './ChampionIllustration'
import { ChasingMoneyIllustration as ChasingMoney } from './ChasingMoneyIllustration'
import { ContactEmptyIllustration as ContactEmpty } from './ContactEmptyIllustration'
import { ContactIllustration as Contact } from './ContactIllustration'
import { ContentEmpty } from './ContentEmptyIllustration'
import { ContentEmptyState } from './ContentEmptyStateIllustration'
import { DataOrganizationEmpty } from './DataOrganizationEmptyIllustration'
import { DeleteItem } from './DeleteItem'
import { Done } from './DoneIllustrantion'
import { EmailConfirmed } from './EmailConfirmedIllustration'
import { EmailNotConfirmed } from './EmailNotConfirmedIllustration'
import { EmailResend } from './EmailResend'
import { EmailSent } from './EmailSent'
import { EvaluationSuccess } from './EvaluationSuccessllustration'
import { Fallback } from './FallbackIllustration'
import { Farewell } from './FarewellIllustration'
import { FavoriteContactsEmpty } from './FavoriteContactsEmptyIllustration'
import { FirstLoginSuccesfullyIllustration as FirstLoginSuccesfully } from './FirstLoginSuccesfullyIllustration'
import { FlameSantanderTurboIllustration as FlameSantanderTurbo } from './FlameSantanderTurboIllustration'
import { GetJobPromotionIllustration as GetJobPromotion } from './GetJobPromotionIllustration'
import { GoodByeIcon as GoodBye } from './GoodByeIcon'
import { LaboritIllustration as Laborit } from './LaboritIllustration'
import { LoginAccountReviewIllustration as LoginAccountReview } from './LoginAccountReviewIllustration'
import { LoginBlocked } from './LoginBlockedIllustration'
import { LogoTabBar } from './LogoTabBarIllustration'
import { NewUpdateIllustration as NewUpdate } from './NewUpdateIllustration'
import { NoNotifications } from './NoNotificationsIllustration'
import { OnlineLearningIllustration as OnlineLearning } from './OnlineLearningIllustration'
import { PageErrorOccurred } from './PageErrorOccurredIllustration'
import { PageNoConnection } from './PageNoConnectionIllustration'
import { PageNotFound } from './PageNotFoundIllustration'
import { RecoveryPasswordSuccessfullyIllustration as RecoveryPasswordSuccesfully } from './RecoveryPasswordSuccesfullyIllustration'
import { RefuseTerms } from './RefuseTermsIllustration'
import { RelatedOpportunitiesEmpty } from './RelatedOpportunitiesEmptyIllustration'
import { ReSentCode } from './ReSentCodeIllustration'
import { ReSentEmail } from './ReSentEmailIllustration'
import { Search } from './SearchIllustration'
import { SearchNotFound } from './SearchNotFoundIllustration'
import { SocialMediaIllustration as SocialMedia } from './SocialMediaIllustration'
import { SuccessfulBusinessIllustration as SuccessfulBusiness } from './SuccessfulBusinessIllustration'
import { TimeInForWork } from './TimeInForWorkIllustration'
import { Trash } from './TrashIllustration'
import { TurboIllustration as Turbo } from './TurboIllustration'
import { VirusThreatFound } from './VirusThreatFoundIllustration'
import { Work } from './WorkIllustration'
import { WorkLifeBalanceIllustration as WorkLifeBalance } from './WorkLifeBalanceIllustration'

export default {
  AcceleratorsEmpty,
  ActivitiesEmpty,
  Birthday,
  Champion,
  ChasingMoney,
  ContactEmpty,
  ContentEmpty,
  ContentEmptyState,
  DataOrganizationEmpty,
  Done,
  DeleteItem,
  Search,
  SearchNotFound,
  EmailConfirmed,
  EmailNotConfirmed,
  EmailSent,
  EvaluationSuccess,
  RecoveryPasswordSuccesfully,
  ReSentEmail,
  SuccessfulBusiness,
  Farewell,
  Fallback,
  FavoriteContactsEmpty,
  GetJobPromotion,
  GoodBye,
  Contact,
  WorkLifeBalance,
  OnlineLearning,
  PageErrorOccurred,
  PageNoConnection,
  PageNotFound,
  LoginBlocked,
  EmailResend,
  FirstLoginSuccesfully,
  Turbo,
  Trash,
  Laborit,
  LogoTabBar,
  LoginAccountReview,
  Work,
  SocialMedia,
  RefuseTerms,
  RelatedOpportunitiesEmpty,
  ReSentCode,
  NoNotifications,
  TimeInForWork,
  FlameSantanderTurbo,
  NewUpdate,
  VirusThreatFound
}
