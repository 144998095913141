import { FunctionComponent } from 'react'

import { Path, Svg } from 'react-native-svg'
import { resize } from 'src/utils'

type PPortalMaisTurboIconProps = {
  size?: number
}

export const PortalMaisTurboIcon: FunctionComponent<PPortalMaisTurboIconProps> = ({
  size = 116
}) => {
  const { height, width } = resize({ height: 12, size, width: 116 })

  return (
    <Svg {...{ height, width }}>
      <Path
        d="M1.76281 3.45477C2.54536 3.45477 3.11637 3.61516 3.47584 3.93592C3.83532 4.25668 4.01506 4.70602 4.01506 5.28395C4.01506 5.54664 3.97358 5.79689 3.89062 6.0347C3.81043 6.26974 3.6777 6.47851 3.49244 6.66101C3.30717 6.84075 3.06245 6.98316 2.75828 7.08823C2.45687 7.19331 2.08495 7.24585 1.64252 7.24585H0.995469V9.51884H0V3.45477H1.76281ZM1.69644 4.28018H0.995469V6.41629H1.53883C1.8513 6.41629 2.11675 6.38035 2.3352 6.30845C2.55365 6.23379 2.71957 6.11627 2.83294 5.95589C2.94631 5.79551 3.003 5.58397 3.003 5.32128C3.003 4.9701 2.89654 4.70879 2.68362 4.53735C2.47346 4.3659 2.14441 4.28018 1.69644 4.28018Z"
        fill="white"
      />
      <Path
        d="M14.8574 6.47851C14.8574 6.94583 14.7979 7.37167 14.679 7.75603C14.5629 8.13763 14.3873 8.46668 14.1522 8.7432C13.9172 9.01696 13.6227 9.22849 13.2688 9.37781C12.9148 9.52713 12.5 9.60179 12.0244 9.60179C11.5405 9.60179 11.1202 9.52713 10.7635 9.37781C10.4096 9.22849 10.1151 9.01557 9.88002 8.73905C9.64775 8.46253 9.47354 8.13209 9.3574 7.74773C9.24127 7.36337 9.1832 6.93753 9.1832 6.47021C9.1832 5.84528 9.28551 5.30054 9.49013 4.83599C9.69752 4.36867 10.0114 4.00643 10.4317 3.74927C10.8548 3.48934 11.3884 3.35938 12.0327 3.35938C12.6632 3.35938 13.1872 3.48796 13.6047 3.74512C14.0223 4.00228 14.3347 4.36452 14.5421 4.83184C14.7523 5.29639 14.8574 5.84528 14.8574 6.47851ZM10.2284 6.47851C10.2284 6.94583 10.292 7.35093 10.4192 7.69381C10.5464 8.03393 10.7414 8.29662 11.0041 8.48189C11.2695 8.66439 11.6097 8.75564 12.0244 8.75564C12.442 8.75564 12.7821 8.66439 13.0448 8.48189C13.3075 8.29662 13.501 8.03393 13.6255 7.69381C13.7499 7.35093 13.8121 6.94583 13.8121 6.47851C13.8121 5.76786 13.6697 5.21205 13.3849 4.8111C13.1029 4.40738 12.6521 4.20552 12.0327 4.20552C11.6179 4.20552 11.2764 4.29677 11.0082 4.47928C10.7428 4.66178 10.5464 4.92309 10.4192 5.26321C10.292 5.60056 10.2284 6.00566 10.2284 6.47851Z"
        fill="white"
      />
      <Path
        d="M22.1118 3.45477C22.6345 3.45477 23.0658 3.51837 23.406 3.64557C23.7488 3.77277 24.0032 3.96633 24.1691 4.22626C24.3378 4.48619 24.4222 4.81663 24.4222 5.21758C24.4222 5.51622 24.3669 5.77062 24.2563 5.98078C24.1456 6.19093 24.0005 6.36514 23.8207 6.5034C23.641 6.64166 23.4488 6.75226 23.2442 6.83522L24.9655 9.51884H23.8415L22.3773 7.08823H21.4026V9.51884H20.4071V3.45477H22.1118ZM22.0455 4.28433H21.4026V6.26697H22.0911C22.5529 6.26697 22.8875 6.18125 23.0949 6.00981C23.305 5.83837 23.4101 5.58535 23.4101 5.25077C23.4101 4.89959 23.2981 4.65072 23.0741 4.50416C22.8529 4.35761 22.51 4.28433 22.0455 4.28433Z"
        fill="white"
      />
      <Path
        d="M32.1827 9.51884H31.1831V4.30092H29.4078V3.45477H33.9538V4.30092H32.1827V9.51884Z"
        fill="white"
      />
      <Path
        d="M42.8881 9.51884L42.295 7.83484H39.9764L39.3832 9.51884H38.3338L40.5902 3.42989H41.6935L43.9458 9.51884H42.8881ZM42.0378 6.98039L41.4613 5.32128C41.4391 5.24938 41.4073 5.14845 41.3659 5.01849C41.3244 4.88576 41.2829 4.75165 41.2414 4.61615C41.2 4.47789 41.1654 4.36176 41.1377 4.26774C41.1101 4.38111 41.0755 4.50831 41.034 4.64934C40.9953 4.7876 40.9566 4.91756 40.9179 5.03923C40.882 5.1609 40.8543 5.25491 40.8349 5.32128L40.2543 6.98039H42.0378Z"
        fill="white"
      />
      <Path d="M48.9936 9.51884V3.45477H49.9891V8.67269H52.5649V9.51884H48.9936Z" fill="white" />
      <Path
        d="M62.123 3.52097C62.123 3.18039 61.8469 2.9043 61.5063 2.9043C61.1658 2.9043 60.8897 3.18039 60.8897 3.52097V5.86519H58.5466C58.206 5.86519 57.9299 6.14128 57.9299 6.48186C57.9299 6.82244 58.206 7.09854 58.5466 7.09854H60.8897V9.44103C60.8897 9.78161 61.1658 10.0577 61.5063 10.0577C61.8469 10.0577 62.123 9.78161 62.123 9.44103V7.09854H64.4667C64.8072 7.09854 65.0833 6.82244 65.0833 6.48186C65.0833 6.14128 64.8072 5.86519 64.4667 5.86519H62.123V3.52097Z"
        fill="#FF393C"
      />
      <Path
        d="M73.2233 9.51884H72.2237V4.30092H70.4484V3.45477H74.9944V4.30092H73.2233V9.51884Z"
        fill="white"
      />
      <Path
        d="M84.9325 3.45477V7.37858C84.9325 7.80165 84.8426 8.18049 84.6629 8.51507C84.4859 8.84966 84.2177 9.11512 83.8582 9.31145C83.4987 9.50501 83.0466 9.60179 82.5019 9.60179C81.7249 9.60179 81.1331 9.39855 80.7266 8.99207C80.3229 8.58282 80.1211 8.03946 80.1211 7.36199V3.45477H81.1165V7.27489C81.1165 7.78092 81.2354 8.15422 81.4732 8.39479C81.711 8.63536 82.0664 8.75564 82.5392 8.75564C82.8655 8.75564 83.131 8.69896 83.3356 8.58559C83.543 8.46945 83.6951 8.30077 83.7919 8.07956C83.8914 7.85558 83.9412 7.58597 83.9412 7.27074V3.45477H84.9325Z"
        fill="white"
      />
      <Path
        d="M92.4358 3.45477C92.9585 3.45477 93.3898 3.51837 93.7299 3.64557C94.0728 3.77277 94.3272 3.96633 94.4931 4.22626C94.6618 4.48619 94.7462 4.81663 94.7462 5.21758C94.7462 5.51622 94.6909 5.77062 94.5802 5.98078C94.4696 6.19093 94.3245 6.36514 94.1447 6.5034C93.965 6.64166 93.7728 6.75226 93.5682 6.83522L95.2895 9.51884H94.1655L92.7013 7.08823H91.7266V9.51884H90.7311V3.45477H92.4358ZM92.3695 4.28433H91.7266V6.26697H92.4151C92.8769 6.26697 93.2115 6.18125 93.4189 6.00981C93.629 5.83837 93.7341 5.58535 93.7341 5.25077C93.7341 4.89959 93.6221 4.65072 93.3981 4.50416C93.1769 4.35761 92.834 4.28433 92.3695 4.28433Z"
        fill="white"
      />
      <Path
        d="M100.404 3.45477H102.208C102.991 3.45477 103.581 3.56815 103.979 3.79489C104.377 4.02164 104.576 4.41291 104.576 4.96872C104.576 5.20099 104.535 5.41115 104.452 5.59918C104.372 5.78445 104.254 5.93792 104.099 6.05958C103.945 6.17849 103.754 6.25868 103.527 6.30016V6.34163C103.762 6.38311 103.971 6.45639 104.153 6.56147C104.339 6.66654 104.484 6.81725 104.589 7.01358C104.697 7.2099 104.751 7.4643 104.751 7.77677C104.751 8.1473 104.662 8.46254 104.485 8.72246C104.311 8.98239 104.061 9.1801 103.734 9.3156C103.411 9.45109 103.027 9.51884 102.581 9.51884H100.404V3.45477ZM101.399 5.95589H102.353C102.804 5.95589 103.116 5.88261 103.291 5.73606C103.465 5.5895 103.552 5.3752 103.552 5.09315C103.552 4.80557 103.448 4.59818 103.241 4.47098C103.036 4.34378 102.71 4.28018 102.262 4.28018H101.399V5.95589ZM101.399 6.76056V8.68513H102.449C102.913 8.68513 103.239 8.59526 103.427 8.41553C103.616 8.23579 103.71 7.99245 103.71 7.68552C103.71 7.49748 103.667 7.33434 103.581 7.19608C103.498 7.05782 103.361 6.95136 103.17 6.8767C102.98 6.79927 102.722 6.76056 102.399 6.76056H101.399Z"
        fill="white"
      />
      <Path
        d="M115.634 6.47851C115.634 6.94583 115.575 7.37167 115.456 7.75603C115.34 8.13763 115.164 8.46668 114.929 8.7432C114.694 9.01696 114.4 9.22849 114.046 9.37781C113.692 9.52713 113.277 9.60179 112.801 9.60179C112.318 9.60179 111.897 9.52713 111.541 9.37781C111.187 9.22849 110.892 9.01557 110.657 8.73905C110.425 8.46253 110.251 8.13209 110.134 7.74773C110.018 7.36337 109.96 6.93753 109.96 6.47021C109.96 5.84528 110.063 5.30054 110.267 4.83599C110.475 4.36867 110.788 4.00643 111.209 3.74927C111.632 3.48934 112.165 3.35938 112.81 3.35938C113.44 3.35938 113.964 3.48796 114.382 3.74512C114.799 4.00228 115.112 4.36452 115.319 4.83184C115.529 5.29639 115.634 5.84528 115.634 6.47851ZM111.005 6.47851C111.005 6.94583 111.069 7.35093 111.196 7.69381C111.323 8.03393 111.518 8.29662 111.781 8.48189C112.047 8.66439 112.387 8.75564 112.801 8.75564C113.219 8.75564 113.559 8.66439 113.822 8.48189C114.085 8.29662 114.278 8.03393 114.403 7.69381C114.527 7.35093 114.589 6.94583 114.589 6.47851C114.589 5.76786 114.447 5.21205 114.162 4.8111C113.88 4.40738 113.429 4.20552 112.81 4.20552C112.395 4.20552 112.053 4.29677 111.785 4.47928C111.52 4.66178 111.323 4.92309 111.196 5.26321C111.069 5.60056 111.005 6.00566 111.005 6.47851Z"
        fill="white"
      />
    </Svg>
  )
}
