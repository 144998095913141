import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { PreferencesProfileScreenComponent } from './PreferencesProfileScreen.types'
import { usePreferencesProfileScreen } from './usePreferencesProfileScreen'

const PreferencesProfileScreenDesktop = lazy(() => import('./PreferencesProfileScreen.desktop'))

const PreferencesProfileScreenMobile = lazy(() => import('./PreferencesProfileScreen.mobile'))

export const PreferencesProfileScreen: PreferencesProfileScreenComponent = ({ navigation }) => {
  const preferencesProfileScreen = usePreferencesProfileScreen({ navigation })

  const Screen = preferencesProfileScreen.isMobile
    ? PreferencesProfileScreenMobile
    : PreferencesProfileScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...preferencesProfileScreen} />
    </Suspense>
  )
}
