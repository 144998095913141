import { FunctionComponent } from 'react'

import { ITheme, useToken } from 'native-base'
import { Leaves } from 'native-base/lib/typescript/theme/base/types'
import { Path, Svg } from 'react-native-svg'
import { resize } from 'src/utils'

type WatchIconProps = {
  color?: Leaves<ITheme['colors']>
  size?: number
}

export const WatchIcon: FunctionComponent<WatchIconProps> = ({ size = 12, color = 'white' }) => {
  const { height, width } = resize({ height: 13, size, width: 12 })

  const [colorPick] = useToken('colors', [color])

  return (
    <Svg {...{ viewBox: '0 0 12 13', width, height }}>
      <Path
        d="M6 3C6.27614 3 6.5 3.22386 6.5 3.5V6.25546L8.55697 7.85532C8.77494 8.02486 8.81421 8.339 8.64468 8.55697C8.47514 8.77494 8.161 8.81421 7.94303 8.64468L5.69303 6.89468C5.57124 6.79995 5.5 6.6543 5.5 6.5V3.5C5.5 3.22386 5.72386 3 6 3Z"
        fill={colorPick}
      />

      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1C9.03757 1 11.5 3.46243 11.5 6.5C11.5 9.53757 9.03757 12 6 12C2.96243 12 0.5 9.53757 0.5 6.5C0.5 3.46243 2.96243 1 6 1ZM6 2C3.51472 2 1.5 4.01472 1.5 6.5C1.5 8.98528 3.51472 11 6 11C8.48528 11 10.5 8.98528 10.5 6.5C10.5 4.01472 8.48528 2 6 2Z"
        fill={colorPick}
      />
    </Svg>
  )
}
