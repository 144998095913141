import { useMemo } from 'react'

import {
  PointsRvStatementData,
  PointsRvStatement,
  PointsItem,
  useGetPointsRvStatementQuery
} from 'integration/resources/rv'
import { useGetRvExtractsQuery } from 'integration/resources/variable-revenue/hooks'
import {
  AcceleratorsData,
  BlocksItem,
  BlocksRvStatementData,
  MultipliersData,
  PerformanceData,
  RvExtracts
} from 'integration/resources/variable-revenue/types'
import { useBreakpointValue } from 'native-base'
import { useAuthAtomValue } from 'src/store/auth'

import {
  RV_STATEMENT_NAMES,
  RV_STATEMENT_NAMES_REMOVE_BUSINESS,
  RV_STATEMENT_ACCELERATORS,
  RV_STATEMENT_MULTIPLIERS,
  RV_STATEMENT_PERFORMANCE
} from './constants'
import { UseSummaryDetailScreen } from './SummaryDetailScreen.types'

export const useSummaryDetailScreen: UseSummaryDetailScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const authAtom = useAuthAtomValue()

  const isGoodsAndServices = useMemo(
    () => authAtom?.user.business_code === 3,
    [authAtom?.user.business_code]
  )

  const handleGoBack = () => navigation.goBack()

  const getRvData = isGoodsAndServices ? useGetPointsRvStatementQuery : useGetRvExtractsQuery

  const { data, isLoading } = getRvData()

  const pointsRvSummary = useMemo(() => data?.data.data, [data?.data.data])

  const blocksRvSummary = useMemo(() => data?.data.data, [data?.data.data])

  const PointsRvStatementResults = isGoodsAndServices
    ? pointsRvSummary
      ? Object.entries(pointsRvSummary)
      : []
    : blocksRvSummary
    ? Object.entries(blocksRvSummary)
    : []

  const rvStatementList: (PointsRvStatementData | BlocksRvStatementData)[] = []

  const acceleratorsList: AcceleratorsData[] = []

  const multipliersList: MultipliersData[] = []

  const performanceList: PerformanceData[] = []

  PointsRvStatementResults.forEach((item, index) => {
    if (isGoodsAndServices) {
      const [key, value] = item as [string, string | number | PointsItem]

      if (typeof value === 'object') {
        const commonFields = {
          id: index,
          title: RV_STATEMENT_NAMES[key as keyof typeof RV_STATEMENT_NAMES] ?? '',
          goal_value: String(value?.goal_value ?? ''),
          percentage: String(value?.percentage ?? ''),
          projection_percent: String(value?.projection_percent ?? ''),
          projection_points_value: String(value?.projection_points_value ?? ''),
          real_points_value: String(value?.real_points_value ?? ''),
          real_value: String(value?.real_value ?? ''),
          weight_value: String(value?.weight_value ?? '')
        }

        if (!RV_STATEMENT_NAMES_REMOVE_BUSINESS.includes(commonFields.title))
          rvStatementList.push(commonFields)
      }
    } else {
      const [key, value] = item as [string, string | number | BlocksItem]

      if (typeof value === 'object') {
        const commonFields = {
          id: index,
          title: RV_STATEMENT_NAMES[key as keyof typeof RV_STATEMENT_NAMES] ?? '',
          processed_at: value?.processed_at ?? ''
        }

        if (RV_STATEMENT_ACCELERATORS.includes(commonFields.title)) {
          const acceleratorsFields = {
            ...commonFields,
            realized: value?.realized ?? 0,
            goal: value?.goal ?? 0,
            percentage_realized: value?.percentage_realized ?? 0,
            //@ts-ignore
            active_value: value?.active_value ?? 0,
            //@ts-ignore
            strip: value?.strip ?? {}
          }

          acceleratorsList.push(acceleratorsFields)
        } else if (RV_STATEMENT_MULTIPLIERS.includes(commonFields.title)) {
          const newMultiplier = {
            ...commonFields,
            //@ts-ignore
            value: value?.value ?? 0,
            //@ts-ignore
            lines: value?.lines ?? 0,
            //@ts-ignore
            multiplier_type: value?.multiplier_type ?? ''
          }

          multipliersList.push(newMultiplier)
        } else if (RV_STATEMENT_PERFORMANCE.includes(commonFields.title)) {
          const newPerformance = {
            ...commonFields,
            //@ts-ignore
            position: value?.position ?? 0,
            //@ts-ignore
            next_level: value?.next_level,
            //@ts-ignore
            boosted_rv: value?.boosted_rv ?? 0,
            //@ts-ignore
            quadrant: value?.quadrant ?? ''
          }

          performanceList.push(newPerformance)
        } else {
          const blocksFields = {
            ...commonFields,
            realized: value?.realized ?? 0,
            projected: value?.projected ?? 0,
            goal: value?.goal ?? 0,
            goal_2: value?.goal_2 ?? 0,
            rv_realized: value?.rv_realized ?? 0,
            rv_projected: value?.rv_projected ?? 0,
            weight: value?.weight ?? 0,
            percentage_realized: value?.percentage_realized ?? 0,
            percentage_projected: value?.percentage_projected ?? 0,
            accelerator: value?.accelerator,
            percentage_accelerator: value?.percentage_accelerator,
            active_base: value?.active_base,
            loyalty: value?.loyalty,
            charge_goal: value?.charge_goal
          }

          rvStatementList.push(blocksFields)
        }
      }
    }
  })

  const activeBaseList = useMemo(
    () => [
      {
        id: '0',
        title: 'F2 (R)',
        value: (blocksRvSummary as RvExtracts)?.active_base?.strip?.strip_two_negative_points ?? 0,
        reference: `<=${
          (blocksRvSummary as RvExtracts)?.active_base?.strip?.strip_two_negative_value
        }`
      },
      {
        id: '1',
        title: 'F1 (R)',
        value: (blocksRvSummary as RvExtracts)?.active_base?.strip?.strip_one_negative_points ?? 0,
        reference: `${
          (blocksRvSummary as RvExtracts)?.active_base?.strip?.strip_one_negative_value
        }`
      },
      {
        id: '2',
        title: 'Neutro',
        value: (blocksRvSummary as RvExtracts)?.active_base?.strip?.strip_zero_points ?? 0,
        reference: `${(blocksRvSummary as RvExtracts)?.active_base?.strip?.strip_zero_value}`
      },
      {
        id: '3',
        title: 'F1',
        value: (blocksRvSummary as RvExtracts)?.active_base?.strip?.strip_one_positive_points ?? 0,
        reference: `${
          (blocksRvSummary as RvExtracts)?.active_base?.strip?.strip_one_positive_value
        }`
      },
      {
        id: '4',
        title: 'F2',
        value: (blocksRvSummary as RvExtracts)?.active_base?.strip?.strip_two_positive_points ?? 0,
        reference: `>=${
          (blocksRvSummary as RvExtracts)?.active_base?.strip?.strip_two_positive_value
        }`
      }
    ],
    [blocksRvSummary]
  )

  const loyaltyList = useMemo(
    () => [
      {
        id: '0',
        title: 'Neutro',
        value: (blocksRvSummary as RvExtracts)?.loyalty?.strip?.strip_zero_points ?? 0,
        reference: `${(blocksRvSummary as RvExtracts)?.loyalty?.strip?.strip_zero_value}`
      },
      {
        id: '1',
        title: 'F1',
        value: (blocksRvSummary as RvExtracts)?.loyalty?.strip?.strip_one_positive_points ?? 0,
        reference: `>=${(blocksRvSummary as RvExtracts)?.loyalty?.strip?.strip_one_positive_value}`
      },
      {
        id: '2',
        title: 'F2',
        value: (blocksRvSummary as RvExtracts)?.loyalty?.strip?.strip_two_positive_points ?? 0,
        reference: `>=${(blocksRvSummary as RvExtracts)?.loyalty?.strip?.strip_two_positive_value}`
      }
    ],
    [blocksRvSummary]
  )

  return {
    isMobile,
    isGoodsAndServices,
    handleGoBack,
    isLoading,
    rvSummary: isGoodsAndServices
      ? (pointsRvSummary as PointsRvStatement)
      : (blocksRvSummary as RvExtracts),
    rvStatementList,
    acceleratorsList: isGoodsAndServices ? [] : acceleratorsList,
    activeBaseList,
    loyaltyList,
    multipliers: multipliersList[0],
    performance: performanceList[0]
  }
}
