import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const PadLockIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 14 19', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.54564 2.33065C4.47143 1.40487 5.72706 0.884766 7.03632 0.884766C8.34558 0.884766 9.60122 1.40487 10.527 2.33065C11.4528 3.25644 11.9729 4.51207 11.9729 5.82133V6.93222C12.9832 6.98462 13.7863 7.82042 13.7863 8.8438V16.0975C13.7863 17.1547 12.9293 18.0117 11.8721 18.0117H2.2005C1.14332 18.0117 0.286316 17.1547 0.286316 16.0975V8.8438C0.286316 7.82042 1.08941 6.98461 2.09975 6.93222V5.82133C2.09975 4.51207 2.61986 3.25644 3.54564 2.33065ZM10.5624 5.82133V6.92962H3.5102V5.82133C3.5102 4.88615 3.8817 3.98927 4.54298 3.32799C5.20425 2.66671 6.10114 2.29521 7.03632 2.29521C7.97151 2.29521 8.86839 2.66671 9.52966 3.32799C10.1909 3.98927 10.5624 4.88615 10.5624 5.82133ZM2.2005 8.34007C1.92229 8.34007 1.69676 8.56559 1.69676 8.8438V16.0975C1.69676 16.3757 1.92229 16.6013 2.2005 16.6013H11.8721C12.1503 16.6013 12.3759 16.3757 12.3759 16.0975V8.8438C12.3759 8.56559 12.1503 8.34007 11.8721 8.34007H2.2005ZM7.13673 12.4706C7.13673 12.5263 7.09163 12.5714 7.03598 12.5714C6.98034 12.5714 6.93524 12.5263 6.93524 12.4706C6.93524 12.415 6.98034 12.3699 7.03598 12.3699C7.09163 12.3699 7.13673 12.415 7.13673 12.4706ZM7.03598 11.1609C6.31266 11.1609 5.72628 11.7473 5.72628 12.4706C5.72628 13.194 6.31266 13.7803 7.03598 13.7803C7.75931 13.7803 8.34569 13.194 8.34569 12.4706C8.34569 11.7473 7.75931 11.1609 7.03598 11.1609Z"
      fill="currentColor"
    />
  </Icon>
)
