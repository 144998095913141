import { FunctionComponent } from 'react'

import { ITheme, useToken } from 'native-base'
import { Leaves } from 'native-base/lib/typescript/theme/base/types'
import { Path, Svg } from 'react-native-svg'
import { resize } from 'src/utils'

type CheckIconProps = {
  color?: Leaves<ITheme['colors']>
  size?: number
}

export const CheckIcon: FunctionComponent<CheckIconProps> = ({ size = 20, color = 'gray.700' }) => {
  const { height, width } = resize({ height: 25, width: 24, size })

  const [colorPick] = useToken('colors', [color])

  return (
    <Svg {...{ viewBox: '2 -1 10 20', width, height }}>
      <Path
        d="M17.5333 0.294647C18.0806 0.746984 18.1577 1.55739 17.7053 2.10476L7.36826 14.6137L7.36515 14.6174C7.13002 14.8997 6.83492 15.1262 6.50132 15.2802C6.16771 15.4341 5.80398 15.5119 5.43659 15.5075H5.43543C5.06202 15.5029 4.69452 15.4138 4.3605 15.2468C4.02761 15.0803 3.7366 14.8409 3.50917 14.5463C3.5084 14.5453 3.50764 14.5442 3.50687 14.5432L0.270891 10.3827C-0.165054 9.82224 -0.0640823 9.01445 0.496418 8.57851C1.05692 8.14256 1.8647 8.24354 2.30065 8.80405L5.46808 12.8764L15.7231 0.466726C16.1754 -0.0806331 16.9859 -0.157675 17.5333 0.294647Z"
        fill={colorPick}
      />
    </Svg>
  )
}
