import { MultipleBarsChart as LandscapeMultipleBars } from './LandscapeCharts/MultipleBarsChart'
import { SingleBarChart as LandscapeSingleBar } from './LandscapeCharts/SingleBarChart'
import { MultipleBarsChart as PortraitMultipleBars } from './PortraitCharts/MultipleBarsChart'
import { SingleBarChart as PortraitSingleBar } from './PortraitCharts/SingleBarChart'

export default {
  LandscapeSingleBar,
  LandscapeMultipleBars,
  PortraitMultipleBars,
  PortraitSingleBar
}
