import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { PasswordScreenComponent } from './PasswordScreen.types'
// eslint-disable-next-line import/namespace
import { useCreateAccountCreatePasswordScreen } from './useCreateAccountCreatePasswordScreen'

const PasswordScreenDesktop = lazy(() => import('./PasswordScreen.desktop'))

const PasswordScreenMobile = lazy(() => import('./PasswordScreen.mobile'))

export const CreateAccountCreatePasswordScreen: PasswordScreenComponent = ({
  navigation,
  route
}) => {
  const passwordScreen = useCreateAccountCreatePasswordScreen({ navigation, route })

  const Screen = passwordScreen.isMobile ? PasswordScreenMobile : PasswordScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...passwordScreen} />
    </Suspense>
  )
}
