import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import * as requests from './requests'
import {
  PostFileParams,
  PresignedUrlResponse,
  UploadFilePayload,
  UploadFileResponse
} from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const usePresinedFileMutation = () =>
  useMutation<
    AxiosResponse<ResponseSuccess<PresignedUrlResponse>>,
    AxiosError<ResponseError>,
    PostFileParams
  >(requests.presignedUrlFile)

export const useUploadMutation = () =>
  useMutation<UploadFileResponse[], any, UploadFilePayload>(requests.uploadFile)
