import { useState } from 'react'

import { Icons } from 'atoms'
import {
  Box,
  Divider,
  HStack,
  IconButton,
  Pressable,
  ScrollView,
  Text,
  VStack,
  useDisclose
} from 'native-base'

import { TSelectTypeOfActivitiesProps } from './types'

export const SelectType: TSelectTypeOfActivitiesProps = ({ value, onSelect, list }) => {
  const { isOpen, onToggle } = useDisclose(false)

  const [valueSelected, setValueSelected] = useState<string | undefined>(value)

  const handleSelect = (activity: string) => {
    setValueSelected(activity)

    onSelect(activity)

    onToggle()
  }

  return (
    <Box position="relative">
      <Pressable
        onPress={onToggle}
        alignItems="center"
        w="full"
        h="50px"
        bg="gray.30"
        borderRadius="30px"
        flexDir="row"
        px={4}
        py="13px">
        <Text fontSize="16px" lineHeight="24px" color="gray.500" mr={4}>
          Tipo
        </Text>
        <HStack flex={1}>
          {valueSelected && (
            <HStack px={4} alignItems="center" bg="tertiary.800" h="24px" borderRadius="40px">
              <Text fontSize="12px" color="white" textTransform="uppercase" bold>
                {value}
              </Text>
            </HStack>
          )}
        </HStack>

        <HStack alignItems="center" space={2}>
          {valueSelected && (
            <IconButton
              borderRadius="full"
              colorScheme="gray"
              onPress={() => setValueSelected(undefined)}
              icon={<Icons.CloseTab color="gray.700" size={4} />}
            />
          )}
          <Icons.ArrowDropDown size={6} color="gray.700" />
        </HStack>
      </Pressable>

      {isOpen && (
        <VStack
          position="static"
          bg="white"
          maxH="500px"
          w="99%"
          mx="auto"
          style={{
            // @ts-ignore
            'box-shadow': '0px 5px 15px rgba(68, 68, 79, 0.1)'
          }}
          top={10}
          _important={{
            zIndex: 999
          }}
          borderRadius="20px"
          py={8}>
          <ScrollView>
            {list?.map((item, index) => (
              <>
                <Pressable
                  onPress={() => handleSelect(item)}
                  flexDir="row"
                  alignItems="center"
                  h={10}
                  px={8}
                  {...(item === valueSelected && { bg: 'gray.30' })}
                  py={4}
                  key={item}>
                  <Text>{item}</Text>
                </Pressable>
                {list?.length !== index + 1 && <Divider bg="gray.30" my={4} />}
              </>
            ))}
          </ScrollView>
        </VStack>
      )}
    </Box>
  )
}
