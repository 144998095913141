import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { G, Path, ClipPath, Defs } from 'react-native-svg'

export const OrganizationIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1.969a.75.75 0 1 0-1.5 0v1.05H2.55A2.55 2.55 0 0 0 0 4.569v10.8a2.55 2.55 0 0 0 2.55 2.55h12.6a2.55 2.55 0 0 0 2.55-2.55v-10.8a2.55 2.55 0 0 0-2.55-2.55H14.1V.969a.75.75 0 0 0-1.5 0v1.05H5.1V.969Zm11.1 6.45v-2.85c0-.58-.47-1.05-1.05-1.05H14.1v1.05a.75.75 0 0 1-1.5 0v-1.05H5.1v1.05a.75.75 0 1 1-1.5 0v-1.05H2.55c-.58 0-1.05.47-1.05 1.05v2.85h14.7Zm-14.7 1.5h14.7v6.45c0 .58-.47 1.05-1.05 1.05H2.55c-.58 0-1.05-.47-1.05-1.05v-6.45Z"
        fill="currentColor"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(0 .219)" d="M0 0h18v18H0z" />
      </ClipPath>
    </Defs>
  </Icon>
)
