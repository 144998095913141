import { memo } from 'react'

import { LinearGradient } from 'expo-linear-gradient'
import { Box, HStack, IconButton, Skeleton, useTheme, VStack } from 'native-base'
import { Dimensions } from 'react-native'

const screenHeight = Dimensions.get('screen').height

export const SkeletonContentDetail = memo(() => {
  const theme = useTheme()

  return (
    <>
      <LinearGradient
        colors={[theme.colors['header-gradient']['100'], theme.colors['header-gradient']['200']]}>
        <LinearGradient
          colors={['#0d0d0d0d', '#00000028', theme.colors['header-gradient']['200']]}
          style={{ flex: 1 }}>
          <HStack mt={screenHeight > 760 ? 12 : 6} justifyContent="flex-end" mr="18px">
            <IconButton
              size={10}
              bg="#ABABAB"
              rounded="full"
              mt={6}
              icon={<Skeleton w="60%" h={6} rounded="full" />}
              _pressed={{ color: 'white', bg: 'transparent' }}
            />
          </HStack>

          <Box h="315">
            <VStack justifyContent="flex-end" px={4} pb={8} h="full">
              <HStack w="full" pb={4}>
                <Skeleton w="50%" h={6} />
              </HStack>
              <VStack w="full">
                <Skeleton w="50%" h={6} />

                <HStack justifyContent="space-between" alignItems="center">
                  <Skeleton w="100%" h={6} mt={3} />
                </HStack>
              </VStack>
            </VStack>
          </Box>
        </LinearGradient>
      </LinearGradient>

      <VStack pt={8} px={4}>
        <Skeleton.Text w="100%" h={6} mt={3} />
        <Box pt={6}>
          <Skeleton.Text w="100%" h={6} mt={10} />
        </Box>
        <Box py={6}>
          <Skeleton.Text w="100%" h={6} mt={10} />
        </Box>
      </VStack>
    </>
  )
})
