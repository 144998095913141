import { useRef } from 'react'

import { WebView } from 'react-native-webview'

type TTypeformEmbed = {
  url: string
  hideHeaders?: boolean
  hideFooter?: boolean
  opacity?: string
  buttonText?: string
  onSubmit(): void
  onClose(): void
}

export const TypeformEmbed = ({
  url,
  hideHeaders = true,
  hideFooter = true,
  opacity,
  buttonText,
  onSubmit,
  onClose,
  ...props
}: TTypeformEmbed) => {
  const webViewRef = useRef<any>(null)

  const options = {
    mode: 'popup',
    hideHeaders: true,
    hideFooter: true,
    opacity: 0,
    buttonText
  }

  const stringifedOptions = JSON.stringify(JSON.stringify(options))

  const embedCode = ` 
  {
    const onSubmit = () => window.ReactNativeWebView.postMessage("onSubmit")
    const onClose = () => window.ReactNativeWebView.postMessage("onClose")
    const options = Object.assign({}, JSON.parse(${stringifedOptions}), {onSubmit,onClose})
    const ref = typeformEmbed.makePopup('${url}', options)
      ref.open()
  }
  true
  `

  const onLoad = () => {}

  const onMessage = (event: any) => {
    const { data } = event.nativeEvent

    if (data === 'onSubmit') return onSubmit()

    if (data === 'onClose') return onClose()
  }

  return (
    <WebView
      startInLoadingState
      originWhitelist={['*']}
      cacheMode="LOAD_NO_CACHE"
      cacheEnabled={false}
      injectedJavaScript={embedCode}
      ref={webViewRef}
      source={{
        html: '<html><head><meta name="viewport" content="width=device-width, initial-scale=1.0"><script src="https://embed.typeform.com/embed.js"></script></head><div id="typeform-embed"></div></html>'
      }}
      onLoadEnd={onLoad}
      onMessage={onMessage}
      {...props}
    />
  )
}
