import { CalendarApresentation } from './CalendarApresentation'
import { CalendarBody } from './CalendarBody'
import { CalendarFooter } from './CalendarFooter'
import { CalendarHeader } from './CalendarHeader'
import { CalendarRoot } from './CalendarRoot'
import { CalendarTabSliding } from './CalendarTabSliding'
import { CalendarTitle } from './CalendarTitle'

export const Calendar = {
  Apresentation: CalendarApresentation,
  Body: CalendarBody,
  Footer: CalendarFooter,
  Header: CalendarHeader,
  Tab: CalendarTabSliding,
  Root: CalendarRoot,
  Title: CalendarTitle
}
