import { FunctionComponent } from 'react'

import { IIconProps, Icon } from 'native-base'
import { Path } from 'react-native-svg'

export const ArrowRightGoIcon: FunctionComponent<IIconProps> = (props) => {
  return (
    <Icon {...{ viewBox: '0 0 25 15', ...props }}>
      <Path
        d="M1.934 6.503a1 1 0 1 0-.005 2l19.186.048-4.304 4.282a1 1 0 0 0 1.411 1.418l6.015-5.985a1 1 0 0 0 .004-1.414L18.256.837a1 1 0 1 0-1.418 1.41l4.282 4.304-19.186-.048Z"
        fill="currentColor"
      />
    </Icon>
  )
}
