import { FunctionComponent, useMemo } from 'react'

import { MultipliersData } from 'integration/resources/variable-revenue/types'
import { VStack, Heading, Text, HStack, Box, Divider, Circle } from 'native-base'
import { formatDateStringToMask } from 'src/utils'

const lines = [2, 3, 4, 5, 6]

const normalMultipliers = [1.0, 1.2, 1.4, 1.8, 4.0]

const insuranceMultipliers = [1.0, 1.4, 1.8, 2.3, 4.0]

type MultiplierProps = {
  multipliers: MultipliersData
}

export const SummaryMultipliers: FunctionComponent<MultiplierProps> = ({ multipliers }) => {
  const { lines: activeLine, title, value: activeItem, processed_at, multiplier_type } = multipliers

  const is_insurance = useMemo(() => multiplier_type.toLowerCase() === 'seguros', [multiplier_type])

  const renderMultiplierLine = (line: number, index: number) => {
    const isActive = activeLine === line

    return (
      <Box h="20px" key={line + index}>
        <Text
          key={index}
          fontSize={{ base: '12px', lg: '14px' }}
          fontWeight={isActive ? '700' : '400'}
          lineHeight={{ base: '16px', lg: '20px' }}
          color={{ base: 'gray.700', lg: isActive ? 'gray.700' : 'gray.400' }}>
          {line} linhas
        </Text>
      </Box>
    )
  }

  const renderMultiplierValue = (multiplier: number, index: number, isInsurance: boolean) => {
    const isActive = activeItem === multiplier && is_insurance === isInsurance

    return (
      <HStack key={index} h="20px" space="5px" alignItems="center">
        {isActive && <Circle bgColor="secondary.300" size="2" />}
        <Text
          fontSize={{ base: '12px', lg: '14px' }}
          fontWeight={isActive ? '700' : '400'}
          lineHeight={{ base: '16px', lg: '20px' }}
          color={{ base: 'gray.700', lg: isActive ? 'gray.700' : 'gray.400' }}>
          {multiplier.toFixed(1).replace('.', ',')}
        </Text>
      </HStack>
    )
  }

  return (
    <VStack
      bg={{ base: 'white', lg: 'gray.50' }}
      borderRadius={{ base: '10px', lg: '20px' }}
      p={{ base: 4, lg: 6 }}
      space={{ base: 3, lg: 6 }}>
      <Heading
        fontSize={{ base: '14px', lg: '18px' }}
        fontWeight={700}
        lineHeight={{ base: '18px', lg: '24px' }}>
        {title}
      </Heading>

      <VStack>
        <HStack mb={4}>
          <Box h="24px" flex={1} alignItems="flex-start" />

          <Box h="24px" flex={1} alignItems="center">
            <Text
              fontSize="14px"
              fontWeight={!is_insurance ? 700 : 400}
              lineHeight={{ base: '18px', lg: '20px' }}
              color="gray.700">
              Normal
            </Text>
          </Box>

          <Box h="24px" flex={1} alignItems="flex-end">
            <Text
              fontSize="14px"
              fontWeight={is_insurance ? 700 : 400}
              lineHeight={{ base: '18px', lg: '20px' }}
              color="gray.700">
              Seguros
            </Text>
          </Box>
        </HStack>

        <HStack justifyContent="space-between">
          <VStack flex={1} space={4}>
            {lines.map(renderMultiplierLine)}
          </VStack>
          <VStack flex={1} space={4} alignItems="center">
            {normalMultipliers.map((multiplier, index) =>
              renderMultiplierValue(multiplier, index, false)
            )}
          </VStack>
          <VStack flex={1} space={4} alignItems="flex-end">
            {insuranceMultipliers.map((multiplier, index) =>
              renderMultiplierValue(multiplier, index, true)
            )}
          </VStack>
        </HStack>
      </VStack>

      <Box>
        <Divider h="1px" my={{ base: 1, lg: 0 }} bg="gray.100" opacity={0.6} />
      </Box>

      <VStack space={3}>
        <Text
          fontSize={{ base: '10px', lg: '14px' }}
          fontWeight={600}
          lineHeight={{ base: '14px', lg: '20px' }}
          color="gray.200">
          Dados até:{' '}
          {processed_at ? formatDateStringToMask(processed_at, "dd'/'MM'/'yyyy") : 'Sem data'}
        </Text>
      </VStack>
    </VStack>
  )
}
