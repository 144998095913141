import { useCallback } from 'react'

import { useBreakpointValue } from 'native-base'

import { UseResetPasswordSuccessfullyScreen } from './ResetPasswordSuccessfullyScreen.types'

export const useResetPasswordSuccessfullyScreen: UseResetPasswordSuccessfullyScreen = ({
  navigation
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const handleGoToLoginScreen = useCallback(
    () =>
      navigation.reset({
        index: 0,
        routes: [{ name: 'Entry' }, { name: isMobile ? 'Login' : 'Entry' }]
      }),
    [navigation, isMobile]
  )

  return {
    handleGoToLoginScreen,
    isMobile
  }
}
