import { FunctionComponent, memo } from 'react'

import Svg, { Path } from 'react-native-svg'

type LaboritIllustrationProps = {
  width?: number
  height?: number
}

export const LaboritIllustration: FunctionComponent<LaboritIllustrationProps> = ({
  width = 64,
  height = 23
}) => (
  <Svg width={width} height={height} viewBox="0 0 64 23" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.265 12.442c.11 3.578 3.178 6.398 6.82 6.267 3.64-.13 6.51-3.123 6.377-6.701-.11-3.579-3.178-6.398-6.82-6.268-3.64.13-6.488 3.123-6.377 6.702Zm6.643 3.491c2.052.022 3.73-1.604 3.73-3.621.021-2.018-1.634-3.666-3.686-3.666-2.053-.022-3.73 1.605-3.73 3.622-.022 1.995 1.633 3.644 3.686 3.665Z"
      fill="#fff"
    />
    <Path
      d="M2.935 2.118c0-.087-.088-.13-.154-.108L.066 3.268C.022 3.289 0 3.31 0 3.354V18.45c0 .065.044.109.11.109h2.715c.066 0 .11-.044.11-.109V2.12ZM57.225 3.766c0-.086-.088-.13-.155-.108l-2.714 1.236a.119.119 0 0 0-.066.109v13.424c0 .066.044.109.11.109h2.715c.066 0 .11-.043.11-.109V3.767ZM57.225.123c0-.087-.088-.13-.155-.109l-2.714 1.237c-.044.043-.066.065-.066.108v2.516c0 .087.088.13.154.108l2.715-1.236a.119.119 0 0 0 .066-.108V.123ZM15.228 7.649C13.793 6.217 11.652 5.523 9.6 5.805c-5.363.76-7.57 7.222-3.73 11.04 2.163 2.168 5.849 2.515 8.387.78v.802c0 .066.044.109.11.109h2.714c.067 0 .11-.043.11-.109v-6.18a6.377 6.377 0 0 0-1.963-4.598Zm-.993 4.576c.022 2.082-1.854 3.795-3.95 3.622-4.657-.52-4.48-6.962.198-7.244 1.986-.065 3.796 1.648 3.752 3.622ZM29.418 7.67c-2.163-2.19-5.892-2.516-8.43-.737V2.16c0-.086-.089-.13-.155-.108L18.12 3.289a.119.119 0 0 0-.067.109v8.892a6.423 6.423 0 0 0 2.825 5.313c2.582 1.822 6.378 1.497 8.585-.759 2.538-2.45 2.516-6.766-.044-9.174Zm-.993 4.576c0 3.21-4.017 4.837-6.312 2.56a3.595 3.595 0 0 1-1.06-2.56c0-1.995 1.656-3.6 3.664-3.622 2.03-.021 3.708 1.627 3.708 3.622ZM52.303 5.718c-3.023-.021-5.65 2.494-5.583 5.466v7.222c0 .065.044.108.11.108h2.715c.066 0 .11-.043.11-.108v-7.222c0-.694.287-1.345.75-1.822.508-.499 1.28-.78 2.03-.78.067 0 .111-.044.111-.11V5.828c0-.065-.066-.109-.132-.109h-.11ZM64 6.434c0-.065-.044-.108-.11-.108H61.88V1.64c0-.087-.088-.13-.154-.109L59.012 2.77a.12.12 0 0 0-.066.108v15.529c0 .065.044.108.11.108h2.715c.066 0 .11-.043.11-.108V9.188h2.009c.066 0 .11-.043.11-.108V6.434ZM44.866 22.635c0 .065-.044.108-.11.108H32.993c-.066 0-.11-.043-.11-.108v-1.887c0-.065.044-.108.11-.108l11.763-.846c.066 0 .11.043.11.108v2.733Z"
      fill="#fff"
    />
  </Svg>
)

export const Turbo = memo(LaboritIllustration)
