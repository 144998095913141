import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path, Mask, G } from 'react-native-svg'

export const GooglePlayLogoIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      d="M0.964921 1.37294C0.694291 1.66224 0.535645 2.11018 0.535645 2.67943V23.322C0.535645 23.9006 0.694291 24.3485 0.964921 24.6285L1.03025 24.6938L12.5927 13.1407V13.0007V12.8607L1.03025 1.30762L0.964921 1.37294Z"
      fill="white"
      stroke="currentColor"
      strokeWidth="0.833333"
      strokeMiterlimit="10"
    />
    <Path
      d="M16.4469 16.9941L12.5928 13.14V13V12.86L16.4469 9.00586L16.5309 9.05252L21.0943 11.6468C22.4008 12.3841 22.4008 13.5972 21.0943 14.3438L16.5309 16.9381L16.4469 16.9941Z"
      fill="white"
      stroke="currentColor"
      strokeWidth="0.833333"
      strokeMiterlimit="10"
    />
    <Path
      d="M16.5317 16.9381L12.5936 13L0.96582 24.6278C1.3951 25.085 2.10433 25.141 2.90689 24.6838L16.5317 16.9381Z"
      fill="white"
      stroke="currentColor"
      strokeWidth="0.833333"
      strokeMiterlimit="10"
    />
    <Path
      d="M16.532 9.06186L2.89786 1.31624C2.0953 0.858968 1.38606 0.914961 0.956787 1.37223L12.5939 13L16.532 9.06186Z"
      fill="white"
      stroke="currentColor"
      strokeWidth="0.833333"
      strokeMiterlimit="10"
    />
    <G opacity="0.2">
      <Mask id="path-5-inside-1_14821_17664" fill="white">
        <Path d="M16.4472 16.8535L2.89707 24.5525C2.14117 24.9817 1.45993 24.9538 1.03066 24.5618L0.965332 24.6271L1.03066 24.6925C1.46926 25.0844 2.14117 25.1124 2.89707 24.6831L16.5312 16.9375L16.4472 16.8535Z" />
      </Mask>
      <Path
        d="M16.4472 16.8535L2.89707 24.5525C2.14117 24.9817 1.45993 24.9538 1.03066 24.5618L0.965332 24.6271L1.03066 24.6925C1.46926 25.0844 2.14117 25.1124 2.89707 24.6831L16.5312 16.9375L16.4472 16.8535Z"
        fill="black"
      />
      <Path
        d="M16.4472 16.8535L17.0365 16.2643L16.5876 15.8153L16.0356 16.129L16.4472 16.8535ZM2.89707 24.5525L3.30859 25.2771L3.30874 25.277L2.89707 24.5525ZM1.03066 24.5618L1.59255 23.9464L1.00448 23.4095L0.441401 23.9725L1.03066 24.5618ZM0.965332 24.6271L0.376076 24.0379L-0.213179 24.6271L0.376076 25.2164L0.965332 24.6271ZM1.03066 24.6925L0.441401 25.2817L0.45794 25.2982L0.47538 25.3138L1.03066 24.6925ZM2.89707 24.6831L3.30859 25.4078L3.3087 25.4077L2.89707 24.6831ZM16.5312 16.9375L16.9429 17.6621L17.894 17.1217L17.1205 16.3482L16.5312 16.9375ZM16.0356 16.129L2.4854 23.8279L3.30874 25.277L16.8589 17.5781L16.0356 16.129ZM2.48555 23.8278C1.91011 24.1546 1.65715 24.0054 1.59255 23.9464L0.468765 25.1772C1.26271 25.9021 2.37223 25.8089 3.30859 25.2771L2.48555 23.8278ZM0.441401 23.9725L0.376076 24.0379L1.55459 25.2164L1.61991 25.1511L0.441401 23.9725ZM0.376076 25.2164L0.441401 25.2817L1.61991 24.1032L1.55459 24.0379L0.376076 25.2164ZM0.47538 25.3138C1.2746 26.028 2.36741 25.9423 3.30859 25.4078L2.48555 23.9585C2.20262 24.1192 1.98887 24.1634 1.85101 24.1624C1.72383 24.1615 1.64491 24.1238 1.58593 24.0711L0.47538 25.3138ZM3.3087 25.4077L16.9429 17.6621L16.1196 16.2129L2.48544 23.9586L3.3087 25.4077ZM17.1205 16.3482L17.0365 16.2643L15.858 17.4428L15.942 17.5268L17.1205 16.3482Z"
        fill="#F3123C"
        mask="url(#path-5-inside-1_14821_17664)"
      />
    </G>
    <G opacity="0.12">
      <Mask id="path-7-inside-2_14821_17664" fill="white">
        <Path d="M0.964432 24.4959C0.693802 24.2067 0.535156 23.7587 0.535156 23.1895V23.3294C0.535156 23.908 0.693802 24.356 0.964432 24.6359L1.02976 24.5706L0.964432 24.4959Z" />
      </Mask>
      <Path
        d="M0.964432 24.4959C0.693802 24.2067 0.535156 23.7587 0.535156 23.1895V23.3294C0.535156 23.908 0.693802 24.356 0.964432 24.6359L1.02976 24.5706L0.964432 24.4959Z"
        fill="black"
      />
      <Path
        d="M0.964432 24.4959L1.59159 23.9472L1.58246 23.9368L1.57299 23.9266L0.964432 24.4959ZM0.964432 24.6359L0.365275 25.2151L0.95436 25.8245L1.55369 25.2252L0.964432 24.6359ZM1.02976 24.5706L1.61901 25.1599L2.17028 24.6086L1.65691 24.0219L1.02976 24.5706ZM1.57299 23.9266C1.4869 23.8346 1.36849 23.6144 1.36849 23.1895H-0.298177C-0.298177 23.903 -0.0992947 24.5787 0.355873 25.0652L1.57299 23.9266ZM-0.298177 23.1895V23.3294H1.36849V23.1895H-0.298177ZM-0.298177 23.3294C-0.298177 24.0457 -0.102429 24.7313 0.365275 25.2151L1.56359 24.0567C1.49003 23.9806 1.36849 23.7703 1.36849 23.3294H-0.298177ZM1.55369 25.2252L1.61901 25.1599L0.440501 23.9813L0.375177 24.0467L1.55369 25.2252ZM1.65691 24.0219L1.59159 23.9472L0.337278 25.0447L0.402602 25.1193L1.65691 24.0219Z"
        fill="#F3123C"
        mask="url(#path-7-inside-2_14821_17664)"
      />
    </G>
    <G opacity="0.12">
      <Mask id="path-9-inside-3_14821_17664" fill="white">
        <Path d="M21.0952 14.2132L16.4385 16.8541L16.5225 16.9381L21.0858 14.3438C21.7391 13.9705 22.0657 13.4853 22.0657 13C22.0191 13.4386 21.6924 13.8772 21.0952 14.2132Z" />
      </Mask>
      <Path
        d="M21.0952 14.2132L16.4385 16.8541L16.5225 16.9381L21.0858 14.3438C21.7391 13.9705 22.0657 13.4853 22.0657 13C22.0191 13.4386 21.6924 13.8772 21.0952 14.2132Z"
        fill="black"
      />
      <Path
        d="M21.0952 14.2132L20.6866 13.4869L20.6841 13.4883L21.0952 14.2132ZM16.4385 16.8541L16.0274 16.1293L15.0751 16.6693L15.8492 17.4434L16.4385 16.8541ZM16.5225 16.9381L15.9332 17.5274L16.3823 17.9764L16.9343 17.6626L16.5225 16.9381ZM21.0858 14.3438L21.4977 15.0683L21.4993 15.0674L21.0858 14.3438ZM22.0657 13H22.899L21.2371 12.9118L22.0657 13ZM20.6841 13.4883L16.0274 16.1293L16.8496 17.579L21.5063 14.938L20.6841 13.4883ZM15.8492 17.4434L15.9332 17.5274L17.1117 16.3489L17.0277 16.2649L15.8492 17.4434ZM16.9343 17.6626L21.4977 15.0683L20.674 13.6194L16.1106 16.2137L16.9343 17.6626ZM21.4993 15.0674C22.2985 14.6107 22.899 13.8956 22.899 13H21.2324C21.2324 13.075 21.1797 13.3304 20.6724 13.6203L21.4993 15.0674ZM21.2371 12.9118C21.2291 12.987 21.1471 13.2278 20.6866 13.4869L21.5037 14.9395C22.2378 14.5266 22.809 13.8902 22.8944 13.0882L21.2371 12.9118Z"
        fill="#F3123C"
        mask="url(#path-9-inside-3_14821_17664)"
      />
    </G>
    <G opacity="0.25">
      <Mask id="path-11-inside-4_14821_17664" fill="white">
        <Path d="M2.89696 1.44522L21.0945 11.7852C21.6824 12.1211 22.0184 12.5504 22.0744 12.9983C22.0744 12.5131 21.7477 12.0185 21.0945 11.6545L2.89696 1.31457C1.59047 0.577332 0.526611 1.19325 0.526611 2.68638V2.82636C0.526611 1.3239 1.5998 0.707982 2.89696 1.44522Z" />
      </Mask>
      <Path
        d="M2.89696 1.44522L21.0945 11.7852C21.6824 12.1211 22.0184 12.5504 22.0744 12.9983C22.0744 12.5131 21.7477 12.0185 21.0945 11.6545L2.89696 1.31457C1.59047 0.577332 0.526611 1.19325 0.526611 2.68638V2.82636C0.526611 1.3239 1.5998 0.707982 2.89696 1.44522Z"
        fill="#777E91"
      />
      <Path
        d="M2.89696 1.44522L2.48519 2.16971L2.48527 2.16976L2.89696 1.44522ZM21.0945 11.7852L21.5079 11.0616L21.5062 11.0606L21.0945 11.7852ZM22.0744 12.9983L21.2475 13.1017L22.9077 12.9983H22.0744ZM21.0945 11.6545L20.6828 12.3791L20.6889 12.3825L21.0945 11.6545ZM2.89696 1.31457L3.30865 0.590023L3.30649 0.588807L2.89696 1.31457ZM2.48527 2.16976L20.6828 12.5097L21.5062 11.0606L3.30865 0.720676L2.48527 2.16976ZM20.681 12.5087C21.1437 12.773 21.2363 13.0122 21.2475 13.1017L22.9013 12.895C22.8005 12.0885 22.2212 11.4692 21.5079 11.0616L20.681 12.5087ZM22.9077 12.9983C22.9077 12.1075 22.3128 11.3793 21.5001 10.9265L20.6889 12.3825C21.1826 12.6576 21.241 12.9186 21.241 12.9983H22.9077ZM21.5062 10.93L3.30865 0.590026L2.48527 2.03911L20.6828 12.379L21.5062 10.93ZM3.30649 0.588807C2.49794 0.132553 1.57616 -0.00384569 0.804398 0.442854C0.0308856 0.890566 -0.306722 1.75865 -0.306722 2.68638H1.35994C1.35994 2.12099 1.55427 1.93454 1.6393 1.88532C1.72609 1.83509 1.98948 1.75934 2.48742 2.04032L3.30649 0.588807ZM-0.306722 2.68638V2.82636H1.35994V2.68638H-0.306722ZM1.35994 2.82636C1.35994 2.25722 1.55605 2.06745 1.64342 2.01678C1.73182 1.96551 1.99449 1.89083 2.48519 2.16971L3.30872 0.72072C2.50226 0.262373 1.57977 0.127026 0.807282 0.575026C0.0337654 1.02362 -0.306722 1.89304 -0.306722 2.82636H1.35994Z"
        fill="#F3123C"
        mask="url(#path-11-inside-4_14821_17664)"
      />
    </G>
  </Icon>
)
