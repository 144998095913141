import { useCallback } from 'react'

import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { useCreateAccountMutation } from 'integration/resources/createAccount'
import { useBreakpointValue } from 'native-base'
import { CreatePasswordFormData } from 'src/components/templates/CreatePasswordScreenTemplate/CreatePasswordScreenTemplate'
import { useSendAuthCode } from 'src/hooks/useSendAuthCode'

import { UseCreateAccountCreatePasswordScreen } from './PasswordScreen.types'

const HTTP_STATUS_CONFLICT = 409

export const useCreateAccountCreatePasswordScreen: UseCreateAccountCreatePasswordScreen = ({
  navigation,
  route: { params }
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const createAccountMutation = useCreateAccountMutation()

  const bottomSheetContext = useBottomSheetContext()

  const { setAuthCodeAtom, handleSendAuthCode, isLoadingAuthCode } = useSendAuthCode()

  const onSubmit = useCallback(
    (formData: CreatePasswordFormData, refBottomSheetConfirm: any, refActionMessageSheet: any) => {
      createAccountMutation.mutate(
        {
          ...formData,
          ...params
        },
        {
          onError: (err) => {
            refBottomSheetConfirm.current?.onToggle()

            // when users already exists in db
            if (err.response?.status === HTTP_STATUS_CONFLICT) {
              return refActionMessageSheet.current?.onToggle()
            } else {
              bottomSheetContext.open({
                description:
                  'Uma ou mais informações que você forneceu não puderam ser confirmadas. Realize seu cadastro novamente.',
                title: 'Erro no cadastro'
              })
            }
          },
          onSuccess: ({
            headers,
            data: {
              data: { id }
            }
          }) => {
            refBottomSheetConfirm.current?.onToggle()

            setAuthCodeAtom({
              user_id: id,
              slt: headers['x-slt'],
              email: params.email,
              phone_number_cell: params.phone_number_cell
            })

            handleSendAuthCode('sms', {
              user_id: id,
              slt: headers['x-slt']
            }).then(() =>
              navigation.reset({
                index: 0,
                routes: [
                  {
                    name: 'CreateAccountAuthCode',
                    params: {
                      id,
                      slt: headers['x-slt'],
                      email: params.email,
                      password: formData.password
                    }
                  }
                ]
              })
            )
          }
        }
      )
    },
    [
      createAccountMutation,
      params,
      bottomSheetContext,
      setAuthCodeAtom,
      handleSendAuthCode,
      navigation
    ]
  )

  const handleButtonOkMessage = () => {
    navigation.reset({
      index: 1,
      routes: [
        {
          name: 'Entry'
        },
        {
          name: 'Login'
        }
      ]
    })
  }

  const isLoading = createAccountMutation.isLoading || isLoadingAuthCode

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  return {
    isMobile,
    isLoading,
    onSubmit,
    userData: params,
    handleButtonOkMessage,
    handleGoBack
  }
}
