import { useCallback, useEffect, useState } from 'react'

import * as ScreenOrientation from 'expo-screen-orientation'
import { useContractsByTabNumberQuery } from 'integration/resources/wallet'
import { useBreakpointValue } from 'native-base'
import { PanelsTypes } from 'organisms/TabPanels/constants'
import { useContractListScreenAtomValue } from 'src/store/screens/contractListScreen'
import { formatToReal, getMonthName } from 'src/utils'

import { UseContractListScreen } from './ContractListScreen.types'

const changeScreenOrientation = (orientation: ScreenOrientation.OrientationLock) => {
  return ScreenOrientation.lockAsync(orientation)
}

export const useContractListScreen: UseContractListScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [showSpinner, setShowSpinner] = useState(false)

  const contractListScreen = useContractListScreenAtomValue()

  const { tabNumber, yearMonth, title, description, type } = route.params

  const { data, isLoading } = useContractsByTabNumberQuery({ tabNumber, yearMonth })

  const contractList = data?.data.data

  const month = getMonthName(Number(yearMonth.slice(4)))

  const year = yearMonth.slice(0, 4)

  const handleGoBack = useCallback(() => {
    navigation.goBack()
  }, [navigation])

  useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        if (e.data.action.type === 'GO_BACK') {
          setShowSpinner(true)

          if (
            navigation.getState().routes?.[navigation.getState().routes.length - 2]?.name ===
            'Chart'
          ) {
            changeScreenOrientation(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT)
          }
        }
      }),
    [navigation]
  )

  const getDescriptionSegment = (type: PanelsTypes) => {
    if (contractList) {
      switch (type) {
        case PanelsTypes.MARKETING_SHARE:
          return `Market Share ${month} ${year} (%)`

        case PanelsTypes.CREDIT_LIFE_INSURANCE:
          return '% IP Seguro Prestamista (Qtd)'

        case PanelsTypes.OVER30_M3:
          return `Over 30 M3 ${month} ${year} (R$)`

        case PanelsTypes.FPD10:
          return `Contratos FPD10 ${month} ${year} (R$)`

        case PanelsTypes.SANTANDER_AUTO_INSURANCE:
          return `Produção ${month} ${year} (R$)`
      }
    }
  }

  const getValueOfSegment = (type: PanelsTypes) => {
    if (contractList) {
      switch (type) {
        case PanelsTypes.MARKETING_SHARE:
          return `${contractList.market_share}%`

        case PanelsTypes.CREDIT_LIFE_INSURANCE:
          return `${contractList?.ip_insurance_spf_qty}%`

        case PanelsTypes.OVER30_M3:
          return `${formatToReal(contractList?.vl_over30_m3 ?? '0')}`

        case PanelsTypes.FPD10:
          return `${formatToReal(contractList?.vl_fpd10 ?? '0')}`

        case PanelsTypes.SANTANDER_AUTO_INSURANCE:
          return `${formatToReal(contractList?.production ?? '0')}`
      }
    }
  }

  const getValueOfContract = (type: PanelsTypes) => {
    if (contractList) {
      switch (type) {
        case PanelsTypes.PRODUCTION:
          return `${formatToReal(contractList?.production ?? '0')}`

        case PanelsTypes.MARKETING_SHARE:
          return `${formatToReal(contractList?.production ?? '0')}`

        case PanelsTypes.CREDIT_LIFE_INSURANCE:
          return `${contractList?.ip_insurance_spf_volume}%`

        case PanelsTypes.OVER30_M3:
          return `${contractList?.over30_m3}%`

        case PanelsTypes.FPD10:
          return `${contractList.fpd10}%`

        case PanelsTypes.SANTANDER_AUTO_INSURANCE:
          return `${contractList?.santander_auto_insurance}%`
      }
    }
  }

  return {
    isMobile,
    isLoading,
    contractList,
    handleGoBack,
    showSpinner,
    title: isMobile ? title : contractListScreen?.title,
    tabNumber,
    description: isMobile ? description : contractListScreen?.description,
    valueOfContract: getValueOfContract(type),
    descriptionSegment: getDescriptionSegment(type),
    valueOfSegment: getValueOfSegment(type)
  }
}
