const primary = {
  50: '#FEECEF',
  100: '#FBB6C3',
  200: '#FAA0B1',
  300: '#F86D87',
  400: '#F54163',
  500: '#F3123C', // base
  600: '#C20E30',
  700: '#920B24',
  800: '#B40929',
  900: '#83071E'
}

const secondary = {
  50: '#E3F2E9',
  100: '#CEE9D8',
  200: '#B3DDB0',
  300: '#96CFAC',
  400: '#67BA61',
  500: '#4FAE73', // base
  600: '#34872E',
  700: '#276523',
  800: '#306946',
  900: '#20462E',
  arc: '#a7ce94'
}

const tertiary = {
  50: '#F3FAFB',
  100: '#CDE9EF',
  200: '#BDE3EA',
  300: '#9ED5E0',
  400: '#7BC6D5',
  500: '#5AB8CA', // base
  600: '#4893A2',
  700: '#366E79',
  800: '#2E7F8F',
  900: '#215D68'
}

const gray = {
  30: '#FAFAFA',
  50: '#F5F5F5',
  70: '#F3F5F8',
  100: '#DBDBDB',
  200: '#C2C2C2',
  300: '#A8A8A8',
  400: '#8F8F8F',
  500: '#737373', // base
  600: '#595959',
  700: '#404040',
  750: '#333333',
  800: '#2E2E2E',
  900: '#0D0D0D',
  dark: '#4D4D4D'
}

const yellow = {
  100: '#FFF1D5',
  200: '#FEE3AB',
  300: '#FED580',
  400: '#FDC756',
  500: '#FDB92C', // base
  600: '#CB9423',
  700: '#986F1A',
  800: '#664B12',
  900: '#332609'
}

export const colors = {
  background: {
    base: '#f5f5f5',
    light: '#f5f5f5',
    modal: 'rgba(64, 64, 64, 0.5)'
  },
  'bronze-gradient': {
    50: '#DF7615',
    100: '#CC7333'
  },
  error: {
    100: '#F7E2E2',
    200: '#EFC5C5',
    300: '#E8A9A9',
    400: '#E08C8C',
    500: '#D86F6F', //base
    600: '#B25A5A',
    700: '#8C4545',
    800: '#653131',
    900: '#3F1C1C'
  },
  gray,
  'gray-gradient': {
    50: '#8C8D92',
    100: '#646470',
    200: '#525159'
  },
  primary,
  'red-gradient': {
    50: '#F3123C',
    100: '#CC0000',
    200: '#FF393C'
  },
  secondary,
  tertiary,
  yellow,
  'header-gradient': {
    50: '#494848',
    100: '#393939',
    200: '#070707'
  },
  'tabs-yellow': {
    50: '#FBAD0A',
    100: '#BC9057'
  }
}
