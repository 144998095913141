import { useSendAuthCode } from 'src/hooks/useSendAuthCode'
import { RootStackScreenComponent } from 'src/navigation'
import { AuthCodeScreen } from 'templates'

import { useFirstLoginAuthCodeScreen } from './useFirstLoginAuthCodeScreen'

export const FirstLoginAuthCodeScreen: RootStackScreenComponent<'FirstLoginAuthCode'> = ({
  navigation,
  route
}) => {
  const { resetPasswordMutation, submit } = useFirstLoginAuthCodeScreen({
    navigation,
    route
  })

  const { handleSendAuthCodeSms, isLoadingAuthSendCodeSms } = useSendAuthCode()

  return (
    <AuthCodeScreen
      isSubmitting={resetPasswordMutation.isLoading}
      submit={submit}
      handleSendAuthCodeSms={handleSendAuthCodeSms}
      isLoadingAuthSendCodeSms={isLoadingAuthSendCodeSms}
    />
  )
}
