import { useMemo } from 'react'

import { useGetNotReadNotificationsQuery } from 'integration/resources/notifications'

export const useNotifications = () => {
  const { data } = useGetNotReadNotificationsQuery()

  const amountToRead = useMemo(
    () => data?.data.data.not_read_count ?? 0,
    [data?.data.data.not_read_count]
  )

  return {
    hasNotifications: amountToRead > 0,
    amountToRead
  }
}
