import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { IntegrationProfileScreenComponent } from './IntegrationProfileScreen.types'
import { useIntegrationProfileScreen } from './useIntegrationProfileScreen'

const IntegrationProfileScreenDesktop = lazy(() => import('./IntegrationProfileScreen.desktop'))

const IntegrationProfileScreenMobile = lazy(() => import('./IntegrationProfileScreen.mobile'))

export const IntegrationProfileScreen: IntegrationProfileScreenComponent = ({ navigation }) => {
  const integrationProfileScreen = useIntegrationProfileScreen({ navigation })

  const Screen = integrationProfileScreen.isMobile
    ? IntegrationProfileScreenMobile
    : IntegrationProfileScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...integrationProfileScreen} />
    </Suspense>
  )
}
