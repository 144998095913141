import { FunctionComponent, memo } from 'react'

import Icons from 'atoms/Icons'
import { parseISO, formatRelative } from 'date-fns'
import ptBR from 'date-fns/esm/locale/pt-BR/index.js'
import { TNotification } from 'integration/resources/notifications'
import { VStack, Heading, Text, Box, HStack, Circle } from 'native-base'

export const NewsCard: FunctionComponent<TNotification> = memo(
  ({ title, description, created_at }) => {
    const firstDate = created_at ? parseISO(created_at) : new Date()

    const secondDate = new Date()

    const relativeDate = formatRelative(firstDate, secondDate, { locale: ptBR })

    const relativeDateCapitalize = relativeDate.charAt(0).toUpperCase() + relativeDate.slice(1)

    return (
      <VStack w="327px" p={4} bg="white" borderRadius={20}>
        <Box>
          <HStack space={4} alignItems="center">
            <Circle size="48px" bg="gray.50" borderColor="background.base" borderWidth="1px">
              <Icons.FlameSantander size={6} color="gray.700" />
            </Circle>
            <VStack>
              <Heading
                fontSize="14px"
                lineHeight="18px"
                fontWeight="bold"
                color="gray.700"
                isTruncated
                {...(title?.length > 40 && { maxW: '85%' })}
                w="full"
                numberOfLines={1}>
                {title}
              </Heading>
              <Text
                fontSize="12px"
                lineHeight="16px"
                fontWeight="normal"
                color="gray.400"
                mt={1}
                isTruncated
                w="230px">
                {description}
              </Text>
              <Text
                fontSize="12px"
                lineHeight="16px"
                fontWeight="normal"
                color="gray.400"
                mt={1}
                numberOfLines={1}>
                {relativeDateCapitalize}
              </Text>
            </VStack>
          </HStack>
        </Box>
      </VStack>
    )
  }
)
