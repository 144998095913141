import { useCallback, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  useGetWalletsManagementDetailsQuery,
  useOpenWalletsPeriodMutation
} from 'integration/resources/walletsManagement'
import { useToast, useDisclose } from 'native-base'
import { Toast } from 'organisms'
import { SubmitHandler, useForm } from 'react-hook-form'
import { capitalizeText } from 'src/utils'
import * as yup from 'yup'

import { TOpenWalletsPeriodForm, UseOpenWalletsPeriodScreen } from './OpenWalletsPeriodScreen.types'

export const openWalletsPeriodFormSchema = yup.object().shape({
  date_start: yup
    .string()
    .required('A data de abertura é obrigatória.')
    .test(
      'is-before-end',
      'A data de abertura deve ser anterior à data de encerramento.',
      function (startDate) {
        const endDate = this.parent.date_end

        return !startDate || !endDate || new Date(startDate) < new Date(endDate)
      }
    ),
  date_end: yup
    .string()
    .required('A data de encerramento é obrigatória.')
    .test(
      'is-after-opening',
      'A data de fechamento deve ser maior que a data de abertura.',
      function (closingDate) {
        const { date_start } = this.parent

        return !date_start || !closingDate || new Date(closingDate) > new Date(date_start)
      }
    )
})

export const useOpenWalletsPeriodScreen: UseOpenWalletsPeriodScreen = ({ navigation, route }) => {
  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  const leaderStdCode = route.params.leaderStdCode

  const { data: walletsManagementDetailsData } = useGetWalletsManagementDetailsQuery({
    leader_std_code: Number(leaderStdCode ?? 0)
  })

  const leaderName = capitalizeText(walletsManagementDetailsData?.data.data.leader_user_name ?? '')

  const lastFinished = walletsManagementDetailsData?.data.data.finished_at

  const {
    isOpen: goBackModalIsOpen,
    onClose: goBackModalOnClose,
    onOpen: goBackModalOnOpen
  } = useDisclose()

  const {
    isOpen: finishModalIsOpen,
    onClose: finishModalOnClose,
    onOpen: finishModalOnOpen
  } = useDisclose()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<TOpenWalletsPeriodForm>({
    mode: 'onChange',
    resolver: yupResolver(openWalletsPeriodFormSchema),
    defaultValues: {
      date_start: '',
      date_end: ''
    }
  })

  const { mutate: openWalletsPeriodMutate, isLoading } = useOpenWalletsPeriodMutation()

  const toast = useToast()

  const onSubmit = handleSubmit(
    useCallback<SubmitHandler<TOpenWalletsPeriodForm>>(
      (data) => {
        const formattedData = {
          date_start: data.date_start,
          date_end: data.date_end,
          ...(leaderStdCode && {
            leader_std_code: Number(leaderStdCode)
          })
        }

        openWalletsPeriodMutate(formattedData, {
          onError: () => {
            toast.show({
              render: () => <Toast type="error" text="Não foi possível completar a ação " />,
              placement: 'bottom',
              duration: 1500
            })

            handleGoBack()
          },
          onSuccess: () => {
            toast.show({
              render: () => <Toast type="success" text="Período alterado 🎉" />,
              placement: 'bottom',
              duration: 1500
            })

            handleGoBack()
          }
        })
      },
      [openWalletsPeriodMutate, handleGoBack, toast, leaderStdCode]
    )
  )

  const isIndividualOpening = useMemo(() => !!leaderStdCode, [leaderStdCode])

  return {
    handleGoBack,
    isLoading,
    control,
    handleSubmit: onSubmit,
    errors,
    isValid,
    goBackModalIsOpen,
    goBackModalOnClose,
    goBackModalOnOpen,
    finishModalIsOpen,
    finishModalOnClose,
    finishModalOnOpen,
    onConfirmSubmit: onSubmit,
    isIndividualOpening,
    leaderName,
    lastFinished
  }
}
