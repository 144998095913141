export const ACCEPTED_FILE_EXTENSION = [
  'pdf',
  'csv',
  'docx',
  'doc',
  'msword',
  'xlsx',
  'xlsm',
  'xls',
  'pptx',
  'ppt',
  'png',
  'jpeg',
  'jpg',
  'mp4',
  'mp3',
  'zip'
]
