type FilterValues = boolean | string | number | any[] | undefined

type Filters = {
  [key: string]: FilterValues
}

const propertyMatchesCondition = (value: FilterValues): boolean => {
  if (typeof value === 'boolean') {
    return value === true
  } else if (Array.isArray(value)) {
    return value.length > 0
  } else if (typeof value === 'string') {
    return value.trim() !== ''
  }

  return false
}

export const calculateActiveFilterCount = (filters: Filters): number => {
  return Object.keys(filters)
    .filter((property) => !property.startsWith('all'))
    .map((property) => filters[property])
    .filter(propertyMatchesCondition).length
}
