import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { HirePlusScreenComponent } from './HirePlusScreen.types'
import { useHirePlusScreen } from './useHirePlusScreen'

const HirePlusScreenDesktop = lazy(() => import('./HirePlusScreen.desktop'))

const HirePlusScreenMobile = lazy(() => import('./HirePlusScreen.mobile'))

export const HirePlusScreen: HirePlusScreenComponent = ({ navigation, route }) => {
  const props = useHirePlusScreen({ navigation, route })

  const Screen = props.isMobile ? HirePlusScreenMobile : HirePlusScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
