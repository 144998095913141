import { FunctionComponent } from 'react'

import { ITheme, useToken } from 'native-base'
import { Leaves } from 'native-base/lib/typescript/theme/base/types'
import { Path, Svg } from 'react-native-svg'
import { resize } from 'src/utils'

type DollarIconProps = {
  color?: Leaves<ITheme['colors']>
  size?: number
}

export const DollarIcon: FunctionComponent<DollarIconProps> = ({
  size = 24,
  color = 'gray.700'
}) => {
  const { height, width } = resize({ height: 25, size, width: 24 })

  const [colorPick] = useToken('colors', [color])

  return (
    <Svg {...{ viewBox: '0 0 24 25', width, height }}>
      <Path
        d="M8.24834 7.26005C8.88103 6.62736 9.75454 6.29789 10.6459 6.35377H15.4746C16.1333 6.35377 16.6673 6.88779 16.6673 7.54654C16.6673 8.20529 16.1333 8.73931 15.4746 8.73931H10.604C10.5723 8.73931 10.5407 8.73805 10.5091 8.73552C10.2961 8.71852 10.0863 8.79577 9.93517 8.94689C9.78405 9.098 9.7068 9.30778 9.7238 9.52081C9.72611 9.54966 9.72736 9.57857 9.72756 9.60751C9.72761 9.6077 9.72768 9.60794 9.72776 9.60822C9.73005 9.61657 9.74313 9.66426 9.80769 9.75586C9.89383 9.87806 10.0582 10.0589 10.3613 10.2997C10.928 10.7501 11.7096 11.2041 12.6007 11.7182L12.6501 11.7467C13.4816 12.2263 14.412 12.7629 15.1323 13.3354C15.7998 13.8658 16.6616 14.7104 16.6673 15.8754C16.6806 16.7474 16.34 17.5878 15.7231 18.2047C15.1067 18.8212 14.267 19.1617 13.3957 19.1489H8.53482C7.87607 19.1489 7.34205 18.6149 7.34205 17.9562C7.34205 17.2974 7.87607 16.7634 8.53482 16.7634L13.4273 16.7635C13.6553 16.7677 13.8752 16.679 14.0363 16.5179C14.1975 16.3567 14.2862 16.1368 14.282 15.9089L14.2818 15.8954C14.2817 15.8952 14.2816 15.8949 14.2815 15.8945C14.2792 15.8861 14.2662 15.8384 14.2016 15.7468C14.1155 15.6246 13.9512 15.4438 13.6481 15.203C13.0814 14.7526 12.2998 14.2986 11.4087 13.7846L11.3593 13.7561C10.5278 13.2764 9.59738 12.7398 8.87705 12.1673C8.21672 11.6426 7.36699 10.811 7.34258 9.66593C7.28411 8.77172 7.61366 7.89473 8.24834 7.26005Z"
        fill={colorPick}
      />
      <Path
        d="M12.0053 3.75C11.3465 3.75 10.8125 4.28402 10.8125 4.94277V7.54518C10.8125 8.20393 11.3465 8.73795 12.0053 8.73795C12.664 8.73795 13.1981 8.20393 13.1981 7.54518V4.94277C13.1981 4.28402 12.664 3.75 12.0053 3.75ZM12.0053 16.7621C11.3465 16.7621 10.8125 17.2961 10.8125 17.9548V20.5572C10.8125 21.216 11.3465 21.75 12.0053 21.75C12.664 21.75 13.198 21.216 13.198 20.5572V17.9548C13.198 17.2961 12.664 16.7621 12.0053 16.7621Z"
        fill={colorPick}
      />
    </Svg>
  )
}
