import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Icons } from 'atoms'
import { HeadPage } from 'atoms/web/HeadPage'
import { HStack, VStack, View, IconButton } from 'native-base'
import Layouts from 'organisms/Layouts'
import { FlatList, RefreshControl } from 'react-native'
import useDebounce from 'src/hooks/useDebounce'

import { ListActivities } from './components/ListActivities'
import { ListActivitiesByDate } from './components/ListActivitiesByDate'
import { ListActivitiesByPeriod } from './components/ListActivitiesByPeriod'
import { ListActivitiesByWeek } from './components/ListActivitiesByWeek'
import { ListActivitiesConcluded } from './components/ListActivitiesConcluded'
import { ListActivitiesNotConcluded } from './components/ListActivitiesNotConcluded'
import { ViewTypeSheet } from './components/ViewTypeSheet'
import {
  ListTypesRef,
  ListTypesView,
  OrganizationScreenActivityComponent
} from './OrganizationScreen.types'
import { useOrganizationScreen } from './useOrganizationScreen'

const OrganizationScreenActivityDesktop: OrganizationScreenActivityComponent = ({
  navigation,
  route
}) => {
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search, 500)

  const {
    handleToggleActivitiesTypeView,
    handleToggleAscOrDesc,
    currentSelectedDate,
    currentSelectedPeriod,
    viewTypeSheetRef,
    currentTab,
    isLoading,
    ascOrDesc,
    viewTypes,
    viewType
    // @ts-ignore
  } = useOrganizationScreen({ navigation, route })

  const currentViewRef = useRef<ListTypesRef>(null)

  const openCreateActivity = useCallback(() => {
    navigation.navigate('OrganizationActivityCreate', {})
  }, [navigation])

  const ActivitiesViews = {
    1: ListActivitiesByWeek,
    2: ListActivities,
    3: ListActivitiesByDate,
    4: ListActivitiesConcluded,
    12: ListActivitiesNotConcluded,
    11: ListActivitiesByPeriod
  }

  const selectedActivityView = viewType.group as 1 | 2 | 3 | 4 | 11 | 12

  const CurrentActivityView = ActivitiesViews[selectedActivityView]

  const CurrentView = CurrentActivityView

  const keyExtractor = useCallback(
    (item: { component: React.ElementType; key: string }) => item.key,
    []
  )

  const data = [
    {
      component: CurrentView,
      key: '1'
    }
  ]

  const renderItem = useCallback(
    (
      { item }: { item: { component: React.FunctionComponent<ListTypesView>; key: string } },
      index: number
    ) => {
      const Component = item.component as
        | React.FunctionComponent<ListTypesView>
        | React.ForwardRefExoticComponent<ListTypesView & React.RefAttributes<ListTypesRef>>

      return (
        <View bg="background.base" flex={1} minH={600} pb={30} key={`${index}-${item.key}`}>
          <Component
            ref={currentViewRef}
            isLoading={isLoading}
            ascOrDesc={ascOrDesc}
            //@ts-ignore
            navigation={navigation}
            handleSelect={handleToggleActivitiesTypeView}
            data={{
              schedule_date_start:
                selectedActivityView === 11
                  ? currentSelectedPeriod?.startingDay
                  : currentSelectedDate,
              schedule_date_end:
                selectedActivityView === 11 ? currentSelectedPeriod?.endingDay : currentSelectedDate
            }}
          />
        </View>
      )
    },
    [
      ascOrDesc,
      currentSelectedDate,
      currentSelectedPeriod?.endingDay,
      currentSelectedPeriod?.startingDay,
      handleToggleActivitiesTypeView,
      isLoading,
      navigation,
      selectedActivityView
    ]
  )

  useEffect(() => {
    if (currentViewRef.current) {
      currentViewRef.current.search(debouncedSearch)
    }
  }, [debouncedSearch, currentViewRef])

  return (
    <Layouts.InternalDesktop onSearch={setSearch} search={search}>
      <View h="full" flex={1} px={8}>
        <FlatList
          data={data}
          keyExtractor={keyExtractor}
          ListHeaderComponent={
            <View>
              <HeadPage
                title="Tarefas"
                leftIcon={<Icons.Add size="20px" color="white" />}
                actionButtonTitle="Nova tarefa"
                actionButtonPress={openCreateActivity}
                pages={[
                  {
                    id: 1,
                    title: 'Minha organização',
                    link: 'minha-organizacao/tarefas',
                    params: null
                  },
                  {
                    id: 2,
                    title: 'Tarefas',
                    link: 'minha-organizacao/tarefas',
                    params: null
                  }
                ]}
              />

              <VStack bg="background.base" mt={8} borderTopRadius={32} pb={2}>
                <>
                  <HStack bgColor="background.base" ml={1} justifyContent="space-between">
                    <ViewTypeSheet
                      ref={viewTypeSheetRef}
                      viewTypes={viewTypes}
                      currentViewType={viewType}
                      handleSelect={handleToggleActivitiesTypeView}
                    />

                    {viewType.group === 1 && currentTab === 1 && (
                      <View flexDir="row" flex="auto" ml={6}>
                        <IconButton
                          bg="white"
                          _pressed={{
                            bg: 'gray.50',
                            opacity: 0.9
                          }}
                          borderRadius="full"
                          isDisabled={isLoading}
                          onPress={handleToggleAscOrDesc}
                          w={10}
                          h={10}
                          icon={<Icons.ChangeOrder color="gray.700" size={5} />}
                        />
                      </View>
                    )}
                  </HStack>
                </>
              </VStack>
            </View>
          }
          showsHorizontalScrollIndicator={false}
          decelerationRate="normal"
          scrollEventThrottle={16}
          onEndReached={() => {
            if (currentViewRef.current) {
              currentViewRef.current?.fetchMore?.()
            }
          }}
          flexShrink={1}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={currentViewRef.current?.refreshing ?? false}
              onRefresh={() => {
                currentViewRef.current?.refetch?.()
              }}
              tintColor="white"
            />
          }
          // @ts-ignore
          renderItem={renderItem}
        />
      </View>
    </Layouts.InternalDesktop>
  )
}

export default OrganizationScreenActivityDesktop
