import { useEffect, useRef, useState } from 'react'

import { useNavigation } from '@react-navigation/native'
import { useBreakpointValue, useTheme } from 'native-base'
import { Animated, Dimensions, FlatList, StyleSheet } from 'react-native'

import { UseAboutScreen } from './AboutScreen.types'

export const useAboutScreen: UseAboutScreen = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const navigation = useNavigation()

  const theme = useTheme()

  const currentDate = new Date()

  const currentYear = currentDate.getFullYear()

  const mobileMarginX = 88

  const screenWidth = Dimensions.get('window').width - mobileMarginX

  const desktopContentWidth = 465

  const desktopContentHeight = 322

  const contentWidth = isMobile ? screenWidth : desktopContentWidth

  const [currentIndex, setCurrentIndex] = useState(0)

  const totalSlides = isMobile ? 6 : 5

  const flatListRef = useRef<FlatList>(null)

  useEffect(() => {
    animateDots(currentIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex])

  const goToSlide = (index: number) => {
    if (flatListRef.current && index >= 0 && index < totalSlides) {
      flatListRef.current.scrollToIndex({ index, animated: true })
    }
  }

  const updateCurrentIndex = (event: any) => {
    const contentOffsetX = event.nativeEvent.contentOffset.x

    const activeIndex = Math.round(contentOffsetX / contentWidth)

    setCurrentIndex(activeIndex)
  }

  const getItemLayout = (data: any, index: number) => ({
    length: contentWidth,
    offset: contentWidth * index,
    index
  })

  const animatedValues = useRef(
    new Array(totalSlides).fill(null).map((_, index) => new Animated.Value(index === 0 ? 1 : 0))
  ).current

  const animateDots = (newIndex: any) => {
    Animated.parallel(
      animatedValues.map((value, index) =>
        Animated.timing(value, {
          toValue: index === newIndex ? 1 : 0,
          duration: 200,
          useNativeDriver: false
        })
      )
    ).start()
  }

  const dotStyles = animatedValues.map((animatedValue) => ({
    width: 8,
    height: 8,
    borderRadius: 5,
    marginHorizontal: 4,
    backgroundColor: animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [theme.colors.gray[500], theme.colors.primary[500]]
    }),
    transform: [
      {
        scale: animatedValue.interpolate({
          inputRange: [0, 1],
          outputRange: [1, 1.25]
        })
      }
    ]
  }))

  const styles = StyleSheet.create({
    linearGradient: {
      height: '100%',
      width: '100%'
    },
    slide: {
      width: contentWidth,
      height: isMobile ? undefined : desktopContentHeight,
      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })

  return {
    isMobile,
    currentYear,
    theme,
    navigation,
    flatListRef,
    updateCurrentIndex,
    screenWidth,
    goToSlide,
    currentIndex,
    animatedValues,
    dotStyles,
    totalSlides,
    styles,
    getItemLayout,
    desktopContentWidth
  }
}
