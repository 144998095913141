import { FunctionComponent, useCallback } from 'react'

import { Icons, Illustrations } from 'atoms'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
  Actionsheet,
  useBreakpointValue,
  Modal,
  VStack,
  Text,
  Button,
  Heading,
  Center
} from 'native-base'
import { useQueryClient } from 'react-query'
import { useSetAuthAtom } from 'src/store/auth'
import { LogEventUserId } from 'src/utils/logEvents'

import { useSetIsOpenNewTermAtom } from './BottomSheetNewTerm'

const isOpenRefuseNewTermAtom = atom(false)

export const useIsOpenRefuseNewTermAtom = () => useAtom(isOpenRefuseNewTermAtom)

export const useIsOpenRefuseNewTermAtomValue = () => useAtomValue(isOpenRefuseNewTermAtom)

export const useSetIsOpenRefuseNewTermAtom = () => useSetAtom(isOpenRefuseNewTermAtom)

const RefuseNewTermContent: FunctionComponent = () => {
  const setIsOpenRefuseNewTermAtom = useSetIsOpenRefuseNewTermAtom()

  const setIsOpenNewTermAtom = useSetIsOpenNewTermAtom()

  const setAuthAtom = useSetAuthAtom()

  const queryClient = useQueryClient()

  const onExit = useCallback(() => {
    LogEventUserId(null)

    setIsOpenNewTermAtom(false)

    setIsOpenRefuseNewTermAtom(false)

    Promise.all([setAuthAtom(null), queryClient.removeQueries()])
  }, [queryClient, setAuthAtom, setIsOpenNewTermAtom, setIsOpenRefuseNewTermAtom])

  const handleGoBack = useCallback(() => {
    setIsOpenNewTermAtom(true)

    setIsOpenRefuseNewTermAtom(false)
  }, [setIsOpenNewTermAtom, setIsOpenRefuseNewTermAtom])

  return (
    <VStack space={0}>
      <Center>
        <Illustrations.RefuseTerms width={190} height={177} />
      </Center>
      <VStack space={8}>
        <VStack space={8} mt={8}>
          <Heading textAlign="center" color="gray.700" lineHeight="30px" fontSize="24px">
            Você recusou os termos e condições e a política de privacidade
          </Heading>
          <Text textAlign="center" color="gray.500" lineHeight="30px" fontSize="18px">
            Ao recusar os termos e condições e a política de privacidade do banco Santander não será
            possível o acesso ao Portal Parceiros.
          </Text>
        </VStack>
        <VStack space={8} flexDir="row" justifyContent="space-between">
          <Button
            leftIcon={<Icons.ArrowLeftGo />}
            flex="1"
            mr="24px"
            onPress={handleGoBack}
            colorScheme="gray"
            variant="outline">
            Voltar para Termos
          </Button>
          <Button flex="1" onPress={onExit} colorScheme="gray">
            Sair
          </Button>
        </VStack>
      </VStack>
    </VStack>
  )
}

export const BottomSheetRefuseNewTerm: FunctionComponent = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const isOpen = useIsOpenRefuseNewTermAtomValue()

  return isDesktop ? (
    <Modal isOpen={isOpen}>
      <Modal.Content minWidth="640px" minH={594} px={8} py={16}>
        <Modal.Body p={0}>
          <RefuseNewTermContent />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  ) : (
    <Actionsheet hideDragIndicator isOpen={isOpen} onClose={() => {}}>
      <Actionsheet.Content>
        <RefuseNewTermContent />
      </Actionsheet.Content>
    </Actionsheet>
  )
}
