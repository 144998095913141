import { RouteProp, useRoute } from '@react-navigation/native'
import { AxiosError, AxiosResponse } from 'axios'
import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import {
  ActivitiesByWeekResponse,
  ActivitiesResponse,
  ActivitiesType,
  Activity,
  ActivityByPeriod,
  ActivityByPeriodParams,
  CreateActivityData,
  GetListParams
} from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_ACTIVITY_LIST_QUERY = 'getActivityList'

export const KEY_ACTIVITY_LIST_BY_DATE_QUERY = 'getActivityListByDate'

export const KEY_ACTIVITY_TYPES_QUERY = 'getActivityTypes'

export const KEY_ACTIVITY_ITEM_QUERY = 'getActivityItem'

export const KEY_ACTIVITY_LIST_BY_WEEK_QUERY = 'getActivityListByWeek'

export const KEY_ACTIVITIES_BY_PERIOD_LIST_QUERY = 'getActivitiesByPeriod'

export const useGetActivityListInfiniteQuery = (
  params: GetListParams,
  onSuccess?: (data: InfiniteData<AxiosResponse<ResponseSuccess<ActivitiesResponse>, any>>) => void
) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<ActivitiesResponse>>, AxiosError<ResponseError>>(
    [KEY_ACTIVITY_LIST_QUERY],
    ({ pageParam }) => requests.getActivityList({ ...params, page: pageParam }),
    {
      enabled: true,
      cacheTime: 0,
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false,
      onSuccess
    }
  )

export const useGetActivityListByDateInfiniteQuery = (
  params: GetListParams,
  onSuccess?: (data: InfiniteData<AxiosResponse<ResponseSuccess<ActivitiesResponse>, any>>) => void
) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<ActivitiesResponse>>, AxiosError<ResponseError>>(
    [KEY_ACTIVITY_LIST_BY_DATE_QUERY, params.schedule_date_start, params.schedule_date_end],
    ({ pageParam }) => requests.getActivityList({ ...params, page: pageParam }),
    {
      enabled: true,
      cacheTime: 0,
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false,
      onSuccess
    }
  )

export const useGetActivityListByWeekInfiniteQuery = (
  params: GetListParams,
  onSuccess?: (
    data: InfiniteData<AxiosResponse<ResponseSuccess<ActivitiesByWeekResponse>, any>>
  ) => void
) =>
  useInfiniteQuery<
    AxiosResponse<ResponseSuccess<ActivitiesByWeekResponse>>,
    AxiosError<ResponseError>
  >(
    [KEY_ACTIVITY_LIST_BY_WEEK_QUERY],
    ({ pageParam }) => requests.getActivityListByWeek({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false,
      onSuccess
    }
  )

export const useGetActivityTypesInfiniteQuery = (params: GetListParams) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<ActivitiesType[]>>, AxiosError<ResponseError>>(
    [KEY_ACTIVITY_TYPES_QUERY],
    ({ pageParam }) => requests.getActivityTypes({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )

export const useGetActivityItemQuery = (
  id: string,
  onSuccess?: (data: AxiosResponse<ResponseSuccess<Activity>>) => void
) =>
  useQuery<AxiosResponse<ResponseSuccess<Activity>>, AxiosError<ResponseError>>(
    [`${KEY_ACTIVITY_ITEM_QUERY}.${id}`],
    () => requests.getActivityItem(id),
    {
      onSuccess(data) {
        onSuccess?.(data)
      }
    }
  )

export const useCreateActivityMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<CreateActivityData>>,
    AxiosError<ResponseError>,
    Partial<CreateActivityData>
  >(requests.createActivity, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_ACTIVITY_LIST_BY_WEEK_QUERY])

      queryClient.invalidateQueries([KEY_ACTIVITY_LIST_QUERY])
    }
  })
}

export const useUpdateActivityMutation = (id: string) => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<CreateActivityData>>,
    AxiosError<ResponseError>,
    Partial<CreateActivityData>
  >(requests.updateActivity, {
    onSuccess: () => {
      queryClient.invalidateQueries([`${KEY_ACTIVITY_ITEM_QUERY}.${id}`])

      queryClient.invalidateQueries([KEY_ACTIVITY_LIST_QUERY])

      queryClient.invalidateQueries([KEY_ACTIVITY_LIST_BY_WEEK_QUERY])
    }
  })
}

export const useFinishedActivityMutation = () => {
  const queryClient = useQueryClient()

  const { params } = useRoute<RouteProp<{ params: { id: string } }>>()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<Activity, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<Activity, 'id'>
  >(requests.finishedActivity, {
    onSuccess: () => {
      queryClient.invalidateQueries([`${KEY_ACTIVITY_ITEM_QUERY}.${params.id}`])

      queryClient.invalidateQueries([KEY_ACTIVITY_LIST_BY_WEEK_QUERY])

      queryClient.invalidateQueries([KEY_ACTIVITY_LIST_QUERY])
    }
  })
}

export const useUnFinishedActivityMutation = () => {
  const queryClient = useQueryClient()

  const { params } = useRoute<RouteProp<{ params: { id: string } }>>()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<Activity, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<Activity, 'id'>
  >(requests.unFinishedActivity, {
    onSuccess: () => {
      queryClient.invalidateQueries([`${KEY_ACTIVITY_ITEM_QUERY}.${params.id}`])

      queryClient.invalidateQueries([KEY_ACTIVITY_LIST_BY_WEEK_QUERY])

      queryClient.invalidateQueries([KEY_ACTIVITY_LIST_QUERY])
    }
  })
}

export const useDeleteActivityMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<Activity, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<Activity, 'id'>
  >(requests.deleteActivity, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_ACTIVITY_LIST_BY_WEEK_QUERY])

      queryClient.invalidateQueries([KEY_ACTIVITY_LIST_QUERY])
    }
  })
}

export const useGetActivitiesByPeriodQuery = (params?: ActivityByPeriodParams) =>
  useQuery<AxiosResponse<ResponseSuccess<ActivityByPeriod[]>>, AxiosError<ResponseError>>(
    KEY_ACTIVITIES_BY_PERIOD_LIST_QUERY,
    ({ pageParam }) => requests.getActivitiesByPeriod({ ...params, page: pageParam }),
    {
      enabled: false,
      cacheTime: 0,
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )
