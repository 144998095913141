import { UseOpportunityInfos } from './OpportunityInfos.types'

export const useOpportunityInfos: UseOpportunityInfos = ({
  opportunityDetails,
  isLoading,
  handleGoToTabDetails,
  isMobile,
  opportunityType
}) => {
  return {
    opportunityDetails,
    isLoading,
    handleGoToTabDetails,
    isMobile,
    opportunityType
  }
}
