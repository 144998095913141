import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { GoalsLeadersListScreenComponent } from './GoalsLeadersListScreen.types'
import { useGoalsLeadersListScreen } from './useGoalsLeadersListScreen'

const GoalsLeadersListScreenDesktop = lazy(() => import('./GoalsLeadersListScreen.desktop'))

export const GoalsLeadersListScreen: GoalsLeadersListScreenComponent = ({ navigation, route }) => {
  const props = useGoalsLeadersListScreen({ navigation, route })

  const Screen = GoalsLeadersListScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
