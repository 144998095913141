import { useGetSubsegmentQuery } from 'integration/resources/store'

import { UseBottomSheetSubsegment } from './types'

export const useBottomSheetSubsegment: UseBottomSheetSubsegment = () => {
  const { data: subsegments, isLoading: isLoadingSubsegments } = useGetSubsegmentQuery()

  return {
    subsegments,
    isLoadingSubsegments
  }
}
