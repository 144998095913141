import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { Cards, Illustrations, LoadingTurbo } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { LinearGradient } from 'expo-linear-gradient'
import {
  Box,
  Center,
  HStack,
  Skeleton,
  VStack,
  ZStack,
  useTheme,
  Text,
  useBreakpointValue,
  Divider
} from 'native-base'
import { useWindowDimensions } from 'react-native'
import { formatDateStringToMask, getRelativeDay } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

import { useListActivitiesByWeek } from './hook'
import { ListTypesRef, ListTypesView } from '../../OrganizationScreen.types'
import TitleByDay from '../TitleByDay'

export const ListActivitiesByWeek = forwardRef<ListTypesRef, ListTypesView>(
  ({ navigation, isLoading, ascOrDesc }, ref) => {
    const [search, setSearch] = useState('')

    const {
      list,
      refreshing,
      handleComplete,
      isFetchingNextPage,
      isLoading: localIsLoading,
      fetchMore,
      refetch
    } = useListActivitiesByWeek({
      search
    })

    const skeletonArray = new Array(6).fill(0)

    useImperativeHandle(ref, () => ({
      refreshing,
      fetchMore,
      refetch,
      search: (value: string) => {
        setSearch(value)
      },
      clearSearch: () => setSearch('')
    }))

    const { width, height } = useWindowDimensions()

    const theme = useTheme()

    const isMobile = useBreakpointValue({ base: true, lg: false })

    const [listItems, setListItems] = useState(list?.items)

    useEffect(() => {
      if (list?.items) {
        // eslint-disable-next-line @babel/no-unused-expressions
        ascOrDesc === 'asc' ? setListItems(list.items) : setListItems([...list.items].reverse())
      }
    }, [ascOrDesc, list, list?.items])

    return (
      <VStack {...(isMobile && { px: 4 })}>
        <Box>
          {(localIsLoading || isLoading) && (
            <VStack py={4} w={width - 32}>
              <HStack justifyContent="space-between">
                <Skeleton h="10" w="200px" rounded="full" />
                <Skeleton h="6" w="100px" rounded="full" startColor="gray.500" />
              </HStack>

              {skeletonArray.map((_, i) => (
                <SkeletonCard key={`key-skeleton-${i}`} />
              ))}
            </VStack>
          )}

          {!list?.items.length && !localIsLoading && (
            <Box mt={-2} alignItems="center" justifyContent="center">
              <Illustrations.ActivitiesEmpty />
              <Text fontSize="md" lineHeight="24px" color="gray.500">
                Ainda não há tarefas na sua organização.
              </Text>
            </Box>
          )}

          {!isLoading &&
            !localIsLoading &&
            listItems?.map((activityItem, index) => (
              <VStack
                key={`${activityItem.day}-${index}`}
                flexDirection={isMobile ? 'column' : 'row'}
                space={2}
                mt={isMobile ? 4 : 8}>
                <TitleByDay
                  title={getRelativeDay(activityItem.day)}
                  subtitle={
                    activityItem.day ? formatDateStringToMask(activityItem.day, 'iiii') : ''
                  }
                  size={activityItem.activities.length}
                  type={`Tarefa${activityItem.activities.length > 1 ? 's' : ''}`}
                  showTotal={isMobile}
                />

                <VStack
                  flex={1}
                  rounded={26}
                  ml={{ lg: 4 }}
                  space={{ base: 2, lg: undefined }}
                  bg={{ base: undefined, lg: 'white' }}>
                  {activityItem.activities.map((activity, index) => (
                    <Box key={`key-${index}-${activity.title}`}>
                      <Cards.OrganizationCard
                        expandedDetail
                        isLoadingFavorite={isLoading}
                        cover={activity.store?.logo_image?.url}
                        topText={activity?.title}
                        organizationType={activity?.activity_type?.name}
                        nameFantasy={activity?.store?.name_fantasy}
                        tabNumber={activity?.store?.tab_number}
                        midText={
                          activity?.store?.tab_number
                            ? `TAB ${activity?.store?.tab_number}`
                            : 'Minha tarefa'
                        }
                        bottomText={
                          activity?.period_infos?.schedule_date_start &&
                          activity?.period_infos?.schedule_date_end
                            ? `${formatDateStringToMask(
                                activity?.period_infos?.schedule_date_start,
                                'dd/MM/yyyy'
                              )} até ${formatDateStringToMask(
                                activity?.period_infos?.schedule_date_end,
                                'dd/MM/yyyy'
                              )}`
                            : activity.schedule_date
                            ? formatDateStringToMask(activity?.schedule_date, 'dd/MM/yyyy')
                            : undefined
                        }
                        hour={formatDateStringToMask(activity?.schedule_date, 'HH:mm')}
                        attachments={activity?.count_activity_attachments}
                        planActions={activity?.activity_plan_action}
                        userName={activity?.user_name}
                        active={activity?.is_finished}
                        icon="Check"
                        onPress={() => {
                          LogEvent('organizacao_atividade_data', {
                            item_id: activity?.id
                          })

                          navigation.navigate('OrganizationActivity', {
                            id: String(activity?.id)
                          })
                        }}
                        onCheckPress={(callback, currentCheck) =>
                          handleComplete(callback, currentCheck, activity.id)
                        }
                        period_infos={activity?.period_infos}
                      />

                      {!isMobile &&
                        !activity?.period_infos?.schedule_date_start &&
                        index !== activityItem.activities.length - 1 && <Divider bg="gray.50" />}
                    </Box>
                  ))}
                </VStack>
              </VStack>
            ))}

          {isFetchingNextPage && (
            <Center mt={6}>
              <LoadingTurbo size={48} />
            </Center>
          )}

          {refreshing && !isLoading && (
            <>
              <LinearGradient
                style={{
                  position: 'absolute',
                  height: 800,
                  zIndex: 4,
                  flex: 1,
                  width
                }}
                colors={['#ffffff05', theme.colors.background.light, '#ffffff05']}
              />
              <ZStack
                position="absolute"
                w={width - 32}
                h={0}
                alignItems="center"
                justifyContent="center"
                top={height / 5}
                zIndex={8}>
                <Center>
                  <LoadingTurbo size={48} />
                </Center>
              </ZStack>
            </>
          )}
        </Box>
      </VStack>
    )
  }
)
