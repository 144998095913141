import { useCallback, useMemo, useRef, useState } from 'react'

import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { useLinkTo } from '@react-navigation/native'
import {
  OpportunitiesTypes,
  OpportunityType,
  useGetOpportunityDetailsActiveBaseItemQuery,
  useGetOpportunityDetailsOdcItemQuery,
  useSetFinishedOpportunityActiveBaseMutation,
  useSetFinishedOpportunityOdcMutation,
  useSetOpportunityActiveBaseHighlightedMutation,
  useSetOpportunityActiveBaseUnHighlightedMutation,
  useSetOpportunityOdcHighlightedMutation,
  useSetOpportunityOdcUnHighlightedMutation
} from 'integration/resources/newOpportunities'
import { EvaluationItem } from 'integration/resources/opportunities'
import { useBreakpointValue, useDisclose, useToast } from 'native-base'
import { Toast } from 'organisms'
import { Platform } from 'react-native'
import { formatCNPJ } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

import { UseOpportunityDetailsScreen } from './OpportunityDetailsScreen.types'
import { LeadsApnefScreenTypes } from '../LeadsApnefScreen/LeadsApnefScreen.types'

export const useOpportunityDetailsScreen: UseOpportunityDetailsScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  const opportunityId = route.params.id

  const isActiveBase = useMemo(
    () => route.params.type === OpportunityType.ACTIVE_BASE,
    [route.params.type]
  )

  const activeBaseQuery = useGetOpportunityDetailsActiveBaseItemQuery(opportunityId, isActiveBase)

  const odcItemQuery = useGetOpportunityDetailsOdcItemQuery(opportunityId, !isActiveBase)

  const isLoading = odcItemQuery.isLoading || activeBaseQuery.isLoading

  const data = isActiveBase ? activeBaseQuery.data?.data.data : odcItemQuery.data?.data.data

  const opportunityDetailsItem = useMemo(() => data, [data])

  const opportunityType = opportunityDetailsItem?.public_rating

  const handleGoBack = useCallback(
    () =>
      navigation.canGoBack() ? navigation.goBack() : linkTo('/minha-organizacao/oportunidades/5'),
    [navigation, linkTo]
  )

  const handleEvaluateConclude = () => handleOpenEvaluate()

  const handleOpenLeads = () => {
    LogEvent(`detalhes_oportunidade_acessar_leads`, {
      item_id: opportunityId
    })

    navigation.navigate('LeadsApnef', {
      opportunityId: String(opportunityId),
      type: LeadsApnefScreenTypes.Leads
    })
  }

  const handleOpenApnef = () => {
    LogEvent(`detalhes_oportunidade_acessar_apnef`, {
      item_id: opportunityId
    })

    navigation.navigate('LeadsApnef', {
      opportunityId: String(opportunityId),
      type: LeadsApnefScreenTypes.APNEF
    })
  }

  const handleOpenRecommendations = () => {
    LogEvent(`detalhes_oportunidade_acessar_recomendacoes`, {
      item_id: opportunityId
    })

    navigation.navigate('Recommendations', {
      opportunityId: String(opportunityId)
    })
  }

  const {
    isOpen: chargeListModalIsOpen,
    onOpen: handleOpenChargeListModal,
    onClose: handleCloseChargeListModal
  } = useDisclose()

  const [expandCommentDetails, setExpandCommentDetails] = useState<string[]>([''])

  const handleExpandCommentDetails = (id: string) =>
    expandCommentDetails.includes(id)
      ? setExpandCommentDetails(expandCommentDetails.filter((prevId) => prevId !== id))
      : setExpandCommentDetails((prev) => [...prev, id])

  const {
    isOpen: commentListModalIsOpen,
    onOpen: handleOpenCommentListModal,
    onClose: handleCloseCommentListModal
  } = useDisclose()

  const handleGoToTabDetails = useCallback(() => {
    LogEvent(`detalhes_oportunidade_acessar_tab`, {
      item_id: opportunityDetailsItem?.store.tab_number
    })

    navigation.navigate('WalletTab', {
      tabNumber: String(opportunityDetailsItem?.store.tab_number)
    })
  }, [navigation, opportunityDetailsItem?.store.tab_number])

  const {
    isOpen: activitiesListModalIsOpen,
    onOpen: handleOpenActivitiesListModal,
    onClose: handleCloseActivitiesListModal
  } = useDisclose()

  const handleGoToCreateActivity = useCallback(() => {
    LogEvent(`detalhes_oportunidade_acessar_criar_atividade`, {
      item_id: opportunityId
    })

    handleCloseActivitiesListModal()

    navigation.navigate('OrganizationActivityCreate', {
      opportunity_id: opportunityId
    })
  }, [navigation, opportunityId, handleCloseActivitiesListModal])

  const handleGoToContentScreen = useCallback(
    (id: string) => {
      LogEvent(`detalhes_oportunidade_acessar_conteudo`, {
        item_id: id
      })

      navigation.navigate('Content', {
        id
      })
    },
    [navigation]
  )

  const title = useMemo(
    () =>
      opportunityDetailsItem?.store.tab_number
        ? `TAB ${opportunityDetailsItem?.store.tab_number} • ${opportunityDetailsItem?.store.name_fantasy}`
        : `CNPJ ${formatCNPJ(String(opportunityDetailsItem?.store.store_cnpj))} • ${
            opportunityDetailsItem?.store.name_fantasy
          }`,
    [opportunityDetailsItem?.store]
  )

  const pageTitle = isLoading ? 'Carregando...' : title

  const hideSections = useMemo(
    () =>
      opportunityType === OpportunitiesTypes.ACCREDITATE ||
      opportunityType === OpportunitiesTypes.RE_CREDIT,
    [opportunityType]
  )

  const isAndroid = Platform.OS === 'android'

  const {
    isOpen: evaluateIsOpen,
    onOpen: handleOpenEvaluate,
    onClose: handleCloseEvaluate
  } = useDisclose()

  const evaluationSuccessSheetRef = useRef<BottomSheetModal>(null)

  const openEvaluationSuccessSheet = useCallback(() => {
    evaluationSuccessSheetRef.current?.present()
  }, [])

  const closeEvaluationSuccessSheet = useCallback(() => {
    evaluationSuccessSheetRef.current?.close()

    evaluationSuccessSheetRef.current?.dismiss()
  }, [])

  const handleGoToActivity = (activityId: string) => {
    LogEvent(`detalhes_oportunidade_acessar_atividade`, {
      item_id: activityId
    })

    return navigation.navigate('OrganizationActivity', {
      id: activityId
    })
  }

  const toast = useToast()

  const highlightHook = isActiveBase
    ? useSetOpportunityActiveBaseHighlightedMutation
    : useSetOpportunityOdcHighlightedMutation

  const unHighlightHook = isActiveBase
    ? useSetOpportunityActiveBaseUnHighlightedMutation
    : useSetOpportunityOdcUnHighlightedMutation

  const finishHook = isActiveBase
    ? useSetFinishedOpportunityActiveBaseMutation
    : useSetFinishedOpportunityOdcMutation

  const { mutateAsync: setOpportunityHighlighted, isLoading: highlightIsLoading } = highlightHook()

  const { mutateAsync: setOpportunityUnHighlighted, isLoading: unHighlightIsLoading } =
    unHighlightHook()

  const handleHighlight = (id: string, highlighted: boolean) => {
    LogEvent(`detalhes_oportunidade_${highlighted ? 'desfazer_' : ''}destacar`, {
      item_id: id
    })

    return (
      id &&
      (highlighted ? setOpportunityUnHighlighted : setOpportunityHighlighted)(
        {
          id
        },
        {
          onError: () => {
            toast.show({
              render: () => <Toast type="error" text="Tente novamente mais tarde" />,
              duration: 3000
            })
          }
        }
      )
    )
  }

  const { mutateAsync: setOpportunityFinished, isLoading: concludeIsLoading } = finishHook()

  const handleFinishEvaluate = useCallback(
    (evaluation: EvaluationItem) => {
      LogEvent(`detalhes_oportunidade_concluir`, {
        item_id: opportunityDetailsItem?.id
      })

      opportunityDetailsItem?.id &&
        setOpportunityFinished(
          {
            ...evaluation,
            id: opportunityDetailsItem?.id,
            only_finish: false
          },
          {
            onError: () => {
              toast.show({
                render: () => <Toast type="error" text="Não foi possível concluir oportunidade" />,
                duration: 3000
              })
            },
            onSuccess: () => {
              openEvaluationSuccessSheet()
            }
          }
        ).finally(() => handleCloseEvaluate())
    },
    [
      handleCloseEvaluate,
      openEvaluationSuccessSheet,
      setOpportunityFinished,
      toast,
      opportunityDetailsItem?.id
    ]
  )

  return {
    isMobile,
    isAndroid,
    opportunityDetails: opportunityDetailsItem,
    opportunityType,
    handleGoBack,
    handleEvaluateConclude,
    handleOpenLeads,
    handleOpenApnef,
    handleOpenRecommendations,
    chargeListModalIsOpen,
    handleOpenChargeListModal,
    handleCloseChargeListModal,
    expandCommentDetails,
    handleExpandCommentDetails,
    commentListModalIsOpen,
    handleOpenCommentListModal,
    handleCloseCommentListModal,
    handleGoToTabDetails,
    activitiesListModalIsOpen,
    handleOpenActivitiesListModal,
    handleCloseActivitiesListModal,
    handleGoToCreateActivity,
    handleGoToContentScreen,
    pageTitle,
    hideSections,
    evaluateIsOpen,
    handleOpenEvaluate,
    handleCloseEvaluate,
    evaluationSuccessSheetRef,
    openEvaluationSuccessSheet,
    closeEvaluationSuccessSheet,
    handleFinishEvaluate,
    isLoading,
    handleGoToActivity,
    isActiveBase,
    handleHighlight,
    highlightIsLoading: highlightIsLoading || unHighlightIsLoading,
    concludeIsLoading
  }
}
