import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ContactSearchScreenComponent } from './ContactSearchScreen.types'
import { useContactSearchScreen } from './useContactSearchScreen'

const ContactSearchScreenDesktop = lazy(() => import('./ContactSearchScreen.desktop'))

const ContactSearchScreenMobile = lazy(() => import('./ContactSearchScreen.mobile'))

export const ContactSearchScreen: ContactSearchScreenComponent = () => {
  const contactSearchScreen = useContactSearchScreen()

  const Screen = contactSearchScreen.isMobile
    ? ContactSearchScreenMobile
    : ContactSearchScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...contactSearchScreen} />
    </Suspense>
  )
}
