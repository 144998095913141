import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const AppleLogoIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      d="M21.806 16.9044C21.7596 16.8319 21.698 16.7703 21.6255 16.7239C21.5529 16.6775 21.4712 16.6474 21.386 16.6356C20.5911 16.5261 19.8573 16.1492 19.3053 15.5669C18.7534 14.9846 18.4162 14.2316 18.3494 13.4321C18.2826 12.6326 18.4902 11.8341 18.9379 11.1683C19.3856 10.5025 20.0467 10.009 20.8124 9.7692C20.9002 9.74075 20.9803 9.69243 21.0464 9.62797C21.1125 9.56352 21.1628 9.48468 21.1935 9.3976C21.2241 9.31051 21.2343 9.21752 21.2231 9.12587C21.212 9.03422 21.1798 8.94639 21.1292 8.8692C20.7623 8.21191 20.2618 7.63875 19.66 7.18662C19.0582 6.73449 18.3684 6.41341 17.6349 6.24407C16.9015 6.07473 16.1407 6.06087 15.4016 6.2034C14.6625 6.34593 13.9614 6.64167 13.3436 7.0716C13.1763 7.15564 12.9897 7.1937 12.8029 7.18189C12.616 7.17008 12.4357 7.10883 12.2804 7.0044C10.9386 6.1935 9.33217 5.94184 7.80675 6.3036C7.02543 6.5174 6.29564 6.88757 5.66158 7.39169C5.02751 7.89581 4.50237 8.52338 4.11795 9.2364C3.11966 11.0585 2.77577 13.1676 3.14355 15.2124C3.4287 17.9656 4.62274 20.5453 6.53715 22.5444C7.2362 23.2157 8.1514 23.6163 9.11886 23.6743C10.0863 23.7324 11.0428 23.4441 11.8172 22.8612C12.0892 22.6558 12.4207 22.5447 12.7616 22.5447C13.1024 22.5447 13.4339 22.6558 13.706 22.8612C14.4282 23.4027 15.3064 23.6957 16.2092 23.6964C17.2091 23.7063 18.1735 23.3257 18.8972 22.6356C20.3426 21.1872 21.3722 19.3768 21.878 17.394C21.9016 17.312 21.9074 17.2258 21.895 17.1414C21.8826 17.0569 21.8522 16.9761 21.806 16.9044Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      d="M15.4748 5.058L17.9804 2.5644C18.1197 2.42446 18.23 2.25845 18.3052 2.07586C18.3803 1.89327 18.4188 1.69766 18.4183 1.5002C18.4179 1.30275 18.3786 1.10731 18.3026 0.92506C18.2266 0.742807 18.1155 0.577302 17.9756 0.437996C17.8356 0.298689 17.6696 0.18831 17.487 0.113159C17.3044 0.0380083 17.1088 -0.000441892 16.9114 3.8312e-06C16.7139 0.000449555 16.5185 0.0397825 16.3362 0.115757C16.154 0.191731 15.9885 0.302859 15.8492 0.442796L13.3556 2.9364C13.2094 3.07399 13.0924 3.23951 13.0114 3.42314C12.9305 3.60678 12.8871 3.8048 12.8841 4.00548C12.881 4.20615 12.9182 4.40541 12.9936 4.59143C13.0689 4.77746 13.1808 4.94648 13.3226 5.08847C13.4645 5.23047 13.6334 5.34256 13.8193 5.4181C14.0052 5.49364 14.2045 5.5311 14.4051 5.52826C14.6058 5.52542 14.8039 5.48233 14.9876 5.40156C15.1713 5.32078 15.337 5.20395 15.4748 5.058Z"
      fill="currentColor"
    />
  </Icon>
)
