import { AttachmentCard } from './AttachmentCard'
import { CategoryContentCard as CategoryContent } from './CategoryContentCard'
import { ContentCard as Content } from './ContentCard'
import { HighlightedContentCard as HighlightedContent } from './HighlightedContentCard'
import { OpportunitiesCard as Opportunities } from './NewOpportunities/OpportunitiesCard'
import { OpportunitiesListCard as OpportunitiesList } from './NewOpportunities/OpportunitiesListCard'
import { PoliciesCard as Policies } from './NewOpportunities/PoliciesCard'
import { NewsCard } from './NewsCard'
import { NotificationCard as Notification } from './NotificationCard'
import { OpportunityCard as Opportunity } from './OpportunityCard'
import { OpportunityOfCard } from './OpportunityOfCard'
import { OrganizationCard } from './OrganizationCard'
import { SummaryCard as Summary } from './SummaryCard'
import { BlocksSummaryDetailCard as BlocksSummaryDetail } from './SummaryDetailCard/BlocksSummaryDetailCard'
import { PointsSummaryDetailCard as PointsSummaryDetail } from './SummaryDetailCard/PointsSummaryDetailCard'
import { WalletCard } from './WalletCard'

export default {
  Content,
  HighlightedContent,
  Opportunity,
  OpportunityOfCard,
  Opportunities,
  OpportunitiesList,
  NewsCard,
  Notification,
  CategoryContent,
  OrganizationCard,
  Summary,
  WalletCard,
  AttachmentCard,
  PointsSummaryDetail,
  BlocksSummaryDetail,
  Policies
}
