import { useCallback, useEffect, useState } from 'react'

import * as Clipboard from 'expo-clipboard'
import { AppState, AppStateStatus, Platform } from 'react-native'
import { useBlurOnFulfill, useClearByFocusCell } from 'react-native-confirmation-code-field'

type TUseAuthCodeScreen = {
  submit({ code }: { code: string }): void
}

const verifyIsNumeric = new RegExp('^[0-9]+$')

export const useAuthCodeScreen = ({ submit }: TUseAuthCodeScreen) => {
  // states
  const [code, setCode] = useState<string>('')

  const [isSubmited, setIsSubmited] = useState(false)

  const [currenStateApp, setCurrentStateApp] = useState<AppStateStatus>(AppState.currentState)

  const ref = useBlurOnFulfill({ value: code, cellCount: 6 })

  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value: code,
    setValue: setCode
  })

  const onSubmit = () => {
    setIsSubmited(true)

    submit({ code })
  }

  const _handleAppStateChanged = useCallback(
    (nextAppState: AppStateStatus) => {
      if (currenStateApp === 'background' && nextAppState === 'active' && Platform.OS !== 'web') {
        setCode('')

        Clipboard.getStringAsync().then((text) => {
          const isNumeric = verifyIsNumeric.exec(text)

          if (text.length === 6 && isNumeric) {
            setCode(text)
          }
        })
      }

      setCurrentStateApp(nextAppState)
    },
    [currenStateApp]
  )

  useEffect(() => {
    const subscription = AppState.addEventListener('change', _handleAppStateChanged)

    return () => {
      subscription.remove()
    }
  }, [_handleAppStateChanged])

  useEffect(() => {
    if (code.length === 1 && isSubmited) {
      setIsSubmited(false)
    }
  }, [code, isSubmited])

  return {
    code,
    getCellOnLayoutHandler,
    isSubmited,
    onSubmit,
    props,
    ref,
    setCode
  }
}
