import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { PageNoConnectionScreenComponent } from './PageNoConnectionScreen.types'
import { usePageNoConnectionScreen } from './usePageNoConnectionScreen'

const PageNoConnectionScreenDesktop = lazy(() => import('./PageNoConnectionScreen.desktop'))

const PageNoConnectionScreenMobile = lazy(() => import('./PageNoConnectionScreen.mobile'))

export const PageNoConnectionScreen: PageNoConnectionScreenComponent = ({ navigation }) => {
  const props = usePageNoConnectionScreen({ navigation })

  const Screen = props.isMobile ? PageNoConnectionScreenMobile : PageNoConnectionScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
