import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import {
  NoteCreateDetailsContactScreenProps,
  NoteCreateDetailsScreenComponent,
  NoteCreateDetailsTabScreenProps
} from './NoteCreateDetailsScreen.types'
import { useNoteCreateDetailsScreen } from './useNoteCreateDetailsScreen'

const NoteCreateDetailsScreenDesktop = lazy(() => import('./NoteCreateDetailsScreen.desktop'))

const NoteCreateDetailsScreenMobile = lazy(() => import('./NoteCreateDetailsScreen.mobile'))

export const NoteCreateDetailsScreen = <
  T extends NoteCreateDetailsTabScreenProps | NoteCreateDetailsContactScreenProps
>({
  navigation,
  route
}: NoteCreateDetailsScreenComponent<T>) => {
  // @ts-ignore
  const NoteCreateDetailsScreen = useNoteCreateDetailsScreen({ navigation, route })

  const Screen = NoteCreateDetailsScreen.isMobile
    ? NoteCreateDetailsScreenMobile
    : NoteCreateDetailsScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...NoteCreateDetailsScreen} />
    </Suspense>
  )
}

export const NoteCreateDetailsTabScreen = NoteCreateDetailsScreen<NoteCreateDetailsTabScreenProps>

export const NoteCreateDetailsContactScreen =
  NoteCreateDetailsScreen<NoteCreateDetailsContactScreenProps>
