import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  KEY_OPPORTUNITIES_CHARGE_LIST,
  OpportunitiesItem,
  OpportunityType,
  useGetOpportunitiesChargeListInfiniteQuery
} from 'integration/resources/newOpportunities'
import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import { ListType } from 'src/screens/OrganizationScreen/OrganizationScreen.types'
import { calculateActiveFilterCount } from 'src/utils/filters'
import { LogEvent } from 'src/utils/logEvents'

import { UseCharge } from './types'

const mergeAllDataInfiniteQuery = (
  data: ReturnType<typeof useGetOpportunitiesChargeListInfiniteQuery>['data']
): OpportunitiesItem[] => {
  return (
    data?.pages?.reduce<OpportunitiesItem[]>((previousValue, currentValue) => {
      return [...previousValue, ...(currentValue.data.data || [])]
    }, []) ?? []
  )
}

export const useCharge: UseCharge = ({ route, search, navigation }) => {
  const modalFilters = useMemo(() => {
    return {
      segment: route?.params?.segment,
      network: route?.params?.network,
      subsidiary: route?.params?.subsidiary,
      user_std_code: route?.params?.user_std_code
    }
  }, [route?.params])

  const orderByFilters = useMemo(() => {
    return {
      order_by: route?.params?.order_by,
      order_by_direction: route?.params?.order_by_direction
    }
  }, [route?.params])

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch: refetchHook
  } = useGetOpportunitiesChargeListInfiniteQuery(
    {
      per_page: 10,
      search: search ?? undefined,
      ...modalFilters,
      ...orderByFilters
    },
    undefined
  )

  const list = useMemo(() => mergeAllDataInfiniteQuery(data), [data])

  const totalItems = data?.pages[0].data.metadata.pagination.total ?? 0

  const fetchMore = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const [refetchLoading, setRefetchLoading] = useState(false)

  const queryClient = useQueryClient()

  const refetch = useCallback(() => {
    setRefetchLoading(true)

    setTimeout(() => {
      setRefetchLoading(false)
    }, 300)

    queryClient.invalidateQueries(KEY_OPPORTUNITIES_CHARGE_LIST)
  }, [queryClient])

  const isEmpty = useMemo(() => !list?.length, [list])

  const handleGoToDetails = (opportunityId: string, itemType?: OpportunityType) => {
    LogEvent(`oportunidades_cobranca_acessar_detalhes`, {
      item_id: opportunityId
    })

    navigation.navigate('OpportunityDetails', {
      id: opportunityId,
      type: itemType
    })
  }

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const activeFilterCount = calculateActiveFilterCount(modalFilters)

  const handleGoToFilters = () => {
    LogEvent(`oportunidades_cobranca_acessar_filtros`, {
      item_id: ''
    })

    navigation.navigate('OpportunityFilter', {
      listType: ListType.Charge,
      ...modalFilters,
      ...orderByFilters
    })
  }

  useEffect(() => {
    refetchHook()
  }, [search, refetchHook])

  return {
    opportunitiesList: list,
    isLoading,
    fetchMore,
    isFetchingNextPage,
    refetch,
    refreshing: refetchLoading,
    isEmpty,
    handleGoToDetails,
    isMobile,
    handleGoToFilters,
    activeFilterCount,
    totalItems
  }
}
