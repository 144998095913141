import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const PhoneIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled = false,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      d={
        filled
          ? 'M22.753 16.865L19.6821 13.7941C19.1256 13.2618 18.3852 12.9648 17.6151 12.9648C16.8451 12.9648 16.1047 13.2618 15.5482 13.7941C15.4959 13.8464 15.4339 13.888 15.3656 13.9163C15.2973 13.9446 15.2241 13.9592 15.1501 13.9592C15.0762 13.9592 15.0029 13.9446 14.9346 13.9163C14.8663 13.888 14.8043 13.8464 14.7521 13.7941L9.81025 8.8546C9.70581 8.74892 9.64723 8.60633 9.64723 8.45774C9.64723 8.30916 9.70581 8.16657 9.81025 8.06088C10.0822 7.78947 10.2979 7.4671 10.4451 7.11223C10.5923 6.75735 10.668 6.37693 10.668 5.99274C10.668 5.60855 10.5923 5.22813 10.4451 4.87325C10.2979 4.51837 10.0822 4.196 9.81025 3.92459L6.73933 0.853667C6.19035 0.306952 5.44713 0 4.67236 0C3.89759 0 3.15437 0.306952 2.60539 0.853667L1.87546 1.57651C0.843518 2.60983 0.195124 3.96439 0.0374852 5.41621C-0.120154 6.86804 0.222402 8.33019 1.00851 9.56091C4.47383 14.7066 8.90071 19.1343 14.0458 22.6005C15.2789 23.381 16.7403 23.7201 18.1911 23.5621C19.6419 23.4042 20.9962 22.7587 22.0325 21.7312L22.7648 21.0013C23.3101 20.4503 23.615 19.7059 23.6128 18.9307C23.6106 18.1555 23.3014 17.4128 22.753 16.865Z'
          : 'M17.588 23.567c-1.2 0-2.39-.34-3.41-.99 0 0-.01 0-.02-.01a48.683 48.683 0 0 1-13.15-13.15 6.324 6.324 0 0 1 .84-7.89l.74-.74c1.05-1.05 2.87-1.05 3.92 0l3.13 3.13c.52.52.81 1.22.81 1.96s-.29 1.44-.81 1.96c-.14.14-.23.34-.23.54s.08.4.23.54l5.02 5.02c.29.29.8.29 1.09 0 1.04-1.04 2.88-1.04 3.92 0l3.12 3.12c.52.52.81 1.22.81 1.96s-.29 1.44-.81 1.96l-.74.74a6.324 6.324 0 0 1-3.78 1.81c-.23.02-.45.04-.68.04Zm-2.32-2.67c.82.52 1.81.75 2.78.65.97-.11 1.89-.54 2.58-1.24l.74-.74c.15-.15.23-.34.23-.54s-.08-.4-.23-.54l-3.12-3.12a.792.792 0 0 0-1.09 0c-1.04 1.04-2.87 1.05-3.92 0l-5.02-5.02a2.779 2.779 0 0 1 0-3.92c.15-.15.23-.34.23-.55 0-.21-.08-.4-.23-.55l-3.13-3.13a.792.792 0 0 0-1.09 0l-.74.74c-.69.69-1.13 1.61-1.24 2.58-.11.97.13 1.96.65 2.79 3.36 4.97 7.6 9.21 12.59 12.58l.01.01Z'
      }
      fill="currentColor"
    />
  </Icon>
)
