import { RvExtracts } from 'integration/resources/variable-revenue/types'

export const RV_STATEMENT_NAMES = {
  production: 'Produção',
  lender_insurance: 'Seguro Prestamista',
  insurance_auto: 'Seguro Auto',
  billing: 'Cobrança',
  webmotors: 'Webmotors',
  plus_accounts: '+Contas',
  active_base: 'Base Ativa',
  loyalty: 'Fidelização',
  multipliers: 'Multiplicadores',
  performance: 'Performance+'
}

export const RV_STATEMENT_NAMES_REMOVE_BUSINESS = ['Seguro Auto', 'Webmotors']

export const RV_STATEMENT_ACCELERATORS = ['Base Ativa', 'Fidelização']

export const RV_STATEMENT_MULTIPLIERS = ['Multiplicadores']

export const RV_STATEMENT_PERFORMANCE = ['Performance+']

export const blocksRvSummaryMock: RvExtracts = {
  net_value: 4013.09,
  production: {
    realized: 1900000,
    projected: 2200000,
    goal: 2100000,
    rv_realized: 0,
    rv_projected: 1330,
    weight: 40,
    percentage_realized: 91,
    percentage_projected: 105,
    accelerator: 3,
    percentage_accelerator: 95,
    active_base: 4,
    loyalty: 4,
    processed_at: '2024-02-01T13:00:00.000'
  },
  lender_insurance: {
    realized: 525000,
    projected: 675000,
    goal: 500000,
    rv_realized: 665.7,
    rv_projected: 855.9,
    weight: 20,
    percentage_realized: 105,
    percentage_projected: 135,
    accelerator: 3,
    percentage_accelerator: 109,
    active_base: 3,
    loyalty: 3,
    processed_at: '2024-02-01T13:00:00.000'
  },
  insurance_auto: {
    realized: 1900000,
    projected: 2400000,
    goal: 2100000,
    rv_realized: 0,
    rv_projected: 855.9,
    weight: 10,
    percentage_realized: 87.21,
    percentage_projected: 120,
    processed_at: '2024-02-01T13:00:00.000'
  },
  billing: {
    realized: 630000,
    projected: 1400000,
    goal: 2100000,
    rv_realized: 0,
    rv_projected: 0,
    weight: 10,
    percentage_realized: 30,
    percentage_projected: 70,
    charge_goal: 1,
    processed_at: '2024-02-01T13:00:00.000'
  },
  webmotors: {
    realized: 2200000,
    projected: 2600000,
    goal: 2100000,
    rv_realized: 332.85,
    rv_projected: 396.25,
    weight: 10,
    percentage_realized: 105,
    percentage_projected: 125,
    processed_at: '2024-02-01T13:00:00.000'
  },
  plus_accounts: {
    realized: 11,
    projected: 14,
    goal: 10,
    rv_realized: 332.85,
    rv_projected: 468.45,
    weight: 10,
    percentage_realized: 105,
    percentage_projected: 135,
    processed_at: '2024-02-01T13:00:00.000'
  },
  active_base: {
    realized: 83,
    percentage_realized: 101.22,
    goal: 82,
    active_value: 3,
    processed_at: '2024-02-01T13:00:00.000'
  },
  loyalty: {
    realized: 43,
    percentage_realized: 97.73,
    goal: 42,
    active_value: 3,
    processed_at: '2024-02-01T13:00:00.000'
  },
  multipliers: {
    value: 1.4,
    lines: 3,
    multiplier_type: 'Seguros',
    processed_at: '2024-02-01T13:00:00.000'
  },
  performance: {
    position: 50,
    next_level: undefined,
    boosted_rv: 2300,
    quadrant: 'A',
    processed_at: '2024-02-01T13:00:00.000'
  },
  processed_at: '2024-02-01T13:00:00.000'
}
