import { AttachmentExtension } from 'integration/resources/knowledgeBase'

export const selectIcon = (extension: string) => {
  switch (extension) {
    case AttachmentExtension.PDF:
      return 'PDF'

    case AttachmentExtension.PNG:
      return 'PNG'

    case AttachmentExtension.MP3:
      return 'MP3'

    case AttachmentExtension.ZIP:
      return 'ZIP'

    case AttachmentExtension.JPEG:
      return 'JPG'

    case AttachmentExtension.JPG:
      return 'JPG'

    case AttachmentExtension.CSV:
      return 'CSV'

    case AttachmentExtension.DOC:
      return 'DOC'

    case AttachmentExtension.DOCX:
      return 'DOCX'

    case AttachmentExtension.MP4:
      return 'MP4'

    case AttachmentExtension.PPT:
      return 'PPT'

    case AttachmentExtension.PPTX:
      return 'PPT'

    case AttachmentExtension.XLS:
      return 'XLS'

    case AttachmentExtension.XLSX:
      return 'XLSX'

    case AttachmentExtension.XLSM:
      return 'XLSM'

    default:
      return 'File'
  }
}
