import { FunctionComponent, useMemo } from 'react'

import { PerformanceData } from 'integration/resources/variable-revenue/types'
import { VStack, Heading, Text, HStack, Box, Divider } from 'native-base'
import { formatDateStringToMask } from 'src/utils'
import { currencyFormat } from 'src/utils/currencyFormat'

type PerformanceProps = {
  performance: PerformanceData
}

interface QuadrantProps {
  quadrant: string
  label: string
  range: string
  isActive: boolean
}

const Quadrant: FunctionComponent<QuadrantProps> = ({ quadrant, label, range, isActive }) => {
  const bgColorBase = useMemo(
    () => (isActive ? (quadrant === 'D' ? 'primary.50' : 'secondary.50') : 'gray.50'),
    [isActive, quadrant]
  )

  const bgColorLg = useMemo(
    () => (isActive ? (quadrant === 'D' ? 'primary.50' : 'secondary.50') : 'white'),
    [isActive, quadrant]
  )

  const textColorBase = useMemo(
    () => (isActive ? (quadrant === 'D' ? 'primary.500' : 'gray.700') : 'gray.200'),
    [isActive, quadrant]
  )

  const textColorLg = useMemo(
    () => (isActive ? (quadrant === 'D' ? 'primary.900' : 'gray.700') : 'gray.400'),
    [isActive, quadrant]
  )

  return (
    <VStack
      alignItems="center"
      flex={1}
      px={1}
      py={2}
      bgColor={{ base: bgColorBase, lg: bgColorLg }}
      borderBottomLeftRadius={quadrant === 'C' ? '10px' : undefined}
      borderBottomRightRadius={quadrant === 'D' ? '10px' : undefined}
      borderTopLeftRadius={quadrant === 'A' ? '10px' : undefined}
      borderTopRightRadius={quadrant === 'B' ? '10px' : undefined}
      space="2px">
      <Text
        fontSize={{ base: '12px', lg: '16px' }}
        fontWeight={400}
        color={{ base: textColorBase, lg: textColorLg }}
        lineHeight={{ base: '16px', lg: '24px' }}>
        {label}
      </Text>
      <Text
        fontSize={{ base: '12px', lg: '16px' }}
        fontWeight={700}
        color={{ base: textColorBase, lg: textColorLg }}
        lineHeight={{ base: '16px', lg: '24px' }}>
        {range}
      </Text>
    </VStack>
  )
}

export const SummaryPerformance: FunctionComponent<PerformanceProps> = ({ performance }) => {
  const { boosted_rv, position, quadrant, title, next_level, processed_at } = performance

  return (
    <VStack
      bg={{ base: 'white', lg: 'gray.50' }}
      borderRadius={{ base: '10px', lg: '20px' }}
      p={{ base: 4, lg: 6 }}
      space={{ base: 3, lg: 6 }}>
      <Heading
        fontSize={{ base: '14px', lg: '18px' }}
        fontWeight={700}
        lineHeight={{ base: '18px', lg: '24px' }}>
        {title}
      </Heading>

      <VStack space={{ base: 3, lg: 1 }}>
        <HStack space={2} alignItems="center">
          <Text
            fontSize={{ base: '12px', lg: '16px' }}
            fontWeight={400}
            lineHeight={{ base: '16px', lg: '24px' }}
            color="gray.700">
            Posição atual:
          </Text>
          <Text
            fontSize={{ base: '14px', lg: '16px' }}
            fontWeight={700}
            lineHeight={{ base: '16px', lg: '24px' }}
            color="gray.700">
            {position}
          </Text>
        </HStack>

        <HStack space={2} alignItems="center">
          <Text
            fontSize={{ base: '12px', lg: '16px' }}
            fontWeight={400}
            lineHeight={{ base: '16px', lg: '24px' }}
            color="gray.700">
            Próximo nível:
          </Text>
          <Text
            fontSize={{ base: '14px', lg: '16px' }}
            fontWeight={700}
            lineHeight={{ base: '16px', lg: '24px' }}
            color="gray.700">
            {next_level ?? 'N/A'}
          </Text>
        </HStack>

        <HStack space={2} alignItems="center">
          <Text
            fontSize={{ base: '12px', lg: '16px' }}
            fontWeight={400}
            lineHeight={{ base: '16px', lg: '24px' }}
            color="gray.700">
            Benefício dos quadrantes na RV:
          </Text>
          <Text
            fontSize={{ base: '14px', lg: '16px' }}
            fontWeight={700}
            lineHeight={{ base: '16px', lg: '24px' }}
            color="gray.700">
            R$ {currencyFormat(Number(boosted_rv ?? 0)?.toFixed(2))}
          </Text>
        </HStack>
      </VStack>

      <VStack space={{ base: '1px', lg: '2px' }}>
        <HStack space={{ base: '1px', lg: '2px' }}>
          <Quadrant
            quadrant="A"
            label="Quadrante A (+30%)"
            range="0% à 10%"
            isActive={quadrant === 'A'}
          />
          <Quadrant
            quadrant="B"
            label="Quadrante B (+15%)"
            range="10% à 40%"
            isActive={quadrant === 'B'}
          />
        </HStack>

        <HStack space={{ base: '1px', lg: '2px' }}>
          <Quadrant
            quadrant="C"
            label="Quadrante C (+5%)"
            range="40% à 70%"
            isActive={quadrant === 'C'}
          />
          <Quadrant
            quadrant="D"
            label="Quadrante D (0%)"
            range="70% à 100%"
            isActive={quadrant === 'D'}
          />
        </HStack>
      </VStack>

      <Box>
        <Divider h="1px" my={{ base: 1, lg: 0 }} bg="gray.100" opacity={0.6} />
      </Box>

      <VStack space={3}>
        <Text
          fontSize={{ base: '10px', lg: '14px' }}
          fontWeight={600}
          lineHeight={{ base: '14px', lg: '20px' }}
          color="gray.200">
          Dados até:{' '}
          {processed_at ? formatDateStringToMask(processed_at, "dd'/'MM'/'yyyy") : 'Sem data'}
        </Text>
      </VStack>
    </VStack>
  )
}
