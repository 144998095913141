import { useCallback } from 'react'

import { useBreakpointValue } from 'native-base'

import { UseIntegrationProfileScreen } from './IntegrationProfileScreen.types'

export const useIntegrationProfileScreen: UseIntegrationProfileScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  /**
   * @TODO change navigation navigate to useLinkTo
   * define custom names cause this name used in url in version web
   */

  const goBack = useCallback(() => navigation.goBack(), [navigation])

  return { isMobile, goBack }
}
