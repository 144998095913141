import { memo } from 'react'

import Svg, { G, Path, Defs, ClipPath, Rect } from 'react-native-svg'

const EvaluationSuccessllustration = () => (
  <Svg width={192} height={173} viewBox="0 0 192 173" fill="none">
    <G clipPath="url(#clip0_10575_68813)">
      <Path
        d="M188.756 129.206L187.636 124.494C188.028 126.262 187.724 128.11 186.772 129.654C185.828 131.198 184.316 132.31 182.564 132.758L50.1318 166.766C48.3398 167.222 46.4438 166.958 44.8438 166.014C43.2518 165.07 42.0998 163.542 41.6358 161.75L17.1798 66.5419C16.9478 65.6539 16.8998 64.7339 17.0278 63.8219C17.1558 62.9179 17.4598 62.0459 17.9238 61.2539C18.3878 60.4619 19.0038 59.7739 19.7398 59.2219C20.4758 58.6699 21.3078 58.2699 22.1958 58.0459L17.5958 59.1659C15.6278 59.6779 13.9398 60.9499 12.9078 62.7019C11.8758 64.4539 11.5718 66.5419 12.0678 68.5179L36.4278 163.366C37.2358 166.486 39.2438 169.158 42.0198 170.798C44.7958 172.438 48.1078 172.918 51.2278 172.118L183.204 138.414C185.156 137.918 186.836 136.678 187.876 134.95C188.916 133.222 189.228 131.158 188.756 129.206Z"
        fill="#737373"
      />
      <Path
        d="M188.756 129.206L187.636 124.494C188.028 126.262 187.724 128.11 186.772 129.654C185.828 131.198 184.316 132.31 182.564 132.758L50.1318 166.766C48.3398 167.222 46.4438 166.958 44.8438 166.014C43.2518 165.07 42.0998 163.542 41.6358 161.75L17.1798 66.5419C16.9478 65.6539 16.8998 64.7339 17.0278 63.8219C17.1558 62.9179 17.4598 62.0459 17.9238 61.2539C18.3878 60.4619 19.0038 59.7739 19.7398 59.2219C20.4758 58.6699 21.3078 58.2699 22.1958 58.0459L17.5958 59.1659C15.6278 59.6779 13.9398 60.9499 12.9078 62.7019C11.8758 64.4539 11.5718 66.5419 12.0678 68.5179L36.4278 163.366C37.2358 166.486 39.2438 169.158 42.0198 170.798C44.7958 172.438 48.1078 172.918 51.2278 172.118L183.204 138.414C185.156 137.918 186.836 136.678 187.876 134.95C188.916 133.222 189.228 131.158 188.756 129.206V129.206Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M154.291 24.0059L22.1341 58.3072C18.3407 59.2917 16.0638 63.1649 17.0483 66.9583L41.8372 162.466C42.8217 166.259 46.695 168.536 50.4883 167.551L182.645 133.25C186.439 132.266 188.716 128.392 187.731 124.599L162.942 29.0916C161.958 25.2983 158.085 23.0214 154.291 24.0059Z"
        fill="#F5F5F5"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M110.676 109.293L95.46 113.205L99.508 128.973L114.732 125.061L110.684 109.285L110.676 109.293Z"
        fill="#737373"
      />
      <Path
        d="M110.676 109.293L95.46 113.205L99.508 128.973L114.732 125.061L110.684 109.285L110.676 109.293Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M70.0197 119.741L54.8037 123.653L58.8517 139.421L74.0677 135.509L70.0197 119.741Z"
        fill="#737373"
      />
      <Path
        d="M70.0197 119.741L54.8037 123.653L58.8517 139.421L74.0677 135.509L70.0197 119.741Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M158.996 93.3574L136.932 99.0294L142.812 121.901L164.876 116.237L158.996 93.3654V93.3574Z"
        fill="#F3123C"
      />
      <Path
        d="M158.996 93.3574L136.932 99.0294L142.812 121.901L164.876 116.237L158.996 93.3654V93.3574Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M134.132 102.718L152.676 114.63L162.196 91.3896"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M55.1074 109.086C63.8274 109.086 70.8994 102.014 70.8994 93.294C70.8994 84.574 63.8274 77.502 55.1074 77.502C46.3874 77.502 39.3154 84.574 39.3154 93.294C39.3154 102.014 46.3874 109.086 55.1074 109.086Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M65.6514 99.1102C65.3714 97.9822 64.8754 96.9262 64.1874 95.9902C63.4994 95.0622 62.6274 94.2702 61.6354 93.6782C60.6434 93.0862 59.5394 92.6942 58.3874 92.5262C57.2434 92.3582 56.0754 92.4222 54.9474 92.7102C53.8274 92.9982 52.7714 93.5102 51.8434 94.2062C50.9154 94.9022 50.1394 95.7822 49.5554 96.7822C48.9714 97.7822 48.5874 98.8942 48.4354 100.038C48.2834 101.182 48.3554 102.358 48.6594 103.47"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M46.0431 91.5101C46.8271 91.5101 47.4671 90.8701 47.4671 90.0861C47.4671 89.3021 46.8271 88.6621 46.0431 88.6621C45.2591 88.6621 44.6191 89.3021 44.6191 90.0861C44.6191 90.8701 45.2591 91.5101 46.0431 91.5101Z"
        fill="#737373"
      />
      <Path
        d="M46.0431 91.5101C46.8271 91.5101 47.4671 90.8701 47.4671 90.0861C47.4671 89.3021 46.8271 88.6621 46.0431 88.6621C45.2591 88.6621 44.6191 89.3021 44.6191 90.0861C44.6191 90.8701 45.2591 91.5101 46.0431 91.5101Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M61.4914 87.5336C62.2754 87.5336 62.9154 86.8935 62.9154 86.1095C62.9154 85.3255 62.2754 84.6855 61.4914 84.6855C60.7074 84.6855 60.0674 85.3255 60.0674 86.1095C60.0674 86.8935 60.7074 87.5336 61.4914 87.5336Z"
        fill="#737373"
      />
      <Path
        d="M61.4914 87.5336C62.2754 87.5336 62.9154 86.8935 62.9154 86.1095C62.9154 85.3255 62.2754 84.6855 61.4914 84.6855C60.7074 84.6855 60.0674 85.3255 60.0674 86.1095C60.0674 86.8935 60.7074 87.5336 61.4914 87.5336Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M136.027 45.7656C132.131 46.5656 128.547 48.4536 125.683 51.2136C122.819 53.9736 120.795 57.4856 119.851 61.3416C118.907 65.2056 119.067 69.2536 120.331 73.0216C121.595 76.7896 123.899 80.1256 126.979 82.6376C130.059 85.1496 133.787 86.7336 137.739 87.2136C141.683 87.6936 145.683 87.0376 149.275 85.3336C152.867 83.6296 155.899 80.9416 158.027 77.5816C160.147 74.2216 161.284 70.3256 161.284 66.3496C161.284 63.2296 160.587 60.1496 159.251 57.3336C157.915 54.5176 155.964 52.0296 153.548 50.0616C151.131 48.0936 148.307 46.6856 145.275 45.9416C142.243 45.1976 139.091 45.1416 136.035 45.7656H136.027Z"
        fill="#F3123C"
      />
      <Path
        d="M136.027 45.7656C132.131 46.5656 128.547 48.4536 125.683 51.2136C122.819 53.9736 120.795 57.4856 119.851 61.3416C118.907 65.2056 119.067 69.2536 120.331 73.0216C121.595 76.7896 123.899 80.1256 126.979 82.6376C130.059 85.1496 133.787 86.7336 137.739 87.2136C141.683 87.6936 145.683 87.0376 149.275 85.3336C152.867 83.6296 155.899 80.9416 158.027 77.5816C160.147 74.2216 161.284 70.3256 161.284 66.3496C161.284 63.2296 160.587 60.1496 159.251 57.3336C157.915 54.5176 155.964 52.0296 153.547 50.0616C151.131 48.0936 148.307 46.6856 145.275 45.9416C142.243 45.1976 139.091 45.1416 136.035 45.7656H136.027Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M151.683 63.8457C152.075 65.3337 152.171 66.8857 151.963 68.4057C151.755 69.9337 151.243 71.3977 150.467 72.7257C149.691 74.0537 148.659 75.2137 147.427 76.1417C146.195 77.0697 144.795 77.7417 143.307 78.1257C141.819 78.5097 140.267 78.5897 138.739 78.3737C137.219 78.1577 135.747 77.6377 134.427 76.8537C133.107 76.0697 131.947 75.0297 131.035 73.7897C130.115 72.5577 129.451 71.1497 129.075 69.6617"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M128.124 63.598C129.172 63.598 130.028 62.75 130.028 61.694C130.028 60.638 129.18 59.79 128.124 59.79C127.068 59.79 126.22 60.638 126.22 61.694C126.22 62.75 127.068 63.598 128.124 63.598Z"
        fill="#737373"
      />
      <Path
        d="M128.124 63.598C129.172 63.598 130.028 62.75 130.028 61.694C130.028 60.638 129.18 59.79 128.124 59.79C127.068 59.79 126.22 60.638 126.22 61.694C126.22 62.75 127.068 63.598 128.124 63.598Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M148.66 58.3178C149.708 58.3178 150.564 57.4698 150.564 56.4138C150.564 55.3578 149.716 54.5098 148.66 54.5098C147.604 54.5098 146.756 55.3578 146.756 56.4138C146.756 57.4698 147.604 58.3178 148.66 58.3178Z"
        fill="#737373"
      />
      <Path
        d="M148.66 58.3178C149.708 58.3178 150.564 57.4698 150.564 56.4138C150.564 55.3578 149.716 54.5098 148.66 54.5098C147.604 54.5098 146.756 55.3578 146.756 56.4138C146.756 57.4698 147.604 58.3178 148.66 58.3178Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M95.7715 98.6533C104.491 98.6533 111.563 91.5813 111.563 82.8613C111.563 74.1413 104.491 67.0693 95.7715 67.0693C87.0515 67.0693 79.9795 74.1413 79.9795 82.8613C79.9795 91.5813 87.0515 98.6533 95.7715 98.6533V98.6533Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M87.8037 89.589L106.004 84.917"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M87.1799 82.8851C87.9639 82.8851 88.6039 82.2451 88.6039 81.4611C88.6039 80.6771 87.9639 80.0371 87.1799 80.0371C86.3959 80.0371 85.7559 80.6771 85.7559 81.4611C85.7559 82.2451 86.3959 82.8851 87.1799 82.8851Z"
        fill="#737373"
      />
      <Path
        d="M87.1799 82.8851C87.9639 82.8851 88.6039 82.2451 88.6039 81.4611C88.6039 80.6771 87.9639 80.0371 87.1799 80.0371C86.3959 80.0371 85.7559 80.6771 85.7559 81.4611C85.7559 82.2451 86.3959 82.8851 87.1799 82.8851Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M102.627 78.9095C103.411 78.9095 104.051 78.2695 104.051 77.4855C104.051 76.7015 103.411 76.0615 102.627 76.0615C101.843 76.0615 101.203 76.7015 101.203 77.4855C101.203 78.2695 101.843 78.9095 102.627 78.9095Z"
        fill="#737373"
      />
      <Path
        d="M102.627 78.9095C103.411 78.9095 104.051 78.2695 104.051 77.4855C104.051 76.7015 103.411 76.0615 102.627 76.0615C101.843 76.0615 101.203 76.7015 101.203 77.4855C101.203 78.2695 101.843 78.9095 102.627 78.9095V78.9095Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M163.499 55.2536C178.555 55.2536 190.763 43.0456 190.763 27.9896C190.763 12.9336 178.563 0.725586 163.499 0.725586C148.435 0.725586 136.235 12.9336 136.235 27.9896C136.235 43.0456 148.443 55.2536 163.499 55.2536Z"
        fill="#F5F5F5"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M159.284 11.5898L166.868 20.4058L178.42 19.1498L172.38 29.0858L177.132 39.6858L165.828 37.0058L157.204 44.7978L156.276 33.2298L146.188 27.4378L156.908 22.9578L159.3 11.5978L159.284 11.5898Z"
        fill="#F3123C"
      />
      <Path
        d="M159.284 11.5898L166.868 20.4058L178.42 19.1498L172.38 29.0858L177.132 39.6858L165.828 37.0058L157.204 44.7978L156.276 33.2298L146.188 27.4378L156.908 22.9578L159.3 11.5978L159.284 11.5898Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M163.859 158.182L167.051 170.702"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M171.732 162.838L159.212 166.03"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10.2119 31.3339L15.2999 30.0059"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M24.3633 27.6698L29.4753 26.3418"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M17.3555 19.1895L18.6675 24.3015"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M21.0195 33.3652L22.3315 38.4772"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.252 23.7891L15.812 26.4691"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M23.8672 31.2217L28.4112 33.9017"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M24.8997 20.2617L22.2197 24.8057"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M17.4671 32.8691L14.7871 37.4131"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M3.81148 159.094C5.49148 159.094 6.85148 157.734 6.85148 156.054C6.85148 154.374 5.49148 153.014 3.81148 153.014C2.13148 153.014 0.771484 154.374 0.771484 156.054C0.771484 157.734 2.13148 159.094 3.81148 159.094V159.094Z"
        stroke="#595959"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_10575_68813">
        <Rect
          width="190.872"
          height="172.648"
          fill="white"
          transform="translate(0.267578 0.349609)"
        />
      </ClipPath>
    </Defs>
  </Svg>
)

export const EvaluationSuccess = memo(EvaluationSuccessllustration)
