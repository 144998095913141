import { AxiosError, AxiosResponse } from 'axios'
import { useInfiniteQuery, useQuery } from 'react-query'

import * as requests from './requests'
import {
  TParams,
  TGoalsLeadersListItem,
  TGoalsPeriod,
  TGoalsStaffListItem,
  TGoalsLeaderDetails
} from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_PERIOD_QUERY = 'getGoalsPeriod'

export const KEY_LEADERS_LIST_QUERY = 'getGoalsLeadersList'

export const KEY_STAFF_LIST_QUERY = 'getStaffList'

export const KEY_LEADER_DETAILS_QUERY = 'getGoalsLeaderDetails'

export const useGetGoalsPeriodQuery = () => {
  return useQuery<AxiosResponse<ResponseSuccess<TGoalsPeriod>>, AxiosError<ResponseError>>(
    [KEY_PERIOD_QUERY],
    () => requests.getGoalsPeriod()
  )
}

export const useGetGoalsLeadersListInfinityQuery = ({ enabled = true, ...params }: TParams) =>
  useInfiniteQuery<
    AxiosResponse<ResponseSuccess<TGoalsLeadersListItem[]>>,
    AxiosError<ResponseError>
  >(
    [KEY_LEADERS_LIST_QUERY, params],
    //@ts-ignore
    ({ pageParam }) => requests.getGoalsLeadersList({ ...params, page: pageParam }),
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )

export const useGetGoalsStaffListInfinityQuery = ({ enabled = true, ...params }: TParams) =>
  useInfiniteQuery<
    AxiosResponse<ResponseSuccess<TGoalsStaffListItem[]>>,
    AxiosError<ResponseError>
  >(
    [KEY_STAFF_LIST_QUERY, params],
    //@ts-ignore
    ({ pageParam }) => requests.getGoalsStaffList({ ...params, page: pageParam }),
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )

export const useGetGoalsLeaderDetailsQuery = ({ leader_std_code }: { leader_std_code: number }) => {
  return useQuery<AxiosResponse<ResponseSuccess<TGoalsLeaderDetails>>, AxiosError<ResponseError>>(
    [KEY_LEADER_DETAILS_QUERY, leader_std_code],
    () => requests.getGoalsLeaderDetails({ leader_std_code })
  )
}
