import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ChangeOrderIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0862 21.0802C14.5339 21.0881 14.0798 20.6469 14.0718 20.0947L13.9117 9.01445C13.9038 8.46222 14.345 8.00809 14.8972 8.00011C15.4494 7.99213 15.9035 8.43333 15.9115 8.98556L16.0716 20.0658C16.0796 20.618 15.6384 21.0722 15.0862 21.0802Z"
      fill="#C2C2C2"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7305 16.4211C19.121 16.8116 19.121 17.4448 18.7305 17.8353L15.7786 20.7872C15.3881 21.1777 14.7549 21.1777 14.3644 20.7872L11.4125 17.8353C11.0219 17.4448 11.0219 16.8116 11.4125 16.4211C11.803 16.0305 12.4362 16.0305 12.8267 16.4211L15.0715 18.6659L17.3163 16.4211C17.7068 16.0305 18.34 16.0305 18.7305 16.4211Z"
      fill="#C2C2C2"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.95536 3.00001C9.50764 3.00202 9.95372 3.45136 9.95171 4.00365L9.91161 15.0036C9.90959 15.5559 9.46025 16.002 8.90797 16C8.35569 15.998 7.90961 15.5486 7.91162 14.9964L7.95172 3.99635C7.95373 3.44407 8.40308 2.99799 8.95536 3.00001Z"
      fill="#404040"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29289 7.65918C4.90237 7.26865 4.90237 6.63549 5.29289 6.24496L8.2448 3.29305C8.63533 2.90253 9.26849 2.90253 9.65902 3.29305L12.6109 6.24496C13.0014 6.63549 13.0014 7.26865 12.6109 7.65918C12.2204 8.0497 11.5872 8.0497 11.1967 7.65918L8.95191 5.41437L6.70711 7.65918C6.31658 8.0497 5.68342 8.0497 5.29289 7.65918Z"
      fill="#404040"
    />
  </Icon>
)
