/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  CALCULATOR_TYPE_ENUM,
  useGetCalculatorData,
  useResetCalculatorSimulationMutation,
  useSendCalculatorSimulationMutation
} from 'integration/resources/rvCalculators'
import { useBreakpointValue, useDisclose } from 'native-base'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  formsDefaultValues,
  goalFields,
  goalOneFields,
  goalTwoFields,
  percentAchievedFields,
  percentAchievedOneFields,
  percentAchievedTwoFields,
  realizedFields,
  realizedOneFields,
  realizedTwoFields,
  weightFields,
  acceleratedPointsFields,
  acceleratorsFields,
  acceleratorsOneFields,
  acceleratorsTwoFields,
  pointsFields,
  acceleratorFields
} from './constants'
import {
  ResultsCalculatorFormFields,
  SelectedTarget,
  Shape,
  UseResultsCalculator
} from './ResultsCalculator.types'
import {
  calculateRVSimulated,
  calculatePercentRealized,
  calculateTotal,
  calculateResultsMultiplier,
  calculateRVMultiplied,
  calculateTotalWithPercentage,
  calculateWeightedAverageResults
} from '../../Utils/calculationsFunctions'
import {
  indicatorsLines,
  resultsTargets,
  chargeGoalValues,
  performanceValues,
  baseValues,
  loyaltyValues
} from '../../Utils/constants'

// Dynamically create Yup validation schema based on field names
const createYupSchema = (fieldNames: (keyof ResultsCalculatorFormFields)[]) => {
  const shape: Shape = {} as Shape

  fieldNames.forEach((name: string) => {
    shape[name] = yup.number().required()
  })

  return yup.object().shape(shape)
}

// Validation schema creation for RV calculator form fields
const ResultsCalculatorFormSchema = createYupSchema([
  ...weightFields,
  ...goalOneFields,
  ...realizedOneFields,
  ...percentAchievedOneFields,
  ...goalTwoFields,
  ...realizedTwoFields,
  ...percentAchievedTwoFields,
  ...goalFields,
  ...realizedFields,
  ...percentAchievedFields,
  ...acceleratedPointsFields,
  ...acceleratorsFields,
  ...acceleratorsOneFields,
  ...acceleratorsTwoFields,
  ...pointsFields,
  ...acceleratorFields,
  'targetAccount',
  'weightTotal',
  'chargeGoal',
  'finalAverage',
  'acceleratedPointsTotal',
  'rvValue',
  'multiplier',
  'multipliedRV',
  'performance',
  'rvTotal'
])

export const useResultsCalculator: UseResultsCalculator = ({
  userIsGR,
  dontShowConfirm,
  handleDontShowConfirm
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { isOpen: showTooltip, onOpen: openTooltip, onClose: closeTooltip } = useDisclose(false)

  const {
    control,
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm<ResultsCalculatorFormFields>({
    resolver: yupResolver(ResultsCalculatorFormSchema),
    mode: 'onChange',
    defaultValues: formsDefaultValues
  })

  const formFields = watch()

  const { data: calculatorData, isLoading: isLoadingResultsData } = useGetCalculatorData(
    CALCULATOR_TYPE_ENUM.RESULTS
  )

  useEffect(() => {
    if (calculatorData?.data?.data) {
      reset({ ...calculatorData.data.data })
    }
  }, [calculatorData, reset])

  const { mutateAsync, isLoading: isSendingSimulation } = useSendCalculatorSimulationMutation(
    CALCULATOR_TYPE_ENUM.RESULTS
  )

  const { mutate: resetResultsSimulation, isLoading: isResettingResultsSimulation } =
    useResetCalculatorSimulationMutation(CALCULATOR_TYPE_ENUM.RESULTS)

  const [selectedTarget, setSelectedTarget] = useState<SelectedTarget>(resultsTargets[0])

  const [successSave, setSuccessSave] = useState(false)

  const { isOpen: savedIsOpen, onClose: savedOnClose, onOpen: savedOnOpen } = useDisclose(false)

  useEffect(() => {
    if (isDirty && dontShowConfirm) {
      handleDontShowConfirm?.()
    }

    if (!isDirty && !dontShowConfirm) {
      handleDontShowConfirm?.()
    }
  }, [isDirty])

  const handleConfirmSimulation = handleSubmit(
    useCallback<SubmitHandler<ResultsCalculatorFormFields>>(
      async (formData) =>
        mutateAsync(
          {
            calculator_json: formData,
            calculator_type_enum: CALCULATOR_TYPE_ENUM.RESULTS
          },
          {
            onSuccess: () => {
              setSuccessSave(true)
            }
          }
        ).then(() => {
          reset(formFields)

          savedOnOpen()
        }),

      [reset]
    )
  )

  const handleResetSimulation = () => {
    resetResultsSimulation(CALCULATOR_TYPE_ENUM.RESULTS, {
      onSuccess: () => {
        reset(formsDefaultValues)
      }
    })
  }

  // --- Weight Section ---
  // Function to update total weight and manage validation
  const updateTotalWeight = () => {
    const weightValues = [
      formFields.weightProduction,
      formFields.weightSPF,
      formFields.weightSA,
      formFields.weightCharge,
      formFields.weightWebmotors,
      formFields.weightMoreAccounts
    ]

    const total = calculateTotal(weightValues)

    setValue('weightTotal', total)

    return total !== 100
      ? setError('weightTotal', { message: 'Valor do campo precisa ser igual a 100' })
      : clearErrors('weightTotal')
  }

  useEffect(() => {
    updateTotalWeight()
  }, [
    formFields.weightWebmotors,
    formFields.weightCharge,
    formFields.weightMoreAccounts,
    formFields.weightProduction,
    formFields.weightSA,
    formFields.weightSPF
  ])

  // --- Target Section ---
  useEffect(() => {
    setValue('targetAccount', selectedTarget?.value ?? 0)
  }, [selectedTarget])

  useEffect(() => {
    if (formFields.targetAccount === 0) {
      setSelectedTarget(resultsTargets[0])

      setValue('targetAccount', resultsTargets[0].value)
    }
  }, [formFields.targetAccount])

  // --- PercentAchieved Section ---
  // Function to update percent realized value for a given field
  const updatePercentAchievedValue = (
    name: keyof ResultsCalculatorFormFields,
    goal: number,
    realized: number
  ) => {
    return setValue(name, calculatePercentRealized(goal, realized, name.includes('Production')))
  }

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneProduction',
      formFields.goalOneProduction,
      formFields.realizedOneProduction
    )
  }, [formFields.goalOneProduction, formFields.realizedOneProduction])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneSPF',
      formFields.goalOneSPF,
      formFields.realizedOneSPF
    )
  }, [formFields.goalOneSPF, formFields.realizedOneSPF])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneSA',
      formFields.goalOneSA,
      formFields.realizedOneSA
    )
  }, [formFields.goalOneSA, formFields.realizedOneSA])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneCharge',
      formFields.goalOneCharge,
      formFields.realizedOneCharge
    )
  }, [formFields.goalOneCharge, formFields.realizedOneCharge])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneWebmotors',
      formFields.goalOneWebmotors,
      formFields.realizedOneWebmotors
    )
  }, [formFields.goalOneWebmotors, formFields.realizedOneWebmotors])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedOneMoreAccounts',
      formFields.goalOneMoreAccounts,
      formFields.realizedOneMoreAccounts
    )
  }, [formFields.goalOneMoreAccounts, formFields.realizedOneMoreAccounts])

  // --- update percentAchieved Two Section ---

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoProduction',
      formFields.goalTwoProduction,
      formFields.realizedTwoProduction
    )
  }, [formFields.goalTwoProduction, formFields.realizedTwoProduction])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoSPF',
      formFields.goalTwoSPF,
      formFields.realizedTwoSPF
    )
  }, [formFields.goalTwoSPF, formFields.realizedTwoSPF])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoSA',
      formFields.goalTwoSA,
      formFields.realizedTwoSA
    )
  }, [formFields.goalTwoSA, formFields.realizedTwoSA])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoCharge',
      formFields.goalTwoCharge,
      formFields.realizedTwoCharge
    )
  }, [formFields.goalTwoCharge, formFields.realizedTwoCharge])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoWebmotors',
      formFields.goalTwoWebmotors,
      formFields.realizedTwoWebmotors
    )
  }, [formFields.goalTwoWebmotors, formFields.realizedTwoWebmotors])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedTwoMoreAccounts',
      formFields.goalTwoMoreAccounts,
      formFields.realizedTwoMoreAccounts
    )
  }, [formFields.goalTwoMoreAccounts, formFields.realizedTwoMoreAccounts])

  // --- update goal & realized Section ---

  const updateGoalOrRealized = (
    fieldName: keyof ResultsCalculatorFormFields,
    valueOne: number,
    valueTwo: number
  ) => {
    setValue(fieldName, calculateTotal([valueOne, valueTwo]))
  }

  useEffect(() => {
    updateGoalOrRealized(
      'goalProduction',
      formFields.goalOneProduction,
      formFields.goalTwoProduction
    )
  }, [formFields.goalOneProduction, formFields.goalTwoProduction])

  useEffect(() => {
    updateGoalOrRealized('goalSPF', formFields.goalOneSPF, formFields.goalTwoSPF)
  }, [formFields.goalOneSPF, formFields.goalTwoSPF])

  useEffect(() => {
    updateGoalOrRealized('goalSA', formFields.goalOneSA, formFields.goalTwoSA)
  }, [formFields.goalOneSA, formFields.goalTwoSA])

  useEffect(() => {
    updateGoalOrRealized('goalCharge', formFields.goalOneCharge, formFields.goalTwoCharge)
  }, [formFields.goalOneCharge, formFields.goalTwoCharge])

  useEffect(() => {
    updateGoalOrRealized('goalWebmotors', formFields.goalOneWebmotors, formFields.goalTwoWebmotors)
  }, [formFields.goalOneWebmotors, formFields.goalTwoWebmotors])

  useEffect(() => {
    updateGoalOrRealized(
      'goalMoreAccounts',
      formFields.goalOneMoreAccounts,
      formFields.goalTwoMoreAccounts
    )
  }, [formFields.goalOneMoreAccounts, formFields.goalTwoMoreAccounts])

  useEffect(() => {
    updateGoalOrRealized(
      'realizedProduction',
      formFields.realizedOneProduction,
      formFields.realizedTwoProduction
    )
  }, [formFields.realizedOneProduction, formFields.realizedTwoProduction])

  useEffect(() => {
    updateGoalOrRealized('realizedSPF', formFields.realizedOneSPF, formFields.realizedTwoSPF)
  }, [formFields.realizedOneSPF, formFields.realizedTwoSPF])

  useEffect(() => {
    updateGoalOrRealized('realizedSA', formFields.realizedOneSA, formFields.realizedTwoSA)
  }, [formFields.realizedOneSA, formFields.realizedTwoSA])

  useEffect(() => {
    updateGoalOrRealized(
      'realizedCharge',
      formFields.realizedOneCharge,
      formFields.realizedTwoCharge
    )
  }, [formFields.realizedOneCharge, formFields.realizedTwoCharge])

  useEffect(() => {
    updateGoalOrRealized(
      'realizedWebmotors',
      formFields.realizedOneWebmotors,
      formFields.realizedTwoWebmotors
    )
  }, [formFields.realizedOneWebmotors, formFields.realizedTwoWebmotors])

  useEffect(() => {
    updateGoalOrRealized(
      'realizedMoreAccounts',
      formFields.realizedOneMoreAccounts,
      formFields.realizedTwoMoreAccounts
    )
  }, [formFields.realizedOneMoreAccounts, formFields.realizedTwoMoreAccounts])

  // --- updatePercentAchievedValue Section ---

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedProduction',
      formFields.goalProduction,
      formFields.realizedProduction
    )
  }, [formFields.goalProduction, formFields.realizedProduction])

  useEffect(() => {
    updatePercentAchievedValue('percentAchievedSPF', formFields.goalSPF, formFields.realizedSPF)
  }, [formFields.goalSPF, formFields.realizedSPF])

  useEffect(() => {
    updatePercentAchievedValue('percentAchievedSA', formFields.goalSA, formFields.realizedSA)
  }, [formFields.goalSA, formFields.realizedSA])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedCharge',
      formFields.goalCharge,
      formFields.realizedCharge
    )
  }, [formFields.goalCharge, formFields.realizedCharge])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedWebmotors',
      formFields.goalWebmotors,
      formFields.realizedWebmotors
    )
  }, [formFields.goalWebmotors, formFields.realizedWebmotors])

  useEffect(() => {
    updatePercentAchievedValue(
      'percentAchievedMoreAccounts',
      formFields.goalMoreAccounts,
      formFields.realizedMoreAccounts
    )
  }, [formFields.goalMoreAccounts, formFields.realizedMoreAccounts])

  // --- Points Section ---
  // Function to update Points value for a given field
  const updatePoints = (
    pointsField: keyof ResultsCalculatorFormFields,
    weight: number,
    percentAchieved: number,
    chargeGoal: number = 1
  ) => {
    const percentLimit = pointsField.includes('Production') ? 200 : 150

    const appliedPercent =
      percentAchieved > percentLimit || chargeGoal === 2 ? percentLimit : percentAchieved

    setValue(pointsField, calculateRVSimulated(appliedPercent, weight))
  }

  useEffect(() => {
    updatePoints(
      'pointsProduction',
      formFields.weightProduction,
      formFields.percentAchievedProduction
    )
  }, [formFields.weightProduction, formFields.percentAchievedProduction])

  useEffect(() => {
    updatePoints('pointsSPF', formFields.weightSPF, formFields.percentAchievedSPF)
  }, [formFields.weightSPF, formFields.percentAchievedSPF])

  useEffect(() => {
    updatePoints('pointsSA', formFields.weightSA, formFields.percentAchievedSA)
  }, [formFields.weightSA, formFields.percentAchievedSA])

  useEffect(() => {
    updatePoints(
      'pointsCharge',
      formFields.weightCharge,
      formFields.percentAchievedCharge,
      formFields.chargeGoal
    )
  }, [formFields.weightCharge, formFields.percentAchievedCharge, formFields.chargeGoal])

  useEffect(() => {
    updatePoints('pointsWebmotors', formFields.weightWebmotors, formFields.percentAchievedWebmotors)
  }, [formFields.weightWebmotors, formFields.percentAchievedWebmotors])

  useEffect(() => {
    updatePoints(
      'pointsMoreAccounts',
      formFields.weightMoreAccounts,
      formFields.percentAchievedMoreAccounts
    )
  }, [formFields.weightMoreAccounts, formFields.percentAchievedMoreAccounts])

  // --- Accelerators Section ---
  // Function to update Accelerators value for a given field

  const updateAccelerators = (
    acceleratorFieldName: keyof ResultsCalculatorFormFields,
    valueOne: number,
    valueTwo: number
  ) => {
    setValue(acceleratorFieldName, valueOne + valueTwo)
  }

  useEffect(() => {
    updateAccelerators(
      'acceleratorsActiveBase',
      formFields.acceleratorsActiveBaseOne,
      formFields.acceleratorsActiveBaseTwo
    )
  }, [formFields.acceleratorsActiveBaseOne, formFields.acceleratorsActiveBaseTwo])

  useEffect(() => {
    updateAccelerators(
      'acceleratorsLoyalty',
      formFields.acceleratorsLoyaltyOne,
      formFields.acceleratorsLoyaltyTwo
    )
  }, [formFields.acceleratorsLoyaltyOne, formFields.acceleratorsLoyaltyTwo])

  useEffect(() => {
    setValue(
      'finalAverage',
      (formFields.acceleratorsLoyalty + formFields.acceleratorsActiveBase) / 2
    )
  }, [formFields.acceleratorsLoyalty, formFields.acceleratorsActiveBase])

  useEffect(() => {
    setValue(
      'acceleratorProduction',
      formFields.percentAchievedProduction >= 90 ? formFields.finalAverage : 0
    )

    setValue('acceleratorSPF', formFields.percentAchievedSPF >= 90 ? formFields.finalAverage : 0)
  }, [formFields.finalAverage, formFields.percentAchievedProduction, formFields.percentAchievedSPF])

  // --- Accelerated Points Section ---
  // Function to update Accelerated Points value for a given field

  const updateAcceleratedPoints = (
    acceleratedPointsField: keyof ResultsCalculatorFormFields,
    points: number,
    weight: number,
    accelerator: number = 0,
    percentAchieved: number = 0
  ) => {
    const percentLimit = acceleratedPointsField.includes('Production') ? 200 : 150

    const maxPoints = (percentLimit * weight) / 100

    let appliedAccelerator = 0

    if (percentAchieved >= 90 && percentAchieved !== percentLimit) {
      appliedAccelerator = accelerator
    }

    let newPoints = points + appliedAccelerator

    // Ensure the total points do not exceed maxPoints
    if (newPoints > maxPoints) {
      newPoints = maxPoints
    }

    setValue(acceleratedPointsField, newPoints)
  }

  useEffect(() => {
    updateAcceleratedPoints(
      'acceleratedPointsProduction',
      formFields.pointsProduction,
      formFields.weightProduction,
      formFields.acceleratorProduction,
      formFields.percentAchievedProduction
    )
  }, [
    formFields.acceleratorProduction,
    formFields.weightProduction,
    formFields.pointsProduction,
    formFields.percentAchievedProduction
  ])

  useEffect(() => {
    updateAcceleratedPoints(
      'acceleratedPointsSPF',
      formFields.pointsSPF,
      formFields.weightSPF,
      formFields.acceleratorSPF,
      formFields.percentAchievedSPF
    )
  }, [
    formFields.acceleratorSPF,
    formFields.pointsSPF,
    formFields.weightSPF,
    formFields.percentAchievedSPF
  ])

  useEffect(() => {
    updateAcceleratedPoints('acceleratedPointsSA', formFields.pointsSA, formFields.weightSA)
  }, [formFields.pointsSA, formFields.weightSA])

  useEffect(() => {
    updateAcceleratedPoints(
      'acceleratedPointsCharge',
      formFields.pointsCharge,
      formFields.weightCharge
    )
  }, [formFields.chargeGoal, formFields.pointsCharge, formFields.weightCharge])

  useEffect(() => {
    updateAcceleratedPoints(
      'acceleratedPointsWebmotors',
      formFields.pointsWebmotors,
      formFields.weightWebmotors
    )
  }, [formFields.pointsWebmotors, formFields.weightWebmotors])

  useEffect(() => {
    updateAcceleratedPoints(
      'acceleratedPointsMoreAccounts',
      formFields.pointsMoreAccounts,
      formFields.weightMoreAccounts
    )
  }, [formFields.pointsMoreAccounts, formFields.weightMoreAccounts])

  const updateTotalAcceleratedPoints = () => {
    const acceleratedPointsValues = [
      formFields.acceleratedPointsProduction,
      formFields.acceleratedPointsSPF,
      formFields.acceleratedPointsSA,
      formFields.acceleratedPointsCharge,
      formFields.acceleratedPointsWebmotors,
      formFields.acceleratedPointsMoreAccounts
    ]

    const total = calculateTotal(acceleratedPointsValues)

    setValue('acceleratedPointsTotal', total > 150 ? 150 : total)
  }

  useEffect(() => {
    updateTotalAcceleratedPoints()
  }, [
    formFields.acceleratedPointsProduction,
    formFields.acceleratedPointsSPF,
    formFields.acceleratedPointsSA,
    formFields.acceleratedPointsCharge,
    formFields.acceleratedPointsWebmotors,
    formFields.acceleratedPointsMoreAccounts
  ])

  // --- RV Value Section ---
  // Function to update RV Value value for a given field

  const updateRvValue = (totalPoints: number, target: number) => {
    let newValue = 0

    if (totalPoints >= 100) newValue = calculateRVSimulated(totalPoints, target)

    setValue('rvValue', newValue)
  }

  useEffect(() => {
    updateRvValue(formFields.acceleratedPointsTotal, formFields.targetAccount)
  }, [formFields.acceleratedPointsTotal, formFields.targetAccount])

  // --- quadrant Section ---
  const [quadrant, setQuadrant] = useState(0)

  useEffect(() => {
    if (formFields.acceleratedPointsTotal >= 140.01) return setQuadrant(5)

    if (formFields.acceleratedPointsTotal >= 130.01) return setQuadrant(4)

    if (formFields.acceleratedPointsTotal >= 120.01) return setQuadrant(3)

    if (formFields.acceleratedPointsTotal >= 110.01) return setQuadrant(2)

    if (formFields.acceleratedPointsTotal >= 105.01) return setQuadrant(1)

    return setQuadrant(0)
  }, [formFields.acceleratedPointsTotal])

  // --- containsInsurance & multiplier & multipliedRV Section ---

  const [multiplierContainsInsurance, setMultiplierContainsInsurance] = useState(false)

  useEffect(() => {
    const containsInsurance = calculateWeightedAverageResults(
      formFields.weightSPF,
      formFields.acceleratedPointsSPF,
      formFields.weightSA,
      formFields.acceleratedPointsSA
    )

    setMultiplierContainsInsurance(containsInsurance)
  }, [
    formFields.weightSPF,
    formFields.acceleratedPointsSPF,
    formFields.weightSA,
    formFields.acceleratedPointsSA
  ])

  // --- Multiplier Section ---
  // Function to update Multiplier value for a given field

  useEffect(() => {
    setValue('multiplier', calculateResultsMultiplier(quadrant, multiplierContainsInsurance))
  }, [formFields.rvValue, quadrant, multiplierContainsInsurance])

  useEffect(() => {
    const newMultipliedRV = calculateRVMultiplied(formFields.rvValue, formFields.multiplier)

    setValue('multipliedRV', newMultipliedRV)
  }, [formFields.multiplier, formFields.rvValue])

  // --- RV Total Section ---
  useEffect(() => {
    const newTotalRV = calculateTotalWithPercentage(formFields.multipliedRV, formFields.performance)

    setValue('rvTotal', newTotalRV)
  }, [formFields.performance, formFields.multipliedRV])

  return {
    isMobile,
    userIsGR,

    control,
    errors,
    isDirty,

    targets: resultsTargets,
    selectedTarget,
    setSelectedTarget,

    openTooltip,
    closeTooltip,
    showTooltip,

    handleConfirmSimulation,
    handleResetSimulation,

    indicatorsLines,
    goalFields,
    goalOneFields,
    goalTwoFields,
    percentAchievedFields,
    percentAchievedOneFields,
    percentAchievedTwoFields,
    realizedFields,
    realizedOneFields,
    realizedTwoFields,
    weightFields,
    acceleratedPointsFields,
    pointsFields,
    acceleratorFields,

    chargeGoalValues,
    performanceValues,
    baseValues,
    loyaltyValues,
    multiplierContainsInsurance,

    isLoading: isLoadingResultsData,
    isSending: isSendingSimulation || isResettingResultsSimulation,
    successSave,
    savedIsOpen,
    savedOnClose
  }
}
