import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const EditIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 22.6024C0 22.0501 0.447715 21.6024 1 21.6024H22.6C23.1523 21.6024 23.6 22.0501 23.6 22.6024C23.6 23.1547 23.1523 23.6024 22.6 23.6024H1C0.447715 23.6024 0 23.1547 0 22.6024Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0751 2.19027L8.23463 13.0355L7.6621 16.2745L10.9029 15.7036L21.7454 4.8564C21.8056 4.79634 21.8535 4.72498 21.8861 4.64639C21.9188 4.5678 21.9356 4.48355 21.9356 4.39845C21.9356 4.31336 21.9188 4.2291 21.8861 4.15052C21.8535 4.07193 21.8056 4.00056 21.7454 3.94051L19.9951 2.19027C19.8731 2.0686 19.7075 2 19.5351 2C19.3628 2 19.1972 2.06868 19.0751 2.19027ZM17.6623 0.774634C18.1594 0.27859 18.8329 0 19.5351 0C20.2373 0 20.9109 0.27859 21.4079 0.774634L21.4086 0.775345L23.1569 2.5236C23.4034 2.76933 23.5996 3.06195 23.7331 3.38339C23.8668 3.70512 23.9356 4.05007 23.9356 4.39845C23.9356 4.74683 23.8668 5.09178 23.7331 5.41352C23.5997 5.73471 23.4043 6.02645 23.1581 6.27206L23.1569 6.27331L12.0944 17.3406C11.9492 17.4858 11.7629 17.5829 11.5606 17.6185L6.60222 18.4921C6.28086 18.5487 5.95208 18.445 5.72141 18.2142C5.49073 17.9834 5.38718 17.6545 5.44398 17.3332L6.31998 12.3772C6.35566 12.1753 6.45252 11.9893 6.59745 11.8443L17.6623 0.774634Z"
      fill="currentColor"
    />
  </Icon>
)
