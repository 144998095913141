import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef
} from 'react'

import {
  BottomSheetModal,
  BottomSheetView,
  useBottomSheetDynamicSnapPoints
} from '@gorhom/bottom-sheet'
import { LoadingTurbo } from 'atoms/LoadingTurbo/LoadingTurbo'
import CustomBottomSheetBackdrop from 'molecules/CustomBottomSheetBackdrop'
import { Center, Modal, useBreakpointValue, useDisclose } from 'native-base'

export const CalendarApresentation = forwardRef<
  Pick<BottomSheetModal, 'present' | 'close'>,
  PropsWithChildren<{ isLoading?: boolean }>
>(({ children, isLoading = false }, ref) => {
  const initialSnapPoints = useMemo(() => ['CONTENT_HEIGHT'], [])

  const { isOpen, onClose, onOpen } = useDisclose(false)

  const bottomSheetRef = useRef<BottomSheetModal>(null)

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } =
    useBottomSheetDynamicSnapPoints(initialSnapPoints)

  const renderBackdrop = useCallback(
    (props: any) => (
      <CustomBottomSheetBackdrop {...props} disappearsOnIndex={1} appearsOnIndex={2} ref={ref} />
    ),
    [ref]
  )

  useImperativeHandle(ref, () => ({
    present: () => (!isMobile ? onOpen() : bottomSheetRef.current?.present()),
    close: () => (!isMobile ? onClose() : bottomSheetRef.current?.close())
  }))

  return isMobile ? (
    <BottomSheetModal
      ref={bottomSheetRef}
      snapPoints={animatedSnapPoints}
      backdropComponent={renderBackdrop}
      handleHeight={animatedHandleHeight}
      contentHeight={animatedContentHeight}>
      <BottomSheetView
        style={{ paddingBottom: 40, position: 'relative' }}
        onLayout={handleContentLayout}>
        {isLoading && (
          <Center h="full" w="full" position="absolute" zIndex={9} bg="white" opacity={0.75}>
            <LoadingTurbo size={48} />
          </Center>
        )}
        {children}
      </BottomSheetView>
    </BottomSheetModal>
  ) : (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content minW="642px" borderRadius="20px" py="54px" px={8} bgColor="white">
        <Modal.Body>{children}</Modal.Body>
      </Modal.Content>
    </Modal>
  )
})
