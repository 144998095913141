import { FunctionComponent } from 'react'

import { IIconProps, Icon } from 'native-base'
import { Path } from 'react-native-svg'

export const ArrowLeftGoIcon: FunctionComponent<IIconProps> = ({ ...props }) => {
  return (
    <Icon {...{ viewBox: '0 0 19 12', ...props }}>
      <Path
        fill="currentColor"
        d="M17.6 6.6a.75.75 0 0 0 0-1.5H3.21l3.22-3.22A.75.75 0 0 0 5.37.82l-4.5 4.5a.75.75 0 0 0 0 1.06l4.5 4.5a.75.75 0 1 0 1.06-1.06L3.21 6.6H17.6Z"
      />
    </Icon>
  )
}
