import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { OptionsProfileScreenComponent } from './OptionsProfileScreen.types'
import { useOptionsProfileScreen } from './useOptionsProfileScreen'

const OptionsProfileScreenDesktop = lazy(() => import('./OptionsProfileScreen.desktop'))

const OptionsProfileScreenMobile = lazy(() => import('./OptionsProfileScreen.mobile'))

export const OptionsProfileScreen: OptionsProfileScreenComponent = ({ navigation }) => {
  const optionsProfileScreen = useOptionsProfileScreen({ navigation })

  const Screen = optionsProfileScreen.isMobile
    ? OptionsProfileScreenMobile
    : OptionsProfileScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...optionsProfileScreen} />
    </Suspense>
  )
}
