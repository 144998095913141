/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useState } from 'react'

import { useBreakpointValue } from 'native-base'
import { Dimensions, Platform } from 'react-native'
import { onlineManager } from 'react-query'

import { UsePointsArcProgress } from './PointsArcProgress.types'

const calculatePercent = (progress: number = 0): number => {
  if (!onlineManager.isOnline()) return 1

  let valuePercent = 0

  if (progress >= 0 && progress <= 100) {
    valuePercent = (5 * progress) / 10
  } else if (progress > 100 && progress <= 150) {
    const restValue = progress - 100

    valuePercent = 50 + restValue
  } else {
    valuePercent = 100
  }

  return valuePercent
}

const isIos = Platform.OS === 'ios'

const isOnline = onlineManager.isOnline()

const widthScreen = () => {
  const width = Dimensions.get('window').width

  if (width < 360) return 'small'

  if (width >= 360 && width <= 400) return 'medium'

  return 'big'
}

const isDesktop = Platform.OS === 'web' && Dimensions.get('window').width > 600

const configs = {
  small: {
    arc: {
      size: 230,
      width: 12
    },
    points: {
      '0': {
        bottom: '30%',
        left: '4%',
        text: {
          fontSize: 'xs'
        }
      },
      '100': {
        top: '0%',
        bar: {
          top: '10%',
          height: '12px'
        },
        text: {
          fontSize: 'xs',
          top: '2%'
        }
      },
      '105': {
        bar: {
          top: '10.8%',
          right: '43.3%',
          height: '12px',
          rotate: '11deg'
        },
        text: {
          fontSize: 'xs',
          top: '2.4%',
          right: '32%'
        }
      },
      '110': {
        bar: {
          height: '12px',
          top: '12.6%',
          right: '37%',
          rotate: '23deg'
        },
        text: {
          fontSize: 'xs',
          top: '7%',
          right: '23%'
        }
      },
      '120': {
        bar: {
          height: '12px',
          top: '20.5%',
          right: '26%',
          rotate: '43deg'
        },
        text: {
          fontSize: 'xs',
          top: '14%',
          right: '12.5%'
        }
      },
      '130': {
        bar: {
          height: '12px',
          top: '32%',
          right: '18.6%',
          rotate: '64deg'
        },
        text: {
          fontSize: '12px',
          top: '27%',
          right: '4.5%'
        }
      },
      '140': {
        bar: {
          height: '12px',
          top: '46.2%',
          right: '15.5%',
          rotate: '80deg'
        },
        text: {
          fontSize: 'xs',
          top: '43%',
          right: '0.9%'
        }
      },
      '150': {
        text: {
          fontSize: 'xs'
        },
        bottom: '30%',
        right: '7%'
      }
    },
    realized: {
      top: '25%',
      fontSize: 'xs',
      value: {
        fontSize: '30px'
      },
      points: {
        fontSize: '10px'
      }
    },
    projected: {
      bottom: '5%',
      left: '35%',
      fontSize: 'xs',
      value: {
        fontSize: 'xs'
      },
      points: {
        fontSize: '10px'
      }
    },
    simulated: {
      bottom: '5%',
      right: '16%',
      fontSize: 'xs',
      value: {
        fontSize: 'xs'
      },
      points: {
        fontSize: '10px'
      }
    }
  },
  medium: {
    arc: {
      size: isIos ? 260 : 270,
      width: 15
    },
    points: {
      '0': {
        bottom: '30%',
        left: '-3%',
        text: {
          fontSize: 'xs'
        }
      },
      '100': {
        top: '6%',
        bar: {
          top: '10%',
          height: '15px'
        },
        text: {
          fontSize: 'xs',
          top: isIos ? '2%' : '3%'
        }
      },
      '105': {
        bar: {
          top: isIos ? '10.6%' : '10.7%',
          right: '41.9%',
          height: '15px',
          rotate: '11deg'
        },
        text: {
          fontSize: 'xs',
          top: isIos ? '3%' : '3.4%',
          right: isIos ? '31%' : '32%'
        }
      },
      '110': {
        bar: {
          height: '15px',
          top: isIos ? '12.9%' : '12.8%',
          right: '34.6%',
          rotate: '23deg'
        },
        text: {
          fontSize: 'xs',
          top: isIos ? '7.2%' : '7.5%',
          right: isIos ? '20.2%' : '21%'
        }
      },
      '120': {
        bar: {
          height: '15px',
          top: isIos ? '21%' : '20.4%',
          right: '22.6%',
          rotate: '45deg'
        },
        text: {
          fontSize: 'xs',
          top: isIos ? '16%' : '15.5%',
          right: isIos ? '8%' : '9%'
        }
      },
      '130': {
        bar: {
          height: '15px',
          top: '32.8%',
          right: isIos ? '14.5%' : '13.5%',
          rotate: '67deg'
        },
        text: {
          fontSize: 'xs',
          top: '31%',
          right: isIos ? '-1%' : '-2%'
        }
      },
      '140': {
        bar: {
          height: '14px',
          top: '47%',
          right: isIos ? '11.2%' : '9.8%',
          rotate: '85deg'
        },
        text: {
          fontSize: 'xs',
          top: '45%',
          right: '-5.5%'
        }
      },
      '150': {
        text: {
          fontSize: 'xs'
        },
        bottom: '30%',
        right: isIos ? '1%' : '2%'
      }
    },
    realized: {
      top: '25%',
      fontSize: 'md',
      value: {
        fontSize: '36px'
      },
      points: {
        fontSize: isDesktop ? '16px' : '10px'
      }
    },
    projected: {
      bottom: '-1.5%',
      left: '35%',
      fontSize: 'xs',
      value: {
        fontSize: '14px'
      },
      points: {
        fontSize: '10px'
      }
    },
    simulated: {
      bottom: '-1.5%',
      right: '18%',
      fontSize: 'xs',
      value: {
        fontSize: '14px'
      },
      points: {
        fontSize: '10px'
      }
    }
  },
  big: {
    arc: {
      size: isDesktop ? 335 : 280,
      width: isDesktop ? 23 : 15
    },
    points: {
      '0': {
        bottom: isDesktop ? '16%' : '26%',
        left: isDesktop ? '-13%' : '-6%',
        text: {
          fontSize: 'sm'
        }
      },
      '100': {
        top: '6%',
        bar: {
          top: '10%',
          height: isDesktop ? '23px' : '15px'
        },
        text: {
          fontSize: 'xs',
          top: '2%'
        }
      },
      '105': {
        bar: {
          top: isDesktop ? '11.4%' : '10.6%',
          right: isDesktop ? '39.5%' : '41.9%',
          height: isDesktop ? '23px' : '15px',
          rotate: '11deg'
        },
        text: {
          fontSize: 'xs',
          top: '2.5%',
          right: '31%'
        }
      },
      '110': {
        bar: {
          height: isDesktop ? '21px' : '15px',
          top: isDesktop ? '14.3%' : '12.8%',
          right: isDesktop ? '30.2%' : '34.6%',
          rotate: isDesktop ? '24deg' : '23deg'
        },
        text: {
          fontSize: 'xs',
          top: '7.2%',
          right: '20.2%'
        }
      },
      '120': {
        bar: {
          height: isDesktop ? '22px' : '14px',
          top: isDesktop ? '24.4%' : '21.9%',
          right: isDesktop ? '15%' : '20.7%',
          rotate: '45deg'
        },
        text: {
          fontSize: 'xs',
          top: isDesktop ? '18%' : '16%',
          right: isDesktop ? '2%' : '7%'
        }
      },
      '130': {
        bar: {
          height: isDesktop ? '22px' : '14px',
          top: isDesktop ? '39.4%' : '35%',
          right: isDesktop ? '4.7%' : '11.8%',
          rotate: isDesktop ? '65deg' : '67deg'
        },
        text: {
          fontSize: 'xs',
          top: isDesktop ? '36.4%' : '31.4%',
          right: isDesktop ? '-11%' : '-3%'
        }
      },
      '140': {
        bar: {
          height: isDesktop ? '22px' : '14px',
          top: isDesktop ? '57%' : '50%',
          right: isDesktop ? '0.6%' : '8.1%',
          rotate: isDesktop ? '84deg' : '81deg'
        },
        text: {
          fontSize: 'xs',
          top: isDesktop ? '56%' : '47%',
          right: isDesktop ? '-16%' : '-7%'
        }
      },
      '150': {
        text: {
          fontSize: 'sm'
        },
        bottom: isDesktop ? '16%' : '28%',
        right: isDesktop ? '-9%' : '-2%'
      }
    },
    realized: {
      top: '27%',
      fontSize: 'md',
      value: {
        fontSize: '36px'
      },
      points: {
        fontSize: '10px'
      }
    },
    projected: {
      bottom: '-1.5%',
      left: '35%',
      fontSize: 'xs',
      value: {
        fontSize: '14px'
      },
      points: {
        fontSize: '10px'
      }
    },
    simulated: {
      bottom: '-1.5%',
      right: '12%',
      fontSize: 'xs',
      value: {
        fontSize: '14px'
      },
      points: {
        fontSize: '10px'
      }
    }
  }
}

const positions = configs[widthScreen()]

const heightBox = widthScreen() === 'small' ? 72 : 80

export const usePointsArcProgress: UsePointsArcProgress = ({
  isAwaitingReceiveFullRvData,
  msgAwaitingReceiveFullRvData,
  potentials,
  projected,
  realized,
  simulated,
  isLoading
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const verifyData = (data: string | number) => isOnline && !isLoading && data

  const [progressRealized, setProgressRealized] = useState(0)

  const [progressProjected, setProgressProjected] = useState(0)

  const [progressSimulated, setProgressSimulated] = useState(0)

  const projectedPotential = useMemo(
    () => potentials.filter((potential) => potential.points === projected.pointsAmount),
    [potentials, projected.pointsAmount]
  )

  const simulatedPotential = useMemo(
    () => potentials.filter((potential) => potential.points === simulated.pointsAmount),
    [potentials, simulated.pointsAmount]
  )

  const [openTooltip105, setOpenTooltip105] = useState(false)

  const [openTooltip110, setOpenTooltip110] = useState(false)

  const [openTooltip120, setOpenTooltip120] = useState(false)

  const [openTooltip130, setOpenTooltip130] = useState(false)

  const [openTooltip140, setOpenTooltip140] = useState(false)

  const [openTooltip150, setOpenTooltip150] = useState(false)

  const [openTooltipProjected, setOpenTooltipProjected] = useState(false)

  const [openTooltipSimulated, setOpenTooltipSimulated] = useState(false)

  useEffect(() => {
    if (realized.current !== undefined) {
      const sanitizedProgressRealized = calculatePercent(Number(realized.current))

      setProgressRealized(sanitizedProgressRealized)
    }
  }, [realized])

  useEffect(() => {
    if (projected.current !== undefined) {
      const sanitizedProgressProjected = calculatePercent(Number(projected.current))

      setProgressProjected(sanitizedProgressProjected)
    }
  }, [projected])

  useEffect(() => {
    if (simulated.current !== undefined) {
      const sanitizedProgressSimulated = calculatePercent(Number(simulated.current))

      setProgressSimulated(sanitizedProgressSimulated)
    }
  }, [simulated])

  return {
    isMobile,
    isOnline,
    isIos,
    positions,
    heightBox,
    verifyData,
    arcProgressData: {
      isAwaitingReceiveFullRvData,
      msgAwaitingReceiveFullRvData,
      potentials,
      projected,
      realized,
      simulated,
      isLoading
    },
    progressRealized,
    progressProjected,
    progressSimulated,
    projectedPotential,
    simulatedPotential,
    openTooltip105,
    setOpenTooltip105,
    openTooltip110,
    setOpenTooltip110,
    openTooltip120,
    setOpenTooltip120,
    openTooltip130,
    setOpenTooltip130,
    openTooltip140,
    setOpenTooltip140,
    openTooltip150,
    setOpenTooltip150,
    openTooltipProjected,
    setOpenTooltipProjected,
    openTooltipSimulated,
    setOpenTooltipSimulated
  }
}
