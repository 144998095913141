import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect
} from 'react'

import { NavigationContainerRefWithCurrent } from '@react-navigation/native'
import { RootStackParamList } from 'navigation/types'
import { AppState, AppStateStatus, Platform } from 'react-native'
import { useDeviceBlockControl } from 'src/hooks/useDeviceBlockControl'

type BlockScreenContextValues = ReturnType<typeof useDeviceBlockControl>

const BlockScreenContext = createContext<BlockScreenContextValues>({} as BlockScreenContextValues)

export const useBlockScreenContext = (): BlockScreenContextValues => {
  const context = useContext(BlockScreenContext)

  return context
}

type TBlockScreenProviderProps = FunctionComponent<
  PropsWithChildren<{
    refNavigation?: NavigationContainerRefWithCurrent<RootStackParamList>
  }>
>

export const BlockScreenProvider: TBlockScreenProviderProps = ({ children, refNavigation }) => {
  const deviceBlockControl = useDeviceBlockControl({
    refNavigation
  })

  const _handleAppStateChanged = useCallback(
    async (nextAppState: AppStateStatus) => {
      if (Platform.OS !== 'web') {
        if (nextAppState === 'active') {
          await deviceBlockControl.verifyIfUserCanBeContinueLogged()
        } else if (nextAppState === 'background' || nextAppState === 'inactive') {
          await deviceBlockControl.setLastTimeToUsed()
        }
      }
    },
    [deviceBlockControl]
  )

  useEffect(() => {
    const subscription = AppState.addEventListener('change', _handleAppStateChanged)

    return () => {
      subscription.remove()
    }
  }, [_handleAppStateChanged])

  return (
    <BlockScreenContext.Provider value={deviceBlockControl}>{children}</BlockScreenContext.Provider>
  )
}
