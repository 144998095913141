import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { OpportunityFilterScreenComponent } from './OpportunityFilterScreen.types'
import { useOpportunityFilterScreen } from './useOpportunityFilterScreen'

const OpportunityFilterScreenDesktop = lazy(() => import('./OpportunityFilterScreen.desktop'))

const OpportunityFilterScreenMobile = lazy(() => import('./OpportunityFilterScreen.mobile'))

export const OpportunityFilterScreen: OpportunityFilterScreenComponent = ({
  navigation,
  route
}) => {
  const opportunityFilterScreen = useOpportunityFilterScreen({ navigation, route })

  const Screen = opportunityFilterScreen.isMobile
    ? OpportunityFilterScreenMobile
    : OpportunityFilterScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...opportunityFilterScreen} />
    </Suspense>
  )
}
