import * as serializers from './serializers'
import { GetStoresListParams, ProductionHistorySerialized, ProductionStoresList } from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const getProductionHistory = async ({ isMobile = true }) =>
  serializers.getProductionHistory(
    await client.get<ResponseSuccess<ProductionHistorySerialized>>(`v1/wallets/production-history`),
    isMobile
  )

export const getProductionHistoryMonths = async ({ isMobile = true }) =>
  serializers.getProductionHistoryMonths(
    await client.get<ResponseSuccess<ProductionHistorySerialized>>(`v1/wallets/production-history`)
  )

export const getProductionStoresList = async (params: GetStoresListParams) =>
  await client.get<ResponseSuccess<ProductionStoresList>>('v1/stores', {
    params: {
      ...params,
      order_by: params?.order_by,
      per_page: params?.per_page ?? 10,
      page: params?.page ?? 1
    }
  })
