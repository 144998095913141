import { AxiosError, AxiosResponse } from 'axios'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

// eslint-disable-next-line import/order
import { ResponseError, ResponseSuccess } from '../../types'
import * as requests from './requests'
import { Dashboard, DashboardDetail, GetDashboardsParams } from './types'

export const KEY_DASHBOARDS_QUERY = 'getDashboards'

export const KEY_DASHBOARD_ITEM_QUERY = 'getDashboard'

export const useGetDashboardsInfiniteQuery = (params?: GetDashboardsParams) => {
  return useInfiniteQuery<AxiosResponse<ResponseSuccess<Dashboard[]>>, AxiosError<ResponseError>>(
    [KEY_DASHBOARDS_QUERY],
    ({ pageParam }) => requests.getDashboards({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )
}

export const useGetDashboardQuery = (dashboardId: string) => {
  const queryClient = useQueryClient()

  return useQuery<AxiosResponse<ResponseSuccess<DashboardDetail>>, AxiosError<ResponseError>>(
    [`${KEY_DASHBOARD_ITEM_QUERY}.${dashboardId}`],
    () => requests.getDashboard(dashboardId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEY_DASHBOARDS_QUERY)
      }
    }
  )
}

export const useFavoriteDashboardMutation = (dashboardId?: string) => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError<ResponseError>, Pick<Dashboard, 'id'>>(
    requests.favoriteDashboard,
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries([KEY_DASHBOARDS_QUERY])

        queryClient.invalidateQueries([`${KEY_DASHBOARD_ITEM_QUERY}.${id}`])
      }
    }
  )
}

export const useUnfavoriteDashboardMutation = (dashboardId?: string) => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError<ResponseError>, Pick<Dashboard, 'id'>>(
    requests.unfavoriteDashboard,
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries([KEY_DASHBOARDS_QUERY, KEY_DASHBOARDS_QUERY])

        queryClient.invalidateQueries([`${KEY_DASHBOARD_ITEM_QUERY}.${id}`])
      }
    }
  )
}
