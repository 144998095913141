import { FunctionComponent, useCallback, useMemo } from 'react'

import { Cards } from 'atoms'
import { OpportunitiesSummary, SummaryItem } from 'integration/resources/newOpportunities'
import { AnimatedSlideDot } from 'molecules/AnimatedSlideDot/AnimatedSlideDot'
import { Box, Button, HStack, Skeleton, useTheme, VStack } from 'native-base'
import { TouchableOpacity, useWindowDimensions } from 'react-native'
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated'
import { ListType } from 'src/screens/OrganizationScreen/OrganizationScreen.types'

type OpportunitiesListProps = {
  data: OpportunitiesSummary
  isLoading?: boolean
  handleGoToOpportunitiesList(listType?: ListType): void
}

export const OpportunitiesList: FunctionComponent<OpportunitiesListProps> = ({
  data,
  isLoading,
  handleGoToOpportunitiesList
}) => {
  const opportunitiesList = useMemo(
    () => [data.odc, data.activeBase, data.insuranceAuto, data.charge],
    [data]
  )

  const theme = useTheme()

  const scrollX = useSharedValue(1)

  const scrollHandler = useAnimatedScrollHandler((event) => {
    scrollX.value = event.contentOffset.x
  })

  const { width: SCREEN_WIDTH } = useWindowDimensions()

  const sliderDots = opportunitiesList

  const renderItem = useCallback(
    ({ item }: { item: SummaryItem }) => {
      return (
        <TouchableOpacity key={Math.random()}>
          <Box mr={4} ml={item.id === opportunitiesList[0].id ? 4 : 0}>
            <Cards.Opportunities
              opportunity={item}
              handleGoToOpportunitiesList={handleGoToOpportunitiesList}
              key={item.id}
            />
          </Box>
        </TouchableOpacity>
      )
    },
    [opportunitiesList, handleGoToOpportunitiesList]
  )

  return (
    <VStack space={8}>
      {!isLoading ? (
        <>
          <Box position="relative">
            <Animated.FlatList
              data={opportunitiesList}
              showsHorizontalScrollIndicator={false}
              onScroll={scrollHandler}
              horizontal
              decelerationRate="normal"
              scrollEventThrottle={16}
              renderItem={renderItem}
            />
          </Box>

          <Box px={4} mt={-2}>
            <HStack alignItems="center" justifyContent="space-between">
              <HStack space={1}>
                {sliderDots.map((_, index) => (
                  <AnimatedSlideDot
                    index={index}
                    key={index}
                    scrollX={scrollX}
                    slideWidth={SCREEN_WIDTH * 0.8}
                    color={theme.colors.tertiary[800]}
                  />
                ))}
              </HStack>

              <Button
                bg="tertiary.100"
                size="md"
                p={0}
                _stack={{ flex: 1, justifyContent: 'center' }}
                onPress={() => handleGoToOpportunitiesList()}
                w={20}
                _text={{
                  color: 'tertiary.800',
                  textTransform: 'uppercase'
                }}
                _pressed={{
                  backgroundColor: 'tertiary.100'
                }}>
                ver tudo
              </Button>
            </HStack>
          </Box>
        </>
      ) : (
        <HStack>
          {[0, 1, 2, 3].map((item) => (
            <Skeleton key={item} h="200px" w="304px" borderRadius={20} ml={4} />
          ))}
        </HStack>
      )}
    </VStack>
  )
}
