import { memo } from 'react'

import Svg, { Path } from 'react-native-svg'

const HeartIcon = () => (
  <Svg width={12} height={13} fill="none" viewBox="0 0 12 13">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.043 1.11c-.3-.351-.657-.63-1.052-.821A2.853 2.853 0 0 0 8.747 0c-.427 0-.85.098-1.244.289-.395.19-.752.47-1.052.821L6 1.631l-.451-.52C4.94.404 4.114.007 3.252.007c-.862 0-1.69.395-2.3 1.1C.344 1.815 0 2.77 0 3.769c0 .997.342 1.954.951 2.66l5.045 5.836 5.044-5.837c.304-.347.546-.76.71-1.217.165-.456.25-.945.25-1.44 0-.494-.085-.983-.25-1.44a3.814 3.814 0 0 0-.71-1.216"
      fill="#fff"
    />
  </Svg>
)

export const Heart = memo(HeartIcon)
