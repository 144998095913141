import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { RemoveAccountConfirmScreenComponent } from './RemoveAccountConfirmScreen.types'
import { useRemoveAccountConfirmScreen } from './useRemoveAccountConfirmScreen'

const RemoveAccountConfirmDesktop = lazy(() => import('./RemoveAccountConfirmScreen.desktop'))

const RemoveAccountConfirmMobile = lazy(() => import('./RemoveAccountConfirmScreen.mobile'))

export const RemoveAccountConfirmScreen: RemoveAccountConfirmScreenComponent = ({ navigation }) => {
  const profileScreen = useRemoveAccountConfirmScreen({ navigation })

  const Screen = profileScreen.isMobile ? RemoveAccountConfirmMobile : RemoveAccountConfirmDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...profileScreen} />
    </Suspense>
  )
}
