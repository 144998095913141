import { FunctionComponent, Suspense, lazy } from 'react'

import { Skeleton } from 'native-base'

import { RecommendationsProps } from './Recommendations.types'
import { useRecommendations } from './useRecommendations'

const RecommendationsDesktop = lazy(() => import('./Recommendations.desktop'))

const RecommendationsMobile = lazy(() => import('./Recommendations.mobile'))

export const Recommendations: FunctionComponent<RecommendationsProps> = (props) => {
  const recommendations = useRecommendations(props)

  const Component = recommendations.isMobile ? RecommendationsMobile : RecommendationsDesktop

  return (
    <Suspense fallback={<Skeleton minHeight="104px" mb={4} borderRadius="20px" />}>
      <Component {...recommendations} />
    </Suspense>
  )
}
