import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { SecurityProfileScreenComponent } from './SecurityProfileScreen.types'
import { useSecurityProfileScreen } from './useSecurityProfileScreen'

const SecurityProfileScreenDesktop = lazy(() => import('./SecurityProfileScreen.desktop'))

const SecurityProfileScreenMobile = lazy(() => import('./SecurityProfileScreen.mobile'))

export const SecurityProfileScreen: SecurityProfileScreenComponent = ({ navigation }) => {
  const securityProfileScreen = useSecurityProfileScreen({ navigation })

  const Screen = securityProfileScreen.isMobile
    ? SecurityProfileScreenMobile
    : SecurityProfileScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...securityProfileScreen} />
    </Suspense>
  )
}
