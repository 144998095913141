import { memo, useCallback, useRef, useState } from 'react'

import DateTimePicker from '@react-native-community/datetimepicker'
import { Icons, Avatar, Calendar } from 'atoms'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Store } from 'integration/resources/store'
import { BottomSheetModal } from 'molecules'
import {
  Box,
  Button,
  Center,
  FormControl,
  Heading,
  HStack,
  Input,
  Pressable,
  Spinner,
  Switch,
  Text,
  VStack
} from 'native-base'
import { BottomSheetJob, BottomSheetStores } from 'organisms'
import { Controller } from 'react-hook-form'
import { Platform } from 'react-native'
import { MaskService } from 'react-native-masked-text'

import { ContactFormTemplateComponent } from './ContactFormTemplate.types'

export const ContactFormTemplate: ContactFormTemplateComponent = memo(
  ({
    control,
    nameContact,
    submit,
    isLoading,
    handleAddedJob,
    handleAddedBirthDate,
    date,
    showDate,
    showDatePicker,
    handleAddedTabNumber,
    type,
    isLoadingPhoto,
    handleShowOptionsCamera
  }) => {
    const [openStores, setOpenStores] = useState(false)

    const isMobile = Platform.OS !== 'web'

    const refCalendar = useRef<BottomSheetModal>(null)

    const handleSelectStore = (store: Store) => {
      handleAddedTabNumber(store)

      setOpenStores(!openStores)
    }

    const jobSheetRef = useRef<BottomSheetModal>(null)

    const openJobSheet = useCallback(() => {
      jobSheetRef.current?.present()
    }, [])

    const closeJobSheet = useCallback(() => {
      jobSheetRef.current?.close()

      jobSheetRef.current?.dismiss()
    }, [])

    const storeSheetRef = useRef<BottomSheetModal>(null)

    const openStoreSheet = useCallback(() => {
      storeSheetRef.current?.present()
    }, [])

    const closeStoreSheet = useCallback(() => {
      storeSheetRef.current?.close()

      storeSheetRef.current?.dismiss()
    }, [])

    return (
      <>
        <VStack safeAreaBottom paddingBottom="72px">
          <VStack mt={isMobile ? 0 : 8} p={0.5}>
            {!isMobile && (
              <Text color="gray.400" fontSize="16px">
                Alterar foto do Perfil
              </Text>
            )}
            <Center mb={type === 'edit' ? 6 : 0}>
              {type === 'edit' ? (
                <Controller
                  control={control}
                  name="profile_image_url"
                  render={({ field: { value } }) => (
                    <Center>
                      <Box position="relative">
                        <Pressable
                          onPress={handleShowOptionsCamera}
                          w="100px"
                          h="100px"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="full">
                          <Center position="relative" opacity={isLoadingPhoto ? 0.5 : 1}>
                            <Avatar
                              type="dark"
                              size={25}
                              fontSize={37}
                              uri={value as string}
                              title={nameContact}
                            />
                            {isLoadingPhoto && (
                              <Spinner style={{ position: 'absolute' }} color="white" />
                            )}
                          </Center>
                        </Pressable>

                        <Box
                          rounded="full"
                          p={1}
                          bg="gray.700"
                          opacity={0.7}
                          position="absolute"
                          right="4"
                          bottom="-2">
                          <Icons.Edit color="white" size={3} />
                        </Box>
                      </Box>
                    </Center>
                  )}
                />
              ) : (
                <Controller
                  control={control}
                  name="profile_image_url"
                  render={({ field: { value } }) => (
                    <Box position="relative" mb={4}>
                      <Pressable
                        onPress={handleShowOptionsCamera}
                        bg={isMobile ? 'white' : 'gray-gradient.200'}
                        w="100px"
                        h="100px"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="full">
                        {value ? (
                          <Center opacity={isLoadingPhoto ? 0.5 : 1}>
                            <Avatar type="dark" size={25} fontSize={37} uri={value}>
                              <Icons.Camera color="white" size={5} />
                            </Avatar>
                            {isLoadingPhoto && (
                              <Spinner style={{ position: 'absolute' }} color="white" />
                            )}
                          </Center>
                        ) : (
                          <Icons.Camera color="gray.200" size={5} />
                        )}
                      </Pressable>
                      <Box
                        rounded="full"
                        p={1}
                        bg="gray.700"
                        opacity={0.7}
                        position="absolute"
                        right="4"
                        bottom="-2">
                        <Icons.Edit color="white" size={3} />
                      </Box>
                    </Box>
                  )}
                />
              )}
            </Center>

            {type === 'edit' && <Box pb="32px" borderTopWidth="1px" borderColor="gray.100" />}

            <Controller
              control={control}
              render={({
                field: { onChange: onChangeText, onBlur, value },
                formState: { errors }
              }) => (
                <FormControl isInvalid={!!errors.name} isRequired>
                  <Text color="gray.400" fontSize="16px">
                    Nome
                  </Text>

                  <Input
                    {...{ onChangeText, onBlur, value }}
                    autoCapitalize="none"
                    autoCorrect={false}
                    enablesReturnKeyAutomatically
                    flex={1}
                    mt={2}
                    p={0}
                    pl="0px"
                    pr="6px"
                    fontSize="16px"
                    lineHeight="24px"
                    bg={isMobile ? 'white' : 'gray.30'}
                    InputLeftElement={
                      <Icons.User color="gray.700" ml={isMobile ? 4 : 0} mr={3} size={5} />
                    }
                    placeholder="Nome"
                  />
                  {errors.name && (
                    <FormControl.ErrorMessage>{errors.name.message}</FormControl.ErrorMessage>
                  )}
                </FormControl>
              )}
              name="name"
            />

            <Controller
              control={control}
              render={({ field: { value }, formState: { errors } }) => (
                <FormControl pt={4} isInvalid={!!errors.jobTitle} isRequired>
                  <Text color="gray.400" fontSize="16px">
                    Função
                  </Text>

                  <Button
                    h="50px"
                    bg={isMobile ? 'white' : 'gray.30'}
                    onPress={openJobSheet}
                    borderColor={errors.jobTitle ? 'error.500' : ''}
                    borderWidth={errors.jobTitle ? 1 : 0}
                    mt={2}
                    _pressed={{
                      bg: 'white'
                    }}
                    _hover={{
                      bg: 'gray.30'
                    }}
                    pl={2}
                    position="relative"
                    justifyContent="space-between">
                    <HStack alignItems="center" space={4}>
                      <Icons.Role color="gray.700" ml={1} mr={1} size={5} />

                      {value ? (
                        <Center h={7} py={1} px={4} bg="tertiary.800" borderRadius="full" mr="auto">
                          <Heading fontSize="12px" color="white" textTransform="uppercase">
                            {value}
                          </Heading>
                        </Center>
                      ) : (
                        <Text color="gray.400" fontSize={16}>
                          Selecione uma Função
                        </Text>
                      )}
                    </HStack>
                  </Button>
                  <Icons.Dropdown color="gray.700" position="absolute" top={18} right={0} mr={4} />

                  {errors.jobTitle && (
                    <FormControl.ErrorMessage>{errors.jobTitle.message}</FormControl.ErrorMessage>
                  )}
                </FormControl>
              )}
              name="jobTitle"
            />

            <Controller
              control={control}
              render={({
                field: { onChange: onChangeText, onBlur, value },
                formState: { errors }
              }) => (
                <FormControl pt={4} isInvalid={!!errors.email} isRequired>
                  <Text color="gray.400" fontSize="16px">
                    E-mail
                  </Text>

                  <Input
                    {...{ onChangeText, onBlur, value }}
                    autoCapitalize="none"
                    autoCorrect={false}
                    enablesReturnKeyAutomatically
                    flex={1}
                    mt={2}
                    pl={0}
                    pr="6px"
                    fontSize="16px"
                    lineHeight="24px"
                    bg={isMobile ? 'white' : 'gray.30'}
                    InputLeftElement={
                      <Icons.Mail color="gray.700" ml={isMobile ? 4 : 0} mr={3} size={5} />
                    }
                    placeholder="Email"
                  />
                  {errors.email && (
                    <FormControl.ErrorMessage>{errors.email.message}</FormControl.ErrorMessage>
                  )}
                </FormControl>
              )}
              name="email"
            />

            <Controller
              control={control}
              render={({ field: { value }, formState: { errors } }) => (
                <FormControl pt={4} isInvalid={!!errors.store_name} isRequired>
                  <Text color="gray.400" fontSize="16px">
                    Loja(TAB)
                  </Text>

                  <Button
                    h="50px"
                    bg={isMobile ? 'white' : 'gray.30'}
                    onPress={openStoreSheet}
                    borderColor={errors.store_name ? 'error.500' : ''}
                    borderWidth={errors.store_name ? 1 : 0}
                    mt={2}
                    _pressed={{
                      bg: 'white'
                    }}
                    _hover={{
                      bg: 'gray.30'
                    }}
                    position="relative"
                    justifyContent="flex-start"
                    startIcon={<Icons.Group color="gray.700" ml={1} mr={1} size={5} />}>
                    {value ? (
                      <Text color="gray.700" fontSize={16}>
                        {value}
                      </Text>
                    ) : (
                      <Text color="gray.400" fontSize={16}>
                        Escolher loja (TAB)
                      </Text>
                    )}
                  </Button>

                  <Icons.Dropdown color="gray.700" position="absolute" top={18} right={0} mr={4} />

                  {errors.store_name && (
                    <FormControl.ErrorMessage>{errors.store_name.message}</FormControl.ErrorMessage>
                  )}
                </FormControl>
              )}
              name="store_name"
            />

            <Controller
              control={control}
              render={({
                field: { onChange: onChangeText, onBlur, value },
                formState: { errors }
              }) => (
                <FormControl pt={4} isInvalid={!!errors.phone_number} isRequired>
                  <Text color="gray.400" fontSize="16px">
                    Telefone
                  </Text>

                  <Input
                    {...{
                      onChangeText,
                      onBlur,
                      value: MaskService.toMask('cel-phone', value ?? '')
                    }}
                    keyboardType="number-pad"
                    autoCapitalize="none"
                    autoCorrect={false}
                    enablesReturnKeyAutomatically
                    flex={1}
                    mt={2}
                    pl={0}
                    pr="6px"
                    fontSize="16px"
                    lineHeight="24px"
                    bg={isMobile ? 'white' : 'gray.30'}
                    InputLeftElement={
                      <Icons.Phone color="gray.700" ml={isMobile ? 4 : 0} mr={3} size={5} />
                    }
                    placeholder="(00) 00000-0000"
                  />
                  {errors.phone_number && (
                    <FormControl.ErrorMessage>
                      {errors.phone_number.message}
                    </FormControl.ErrorMessage>
                  )}
                </FormControl>
              )}
              name="phone_number"
            />

            {type === 'edit' && (
              <Controller
                control={control}
                render={({ field: { value }, formState: { errors } }) => (
                  <FormControl pt={4} isInvalid={!!errors.phone_number} isRequired>
                    <Text color="gray.400" fontSize="16px">
                      Endereço
                    </Text>

                    <HStack mt={2}>
                      <Center bg="white" size="34px" borderRadius="full" mr={4}>
                        <Icons.Location size="18px" color="gray.700" />
                      </Center>
                      <Text maxW="85%" textTransform="capitalize" fontSize="16px">
                        {value ? (
                          <>
                            {value.substr(0, value?.length - 2)}
                            <Text textTransform="uppercase" fontSize="16px">
                              {value.substr(-2)}
                            </Text>
                          </>
                        ) : (
                          'Sem endereço'
                        )}
                      </Text>
                    </HStack>

                    {errors.address_street && (
                      <FormControl.ErrorMessage>
                        {errors.address_street.message}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                )}
                name="address_street"
              />
            )}

            <Box position="relative" pt={4}>
              <Text color="gray.400" fontSize="16px">
                Data de aniversário
              </Text>

              <Controller
                control={control}
                name="birth_date"
                render={({ fieldState: { error }, field: { value } }) => {
                  return (
                    <FormControl isInvalid={!!error?.message}>
                      <Pressable
                        onPress={() =>
                          isMobile ? showDatePicker() : refCalendar.current?.present()
                        }>
                        <Box
                          mt={2}
                          bg={isMobile ? 'white' : 'gray.30'}
                          width="full"
                          height="50px"
                          borderColor={!error?.message ? 'none' : 'error.500'}
                          borderWidth={error?.message ? 1 : 0}
                          borderRadius="40px">
                          <HStack alignItems="center" flex={1}>
                            <Icons.Gift color="gray.700" ml={4} size="18px" />
                            <Text
                              pl={2}
                              color={!value ? (error ? 'error.500' : 'gray.400') : 'gray.900'}
                              fontSize="16px">
                              {!value
                                ? 'Data'
                                : format(
                                    typeof date === 'string' ? parseISO(date) : date,
                                    "dd 'de' MMMM 'de' yyyy",
                                    {
                                      locale: ptBR
                                    }
                                  )}
                            </Text>
                          </HStack>
                          {isMobile && showDate && (
                            <DateTimePicker
                              value={date}
                              style={{
                                flex: 1,
                                position: 'absolute',
                                zIndex: 2,
                                justifyContent: 'center',
                                right: 150,
                                left: 0,
                                height: 50,
                                opacity: 0.02
                              }}
                              mode="date"
                              onChange={handleAddedBirthDate}
                            />
                          )}
                        </Box>
                      </Pressable>
                      {error?.message && (
                        <FormControl.ErrorMessage>{error.message}</FormControl.ErrorMessage>
                      )}
                    </FormControl>
                  )
                }}
              />
            </Box>
            {type === 'create' && (
              <Controller
                control={control}
                name="is_favorite"
                render={({ field: { value, onChange } }) => {
                  return (
                    <HStack pt={4} alignItems="center" justifyContent="space-between">
                      <Text color="gray.500" fontSize="16px" lineHeight="24px">
                        Tornar como favorito
                      </Text>
                      <Switch
                        size={Platform.OS === 'android' ? 'md' : 'sm'}
                        value={value}
                        onValueChange={(checked) => onChange(checked)}
                      />
                    </HStack>
                  )
                }}
              />
            )}

            <Controller
              control={control}
              name="jobTitle"
              render={({ field }) => {
                return (
                  <BottomSheetJob
                    value={field.value}
                    onChange={field.onChange}
                    onSelect={() => [handleAddedJob(), closeJobSheet()]}
                    ref={jobSheetRef}
                  />
                )
              }}
            />

            <Controller
              control={control}
              name="store_name"
              render={({ field }) => {
                return (
                  <BottomSheetStores
                    ref={storeSheetRef}
                    handleSelectStore={(e: Store) => {
                      handleSelectStore(e)

                      closeStoreSheet()

                      field.onChange(e.name_fantasy)
                    }}
                  />
                )
              }}
            />
          </VStack>

          <Button onPress={submit} isLoading={isLoading} mt="34px" colorScheme="gray">
            {type === 'edit' ? 'Salvar alterações' : 'Salvar'}
          </Button>
        </VStack>

        <Calendar.Apresentation ref={refCalendar} isLoading={isLoading}>
          <Calendar.Body
            daySelected={
              typeof date === 'object' ? format(date, 'yyyy-MM-dd', { locale: ptBR }) : date
            }
            enableSelectYear
            onDaySelected={(date) => {
              handleAddedBirthDate(null, date)

              refCalendar.current?.close()
            }}
          />
        </Calendar.Apresentation>
      </>
    )
  }
)
