import { AxiosError, AxiosResponse } from 'axios'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

// eslint-disable-next-line import/order
import { ResponseError, ResponseSuccess } from '../../types'
import * as requests from './requests'
import { GetKnowledgeBasesParams, ContentSerialized, Category, Content } from './types'

export const KEY_KNOWLEGDE_BASES_QUERY = 'getKnowledgeBases'

export const KEY_KNOWLEGDE_ITEM_QUERY = 'getKnowledge'

export const useGetKnowledgeBasesInfiniteQuery = (params?: GetKnowledgeBasesParams) => {
  return useInfiniteQuery<
    AxiosResponse<ResponseSuccess<ContentSerialized[]>>,
    AxiosError<ResponseError>
  >(
    [`${KEY_KNOWLEGDE_BASES_QUERY}.${JSON.stringify(params)}`],
    ({ pageParam }) => requests.getKnowledgeBases({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )
}

export const useGetKnowledgeBasesFavoriteInfiniteQuery = (params?: GetKnowledgeBasesParams) => {
  return useInfiniteQuery<
    AxiosResponse<ResponseSuccess<ContentSerialized[]>>,
    AxiosError<ResponseError>
  >(
    [`${KEY_KNOWLEGDE_BASES_QUERY}.favorite`],
    ({ pageParam }) => requests.getKnowledgeBases({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )
}

export const useGetKnowledgeBasesHighlightInfiniteQuery = (params?: GetKnowledgeBasesParams) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<ContentSerialized[]>>, AxiosError<ResponseError>>(
    [`${KEY_KNOWLEGDE_BASES_QUERY}.featured`],
    ({ pageParam }) => requests.getKnowledgeBases({ ...params, page: pageParam, is_featured: 1 }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )

export const useGetKnowledgeQuery = (contentId: string) => {
  const queryClient = useQueryClient()

  return useQuery<AxiosResponse<ResponseSuccess<ContentSerialized>>, AxiosError<ResponseError>>(
    [`${KEY_KNOWLEGDE_ITEM_QUERY}.${contentId}`],
    () => requests.getKnowledge(contentId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEY_KNOWLEGDE_BASES_QUERY)
      }
    }
  )
}

export const useGetCategoriesQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<Category[]>>, AxiosError<ResponseError>>(
    'getCategories',
    requests.getCategories
  )

export const useFavoriteKnowledgeMutation = (contentId: string) => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<Content, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<Content, 'id'>
  >(requests.favoriteKnowledge, {
    onSuccess: () => {
      queryClient.invalidateQueries(`${KEY_KNOWLEGDE_ITEM_QUERY}.${contentId}`)

      queryClient.invalidateQueries(`${KEY_KNOWLEGDE_BASES_QUERY}.favorite`)
    }
  })
}

export const useUnfavoriteKnowledgeMutation = (contentId: string) => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<Content, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<Content, 'id'>
  >(requests.unFavoriteKnowledge, {
    onSuccess: () => {
      queryClient.invalidateQueries(`${KEY_KNOWLEGDE_ITEM_QUERY}.${contentId}`)

      queryClient.invalidateQueries(`${KEY_KNOWLEGDE_BASES_QUERY}.favorite`)
    }
  })
}

export const useKnowledgeBaseByCategory = (category_id: string, params?: GetKnowledgeBasesParams) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<ContentSerialized[]>>, AxiosError<ResponseError>>(
    [`${KEY_KNOWLEGDE_BASES_QUERY}.${category_id}`],
    ({ pageParam }) => requests.getKnowledgeBases({ ...params, page: pageParam, category_id }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )
