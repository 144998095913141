import { createIcon } from 'native-base'
import { Path } from 'react-native-svg'

export const RotateIcon = createIcon({
  viewBox: '0 0 32 32',
  path: [
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.042 9.067c-1.984 0-3.924.578-5.574 1.662a9.904 9.904 0 0 0-3.696 4.429 9.714 9.714 0 0 0-.57 5.7 9.817 9.817 0 0 0 2.745 5.052 10.087 10.087 0 0 0 5.138 2.7c1.946.381 3.964.186 5.797-.561a9.999 9.999 0 0 0 4.503-3.634 9.75 9.75 0 0 0 1.691-5.482c0-.736.607-1.333 1.356-1.333.75 0 1.356.597 1.356 1.333 0 2.48-.747 4.902-2.148 6.963a12.702 12.702 0 0 1-5.72 4.617 12.947 12.947 0 0 1-7.364.713 12.814 12.814 0 0 1-6.526-3.43 12.47 12.47 0 0 1-3.488-6.418 12.34 12.34 0 0 1 .725-7.241A12.582 12.582 0 0 1 9.96 8.512 12.9 12.9 0 0 1 17.042 6.4h8.136c.749 0 1.356.597 1.356 1.333 0 .737-.607 1.334-1.356 1.334h-8.136Z"
      fill="currentColor"
    />,
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.71.39c.53-.52 1.389-.52 1.918 0l6.509 6.4c.53.521.53 1.365 0 1.886l-6.509 6.4c-.53.52-1.388.52-1.917 0a1.318 1.318 0 0 1 0-1.886l5.55-5.457-5.55-5.457a1.318 1.318 0 0 1 0-1.885Z"
      fill="currentColor"
    />
  ]
})
