import { AxiosError, AxiosResponse } from 'axios'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

// eslint-disable-next-line import/order
import { ResponseError, ResponseSuccess } from '../../types'
import * as requests from './requests'
import { AnswerSurveyItem, Survey, SurveyDetail } from './types'

export const KEY_ALL_SURVEYS_QUERY = 'getAllSurveys'

export const KEY_SURVEY_DETAIL_QUERY = 'getSurveyDetail'

export const useGetAllSurveysInfinityQuery = ({ enabled = true, ...params }) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<Survey[]>>, AxiosError<ResponseError>>(
    [KEY_ALL_SURVEYS_QUERY, params.pageParam],
    ({ pageParam }) =>
      requests.getAllSurveys({
        ...params,
        currentPage: pageParam
      }),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        if (
          lastPage.data.metadata.pagination.current_page <
          lastPage.data.metadata.pagination.last_page
        ) {
          return lastPage.data.metadata.pagination.current_page + 1
        }

        if (
          lastPage.data.metadata.pagination.current_page ===
          lastPage.data.metadata.pagination.last_page
        ) {
          return false
        }
      }
    }
  )

export const useGetSurveyDetailQuery = (surveyId: string) => {
  const queryClient = useQueryClient()

  return useQuery<AxiosResponse<ResponseSuccess<SurveyDetail>>, AxiosError<ResponseError>>(
    [`${KEY_SURVEY_DETAIL_QUERY}.${surveyId}`],
    () => requests.getSurvey(surveyId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEY_ALL_SURVEYS_QUERY)
      }
    }
  )
}

export const useAnswerSurveyMutation = () =>
  useMutation<
    AxiosResponse<ResponseSuccess<Pick<Survey, 'id'>[]>>,
    AxiosError<ResponseError>,
    AnswerSurveyItem
  >(requests.answerSurvey)
