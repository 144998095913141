import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { TabSearchScreenComponent } from './TabSearchScreen.types'
import { useTabSearchScreen } from './useTabSearchScreen'

const TabSearchScreenDesktop = lazy(() => import('./TabSearchScreen.desktop'))

const TabSearchScreenMobile = lazy(() => import('./TabSearchScreen.mobile'))

export const TabSearchScreen: TabSearchScreenComponent = () => {
  const tabSearchScreen = useTabSearchScreen()

  const Screen = tabSearchScreen.isMobile ? TabSearchScreenMobile : TabSearchScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...tabSearchScreen} />
    </Suspense>
  )
}
