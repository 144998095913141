import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

type Direction = 'up' | 'down' | 'left' | 'right'

const getPath = (direction: Direction) => {
  switch (direction) {
    case 'up':
      return (
        <>
          <Path
            key={Math.random()}
            d="M3.71967 16.2803C3.98594 16.5466 4.4026 16.5708 4.69621 16.3529L4.78033 16.2803L11.25 9.81097L17.7197 16.2803C17.9859 16.5466 18.4026 16.5708 18.6962 16.3529L18.7803 16.2803C19.0466 16.014 19.0708 15.5974 18.8529 15.3038L18.7803 15.2196L11.7803 8.21964C11.5141 7.95338 11.0974 7.92917 10.8038 8.14702L10.7197 8.21964L3.71967 15.2196C3.42678 15.5125 3.42678 15.9874 3.71967 16.2803Z"
            fill="currentColor"
          />
          <Path
            key={Math.random()}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.00897 16.7435L5.12093 16.6468L11.25 10.5181L17.3661 16.6339C17.8102 17.0779 18.5044 17.1178 18.9941 16.7545L19.009 16.7435L19.1209 16.6468L19.1339 16.6339C19.578 16.1898 19.6179 15.4955 19.2545 15.0058L19.2435 14.991L19.1468 14.879L12.1339 7.86609C11.6898 7.422 10.9956 7.38212 10.5059 7.74548L10.491 7.75648L10.3791 7.85314L3.36612 14.8661C2.87796 15.3542 2.87796 16.1457 3.36612 16.6339C3.81021 17.0779 4.50443 17.1178 4.99415 16.7545L5.00897 16.7435ZM17.7197 16.2803L11.25 9.81097L4.78033 16.2803L4.69621 16.3529C4.4026 16.5708 3.98594 16.5466 3.71967 16.2803C3.42678 15.9874 3.42678 15.5125 3.71967 15.2196L10.7197 8.21964L10.8038 8.14702C11.0974 7.92917 11.5141 7.95338 11.7803 8.21964L18.7803 15.2196L18.8529 15.3038C19.0708 15.5974 19.0466 16.014 18.7803 16.2803L18.6962 16.3529C18.4026 16.5708 17.9859 16.5466 17.7197 16.2803Z"
            fill="currentColor"
          />
        </>
      )

    case 'left':
      return (
        <Path
          key={Math.random()}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2933 17.7072L8.29325 12.7073C7.90225 12.3163 7.90225 11.6842 8.29325 11.2933L13.2933 6.29325C13.6842 5.90225 14.3163 5.90225 14.7073 6.29325C15.0982 6.68425 15.0982 7.31625 14.7073 7.70725L10.4142 12.0002L14.7073 16.2933C15.0982 16.6842 15.0982 17.3162 14.7073 17.7072C14.5122 17.9022 14.2562 18.0002 14.0002 18.0002C13.7442 18.0002 13.4883 17.9022 13.2933 17.7072Z"
          fill="currentColor"
        />
      )

    case 'down':
      return (
        <>
          <Path
            key={Math.random()}
            d="M4.71967 7.2197C4.98594 6.95343 5.4026 6.92923 5.69621 7.14708L5.78033 7.2197L12.25 13.689L18.7197 7.2197C18.9859 6.95343 19.4026 6.92923 19.6962 7.14708L19.7803 7.2197C20.0466 7.48597 20.0708 7.90263 19.8529 8.19624L19.7803 8.28036L12.7803 15.2804C12.5141 15.5466 12.0974 15.5708 11.8038 15.353L11.7197 15.2804L4.71967 8.28036C4.42678 7.98747 4.42678 7.51259 4.71967 7.2197Z"
            fill="currentColor"
          />
          <Path
            key={Math.random()}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.00897 6.75654L6.12093 6.85319L12.25 12.9819L18.3661 6.86615C18.8102 6.42206 19.5044 6.38218 19.9941 6.74554L20.009 6.75654L20.1209 6.85319L20.1339 6.86615C20.578 7.31024 20.6179 8.00446 20.2545 8.49418L20.2435 8.509L20.1468 8.62096L13.1339 15.6339C12.6898 16.078 11.9956 16.1179 11.5059 15.7545L11.491 15.7435L11.3791 15.6469L4.36612 8.63391C3.87796 8.14576 3.87796 7.3543 4.36612 6.86615C4.81021 6.42206 5.50443 6.38218 5.99415 6.74554L6.00897 6.75654ZM18.7197 7.2197L12.25 13.689L5.78033 7.2197L5.69621 7.14708C5.4026 6.92923 4.98594 6.95343 4.71967 7.2197C4.42678 7.51259 4.42678 7.98747 4.71967 8.28036L11.7197 15.2804L11.8038 15.353C12.0974 15.5708 12.5141 15.5466 12.7803 15.2804L19.7803 8.28036L19.8529 8.19624C20.0708 7.90263 20.0466 7.48597 19.7803 7.2197L19.6962 7.14708C19.4026 6.92923 18.9859 6.95343 18.7197 7.2197Z"
            fill="currentColor"
          />
        </>
      )

    case 'right':
      return (
        <>
          <Path
            key={Math.random()}
            d="M7.2197 4.71967C6.95343 4.98594 6.92923 5.4026 7.14708 5.69621L7.2197 5.78033L13.689 12.25L7.2197 18.7197C6.95343 18.9859 6.92923 19.4026 7.14708 19.6962L7.2197 19.7803C7.48597 20.0466 7.90263 20.0708 8.19624 19.853L8.28036 19.7803L15.2804 12.7803C15.5466 12.5141 15.5708 12.0974 15.353 11.8038L15.2804 11.7197L8.28036 4.71967C7.98747 4.42678 7.51259 4.42678 7.2197 4.71967Z"
            fill="currentColor"
          />
          <Path
            key={Math.random()}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.75654 6.00897L6.85319 6.12093L12.9819 12.25L6.86615 18.3661C6.42206 18.8102 6.38218 19.5044 6.74554 19.9941L6.75654 20.009L6.85319 20.1209L6.86615 20.1339C7.31024 20.578 8.00446 20.6179 8.49418 20.2545L8.509 20.2435L8.62096 20.1468L15.6339 13.1339C16.078 12.6898 16.1179 11.9956 15.7545 11.5059L15.7435 11.491L15.6469 11.3791L8.63391 4.36612C8.14576 3.87796 7.3543 3.87796 6.86615 4.36612C6.42206 4.81021 6.38218 5.50443 6.74554 5.99415L6.75654 6.00897ZM7.2197 18.7197L13.689 12.25L7.2197 5.78033L7.14708 5.69621C6.92923 5.4026 6.95343 4.98594 7.2197 4.71967C7.51259 4.42678 7.98747 4.42678 8.28036 4.71967L15.2804 11.7197L15.353 11.8038C15.5708 12.0974 15.5466 12.5141 15.2804 12.7803L8.28036 19.7803L8.19624 19.853C7.90263 20.0708 7.48597 20.0466 7.2197 19.7803L7.14708 19.6962C6.92923 19.4026 6.95343 18.9859 7.2197 18.7197Z"
            fill="currentColor"
          />
        </>
      )
  }
}

export const ChevronIcon: FunctionComponent<
  IIconProps & { direction: 'up' | 'down' | 'left' | 'right' }
> = ({ direction, ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>{getPath(direction)}</Icon>
)
