import { FunctionComponent, Suspense, lazy } from 'react'

import { Skeleton } from 'native-base'

import { PoliciesProps } from './Policies.types'
import { usePolicies } from './usePolicies'

const PoliciesDesktop = lazy(() => import('./Policies.desktop'))

const PoliciesMobile = lazy(() => import('./Policies.mobile'))

export const Policies: FunctionComponent<PoliciesProps> = (props) => {
  const policies = usePolicies(props)

  const Component = policies.isMobile ? PoliciesMobile : PoliciesDesktop

  return (
    <Suspense fallback={<Skeleton minHeight="104px" mb={4} borderRadius="20px" />}>
      <Component {...policies} />
    </Suspense>
  )
}
