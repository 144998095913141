import { FunctionComponent } from 'react'

import { AcceleratorsData } from 'integration/resources/variable-revenue/types'
import { VStack, Heading, Text, HStack, Box, Divider } from 'native-base'
import { AcceleratorsRulerData } from 'src/screens/SummaryDetailScreen/SummaryDetailScreen.types'
import { formatDateStringToMask } from 'src/utils'

import { Ruler } from './Ruler'

type RulerProps = {
  rulerData: AcceleratorsRulerData
  infos: AcceleratorsData
}

export const SummaryRuler: FunctionComponent<RulerProps> = ({ infos, rulerData }) => {
  const { strip, goal, percentage_realized, realized, title, processed_at } = infos

  const active_value = strip?.strip_activated_value ?? 0

  return (
    <VStack
      bg={{ base: 'white', lg: 'gray.50' }}
      borderRadius={{ base: '10px', lg: '20px' }}
      p={{ base: 4, lg: 6 }}
      space={{ base: 3, lg: 6 }}>
      <Heading
        fontSize={{ base: '14px', lg: '18px' }}
        fontWeight={700}
        lineHeight={{ base: '18px', lg: '24px' }}>
        {title}
      </Heading>

      <VStack space={{ base: 3, lg: 1 }}>
        <HStack space={2} alignItems="center">
          <Text
            fontSize={{ base: '12px', lg: '16px' }}
            fontWeight={400}
            lineHeight={{ base: '16px', lg: '24px' }}
            color="gray.700">
            Realizado:
          </Text>
          <Text
            fontSize={{ base: '14px', lg: '16px' }}
            fontWeight={700}
            lineHeight={{ base: '16px', lg: '24px' }}
            color="gray.700">
            {realized}
          </Text>
          <Text
            fontSize={{ base: '14px', lg: '16px' }}
            fontWeight={400}
            lineHeight={{ base: '16px', lg: '16px' }}
            color="gray.300">
            {percentage_realized}%
          </Text>
        </HStack>

        <HStack space={2} alignItems="center" mb={{ base: 3, lg: 0 }}>
          <Text
            fontSize={{ base: '12px', lg: '16px' }}
            fontWeight={400}
            lineHeight={{ base: '16px', lg: '24px' }}
            color="gray.700">
            Meta:
          </Text>
          <Text
            fontSize={{ base: '14px', lg: '16px' }}
            fontWeight={700}
            lineHeight={{ base: '16px', lg: '24px' }}
            color="gray.700">
            {goal}
          </Text>
        </HStack>
      </VStack>

      <VStack h={{ base: '70px', lg: '94px' }}>
        <Ruler activeValue={active_value} rulerData={rulerData} />
      </VStack>

      <Box>
        <Divider h="1px" my={{ base: 1, lg: 0 }} bg="gray.100" opacity={0.6} />
      </Box>

      <VStack space={3}>
        <Text
          fontSize={{ base: '10px', lg: '14px' }}
          fontWeight={600}
          lineHeight={{ base: '14px', lg: '20px' }}
          color="gray.200">
          Dados até:{' '}
          {processed_at ? formatDateStringToMask(processed_at, "dd'/'MM'/'yyyy") : 'Sem data'}
        </Text>
      </VStack>
    </VStack>
  )
}
