import { forwardRef, useState } from 'react'

import { CustomBottomSheet, BottomSheetModal } from 'molecules'
import { VStack, Heading, HStack, Button } from 'native-base'
import { Platform } from 'react-native'

const JOBS = ['PROPRIETÁRIO', 'DIRETOR', 'GERENTE', 'COMERCIAL', 'ADM', 'FINANCEIRO', 'OUTROS']

type TBottomSheetJob = {
  value: string | undefined
  onSelect(): void
  onChange(value: string): void
}

export const BottomSheetJob = forwardRef<BottomSheetModal, TBottomSheetJob>(
  ({ onSelect, value, onChange }, ref) => {
    const [jobSelected, setJobSelected] = useState(value)

    const isMobile = Platform.OS !== 'web'

    return (
      <CustomBottomSheet ref={ref} snapPoints={[350]}>
        <VStack px={isMobile ? 4 : 0} pt={isMobile ? 4 : 0} pb={10} justifyContent="center">
          <VStack alignItems="center">
            <Heading
              maxW="90%"
              fontSize="18px"
              lineHeight="24px"
              textAlign="center"
              mt="10px"
              color="gray.700">
              Função
            </Heading>
          </VStack>
          <HStack flexWrap="wrap" maxW="full" mt={6}>
            {JOBS.map((_job) => {
              return (
                <Button
                  key={_job}
                  h="24px"
                  p={0}
                  px={4}
                  mb={4}
                  mr={3}
                  onPress={() => setJobSelected(_job)}
                  _pressed={{
                    bg: 'gray.200'
                  }}
                  bg={jobSelected !== _job ? 'gray.200' : 'tertiary.800'}
                  _text={{
                    color: 'white',
                    fontSize: '12px',
                    lineHeight: '16px'
                  }}>
                  {_job}
                </Button>
              )
            })}
          </HStack>
          <HStack mt={4} mb={isMobile ? 10 : 0} w="full">
            <Button
              w="full"
              colorScheme="gray"
              isDisabled={!jobSelected}
              onPress={() => {
                if (jobSelected) onChange(jobSelected)

                onSelect()
              }}>
              Adicionar
            </Button>
          </HStack>
        </VStack>
      </CustomBottomSheet>
    )
  }
)
