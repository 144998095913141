import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const CalendarWeekIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <G clipPath="url(#clip0_5325_47275)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.896899 2.54688C1.37511 2.06866 2.02371 1.8 2.70001 1.8H15.3C15.9763 1.8 16.6249 2.06866 17.1031 2.54688C17.5813 3.0251 17.85 3.6737 17.85 4.35V15.15C17.85 15.8263 17.5813 16.4749 17.1031 16.9531C16.6249 17.4313 15.9763 17.7 15.3 17.7C14.8857 17.7 14.55 17.3642 14.55 16.95C14.55 16.5358 14.8857 16.2 15.3 16.2C15.5784 16.2 15.8455 16.0894 16.0424 15.8925C16.2393 15.6955 16.35 15.4285 16.35 15.15V4.35C16.35 4.07152 16.2393 3.80445 16.0424 3.60754C15.8455 3.41062 15.5784 3.3 15.3 3.3H2.70001C2.42154 3.3 2.15447 3.41062 1.95755 3.60754C1.76064 3.80445 1.65002 4.07152 1.65002 4.35V15.15C1.65002 15.4285 1.76064 15.6955 1.95755 15.8925C2.15447 16.0894 2.42154 16.2 2.70001 16.2C3.11423 16.2 3.45001 16.5358 3.45001 16.95C3.45001 17.3642 3.11423 17.7 2.70001 17.7C2.02371 17.7 1.37511 17.4313 0.896899 16.9531C0.418683 16.4749 0.150024 15.8263 0.150024 15.15V4.35C0.150024 3.6737 0.418683 3.0251 0.896899 2.54688Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50001 0C4.91422 0 5.25 0.335786 5.25 0.75V2.55C5.25 2.96421 4.91422 3.3 4.50001 3.3C4.08579 3.3 3.75001 2.96421 3.75001 2.55V0.75C3.75001 0.335786 4.08579 0 4.50001 0Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 0C13.9142 0 14.25 0.335786 14.25 0.75V2.55C14.25 2.96421 13.9142 3.3 13.5 3.3C13.0858 3.3 12.75 2.96421 12.75 2.55V0.75C12.75 0.335786 13.0858 0 13.5 0Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.150024 7.05C0.150024 6.63579 0.485809 6.3 0.900021 6.3H17.1C17.5142 6.3 17.85 6.63579 17.85 7.05C17.85 7.46421 17.5142 7.8 17.1 7.8H0.900021C0.485809 7.8 0.150024 7.46421 0.150024 7.05Z"
        fill="currentColor"
      />
      <Path
        d="M6.93013 17.55L9.89897 11.1371H6V9.75H11.6727V10.785L8.68223 17.55H6.93013Z"
        fill="currentColor"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_5325_47275">
        <Rect width="17.9999" height="18" fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)
