import { useCallback, useEffect } from 'react'

import {
  Contact,
  useEditContactBirthdayMutation,
  useGetContactBirthdayItemQuery
} from 'integration/resources/wallet'
import { useBreakpointValue } from 'native-base'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard } from 'react-native'

import { ContactBirthdayFormProps, UseContactBirthdayScreen } from './ContactBirthdayScreen.types'

export const useContactBirthdayScreen: UseContactBirthdayScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { contactId } = route.params

  const { data, isLoading } = useGetContactBirthdayItemQuery(contactId)

  const { control, handleSubmit, reset, setValue } = useForm<Contact>()

  const { mutate, isLoading: isLoadingEdit } = useEditContactBirthdayMutation(contactId)

  const submit = handleSubmit(
    useCallback<SubmitHandler<Contact>>(
      async (formData) => {
        Keyboard.dismiss()

        const { jobTitle, tab_number = '0' } = formData

        const formattedFields = {
          ...formData,
          job_title: jobTitle?.toUpperCase(),
          store_tab_number: parseInt(tab_number, 10)
        } as Contact

        mutate(formattedFields)
      },
      [mutate]
    )
  )

  const contact = data?.data?.data

  useEffect(() => {
    reset(contact)
  }, [reset, contact])

  const handleToggle = (name: ContactBirthdayFormProps, value: boolean) => {
    setValue(name, value)

    submit()
  }

  const handleGoToEdit = useCallback(() => {
    navigation.navigate('ContactEditBirthday', { contactId })
  }, [navigation, contactId])

  return {
    isMobile,
    control,
    isLoading: isLoading || isLoadingEdit,
    isLoadingEdit,
    contact,
    handleGoToEdit,
    handleToggle
  }
}
