import { CountryCode } from 'react-native-country-picker-modal'
import { MaskService } from 'react-native-masked-text'
import { isValidNumber } from 'react-native-phone-number-input'

export const validateAuthCode = (value: string) => value.length === 6 || 'Código inválido'

export const validateCPF = (value: string) =>
  MaskService.isValid('cpf', value) && value !== '00000000000'

export const validatePassword = (value: string) => ({
  'Mínimo 8 caracteres': value ? value.length >= 8 : null,
  'Pelo menos 1 letra maiúscula': value ? !!value.match(new RegExp('^(?=.*[A-Z]).*$')) : null,
  'Pelo menos 1 letra minúscula': value ? !!value.match(new RegExp('^(?=.*[a-z]).*$')) : null,
  'Pelo menos 1 símbolo (@#!$%^+=)': value
    ? !!value.match(new RegExp('^[a-zA-Z0-9@#!$%^+=]*[@#!$%^+=][a-zA-Z0-9@#!$%^+=]*$'))
    : null,
  'Pelo menos 1 número': value ? !!value.match(new RegExp('^(?=.*[0-9]).*$')) : null
})

export const validatePhone = (value: string, countryCode: CountryCode) => {
  return isValidNumber(value, countryCode)
}

export const isBase64File = (value: string) => {
  const base64Regex = /^data:[\w/]+;base64,[\w+/=]+$/

  if (!base64Regex.test(value)) return false

  const byteCharacters = atob(value.split(',')[1])

  const byteArrays = []

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i))
  }

  const byteArray = new Uint8Array(byteArrays)

  const blob = new Blob([byteArray])

  return blob.size > 0
}
