import { FunctionComponent } from 'react'

import { ApnefItem, LeadsItem } from 'integration/resources/newOpportunities'
import { RootStackScreenComponentProps } from 'src/navigation'

export type LeadsApnefScreenComponent = FunctionComponent<LeadsApnefScreenProps>

export type LeadsApnefScreenProps = RootStackScreenComponentProps<'LeadsApnef'>

export type ResponsiveLeadsApnefScreenComponent = FunctionComponent<ReturnType<UseLeadsApnefScreen>>

export enum LeadsApnefScreenTypes {
  Leads = 0,
  APNEF = 1
}

export type UseLeadsApnefScreen = ({
  navigation,
  route
}: Pick<LeadsApnefScreenProps, 'navigation' | 'route'>) => {
  isMobile: boolean
  isLeads: boolean
  handleGoBack(): void
  infos?: ApnefItem[] | LeadsItem[]
  infosQuantity?: number
  isLoading?: boolean
}
