import { useCallback, useEffect, useState } from 'react'

import { useBlockScreenContext } from 'contexts/BlockScreenContext'
import { useBreakpointValue } from 'native-base'

import { LockscreenTimeOptions, UseLockscreenScreen } from './LockscreenScreen.types'

export const useLockscreenScreen: UseLockscreenScreen = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { toggleToEnableBlock, getDeviceControlEnable, setTimeToBlockDevice, getTimeBlockDevice } =
    useBlockScreenContext()

  const listOfTimes = [
    LockscreenTimeOptions.IMMEDIATELY,
    LockscreenTimeOptions.ONE_MINUTE,
    LockscreenTimeOptions.FIFTEEN_MINUTES,
    LockscreenTimeOptions.ONE_HOUR
  ]
  /**
   * @TODO change navigation navigate to useLinkTo
   * define custom names cause this name used in url in version web
   */

  const [unlock, setUnlock] = useState(false)

  const [unlockTime, setUnlockTime] = useState<LockscreenTimeOptions>(1)

  const handleUnlockTime = useCallback(
    async (value: LockscreenTimeOptions) => {
      setUnlockTime(value)

      setTimeToBlockDevice(value)
    },
    [setTimeToBlockDevice]
  )

  const requestUnlock = useCallback(
    async (value: boolean) => {
      await toggleToEnableBlock(value)

      setUnlock(value)
    },
    [toggleToEnableBlock]
  )

  useEffect(() => {
    getDeviceControlEnable().then((value) => {
      setUnlock(value)
    })

    getTimeBlockDevice().then((value) => {
      setUnlockTime(value)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isMobile, unlock, requestUnlock, unlockTime, handleUnlockTime, listOfTimes }
}
