import { forwardRef, useImperativeHandle, useState } from 'react'

import { Avatar, Icons, Illustrations, LoadingTurbo } from 'atoms'
import { Note } from 'integration/resources/wallet'
import { HStack, Text, Button, VStack, Center, Spinner, View, Modal, Heading } from 'native-base'
import { StyleSheet } from 'react-native'
import { CurrentViewTypesRef } from 'src/components/templates/ContactScreenTemplate/ContactScreenTemplate'

import { useTabComponent } from './hook'
import { TabCommonProps } from './types'

const NoteCard = ({
  handleGoToEditNote,
  handleGoToDeleteNote,
  ...note
}: Note & {
  handleGoToEditNote?: (note: Note) => void
  handleGoToDeleteNote?: (note: Note) => void
}) => {
  const [active, setActive] = useState(false)

  const { title, detail, created_at, type, tab_name, tab_number } = note

  return (
    <VStack
      bg="white"
      borderRadius="20px"
      style={[styles.card, styles.transition, active && styles.openCard]}
      mb={4}>
      <HStack
        space={4}
        alignItems="center"
        px={6}
        pt={active ? 6 : 3}
        pb={active ? 6 : 0}
        style={styles.transition}>
        <VStack flex={1}>
          <Text
            fontSize={16}
            color="gray.700"
            isTruncated
            bold={active}
            onPress={() => setActive(!active)}>
            {title}
          </Text>
        </VStack>

        <HStack flex={1} h={12} alignItems="center" justifyContent="flex-end" space={4}>
          <Button
            alignSelf="flex-end"
            onPress={() => setActive(!active)}
            h={10}
            w={10}
            borderRadius="full"
            bgColor="gray.50">
            <Center>
              <Icons.AccordionArrow direction={active ? 'up' : 'down'} color="gray.700" size={5} />
            </Center>
          </Button>
        </HStack>
      </HStack>

      <VStack px={6} style={[styles.details, active && styles.openDetails]}>
        <View w="full" h="1px" bg="gray.100" />

        <HStack alignItems="center" pt={4}>
          <Text fontSize={16} color="gray.700" isTruncated>
            {detail}
          </Text>

          <HStack flex={1} h={12} alignItems="center" justifyContent="flex-end" space={4}>
            <Button
              alignSelf="flex-end"
              onPress={() => handleGoToEditNote?.(note)}
              h={10}
              w={10}
              bgColor="white">
              <Center>
                <Icons.Edit color="gray.500" size={4} />
              </Center>
            </Button>

            <Button
              alignSelf="flex-end"
              onPress={() => handleGoToDeleteNote?.(note)}
              h={10}
              w={10}
              bgColor="white">
              <Center>
                <Icons.TrashGhost color="error.400" size={4} />
              </Center>
            </Button>
          </HStack>
        </HStack>
      </VStack>

      <HStack px={6} alignItems="center" flex={1}>
        <HStack space={4} alignItems="center">
          <Center>
            <Icons.Calendar color="gray.400" size={4} />
          </Center>
          <Text fontSize={16} color="gray.400">
            {created_at}
          </Text>
        </HStack>
        <HStack alignItems="center" style={[styles.details, active && styles.openDetails]}>
          <View w="1px" h={8} bgColor="gray.50" mx={6} />

          <Button
            h="24px"
            px={4}
            _pressed={{
              bg: 'gray.200'
            }}
            bg="tertiary.800"
            _text={{
              color: 'white',
              fontSize: '12px',
              lineHeight: '16px'
            }}>
            {type}
          </Button>

          <View w="1px" h={8} bgColor="gray.50" mx={6} />

          <HStack space={2} alignItems="center">
            <Center bg="gray.50" mr={2} borderRadius="full" h={10} w={10}>
              <Avatar
                uri={undefined}
                h={10}
                w={10}
                type="light"
                fontSize={16}
                title={tab_name}
                borderWidth="1px"
                borderColor="gray.100"
                _text={{
                  color: 'gray.400'
                }}
              />
            </Center>
            <Text fontSize={16} textTransform="capitalize" color="gray.400" isTruncated>
              {tab_name?.toLowerCase()}
            </Text>
            <Text fontSize={16} color="gray.400" bold>
              TAB {tab_number}
            </Text>
          </HStack>
        </HStack>
      </HStack>
    </VStack>
  )
}

export const NotesDesktop = forwardRef<CurrentViewTypesRef, TabCommonProps>(
  (
    {
      contactId,
      contact,
      handleGoToNoteCreateDetails,
      handleGoToEditNote,
      isLoadingNoteDelete,
      isConfirmDeleteNoteOpen,
      setIsConfirmDeleteNoteOpen,
      handleConfirmDelete
    },
    ref
  ) => {
    const [note, setNote] = useState<Note | undefined>(undefined)

    const { allNotes, onScrollEndDragAllNotesList, allNotesIsFetchingNextPage, isLoading } =
      useTabComponent({
        contactId: contactId ?? ''
      })

    useImperativeHandle(ref, () => ({
      onScrollEndDragContent: onScrollEndDragAllNotesList
    }))

    return (
      <>
        <VStack flex={1} mt={6}>
          <Text color="gray.700" fontSize="16px" mb={6}>
            Caderninho
          </Text>

          {isLoading ? (
            <Center h={360} w="full" position="absolute" zIndex={9}>
              <LoadingTurbo size={100} />
            </Center>
          ) : (
            <>
              {allNotes.length > 0 &&
                allNotes.map((note) => (
                  <NoteCard
                    key={note.id}
                    {...note}
                    tab_number={contact?.tab_number}
                    handleGoToEditNote={handleGoToEditNote}
                    handleGoToDeleteNote={(note) => [
                      setNote(note),
                      setIsConfirmDeleteNoteOpen?.(true)
                    ]}
                  />
                ))}

              {allNotes.length === 0 && (
                <Text flexGrow={1} textAlign="center" fontSize="16px" color="gray.500">
                  Nenhuma nota ainda
                </Text>
              )}

              {allNotesIsFetchingNextPage ? (
                <Center flex={1} mx={2} mb={10}>
                  <Spinner />
                </Center>
              ) : null}

              <VStack flex={1} mt={4}>
                <Button
                  px={12}
                  colorScheme="gray"
                  onPress={handleGoToNoteCreateDetails}
                  alignSelf="flex-end">
                  Nova nota
                </Button>
              </VStack>
            </>
          )}
        </VStack>

        <Modal isOpen={isConfirmDeleteNoteOpen} onClose={setIsConfirmDeleteNoteOpen} size="xl">
          <Modal.Content rounded="3xl">
            <Modal.CloseButton />
            <Modal.Body py={20} px={6}>
              <Center>
                <Illustrations.DeleteItem />

                <Heading fontSize={18} color="gray.700" mt={6} mb={4}>
                  Deseja mesmo excluir esta nota?
                </Heading>

                <Text color="gray.500" fontSize={16}>
                  Após a confirmação esta ação nao poderá ser desfeita.
                </Text>
              </Center>

              <HStack mt={8}>
                <Button
                  colorScheme="gray"
                  variant="ghost"
                  borderWidth="1px"
                  borderColor="gray.700"
                  borderRadius="40px"
                  onPress={() => setIsConfirmDeleteNoteOpen?.(false)}
                  mr={1}
                  flex={1}
                  disabled={isLoading}>
                  Cancelar
                </Button>

                <Button
                  ml={1}
                  mr={0}
                  flex={1}
                  isLoading={isLoadingNoteDelete}
                  colorScheme="gray"
                  borderRadius="40px"
                  onPress={() => handleConfirmDelete?.(note!)}>
                  Confirmar
                </Button>
              </HStack>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      </>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  card: {
    height: 111
  },
  transition: {
    position: 'relative',
    transition: 'all ease 0.3s'
  },
  openCard: {
    height: 241,
    boxShadow: '0px 3px 16px rgba(0, 0, 0, 0.08)'
  },
  details: {
    height: 0,
    opacity: 0,
    transition: 'all ease 0.3s 0s'
  },
  openDetails: {
    opacity: 1,
    height: 'auto',
    transitionDelay: '0.2s'
  }
})
