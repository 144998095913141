import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const MoreIcon: FunctionComponent<IIconProps & { vertical?: boolean }> = ({
  vertical = false,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    {vertical ? (
      <>
        <Path
          key={Math.random()}
          d="M10 2.4C10 3.72548 11.0745 4.8 12.4 4.8V0C11.0745 0 10 1.07451 10 2.4Z"
          fill="currentColor"
        />
        <Path
          key={Math.random()}
          d="M14.8004 2.4C14.8004 1.07451 13.7255 0 12.4 0V4.8C13.7255 4.8 14.8004 3.72548 14.8004 2.4Z"
          fill="currentColor"
        />
        <Path
          key={Math.random()}
          d="M10 21.6C10 22.9255 11.0745 24 12.4 24V19.2C11.0745 19.2 10 20.2745 10 21.6Z"
          fill="currentColor"
        />
        <Path
          key={Math.random()}
          d="M14.8004 21.6C14.8004 20.2745 13.7255 19.2 12.4 19.2V24C13.7255 24 14.8004 22.9255 14.8004 21.6Z"
          fill="currentColor"
        />
        <Path
          key={Math.random()}
          d="M10 12C10 13.3255 11.0745 14.4 12.4 14.4V9.59998C11.0745 9.59998 10 10.6745 10 12Z"
          fill="currentColor"
        />
        <Path
          key={Math.random()}
          d="M14.8004 12C14.8004 10.6745 13.7255 9.59998 12.4 9.59998V14.4C13.7255 14.4 14.8004 13.3255 14.8004 12Z"
          fill="currentColor"
        />
      </>
    ) : (
      <>
        <Path
          key={Math.random()}
          d="M23.9992 12.0003C23.9992 13.3258 22.9247 14.4003 21.5992 14.4003C20.2737 14.4003 19.1992 13.3258 19.1992 12.0003C19.1992 10.6749 20.2737 9.60034 21.5992 9.60034C22.9247 9.60034 23.9992 10.6749 23.9992 12.0003Z"
          fill="currentColor"
        />
        <Path
          key={Math.random()}
          d="M14.3996 12.0003C14.3996 13.3258 13.3251 14.4003 11.9996 14.4003C10.6741 14.4003 9.59961 13.3258 9.59961 12.0003C9.59961 10.6749 10.6741 9.60034 11.9996 9.60034C13.3251 9.60034 14.3996 10.6749 14.3996 12.0003Z"
          fill="currentColor"
        />
        <Path
          key={Math.random()}
          d="M4.8 12.0003C4.8 13.3258 3.72548 14.4003 2.4 14.4003C1.07452 14.4003 0 13.3258 0 12.0003C0 10.6749 1.07452 9.60034 2.4 9.60034C3.72548 9.60034 4.8 10.6749 4.8 12.0003Z"
          fill="currentColor"
        />
      </>
    )}
  </Icon>
)
