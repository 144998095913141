export const typography = {
  fontConfig: {
    OpenSans: {
      300: { normal: 'OpenSans_300Light', italic: 'OpenSans_300Light_Italic' },
      400: { normal: 'OpenSans_400Regular', italic: 'OpenSans_400Regular_Italic' },
      500: { normal: 'OpenSans_500Medium', italic: 'OpenSans_500Medium_Italic' },
      600: { normal: 'OpenSans_600SemiBold', italic: 'OpenSans_600SemiBold_Italic' },
      700: { normal: 'OpenSans_700Bold', italic: 'OpenSans_700Bold_Italic' },
      800: { normal: 'OpenSans_800ExtraBold', italic: 'OpenSans_800ExtraBold_Italic' }
    },
    Assistant: {
      400: { normal: 'Assistant_400Regular' },
      600: { normal: 'Assistant_600SemiBold' },
      700: { normal: 'Assistant_700Bold' }
    }
  },
  fonts: {
    heading: 'Assistant',
    body: 'OpenSans',
    mono: 'OpenSans'
  }
}
