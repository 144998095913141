import { FunctionComponent, Suspense, lazy } from 'react'

import { Skeleton } from 'native-base'

import { HeaderProps } from './Header.types'
import { useHeader } from './useHeader'

const HeaderDesktop = lazy(() => import('./Header.desktop'))

const HeaderMobile = lazy(() => import('./Header.mobile'))

export const Header: FunctionComponent<HeaderProps> = (props) => {
  const header = useHeader(props)

  const Component = header.isMobile ? HeaderMobile : HeaderDesktop

  return (
    <Suspense fallback={<Skeleton minHeight="104px" mb={4} borderRadius="20px" />}>
      <Component {...header} />
    </Suspense>
  )
}
