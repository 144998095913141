import { FunctionComponent, useCallback } from 'react'

import { LinearGradient } from 'expo-linear-gradient'
import { Avatar as AvatarBase, IAvatarProps, useTheme } from 'native-base'

type TAvatarProps = IAvatarProps & {
  title?: string | undefined
  uri: string | undefined
  type: 'light' | 'dark'
  variant?: 'white'
}

export const Avatar: FunctionComponent<TAvatarProps> = ({
  title,
  uri,
  type,
  fontSize = 18,
  variant,
  ...props
}) => {
  const theme = useTheme()

  const sanitizeInitialLetters = useCallback(() => {
    const name = title ? title.replace(/[^a-zA-Z0-9ÁÉÍÓÚáéíóúâêîôûàèìòùÇç\s]/g, '') : ' '

    if (name) {
      const nameSplited = name.split(' ')

      const arrNames = nameSplited.filter((item) => item !== '')

      if (arrNames.length > 1) {
        const [firstName] = arrNames

        return `${firstName.slice(0, 1)}${arrNames[arrNames.length - 1].slice(0, 1)}`.toUpperCase()
      } else {
        return name.slice(0, 2).toUpperCase()
      }
    }
  }, [title])

  const sanitizeTitle = sanitizeInitialLetters()

  return type === 'dark' ? (
    <LinearGradient
      start={{ x: 0, y: 0.1 }}
      end={{ x: 0.3, y: 1 }}
      locations={[0, 0.2, 0.9]}
      style={{
        borderRadius: 50
      }}
      colors={[
        theme.colors['header-gradient'][50],
        theme.colors['header-gradient'][100],
        theme.colors['header-gradient'][200]
      ]}>
      <AvatarBase
        {...props}
        background="transparent"
        key={`${uri}${new Date()}`}
        _image={{
          resizeMode: 'contain'
        }}
        source={{
          cache: 'reload',
          uri,
          headers: {
            Pragma: 'no-cache'
          }
        }}
        _text={{
          color: 'white',
          fontFamily: 'heading',
          fontWeight: 600,
          fontSize
        }}>
        {sanitizeTitle}
      </AvatarBase>
    </LinearGradient>
  ) : (
    <AvatarBase
      {...props}
      background={variant ? variant : 'gray.50'}
      key={`${uri}${new Date()}`}
      _image={{
        resizeMode: 'contain'
      }}
      source={{
        cache: 'reload',
        uri,
        headers: {
          Pragma: 'no-cache'
        }
      }}
      _text={{
        color: 'gray.700',
        fontFamily: 'heading',
        fontWeight: 600,
        fontSize
      }}>
      {sanitizeTitle}
    </AvatarBase>
  )
}
