import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const WebsiteIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.45614 3.45614C5.66907 1.24321 8.67045 0 11.8 0C14.9296 0 17.9309 1.24321 20.1439 3.45614C22.3568 5.66907 23.6 8.67045 23.6 11.8C23.6 14.9296 22.3568 17.9309 20.1439 20.1439C17.9309 22.3568 14.9296 23.6 11.8 23.6C8.67045 23.6 5.66907 22.3568 3.45614 20.1439C1.24321 17.9309 0 14.9296 0 11.8C0 8.67045 1.24321 5.66907 3.45614 3.45614ZM11.8 2C9.20088 2 6.70821 3.0325 4.87035 4.87035C3.0325 6.70821 2 9.20088 2 11.8C2 14.3991 3.0325 16.8918 4.87035 18.7296C6.70821 20.5675 9.20088 21.6 11.8 21.6C14.3991 21.6 16.8918 20.5675 18.7296 18.7296C20.5675 16.8918 21.6 14.3991 21.6 11.8C21.6 9.20088 20.5675 6.70821 18.7296 4.87035C16.8918 3.0325 14.3991 2 11.8 2Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11.8C0 11.2477 0.447715 10.8 1 10.8H22.6C23.1523 10.8 23.6 11.2477 23.6 11.8C23.6 12.3523 23.1523 12.8 22.6 12.8H1C0.447715 12.8 0 12.3523 0 11.8Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8 0C12.101 0 12.386 0.135594 12.5759 0.369154C15.2045 3.60217 16.7373 7.58706 16.9531 11.7482L15.9544 11.8L16.9531 11.8518C16.7373 16.0129 15.2045 19.9978 12.5759 23.2308C12.386 23.4644 12.101 23.6 11.8 23.6C11.499 23.6 11.214 23.4644 11.0241 23.2308C8.39551 19.9978 6.86266 16.0129 6.64694 11.8518C6.64515 11.8173 6.64515 11.7827 6.64694 11.7482C6.86266 7.58706 8.39551 3.60217 11.0241 0.369154C11.214 0.135594 11.499 0 11.8 0ZM14.953 11.8C14.7728 8.51115 13.6766 5.34683 11.8 2.65972C9.92341 5.34683 8.8272 8.51115 8.64702 11.8C8.8272 15.0889 9.92341 18.2532 11.8 20.9403C13.6766 18.2532 14.7728 15.0889 14.953 11.8Z"
      fill="currentColor"
    />
  </Icon>
)
