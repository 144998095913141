import { useCallback, useEffect, useMemo, useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { parseISO, differenceInDays } from 'date-fns'
import {
  KEY_NOTIFICATIONS_QUERY,
  NOTIFICATION_CHOICES,
  TNotification,
  TNotificationType,
  useGetAllNotificationsInfinityQuery,
  useGetNotReadNotificationsQuery,
  usePathMarkReadNotificationMutation,
  usePathMarkUnreadNotificationMutation
} from 'integration/resources/notifications'
import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import useDebounce from 'src/hooks/useDebounce'
import { LogEvent } from 'src/utils/logEvents'

import { TParamsNotificationDetail, UseNotificationScreen } from './NotificationScreen.types'

export const useNotificationScreen: UseNotificationScreen = ({ navigation }) => {
  const [filterSelected, setFilterSelected] = useState<NOTIFICATION_CHOICES | undefined>(undefined)

  const [search, setSearch] = useState<string>()

  const debouncedSearch = useDebounce(search, 500)

  const linkTo = useLinkTo()

  const [currentPage, setCurrentPage] = useState(1)

  const getAllNotifications = useGetAllNotificationsInfinityQuery({
    notification_type: filterSelected,
    enabled: true,
    ...(debouncedSearch?.length && { search: debouncedSearch })
  })

  const mutationMarkUnread = usePathMarkUnreadNotificationMutation({ currentPage })

  const mutationMarkRead = usePathMarkReadNotificationMutation({ currentPage })

  const getNotReadNotifications = useGetNotReadNotificationsQuery()

  const notificationsCount = useMemo(
    () => getNotReadNotifications?.data?.data?.data,
    [getNotReadNotifications]
  )

  useEffect(() => {
    getAllNotifications.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelected, debouncedSearch])

  const handleApplyFilter = (type?: NOTIFICATION_CHOICES) => {
    setFilterSelected(type ?? undefined)
  }

  const notificationsTotal = useMemo(() => {
    const notificationsValue = Object.entries(notificationsCount ?? {})
      .filter(([key]) => key !== 'not_read_count')
      .reduce((total, [, valor]) => total + valor, 0)

    return notificationsValue
  }, [notificationsCount])

  const queryClient = useQueryClient()

  const data = useMemo(
    () =>
      getAllNotifications.data?.pages.reduce<TNotification[]>((previousValue, currentValue) => {
        return [...previousValue, ...currentValue.data.data] as TNotification[]
      }, []),
    [getAllNotifications.data?.pages]
  )

  const notificationSections: { title: string; data: TNotification[] }[] = useMemo(
    () => [
      {
        title: 'Hoje',
        data:
          data?.filter((item) => {
            const objectDate = parseISO(item.created_at)

            const today = new Date()

            return differenceInDays(today, objectDate) === 0
          }) ?? []
      },
      {
        title: 'Anteriores',
        data:
          data?.filter((item) => {
            const objectDate = parseISO(item.created_at)

            const today = new Date()

            return differenceInDays(today, objectDate) > 0
          }) ?? []
      }
    ],
    [data]
  )

  const empty = !data?.length

  const onEndReached = useCallback(() => {
    if (!getAllNotifications.isFetchingNextPage && getAllNotifications.hasNextPage) {
      setCurrentPage(currentPage + 1)

      getAllNotifications.fetchNextPage()
    }
  }, [getAllNotifications, currentPage])

  const isMobile = useBreakpointValue({ base: true, lg: false })

  useEffect(() => {
    if (!isMobile)
      navigation.setOptions({
        title: 'Notificações'
      })
  }, [isMobile, navigation])

  const handleGoToNotificationDetail = (notification: TNotification) => {
    let params: TParamsNotificationDetail = { id: notification.id }

    LogEvent(`notificacao`, {
      item_id: notification.id
    })

    if (notification.extras) {
      params = { ...params, extras: notification.extras }
    }

    navigation.navigate('NotificationDetail', params)
  }

  const onRefresh = () => queryClient.invalidateQueries(KEY_NOTIFICATIONS_QUERY)

  const [notificationLoading, setNotificationLoading] = useState<string>()

  const [renderListLoading, setRenderListLoading] = useState(false)

  const handleReadNotification = (id: string, isRead: boolean) => {
    setRenderListLoading(true)

    setNotificationLoading(id)

    if (isRead) mutationMarkUnread.mutateAsync({ id })
    else mutationMarkRead.mutateAsync({ id })

    const timeoutSpinner = setTimeout(() => {
      setRenderListLoading(false)
    }, 3000)

    return () => {
      clearTimeout(timeoutSpinner)
    }
  }

  const handleOpenRightSheet = (id: string) => {
    LogEvent(`notificacao`, {
      item_id: id
    })

    navigation.navigate('NotificationDetail', { id })
  }

  const selectTypeNotification = (
    type: TNotificationType = 'WALLET',
    notificationItem: TNotification
  ) => {
    const object = {
      WALLET: {
        title: 'Carteira',
        titleButton: 'Ver carteira',
        handleAction: () => navigation.navigate('WalletTabList', {})
      },
      CONTENT: {
        title: 'Conteúdos',
        titleButton: 'Ver conteúdo',
        handleAction: () => {
          if (notificationItem?.extras?.contentId)
            navigation.navigate('Content', { id: notificationItem?.extras?.contentId })
        }
      },
      ORGANIZATION: {
        title: 'Minha organização',
        titleButton: 'Ver tarefas',
        handleAction: () => linkTo('/minha-organizacao/tarefas')
      },
      OPPORTUNITY: {
        title: 'Minha organização',
        titleButton: notificationItem?.extras?.contentId ? 'Ver oportunidade' : 'Ver oportunidades',
        handleAction: () => {
          if (notificationItem?.extras?.contentId) {
            navigation.navigate('OrganizationOpportunity', {
              id: notificationItem?.extras.contentId
            })

            return
          }

          linkTo('/minha-organizacao/oportunidades/5')
        }
      },
      ACTIVITY: {
        title: 'Minha organização',
        titleButton: notificationItem?.extras?.contentId ? 'Ver tarefa' : 'Ver tarefas',
        handleAction: () => {
          if (notificationItem?.extras?.contentId) {
            navigation.navigate('OrganizationActivity', {
              id: notificationItem?.extras.contentId
            })

            return
          }

          linkTo('/minha-organizacao/tarefas')
        }
      },
      COMMUNICATION: {
        title: 'Comunicado institucional',
        titleButton: 'Ver comunicado',
        handleAction: () => {
          return navigation.navigate('NotificationDetail', { id: notificationItem.id })
        }
      },
      BIRTHDATE: {
        title: 'Aniversários',
        titleButton: 'Ver contato',
        //@ts-ignore
        handleAction: () => {
          if (notificationItem?.extras?.contactId) {
            navigation.navigate('WalletContact', { contactId: notificationItem?.extras?.contactId })
          }
        }
      },
      APP_VERSION: {
        title: 'Nova atualização disponível pra você!'
      },
      SURVEY: {
        title: 'Pesquisas',
        titleButton: 'Ver pesquisa',
        handleAction: () => {
          if (notificationItem?.extras?.surveyId)
            navigation.navigate('SurveyDetail', { id: notificationItem?.extras?.surveyId })
        }
      },
      DEFAULT: {
        title: 'Notificação não configurada',
        titleButton: 'Ver detalhes',
        handleAction: () => handleOpenRightSheet(notificationItem.id)
      }
    }

    return type in object ? object[type] : object['DEFAULT']
  }

  return {
    isMobile,
    handleGoToNotificationDetail,
    navigation,
    notificationSections,
    onEndReached,
    isRefetching: getAllNotifications.isRefetching,
    isLoading: getAllNotifications.isLoading,
    MarkReadUnreadIsLoading: mutationMarkUnread.isLoading || mutationMarkRead.isLoading,
    onRefresh,
    handleReadNotification,
    notificationLoading,
    empty,
    handleOpenRightSheet,
    selectTypeNotification,
    notificationsCount,
    notificationsTotal,
    handleApplyFilter,
    filterSelected,
    renderListLoading,
    setSearch,
    search
  }
}
