import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const WorldIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      d="M11.8585 0C11.6986 4.41801e-05 11.5416 0.0430566 11.4039 0.12454C11.2663 0.206024 11.1531 0.322987 11.0761 0.4632C9.38981 3.44684 8.3145 6.7364 7.91292 10.14C7.9049 10.2234 7.91438 10.3076 7.94074 10.3871C7.9671 10.4667 8.00977 10.5398 8.06601 10.6019C8.12225 10.664 8.19083 10.7137 8.26737 10.7478C8.34391 10.782 8.42672 10.7997 8.51052 10.8H15.2185C15.3023 10.7997 15.3851 10.782 15.4617 10.7478C15.5382 10.7137 15.6068 10.664 15.663 10.6019C15.7193 10.5398 15.7619 10.4667 15.7883 10.3871C15.8147 10.3076 15.8241 10.2234 15.8161 10.14C15.4137 6.74121 14.3367 3.457 12.6481 0.48C12.572 0.336255 12.4584 0.215761 12.3194 0.131266C12.1804 0.0467703 12.0212 0.00141572 11.8585 0Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      d="M8.51052 13.2C8.42672 13.2003 8.34391 13.218 8.26737 13.2522C8.19083 13.2863 8.12225 13.336 8.06601 13.3981C8.00977 13.4602 7.9671 13.5333 7.94074 13.6129C7.91438 13.6924 7.9049 13.7766 7.91292 13.86C8.30891 17.2623 9.382 20.5509 11.0689 23.532C11.1459 23.6732 11.2595 23.7911 11.3979 23.8733C11.5362 23.9554 11.6941 23.9987 11.8549 23.9987C12.0158 23.9987 12.1737 23.9554 12.312 23.8733C12.4503 23.7911 12.5639 23.6732 12.6409 23.532C14.3273 20.5501 15.4027 17.2621 15.8041 13.86C15.8121 13.7776 15.8029 13.6944 15.7773 13.6157C15.7516 13.537 15.71 13.4644 15.6551 13.4024C15.6001 13.3405 15.533 13.2906 15.4579 13.2557C15.3828 13.2209 15.3013 13.2019 15.2185 13.2H8.51052Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      d="M18.2257 10.248C18.2372 10.3976 18.3045 10.5375 18.4143 10.6398C18.5242 10.742 18.6684 10.7992 18.8185 10.8H23.1169C23.2037 10.7997 23.2895 10.7806 23.3682 10.744C23.447 10.7075 23.5169 10.6543 23.5732 10.5882C23.6295 10.5221 23.6709 10.4446 23.6944 10.361C23.718 10.2775 23.7232 10.1898 23.7097 10.104C23.3925 8.14871 22.5979 6.30183 21.3965 4.72695C20.195 3.15206 18.6237 1.89778 16.8217 1.0752C16.7112 1.02525 16.5881 1.00997 16.4687 1.03139C16.3493 1.0528 16.2392 1.10989 16.153 1.19514C16.0667 1.28038 16.0082 1.38978 15.9854 1.5089C15.9625 1.62802 15.9763 1.75127 16.0249 1.8624C17.2164 4.51693 17.9601 7.35047 18.2257 10.248Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      d="M4.89852 10.8C5.04942 10.8004 5.1949 10.7438 5.30575 10.6414C5.41659 10.539 5.4846 10.3985 5.49612 10.248C5.76021 7.35074 6.50225 4.51722 7.69212 1.8624C7.74072 1.75127 7.75451 1.62802 7.73165 1.5089C7.70879 1.38978 7.65037 1.28038 7.56408 1.19514C7.4778 1.10989 7.3677 1.0528 7.24831 1.03139C7.12892 1.00997 7.00585 1.02525 6.89532 1.0752C5.09333 1.89778 3.52202 3.15206 2.32055 4.72695C1.11908 6.30183 0.324548 8.14871 0.00731685 10.104C-0.00503902 10.1891 0.000762586 10.2759 0.0243403 10.3586C0.047918 10.4414 0.088738 10.5182 0.144117 10.584C0.200336 10.6501 0.270216 10.7032 0.34894 10.7396C0.427664 10.7761 0.513359 10.7951 0.600117 10.7952L4.89852 10.8Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      d="M18.8185 13.2C18.6676 13.1996 18.5221 13.2562 18.4113 13.3586C18.3004 13.461 18.2324 13.6015 18.2209 13.752C17.9553 16.6495 17.2116 19.4831 16.0201 22.1376C15.9715 22.2487 15.9577 22.372 15.9806 22.4911C16.0034 22.6102 16.0619 22.7196 16.1482 22.8049C16.2344 22.8901 16.3445 22.9472 16.4639 22.9686C16.5833 22.99 16.7064 22.9748 16.8169 22.9248C18.6196 22.1025 20.1916 20.8484 21.3939 19.2735C22.5962 17.6987 23.3916 15.8516 23.7097 13.896C23.7221 13.8109 23.7163 13.7241 23.6927 13.6414C23.6691 13.5586 23.6283 13.4818 23.5729 13.416C23.5167 13.3499 23.4468 13.2968 23.3681 13.2604C23.2894 13.2239 23.2037 13.2049 23.1169 13.2048L18.8185 13.2Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      d="M5.49132 13.752C5.47987 13.6024 5.41254 13.4625 5.30271 13.3602C5.19288 13.258 5.04859 13.2008 4.89852 13.2H0.600117C0.513286 13.2003 0.427552 13.2194 0.34879 13.256C0.270029 13.2925 0.200101 13.3457 0.143798 13.4118C0.0874956 13.4779 0.0461493 13.5554 0.0225923 13.639C-0.000964725 13.7225 -0.00617556 13.8102 0.00731685 13.896C0.324951 15.8511 1.11964 17.6978 2.32106 19.2727C3.52248 20.8475 5.09358 22.1019 6.89532 22.9248C7.00585 22.9748 7.12892 22.99 7.24831 22.9686C7.3677 22.9472 7.4778 22.8901 7.56408 22.8049C7.65037 22.7196 7.70879 22.6102 7.73165 22.4911C7.75451 22.372 7.74072 22.2487 7.69212 22.1376C6.5006 19.4831 5.75693 16.6495 5.49132 13.752Z"
      fill="currentColor"
    />
  </Icon>
)
