import { useCallback } from 'react'

import { useBreakpointValue } from 'native-base'

import { UsePageNotFoundScreen } from './PageNotFoundScreen.types'

export const usePageNotFoundScreen: UsePageNotFoundScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  /**
   * @TODO change navigation navigate to useLinkTo
   * define custom names cause this name used in url in version web
   */

  const goToStart = useCallback(
    () => navigation.navigate('Tab', { screen: 'Home', params: { refetch: true } }),
    [navigation]
  )

  return { goToStart, isMobile }
}
