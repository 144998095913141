import { useCallback } from 'react'

import { manipulateAsync } from 'expo-image-manipulator'
import * as ImagePicker from 'expo-image-picker'

type TCallback = (photo: string) => void

type TUseCamera = () => {
  handleChooseImage(onSelect: TCallback): void
  handleTakePicture(onSelect: TCallback): void
}

export const useCamera: TUseCamera = () => {
  const handleChooseImage = useCallback(async (onSelect: (photo: string) => void) => {
    const result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      quality: 1,
      aspect: [4, 4],
      mediaTypes: ImagePicker.MediaTypeOptions.Images
    })

    if (!result.canceled) {
      const [image] = result.assets

      const newResult = await manipulateAsync(
        image.uri,
        [
          {
            resize: {
              height: 1024,
              width: 1024
            }
          }
        ],
        {
          compress: 1
        }
      )

      onSelect(newResult.uri)
    }

    if (result.canceled) {
      onSelect('')
    }
  }, [])

  const _openCamera = useCallback(async (onSelect: (photo: string) => void) => {
    const result = await ImagePicker.launchCameraAsync({
      allowsEditing: true,
      aspect: [4, 4],
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1
    })

    if (!result.canceled) {
      const [picture] = result.assets

      const newResult = await manipulateAsync(
        picture.uri,
        [
          {
            resize: {
              height: 1024,
              width: 1024
            }
          }
        ],
        { compress: 1 }
      )

      onSelect(newResult.uri)
    }

    if (result.canceled) {
      onSelect('')
    }
  }, [])

  const handleTakePicture = useCallback(
    async (onSelect: (photo: string) => void) => {
      try {
        const permissions = await ImagePicker.getCameraPermissionsAsync()

        if (!permissions.granted) {
          const requested = await ImagePicker.requestCameraPermissionsAsync()

          if (requested.granted) {
            await _openCamera(onSelect)
          }
        } else {
          await _openCamera(onSelect)
        }
      } catch (error) {
        console.log('takePictureERror', error)
      }
    },
    [_openCamera]
  )

  return {
    handleChooseImage,
    handleTakePicture
  }
}
