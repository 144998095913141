import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import * as requests from './requests'
import { THirePlusUrl } from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_HIRE_MORE_URL_QUERY = 'getHirePlusUrl'

export const useGetHirePlusUrlQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<THirePlusUrl>>, AxiosError<ResponseError>>(
    [KEY_HIRE_MORE_URL_QUERY],
    () => requests.getHirePlusUrl()
  )
