import { FunctionComponent, useMemo, useCallback, useEffect, useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons, LoadingTurbo } from 'atoms'
import * as ScreenOrientation from 'expo-screen-orientation'
import LottieView from 'lottie-react-native'
import { UnconnectedMessage } from 'molecules'
import { Box, Center, Heading, HStack, Modal, Pressable, Text, useTheme } from 'native-base'
import Chart from 'organisms/Chart'
import { Dimensions, Platform, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSetContractListScreenAtom } from 'src/store/screens/contractListScreen'
import { formatDateStringToMask, getMonthName } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

import { PanelsTypes, panelsFullChartsProps } from '../../TabPanels/constants'

const changeScreenOrientation = (orientation: ScreenOrientation.OrientationLock) => {
  return ScreenOrientation.lockAsync(orientation)
}

type FullChartLayoutProps = {
  title?: string
  last_updated?: string
  subtitles?: { title: string; color: string }[]
  handleGoBack?(): void
  showModal: boolean
  setShowModal(value: boolean): void
  showSpinner: boolean
  type: PanelsTypes
  chartData: any
  chartsDataIsLoading: boolean
  colors: string[]
  chartXValues: string[]
  tabNumber?: string
  isShortFormat?: boolean
}

const CONTRACT_LIST_NAVIGATION = [
  PanelsTypes.PRODUCTION,
  PanelsTypes.MARKETING_SHARE,
  PanelsTypes.CREDIT_LIFE_INSURANCE,
  PanelsTypes.OVER30_M3,
  PanelsTypes.FPD10,
  PanelsTypes.SANTANDER_AUTO_INSURANCE
]

export const FullChartLayout: FunctionComponent<FullChartLayoutProps> = ({
  handleGoBack,
  setShowModal,
  showModal,
  showSpinner,
  type,
  chartData,
  chartsDataIsLoading,
  chartXValues,
  colors,
  tabNumber,
  last_updated,
  isShortFormat = false
}) => {
  const insets = useSafeAreaInsets()

  const isMobile = Platform.OS !== 'web'

  const linkTo = useLinkTo()

  const [spinner, setSpinner] = useState(true)

  useEffect(() => {
    if (!spinner) return

    const timeoutSpinner = setTimeout(() => {
      setSpinner(!spinner)
    }, 3000)

    return () => {
      clearTimeout(timeoutSpinner)
    }
  }, [spinner])

  const isMultipleChart = useMemo(
    () =>
      [
        `${PanelsTypes.CONTRACTS_AMOUNT}GoodsAndServices`,
        PanelsTypes.CONTRACTS_AMOUNT,
        PanelsTypes.SPREAD,
        PanelsTypes.CREDIT_LIFE_INSURANCE,
        PanelsTypes.TYPES_SANTANDER_AUTO
      ].includes(type),
    [type]
  )

  const phoneWidth = Dimensions.get('window').width

  const lowWidthPhone = phoneWidth < 700

  const multipleSubtitles = panelsFullChartsProps[type].subtitles.length > 2

  const theme = useTheme()

  const setContractListScreen = useSetContractListScreenAtom()

  const handleGoToContractListScreen = useCallback(
    (monthYear: string) => {
      const productionMonthYear = `${getMonthName(Number(monthYear.slice(4)))} ${monthYear.slice(
        0,
        4
      )}`

      LogEvent(`resumo_carteira_grafico_completo`, {
        item_id: panelsFullChartsProps[type].slug
      })

      if (CONTRACT_LIST_NAVIGATION.includes(type) && tabNumber) {
        if (isMobile) {
          setSpinner(true)

          changeScreenOrientation(ScreenOrientation.OrientationLock.PORTRAIT_UP)

          linkTo(`/contratos/${tabNumber}/${monthYear}/${type}`)

          return
        }

        linkTo(`/contratos/${tabNumber}/${monthYear}/${type}`)

        setContractListScreen({
          title: 'Contratos da TAB',
          description: `Produção ${productionMonthYear} (R$)`
        })
      }
    },
    [isMobile, linkTo, setContractListScreen, tabNumber, type]
  )

  const onGoBack = () => {
    setSpinner(true)

    changeScreenOrientation(ScreenOrientation.OrientationLock.PORTRAIT_UP)

    handleGoBack?.()
  }

  return showSpinner || spinner ? (
    <View
      style={{
        flex: 1,
        padding: insets.left
      }}>
      <LoadingTurbo />
    </View>
  ) : (
    <>
      <Box
        flex={1}
        safeAreaLeft={isMobile}
        safeAreaBottom={isMobile}
        py={4}
        px={6}
        _ios={{ pt: lowWidthPhone ? 12 : 20 }}
        bg="white">
        {isMobile && (
          <HStack
            justifyContent="space-between"
            alignItems="center"
            zIndex={2}
            _android={{ mt: 4 }}
            mb="17px">
            <Heading fontSize="14px">{panelsFullChartsProps[type].title}</Heading>
            <Pressable onPress={onGoBack}>
              <Center bg="background.base" borderRadius="full" size={10} p={3}>
                <Icons.Close size={4} color="gray.700" />
              </Center>
            </Pressable>
          </HStack>
        )}

        <Box flex={isMobile ? 9 : 1} mb="18px">
          {chartsDataIsLoading ? (
            <View style={{ flex: 9 }}>
              <LoadingTurbo size={80} />
            </View>
          ) : chartData.length ? (
            isMultipleChart ? (
              <Chart.LandscapeMultipleBars
                colors={colors}
                chartsData={chartData}
                tickValues={chartXValues}
                onClickChart={(monthYear) => handleGoToContractListScreen(monthYear)}
                type={panelsFullChartsProps[type].type}
              />
            ) : (
              <Chart.LandscapeSingleBar
                chartData={chartData}
                color={type === PanelsTypes.OVER30_M3 ? theme.colors.primary[300] : undefined}
                onClickChart={(monthYear) => handleGoToContractListScreen(monthYear)}
                type={panelsFullChartsProps[type].type}
              />
            )
          ) : (
            <UnconnectedMessage />
          )}
        </Box>

        <HStack flex={isMobile ? 1 : 0}>
          <HStack alignItems="flex-start" flex={1} space={4}>
            {panelsFullChartsProps[type].subtitles &&
              panelsFullChartsProps[type].subtitles.length > 0 &&
              panelsFullChartsProps[type].subtitles.map((subtitle) => (
                <HStack
                  key={Math.random()}
                  alignItems="center"
                  maxW={multipleSubtitles ? '60%' : undefined}>
                  <Center
                    bg={subtitle.color}
                    borderRadius="full"
                    size={isMobile ? 3 : 4}
                    mr="6px"
                  />
                  <Text
                    color="gray.500"
                    fontSize={isMobile ? '12px' : '14px'}
                    fontWeight={700}
                    isTruncated>
                    {subtitle.title}
                  </Text>
                </HStack>
              ))}
          </HStack>

          {last_updated && (
            <HStack flex={1} justifyContent="flex-end" alignItems="flex-start">
              <Text color="gray.200" fontSize="14px" fontWeight="semibold">
                Referência{' '}
                {last_updated &&
                  formatDateStringToMask(last_updated, isShortFormat ? 'MM/yyyy' : 'dd/MM/yyyy')}
              </Text>
            </HStack>
          )}
        </HStack>
      </Box>

      {isMobile && (
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          _backdrop={{
            mb: -100,
            bg: 'black',
            opacity: '0.65'
          }}>
          <Center>
            <LottieView
              autoPlay
              loop
              style={{
                height: 280
              }}
              source={require('assets/change-orientation.json')}
            />
          </Center>
        </Modal>
      )}
    </>
  )
}
