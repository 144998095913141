import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { NotificationScreenComponent } from './NotificationScreen.types'
import { useNotificationScreen } from './useNotificationScreen'

const NotificationScreenDesktop = lazy(() => import('./NotificationScreen.desktop'))

const NotificationScreenMobile = lazy(() => import('./NotificationScreen.mobile'))

export const NotificationScreen: NotificationScreenComponent = ({ navigation }) => {
  const props = useNotificationScreen({ navigation })

  const Screen = props.isMobile ? NotificationScreenMobile : NotificationScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
