import { memo } from 'react'

import Svg, { Path, Rect } from 'react-native-svg'

const RelatedOpportunitiesEmptyIllustration = () => (
  <Svg width={251} height={251} viewBox="0 0 251 251" fill="none">
    <Path
      d="m80.393 208.466 52.042 10.878 7.076-6.105 45.652-170.732.206-4.458-.825-3.302-1.899-2.806-2.22-1.998-1.919-.853L96.389 7.335l-11.944 4.052L39.228 179.46l-1.92 13.864 43.085 15.143Z"
      fill="#F5F5F5"
    />
    <Path
      d="M82.375 177.807a12.723 12.723 0 1 1-9-15.58 12.757 12.757 0 0 1 9 15.58Z"
      fill="#fff"
    />
    <Path
      d="M97.266 17.973a3.22 3.22 0 1 0 4.536-.404 3.2 3.2 0 0 0-2.352-.741 3.21 3.21 0 0 0-2.184 1.145Z"
      fill="#F3123C"
    />
    <Path
      d="m140.775 208.562-1.188 4.394a15.09 15.09 0 0 1-7.028 9.161 15.08 15.08 0 0 1-11.448 1.502L47.75 203.954a18.772 18.772 0 0 1-13.275-22.988l43.887-163.92A14.422 14.422 0 0 1 96.032 6.86l4.322 1.164a11.572 11.572 0 0 0-14.155 8.193L41.787 182.01a11.499 11.499 0 0 0 8.194 14.177l76.663 20.544a11.544 11.544 0 0 0 14.131-8.17Z"
      fill="#F3123C"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M181.837 171.655a48.464 48.464 0 0 0-4.928-66 48.467 48.467 0 1 0-4.572 75l58.4 61.178a6.526 6.526 0 0 0 7.123 1.645 6.565 6.565 0 0 0 2.171-1.374 6.517 6.517 0 0 0 2.02-4.615 6.52 6.52 0 0 0-1.861-4.681v.001l-58.353-61.154Zm-77.65-18.253a41.796 41.796 0 0 0 13.955 20.532v.001a41.84 41.84 0 0 0 55.625-3.183 41.803 41.803 0 0 0 8.542-46.636 41.801 41.801 0 0 0-63.768-15.9 41.797 41.797 0 0 0-14.354 45.186Z"
      fill="#595959"
    />
    <Path
      d="M221.784 55.807a2.779 2.779 0 1 1-5.56.001 2.779 2.779 0 0 1 5.56 0ZM42.737 90.047a2.777 2.777 0 0 1-5.504.542 2.779 2.779 0 1 1 5.504-.542Z"
      fill="#F5F5F5"
      stroke="#595959"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M140.775 208.562 185.21 42.697a11.52 11.52 0 0 0 .291-4.493 11.522 11.522 0 0 0-8.461-9.632L100.375 8.023M34.474 180.966a18.783 18.783 0 0 0 13.276 22.988l73.337 19.665c1.914.515 3.91.649 5.875.392a15.09 15.09 0 0 0 5.575-1.89 15.096 15.096 0 0 0 4.427-3.883 15.086 15.086 0 0 0 2.6-5.282l1.187-4.394a11.553 11.553 0 0 1-14.125 8.17l-77.779-20.9a11.477 11.477 0 0 1-7.053-13.821L86.199 16.216a11.593 11.593 0 0 1 14.176-8.193l-4.32-1.163a14.424 14.424 0 0 0-17.669 10.187L34.474 180.966Z"
      stroke="#595959"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M82.375 177.807a12.726 12.726 0 0 1-10.626 9.324 12.727 12.727 0 0 1-14.36-13.443 12.724 12.724 0 0 1 15.986-11.461 12.758 12.758 0 0 1 9 15.58ZM71.665 168.71 68.53 180.3M75.892 176.073l-11.59-3.135M171.269 126.912l-48.899 28.142M160.891 165.433l-28.143-48.9M84.37 64.332l77.685 20.805M180.75 59.32c8.336 1.5 13.654 4.396 14.844 8.717 3.75 13.61-34.912 36.17-86.375 50.349-51.464 14.178-96.22 14.653-99.97 1.045-2.087-7.571 8.956-17.93 28.05-28.048M209.672 133.609l23.844-2.113M208.722 165.884l20.519 12.35M202.381 107.651l17.74-16.078M28.061 40.773l4.798-2.684M41.384 33.34l4.797-2.685M32.051 26.642l2.684 4.797M39.507 39.966l2.684 4.82M27.135 32.864l5.296 1.52M41.811 37.044l5.296 1.497M39.959 25.716l-1.497 5.296M35.804 40.417l-1.52 5.273"
      stroke="#595959"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M181.837 171.656a48.465 48.465 0 0 0-4.928-66 48.465 48.465 0 0 0-66.183.452 48.466 48.466 0 0 0 61.611 74.548l58.4 61.177a6.528 6.528 0 0 0 7.123 1.645 6.535 6.535 0 0 0 2.33-10.67m-58.353-61.152 58.353 61.153v-.001m-58.353-61.152 58.353 61.152m-122.048-58.874a41.798 41.798 0 1 1 55.625-3.182 41.839 41.839 0 0 1-55.625 3.184v-.002Z"
      stroke="#595959"
      strokeWidth={1.25}
      strokeLinejoin="round"
    />
    <Rect
      x={118.279}
      y={31.08}
      width={33.985}
      height={33.985}
      rx={16.993}
      transform="rotate(14.558 118.279 31.08)"
      fill="#fff"
      stroke="#595959"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M128.202 45.395a3.114 3.114 0 0 1 2.548-.275l4.674 1.214a1.193 1.193 0 0 1-.599 2.309l-4.715-1.224a1.369 1.369 0 0 1-.091-.028.728.728 0 0 0-.957.563 1.231 1.231 0 0 1-.018.084v.001a.5.5 0 0 0 .04.163c.053.14.166.357.399.666.435.578 1.078 1.214 1.811 1.936l.041.04c.684.673 1.45 1.426 2.003 2.161.513.681 1.134 1.715.847 2.844a3.224 3.224 0 0 1-3.989 2.347l-4.705-1.222a1.193 1.193 0 1 1 .6-2.31l4.735 1.23a.839.839 0 0 0 1.042-.612l.003-.013a.5.5 0 0 0-.04-.164c-.053-.14-.166-.356-.399-.665-.435-.579-1.078-1.215-1.811-1.936l-.041-.04c-.684-.673-1.45-1.426-2.003-2.162-.507-.674-1.12-1.692-.856-2.806a3.113 3.113 0 0 1 1.481-2.101Z"
      fill="#595959"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.72 42.943a1.193 1.193 0 0 0-1.455.855l-.654 2.518a1.193 1.193 0 0 0 2.309.6l.654-2.519a1.193 1.193 0 0 0-.854-1.454Zm-3.271 12.594a1.192 1.192 0 0 0-1.454.855l-.655 2.519a1.193 1.193 0 0 0 2.309.6l.655-2.52a1.194 1.194 0 0 0-.855-1.454Z"
      fill="#595959"
    />
  </Svg>
)

export const RelatedOpportunitiesEmpty = memo(RelatedOpportunitiesEmptyIllustration)
