import { useEffect, useState } from 'react'

import { Icons } from 'atoms'
import { ActivitiesType } from 'integration/resources/activities'
import {
  Box,
  Divider,
  HStack,
  Heading,
  IconButton,
  Pressable,
  ScrollView,
  Spinner,
  Text,
  VStack,
  useDisclose
} from 'native-base'
import { useBottomSheetActivities } from 'organisms/BottomSheetActivities/hook'

import { TSelectTypeOfActivitiesProps } from './types'

export const SelectTypeOfActivities: TSelectTypeOfActivitiesProps = ({ value, onSelect }) => {
  const { isOpen, onToggle } = useDisclose(false)

  const { activities, isLoading } = useBottomSheetActivities()

  useEffect(() => {
    setValueSelected(value)
  }, [value])

  const [valueSelected, setValueSelected] = useState<ActivitiesType | undefined>(value)

  const handleSelect = (activity: ActivitiesType) => {
    setValueSelected(activity)

    onSelect(activity)

    onToggle()
  }

  return (
    <Box position="relative">
      <Pressable
        onPress={onToggle}
        alignItems="center"
        w="full"
        h="50px"
        bg="gray.30"
        borderRadius="30px"
        flexDir="row"
        px={4}
        py="13px">
        <Text fontSize="16px" lineHeight="24px" color="gray.500" mr={4}>
          Tipo
        </Text>
        <HStack flex={1}>
          {valueSelected && (
            <HStack px={4} alignItems="center" bg="tertiary.800" h="24px" borderRadius="40px">
              <Heading textTransform="uppercase" fontSize="12px" color="white">
                {valueSelected.name}
              </Heading>
            </HStack>
          )}
        </HStack>

        <HStack alignItems="center" space={2}>
          {valueSelected && (
            <IconButton
              borderRadius="full"
              colorScheme="gray"
              onPress={() => setValueSelected(undefined)}
              icon={<Icons.CloseTab color="gray.700" size={4} />}
            />
          )}
          {isLoading ? <Spinner /> : <Icons.ArrowDropDown size={6} color="gray.700" />}
        </HStack>
      </Pressable>

      {isOpen && (
        <VStack
          position="static"
          bg="white"
          maxH="500px"
          w="99%"
          mx="auto"
          style={{
            // @ts-ignore
            boxShadow: '0px 5px 15px rgba(68, 68, 79, 0.1)'
          }}
          top={10}
          _important={{
            zIndex: 999
          }}
          borderRadius="20px"
          py={8}>
          <ScrollView>
            {activities?.map((item, index) => (
              <>
                <Pressable
                  onPress={() => handleSelect(item)}
                  flexDir="row"
                  alignItems="center"
                  h={10}
                  px={8}
                  {...(item.id === valueSelected?.id && { bg: 'gray.30' })}
                  py={4}
                  key={item.id}>
                  <Text>{item.name}</Text>
                </Pressable>
                {activities?.length !== index + 1 && <Divider bg="gray.30" my={4} />}
              </>
            ))}
          </ScrollView>
        </VStack>
      )}
    </Box>
  )
}
