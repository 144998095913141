import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ChatScreenComponent } from './ChatScreen.types'
import { useChatScreen } from './useChatScreen'

const ChatScreenDesktop = lazy(() => import('./ChatScreen.desktop'))

const ChatScreenMobile = lazy(() => import('./ChatScreen.mobile'))

export const ChatScreen: ChatScreenComponent = ({ navigation }) => {
  const props = useChatScreen({ navigation })

  const Screen = props.isMobile ? ChatScreenMobile : ChatScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
