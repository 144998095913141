import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

type Direction = 'up' | 'down' | 'left' | 'right'

const getPath = (direction: Direction) => {
  switch (direction) {
    case 'up':
      return (
        <Path
          key={Math.random()}
          d="M11 22.6001C11 23.1524 11.4477 23.6001 12 23.6001C12.5523 23.6001 13 23.1524 13 22.6001L13 3.41421L17.2929 7.70711C17.6834 8.09763 18.3166 8.09763 18.7071 7.70711C19.0976 7.31658 19.0976 6.68342 18.7071 6.29289L12.7071 0.292892C12.3166 -0.097632 11.6834 -0.0976321 11.2929 0.292892L5.29289 6.29289C4.90237 6.68342 4.90237 7.31658 5.29289 7.70711C5.68342 8.09763 6.31658 8.09763 6.70711 7.70711L11 3.41421L11 22.6001Z"
          fill="currentColor"
        />
      )

    case 'left':
      return (
        <Path
          key={Math.random()}
          d="M22.8311 12.8306C23.3833 12.8306 23.8311 12.3829 23.8311 11.8306C23.8311 11.2783 23.3833 10.8306 22.8311 10.8306L3.64517 10.8306L7.93806 6.53768C8.32859 6.14715 8.32859 5.51399 7.93806 5.12346C7.54754 4.73294 6.91438 4.73294 6.52385 5.12346L0.523851 11.1235C0.133327 11.514 0.133327 12.1472 0.523851 12.5377L6.52385 18.5377C6.91438 18.9282 7.54754 18.9282 7.93807 18.5377C8.32859 18.1472 8.32859 17.514 7.93807 17.1235L3.64517 12.8306L22.8311 12.8306Z"
          fill="currentColor"
        />
      )

    case 'down':
      return (
        <Path
          key={Math.random()}
          d="M13 1C13 0.447715 12.5523 0 12 0C11.4477 0 11 0.447715 11 1L11 20.1859L6.70711 15.893C6.31658 15.5025 5.68342 15.5025 5.29289 15.893C4.90237 16.2835 4.90237 16.9167 5.29289 17.3072L11.2929 23.3072C11.6834 23.6977 12.3166 23.6977 12.7071 23.3072L18.7071 17.3072C19.0976 16.9167 19.0976 16.2835 18.7071 15.893C18.3166 15.5025 17.6834 15.5025 17.2929 15.893L13 20.1859L13 1Z"
          fill="currentColor"
        />
      )

    case 'right':
      return (
        <Path
          key={Math.random()}
          d="M2.40218 11.973C1.84989 11.9716 1.40104 12.4182 1.39964 12.9705C1.39823 13.5228 1.84481 13.9716 2.39709 13.973L21.5829 14.0218L17.2791 18.3038C16.8876 18.6933 16.886 19.3265 17.2755 19.718C17.6651 20.1095 18.2982 20.1111 18.6897 19.7216L24.705 13.7369C25.0965 13.3473 25.0981 12.7142 24.7086 12.3226L18.7238 6.30741C18.3343 5.91589 17.7012 5.91428 17.3096 6.30381C16.9181 6.69334 16.9165 7.32651 17.306 7.71802L21.588 12.0218L2.40218 11.973Z"
          fill="currentColor"
        />
      )
  }
}

export const ArrowIcon: FunctionComponent<
  IIconProps & { direction: 'up' | 'down' | 'left' | 'right' }
> = ({ direction, ...props }) => (
  <Icon {...{ viewBox: '0 0 26 26', ...props }}>{getPath(direction)}</Icon>
)
