import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path, G, ClipPath, Rect, Defs } from 'react-native-svg'

export const ZendeskIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 50 50', ...props }}>
    <Rect width={50} height={50} rx={25} fill="#F3123C" />
    <G clipPath="url(#a)" fill="#fff">
      <Path d="M24.8 13C18.29 13 13 18.29 13 24.8c0 6.51 5.29 11.8 11.8 11.8 6.51 0 11.8-5.29 11.8-11.8 0-6.51-5.29-11.8-11.8-11.8Zm0 21.6c-5.4 0-9.8-4.4-9.8-9.8s4.4-9.8 9.8-9.8 9.8 4.4 9.8 9.8-4.4 9.8-9.8 9.8Z" />
      <Path d="M27.05 18.49c-.56-.38-1.22-.62-1.89-.68-.68-.06-1.36.05-1.98.33-.62.28-1.17.71-1.57 1.26-.33.44-.24 1.07.2 1.4.44.33 1.07.24 1.4-.2.2-.27.48-.49.79-.63.31-.14.65-.2.99-.16.34.03.67.15.95.34.28.19.51.45.66.76.15.3.22.65.21.98-.02.34-.12.67-.3.96-.18.29-.43.53-.73.7-.3.17-.63.25-.97.25-.55 0-1 .45-1 1s.45 1 1 1c.68 0 1.35-.18 1.95-.51.59-.33 1.1-.81 1.45-1.39.36-.58.56-1.24.59-1.93a4.03 4.03 0 0 0-1.74-3.49l-.01.01ZM24.8 28.3a1.9 1.9 0 1 0 0 3.8 1.9 1.9 0 1 0 0-3.8Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(13 13)" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Icon>
)
