import { memo } from 'react'

import Svg, { G, Path, Defs, ClipPath, Rect } from 'react-native-svg'

const BirthdayIllustration = () => (
  <Svg width={251} height={250} viewBox="0 0 251 250" fill="none">
    <G clipPath="url(#clip0_8498_161522)">
      <Path
        d="M204.21 112.58H91.5401C84.5427 112.58 78.8701 118.253 78.8701 125.25V204.31C78.8701 211.307 84.5427 216.98 91.5401 216.98H204.21C211.208 216.98 216.88 211.307 216.88 204.31V125.25C216.88 118.253 211.208 112.58 204.21 112.58Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M131.87 123.68C131.87 123.68 131.86 123.75 131.85 123.79C131.47 126.02 130.74 128.08 129.66 130.04C129.6 130.15 129.53 130.27 129.47 130.38C129.37 129 129.08 127.72 128.69 126.44C128.68 126.42 128.68 126.4 128.67 126.38C129.67 125.68 130.77 124.78 131.87 123.68Z"
        fill="#404040"
      />
      <Path
        d="M207.87 209.68C207.84 209.76 207.8 209.85 207.76 209.93"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M170.67 167.58C169.26 169.42 167.23 172.54 164.97 176.25"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M170.87 216.88L112.18 216.65C113.47 212.86 114.77 206.7 117.25 199.85C122.91 205.55 137.45 204.84 147.93 200.64H147.94C149.61 199.98 151.17 199.22 152.58 198.39C160.58 202.39 166.97 208.88 170.87 216.88Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M110.57 184.44L109.19 186.08"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M119.67 154.28C116.77 157.98 114.77 162.48 114.07 167.18"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M117.25 199.85C117.25 199.85 117.2 199.81 117.17 199.78"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M152.61 198.37C152.61 198.37 152.59 198.38 152.58 198.39"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M147.94 200.64C152.71 205.13 156.28 210.61 158.57 216.78"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M147.93 200.64L147.87 200.58"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M131.85 123.79C130.7 124.84 129.74 125.7 128.77 126.38"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M129.67 130.19C129.64 130.25 129.61 130.32 129.57 130.38"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M156.61 133.82C149.33 138.71 142.55 144.4 136.57 150.88C135.67 151.88 134.47 152.58 133.17 152.98C131.87 153.38 130.47 153.38 129.17 153.08C127.87 152.78 126.67 152.18 125.67 151.18C124.67 150.28 123.97 149.08 123.47 147.78C123.17 146.69 122.83 145.64 122.54 144.74C123.18 144.23 123.76 143.74 124.27 143.28C126.77 141.28 128.47 138.58 129.27 135.58C129.73 133.84 129.86 132 129.67 130.19C130.82 128.15 131.58 126.01 131.97 123.68C131.93 123.72 131.89 123.76 131.85 123.79C131.86 123.75 131.86 123.72 131.87 123.68C130.77 124.78 129.67 125.68 128.67 126.38C128.68 126.4 128.68 126.42 128.69 126.44C119.47 133.12 115.44 126.69 117.2 106.88C121.39 106.08 125.68 105.88 129.87 106.08C129.97 107.48 130.17 108.88 130.47 110.28C130.67 111.18 131.27 111.28 132.47 109.78C135.07 106.68 139.27 112.18 133.47 115.68C135.37 119.88 138.17 123.88 141.37 127.28C144.97 130.78 149.67 132.98 154.67 133.58C155.34 133.68 155.98 133.76 156.61 133.82Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M233.47 171.38C227.57 179.38 224.07 187.18 217.17 198.78C214.17 203.74 211 207.41 207.76 209.93H207.75C195.11 219.83 181.66 212.41 175.37 198.08L164.97 176.25C160.84 183.06 155.93 191.85 152.57 198.38C152.57 198.38 152.58 198.38 152.58 198.39C151.17 199.22 149.61 199.98 147.94 200.64H147.93C137.45 204.84 122.91 205.55 117.25 199.85C117.25 199.83 117.26 199.8 117.27 199.78C115.77 193.28 114.77 186.78 114.07 180.28L110.57 184.44C109.07 181.5 105.97 179.42 102.37 179.28H101.77V167.93C107.62 155.4 117.08 149.11 122.54 144.74C122.83 145.64 123.17 146.69 123.47 147.78C123.97 149.08 124.67 150.28 125.67 151.18C126.67 152.18 127.87 152.78 129.17 153.08C130.47 153.38 131.87 153.38 133.17 152.98C134.47 152.58 135.67 151.88 136.57 150.88C142.55 144.4 149.33 138.71 156.61 133.82C171.41 135.35 175.78 129.21 194.97 181.78C200.17 172.88 205.97 164.38 212.47 156.38C218.07 163.08 225.27 168.28 233.47 171.38Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M164.97 176.25L164.95 176.2"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M117.21 106.71C117.2 106.77 117.2 106.82 117.2 106.88"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M128.69 126.44C128.69 126.44 128.74 126.4 128.77 126.38C129.21 127.55 129.51 128.79 129.66 130.04C129.66 130.09 129.67 130.14 129.67 130.19"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M101.77 167.93L101.56 168.38"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M122.54 144.74C122.54 144.74 122.53 144.74 122.53 144.72"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M156.67 133.78C156.67 133.78 156.63 133.81 156.61 133.82"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M194.87 181.68L189.97 190.48"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M168.67 94.08C168.67 94.48 168.67 94.88 168.57 95.18C168.47 95.88 168.37 96.58 168.17 97.28C168.17 97.38 168.07 97.48 168.07 97.58C167.97 97.88 167.87 98.18 167.77 98.38C167.67 98.68 167.47 98.98 167.37 99.18C167.27 99.38 167.17 99.58 167.07 99.68C166.97 99.88 166.87 100.08 166.67 100.28V100.38C166.47 100.58 166.27 100.88 166.17 101.08L166.07 101.18C165.97 101.28 165.87 101.38 165.77 101.48C165.57 101.68 165.37 101.88 165.17 102.08C164.97 102.28 164.77 102.38 164.57 102.58L164.47 102.68C164.37 102.78 164.27 102.78 164.27 102.88C164.17 102.98 164.07 102.98 164.07 103.08C163.97 103.18 163.77 103.28 163.67 103.38C163.57 103.48 163.37 103.58 163.17 103.68C162.97 103.78 162.87 103.88 162.67 103.98C162.67 103.98 162.57 103.98 162.57 104.08C162.47 104.18 162.27 104.18 162.17 104.28C162.07 104.38 161.97 104.38 161.87 104.48C161.77 104.48 161.67 104.58 161.47 104.58H161.37C161.07 104.68 160.87 104.78 160.57 104.78C160.37 104.78 160.27 104.88 160.07 104.88C159.87 104.88 159.67 104.98 159.47 104.98H159.17C158.97 104.98 158.77 105.08 158.47 105.08H156.77C156.37 101.98 155.27 99.08 153.57 96.48V95.98C153.47 94.08 153.17 89.08 152.57 83.68C153.17 83.38 153.87 83.18 154.57 82.88C154.77 82.78 155.07 82.78 155.37 82.68C156.67 82.38 158.07 82.38 159.47 82.58C160.17 82.68 160.77 82.88 161.37 83.08C161.67 83.18 161.97 83.28 162.27 83.48C162.57 83.68 162.87 83.78 163.17 83.98C163.77 84.28 164.27 84.68 164.77 85.08C164.97 85.28 165.27 85.48 165.47 85.78C165.57 85.88 165.67 85.98 165.67 85.98C165.77 86.18 165.97 86.28 166.07 86.48C166.47 86.98 166.87 87.58 167.17 88.08C167.37 88.38 167.47 88.68 167.67 88.98C167.87 89.28 167.97 89.58 168.07 89.88C168.17 90.18 168.27 90.48 168.37 90.78C168.47 91.08 168.47 91.38 168.57 91.78C168.57 92.78 168.67 93.48 168.67 94.08Z"
        fill="#404040"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M157.07 107.18C156.77 106.98 156.77 107.18 156.77 107.28V108.58C156.77 108.88 156.77 109.18 156.67 109.48C156.57 110.08 156.57 110.58 156.47 111.18C156.37 111.58 156.27 111.98 156.17 112.38C156.07 112.78 155.97 113.18 155.87 113.58C155.77 113.98 155.57 114.38 155.47 114.78C155.37 114.98 155.37 115.18 155.27 115.38C155.17 115.58 155.07 115.78 155.07 115.98C154.57 117.18 153.87 118.28 153.17 119.38C152.97 119.68 152.77 119.98 152.47 120.28C149.67 123.78 145.77 126.28 141.37 127.28C138.17 123.88 135.37 119.88 133.47 115.68C139.27 112.18 135.07 106.68 132.47 109.78C131.27 111.28 130.67 111.18 130.47 110.28C130.17 108.88 129.97 107.48 129.87 106.08C125.68 105.88 121.39 106.08 117.2 106.88H117.17C117.17 106.48 117.17 106.08 117.27 105.68C117.27 105.38 117.37 105.08 117.37 104.78C117.37 104.58 117.47 104.38 117.47 104.18V104.08C117.47 103.98 117.47 103.88 117.57 103.78C117.57 103.58 117.67 103.48 117.67 103.38C117.67 103.28 117.77 103.08 117.77 102.98C117.77 102.88 117.87 102.78 117.87 102.58C117.97 102.28 118.07 101.98 118.17 101.68C118.27 101.48 118.37 101.18 118.47 100.88C118.57 100.48 118.77 100.08 118.97 99.78C119.17 99.38 119.27 99.08 119.47 98.68C119.57 98.48 119.67 98.18 119.87 97.98C119.97 97.78 120.17 97.48 120.27 97.28C120.47 96.98 120.67 96.58 120.97 96.28C120.97 96.28 120.97 96.18 121.07 96.18C121.27 95.88 121.57 95.58 121.77 95.28C122.07 94.98 122.27 94.58 122.57 94.28L123.47 93.38L123.57 93.28C123.87 92.98 124.17 92.78 124.37 92.58C124.67 92.38 124.87 92.18 125.17 91.98C125.27 91.88 125.37 91.88 125.47 91.78H125.57L125.67 91.68C125.77 91.58 125.87 91.58 125.97 91.48C125.97 91.38 126.07 91.38 126.07 91.38C133.47 99.48 153.67 97.48 153.67 97.48V97.08C155.37 99.68 156.47 102.58 156.87 105.68C156.87 105.88 156.87 106.08 156.97 106.28C156.97 106.58 156.97 106.88 157.07 107.18Z"
        fill="#404040"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M153.47 97.08C153.47 97.08 133.27 99.08 125.87 90.98C125.77 90.98 125.77 90.88 125.67 90.78C125.67 90.78 146.07 62.48 149.07 65.28C150.57 66.68 151.77 75.78 152.47 83.88L153.47 96.18V97.08Z"
        fill="#F3123C"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M244.82 140.22C244.32 141.72 243.82 142.92 243.42 144.02C242.12 147.12 240.92 150.12 239.72 153.22C238.42 156.52 230.72 170.22 230.72 170.22C224.82 168.12 219.02 163.12 214.52 158.72C214.62 158.52 217.62 154.02 217.82 153.82C218.42 152.92 218.72 151.82 218.72 150.72C218.72 149.62 218.32 148.52 217.72 147.62C215.52 144.42 212.62 139.72 213.82 138.62C215.72 136.92 220.52 143.62 223.32 144.32C224.32 144.52 225.42 141.52 225.72 140.42C226.22 138.42 227.92 131.52 228.02 130.82C229.32 124.52 231.52 125.82 230.92 131.42C230.42 136.72 230.17 140.32 230.17 140.32C231.44 137.33 234.48 130.15 234.72 129.52C237.12 123.62 238.92 125.12 237.52 130.52C236.21 135.39 234.81 141.24 234.64 141.95C236.3 139.14 237.83 136.25 239.22 133.22C242.02 127.42 243.42 129.12 241.92 134.42C241.03 137.7 240 141.05 238.71 144.23C240.07 142.46 241.3 140.6 242.32 138.62C245.32 132.92 246.72 134.72 244.82 140.22Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M238.71 144.23C237.95 145.22 237.15 146.18 236.32 147.12L238.71 144.23Z"
        fill="#F5F5F5"
      />
      <Path
        d="M238.71 144.23C237.95 145.22 237.15 146.18 236.32 147.12"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M234.64 141.95L234.62 142.02L234.64 141.95Z" fill="#F5F5F5" />
      <Path
        d="M234.64 141.95L234.62 142.02"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M230.17 140.32C229.83 141.12 229.62 141.62 229.62 141.62L230.17 140.32Z"
        fill="#F5F5F5"
      />
      <Path
        d="M230.17 140.32C229.83 141.12 229.62 141.62 229.62 141.62"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M238.71 144.23C238.71 144.23 238.7 144.27 238.69 144.29L238.71 144.23Z"
        fill="#F5F5F5"
      />
      <Path
        d="M238.71 144.23C238.71 144.23 238.7 144.27 238.69 144.29"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M234.64 141.95C234.28 142.58 233.9 143.2 233.52 143.82L234.64 141.95Z"
        fill="#F5F5F5"
      />
      <Path
        d="M234.64 141.95C234.28 142.58 233.9 143.2 233.52 143.82"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M227.82 140.82C233.02 143.02 235.82 146.02 238.92 150.82L227.82 140.82Z"
        fill="#F5F5F5"
      />
      <Path
        d="M227.82 140.82C233.02 143.02 235.82 146.02 238.92 150.82"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M226.22 143.22C227.92 145.32 229.22 147.72 229.92 150.32C230.62 152.92 230.82 155.62 230.42 158.32"
        fill="#F5F5F5"
      />
      <Path
        d="M226.22 143.22C227.92 145.32 229.22 147.72 229.92 150.32C230.62 152.92 230.82 155.62 230.42 158.32"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M37.27 136.08H37.47C37.67 136.08 37.87 136.28 37.97 136.38C38.17 136.58 38.27 136.88 38.27 137.18C38.27 137.48 38.17 137.78 37.97 137.98C37.77 138.08 37.67 138.18 37.37 138.28H37.17C37.07 138.28 36.87 138.28 36.77 138.18C36.57 138.08 36.37 137.98 36.27 137.78C36.17 137.58 36.07 137.38 36.07 137.18C36.07 136.98 36.17 136.78 36.27 136.58C36.37 136.38 36.57 136.28 36.77 136.18C37.07 136.08 37.17 136.08 37.27 136.08ZM37.27 135.08C36.97 135.08 36.77 135.08 36.57 135.18C36.17 135.28 35.87 135.58 35.67 135.88C35.47 136.18 35.27 136.58 35.27 136.98C35.27 137.38 35.37 137.78 35.57 138.08C35.77 138.38 36.07 138.68 36.47 138.88C36.77 138.98 36.97 139.08 37.27 139.08H37.67C38.07 138.98 38.47 138.78 38.67 138.58C39.07 138.18 39.27 137.68 39.27 137.18C39.27 136.68 39.07 136.18 38.67 135.78C38.37 135.48 38.07 135.28 37.67 135.18C37.57 135.08 37.47 135.08 37.27 135.08Z"
        fill="#2E2E2E"
      />
      <Path
        d="M13.07 207.48C12.47 208.08 11.67 208.48 10.77 208.68C9.86997 208.88 8.96997 208.68 8.16997 208.38C7.36997 207.98 6.66997 207.48 6.16997 206.68C5.66997 205.98 5.46997 205.08 5.46997 204.18C5.46997 203.28 5.76997 202.48 6.26997 201.78C6.76997 201.08 7.46997 200.48 8.26997 200.18C9.06997 199.88 9.96997 199.78 10.87 199.98C11.77 200.18 12.47 200.58 13.07 201.28C13.87 202.18 14.37 203.28 14.27 204.48C14.37 205.58 13.87 206.68 13.07 207.48Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M25.8699 99.28C25.5699 99.58 25.1699 99.78 24.7699 99.88C24.3699 99.98 23.8699 99.88 23.4699 99.68C23.0699 99.48 22.7699 99.18 22.4699 98.88C22.2699 98.48 22.0699 98.08 22.1699 97.68C22.1699 97.28 22.2699 96.78 22.5699 96.48C22.8699 96.08 23.1699 95.88 23.5699 95.68C23.9699 95.48 24.4699 95.48 24.8699 95.58C25.2699 95.68 25.6699 95.88 25.9699 96.18C26.3699 96.58 26.5699 97.18 26.5699 97.78C26.5699 98.28 26.3699 98.88 25.8699 99.28V99.28Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M70.1701 97.28C69.5701 97.88 68.7701 98.28 67.8701 98.48C66.9701 98.68 66.0701 98.48 65.2701 98.18C64.4701 97.78 63.7701 97.28 63.2701 96.48C62.7701 95.78 62.5701 94.88 62.5701 93.98C62.5701 93.08 62.8701 92.28 63.3701 91.58C63.8701 90.88 64.5701 90.28 65.3701 89.98C66.1701 89.68 67.0701 89.58 67.9701 89.78C68.8701 89.98 69.5701 90.38 70.1701 91.08C70.9701 91.98 71.4701 93.08 71.3701 94.28C71.4701 95.28 70.9701 96.48 70.1701 97.28Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M182.29 60.97L190.19 51.47C190.29 51.37 190.39 51.17 190.49 51.07C194.69 43.17 189.19 33.47 180.29 33.47C171.39 33.47 165.89 43.17 170.09 51.07C170.19 51.27 170.29 51.37 170.39 51.47L178.29 60.97C179.29 62.17 181.19 62.17 182.29 60.97Z"
        fill="#404040"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M180.59 64.37C180.59 67.3 176.89 67.3 176.89 70.14C176.89 72.98 180.59 73.07 180.59 75.91C180.59 78.75 176.89 78.84 176.89 81.68C176.89 84.52 180.59 84.61 180.59 87.45C180.59 90.29 176.89 90.38 176.89 93.22C176.89 96.06 180.59 96.15 180.59 98.99C180.59 101.83 176.89 101.92 176.89 104.76C176.89 107.6 180.59 107.69 180.59 110.53"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M223.9 71.12L234.28 58.64C234.41 58.51 234.54 58.25 234.67 58.11C240.19 47.73 232.96 34.99 221.27 34.99C209.58 34.99 202.35 47.73 207.87 58.11C208 58.37 208.13 58.5 208.26 58.64L218.64 71.12C219.95 72.7 222.45 72.7 223.89 71.12H223.9Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M224.59 49.22C224.57 48.68 224.42 48.15 224.14 47.67L221.67 43.41C221.48 43.09 221.35 42.74 221.28 42.38L221.18 42.56C220.57 43.61 220.57 44.91 221.18 45.96L223.16 49.37C223.77 50.42 223.77 51.72 223.16 52.78L223.06 52.96C222.99 52.6 222.86 52.26 222.67 51.93L219.71 46.82C219.52 46.5 219.39 46.15 219.32 45.79L219.22 45.97C218.61 47.02 218.61 48.31 219.22 49.36L221.2 52.78C221.81 53.83 221.81 55.13 221.2 56.19L221.1 56.37C221.03 56.01 220.9 55.67 220.71 55.34L218.24 51.08C217.91 50.51 217.76 49.87 217.78 49.23C215.14 49.92 213.32 51.38 213.32 53.07C213.32 55.42 216.85 57.33 221.2 57.33C225.55 57.33 229.08 55.42 229.08 53.07C229.08 51.38 227.26 49.92 224.61 49.23L224.59 49.22Z"
        fill="#F3123C"
      />
      <Path
        d="M224.59 49.22C224.57 48.68 224.42 48.15 224.14 47.67L221.67 43.41C221.48 43.09 221.35 42.74 221.28 42.38L221.18 42.56C220.57 43.61 220.57 44.91 221.18 45.96L223.16 49.37C223.77 50.42 223.77 51.72 223.16 52.78L223.06 52.96C222.99 52.6 222.86 52.26 222.67 51.93L219.71 46.82C219.52 46.5 219.39 46.15 219.32 45.79L219.22 45.97C218.61 47.02 218.61 48.31 219.22 49.36L221.2 52.78C221.81 53.83 221.81 55.13 221.2 56.19L221.1 56.37C221.03 56.01 220.9 55.67 220.71 55.34L218.24 51.08C217.91 50.51 217.76 49.87 217.78 49.23C215.14 49.92 213.32 51.38 213.32 53.07C213.32 55.42 216.85 57.33 221.2 57.33C225.55 57.33 229.08 55.42 229.08 53.07C229.08 51.38 227.26 49.92 224.61 49.23L224.59 49.22Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M222.37 76C222.37 79.9 218.67 79.9 218.67 83.7C218.67 87.5 222.37 87.6 222.37 91.4C222.37 95.2 218.67 95.3 218.67 99.1C218.67 102.9 222.37 103 222.37 106.8C222.37 110.6 218.67 110.7 218.67 114.5C218.67 118.3 222.37 118.4 222.37 122.2C222.37 126 218.67 126.1 218.67 129.9C218.67 133.7 222.37 133.8 222.37 137.6"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M86.4199 84.53L94.3199 75.03C94.4199 74.93 94.5199 74.73 94.6199 74.63C98.8199 66.73 93.3199 57.03 84.4199 57.03C75.5199 57.03 70.0199 66.73 74.2199 74.63C74.3199 74.83 74.4199 74.93 74.5199 75.03L82.4199 84.53C83.4199 85.83 85.3199 85.83 86.4199 84.53Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M84.7199 87.63C84.7199 88.99 82.6199 88.99 82.6199 90.29C82.6199 91.59 84.7199 91.65 84.7199 92.95C84.7199 94.25 82.6199 94.31 82.6199 95.61C82.6199 96.91 84.7199 96.97 84.7199 98.27C84.7199 99.57 82.6199 99.63 82.6199 100.93C82.6199 102.23 84.7199 102.29 84.7199 103.66C84.7199 105.03 82.6199 105.02 82.6199 106.39C82.6199 107.76 84.7199 107.75 84.7199 109.12"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M69.0702 127.78L65.5702 136.98C64.9702 138.48 66.0702 139.98 67.6702 139.98H70.5702C72.1702 139.98 73.1702 138.38 72.6702 136.98L69.0702 127.78Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M94.1702 216.58L99.6302 216.6H100.1L112.18 216.65"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M111.57 188.48V196.78C104.47 196.78 104.47 191.68 97.3701 191.68C90.2701 191.68 90.2701 196.78 83.1701 196.78C76.0701 196.78 76.0701 191.68 68.9701 191.68C61.8701 191.68 61.8701 196.78 54.7701 196.78C47.6701 196.78 47.6701 191.68 40.5701 191.68C33.4701 191.68 33.4701 196.78 26.3701 196.78V188.48C26.3701 183.48 30.3701 179.48 35.3701 179.48H36.2701L101.77 179.28H102.37C105.97 179.42 109.07 181.5 110.57 184.44C111.21 185.66 111.57 187.04 111.57 188.48Z"
        fill="#F3123C"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M111.57 196.88V214.38C111.57 215.58 110.57 216.58 109.37 216.58L100.1 216.6L28.8702 216.78C27.6702 216.78 26.6702 215.78 26.6702 214.58V197.08C33.7702 197.08 33.7702 191.98 40.8702 191.98C47.9702 191.98 47.9702 197.08 55.0702 197.08C62.1702 197.08 62.1702 191.98 69.2702 191.98C76.3702 191.98 76.3702 197.08 83.4702 197.08C90.5702 197.08 90.5702 191.98 97.6702 191.98C104.47 191.78 104.47 196.88 111.57 196.88Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M101.77 159.38V164.28C101.26 164.28 100.81 164.22 100.41 164.11C100.21 164.06 100.02 163.99 99.8402 163.92C99.5702 163.81 99.3202 163.67 99.1002 163.53C97.3502 162.39 96.5202 160.38 93.5702 160.38C89.4702 160.38 89.4702 164.28 85.3702 164.38C81.2702 164.48 81.2702 160.48 77.1702 160.48C73.0702 160.48 73.0702 164.38 68.9702 164.48C64.8702 164.48 64.8702 160.58 60.7702 160.58C56.6702 160.58 56.6702 164.48 52.5702 164.58C48.4702 164.58 48.4702 160.68 44.3702 160.68C40.2702 160.68 40.2702 164.51 36.2702 164.67C36.2402 164.68 36.2002 164.68 36.1702 164.68V159.78C36.1702 154.78 40.1702 150.78 45.1702 150.78L92.6702 150.68C97.6702 150.38 101.77 154.38 101.77 159.38Z"
        fill="#F3123C"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M101.77 164.28V179.28L36.27 179.48V164.67C38.14 164.59 39.15 163.71 40.1 162.79C40.17 162.74 40.23 162.68 40.29 162.61C41.31 161.64 42.34 160.68 44.37 160.68C48.47 160.68 48.47 164.58 52.57 164.58C56.67 164.48 56.67 160.58 60.77 160.58C64.87 160.58 64.87 164.48 68.97 164.48C73.07 164.38 73.07 160.48 77.17 160.48C81.27 160.48 81.27 164.48 85.37 164.38C89.47 164.28 89.47 160.38 93.57 160.38C96.52 160.38 97.35 162.39 99.1 163.53C99.32 163.67 99.57 163.81 99.84 163.92C100.02 164 100.21 164.06 100.41 164.11C100.81 164.22 101.26 164.28 101.77 164.28Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M44.37 160.68C40.3 160.68 40.27 164.51 36.27 164.67V164.48C40.37 164.48 40.37 160.58 44.47 160.48C48.57 160.48 48.57 164.38 52.67 164.38C56.77 164.38 56.77 160.48 60.87 160.38C64.97 160.28 64.97 164.28 69.07 164.28C73.17 164.28 73.17 160.38 77.27 160.28C81.37 160.28 81.37 164.18 85.47 164.18C89.57 164.18 89.57 160.28 93.67 160.18C96.54 160.32 97.35 162.37 99.1 163.53C97.35 162.39 96.52 160.38 93.57 160.38C89.47 160.38 89.47 164.28 85.37 164.38C81.27 164.48 81.27 160.48 77.17 160.48C73.07 160.48 73.07 164.38 68.97 164.48C64.87 164.48 64.87 160.58 60.77 160.58C56.67 160.58 56.67 164.48 52.57 164.58C48.47 164.58 48.47 160.68 44.37 160.68V160.68Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M101.77 179.28V179.58L36.27 179.78V179.48L101.77 179.28Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M68.9702 147.98V136.38"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_8498_161522">
        <Rect width={241.16} height={184.45} fill="white" transform="translate(5 33)" />
      </ClipPath>
    </Defs>
  </Svg>
)

export const Birthday = memo(BirthdayIllustration)
