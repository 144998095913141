import { createElement } from 'react'

import { registerRootComponent } from 'expo'
import { createRoot } from 'react-dom/client'
import { Platform } from 'react-native'

import { App } from './src/App'

if (Platform.OS === 'web') {
  const rootTag = createRoot(document.getElementById('root') ?? document.getElementById('main'))

  rootTag.render(createElement(App))
} else {
  registerRootComponent(App)
}
