import { FunctionComponent, Suspense, lazy } from 'react'

import { Skeleton } from 'native-base'

import { OpportunityInfosProps } from './OpportunityInfos.types'
import { useOpportunityInfos } from './useOpportunityInfos'

const OpportunityInfosDesktop = lazy(() => import('./OpportunityInfos.desktop'))

const OpportunityInfosMobile = lazy(() => import('./OpportunityInfos.mobile'))

export const OpportunityInfos: FunctionComponent<OpportunityInfosProps> = (props) => {
  const opportunityInfos = useOpportunityInfos(props)

  const Component = opportunityInfos.isMobile ? OpportunityInfosMobile : OpportunityInfosDesktop

  return (
    <Suspense fallback={<Skeleton minHeight="104px" mb={4} borderRadius="20px" />}>
      <Component {...opportunityInfos} />
    </Suspense>
  )
}
