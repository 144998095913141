import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { Note, useEditNoteMutation } from 'integration/resources/wallet'
import { useBreakpointValue, useDisclose, useToast } from 'native-base'
import { Toast } from 'organisms'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard } from 'react-native'
import fieldsValidation from 'src/constants/fields-validation'
import * as Yup from 'yup'

import { UseNoteEditDetailsScreen } from './NoteEditDetailsScreen.types'

const schema = Yup.object().shape({
  title: Yup.string()
    .required(fieldsValidation.common.required)
    .min(10, 'Título precisa ter no mínimo 10 caracteres')
    .max(60, 'Título pode ter no máximo 60 caracteres'),
  detail: Yup.string()
    .required(fieldsValidation.common.required)
    .max(400, 'Detalhe pode ter no máximo 400 caracteres'),
  type: Yup.string().required(fieldsValidation.common.required)
})

export const useNoteEditDetailsScreen: UseNoteEditDetailsScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { isOpen: isOpenMoreOptions, onToggle: onToggleMoreOptions } = useDisclose(false)

  const bottomSheetContext = useBottomSheetContext()

  const { note, contactId, tabNumber } = route.params

  const [openAddedNoteType, setOpenAddedNoteType] = useState(false)

  const { control, handleSubmit, setValue, formState, reset } = useForm<Note>({
    mode: 'all',
    resolver: yupResolver(schema)
  })

  const toast = useToast()

  const { isDirty } = formState

  const isDirtySelect = control._getDirty()

  const isDisabled = useMemo(() => !isDirtySelect ?? !isDirty, [isDirty, isDirtySelect])

  const { isLoading, mutate } = useEditNoteMutation(contactId ? 'contacts' : 'stores')

  const handleGoToWalletContact = useCallback(() => {
    if (contactId) navigation.navigate('WalletContact', { contactId })

    if (tabNumber) navigation.navigate('WalletTab', { tabNumber })
  }, [navigation, contactId, tabNumber])

  const handleEditNote = useCallback(() => {
    bottomSheetContext.open({
      description: 'Não foi possível editar a nota, tente novamente!',
      title: 'Erro ao editar nota'
    })
  }, [bottomSheetContext])

  const submit = handleSubmit(
    useCallback<SubmitHandler<Note>>(
      async (formData) => {
        Keyboard.dismiss()

        const formatData = {
          ...formData,
          tab_number: tabNumber,
          contact_id: contactId
        }

        mutate(
          {
            ...formatData
          },
          {
            onError: handleEditNote,
            onSuccess: () => {
              toast.show({
                render: () => <Toast type="success" text="Informações salvas com sucesso" />
              })

              handleGoToWalletContact()
            }
          }
        )
      },
      [tabNumber, contactId, mutate, handleEditNote, toast, handleGoToWalletContact]
    )
  )

  const handleSetOpenAddedNoteType = () => setOpenAddedNoteType(!openAddedNoteType)

  const handleAddedNoteType = (type: string) => {
    setValue('type', type)

    handleSetOpenAddedNoteType()
  }

  const handleGoBack = () => {
    navigation.goBack()
  }

  useEffect(() => {
    reset(note)

    if (typeof note === 'string') navigation.goBack()
  }, [navigation, note, reset])

  return {
    isMobile,
    isLoading,
    control,
    submit,
    handleGoBack,
    handleAddedNoteType,
    openAddedNoteType,
    handleSetOpenAddedNoteType,
    isDisabled,
    isOpenMoreOptions,
    onToggleMoreOptions
  }
}
