import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'

import { RecoveryCalculatorProps } from './RecoveryCalculator.types'
import { useRecoveryCalculator } from './useRecoveryCalculator'

const RecoveryCalculatorDesktop = lazy(() => import('./RecoveryCalculator.desktop'))

export const RecoveryCalculator: FunctionComponent<RecoveryCalculatorProps> = (props) => {
  const recoveryCalculator = useRecoveryCalculator(props)

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <RecoveryCalculatorDesktop {...recoveryCalculator} />
    </Suspense>
  )
}
