import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ResetPasswordReSentEmailScreenComponent } from './ResetPasswordReSentEmailScreen.types'
import { useResetPasswordReSentEmailScreen } from './useResetPasswordReSentEmailScreen'

const ResetPasswordReSentEmailScreenMobile = lazy(
  () => import('./ResetPasswordReSentEmailScreen.mobile')
)

const ResetPasswordReSentEmailScreenDesktop = lazy(
  () => import('./ResetPasswordReSentEmailScreen.desktop')
)

export const ResetPasswordReSentEmailScreen: ResetPasswordReSentEmailScreenComponent = ({
  navigation,
  route
}) => {
  const props = useResetPasswordReSentEmailScreen({ navigation, route })

  const Screen = props.isMobile
    ? ResetPasswordReSentEmailScreenMobile
    : ResetPasswordReSentEmailScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
