import { useCallback } from 'react'

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Cards } from 'atoms'
import { TNotification } from 'integration/resources/notifications'
import { Box, VStack } from 'native-base'
import { RootStackParamList } from 'navigation/types'
import { TouchableOpacity } from 'react-native'
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated'

export const NewsList = (news: TNotification[]) => {
  const newsData = Object.keys(news).map((key: string | any) => {
    Number(key)

    return news[key]
  })

  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const scrollX = useSharedValue(1)

  const scrollHandler = useAnimatedScrollHandler((event) => {
    scrollX.value = event.contentOffset.x
  })

  const renderItem = useCallback(
    ({ item }: { item: TNotification }) => {
      return (
        <TouchableOpacity
          onPress={() =>
            item.id
              ? navigation.navigate('NotificationDetail', { id: item.id })
              : navigation.navigate('Notification')
          }
          key={Math.random()}>
          <Box mr={4} ml={item.id === newsData[0].id ? 4 : 0}>
            <Cards.NewsCard {...item} key={item.title} />
          </Box>
        </TouchableOpacity>
      )
    },
    [navigation, newsData]
  )

  const keyExtractor = useCallback((item: TNotification) => item.id, [])

  return (
    <VStack>
      <Box pb={10} pt={6} position="relative">
        <Animated.FlatList
          data={newsData}
          keyExtractor={keyExtractor}
          showsHorizontalScrollIndicator={false}
          onScroll={scrollHandler}
          horizontal
          decelerationRate="normal"
          scrollEventThrottle={16}
          renderItem={renderItem}
        />
      </Box>
    </VStack>
  )
}
