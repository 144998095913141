import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { useBreakpointValue, useTheme } from 'native-base'
import { CustomTabBarNavigator } from 'organisms'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import {
  HomeScreen,
  ProfileScreen,
  WalletScreen,
  NotificationScreen,
  HomeContentScreen,
  OrganizationScreen,
  MoreModulesScreen,
  SurveyScreen
} from 'src/screens'

import { RootStackScreenComponent, TabParamList } from './types'

const Tab = createBottomTabNavigator<TabParamList>()

export const TABBAR_HEIGHT = 62

export const TabNavigator: RootStackScreenComponent<'Tab'> = () => {
  const insets = useSafeAreaInsets()

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const theme = useTheme()

  return (
    <>
      <Tab.Navigator
        screenOptions={{
          headerShown: false,
          tabBarStyle: {
            height: TABBAR_HEIGHT + insets.bottom
          }
        }}
        sceneContainerStyle={{ backgroundColor: theme.colors.white }}
        tabBar={(props) => (isDesktop ? <></> : <CustomTabBarNavigator {...props} />)}>
        {/* TO DO: CHANGE THE COMPONENT WITH THE SCREEN */}
        <Tab.Screen component={HomeScreen} name="Home" />
        <Tab.Screen component={OrganizationScreen} name="Organization" />
        <Tab.Screen component={NotificationScreen} name="Notification" />
        <Tab.Screen component={MoreModulesScreen} name="MoreModules" />
        <Tab.Screen component={HomeContentScreen} name="HomeContent" />
        <Tab.Screen component={ProfileScreen} name="Profile" />
        <Tab.Screen component={WalletScreen} name="Wallet" />
        <Tab.Screen component={SurveyScreen} name="Survey" />
      </Tab.Navigator>
    </>
  )
}
