import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'

type TStorage = {
  getItem: (key: string) => Promise<string | null>
  removeItem: (key: string) => Promise<void>
  setItem: (key: string, newValue: string) => Promise<void>
}

export const Storage = Platform.select<TStorage>({
  native: {
    getItem: (key) => {
      const item = AsyncStorage.getItem(key)

      return item
    },
    removeItem: (key) => AsyncStorage.removeItem(key),
    setItem: (key, value) => AsyncStorage.setItem(key, value)
  },
  web: {
    getItem: (key) => AsyncStorage.getItem(key),
    removeItem: (key) => AsyncStorage.removeItem(key),
    setItem: (key, value) => AsyncStorage.setItem(key, JSON.stringify(value))
  }
}) as TStorage
