import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const TrashGhostIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 20 20', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833008 5.09155C0.833008 4.63132 1.2061 4.25822 1.66634 4.25822H18.6993C19.1596 4.25822 19.5327 4.63132 19.5327 5.09155C19.5327 5.55179 19.1596 5.92489 18.6993 5.92489H1.66634C1.2061 5.92489 0.833008 5.55179 0.833008 5.09155Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.96202 5.09155C2.96202 4.63132 3.33511 4.25822 3.79535 4.25822H16.5701C17.0303 4.25822 17.4034 4.63132 17.4034 5.09155V17.8663C17.4034 18.4638 17.1661 19.0368 16.7436 19.4592C16.3211 19.8817 15.7481 20.119 15.1507 20.119H5.21477C4.6173 20.119 4.0443 19.8817 3.62183 19.4592C3.19936 19.0368 2.96202 18.4638 2.96202 17.8663V5.09155ZM4.62868 5.92489V17.8663C4.62868 18.0217 4.69043 18.1708 4.80034 18.2807C4.91026 18.3906 5.05933 18.4524 5.21477 18.4524H15.1507C15.3061 18.4524 15.4552 18.3906 15.5651 18.2807C15.675 18.1708 15.7368 18.0217 15.7368 17.8663V5.92489H4.62868Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1828 1.66667C9.46272 1.66667 8.7721 1.95273 8.2629 2.46193C7.7537 2.97113 7.46763 3.66176 7.46763 4.38187V5.09158C7.46763 5.55182 7.09453 5.92491 6.6343 5.92491C6.17406 5.92491 5.80096 5.55182 5.80096 5.09158V4.38187C5.80096 3.21973 6.26262 2.10518 7.08438 1.28342C7.90615 0.46166 9.02069 0 10.1828 0C11.345 0 12.4595 0.46166 13.2813 1.28342C14.1031 2.10518 14.5647 3.21973 14.5647 4.38187V5.09158C14.5647 5.55182 14.1916 5.92491 13.7314 5.92491C13.2711 5.92491 12.898 5.55182 12.898 5.09158V4.38187C12.898 3.66176 12.612 2.97113 12.1028 2.46193C11.5936 1.95273 10.903 1.66667 10.1828 1.66667Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.05377 7.09707C8.51401 7.09707 8.88711 7.47016 8.88711 7.9304V15.7372C8.88711 16.1974 8.51401 16.5705 8.05377 16.5705C7.59353 16.5705 7.22044 16.1974 7.22044 15.7372V7.9304C7.22044 7.47016 7.59353 7.09707 8.05377 7.09707Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3118 7.09707C12.772 7.09707 13.1451 7.47016 13.1451 7.9304V15.7372C13.1451 16.1974 12.772 16.5705 12.3118 16.5705C11.8516 16.5705 11.4785 16.1974 11.4785 15.7372V7.9304C11.4785 7.47016 11.8516 7.09707 12.3118 7.09707Z"
      fill="currentColor"
    />
  </Icon>
)
