import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const ProcessIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 21 20', ...props }}>
    <G clipPath="url(#clip0_3568_5074)">
      <Path
        d="M16.0001 1.33702e-06H3.00012C2.80306 -0.000261932 2.60789 0.038357 2.42578 0.113646C2.24367 0.188935 2.07821 0.299414 1.93887 0.438754C1.79953 0.578094 1.68905 0.743558 1.61376 0.925665C1.53847 1.10777 1.49985 1.30294 1.50012 1.5V14.278C1.4947 14.933 1.67598 15.576 2.02274 16.1318C2.3695 16.6875 2.86738 17.133 3.45812 17.416L7.58012 19.698C8.07414 19.9173 8.61223 20.0192 9.15223 19.9957C9.69222 19.9722 10.2194 19.824 10.6926 19.5626C11.1657 19.3013 11.5718 18.9339 11.8792 18.4893C12.1865 18.0447 12.3868 17.5349 12.4641 17H16.0001C16.9279 16.9984 17.8172 16.6292 18.4732 15.9731C19.1293 15.3171 19.4985 14.4278 19.5001 13.5V3.5C19.4991 2.57207 19.13 1.68245 18.4738 1.0263C17.8177 0.370149 16.9281 0.00106002 16.0001 1.33702e-06ZM16.5001 13.5C16.5001 13.6326 16.4474 13.7598 16.3537 13.8536C16.2599 13.9473 16.1327 14 16.0001 14H12.5001V6.8C12.5041 6.13661 12.3178 5.48598 11.9633 4.92521C11.6089 4.36444 11.1011 3.91703 10.5001 3.636L9.24612 3H16.0001C16.1327 3 16.2599 3.05268 16.3537 3.14645C16.4474 3.24022 16.5001 3.36739 16.5001 3.5V13.5Z"
        fill="#FEECEF"
      />
    </G>
    <Path
      d="M9.16536 19.6667C8.76536 19.6667 8.3737 19.5833 8.01536 19.425L3.01536 17.2C2.51536 16.975 2.09036 16.6167 1.79036 16.1583C1.49036 15.7 1.33203 15.1667 1.33203 14.6167V0.833333C1.33203 0.55 1.4737 0.291667 1.71536 0.133333C1.9487 -0.0166667 2.2487 -0.0416667 2.50703 0.075L10.3154 3.54167C10.8154 3.76667 11.2404 4.125 11.5404 4.58333C11.8404 5.04167 11.9987 5.575 11.9987 6.125V16.825C11.9987 17.2917 11.882 17.7667 11.657 18.175C11.432 18.5917 11.107 18.9417 10.707 19.2C10.3154 19.4583 9.85703 19.6083 9.39036 19.65C9.31536 19.65 9.24036 19.65 9.16536 19.65V19.6667ZM2.9987 2.11667V14.6167C2.9987 14.8417 3.06536 15.0583 3.19036 15.25C3.31536 15.4417 3.49036 15.5917 3.6987 15.6833L8.6987 17.9083C8.8737 17.9833 9.0737 18.0167 9.26536 18.0083C9.45703 17.9917 9.6487 17.925 9.80703 17.825C9.9737 17.7167 10.107 17.575 10.1987 17.4C10.2904 17.225 10.3404 17.0333 10.3404 16.8417V6.13333C10.3404 5.90833 10.2737 5.68333 10.1487 5.5C10.0237 5.30833 9.8487 5.15833 9.6487 5.06667L2.9987 2.11667Z"
      fill="#F3123C"
    />
    <Path
      d="M16.1654 16.6667H11.1654C10.707 16.6667 10.332 16.2917 10.332 15.8333C10.332 15.375 10.707 15 11.1654 15H16.1654C16.4737 15 16.7737 14.875 16.9904 14.6583C17.207 14.4417 17.332 14.1417 17.332 13.8333V2.83333C17.332 2.525 17.207 2.225 16.9904 2.00833C16.7737 1.79167 16.4737 1.66667 16.1654 1.66667H2.16536C1.70703 1.66667 1.33203 1.29167 1.33203 0.833333C1.33203 0.375 1.70703 0 2.16536 0H16.1654C16.9237 0 17.632 0.291667 18.1654 0.833333C18.6987 1.36667 18.9987 2.08333 18.9987 2.83333V13.8333C18.9987 14.5917 18.707 15.3 18.1654 15.8333C17.6237 16.3667 16.9154 16.6667 16.1654 16.6667Z"
      fill="#F3123C"
    />
    <Defs>
      <ClipPath id="clip0_3568_5074">
        <Rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </ClipPath>
    </Defs>
  </Icon>
)
