import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'
import { useBreakpointValue } from 'native-base'

import { ActivitiesRelatedByStoreProps } from './ActivitiesRelatedByStore.types'

const ActivitiesRelatedByStoreMobile = lazy(() => import('./ActivitiesRelatedByStore.mobile'))

const ActivitiesRelatedByStoreDesktop = lazy(() => import('./ActivitiesRelatedByStore.desktop'))

export const ActivitiesRelatedByStore: FunctionComponent<ActivitiesRelatedByStoreProps> = (
  props
) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const Component = isMobile ? ActivitiesRelatedByStoreMobile : ActivitiesRelatedByStoreDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Component {...props} />
    </Suspense>
  )
}
