import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { G, Defs, Path, ClipPath } from 'react-native-svg'

export const ClockIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <G fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <Path d="M2.592 2.81a8.85 8.85 0 1 1 12.516 12.517A8.85 8.85 0 0 1 2.592 2.81ZM8.85 1.72a7.35 7.35 0 1 0 0 14.7 7.35 7.35 0 0 0 0-14.7Z" />
      <Path d="M8.85 5.203a.75.75 0 0 1 .75.75V8.79l2.985 3.483a.75.75 0 1 1-1.138.976L8.28 9.557a.75.75 0 0 1-.18-.488V5.953a.75.75 0 0 1 .75-.75Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(0 .219)" d="M0 0h18v18H0z" />
      </ClipPath>
    </Defs>
  </Icon>
)
