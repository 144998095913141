import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ContentIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Path
      key={Math.random()}
      d="M17.482 1.733c-.15-.33-.36-.615-.622-.855-.27-.24-.578-.42-.923-.533A2.53 2.53 0 0 0 14.88.233c-2.985.315-5.018 1.08-6.03 2.242C7.837 1.313 5.805.548 2.82.233a2.53 2.53 0 0 0-1.058.112A2.584 2.584 0 0 0 .84.878c-.27.24-.48.532-.623.855C.075 2.063 0 2.408 0 2.768v9.082c0 .638.232 1.253.66 1.725.427.473 1.02.765 1.657.825 1.358.12 5.783.683 5.783 2.768 0 .412.337.75.75.75.412 0 .75-.338.75-.75 0-2.085 4.425-2.648 5.782-2.768a2.573 2.573 0 0 0 1.658-.825 2.496 2.496 0 0 0 .66-1.725V2.768c0-.36-.075-.705-.218-1.035ZM8.1 14.543c-1.208-.848-3.113-1.41-5.648-1.635a1.047 1.047 0 0 1-.682-.338 1.009 1.009 0 0 1-.27-.712v-9.09c0-.143.03-.293.09-.428s.15-.255.255-.352c.112-.098.24-.173.375-.218a.938.938 0 0 1 .322-.052h.113c1.635.172 5.437.787 5.437 2.73v10.087l.008.008Zm8.1-2.685c0 .262-.098.517-.27.712-.18.195-.42.315-.683.338-2.535.232-4.44.787-5.647 1.635V4.455c0-1.942 3.802-2.557 5.437-2.73h.113c.112 0 .217.015.322.053.143.045.27.12.375.217a.99.99 0 0 1 .255.353c.06.135.09.285.09.427v9.083h.008Z"
      fill="currentColor"
    />
  </Icon>
)
