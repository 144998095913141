import { useCallback } from 'react'

import { useBreakpointValue } from 'native-base'

import { UsePageErrorOccurredScreen } from './PageErrorOccurredScreen.types'

export const usePageErrorOccurredScreen: UsePageErrorOccurredScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const goToTryAgain = useCallback(() => navigation.goBack(), [navigation])

  /**
   * @TODO change navigation navigate to useLinkTo
   * define custom names cause this name used in url in version web
   */

  return { isMobile, goToTryAgain, route }
}
