import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { GoalsLeaderDetailsScreenComponent } from './GoalsLeaderDetailsScreen.types'
import { useGoalsLeaderDetailsScreen } from './useGoalsLeaderDetailsScreen'

const GoalsLeaderDetailsScreenDesktop = lazy(() => import('./GoalsLeaderDetailsScreen.desktop'))

export const GoalsLeaderDetailsScreen: GoalsLeaderDetailsScreenComponent = ({
  navigation,
  route
}) => {
  const props = useGoalsLeaderDetailsScreen({ navigation, route })

  const Screen = GoalsLeaderDetailsScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
