import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { AccountConfirmEmailScreenComponent } from './AccountConfirmEmailScreen.types'
import { useAccountConfirmEmailScreen } from './useAccountConfirmEmailScreen'

const AccountConfirmEmailScreenDesktop = lazy(() => import('./AccountConfirmEmailScreen.desktop'))

const AccountConfirmEmailScreenMobile = lazy(() => import('./AccountConfirmEmailScreen.mobile'))

export const AccountConfirmEmailScreen: AccountConfirmEmailScreenComponent = ({
  navigation,
  route
}) => {
  const props = useAccountConfirmEmailScreen({ navigation, route })

  const Screen = props.isMobile ? AccountConfirmEmailScreenMobile : AccountConfirmEmailScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
