import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Icons } from 'atoms'
import { HeadPage } from 'atoms/web/HeadPage'
import { HStack, VStack, View, Button } from 'native-base'
import Layouts from 'organisms/Layouts'
import { FlatList, RefreshControl } from 'react-native'
import useDebounce from 'src/hooks/useDebounce'

import { ActiveBase } from './components/ListNewOpportunities/ActiveBase'
import { Charge } from './components/ListNewOpportunities/Charge'
import { Concluded } from './components/ListNewOpportunities/Concluded'
import { Highlighted } from './components/ListNewOpportunities/Highlighted'
import { Insurance } from './components/ListNewOpportunities/Insurance'
import { ODC } from './components/ListNewOpportunities/ODC'
import { Pending } from './components/ListNewOpportunities/Pending'
import {
  ListTypesRef,
  ListTypesView,
  OrganizationScreenOpportunityComponent
} from './OrganizationScreen.types'
import { useOrganizationScreen } from './useOrganizationScreen'

const OpportunitiesViews: any = {
  13: Pending,
  10: Concluded,
  9: Highlighted,
  8: Charge,
  7: Insurance,
  6: ActiveBase,
  5: ODC
}

const monthNames = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Maio',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez'
]

const OrganizationScreenOpportunityDesktop: OrganizationScreenOpportunityComponent = ({
  navigation,
  route
}) => {
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search, 500)

  const {
    handleToggleActivitiesTypeView,
    currentSelectedDate,
    isLoading,
    ascOrDesc,
    viewTypes,
    viewType
    //@ts-ignore
  } = useOrganizationScreen({ navigation, route })

  const currentViewRef = useRef<ListTypesRef>(null)

  const viewTypesDesktop = viewTypes

  const selectedOpportunityView = viewType.group

  const CurrentOpportunityView = OpportunitiesViews[selectedOpportunityView]

  const CurrentView = CurrentOpportunityView

  const data = [
    {
      component: CurrentView,
      key: '1'
    }
  ]

  const keyExtractor = useCallback(
    (item: { component: React.ElementType; key: string }) => item.key,
    []
  )

  const renderItem = useCallback(
    (
      { item }: { item: { component: React.FunctionComponent<ListTypesView>; key: string } },
      index: number
    ) => {
      const Component = item.component as
        | React.FunctionComponent<ListTypesView>
        | React.ForwardRefExoticComponent<ListTypesView & React.RefAttributes<ListTypesRef>>

      return (
        <View
          bg="background.base"
          maxW={1122}
          flex={1}
          minH={600}
          pb={30}
          key={`${index}-${item.key}`}>
          <Component
            ref={currentViewRef}
            isLoading={isLoading}
            ascOrDesc={ascOrDesc}
            //@ts-ignore
            route={route}
            //@ts-ignore
            navigation={navigation}
            handleSelect={handleToggleActivitiesTypeView}
            data={{
              date: currentSelectedDate
            }}
          />
        </View>
      )
    },
    [ascOrDesc, currentSelectedDate, handleToggleActivitiesTypeView, isLoading, navigation, route]
  )

  useEffect(() => {
    if (currentViewRef.current) {
      currentViewRef.current.search(debouncedSearch)
    }
  }, [debouncedSearch, currentViewRef])

  return (
    <Layouts.InternalDesktop onSearch={setSearch} search={search}>
      <View h="full" flex={1} px={8}>
        <FlatList
          data={data}
          keyExtractor={keyExtractor}
          ListHeaderComponent={
            <View>
              <HeadPage
                title="Oportunidades"
                badge={monthNames[new Date().getMonth()]}
                pages={[
                  {
                    id: 1,
                    title: 'Minha organização',
                    link: 'minha-organizacao/oportunidades/5',
                    params: null
                  },
                  {
                    id: 2,
                    title: 'Oportunidades',
                    link: 'minha-organizacao/oportunidades/5',
                    params: null
                  }
                ]}
              />

              <VStack bg="background.base" borderTopRadius={32} mt={8} pb={2}>
                <>
                  <HStack bgColor="background.base" justifyContent="space-between" mt={2}>
                    <View
                      flexDir="row"
                      alignItems="center"
                      flexWrap="wrap"
                      //  @ts-ignore
                      style={{ gap: '8px' }}>
                      {viewTypesDesktop.map((vision) => {
                        const IconComponent = Icons[vision.icon] as typeof Icons.Add

                        return (
                          <Button
                            key={vision.title}
                            //@ts-ignore
                            onPress={() => handleToggleActivitiesTypeView(vision)}
                            colorScheme={
                              viewType.title === vision.title ? 'primary-light' : 'white'
                            }
                            borderRadius="full"
                            px={6}
                            size="sm"
                            _text={{
                              fontSize: '16px'
                            }}
                            h={10}
                            rightIcon={
                              <IconComponent
                                color={viewType.title === vision.title ? 'primary.500' : 'gray.700'}
                                size="18px"
                              />
                            }>
                            {vision.title}
                          </Button>
                        )
                      })}
                    </View>
                  </HStack>
                </>
              </VStack>
            </View>
          }
          showsHorizontalScrollIndicator={false}
          decelerationRate="normal"
          scrollEventThrottle={16}
          onEndReached={() => {
            if (currentViewRef.current) {
              currentViewRef.current?.fetchMore?.()
            }
          }}
          flexShrink={1}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={currentViewRef.current?.refreshing ?? false}
              onRefresh={() => {
                currentViewRef.current?.refetch?.()
              }}
              tintColor="white"
            />
          }
          // @ts-ignore
          renderItem={renderItem}
        />
      </View>
    </Layouts.InternalDesktop>
  )
}

export default OrganizationScreenOpportunityDesktop
