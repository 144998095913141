import { FunctionComponent, useCallback, useEffect } from 'react'

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Icons } from 'atoms'
import { Pressable } from 'native-base'
import { RootStackParamList } from 'navigation/types'
import { Keyboard, Platform } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue } from 'react-native-reanimated'
import { ZendeskAPI } from 'react-zendesk'

type TButtonZendesk = {
  direction?: 'left' | 'right'
  isDesktop?: boolean
}

const AnimatedPressable = Animated.createAnimatedComponent(Pressable)

export const ButtonZendesk: FunctionComponent<TButtonZendesk> = ({
  direction,
  isDesktop = false
}) => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const showButtonAnimated = useSharedValue(1)

  const animatedOpacity = useAnimatedStyle(() => {
    return {
      opacity: showButtonAnimated.value
    }
  })

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      showButtonAnimated.value = 0
    })

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      showButtonAnimated.value = 1
    })

    return () => {
      keyboardDidShowListener.remove()

      keyboardDidHideListener.remove()
    }
  }, [showButtonAnimated])

  const handleZendeskButtonClick = () => {
    ZendeskAPI('webWidget', 'open')

    ZendeskAPI('webWidget', 'setLocale', 'pt-BR')

    ZendeskAPI('webWidget', 'helpCenter:setSuggestions', { search: 'login' })
  }

  const handleGoToChat = useCallback(() => navigation.navigate('Chat'), [navigation])

  return isDesktop ? (
    <AnimatedPressable style={animatedOpacity} onPress={handleZendeskButtonClick}>
      <Icons.Zendesk size={10} />
    </AnimatedPressable>
  ) : (
    <AnimatedPressable
      style={animatedOpacity}
      position="absolute"
      bottom="22px"
      left={direction === 'right' ? undefined : 4}
      right={direction === 'right' ? 4 : undefined}
      onPress={Platform.OS === 'web' ? handleZendeskButtonClick : handleGoToChat}>
      <Icons.Zendesk size={10} />
    </AnimatedPressable>
  )
}
