import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const SearchIcon: FunctionComponent<IIconProps & { filled?: boolean; color?: string }> = ({
  filled,
  color = '#404040',
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 19 18', ...props }}>
    <G clipPath="url(#clip0_3848_28466)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.42283 2.1967C3.82936 0.790176 5.73701 0 7.72614 0C9.71526 0 11.6229 0.790176 13.0294 2.1967C14.436 3.60322 15.2261 5.51088 15.2261 7.5C15.2261 9.48912 14.436 11.3968 13.0294 12.8033C11.6229 14.2098 9.71526 15 7.72614 15C5.73701 15 3.82936 14.2098 2.42283 12.8033C1.01631 11.3968 0.226135 9.48912 0.226135 7.5C0.226135 5.51088 1.01631 3.60322 2.42283 2.1967ZM7.72614 1.5C6.13484 1.5 4.60871 2.13214 3.48349 3.25736C2.35828 4.38258 1.72614 5.9087 1.72614 7.5C1.72614 9.0913 2.35828 10.6174 3.48349 11.7426C4.60871 12.8679 6.13484 13.5 7.72614 13.5C9.31743 13.5 10.8436 12.8679 11.9688 11.7426C13.094 10.6174 13.7261 9.0913 13.7261 7.5C13.7261 5.9087 13.094 4.38258 11.9688 3.25736C10.8436 2.13214 9.31743 1.5 7.72614 1.5Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9694 11.7433C12.2623 11.4504 12.7372 11.4504 13.0301 11.7433L17.7065 16.4197C17.9994 16.7126 17.9994 17.1874 17.7065 17.4803C17.4136 17.7732 16.9387 17.7732 16.6458 17.4803L11.9694 12.8039C11.6765 12.511 11.6765 12.0362 11.9694 11.7433Z"
        fill={color}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_3848_28466">
        <Rect width="18" height="18" fill="white" transform="translate(0.226318)" />
      </ClipPath>
    </Defs>
  </Icon>
)
