import {
  CPFScreen,
  CreateAccountPhoneEmailScreen,
  CreateAccountCreatePasswordScreen,
  CreateAccountAuthCodeScreen,
  CreateAccountSendEmailConfirmationScreen,
  CreateAccountChangeEmailScreen,
  CreateAccountConfirmEmailScreen,
  EntryScreen,
  SSOAuthScreen,
  LoginScreen,
  LoginBlockedScreen,
  LoginAccountReviewScreen,
  LoginAuthCodeScreen,
  ResetPasswordEmailSentSuccessfullyScreen,
  ResetPasswordSendEmailScreen,
  ResetPasswordCreatePasswordScreen,
  ResetPasswordAuthCodeScreen,
  ResetPasswordSuccessfullyScreen,
  ResetPasswordReSentEmailScreen,
  FirstLoginPasswordScreen,
  FirstLoginSuccessScreen,
  FirstLoginAuthCodeScreen,
  AboutScreen,
  TermsScreen,
  ChatScreen,
  PageNotFoundScreen,
  PageNoConnectionScreen,
  PageErrorOccurredScreen
} from 'src/screens'

import { RootStackScreenComponent } from '.'
import { RootStackParamList } from './types'

export const noLoggedScreens: Partial<{
  [K in keyof RootStackParamList]: RootStackScreenComponent<K>
}> = {
  Entry: EntryScreen,
  CreateAccountCPF: CPFScreen,
  CreateAccountPhoneEmail: CreateAccountPhoneEmailScreen,
  CreateAccountCreatePassword: CreateAccountCreatePasswordScreen,
  CreateAccountAuthCode: CreateAccountAuthCodeScreen,
  CreateAccountSendEmailConfirmation: CreateAccountSendEmailConfirmationScreen,
  CreateAccountChangeEmail: CreateAccountChangeEmailScreen,
  CreateAccountConfirmEmail: CreateAccountConfirmEmailScreen,
  ErrorPageNotFound: PageNotFoundScreen,
  ErrorPageNoConnection: PageNoConnectionScreen,
  ErrorPageErrorOccurred: PageErrorOccurredScreen,
  Login: LoginScreen,
  LoginBlocked: LoginBlockedScreen,
  LoginAccountReview: LoginAccountReviewScreen,
  LoginAuthCode: LoginAuthCodeScreen,
  ResetPasswordEmailSentSuccessfully: ResetPasswordEmailSentSuccessfullyScreen,
  ResetPasswordSendEmail: ResetPasswordSendEmailScreen,
  ResetPasswordCreatePassword: ResetPasswordCreatePasswordScreen,
  ResetPasswordAuthCode: ResetPasswordAuthCodeScreen,
  ResetPasswordSuccessfully: ResetPasswordSuccessfullyScreen,
  ResetPasswordReSentEmail: ResetPasswordReSentEmailScreen,
  FirstLoginPassword: FirstLoginPasswordScreen,
  FirstLoginSuccess: FirstLoginSuccessScreen,
  FirstLoginAuthCode: FirstLoginAuthCodeScreen,
  About: AboutScreen,
  Terms: TermsScreen,
  Chat: ChatScreen,
  SSOAuth: SSOAuthScreen
}
