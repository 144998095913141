import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const WalletIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.6 5.62v-.151a5.25 5.25 0 1 1 10.5 0v.15h1.95c.911 0 1.65.74 1.65 1.65v9a1.65 1.65 0 0 1-1.65 1.65H1.65A1.65 1.65 0 0 1 0 16.27v-9c0-.91.739-1.65 1.65-1.65H3.6Zm2.598-2.803A3.75 3.75 0 0 1 12.6 5.47v.15H5.1v-.15a3.75 3.75 0 0 1 1.098-2.652ZM16.2 7.27v2.85H1.5V7.27a.15.15 0 0 1 .15-.15h14.4a.15.15 0 0 1 .15.15Zm-8.1 4.35v1.95a.75.75 0 0 0 1.5 0v-1.95h6.6v4.65a.15.15 0 0 1-.15.15H1.65a.15.15 0 0 1-.15-.15v-4.65h6.6Z"
      fill="currentColor"
    />
  </Icon>
)
