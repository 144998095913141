import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const CashIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <G clipPath="url(#clip0_6853_117061)">
      <Path
        d="M9.59981 3.59999V4.5H10.6499C11.0641 4.5 11.3999 4.83579 11.3999 5.25C11.3999 5.66421 11.0641 6 10.6499 6H8.84989C8.57141 6 8.30434 6.11062 8.10743 6.30754C7.91052 6.50445 7.79989 6.77152 7.79989 7.05C7.79989 7.32848 7.91052 7.59555 8.10743 7.79246C8.30434 7.98938 8.57141 8.1 8.84989 8.1C9.52619 8.1 10.1748 8.36866 10.653 8.84688C11.1312 9.3251 11.3999 9.9737 11.3999 10.65C11.3999 11.3263 11.1312 11.9749 10.653 12.4531C10.3562 12.7499 9.99377 12.966 9.59981 13.0872V13.35C9.59981 13.7642 9.26403 14.1 8.84982 14.1C8.4356 14.1 8.09982 13.7642 8.09982 13.35V13.2H7.0499C6.63568 13.2 6.2999 12.8642 6.2999 12.45C6.2999 12.0358 6.63568 11.7 7.0499 11.7H8.84989C9.12836 11.7 9.39544 11.5894 9.59235 11.3925C9.78926 11.1955 9.89989 10.9285 9.89989 10.65C9.89989 10.3715 9.78926 10.1045 9.59235 9.90754C9.39544 9.71063 9.12836 9.6 8.84989 9.6C8.17359 9.6 7.52499 9.33134 7.04677 8.85312C6.56856 8.37491 6.2999 7.7263 6.2999 7.05C6.2999 6.3737 6.56856 5.7251 7.04677 5.24688C7.34354 4.95011 7.70592 4.73405 8.09982 4.61281V3.59999C8.09982 3.18578 8.4356 2.84999 8.84982 2.84999C9.26403 2.84999 9.59981 3.18578 9.59981 3.59999Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.84996 0C6.50281 0 4.25178 0.932408 2.59209 2.59211C0.932404 4.2518 0 6.50283 0 8.85C0 11.1972 0.932404 13.4482 2.59209 15.1079C4.25178 16.7676 6.50281 17.7 8.84996 17.7C11.1971 17.7 13.4481 16.7676 15.1078 15.1079C16.7675 13.4482 17.6999 11.1972 17.6999 8.85C17.6999 6.50283 16.7675 4.2518 15.1078 2.59211C13.4481 0.932408 11.1971 0 8.84996 0ZM3.65275 3.65277C5.03114 2.27437 6.90063 1.5 8.84996 1.5C10.7993 1.5 12.6688 2.27437 14.0472 3.65277C15.4256 5.03116 16.1999 6.90066 16.1999 8.85C16.1999 10.7993 15.4256 12.6688 14.0472 14.0472C12.6688 15.4256 10.7993 16.2 8.84996 16.2C6.90063 16.2 5.03114 15.4256 3.65275 14.0472C2.27436 12.6688 1.49999 10.7993 1.49999 8.85C1.49999 6.90066 2.27436 5.03116 3.65275 3.65277Z"
        fill="currentColor"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_6853_117061">
        <Rect width="17.9999" height="18" fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)
