import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import * as requests from './requests'
import { TIndicatorResume } from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_GET_INDICATOR_RESUME_QUERY = 'getIndicatorResume'

export const useGetIndicatorResumeQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<TIndicatorResume>>, AxiosError<ResponseError>>(
    [KEY_GET_INDICATOR_RESUME_QUERY],
    () => requests.getIndicatorResume()
  )
