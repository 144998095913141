import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { TermsScreenComponent } from './TermsScreen.types'
import { useTermsScreen } from './useTermsScreen'

const TermsScreenDesktop = lazy(() => import('./TermsScreen.desktop'))

const TermsScreenMobile = lazy(() => import('./TermsScreen.mobile'))

export const TermsScreen: TermsScreenComponent = ({ navigation }) => {
  const termsScreen = useTermsScreen({ navigation })

  const Screen = termsScreen.isMobile ? TermsScreenMobile : TermsScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...termsScreen} />
    </Suspense>
  )
}
