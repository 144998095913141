import { memo } from 'react'

import Svg, { Path } from 'react-native-svg'

const VirusThreatFoundIllustration = () => (
  <Svg width={200} height={200} viewBox="0 0 200 200" fill="none">
    <Path
      fill="#F3123C"
      d="M187.346 168.329a11.616 11.616 0 0 0-1.426-15.989l.294-5.988-4.243 3.7a11.646 11.646 0 0 0-14.097 5.706 11.649 11.649 0 0 0-1.285 5.32c0 .129.516-.115-6.436 2.592l6.94.8a11.643 11.643 0 0 0 16.857 6.758l11.05 7.521-7.654-10.42Z"
    />
    <Path
      stroke="#595959"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M187.346 168.329a11.616 11.616 0 0 0-1.426-15.989l.294-5.988-4.243 3.7a11.646 11.646 0 0 0-14.097 5.706 11.649 11.649 0 0 0-1.285 5.32c0 .129.516-.115-6.436 2.592l6.94.8a11.643 11.643 0 0 0 16.857 6.758l11.05 7.521-7.654-10.42Z"
    />
    <Path
      fill="#F3123C"
      d="m43.118 46.75-10.145 4.055a10.33 10.33 0 0 0-9.929-2.157l-4.594-4.25.14 7.03a10.3 10.3 0 0 0-2.61 9.32L5 65.411l12.717-.79a10.354 10.354 0 0 0 18.207-9.368l7.194-8.503Z"
    />
    <Path
      stroke="#595959"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m43.118 46.75-10.145 4.055a10.33 10.33 0 0 0-9.929-2.157l-4.594-4.25.14 7.03a10.3 10.3 0 0 0-2.61 9.32L5 65.411l12.717-.79a10.354 10.354 0 0 0 18.207-9.368l7.194-8.503ZM9.494 162.547h10.338M14.663 167.713v-10.338M184.495 51.195l3.728 3.728M184.495 54.923l3.728-3.728M156.225 26.766h5.639M159.044 29.584v-5.639"
    />
    <Path
      fill="#F5F5F5"
      d="M171.05 74.73v65.48c0 6.2-5.02 11.23-11.22 11.23h-44.68v18.04c5.37 0 10.72.61 15.95 1.82v2.99H63.45v-2.99a70.754 70.754 0 0 1 15.95-1.82v-18.04H34.73c-6.2 0-11.23-5.03-11.23-11.23V74.73c0-6.2 5.03-11.23 11.23-11.23h125.1c6.2 0 11.22 5.03 11.22 11.23Z"
    />
    <Path
      stroke="#595959"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M115.708 151.398H79.965v18.041h35.743v-18.041Z"
    />
    <Path
      fill="#F3123C"
      d="M64.01 171.257v2.991h67.653v-2.991a70.81 70.81 0 0 0-15.955-1.819H79.965c-5.37 0-10.723.61-15.955 1.819Z"
    />
    <Path
      stroke="#595959"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M64.01 171.257v2.991h67.653v-2.991a70.81 70.81 0 0 0-15.955-1.819H79.965c-5.37 0-10.723.61-15.955 1.819Z"
    />
    <Path
      fill="#F3123C"
      d="M164.149 63.46h-3.761a11.224 11.224 0 0 1 11.226 11.227v65.482a11.218 11.218 0 0 1-3.286 7.942 11.234 11.234 0 0 1-7.94 3.29h3.761a11.224 11.224 0 0 0 10.372-6.931c.565-1.362.855-2.822.855-4.296V74.687a11.231 11.231 0 0 0-3.288-7.939 11.22 11.22 0 0 0-7.939-3.287Z"
    />
    <Path
      stroke="#595959"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M164.149 63.46h-3.761a11.224 11.224 0 0 1 11.226 11.227v65.482a11.218 11.218 0 0 1-3.286 7.942 11.234 11.234 0 0 1-7.94 3.29h3.761a11.224 11.224 0 0 0 10.372-6.931c.565-1.362.855-2.822.855-4.296V74.687a11.231 11.231 0 0 0-3.288-7.939 11.22 11.22 0 0 0-7.939-3.287Z"
    />
    <Path
      stroke="#595959"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M160.388 63.46H35.285c-6.2 0-11.226 5.027-11.226 11.227v65.483c0 6.2 5.026 11.226 11.226 11.226h125.103c6.2 0 11.226-5.026 11.226-11.226V74.687c0-6.2-5.026-11.226-11.226-11.226ZM171.614 134.703H24.059M88.67 142.781h18.332"
    />
    <Path
      fill="#F5F5F5"
      d="m142.9 77.885 8.219-5.053-9.94-2.682a47.863 47.863 0 0 0-29.229-30.574l-3.891-18.318L97.337 36.75a47.723 47.723 0 0 0-48.4 37.67l-6.4 1.656 5.585 3.514a47.6 47.6 0 0 0 7.478 30.875l-2.779 7.256 6.25-2.572a47.586 47.586 0 0 0 38.53 16.987l5.154 8.8 2.872-9.81a47.685 47.685 0 0 0 25.624-14.924l11.518 2.974-5.9-10.713a47.431 47.431 0 0 0 6.343-20.472l7.728-1.05-7.745-6.26a51.434 51.434 0 0 0-.295-2.796Z"
    />
    <Path
      stroke="#595959"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m142.9 77.885 8.219-5.053-9.94-2.682a47.863 47.863 0 0 0-29.229-30.574l-3.891-18.318L97.337 36.75a47.723 47.723 0 0 0-48.4 37.67l-6.4 1.656 5.585 3.514a47.6 47.6 0 0 0 7.478 30.875l-2.779 7.256 6.25-2.572a47.586 47.586 0 0 0 38.53 16.987l5.154 8.8 2.872-9.81a47.685 47.685 0 0 0 25.624-14.924l11.518 2.974-5.9-10.713a47.431 47.431 0 0 0 6.343-20.472l7.728-1.05-7.745-6.26a51.434 51.434 0 0 0-.295-2.796Z"
    />
    <Path
      stroke="#595959"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m122.543 66.789 2.452-7.023-7.341 1.26M120.539 99.25l8.911.257-4.357-7.765M67.382 87.46l-9.905 6.897 11.545 3.21M79.763 58.428l-12.94-5.287 4.147 13.245"
    />
    <Path
      fill="#F3123C"
      d="M95.897 114.648a5.235 5.235 0 0 0 0-10.468 5.232 5.232 0 0 0-5.234 5.234 5.235 5.235 0 0 0 5.234 5.234Z"
    />
    <Path
      stroke="#595959"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M95.897 114.648a5.235 5.235 0 0 0 0-10.468 5.232 5.232 0 0 0-5.234 5.234 5.235 5.235 0 0 0 5.234 5.234Z"
    />
    <Path
      fill="#F3123C"
      d="M95.9 53.142a6.4 6.4 0 0 0-6.4 6.4c0 3.532 6.4 37.242 6.4 37.242s6.4-33.71 6.4-37.243a6.396 6.396 0 0 0-6.4-6.399Z"
    />
    <Path
      stroke="#595959"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M95.9 53.142a6.4 6.4 0 0 0-6.4 6.4c0 3.532 6.4 37.242 6.4 37.242s6.4-33.71 6.4-37.243a6.396 6.396 0 0 0-6.4-6.399Z"
    />
  </Svg>
)

export const VirusThreatFound = memo(VirusThreatFoundIllustration)
