import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ThumbsDownIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled = false,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 12 12', ...props }}>
    <Path
      key={Math.random()}
      d="M11.4801 7.63398C11.1934 7.97398 10.7801 8.16732 10.3334 8.16732L7.64675 8.16732L7.64675 10.0007C7.64675 11.014 6.82675 11.834 5.76008 11.834C5.29342 11.834 4.87341 11.554 4.68675 11.1207L2.99341 7.16732L1.74675 7.16732C0.873414 7.16732 0.166748 6.46065 0.166748 5.58732L0.166747 1.74065C0.166747 0.873984 0.88008 0.167317 1.74675 0.167317L9.45341 0.167317C10.1801 0.167317 10.8001 0.687317 10.9267 1.40732L11.8067 6.40732C11.8801 6.84732 11.7601 7.29398 11.4734 7.63398L11.4801 7.63398ZM1.74675 1.16732C1.42675 1.16732 1.16675 1.42732 1.16675 1.74065L1.16675 5.58732C1.16675 5.90732 1.42675 6.16732 1.74675 6.16732L2.82008 6.16732L2.82008 1.16732L1.74675 1.16732ZM9.94675 1.58065C9.90675 1.34065 9.70008 1.16732 9.45341 1.16732L3.82675 1.16732L3.82675 6.56732L5.60675 10.734C5.63341 10.794 5.69341 10.8407 5.82008 10.8407C6.28008 10.8407 6.64675 10.4673 6.64675 10.0073L6.64675 7.17398L10.3334 7.17398C10.4867 7.17398 10.6201 7.11398 10.7134 7.00065C10.8067 6.88732 10.8467 6.74065 10.8201 6.58732L9.94008 1.58732L9.94675 1.58065Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </Icon>
)
