import { useGetStatusTabQuery } from 'integration/resources/store'

import { UseBottomSheetStatusTab } from './types'

export const useBottomSheetStatusTab: UseBottomSheetStatusTab = () => {
  const { data: statusTab, isLoading: isLoadingStatusTab } = useGetStatusTabQuery()

  return {
    statusTab,
    isLoadingStatusTab
  }
}
