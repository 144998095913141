import { FunctionComponent, PropsWithChildren } from 'react'

import { Header, Sidebar } from 'molecules'
import { VStack, HStack } from 'native-base'

export const InternalLayoutDesktop: FunctionComponent<
  PropsWithChildren<{
    hiddenSearch?: boolean
    onSearch?(search: string): void
    search?: string
    isContent?: boolean
    onPressSearch?: any
    onPressSearchClear?: () => void
  }>
> = ({
  children,
  hiddenSearch = false,
  onSearch,
  search,
  isContent,
  onPressSearch,
  onPressSearchClear
}) => {
  return (
    <HStack h="full">
      <Sidebar />
      {/* main content */}
      <VStack flex={1} bg="background.base">
        <VStack w="full" mx={{ '2xl': 'auto' }} flex={1}>
          {/* header */}
          <Header
            hiddenSearch={hiddenSearch}
            onSearch={onSearch}
            search={search}
            isContent={isContent}
            onPressSearch={onPressSearch}
            onPressSearchClear={onPressSearchClear}
          />
          {/* header */}

          {/* content */}
          {children}
          {/* content */}
        </VStack>
      </VStack>
      {/* main content */}
    </HStack>
  )
}
