import { createIcon } from 'native-base'
import { Path } from 'react-native-svg'

export const MacOsIcon = createIcon({
  viewBox: '0 0 25 20',
  path: [
    <Path
      key={Math.random()}
      d="M13.9338 19.4401C13.7132 18.4682 13.5575 17.4868 13.5226 16.4889C13.5185 16.38 13.5069 16.2683 13.4544 16.1674C13.379 16.105 13.288 16.1221 13.2019 16.1203C12.6301 16.1059 12.0587 16.1328 11.4859 16.0911C11.0029 16.0557 10.5194 16.0247 10.0391 15.9642C8.55052 15.7763 7.09515 15.4444 5.68777 14.9174C5.20608 14.7371 4.73292 14.5371 4.26827 14.3169C4.15211 14.2622 4.12655 14.2016 4.2001 14.0872C4.31716 13.9056 4.4405 13.7275 4.54634 13.5392C4.61945 13.4096 4.7069 13.4024 4.83697 13.4629C5.60704 13.8208 6.40805 14.0971 7.22477 14.3227C9.15286 14.8551 11.1218 15.0582 13.118 15.0259C13.2346 15.0241 13.3526 15.0206 13.4526 14.9466C13.5082 14.8089 13.5176 14.6618 13.5221 14.5178C13.5445 13.7576 13.6405 13.0055 13.7535 12.2551C13.8105 11.8761 13.8141 11.8766 13.4243 11.8766C12.1977 11.8766 10.9715 11.8766 9.74487 11.8761C9.54081 11.8761 9.54125 11.8739 9.55785 11.6685C9.59866 11.1621 9.66504 10.6585 9.73994 10.1562C10.0077 8.3541 10.4436 6.5933 11.0617 4.87914C11.6339 3.29326 12.3426 1.77016 13.2055 0.320169C13.2436 0.256482 13.2934 0.193244 13.2651 0.109375C9.08737 0.109375 4.90962 0.109375 0.731873 0.109375C0.577291 0.109375 0.5 0.189357 0.5 0.349321C0.5 6.68883 0.5 13.0283 0.5 19.3678C0.5 19.526 0.578188 19.6051 0.734564 19.6051C5.05225 19.6051 9.37038 19.6051 13.6881 19.6051C13.7688 19.6051 13.8527 19.5899 13.9267 19.6392C13.9805 19.5746 13.9486 19.5051 13.9334 19.4396L13.9338 19.4401ZM6.46815 3.05824C6.78345 3.06542 7.09874 3.06452 7.41403 3.05824C7.52077 3.05645 7.55486 3.09009 7.55441 3.19773C7.55082 4.07364 7.55127 4.94956 7.55441 5.82502C7.55441 5.92504 7.52167 5.95688 7.42345 5.95554C7.0965 5.95105 6.76954 5.94971 6.44259 5.95598C6.32598 5.95823 6.30938 5.9053 6.31028 5.80753C6.31387 5.3698 6.31163 4.93162 6.31163 4.49388C6.31163 4.06781 6.31566 3.64129 6.30894 3.21522C6.30714 3.09143 6.34706 3.0551 6.4686 3.0578L6.46815 3.05824Z"
      fill="currentColor"
    />,
    <Path
      key={Math.random()}
      d="M24.3094 0.112515C24.047 0.123279 23.7837 0.115655 23.5209 0.115655C20.5765 0.116552 17.6321 0.117449 14.6878 0.118346C14.6066 0.113861 14.5815 0.181135 14.5478 0.232712C13.9554 1.13957 13.4517 2.09622 12.9965 3.07708C12.4323 4.29341 11.9851 5.55323 11.6371 6.84849C11.3209 8.02445 11.0962 9.21746 10.9473 10.4248C10.9235 10.6181 10.9697 10.6688 11.1603 10.6769C11.2128 10.6791 11.2653 10.6778 11.3182 10.6778C12.5744 10.6778 13.8307 10.6778 15.0869 10.6778C15.2928 10.6778 15.3022 10.6854 15.2547 10.8872C15.0901 11.5873 14.9479 12.291 14.8456 13.0032C14.7658 13.5585 14.7218 14.1168 14.6918 14.6761C14.6806 14.8869 14.7335 14.9197 14.9407 14.9004C15.2493 14.8712 15.5556 14.8264 15.861 14.7779C17.3761 14.538 18.8453 14.1514 20.1958 13.3952C20.3186 13.3266 20.4012 13.3504 20.4797 13.4575C20.6075 13.632 20.7438 13.7998 20.8761 13.9706C21.0183 14.155 21.0192 14.1751 20.8201 14.2805C20.2357 14.5891 19.636 14.8636 19.0122 15.0851C17.69 15.5556 16.3275 15.8458 14.9358 16.0099C14.7035 16.0373 14.6662 16.0781 14.6945 16.3145C14.721 16.5347 14.7335 16.7562 14.7546 16.9769C14.8344 17.8111 14.9972 18.6292 15.2107 19.4378C15.2278 19.5019 15.2327 19.58 15.3121 19.6105C15.3879 19.6069 15.4637 19.6006 15.539 19.6006C18.4484 19.6002 21.3578 19.6006 24.2668 19.6006C24.4184 19.6006 24.4943 19.5265 24.4946 19.3782C24.4946 13.0337 24.4946 6.68928 24.4946 0.344837C24.4946 0.327345 24.4919 0.309405 24.4946 0.292362C24.5211 0.140322 24.4489 0.106685 24.3085 0.112515H24.3094ZM17.7766 5.95509C17.4613 5.94612 17.146 5.94926 16.8307 5.95374C16.7334 5.95509 16.6993 5.92504 16.6997 5.82413C16.7033 4.94821 16.7033 4.0723 16.6997 3.19638C16.6993 3.08605 16.7361 3.0578 16.841 3.05959C17.1563 3.06542 17.472 3.06766 17.7869 3.05869C17.9169 3.0551 17.9582 3.09054 17.956 3.22374C17.9479 3.65564 17.9528 4.08799 17.9528 4.5199C17.9528 4.94014 17.9465 5.36083 17.9564 5.78107C17.96 5.92504 17.9116 5.95823 17.7775 5.95464L17.7766 5.95509Z"
      fill="currentColor"
    />
  ]
})
