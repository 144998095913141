import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const CalendarHourIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 18 19', ...props }}>
    <Path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.592 3.287a8.85 8.85 0 1 1 12.516 12.516A8.85 8.85 0 0 1 2.592 3.287ZM8.85 2.195a7.35 7.35 0 1 0 0 14.7 7.35 7.35 0 0 0 0-14.7Z"
      clipRule="evenodd"
    />
    <Path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.85 5.68a.75.75 0 0 1 .75.75v2.838l2.986 3.483a.75.75 0 1 1-1.14.976L8.28 10.033a.75.75 0 0 1-.18-.488V6.43a.75.75 0 0 1 .75-.75Z"
      clipRule="evenodd"
    />
  </Icon>
)
