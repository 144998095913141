import { FunctionComponent } from 'react'

import { Circle, HStack, Text, VStack, useBreakpointValue } from 'native-base'
import { TouchableHighlight } from 'react-native'
import Tooltip from 'react-native-walkthrough-tooltip'

type ArcProgressProps = {
  title: string
  normalValue: string
  insuranceValue: string
  circleColor: string
  isVisible: boolean
  isOnline: boolean
  handleOnClose: () => void
  marginTopTooltip?: number
  marginLeftTooltip?: number
}

export const ArcProgressTooltip: FunctionComponent<ArcProgressProps> = ({
  title = '',
  normalValue,
  insuranceValue,
  marginTopTooltip,
  marginLeftTooltip,
  circleColor,
  isVisible,
  isOnline,
  handleOnClose
}) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  return (
    <Tooltip
      isVisible={isVisible}
      placement="top"
      {...(isDesktop && {
        backgroundColor: 'transparent',
        tooltipStyle: {
          marginTop: marginTopTooltip,
          marginLeft: marginLeftTooltip,
          borderRadius: 20,
          shadowOffset: {
            width: 0,
            height: 5
          },
          shadowOpacity: 0.1,
          shadowRadius: 15,
          shadowColor: '#44444f',
          paddingBottom: 0
        },
        arrowStyle: {
          top: 76
        }
      })}
      contentStyle={{ width: 128, height: 74, paddingTop: 16, marginTop: 3, borderRadius: 15 }}
      tooltipStyle={
        !isDesktop ? { marginTop: marginTopTooltip, marginLeft: marginLeftTooltip } : {}
      }
      content={
        <VStack flex={1}>
          <HStack alignItems="center">
            <Circle size="10px" bg={isOnline ? circleColor : 'error.500'} />
            <Text ml={1} fontSize="10px" fontWeight={700} color="gray.700">
              {title}
            </Text>
          </HStack>

          <HStack space="2px" alignItems="center">
            <Text fontSize="9px" color="gray.300">
              Mult. norm. R$
            </Text>
            <Text fontSize="9px" color="gray.700" fontWeight={700}>
              {normalValue}
            </Text>
          </HStack>

          <HStack space="2px" alignItems="center">
            <Text fontSize="9px" color="gray.300">
              Mult. seg. R$
            </Text>
            <Text fontSize="9px" color="gray.700" fontWeight={700}>
              {insuranceValue}
            </Text>
          </HStack>
        </VStack>
      }
      onClose={handleOnClose}>
      <TouchableHighlight>
        <Text opacity={0}>Click</Text>
      </TouchableHighlight>
    </Tooltip>
  )
}
