import { Center, Text, View } from 'native-base'

export const TabUserListOfMessagesEmpty = () => {
  return (
    <View mt={4}>
      <Center>
        <Text>Não há mensagens recebidas</Text>
      </Center>
    </View>
  )
}
