import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import * as requests from './requests'
import { PointsRvStatement } from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_GET_RV_STATEMENT_QUERY = 'useGetPointsRvStatementQuery'

export const useGetPointsRvStatementQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<PointsRvStatement>>, AxiosError<ResponseError>>(
    [KEY_GET_RV_STATEMENT_QUERY],
    () => requests.getPointsRvStatement()
  )
