import { FunctionComponent, useEffect, useState } from 'react'

import { Box, Heading, HStack, Pressable, Text } from 'native-base'
import { Platform, TouchableHighlight } from 'react-native'
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated'
import Tooltip from 'react-native-walkthrough-tooltip'

import { Striped } from './striped'
import { StackedBarChartProps } from './types'

const AnimatedPressable = Animated.createAnimatedComponent(Pressable)

const StackedBarChart: FunctionComponent<StackedBarChartProps> = ({
  expectedValue,
  expectedIsStriped,
  currentValue,
  currentIsStriped,
  maxValue,
  realValue,
  realPointsValue,
  projectionPointsValue,
  goalValue,
  isPlusAccounts,
  isProduction,
  style,
  withoutTooltip
}) => {
  const points = isProduction ? [0, 100, 130, 150, 200] : [0, 100, 130, 150]

  const calculatedExpectedValue = useSharedValue('0%')

  const calculatedCurrentValue = useSharedValue('0%')

  const isIos = Platform.OS === 'ios'

  const config = {
    duration: 1200,
    easing: Easing.bezier(0.5, 0.01, 0, 1),
    damping: 20,
    stiffness: 90
  }

  const expectedStyles = useAnimatedStyle(() => ({
    width: withTiming(calculatedExpectedValue.value, config)
  }))

  const currentStyles = useAnimatedStyle(() => ({
    width: withTiming(calculatedCurrentValue.value, config)
  }))

  const currentIsFull = currentValue >= maxValue / 2

  const expectedIsFull = expectedValue >= maxValue / 2

  useEffect(() => {
    calculatedExpectedValue.value = `${(expectedValue / maxValue) * 100 + 1}%` // +1 visualy only

    calculatedCurrentValue.value = `${(currentValue / maxValue) * 100}%`
  }, [expectedValue, currentValue, maxValue, calculatedExpectedValue, calculatedCurrentValue])

  const [openTooltipRealized, setOpenTooltipRealized] = useState(false)

  const [openTooltipProjected, setOpenTooltipProjected] = useState(false)

  return (
    <Box style={style}>
      <Box
        w="100%"
        h={6}
        bgColor="gray.100"
        position="relative"
        borderRadius={20}
        overflow="hidden">
        <Tooltip
          isVisible={openTooltipRealized}
          tooltipStyle={{ marginTop: isIos ? -4 : -24 }}
          contentStyle={{ borderRadius: 15, position: 'absolute', padding: 10 }}
          content={
            <Box w="90px">
              <HStack alignItems="center">
                <Box
                  w="10px"
                  h="10px"
                  bg={currentIsFull ? 'secondary.500' : 'error.500'}
                  rounded="full"
                />
                <Text ml={1} fontSize="10px">
                  Realizado Meta
                </Text>
              </HStack>
              <HStack alignItems="center">
                {!isPlusAccounts && (
                  <Text color="gray.300" fontSize="10px">
                    R${` `}
                  </Text>
                )}
                <Text fontSize="10px">{realValue}</Text>
              </HStack>
              <HStack alignItems="center">
                <Text color="gray.300" fontSize="10px">
                  Pts{` `}
                </Text>
                <Text fontSize="10px">{realPointsValue}</Text>
              </HStack>
              <HStack alignItems="center">
                <Text color="gray.300" fontSize="10px">
                  %{` `}
                </Text>
                <Text fontSize="10px">{currentValue}</Text>
              </HStack>
            </Box>
          }
          placement="top"
          onClose={() => setOpenTooltipRealized(false)}>
          <TouchableHighlight>
            <Text opacity={0}>Click</Text>
          </TouchableHighlight>
        </Tooltip>

        <AnimatedPressable
          onPress={() => setOpenTooltipRealized(!withoutTooltip && true)}
          bgColor={currentIsFull ? 'secondary.500' : 'error.500'}
          style={[
            {
              height: '100%',
              position: 'relative',
              overflow: 'hidden',
              borderRadius: 20,
              zIndex: 2,
              top: -21,
              left: 0
            },
            currentStyles
          ]}>
          {currentIsStriped && (
            <Box position="absolute" right={0} top={-8}>
              <Striped color={currentIsFull ? '#4FAE73' : '#B40929'} />
            </Box>
          )}
        </AnimatedPressable>

        <Tooltip
          isVisible={openTooltipProjected}
          tooltipStyle={{ marginTop: isIos ? -50 : -70 }}
          contentStyle={{ borderRadius: 15, position: 'absolute', padding: 10 }}
          content={
            <Box w="90px">
              <HStack alignItems="center">
                <Box
                  w="10px"
                  h="10px"
                  bg={expectedIsFull ? 'secondary.300' : 'error.500'}
                  rounded="full"
                />
                <Text ml={1} fontSize="10px">
                  Projetado Meta
                </Text>
              </HStack>
              <HStack alignItems="center">
                {!isPlusAccounts && (
                  <Text color="gray.300" fontSize="10px">
                    R${` `}
                  </Text>
                )}
                <Text fontSize="10px">{goalValue}</Text>
              </HStack>
              <HStack alignItems="center">
                <Text color="gray.300" fontSize="10px">
                  Pts{` `}
                </Text>
                <Text fontSize="10px">{projectionPointsValue}</Text>
              </HStack>
              <HStack alignItems="center">
                <Text color="gray.300" fontSize="10px">
                  %{` `}
                </Text>
                <Text fontSize="10px">{expectedValue.toFixed(2)}</Text>
              </HStack>
            </Box>
          }
          placement="top"
          onClose={() => setOpenTooltipProjected(false)}>
          <TouchableHighlight>
            <Text opacity={0}>Click</Text>
          </TouchableHighlight>
        </Tooltip>

        <AnimatedPressable
          onPress={() => setOpenTooltipProjected(!withoutTooltip && true)}
          bgColor={expectedIsFull ? 'secondary.300' : 'error.500'}
          style={[
            {
              height: '100%',
              overflow: 'hidden',
              position: 'absolute',
              borderRadius: 20,
              zIndex: 1,
              top: 0,
              left: 0
            },
            expectedStyles
          ]}>
          {expectedIsStriped && (
            <Box position="absolute" right={0} top={-8}>
              <Striped color="#4FAE73" />
            </Box>
          )}
        </AnimatedPressable>

        {/* lines */}
        <Box
          w={0.5}
          h={6}
          bgColor="white"
          position="absolute"
          zIndex={3}
          top={0}
          right={isProduction ? '20%' : '13.4%'}
        />
        <Box
          w={0.5}
          h={6}
          bgColor="white"
          position="absolute"
          zIndex={3}
          top={0}
          right={isProduction ? '35%' : '34%'}
        />
        {isProduction && (
          <Box w={0.5} h={6} bgColor="white" position="absolute" zIndex={3} top={0} right="50%" />
        )}
      </Box>

      <Box w="100%" h={6} mt={2} position="relative">
        <Box position="absolute" zIndex={3} top={0} right={0}>
          <HStack alignItems="center">
            <Heading fontSize={{ base: 16, lg: 14 }} fontWeight="bold" color="gray.400">
              {points[isProduction ? 4 : 3]}
            </Heading>
            <Heading
              fontSize={{ base: 12, lg: 14 }}
              fontWeight="bold"
              color="gray.400"
              mt={{ base: '2px', lg: 0 }}>
              %
            </Heading>
          </HStack>
        </Box>

        <Box position="absolute" zIndex={3} top={0} right={isProduction ? '17%' : '11%'}>
          <HStack alignItems="center">
            <Heading fontSize={{ base: 16, lg: 14 }} fontWeight="bold" color="gray.400">
              {points[isProduction ? 3 : 2]}
            </Heading>
            <Heading
              fontSize={{ base: 12, lg: 14 }}
              fontWeight="bold"
              color="gray.400"
              mt={{ base: '2px', lg: 0 }}>
              %
            </Heading>
          </HStack>
        </Box>
        <Box position="absolute" zIndex={3} top={0} right="32%">
          <HStack alignItems="center">
            <Heading fontSize={{ base: 16, lg: 14 }} fontWeight="bold" color="gray.400">
              {points[isProduction ? 2 : 1]}
            </Heading>
            <Heading
              fontSize={{ base: 12, lg: 14 }}
              fontWeight="bold"
              color="gray.400"
              mt={{ base: '2px', lg: 0 }}>
              %
            </Heading>
          </HStack>
        </Box>
        {isProduction && (
          <Box position="absolute" zIndex={3} top={0} right="47%">
            <HStack alignItems="center">
              <Heading fontSize={{ base: 16, lg: 14 }} fontWeight="bold" color="gray.400">
                {points[1]}
              </Heading>
              <Heading
                fontSize={{ base: 12, lg: 14 }}
                fontWeight="bold"
                color="gray.400"
                mt={{ base: '2px', lg: 0 }}>
                %
              </Heading>
            </HStack>
          </Box>
        )}
        <Box position="absolute" zIndex={3} top={0} left={0}>
          <HStack alignItems="center">
            <Heading fontSize={{ base: 16, lg: 14 }} fontWeight="bold" color="gray.400">
              {points[0]}
            </Heading>
            <Heading
              fontSize={{ base: 12, lg: 14 }}
              fontWeight="bold"
              color="gray.400"
              mt={{ base: '2px', lg: 0 }}>
              %
            </Heading>
          </HStack>
        </Box>
      </Box>
    </Box>
  )
}

export default StackedBarChart
