import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const SupportIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Path
      fill="#A8A8A8"
      d="M8.85 0C3.967 0 0 3.968 0 8.85c0 4.883 3.967 8.85 8.85 8.85 4.883 0 8.85-3.967 8.85-8.85C17.7 3.968 13.733 0 8.85 0Zm0 16.2c-4.05 0-7.35-3.3-7.35-7.35S4.8 1.5 8.85 1.5s7.35 3.3 7.35 7.35-3.3 7.35-7.35 7.35Z"
    />
    <Path
      fill="#A8A8A8"
      d="M10.537 4.118a3.029 3.029 0 0 0-1.417-.51 2.966 2.966 0 0 0-1.485.247c-.465.21-.877.533-1.178.945a.752.752 0 0 0 .15 1.05c.33.248.803.18 1.05-.15.15-.202.36-.367.593-.472.232-.105.488-.15.742-.12.255.022.503.112.713.255.21.142.383.337.495.57.113.225.165.487.158.735a1.531 1.531 0 0 1-.772 1.245 1.461 1.461 0 0 1-.729.187.752.752 0 0 0-.75.75c0 .413.338.75.75.75a3.04 3.04 0 0 0 1.463-.382 2.96 2.96 0 0 0 1.088-1.043c.27-.435.42-.93.442-1.447a3.023 3.023 0 0 0-1.305-2.618l-.008.008ZM8.85 11.475a1.424 1.424 0 1 0-.001 2.849 1.424 1.424 0 0 0 .001-2.849Z"
    />
  </Icon>
)
