import { useCallback } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Icons } from 'atoms'
import { Button, FormControl, Heading, HStack, Input, Text } from 'native-base'
import Layouts from 'organisms/Layouts'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard } from 'react-native'
import { RootStackScreenComponent } from 'src/navigation'
import * as Yup from 'yup'

type FormData = {
  email: string
}

const schema = Yup.object().shape({
  email: Yup.string().required('Digite seu e-mail')
})

export const CreateAccountChangeEmailScreen: RootStackScreenComponent<
  'CreateAccountChangeEmail'
> = ({ navigation, route }) => {
  const { control, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  const onSubmit = handleSubmit(
    useCallback<SubmitHandler<FormData>>(
      (data) => {
        Keyboard.dismiss()

        navigation.goBack()
      },
      [navigation]
    )
  )

  const { params: { email } = {} } = route

  return (
    <Layouts.External>
      <Controller
        control={control}
        render={({ field: { onChange: onChangeText, onBlur, value }, formState: { errors } }) => (
          <FormControl isInvalid={!!errors.email} isRequired>
            <Heading fontSize="24px" lineHeight="28px" color="gray.700" pt={8}>
              Alterar e-mail
            </Heading>
            <HStack alignItems="center">
              <Text fontSize="16px" lineHeight="24px" fontWeight={400} color="gray.500" py={2}>
                E-mail atual:{' '}
              </Text>
              <Text fontSize="14px" bold color="gray.500">
                {email}
              </Text>
            </HStack>

            <Input
              {...{ onChangeText, onBlur, value }}
              autoCapitalize="none"
              enablesReturnKeyAutomatically
              keyboardType="email-address"
              onSubmitEditing={onSubmit}
              returnKeyType="next"
              placeholder="Novo e-mail"
              _stack={{ alignItems: 'center' }}
              leftElement={<Icons.Email color="gray.700" size={6} ml={4} mr={1} mt="6px" />}
            />
            {errors.email && (
              <FormControl.ErrorMessage>{errors.email.message}</FormControl.ErrorMessage>
            )}
            <Button
              colorScheme="gray"
              mt={8}
              onPress={onSubmit}
              disabled={!!errors.email}
              _disabled={{ bg: 'gray.200' }}>
              Alterar
            </Button>
          </FormControl>
        )}
        name="email"
      />
    </Layouts.External>
  )
}
