import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  HandleFinishParams,
  KEY_OPPORTUNITIES_ODC_LIST,
  OpportunitiesItem,
  OpportunityType,
  useGetOpportunitiesODCListInfiniteQuery,
  useSetFinishedOpportunityOdcMutation,
  useSetOpportunityOdcHighlightedMutation,
  useSetOpportunityOdcUnHighlightedMutation,
  useSetUnFinishedOpportunityOdcMutation
} from 'integration/resources/newOpportunities'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { useQueryClient } from 'react-query'
import { ListType } from 'src/screens/OrganizationScreen/OrganizationScreen.types'
import { calculateActiveFilterCount } from 'src/utils/filters'
import { LogEvent } from 'src/utils/logEvents'

import { UseODC } from './types'

const mergeAllDataInfiniteQuery = (
  data: ReturnType<typeof useGetOpportunitiesODCListInfiniteQuery>['data']
): OpportunitiesItem[] => {
  return (
    data?.pages?.reduce<OpportunitiesItem[]>((previousValue, currentValue) => {
      return [...previousValue, ...(currentValue.data.data || [])]
    }, []) ?? []
  )
}

export const useODC: UseODC = ({ search, route, navigation }) => {
  const modalFilters = useMemo(() => {
    return {
      offer: route?.params?.offer,
      public_rating: route?.params?.public_rating,
      segment: route?.params?.segment,
      network: route?.params?.network,
      subsidiary: route?.params?.subsidiary,
      user_std_code: route?.params?.user_std_code
    }
  }, [route?.params])

  const orderByFilters = useMemo(() => {
    return {
      order_by: route?.params?.order_by,
      order_by_direction: route?.params?.order_by_direction
    }
  }, [route?.params])

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch: refetchHook
  } = useGetOpportunitiesODCListInfiniteQuery(
    {
      per_page: 10,
      search: search ?? undefined,
      ...modalFilters,
      ...orderByFilters
    },
    undefined
  )

  const list = useMemo(() => mergeAllDataInfiniteQuery(data), [data])

  const totalItems = data?.pages[0].data.metadata.pagination.total ?? 0

  const fetchMore = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const [refetchLoading, setRefetchLoading] = useState(false)

  const queryClient = useQueryClient()

  const refetch = useCallback(() => {
    setRefetchLoading(true)

    setTimeout(() => {
      setRefetchLoading(false)
    }, 300)

    queryClient.invalidateQueries(KEY_OPPORTUNITIES_ODC_LIST)
  }, [queryClient])

  const isEmpty = useMemo(() => !list?.length, [list])

  const highlights = useMemo(() => list.filter((item) => item.highlighted), [list])

  const highlightsIsEmpty = !highlights.length

  const handleGoToDetails = (opportunityId: string, itemType?: OpportunityType) => {
    LogEvent(`oportunidades_odc_acessar_detalhes`, {
      item_id: opportunityId
    })

    navigation.navigate('OpportunityDetails', {
      id: opportunityId,
      type: itemType
    })
  }

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const toast = useToast()

  const { mutateAsync: setOpportunityHighlighted } = useSetOpportunityOdcHighlightedMutation()

  const { mutateAsync: setOpportunityUnHighlighted } = useSetOpportunityOdcUnHighlightedMutation()

  const [opportunityHighlightIsLoading, setOpportunityHighlightIsLoading] = useState('')

  const handleHighlight = (id: string, highlighted: boolean) => {
    LogEvent(`oportunidades_odc_${highlighted ? 'desfazer_' : ''}destacar`, {
      item_id: id
    })

    setOpportunityHighlightIsLoading(id)

    return (
      id &&
      (highlighted ? setOpportunityUnHighlighted : setOpportunityHighlighted)(
        {
          id
        },
        {
          onError: () => {
            toast.show({
              render: () => <Toast type="error" text="Tente novamente mais tarde" />,
              duration: 3000
            })

            setOpportunityHighlightIsLoading('')
          },
          onSuccess: () => {
            refetch()

            setOpportunityHighlightIsLoading('')
          }
        }
      )
    )
  }

  const [opportunityConcludeIsLoading, setOpportunityConcludeIsLoading] = useState('')

  const { mutateAsync: setOpportunityFinished } = useSetFinishedOpportunityOdcMutation()

  const { mutateAsync: setOpportunityUnFinished } = useSetUnFinishedOpportunityOdcMutation()

  const handleConclude = (data: HandleFinishParams) => {
    const { finished, id, comment, insight, only_finish, score } = data

    LogEvent(`oportunidades_odc_${finished ? 'desfazer_' : ''}concluir`, {
      item_id: id
    })

    setOpportunityConcludeIsLoading(id)

    return (
      id &&
      (finished ? setOpportunityUnFinished : setOpportunityFinished)(
        {
          id,
          only_finish,
          comment,
          insight,
          score
        },
        {
          onError: () => {
            toast.show({
              render: () => <Toast type="error" text="Tente novamente mais tarde" />,
              duration: 3000
            })

            setOpportunityConcludeIsLoading('')
          },
          onSuccess: () => {
            refetch()

            setOpportunityConcludeIsLoading('')
          }
        }
      )
    )
  }

  const activeFilterCount = calculateActiveFilterCount(modalFilters)

  const handleGoToFilters = () => {
    LogEvent(`oportunidades_odc_acessar_filtros`, {
      item_id: ''
    })

    navigation.navigate('OpportunityFilter', {
      listType: ListType.ODC,
      ...modalFilters,
      ...orderByFilters
    })
  }

  useEffect(() => {
    refetchHook()
  }, [search, refetchHook])

  return {
    opportunitiesList: list,
    isLoading,
    fetchMore,
    isFetchingNextPage,
    refetch,
    refreshing: refetchLoading,
    isEmpty,
    highlights,
    highlightsIsEmpty,
    handleHighlight,
    highlightIsLoading: opportunityHighlightIsLoading,
    handleGoToDetails,
    isMobile,
    handleConclude,
    concludeIsLoading: opportunityConcludeIsLoading,
    totalItems,
    handleGoToFilters,
    activeFilterCount
  }
}
