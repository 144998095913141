import { FunctionComponent } from 'react'

import { Box, Flex, Heading, Text, VStack, View, useBreakpointValue } from 'native-base'

interface TitleByDayProps {
  title: string
  subtitle: string
  size: number
  type: string
  short?: boolean
  titleFormmated?: boolean
  showTotal?: boolean
}

const TitleByDay: FunctionComponent<TitleByDayProps> = ({
  short = false,
  titleFormmated = false,
  title,
  subtitle,
  size,
  type,
  showTotal = true
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <Flex
      flexDir="row"
      justifyContent="space-between"
      {...(!isMobile && short && { width: '137px' })}
      my={2}>
      <View>
        <Heading
          color="gray.700"
          fontSize={isMobile ? 24 : '21px'}
          bold
          textTransform={
            titleFormmated
              ? 'none'
              : isMobile
              ? 'capitalize'
              : !short || /hoje|amanhã/i.test(title)
              ? 'capitalize'
              : 'uppercase'
          }>
          {title}
        </Heading>

        <Text
          color={isMobile ? 'gray.400' : 'tertiary.800'}
          fontSize={14}
          {...(!isMobile && { fontWeight: 600 })}
          textTransform={isMobile ? 'capitalize' : 'uppercase'}>
          {isMobile ? subtitle : !short ? subtitle : subtitle.substring(0, 3)}
        </Text>
      </View>

      {short ? (
        <VStack borderColor="gray.100" mx="30px" borderRightWidth="1px" />
      ) : (
        <>
          {showTotal && (
            <>
              {isMobile ? (
                <View>
                  <Box flexDir="row" bgColor="tertiary.800" px={3} py={1} borderRadius={20}>
                    <Text color="white" fontSize={12} bold fontFamily="heading">
                      {size} {type.toUpperCase()}
                    </Text>
                  </Box>
                </View>
              ) : (
                <Text fontSize="md" color="gray.400" mt={2}>
                  {size} {type}
                </Text>
              )}
            </>
          )}
        </>
      )}
    </Flex>
  )
}

export default TitleByDay
