import { FunctionComponent } from 'react'

import { TGoalsLeadersListItem, TGoalsPeriod } from 'integration/resources/goals'
import { RootStackScreenComponentProps } from 'src/navigation'

export type GoalsLeadersListScreenComponent = FunctionComponent<GoalsLeadersListScreenProps>

export type GoalsLeadersListScreenProps = RootStackScreenComponentProps<'GoalsLeadersList'>

export type ResponsiveGoalsLeadersListScreenComponent = FunctionComponent<
  ReturnType<UseGoalsLeadersListScreen>
>

export enum Lists {
  Production,
  Insurance,
  InsuranceAuto,
  Webmotors,
  MoreAccounts,
  Charge
}

export type ListButtonProps = {
  list: Lists
  label: string
  currentList: Lists
  onPress: (list: Lists) => void
}

export type SearchBarProps = {
  onSearch?(search: string): void
  search?: string
  onPressSearch?: () => void
  onPressSearchClear?: () => void
  placeholder: string
}

export type OrderOption = {
  id: 'asc' | 'desc'
  name: string
}

export type OrderField = {
  label: string
  options: OrderOption[]
}

export type LeadersListProps = {
  search?: string
  leadersListData?: TGoalsLeadersListItem[]
  leadersListIsLoading: boolean
  leadersListIsFetchingNextPage: boolean
  handleGoToGoalsStaffList?: (userStdCode: string) => void
  periodIsOpen: boolean
}

export type LeaderCardProps = TGoalsLeadersListItem & {
  handleGoToGoalsStaffList?: (userStdCode: string) => void
  generalStatusIsOpen?: boolean
}

export type OrderByButtonProps = {
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear(): void
  handleApply(): void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen(): void
  orderByOnClose(): void
}

export type UseGoalsLeadersListScreen = ({
  navigation,
  route
}: Pick<GoalsLeadersListScreenProps, 'navigation' | 'route'>) => {
  isMobile: boolean
  periodData?: TGoalsPeriod
  periodDataIsLoading: boolean
  periodIsOpen: boolean
  currentList: Lists
  handleListChange: (list: Lists) => void
  leadersListData?: TGoalsLeadersListItem[]
  leadersListIsLoading: boolean
  leadersListIsFetchingNextPage: boolean
  leadersListTotalItems: number
  onEndReached: () => void
  onSearch?(search: string): void
  search?: string
  onPressSearch?: () => void
  onPressSearchClear?: () => void
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear: () => void
  handleApply: () => void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen: () => void
  orderByOnClose: () => void
  handleGoToGoalsStaffList: (userStdCode: string) => void
}
