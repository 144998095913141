import {
  TStaffListItem,
  TParams,
  TStoresListItem,
  TWalletsManagementDetails,
  TLeadersListItem,
  TConcludeParams,
  TManageWalletParams,
  TOpenWalletsPeriodParams,
  TWalletsManagementPeriod,
  TStoreWithoutSubsidiaryParams,
  TWalletsManagementReport
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const getLeadersList = async (params: TParams) =>
  await client.get<ResponseSuccess<TLeadersListItem[]>>(`v1/wallets/management/leaders`, {
    params: {
      per_page: params?.per_page ?? 10,
      page: params?.page ?? 1,
      order_by: params.order_by ?? undefined,
      order_by_direction: params.order_by_direction ?? 'desc',
      ...params
    }
  })

export const getStaffList = async (params: TParams) =>
  await client.get<ResponseSuccess<TStaffListItem[]>>(
    `v1/wallets/management/leaders/${params.leader_std_code}/staff`,
    {
      params: {
        per_page: params?.per_page ?? 10,
        page: params?.page ?? 1,
        order_by: params.order_by ?? undefined,
        order_by_direction: params.order_by_direction ?? 'desc',
        ...params
      }
    }
  )

export const getStoresList = async (params: TParams) =>
  await client.get<ResponseSuccess<TStoresListItem[]>>(
    `v1/wallets/management/leaders/${params.leader_std_code}/stores`,
    {
      params: {
        per_page: params?.per_page ?? 10,
        page: params?.page ?? 1,
        assigned_stores: params?.assigned_stores ?? undefined,
        ...params
      }
    }
  )

export const getWalletsManagementDetails = async (params: TParams) => {
  return await client.get<ResponseSuccess<TWalletsManagementDetails>>(
    `v1/wallets/management/leaders/${params.leader_std_code ?? 0}`,
    {}
  )
}

export const concludeWalletsManagement = async ({ leader_std_code, stores }: TConcludeParams) =>
  await client.post<ResponseSuccess<TConcludeParams>>(`v1/wallets/management/complete`, {
    leader_std_code,
    stores
  })

export const manageWallet = async ({
  leaderStdCode,
  storesTabNumber,
  userStdCode
}: TManageWalletParams) =>
  await client.patch<ResponseSuccess<TManageWalletParams>>(`v1/wallets/management`, {
    leaderStdCode,
    storesTabNumber,
    userStdCode
  })

export const getWalletsManagementPeriod = async () => {
  return await client.get<ResponseSuccess<TWalletsManagementPeriod>>(
    `v1/wallets/management/period`,
    {}
  )
}

export const openWalletsPeriod = async ({
  date_start,
  date_end,
  leader_std_code
}: TOpenWalletsPeriodParams) => {
  return await client.post<ResponseSuccess<TOpenWalletsPeriodParams>>(
    `v1/wallets/management/period`,
    {
      date_start,
      date_end,
      leader_std_code: leader_std_code ?? undefined
    }
  )
}

export const finishWalletsPeriod = async ({ period_id }: { period_id: string }) => {
  return await client.patch<ResponseSuccess<{ period_id: string }>>(
    `v1/wallets/management/period/${period_id}`
  )
}

export const flagAsExternal = async ({
  leaderStdCode,
  storeTabNumber,
  status
}: TStoreWithoutSubsidiaryParams) => {
  return await client.patch<ResponseSuccess<TStoreWithoutSubsidiaryParams>>(
    `v1/wallets/management/store-without-subsidiary`,
    {
      leaderStdCode,
      storeTabNumber,
      status
    }
  )
}

export const reactivateWalletsPeriod = async ({ leaderStdCode }: { leaderStdCode: number }) => {
  return await client.patch<ResponseSuccess<{ leaderStdCode: number }>>(
    `v1/wallets/management/leaders/reactivate`,
    {
      leaderStdCode
    }
  )
}

export const getWalletsManagementReport = async (params: TParams) => {
  return await client.get<ResponseSuccess<TWalletsManagementReport>>(
    `v1/wallets/management/reports/${params.leader_std_code}`,
    {}
  )
}

export const saveReportFile = async ({
  leaderStdCode,
  fileName
}: {
  leaderStdCode: number
  fileName: string
}) => {
  return await client.post<ResponseSuccess<{ leaderStdCode: number; fileName: string }>>(
    `v1/wallets/management/reports`,
    {
      leaderStdCode,
      fileName
    }
  )
}
