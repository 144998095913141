import { FunctionComponent, PropsWithChildren } from 'react'

import { Box, Pressable } from 'native-base'
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box'
import { PressableProps } from 'react-native'

export const CardButton: FunctionComponent<
  PropsWithChildren<PressableProps & InterfaceBoxProps>
> = ({ children, onPress }) => {
  return (
    <Pressable
      onPress={onPress}
      _hover={{
        style: {
          shadowOffset: { width: 0, height: 3 },
          shadowRadius: 16,
          shadowColor: 'rgba(0, 0, 0, 0.08)',
          borderRadius: 20,
          transform: [
            {
              translateY: -2
            }
          ],
          // @ts-ignore
          transition: 'all 0.3s ease'
        }
      }}>
      <Box p={6} borderRadius="20px" bg="white">
        {children}
      </Box>
    </Pressable>
  )
}
