import { Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { TReportHeader, TReportStore } from 'integration/resources/walletsManagement'

import { Header } from './Header'
import { formatReferenceMonth } from './utils'

export const UnassignedStoresPage: React.FC<
  {
    unassignedStores: TReportStore[]
    referenceMonth: Date
  } & { header?: TReportHeader }
> = ({ unassignedStores, referenceMonth, header }) => (
  <Page size="A4" style={styles.page}>
    <Header header={{ ...header }} />

    <View style={styles.mainContent}>
      <Text style={styles.specialistTitle}>Lojas não Carteirizadas</Text>
      <Text style={styles.specialistSubtitle}>
        {formatReferenceMonth(referenceMonth)} • {unassignedStores.length} Lojas
      </Text>

      <View style={styles.tableHeader}>
        <Text style={[styles.tableColumn, styles.firstTableItem]}>TAB</Text>
        <Text style={[styles.tableColumn]}>Filial</Text>
        <Text style={[styles.tableColumn]}>Segmento</Text>
        <Text style={[styles.tableColumn]}>Número da TAB</Text>
      </View>

      {unassignedStores.map((store, index) => (
        <View
          key={index}
          style={[styles.tableRow, index % 2 === 0 ? styles.rowEven : styles.rowOdd]}>
          <Text style={[styles.tableCell, styles.firstTableItem]}>
            <Text style={styles.tableCellBold}>{String(index + 1).padStart(2, '0')}</Text>{' '}
            {store?.storeNameFantasy}
          </Text>
          <Text style={[styles.tableCell]}>{store?.subsidiaryCode}</Text>
          <Text style={[styles.tableCell]}>{store?.classificationGoalDescription}</Text>
          <Text style={[styles.tableCell]}>{store?.storeTabNumber}</Text>
        </View>
      ))}
    </View>

    <View style={styles.pageFooterContainer} fixed>
      <View style={styles.pageCounter}>
        <Text style={styles.pageNumber} render={({ pageNumber }) => `${pageNumber}`} fixed />
        <Text style={styles.separator}>/</Text>
        <Text style={styles.totalPages} render={({ totalPages }) => `${totalPages}`} fixed />
      </View>
    </View>
  </Page>
)

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
    backgroundColor: '#FFFFFF'
  },
  mainContent: {
    marginTop: 20
  },
  specialistTitle: {
    fontSize: 23,
    color: '#000',
    fontWeight: 'bold'
  },
  specialistSubtitle: {
    fontSize: 12,
    color: '#000',
    fontWeight: 'bold',
    paddingBottom: 8
  },
  tableHeader: {
    minHeight: 36,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    paddingVertical: 5,
    fontSize: 10,
    fontWeight: 'bold',
    color: '#000'
  },
  firstTableItem: {
    paddingLeft: 8,
    flex: 2
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
    fontSize: 10
  },
  tableColumn: {
    textAlign: 'left',
    flex: 1
  },
  tableCellBold: {
    color: '#000',
    fontWeight: 'bold'
  },
  tableCell: {
    flex: 1,
    textAlign: 'left',
    color: '#404040'
  },
  rowEven: {
    backgroundColor: '#FFFFFF'
  },
  rowOdd: {
    backgroundColor: '#FAFAFA'
  },
  pageFooterContainer: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center'
  },
  pageCounter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    borderWidth: 1,
    borderColor: '#DDD',
    paddingHorizontal: 10,
    paddingVertical: 2,
    alignSelf: 'center',
    width: 50
  },
  pageNumber: {
    color: '#F3123C',
    fontSize: 10,
    fontWeight: 'bold'
  },
  separator: {
    color: '#999',
    fontSize: 10,
    paddingHorizontal: 3
  },
  totalPages: {
    color: '#999',
    fontSize: 10
  }
})
