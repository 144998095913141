import { createIcon } from 'native-base'
import { Path } from 'react-native-svg'

export const ShieldIcon = createIcon({
  viewBox: '0 0 18 18',
  path: [
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.152 0a.75.75 0 0 1 .596.297 7.35 7.35 0 0 0 6.941 2.827.75.75 0 0 1 .861.742v3.463a10.683 10.683 0 0 1-6.96 9.963l-.002.001-.75.283a1.952 1.952 0 0 1-1.375 0l-.751-.283H7.71A10.683 10.683 0 0 1 .75 7.328V3.865a.75.75 0 0 1 .856-.742 7.442 7.442 0 0 0 6.95-2.83A.75.75 0 0 1 9.151 0Zm1.175 16.59-.261-.703a9.183 9.183 0 0 0 5.984-8.563v-2.63a8.85 8.85 0 0 1-6.904-2.787A8.942 8.942 0 0 1 2.25 4.69v2.635a9.183 9.183 0 0 0 5.984 8.563l.003.001.754.285a.452.452 0 0 0 .317 0m0 0 .755-.285.264.702"
      fill="currentColor"
    />
  ]
})
