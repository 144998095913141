import { memo, useRef, useState } from 'react'

import { Calendar, Icons } from 'atoms'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { BottomSheetModal } from 'molecules'
import { Box, Pressable, Text, HStack, Button } from 'native-base'

import { TCalendarBodyProps } from './Calendar/types'

type InputDatePickerProps = {
  label: string
  value: string | null
  onChange: (date: string) => void
  error?: string
} & Omit<TCalendarBodyProps, 'onDaySelected' | 'daySelected'>

export const InputDatePicker = memo<InputDatePickerProps>(
  ({ label, value, onChange, error, enableSelectYear, ...calendarBodyProps }) => {
    const refCalendar = useRef<BottomSheetModal>(null)

    const [tempSelectedDate, setTempSelectedDate] = useState<string | null>(null)

    const openCalendar = () => {
      setTempSelectedDate(value)

      refCalendar.current?.present()
    }

    const handleDateSelect = (date: string) => {
      setTempSelectedDate(date)
    }

    const handleConfirm = () => {
      if (tempSelectedDate) {
        onChange(tempSelectedDate)
      }

      refCalendar.current?.close()
    }

    const handleCancel = () => {
      refCalendar.current?.close()
    }

    return (
      <Box position="relative">
        <Text color="gray.400" fontSize="14px" fontFamily="OpenSans_600SemiBold">
          {label}
        </Text>
        <Pressable onPress={openCalendar}>
          <Box
            mt={2}
            bg="gray.30"
            width="full"
            height="50px"
            borderColor={error ? 'error.500' : 'none'}
            borderWidth={error ? 1 : 0}
            borderRadius="40px">
            <HStack alignItems="center" flex={1}>
              <Icons.Calendar color="gray.700" ml={4} size="18px" />
              <Text
                pl={2}
                color={!value ? (error ? 'error.500' : 'gray.400') : 'gray.900'}
                fontSize="16px">
                {value
                  ? format(parseISO(value), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })
                  : 'dd/mm/aaaa'}
              </Text>
            </HStack>
          </Box>
        </Pressable>
        {error && (
          <Text color="error.500" fontSize="14px" mt={1}>
            {error}
          </Text>
        )}

        <Calendar.Apresentation ref={refCalendar}>
          <Calendar.Body
            {...calendarBodyProps}
            daySelected={tempSelectedDate ?? undefined}
            enableSelectYear={enableSelectYear}
            onDaySelected={handleDateSelect}
          />
          <Calendar.Footer>
            <HStack space={2} justifyContent="flex-end" w="full">
              <Button onPress={handleCancel} colorScheme="gray" variant="ghost">
                Cancelar
              </Button>
              <Button onPress={handleConfirm} colorScheme="gray" w="189px">
                Selecionar data
              </Button>
            </HStack>
          </Calendar.Footer>
        </Calendar.Apresentation>
      </Box>
    )
  }
)
