import { useCallback, useEffect, useMemo, useState } from 'react'

import NetInfo from '@react-native-community/netinfo'
import { AxiosResponse } from 'axios'
import { format } from 'date-fns'
import {
  GetListsParams,
  useFavoriteContactMutation,
  useUnfavoriteContactMutation,
  KEY_CONTACT_LIST_QUERY,
  KEY_TAB_LIST_QUERY,
  useGetContactListInfiniteQuery,
  useGetTabListInfiniteQuery,
  WalletItem,
  useFavoriteTabMutation,
  useUnfavoriteTabMutation
} from 'integration/resources/wallet'
import { getContactList, getTabList } from 'integration/resources/wallet/requests'
import { ResponseSuccess } from 'integration/types'
import { useBreakpointValue } from 'native-base'
import { RootStackParamList } from 'navigation/types'
import { Platform } from 'react-native'
import { useQueryClient } from 'react-query'
import screensNames from 'src/constants/screensNames'
import { useAuthAtomValue } from 'src/store/auth'
import { LogEvent, LogEventScreen } from 'src/utils/logEvents'

import { ContactFilterFormFields } from './Contact/ContactFilterScreen/components/Form/types'
import { UseWalletScreen } from './WalletScreen.types'
import { FAVORITE_TYPE } from '../ContentScreen/ContentScreen.types'

const tabs = [
  {
    id: 1,
    title: 'Contatos'
  },
  {
    id: 2,
    title: 'TABs'
  }
]

const sanitizeFilters = (
  parameters: GetListsParams = {}
): { params: GetListsParams; size: number } => {
  let size = 0

  const params: GetListsParams = {}

  if (parameters?.is_favorite) {
    params.is_favorite = 1

    size++
  } else {
    delete params.is_favorite
  }

  if ('store_tab_number' in parameters && parameters.store_tab_number) {
    params.store_tab_number = parameters.store_tab_number

    size++
  } else if ('search' in parameters && parameters.search) {
    params.search = parameters.search

    size++
  }

  if ('job_title' in parameters && parameters.job_title) {
    params.job_title = parameters.job_title

    size++
  }

  if ('status' in parameters && parameters.status) {
    params.status = parameters.status

    size++
  }

  if ('subsegment_code' in parameters && parameters.subsegment_code) {
    params.subsegment_code = parameters.subsegment_code

    params.subSegment_name = parameters.subSegment_name

    size++
  }

  return {
    params,
    size
  }
}

export const useWalletScreen: UseWalletScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [currentTab, setCurrentTab] = useState(
    route?.params?.tabId || route.path ? (!route?.path?.includes('contatos') ? 2 : 1) : 1
  )

  const [searchIsLoading, setSearchIsLoading] = useState(false)

  const [searchListData, setSearchListData] = useState<WalletItem[]>([])

  const [searchVisible, setSearchVisible] = useState(false)

  const isContact = useMemo(() => currentTab === 1, [currentTab])

  const { params, size: filtersSize } = sanitizeFilters(route?.params as GetListsParams)

  const currentGetQueryListInfinity = useMemo(
    () => (isContact ? useGetContactListInfiniteQuery : useGetTabListInfiniteQuery),
    [isContact]
  )

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    currentGetQueryListInfinity({ ...params, expand_details: !isMobile && Platform.OS === 'web' })

  const dataContact = useGetContactListInfiniteQuery({ ...params })

  const totalContact = useMemo(
    () => dataContact.data?.pages[0].data.metadata.pagination.total ?? 0,
    [dataContact.data?.pages]
  )

  const dataTABs = useGetTabListInfiniteQuery({ ...params })

  const totalTABs = useMemo(
    () => dataTABs.data?.pages[0].data.metadata.pagination.total ?? 0,
    [dataTABs.data?.pages]
  )

  const { data: favoriteData, isLoading: isLoadingFavoriteData } = currentGetQueryListInfinity({
    is_favorite: 1
  })

  useEffect(() => {
    const logAnalitcs = async () => {
      const date = new Date()

      const formated = format(date, 'dd-MM-yyyy HH:mm')

      const screen_class = Number(currentTab) === 1 ? 'WalletContactList' : 'WalletTabList'

      LogEventScreen({
        screen_class,
        screen_name: screensNames[screen_class],
        date: formated,
        hora: format(date, 'HH'),
        minutos: format(date, 'mm')
      })
    }

    Platform.OS !== 'web' && logAnalitcs()
  }, [currentTab])

  const onEndReached = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  const totalItensList =
    data && data?.pages.length > 0 ? data?.pages[0].data?.metadata?.pagination?.total : 0

  const list = useMemo(
    () =>
      data?.pages.reduce<WalletItem[]>((previousValue, currentValue) => {
        return [...previousValue, ...currentValue.data.data]
      }, []) ?? [],
    [data]
  )

  const favoriteList = useMemo(
    () =>
      favoriteData?.pages.reduce<WalletItem[]>((previousValue, currentValue) => {
        return [...previousValue, ...currentValue.data.data]
      }, []),
    [favoriteData]
  )

  const pagination = data?.pages[0].data.metadata.pagination

  const queryClient = useQueryClient()

  const onRefresh = () =>
    queryClient.invalidateQueries(isContact ? KEY_CONTACT_LIST_QUERY : KEY_TAB_LIST_QUERY)

  const goBack = useCallback(() => navigation.goBack(), [navigation])

  const handleGoToScreen = useCallback(
    (screen: keyof RootStackParamList, params = undefined) => {
      // @ts-ignore
      navigation.navigate(screen, params)
    },
    [navigation]
  )

  const handleGoToContactDetailsScreen = useCallback(
    (contactId: string) => {
      navigation.navigate('WalletContact', { contactId })

      setSearchListData([])
    },
    [navigation]
  )

  const handleGoToTabDetailsScreen = useCallback(
    (tabNumber: string) => {
      navigation.navigate('WalletTab', { tabNumber })

      setSearchListData([])
    },
    [navigation]
  )

  const successSearchResponse = (res: AxiosResponse<ResponseSuccess<WalletItem[]>>) => {
    setSearchListData(res.data.data)
  }

  const errorSearchResponse = () => {
    setSearchListData([])
  }

  const finallySearchResponse = () => {
    setSearchIsLoading(false)
  }

  const handleGoToFilters = () => {
    if (isContact) {
      return navigation.navigate('ContactFilter', params as Partial<ContactFilterFormFields>)
    }

    return navigation.navigate('TabFilter', params)
  }

  const handleSearch = (search: string) => {
    setSearchIsLoading(true)

    isContact &&
      getContactList({ search })
        .then(successSearchResponse)
        .catch(errorSearchResponse)
        .finally(finallySearchResponse)

    !isContact &&
      getTabList({ search })
        .then(successSearchResponse)
        .catch(errorSearchResponse)
        .finally(finallySearchResponse)
  }

  const handleResetForm = () => {
    const resetFields = {
      status: undefined,
      subSegment_name: undefined,
      subsegment_code: undefined,
      is_favorite: false,
      store_tab_number: undefined,
      job_title: undefined,
      search: undefined
    }

    navigation.setParams(resetFields)
  }

  const handleSetCurrentTab = (tab: number) => {
    if (tab === currentTab) {
      return null
    }

    setCurrentTab(tab)

    handleResetForm()

    setSearchListData([])
  }

  const handleGoToDetailsScreen = (id: string) => {
    const screen = isContact ? 'WalletContact' : 'WalletTab'

    const params = isContact ? { contactId: id } : { tabNumber: id }

    // @ts-ignore
    navigation.navigate(screen, params)
  }

  const authAtomValue = useAuthAtomValue()

  const isGoodsAndServices = useMemo(
    // @ts-ignore
    () => authAtomValue?.user?.business_code === 3,
    [authAtomValue?.user?.business_code]
  )

  const favoriteTabMutation = useFavoriteTabMutation()

  const unfavoriteTabMutation = useUnfavoriteTabMutation()

  const favoriteContactMutation = useFavoriteContactMutation()

  const unfavoriteContactMutation = useUnfavoriteContactMutation()

  const handleToggleFavorite = (type: FAVORITE_TYPE, id: string) => {
    const toggle = !isContact
      ? [favoriteTabMutation, unfavoriteTabMutation]
      : [favoriteContactMutation, unfavoriteContactMutation]

    LogEvent(
      `carteira_${isContact ? 'contatos' : 'tabs'}_${
        type === FAVORITE_TYPE.FAVORITE ? 'favoritar' : 'desfavoritar'
      }`,
      {
        item_id: id
      }
    )

    const mutation = type === FAVORITE_TYPE.FAVORITE ? toggle[0] : toggle[1]

    return mutation.mutateAsync({ id, identifier: id })
  }

  const handleChangeFilters = useCallback(
    (parameters: GetListsParams = {}) => {
      //@ts-ignore
      navigation.setParams({ ...route.params, ...parameters })
    },
    [navigation, route]
  )

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      if (!state.isConnected || (!state.isInternetReachable && searchVisible)) {
        setSearchVisible(false)
      }
    })

    return unsubscribe
  }, [searchVisible])

  return {
    params,
    navigation,
    currentTab,
    favoriteList,
    goBack,
    handleGoToContactDetailsScreen,
    handleGoToDetailsScreen,
    handleGoToScreen,
    handleGoToTabDetailsScreen,
    handleSearch,
    handleGoToFilters,
    isGoodsAndServices,
    isLoading,
    isLoadingFavoriteData,
    isMobile,
    list,
    onEndReached,
    filtersSize,
    onRefresh,
    searchIsLoading,
    searchListData,
    setCurrentTab: handleSetCurrentTab,
    tabs,
    totalContact,
    totalTabs: totalTABs,
    pagination,
    setSearchVisible,
    searchVisible,
    isFetchingNextPage,
    handleChangeFilters,
    handleToggleFavorite,
    totalItensList
  }
}
