import * as Device from 'expo-device'
import * as Notifications from 'expo-notifications'
import { Platform } from 'react-native'
import 'react-native-get-random-values'
import { v4 as uuidv4 } from 'uuid'

import { Storage } from './storage'

/**
 * Create a unique ID to identify device of the user
 * to use in login.
 * @return string
 */
export const createAndSaveDeviceId = async () => {
  const uuid = uuidv4()

  await Storage.setItem('secure_deviceid', uuid)

  return uuid
}

/**
 * Return device id of the user devices
 */
export const getDeviceId = async () => {
  let deviceId

  try {
    deviceId = await Storage.getItem('secure_deviceid')
  } catch (error) {
    //
  }

  if (!deviceId) {
    deviceId = await createAndSaveDeviceId()
  }

  return deviceId
}

export const getDeviceDescription = () => {
  let platform

  if (Platform.OS === 'web') {
    const userAgent = navigator.userAgent

    if (userAgent.match(/chrome|chromium|crios/i)) {
      platform = 'Chrome'
    } else if (userAgent.match(/firefox|fxios/i)) {
      platform = 'Firefox'
    } else if (userAgent.match(/safari/i)) {
      platform = 'Safari'
    } else if (userAgent.match(/opr\//i)) {
      platform = 'Opera'
    } else if (userAgent.match(/edg/i)) {
      platform = 'Edge'
    } else {
      platform = 'Navegador Desconhecido'
    }
  } else {
    platform = Platform.OS
  }

  return `Platform: ${platform} - Device: ${Device.brand} ${Device.modelName} - ${
    Platform.OS === 'web' && !Device.deviceName ? 'Navegador' : Device.deviceName
  }`
}

/**
 * Get token to receive push notifications
 * @error Receive an alert
 * @returns Promise with token
 */
export const registerForPushNotificationsAsync = async () => {
  let token

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.HIGH,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C'
    })
  }

  if (Device.isDevice && Platform.OS !== 'web') {
    const { status: existingStatus } = await Notifications.getPermissionsAsync()

    let finalStatus = existingStatus

    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync()

      finalStatus = status
    }

    if (finalStatus !== 'granted') {
      console.warn('Permissão de notificações não aceita!')

      return
    }

    try {
      /**
       * We can get this token in dashboard expo, in overview page
       * @description This token now is a GUID, is required by expo notificications
       */
      token = (
        await Notifications.getExpoPushTokenAsync({
          projectId: '6564321f-784e-456b-955c-8b679287e429'
        })
      ).data
    } catch (error) {
      console.log('error', error)
    }
  } else {
    console.warn('Para testar a notificações se faz necessário um dispositivo físico')
  }

  return token
}
