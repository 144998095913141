import Svg, { SvgProps, G, Path, Defs, ClipPath, Rect } from 'react-native-svg'

export const DashboardIcon = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <G clipPath="url(#clip0_5939_43446)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.7778 1.78003C1.69496 1.78003 1.6278 1.84719 1.6278 1.93003V8.23003C1.6278 8.31287 1.69496 8.38003 1.7778 8.38003H5.37779C5.46063 8.38003 5.52779 8.31287 5.52779 8.23003V1.93003C5.52779 1.84719 5.46063 1.78003 5.37779 1.78003H1.7778ZM0.127808 1.93003C0.127808 1.01876 0.866535 0.280029 1.7778 0.280029H5.37779C6.28905 0.280029 7.02778 1.01876 7.02778 1.93003V8.23003C7.02778 9.1413 6.28905 9.88003 5.37779 9.88003H1.7778C0.866535 9.88003 0.127808 9.1413 0.127808 8.23003V1.93003Z"
        fill="#404040"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.7778 14.3801C1.69496 14.3801 1.6278 14.4473 1.6278 14.5301V16.3301C1.6278 16.413 1.69496 16.4801 1.7778 16.4801H5.37779C5.46063 16.4801 5.52779 16.413 5.52779 16.3301V14.5301C5.52779 14.4473 5.46063 14.3801 5.37779 14.3801H1.7778ZM0.127808 14.5301C0.127808 13.6189 0.866535 12.8801 1.7778 12.8801H5.37779C6.28905 12.8801 7.02778 13.6189 7.02778 14.5301V16.3301C7.02778 17.2414 6.28905 17.9801 5.37779 17.9801H1.7778C0.866535 17.9801 0.127808 17.2414 0.127808 16.3301V14.5301Z"
        fill="#404040"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5777 9.88013C12.4948 9.88013 12.4277 9.94728 12.4277 10.0301V16.3301C12.4277 16.413 12.4948 16.4801 12.5777 16.4801H16.1777C16.2605 16.4801 16.3277 16.413 16.3277 16.3301V10.0301C16.3277 9.94728 16.2605 9.88013 16.1777 9.88013H12.5777ZM10.9277 10.0301C10.9277 9.11886 11.6664 8.38013 12.5777 8.38013H16.1777C17.0889 8.38013 17.8276 9.11886 17.8276 10.0301V16.3301C17.8276 17.2414 17.0889 17.9801 16.1777 17.9801H12.5777C11.6664 17.9801 10.9277 17.2414 10.9277 16.3301V10.0301Z"
        fill="#404040"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5777 1.78003C12.4948 1.78003 12.4277 1.84719 12.4277 1.93003V3.73003C12.4277 3.81287 12.4948 3.88003 12.5777 3.88003H16.1777C16.2605 3.88003 16.3277 3.81287 16.3277 3.73003V1.93003C16.3277 1.84719 16.2605 1.78003 16.1777 1.78003H12.5777ZM10.9277 1.93003C10.9277 1.01876 11.6664 0.280029 12.5777 0.280029H16.1777C17.0889 0.280029 17.8276 1.01876 17.8276 1.93003V3.73003C17.8276 4.6413 17.0889 5.38003 16.1777 5.38003H12.5777C11.6664 5.38003 10.9277 4.6413 10.9277 3.73003V1.93003Z"
        fill="#404040"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_5939_43446">
        <Rect width={17.9999} height={18} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
)
