import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { OrganizationOpportunityScreenComponent } from './types'
import { useOrganizationOpportunityDetails } from './useOpportunityDetails'

const ScreenDesktop = lazy(() => import('./desktop'))

const ScreenMobile = lazy(() => import('./mobile'))

export const OrganizationOpportunityScreen: OrganizationOpportunityScreenComponent = ({
  navigation,
  route
}) => {
  const walletScreen = useOrganizationOpportunityDetails({ navigation, route })

  const Screen = walletScreen.isMobile ? ScreenMobile : ScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...walletScreen} />
    </Suspense>
  )
}
