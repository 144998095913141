import { format, parse } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { capitalizeText, formatDateStringToMask } from 'src/utils'

export const formatReferenceMonth = (referenceMonth: Date | undefined): string =>
  referenceMonth
    ? capitalizeText(format(referenceMonth, 'MMMM yyyy', { locale: ptBR }))
    : 'Sem data'

export const formatDate = (dateString: string | null, format?: string): string =>
  dateString ? formatDateStringToMask(dateString, format ?? "dd'/'MM'/'yyyy") : 'Sem data'

export const formatDayDate = format(new Date(), "EEEE, dd 'de' MMMM 'de' yyyy", { locale: ptBR })

export const correctDate = (dataString: string) => parse(dataString, 'yyyyMM', new Date())
