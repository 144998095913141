import { useCallback, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { GetListSubsegments } from 'integration/resources/store'
import { KEY_TAB_LIST_QUERY } from 'integration/resources/wallet/hooks'
import { useBreakpointValue } from 'native-base'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'

import { tabFilterFormSchema } from './components/Form/schema'
import { TabFilterFormFields } from './components/Form/types'
import { UseTabFilterScreen } from './TabFilterScreen.types'

export const useTabFilterScreen: UseTabFilterScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const queryClient = useQueryClient()

  const { control, formState, reset, handleSubmit, watch, getValues, setValue } =
    useForm<TabFilterFormFields>({
      resolver: yupResolver(tabFilterFormSchema),
      mode: 'onChange'
    })

  const handleSubmitForm = (values: TabFilterFormFields) => {
    queryClient.invalidateQueries(KEY_TAB_LIST_QUERY).then(() => {
      navigation.navigate('Tab', {
        screen: 'Wallet',
        params: {
          ...values,
          tabId: 2
        }
      })
    })
  }

  const handleGoBack = () => {
    queryClient.invalidateQueries(KEY_TAB_LIST_QUERY)

    navigation.navigate('Tab', {
      screen: 'Wallet',
      params: {
        ...getValues(),
        tabId: 2
      }
    })
  }

  const [openSubSegment, setOpenSubSegment] = useState(false)

  const [openStatusTab, setOpenStatusTab] = useState(false)

  const handleSelectSubSegment = useCallback(
    (subsegment: GetListSubsegments) => {
      setValue('subSegment_name', subsegment?.name ?? '')

      setValue('subsegment_code', Number(subsegment?.code) ?? 0)

      setOpenSubSegment(false)
    },
    [setValue]
  )

  const handleSelectStatusTab = useCallback(
    (status: string) => {
      setValue('status', status ?? '')

      setOpenStatusTab(false)
    },
    [setValue]
  )

  const formFields = watch()

  let dirtySize = 0

  if (formFields.status) dirtySize++

  if (formFields.subsegment_code) dirtySize++

  useEffect(() => {
    reset(route.params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params])

  const handleResetForm = () => {
    const resetFields = {
      status: undefined,
      subSegment_name: undefined,
      subsegment_code: undefined
    }

    navigation.setParams(resetFields)

    reset(resetFields)
  }

  return {
    isMobile,
    control,
    dirtySize,
    formState,
    getValues,
    handleSubmit,
    handleGoBack,
    handleSubmitForm,
    handleResetForm,
    openSubSegment,
    setOpenSubSegment,
    openStatusTab,
    setOpenStatusTab,
    handleSelectSubSegment,
    handleSelectStatusTab
  }
}
