import { FunctionComponent, memo } from 'react'

import { Illustrations } from 'atoms'
import { VStack, Heading, Button, Actionsheet, Text, Center } from 'native-base'
import { Dimensions } from 'react-native'

type BottomSheetEvaluationOpportunitySuccessProps = {
  isOpen: boolean
  handleClose: () => void
}

export const BottomSheetEvaluationOpportunitySuccess: FunctionComponent<BottomSheetEvaluationOpportunitySuccessProps> =
  memo(({ isOpen, handleClose }) => {
    const screenHeight = Dimensions.get('screen').height

    return (
      <Actionsheet hideDragIndicator isOpen={isOpen} onClose={handleClose}>
        <Actionsheet.Content p={4} maxH={screenHeight > 844 ? '466px' : '426px'}>
          <VStack justifyContent="center" space={6} h="full">
            <VStack space={6} alignItems="center">
              <Center mt={screenHeight > 844 ? 4 : 2}>
                <Illustrations.SocialMedia />
              </Center>

              <VStack space={3} alignItems="center">
                <Heading fontSize="16px" lineHeight="18px" fontWeight={700}>
                  Obrigado por avaliar esta oportunidade
                </Heading>

                <Text fontSize="12px" lineHeight="16px" color="gray.500" textAlign="center">
                  Desta forma nós podemos separar as oportunidades que melhor combinam com o seu
                  perfil.
                </Text>
              </VStack>
            </VStack>

            <Button colorScheme="gray" onPress={handleClose}>
              Confirmar
            </Button>
          </VStack>
        </Actionsheet.Content>
      </Actionsheet>
    )
  })
