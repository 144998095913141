import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const UserIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled = false,
  ...props
}) =>
  filled ? (
    <Icon {...{ viewBox: '0 0 24 24', ...props }}>
      <Path
        key={Math.random()}
        d="M6.38491 6C6.38491 7.5913 7.01705 9.11742 8.14227 10.2426C9.26749 11.3679 10.7936 12 12.3849 12C13.9762 12 15.5023 11.3679 16.6276 10.2426C17.7528 9.11742 18.3849 7.5913 18.3849 6C18.3849 4.4087 17.7528 2.88258 16.6276 1.75736C15.5023 0.632141 13.9762 0 12.3849 0C10.7936 0 9.26749 0.632141 8.14227 1.75736C7.01705 2.88258 6.38491 4.4087 6.38491 6Z"
        fill="currentColor"
      />
      <Path
        key={Math.random()}
        d="M23.7009 22.3992C22.8747 20.058 21.3426 18.0307 19.3159 16.5967C17.2892 15.1628 14.8676 14.3927 12.3849 14.3927C9.9022 14.3927 7.48061 15.1628 5.4539 16.5967C3.42719 18.0307 1.89514 20.058 1.06891 22.3992C1.00479 22.5802 0.98502 22.7739 1.01127 22.9641C1.03752 23.1543 1.10902 23.3354 1.21976 23.4922C1.33051 23.649 1.47726 23.777 1.64771 23.8654C1.81816 23.9537 2.00732 23.9999 2.19931 24H22.5705C22.7625 23.9999 22.9517 23.9537 23.1221 23.8654C23.2926 23.777 23.4393 23.649 23.5501 23.4922C23.6608 23.3354 23.7323 23.1543 23.7586 22.9641C23.7848 22.7739 23.765 22.5802 23.7009 22.3992Z"
        fill="currentColor"
      />
    </Icon>
  ) : (
    <Icon {...{ viewBox: '0 0 18 18', ...props }}>
      <Path
        key={Math.random()}
        d="M7.302.365a4.8 4.8 0 1 1 3.674 8.87A4.8 4.8 0 0 1 7.302.365ZM9.14 1.5a3.3 3.3 0 1 0 0 6.6 3.3 3.3 0 0 0 0-6.6ZM4.026 12.42a8.85 8.85 0 0 1 13.46 4.28.75.75 0 0 1-.708 1H1.5a.75.75 0 0 1-.707-1 8.85 8.85 0 0 1 3.233-4.28Zm5.113-.127A7.35 7.35 0 0 0 2.646 16.2h12.987a7.35 7.35 0 0 0-6.494-3.907Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </Icon>
  )
