import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const EcosystemWebmotorsIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 32 32', ...props }}>
    <Path
      d="M0 16C0 7.169 7.167 0 16.006 0 24.844 0 32 7.169 32 16s-7.167 16-15.994 16C7.156 32 0 24.831 0 16Zm23.543 6.64a1.315 1.315 0 0 0 1.39-1.325v-10.64c0-.754-.627-1.36-1.39-1.327-.718.034-1.256.686-1.256 1.394v2.92c0 .551-.449.99-.998.99h-2.984a.999.999 0 0 1-.998-.99v-3.92c0-.72-.539-1.36-1.256-1.394a1.322 1.322 0 0 0-1.391 1.326v4c0 .55-.449.989-.999.989h-2.983a.999.999 0 0 1-.998-.989v-2.921c0-.72-.539-1.36-1.257-1.393a1.322 1.322 0 0 0-1.39 1.325v10.562c0 .72.538 1.36 1.256 1.393a1.315 1.315 0 0 0 1.39-1.325v-3c0-.551.45-.99.999-.99h2.983a1 1 0 0 1 .999.99v3.921c0 .72.538 1.36 1.256 1.393a1.315 1.315 0 0 0 1.39-1.326v-4c0-.55.45-.988.999-.988h2.983c.55 0 .999.449.999.988v2.922c0 .73.538 1.382 1.256 1.415Z"
      fill="#F3123C"
    />
  </Icon>
)
