import { AxiosError, AxiosResponse } from 'axios'
import { InfiniteData, useInfiniteQuery, useQuery } from 'react-query'

import * as requests from './requests'
import { GetStoresListParams, ProductionHistorySerialized, ProductionStoresList } from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_PRODUCTION_STORES_LIST_QUERY = 'getProductionStoresList'

export const useGetProductionHistoryQuery = ({ isMobile = true }) =>
  useQuery<AxiosResponse<ResponseSuccess<ProductionHistorySerialized>>, AxiosError<ResponseError>>(
    ['getProductionHistory'],
    () => requests.getProductionHistory({ isMobile })
  )

export const useGetProductionHistoryMonthsQuery = ({ isMobile = true }) =>
  useQuery<AxiosResponse<{ value: number; label: string }[]>, AxiosError<ResponseError>>(
    ['getProductionHistoryMonths'],
    () => requests.getProductionHistoryMonths({ isMobile })
  )

export const useGetProductionStoresListInfiniteQuery = (
  params: GetStoresListParams,
  onSuccess?: (
    data: InfiniteData<AxiosResponse<ResponseSuccess<ProductionStoresList>, any>>
  ) => void
) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<ProductionStoresList>>, AxiosError<ResponseError>>(
    [`${KEY_PRODUCTION_STORES_LIST_QUERY}.${params.year_month}`, params],
    ({ pageParam }) => requests.getProductionStoresList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false,
      onSuccess
    }
  )
