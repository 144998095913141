import { Dashboard, DashboardDetail, GetDashboardsParams } from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const getDashboards = async (params?: GetDashboardsParams) => {
  const response = await client.get<ResponseSuccess<Dashboard[]>>(`v1/dashboards`, {
    params: {
      ...params
    }
  })

  return response
}

export const getDashboard = async (id: string) =>
  await client.get<ResponseSuccess<DashboardDetail>>(`v1/dashboards/${id}`)

export const favoriteDashboard = async ({ id }: Pick<Dashboard, 'id'>) => {
  return await client.post(`v1/dashboards/${id}/favorite`)
}

export const unfavoriteDashboard = async ({ id }: Pick<Dashboard, 'id'>) =>
  await client.delete(`v1/dashboards/${id}/favorite`)
