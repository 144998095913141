import { UseHeader } from './Header.types'

export const useHeader: UseHeader = ({
  opportunityDetails,
  isLoading,
  handleEvaluateConclude,
  handleHighlight,
  opportunityType,
  isMobile,
  pageTitle,
  handleCloseEvaluate,
  evaluateIsOpen,
  evaluationSuccessSheetRef,
  closeEvaluationSuccessSheet,
  handleFinishEvaluate,
  concludeIsLoading,
  highlightIsLoading
}) => {
  return {
    opportunityDetails,
    isLoading,
    handleEvaluateConclude,
    handleHighlight,
    opportunityType,
    isMobile,
    pageTitle,
    handleCloseEvaluate,
    evaluateIsOpen,
    evaluationSuccessSheetRef,
    closeEvaluationSuccessSheet,
    handleFinishEvaluate,
    concludeIsLoading,
    highlightIsLoading
  }
}
