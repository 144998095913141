import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { FirstLoginSuccessScreenComponent } from './FirstLoginSuccessScreen.types'
import { useFirstLoginSuccessScreen } from './useFirstLoginSuccessScreen'

const FirstLoginSuccessScreenDesktop = lazy(() => import('./FirstLoginSuccessScreen.desktop'))

const FirstLoginSuccessScreenMobile = lazy(() => import('./FirstLoginSuccessScreen.mobile'))

export const FirstLoginSuccessScreen: FirstLoginSuccessScreenComponent = ({ navigation }) => {
  const props = useFirstLoginSuccessScreen({ navigation })

  const Screen = props.isMobile ? FirstLoginSuccessScreenMobile : FirstLoginSuccessScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
