import { UseRecommendations } from './Recommendations.types'

export const useRecommendations: UseRecommendations = ({
  opportunityDetails,
  isLoading,
  handleOpenRecommendations,
  isMobile
}) => {
  const data = opportunityDetails?.recommendations.items.filter((item) => !!item.value)

  return {
    opportunityDetails,
    isLoading,
    handleOpenRecommendations,
    isMobile,
    data
  }
}
