import { useCallback, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useBreakpointValue } from 'native-base'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard } from 'react-native'
import fieldsValidation from 'src/constants/fields-validation'
import { useBottomSheetContext } from 'src/contexts/BottomSheetContext'
import {
  SendEmailResetPasswordPayload,
  useSendEmailResetPasswordMutation
} from 'src/integration/resources/resetPassword'
import { REMEMBER_EMAIL_STORAGE_KEY } from 'src/screens/Login/LoginScreen/useLoginScreen'
import * as Yup from 'yup'

import { UseResetPasswordSendEmailScreen } from './ResetPasswordSendEmailScreen.types'

const schema = Yup.object().shape({
  email: Yup.string()
    .email(fieldsValidation.email.invalid)
    .required(fieldsValidation.common.required)
})

export const useResetPasswordSendEmailScreen: UseResetPasswordSendEmailScreen = ({
  navigation
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, errors }
  } = useForm<SendEmailResetPasswordPayload>({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const isInvalid = isDirty && !!errors?.email

  const sendEmailResetPasswordMutation = useSendEmailResetPasswordMutation()

  const bottomSheetContext = useBottomSheetContext()

  const submit = handleSubmit(
    useCallback<SubmitHandler<SendEmailResetPasswordPayload>>(
      (formData) => {
        Keyboard.dismiss()

        sendEmailResetPasswordMutation.mutate(formData, {
          onError: () =>
            bottomSheetContext.open({
              description: 'Não foi possível enviar o e-mail de redefinição de senha.',
              title: 'Erro ao enviar email'
            }),
          onSuccess: () => {
            navigation.navigate('ResetPasswordEmailSentSuccessfully', formData)
          }
        })
      },
      [bottomSheetContext, navigation, sendEmailResetPasswordMutation]
    )
  )

  useEffect(() => {
    const getEmailFromStorage = async () => {
      const savedEmail = await AsyncStorage.getItem(REMEMBER_EMAIL_STORAGE_KEY)

      if (savedEmail) {
        setValue('email', savedEmail)
      }
    }

    getEmailFromStorage()
  }, [setValue])

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  return {
    control,
    isMobile,
    isLoading: sendEmailResetPasswordMutation.isLoading,
    isInvalid,
    submit,
    handleGoBack
  }
}
