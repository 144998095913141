import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path, Rect, Mask, G } from 'react-native-svg'

export const EcosystemSIMIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 32 32', ...props }}>
    <Rect width={32} height={32} rx={16} fill="#F5F5F5" />
    <Mask id="a" x={8} y={11} width={6} height={3}>
      <Path d="M8.908 11.7h5.065v2.272H8.91V11.7Z" fill="#fff" />
    </Mask>
    <G mask="url(#a)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.055 13.972h2.772c.633 0 1.146-.509 1.146-1.136 0-.628-.513-1.137-1.146-1.137h-2.772c-.634 0-1.147.509-1.147 1.137s.513 1.136 1.147 1.136Z"
        fill="#FD0064"
      />
    </G>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.794 12.824a.973.973 0 0 1-.992.974.973.973 0 0 1-.992-.974c0-.546.435-.974.992-.974.557 0 .992.428.992.974Z"
      fill="#FDC500"
    />
    <Mask id="b" x={6} y={14} width={6} height={7}>
      <Path d="M6.184 14.295h5.097v6.002H6.184v-6.002Z" fill="#fff" />
    </Mask>
    <G mask="url(#b)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m9.384 16.506-.131-.038c-.382-.111-.718-.209-.837-.277.048-.017.146-.039.334-.039.243 0 .53.043.813.121l.162.046c.173.05.352.103.536.103.43 0 .866-.32.866-.929 0-.42-.252-.743-.709-.913-.372-.142-1.032-.285-1.668-.285-1.495 0-2.46.76-2.46 1.936 0 1.2.971 1.586 1.967 1.88l.246.069c.26.074.55.156.654.223-.051.02-.167.049-.407.049a2.716 2.716 0 0 1-.866-.15 7.853 7.853 0 0 1-.156-.057c-.22-.08-.426-.157-.667-.157a.867.867 0 0 0-.877.894c0 .276.113.66.655.917.401.193 1.12.398 1.934.398 1.547 0 2.508-.75 2.508-1.959 0-1.192-.98-1.57-1.897-1.832"
        fill="#FD0064"
      />
    </G>
    <Mask id="c" x={11} y={14} width={3} height={7}>
      <Path d="M11.731 14.412h2.228v5.885h-2.228v-5.885Z" fill="#fff" />
    </Mask>
    <G mask="url(#c)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.845 14.412c-.603 0-1.114.506-1.114 1.104v3.665c0 .605.51 1.116 1.114 1.116a1.116 1.116 0 0 0 1.114-1.116v-3.664c0-.61-.5-1.105-1.114-1.105"
        fill="#FD0064"
      />
    </G>
    <Mask id="d" x={23} y={18} width={3} height={3}>
      <Path d="M23.832 18.352h1.985V20.3h-1.985v-1.948Z" fill="#fff" />
    </Mask>
    <G mask="url(#d)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.824 18.35a.973.973 0 1 1 0 1.948.973.973 0 0 1-.992-.974c0-.547.436-.974.992-.974Z"
        fill="#FDC500"
      />
    </G>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.525 14.342c-.697 0-1.254.224-1.73.701-.357-.449-.923-.701-1.6-.701-.569 0-1.053.195-1.47.594a1.119 1.119 0 0 0-.938-.524c-.604 0-1.114.517-1.114 1.128v3.641c0 .605.51 1.116 1.114 1.116a1.116 1.116 0 0 0 1.113-1.116v-2.468c.114-.197.314-.408.646-.408.214 0 .469.103.469.593v2.283a1.116 1.116 0 0 0 1.113 1.116 1.13 1.13 0 0 0 1.114-1.116v-2.479c.157-.263.37-.397.634-.397.214 0 .468.105.468.605v2.271a1.116 1.116 0 0 0 1.114 1.116 1.13 1.13 0 0 0 1.114-1.116v-2.658c0-2.024-1.567-2.181-2.047-2.181"
      fill="#FD0064"
    />
  </Icon>
)
