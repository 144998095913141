import { TabSliding } from 'molecules/TabSliding'

export const CalendarTabSliding = ({
  index,
  setCurrentIndex
}: {
  index: number
  setCurrentIndex(index: number): void
}) => {
  return (
    <TabSliding
      backgroundColor="#f5f5f5"
      tabs={[
        {
          id: 1,
          title: 'Data'
        },
        {
          id: 2,
          title: 'Período'
        }
      ]}
      index={index}
      setCurrentIndex={setCurrentIndex}
    />
  )
}
