import { FunctionComponent, useCallback } from 'react'

import { Icons } from 'atoms'
import { useSendConfirmAccountEmailMutation } from 'integration/resources/createAccount'
import { ButtonZendesk } from 'molecules'
import { Box, Button, Heading, HStack, Text, useBreakpointValue, VStack } from 'native-base'
import Layouts from 'organisms/Layouts'
import { TExternalDesktopLayoutProps } from 'organisms/Layouts/ExternalDesktopLayout'
import { useBottomSheetContext } from 'src/contexts/BottomSheetContext'
import { RootStackScreenComponent } from 'src/navigation'
import { formatEmail } from 'src/utils/formatters'

export const CreateAccountSendEmailConfirmationScreen: RootStackScreenComponent<
  'CreateAccountSendEmailConfirmation'
> = ({ route, navigation }) => {
  const {
    params: { id, email }
  } = route

  const sendConfirmEmailMutation = useSendConfirmAccountEmailMutation()

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const goToEntryScreen = useCallback(
    () =>
      navigation.reset({
        index: 0,
        routes: [{ name: 'Entry' }]
      }),
    [navigation]
  )

  const bottomSheetContext = useBottomSheetContext()

  const handleResendEmail = useCallback(
    () =>
      sendConfirmEmailMutation.mutate(
        {
          id
        },
        {
          onError: () => {
            bottomSheetContext.open({
              description: `Erro ao reenviar e-mail`,
              title: 'Não foi possível reenviar o e-mail, tente novamente mais tarde'
            })
          },
          onSuccess: () =>
            bottomSheetContext.open({
              testID: 'login-authenticate-resend-ok',
              illustrationName: 'ReSentCode',
              description: `Reenviamos um E-MAIL para o endereço ${formatEmail(email)}`,
              title: 'Reenviamos o e-mail de confirmação'
            })
        }
      ),
    [bottomSheetContext, id, email, sendConfirmEmailMutation]
  )

  const LayoutPlatform: FunctionComponent<TExternalDesktopLayoutProps> = isDesktop
    ? Layouts.ExternalDesktop
    : Layouts.External

  return (
    <>
      <LayoutPlatform image={require('assets/login_desktop.jpg')}>
        {isDesktop && <Icons.FlameSantander color="primary.500" size={8} />}
        <Heading
          fontSize="24px"
          lineHeight={{
            base: '28px',
            lg: '38px'
          }}
          color="gray.700"
          mt={4}
          textAlign={{
            base: 'center',
            lg: 'left'
          }}>
          Falta pouco
        </Heading>

        <VStack alignItems={{ base: 'center', lg: 'left' }}>
          <Text
            fontSize={{
              base: '16px',
              lg: '21px'
            }}
            lineHeight={{
              base: '24px',
              lg: '30px'
            }}
            fontWeight={400}
            color="gray.500"
            mt={2}
            textAlign={{
              base: 'center',
              lg: 'left'
            }}>
            Um e-mail com um link de confirmação foi
          </Text>

          <HStack alignItems="baseline" flexWrap="wrap">
            <Text
              fontSize={{
                base: '16px',
                lg: '21px'
              }}
              lineHeight={{
                base: '24px',
                lg: '30px'
              }}
              fontWeight={400}
              color="gray.500">
              enviado para
            </Text>
            <Text
              ml="3px"
              fontSize={{
                base: '16px',
                lg: '21px'
              }}
              lineHeight={{
                base: '24px',
                lg: '30px'
              }}
              bold
              color="gray.500">
              {email}.
            </Text>
          </HStack>

          <Text
            fontSize={{
              base: '16px',
              lg: '21px'
            }}
            lineHeight={{
              base: '24px',
              lg: '30px'
            }}
            fontWeight={400}
            color="gray.500"
            textAlign={{
              base: 'center',
              lg: 'left'
            }}>
            Acesse seu e-mail para confirmar seu cadastro.
          </Text>
        </VStack>

        <Button
          variant="solid"
          colorScheme="gray"
          isLoading={sendConfirmEmailMutation.isLoading}
          mt={8}
          onPress={goToEntryScreen}
          w="full">
          Voltar para entrada
        </Button>

        <HStack
          justifyContent={{
            base: 'center',
            lg: 'flex-start'
          }}>
          <HStack alignItems="center" flexWrap="wrap" mt="4">
            <Text color="gray.500" fontSize="16px" lineHeight="24px" mr={2}>
              Não recebeu o link de confirmação?
            </Text>

            <HStack space={1} alignItems="center">
              <Button
                onPress={handleResendEmail}
                variant="link"
                p={0}
                h={5}
                _text={{
                  underline: true,
                  color: 'tertiary.800',
                  fontSize: {
                    base: '14px',
                    lg: '16'
                  },
                  lineHeight: {
                    lg: '24px'
                  },
                  fontWeight: {
                    base: 400,
                    lg: 700
                  }
                }}>
                Reenviar
              </Button>
            </HStack>
          </HStack>
        </HStack>

        <Text
          fontSize="16px"
          lineHeight="24px"
          fontWeight={400}
          color="gray.500"
          mt={4}
          textAlign={{
            base: 'center',
            lg: 'left'
          }}>
          Caso não tenha recebido o e-mail, certifique-se de que o e-mail informado está correto e
          verifique a sua caixa de lixo eletrônico ou spam.
        </Text>

        {isDesktop && (
          <Box mt="49px">
            <ButtonZendesk isDesktop />
          </Box>
        )}
      </LayoutPlatform>
      {!isDesktop && <ButtonZendesk />}
    </>
  )
}
