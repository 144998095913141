import { HighlightedFeatures, HighlightedFeaturesItem, Menu } from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

if (!process.env.PLATFORM_ID) {
  throw new Error('PLATFORM_ID variable missing .env')
}

export const getMenus = () =>
  client.get<ResponseSuccess<Menu[]>>(`v1/menu/platform/${process.env.PLATFORM_ID}`)

export const getHighlightedFeatures = async () =>
  await client.get<ResponseSuccess<HighlightedFeatures>>(`v1/highlighted-features`)

export const removeHighlightedFeature = async ({ id }: Pick<HighlightedFeaturesItem, 'id'>) =>
  await client.post<ResponseSuccess<Pick<HighlightedFeaturesItem, 'id'>>>(
    `v1/highlighted-features`,
    { id }
  )
