import { UseCharges } from './Charges.types'

export const useCharges: UseCharges = ({
  opportunityDetails,
  isLoading,
  isMobile,
  handleOpenChargeListModal,
  chargeListModalIsOpen,
  handleCloseChargeListModal
}) => {
  const chargesData = opportunityDetails?.charges

  const sliderDots = chargesData?.slice(0, 3)

  return {
    opportunityDetails,
    isLoading,
    isMobile,
    handleOpenChargeListModal,
    chargeListModalIsOpen,
    handleCloseChargeListModal,
    chargesData,
    sliderDots
  }
}
