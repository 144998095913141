import { useCallback, useMemo } from 'react'

import { useGetOpportunityDetailsOdcItemQuery } from 'integration/resources/newOpportunities'
import { useBreakpointValue } from 'native-base'

import { UseRecommendationsScreen } from './RecommendationsScreen.types'

export const useRecommendationsScreen: UseRecommendationsScreen = ({ navigation, route }) => {
  const opportunityId = route.params.opportunityId

  const { data: opportunityData, isLoading } = useGetOpportunityDetailsOdcItemQuery(opportunityId)

  const recommendationItem = useMemo(
    () => opportunityData?.data.data.recommendations,
    [opportunityData?.data.data.recommendations]
  )

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  return {
    isMobile,
    handleGoBack,
    infos: recommendationItem?.items?.filter((item) => !!item.value),
    isLoading
  }
}
