import { FunctionComponent } from 'react'

import { useBreakpointValue } from 'native-base'
import { TabPanels, TabPanelsDesktop } from 'organisms'

import { TabCommonProps } from './types'

export const Panels: FunctionComponent<TabCommonProps> = ({
  tabNumber,
  handleGoToFullChartScreen
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  if (!tabNumber || !handleGoToFullChartScreen) return null

  if (!isMobile)
    return (
      <TabPanelsDesktop
        tabNumber={tabNumber}
        handleGoToFullChartScreen={handleGoToFullChartScreen}
      />
    )

  return <TabPanels tabNumber={tabNumber} handleGoToFullChartScreen={handleGoToFullChartScreen} />
}
