import { createIcon } from 'native-base'
import { Path } from 'react-native-svg'

export const GroupIcon = createIcon({
  viewBox: '0 0 18 19',
  path: [
    <Path
      key={Math.random()}
      d="M3.6 8.169a.75.75 0 0 1 .75-.75h3.6a.75.75 0 1 1 0 1.5h-3.6a.75.75 0 0 1-.75-.75ZM4.35 11.019a.75.75 0 0 0 0 1.5h3.6a.75.75 0 0 0 0-1.5h-3.6Z"
      fill="currentColor"
    />,
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.63.393a.75.75 0 0 0-.96 0l-5.4 4.5a.75.75 0 0 0-.27.576v11.7c0 .414.336.75.75.75H16.95a.75.75 0 0 0 .75-.75v-9a.75.75 0 0 0-.75-.75H12.3v-1.95a.75.75 0 0 0-.27-.576l-5.4-4.5Zm5.67 16.026v-7.5h3.9v7.5h-3.9Zm-1.5-8.26V5.82L6.15 1.945 1.5 5.82v10.599h9.3v-8.26Z"
      fill="currentColor"
    />
  ]
})
