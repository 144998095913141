import { FunctionComponent, useEffect, useRef } from 'react'

import { Icons } from 'atoms'
import LottieView from 'lottie-react-native'
import { Center, HStack, Text, VStack } from 'native-base'

type TUnconnectedMessage = {
  inline?: boolean
  noAnimation?: boolean
}

export const UnconnectedMessage: FunctionComponent<TUnconnectedMessage> = ({
  inline = false,
  noAnimation = false
}) => {
  const animation = useRef(null)

  useEffect(() => {
    async function prepare() {
      try {
        // @ts-ignore
        animation.current?.play()
      } catch (error) {
        console.warn(error)
      }
    }

    if (!noAnimation) {
      prepare()
    }
  }, [noAnimation])

  return inline ? (
    <HStack
      borderWidth="1px"
      borderColor="white"
      borderRadius="20px"
      space="10px"
      alignItems="center"
      m={4}
      px={4}
      py={5}>
      <Icons.Alert size={25} />
      <Text color="white" fontSize="14px">
        Estamos aguardando o recebimento das bases para atualizar esta tela.
      </Text>
    </HStack>
  ) : (
    <VStack space={0} m="auto">
      <Center>
        <HStack maxW="260px">
          <LottieView
            ref={animation}
            loop
            style={{
              flex: 1,
              height: 260
            }}
            source={require('assets/not_connected_turbo.json')}
          />
        </HStack>
      </Center>

      <VStack space={2} alignItems="center" maxW={{ base: '245px', lg: '350px' }}>
        <Text
          color="gray.500"
          fontSize={{ base: '14px', lg: '18px' }}
          lineHeight={{ base: '16px', lg: '24px' }}
          fontWeight="bold">
          Sem Dados
        </Text>

        <Text
          color="gray.300"
          fontSize={{ base: '14px', lg: '16px' }}
          lineHeight={{ base: '16px', lg: '21px' }}
          textAlign="center">
          Estamos aguardando o recebimento das bases para atualizar os dados.
        </Text>
      </VStack>
    </VStack>
  )
}
