import { createIcon } from 'native-base'
import { Path } from 'react-native-svg'

export const IntegrationIcon = createIcon({
  viewBox: '0 0 18 18',
  path: [
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4547 0.223393C10.7526 0.521251 10.7526 1.00417 10.4547 1.30203L1.30205 10.4546C1.00419 10.7524 0.521259 10.7524 0.223397 10.4546C-0.0744656 10.1567 -0.0744656 9.6738 0.223397 9.37595L9.37609 0.223393C9.67395 -0.0744645 10.1569 -0.0744645 10.4547 0.223393Z"
      fill="currentColor"
    />,
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.66093 0.762713C3.66093 0.341478 4.00241 1.13653e-08 4.42365 1.13653e-08H9.91527C10.3365 1.13653e-08 10.678 0.341478 10.678 0.762713V6.25424C10.678 6.67548 10.3365 7.01696 9.91527 7.01696C9.49403 7.01696 9.15254 6.67548 9.15254 6.25424V1.52543H4.42365C4.00241 1.52543 3.66093 1.18395 3.66093 0.762713Z"
      fill="currentColor"
    />,
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7766 7.54542C18.0745 7.84327 18.0745 8.3262 17.7766 8.62405L8.62391 17.7766C8.32605 18.0745 7.84312 18.0745 7.54525 17.7766C7.24739 17.4787 7.24739 16.9958 7.54525 16.698L16.6979 7.54542C16.9958 7.24756 17.4787 7.24756 17.7766 7.54542Z"
      fill="currentColor"
    />,
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.08458 10.983C8.50582 10.983 8.84731 11.3245 8.84731 11.7457V16.4745H13.5762C13.9974 16.4745 14.3389 16.816 14.3389 17.2372C14.3389 17.6585 13.9974 17.9999 13.5762 17.9999H8.08458C7.66334 17.9999 7.32186 17.6585 7.32186 17.2372V11.7457C7.32186 11.3245 7.66334 10.983 8.08458 10.983Z"
      fill="currentColor"
    />
  ]
})
