import Animated, { FadeIn } from 'react-native-reanimated'

type TFadeInView = {
  children: JSX.Element
  duration?: number
  delay?: number
}

export const FadeInView = ({ children, duration = 1000, delay = 0 }: TFadeInView) => (
  <Animated.View entering={FadeIn.delay(delay).duration(duration)}>{children}</Animated.View>
)
