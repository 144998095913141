import { ReactNode, forwardRef, useCallback, useImperativeHandle, useRef } from 'react'

import { BottomSheetModal, BottomSheetProps } from '@gorhom/bottom-sheet'
import CustomBottomSheetBackdrop from 'molecules/CustomBottomSheetBackdrop'
import { Modal, useBreakpointValue, useDisclose } from 'native-base'

type TCustomBottomSheetProps = BottomSheetProps & {
  children: ReactNode
  enableTapToClose?: boolean
}

const CustomBottomSheet = forwardRef<BottomSheetModal, TCustomBottomSheetProps>(
  ({ children, snapPoints = ['65%'], enableTapToClose = true, ...props }, ref) => {
    const { isOpen, onOpen: present, onClose: close } = useDisclose(false)

    const renderBackdrop = useCallback(
      (props: any) => (
        <CustomBottomSheetBackdrop
          enableTapToClose={enableTapToClose}
          {...props}
          disappearsOnIndex={1}
          appearsOnIndex={2}
          ref={ref}
        />
      ),
      [ref, enableTapToClose]
    )

    const isMobile = useBreakpointValue({ base: true, lg: false })

    const BottomSheetModalRef = useRef<BottomSheetModal>(null)

    // @ts-ignore
    useImperativeHandle(ref, () => ({
      present: () => (isMobile ? BottomSheetModalRef.current?.present() : present()),
      close: () =>
        isMobile
          ? [BottomSheetModalRef.current?.forceClose(), BottomSheetModalRef.current?.dismiss()]
          : close(),
      dismiss: () => (isMobile ? BottomSheetModalRef.current?.dismiss() : {})
    }))

    return isMobile ? (
      <BottomSheetModal
        ref={BottomSheetModalRef}
        snapPoints={snapPoints}
        enablePanDownToClose
        enableOverDrag
        detached={!isMobile}
        enableDismissOnClose
        style={{
          borderRadius: 24,
          overflow: 'hidden'
        }}
        backdropComponent={renderBackdrop}
        {...props}>
        {children}
      </BottomSheetModal>
    ) : (
      <Modal isOpen={isOpen} onClose={close}>
        <Modal.Content minW="642px" borderRadius="20px" py="64px" px={8} bgColor="white">
          <Modal.Body>{children}</Modal.Body>
        </Modal.Content>
      </Modal>
    )
  }
)

export { BottomSheetModal, CustomBottomSheet }
