import { Opportunity } from 'integration/resources/opportunities'
import { Store } from 'integration/resources/store'
import { Attachment } from 'molecules/Fields/AttachmentsField/types'
import { CheckItem } from 'organisms/Checklist/types'
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import fieldsValidation from 'src/constants/fields-validation'
import * as yup from 'yup'

export const activityFormSchema = yup.object().shape({
  title: yup
    .string()
    .required(fieldsValidation.common.required)
    .max(100, 'O título pode ter no máximo 100 caracteres'),
  description: yup
    .string()
    .nullable()
    .max(80, 'O detalhe pode ter no máximo 80 caracteres')
    .min(3, 'O detalhe precisa ter no mínimo 3 caracteres'),
  is_finished: yup.string(),
  finished_at: yup.string().nullable(),
  schedule_date: yup.string().nullable(),
  schedule_date_start: yup.string().nullable(),
  schedule_date_end: yup.string(),
  is_all_day_schedule: yup.boolean().nullable(),
  activity_type: yup.object().shape({
    id: yup.string().required(fieldsValidation.common.required)
  }),
  store: yup
    .object()
    .shape({
      id: yup.string()
    })
    .nullable(),
  opportunity: yup
    .object()
    .shape({
      id: yup.string()
    })
    .nullable(),
  created_by_user_std_code: yup.string().nullable(),
  created_at: yup.string(),
  activity_plan_action_items: yup.array(
    yup
      .object()
      .shape({
        id: yup.string(),
        value: yup.string(),
        checked: yup.boolean()
      })
      .nullable()
  ),
  activity_attachments: yup
    .array(
      yup.object().shape({
        name: yup.string(),
        file_name: yup.string()
      })
    )
    .nullable()
})

export interface ActivityForm {
  id: string
  title: string
  description: string
  is_finished: boolean
  finished_at: string
  schedule_date?: string
  schedule_date_start?: string
  schedule_date_end?: string
  is_all_day_schedule: boolean
  activity_type: {
    id: string
    identifier: number
    name: string
  }
  store: Store
  opportunity: Opportunity | null
  created_by_user_std_code: number
  created_at: string
  activity_plan_action_items: CheckItem[]
  related_activities: []
  activity_attachments: Attachment[]
}

export interface ActivityFormTemplateProps {
  control: Control<ActivityForm>
  watch: UseFormWatch<ActivityForm>
  setValue: UseFormSetValue<ActivityForm>
  handleAddItemActionPlan: (value: string) => void
  handleUpdateItemActionPlan: (item: CheckItem, status: boolean) => void
  handleRemoveItemActionPlan: (item: CheckItem) => void
  isEdit?: boolean
}
