import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  HandleFinishParams,
  KEY_OPPORTUNITIES_FINISHED_LIST,
  OpportunitiesItem,
  OpportunityType,
  useGetOpportunitiesFinishedListInfiniteQuery,
  useSetOpportunityActiveBaseHighlightedMutation,
  useSetOpportunityActiveBaseUnHighlightedMutation,
  useSetOpportunityOdcHighlightedMutation,
  useSetOpportunityOdcUnHighlightedMutation,
  useSetUnFinishedOpportunityActiveBaseMutation,
  useSetUnFinishedOpportunityOdcMutation
} from 'integration/resources/newOpportunities'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { useQueryClient } from 'react-query'
import { ListType } from 'src/screens/OrganizationScreen/OrganizationScreen.types'
import { calculateActiveFilterCount } from 'src/utils/filters'
import { LogEvent } from 'src/utils/logEvents'

import { UseConcluded } from './types'

const mergeAllDataInfiniteQuery = (
  data: ReturnType<typeof useGetOpportunitiesFinishedListInfiniteQuery>['data']
): OpportunitiesItem[] => {
  return (
    data?.pages?.reduce<OpportunitiesItem[]>((previousValue, currentValue) => {
      return [...previousValue, ...(currentValue.data.data || [])]
    }, []) ?? []
  )
}

export const useConcluded: UseConcluded = ({ route, search, navigation }) => {
  const allFilters = useMemo(() => {
    return {
      segment: route?.params?.segment,
      network: route?.params?.network,
      subsidiary: route?.params?.subsidiary,
      opportunity_type: route?.params?.opportunity_type,
      user_std_code: route?.params?.user_std_code
    }
  }, [route?.params])

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch: refetchHook
  } = useGetOpportunitiesFinishedListInfiniteQuery(
    {
      per_page: 10,
      search: search ?? undefined,
      segment: allFilters.segment,
      network: allFilters.network,
      subsidiary: allFilters.subsidiary,
      opportunity_type: allFilters.opportunity_type,
      user_std_code: allFilters.user_std_code
    },
    undefined
  )

  const list = useMemo(() => mergeAllDataInfiniteQuery(data), [data])

  const totalItems = data?.pages[0].data.metadata.pagination.total ?? 0

  const fetchMore = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const [refetchLoading, setRefetchLoading] = useState(false)

  const queryClient = useQueryClient()

  const refetch = useCallback(() => {
    setRefetchLoading(true)

    setTimeout(() => {
      setRefetchLoading(false)
    }, 300)

    queryClient.invalidateQueries(KEY_OPPORTUNITIES_FINISHED_LIST)
  }, [queryClient])

  const isEmpty = useMemo(() => !list?.length, [list])

  const highlights = list.filter((item) => item.highlighted)

  const highlightsIsEmpty = !highlights.length

  const handleGoToDetails = (opportunityId: string, itemType?: OpportunityType) => {
    LogEvent(`oportunidades_concluidas_acessar_detalhes`, {
      item_id: opportunityId
    })

    navigation.navigate('OpportunityDetails', {
      id: opportunityId,
      type: itemType
    })
  }

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const toast = useToast()

  const { mutateAsync: setOpportunityOdcHighlighted } = useSetOpportunityOdcHighlightedMutation()

  const { mutateAsync: setOpportunityOdcUnHighlighted } =
    useSetOpportunityOdcUnHighlightedMutation()

  const { mutateAsync: setOpportunityActiveBaseHighlighted } =
    useSetOpportunityActiveBaseHighlightedMutation()

  const { mutateAsync: setOpportunityActiveBaseUnHighlighted } =
    useSetOpportunityActiveBaseUnHighlightedMutation()

  const [opportunityHighlightIsLoading, setOpportunityHighlightIsLoading] = useState('')

  const handleHighlight = (
    id: string,
    highlighted: boolean,
    opportunity_type_enum: OpportunityType
  ) => {
    setOpportunityHighlightIsLoading(id)

    LogEvent(`oportunidades_concluidas_${highlighted ? 'desfazer_' : ''}destacar`, {
      item_id: id
    })

    const isActiveBase = opportunity_type_enum === OpportunityType.ACTIVE_BASE

    const highlightedMutate = isActiveBase
      ? setOpportunityActiveBaseHighlighted
      : setOpportunityOdcHighlighted

    const unHighlightedMutate = isActiveBase
      ? setOpportunityActiveBaseUnHighlighted
      : setOpportunityOdcUnHighlighted

    return (
      id &&
      (highlighted ? unHighlightedMutate : highlightedMutate)(
        {
          id
        },
        {
          onError: () => {
            toast.show({
              render: () => <Toast type="error" text="Tente novamente mais tarde" />,
              duration: 3000
            })

            setOpportunityHighlightIsLoading('')
          },
          onSuccess: () => {
            refetch()

            setOpportunityHighlightIsLoading('')
          }
        }
      )
    )
  }

  const [opportunityConcludeIsLoading, setOpportunityConcludeIsLoading] = useState('')

  const { mutateAsync: setOpportunityOdcUnFinished } = useSetUnFinishedOpportunityOdcMutation()

  const { mutateAsync: setOpportunityActiveBaseUnFinished } =
    useSetUnFinishedOpportunityActiveBaseMutation()

  const handleConclude = (data: HandleFinishParams) => {
    const { id, comment, insight, only_finish, score, opportunity_type_enum } = data

    LogEvent(`oportunidades_concluidas_desfazer_concluir`, {
      item_id: id
    })

    setOpportunityConcludeIsLoading(id)

    const isActiveBase = opportunity_type_enum === OpportunityType.ACTIVE_BASE

    return (
      id &&
      (isActiveBase ? setOpportunityActiveBaseUnFinished : setOpportunityOdcUnFinished)(
        {
          id,
          only_finish,
          comment,
          insight,
          score
        },
        {
          onError: () => {
            toast.show({
              render: () => <Toast type="error" text="Tente novamente mais tarde" />,
              duration: 3000
            })

            setOpportunityConcludeIsLoading('')
          },
          onSuccess: () => {
            refetch()

            setOpportunityConcludeIsLoading('')
          }
        }
      )
    )
  }

  const activeFilterCount = calculateActiveFilterCount(allFilters)

  const handleGoToFilters = () => {
    LogEvent(`oportunidades_concluidas_acessar_filtros`, {
      item_id: ''
    })

    navigation.navigate('OpportunityFilter', {
      listType: ListType.Concluded,
      offer: route?.params?.offer,
      public_rating: route?.params?.public_rating,
      segment: route?.params?.segment,
      network: route?.params?.network,
      subsidiary: route?.params?.subsidiary,
      opportunity_type: route?.params?.opportunity_type,
      user_std_code: route?.params?.user_std_code
    })
  }

  useEffect(() => {
    refetchHook()
  }, [search, refetchHook])

  return {
    opportunitiesList: list,
    isLoading,
    fetchMore,
    isFetchingNextPage,
    refetch,
    refreshing: refetchLoading,
    isEmpty,
    highlights,
    highlightsIsEmpty,
    handleGoToDetails,
    isMobile,
    handleHighlight,
    highlightIsLoading: opportunityHighlightIsLoading,
    handleConclude,
    concludeIsLoading: opportunityConcludeIsLoading,
    totalItems,
    handleGoToFilters,
    activeFilterCount
  }
}
