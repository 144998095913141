import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import {
  NoteEditDetailsContactScreenProps,
  NoteEditDetailsScreenComponent,
  NoteEditDetailsTabScreenProps
} from './NoteEditDetailsScreen.types'
import { useNoteEditDetailsScreen } from './useNoteEditDetailsScreen'

const NoteEditDetailsScreenDesktop = lazy(() => import('./NoteEditDetailsScreen.desktop'))

const NoteEditDetailsScreenMobile = lazy(() => import('./NoteEditDetailsScreen.mobile'))

export const NoteEditDetailsScreen = <
  T extends NoteEditDetailsTabScreenProps | NoteEditDetailsContactScreenProps
>({
  navigation,
  route
}: NoteEditDetailsScreenComponent<T>) => {
  // @ts-ignore
  const noteEditDetailsScreen = useNoteEditDetailsScreen({ navigation, route })

  const Screen = noteEditDetailsScreen.isMobile
    ? NoteEditDetailsScreenMobile
    : NoteEditDetailsScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...noteEditDetailsScreen} />
    </Suspense>
  )
}

export const NoteEditDetailsTabScreen = NoteEditDetailsScreen<NoteEditDetailsTabScreenProps>

export const NoteEditDetailsContactScreen = NoteEditDetailsScreen<NoteEditDetailsContactScreenProps>
