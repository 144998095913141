import { FunctionComponent } from 'react'

import { Box, HStack, VStack, Text } from 'native-base'
import { AcceleratorsRulerData } from 'src/screens/SummaryDetailScreen/SummaryDetailScreen.types'

type RulerProps = {
  rulerData: AcceleratorsRulerData
  activeValue: number
}

type RulerItemProps = {
  item: AcceleratorsRulerData[number]
  isHighlighted: boolean
}

const RulerItem: FunctionComponent<RulerItemProps> = ({ item, isHighlighted }) => {
  const baseFontSize = '12px'

  const lgFontSize = '14px'

  const fontWeightBase = 400

  const fontWeightLg = 600

  const lineHeightBase = '16px'

  const lineHeightLg = '20px'

  const colorBase = isHighlighted ? 'secondary.500' : 'gray.200'

  const colorLg = isHighlighted ? 'secondary.500' : 'gray.400'

  return (
    <VStack
      flex={1}
      space={{ base: isHighlighted ? '6px' : '8px', lg: isHighlighted ? '12px' : '16px' }}
      alignItems="center">
      <Text
        fontSize={{ base: baseFontSize, lg: lgFontSize }}
        fontWeight={{ base: fontWeightBase, lg: fontWeightLg }}
        lineHeight={{ base: lineHeightBase, lg: lineHeightLg }}
        color={{ base: colorBase, lg: colorLg }}>
        {item.title}
      </Text>
      <Box
        w="full"
        bgColor={isHighlighted ? 'secondary.500' : 'transparent'}
        borderRadius="8px"
        h={isHighlighted ? 2 : 1}
      />
      <VStack alignItems="center" space="2px">
        <Text
          fontSize={{ base: baseFontSize, lg: lgFontSize }}
          fontWeight={{ base: 700, lg: fontWeightLg }}
          lineHeight={{ base: lineHeightBase, lg: lineHeightLg }}
          color={{ base: colorBase, lg: colorLg }}>
          {`${item.value > 0 ? '+' : ''}${item.value} p.p.`}
        </Text>
        <Text fontSize="12px" fontWeight={fontWeightBase} color={{ base: colorBase, lg: colorLg }}>
          {item.reference}
        </Text>
      </VStack>
    </VStack>
  )
}

export const Ruler: FunctionComponent<RulerProps> = ({ activeValue, rulerData }) => {
  return (
    <Box flex={1} position="relative">
      <Box
        w="full"
        bgColor="gray.100"
        borderRadius="8px"
        h={1}
        position="absolute"
        top={{ base: '34%', lg: '36%' }}
      />
      <HStack w="100%">
        {rulerData.map((item, index) => (
          <RulerItem key={index} item={item} isHighlighted={activeValue === item.value} />
        ))}
      </HStack>
    </Box>
  )
}
