import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { LoginAccountReviewScreenComponent } from './LoginAccountReviewScreen.types'
import { useLoginAccountReviewScreen } from './useLoginAccountReviewScreen'

const LoginBlockedScreenDesktop = lazy(() => import('./LoginAccountReviewScreen.desktop'))

const LoginBlockedScreenMobile = lazy(() => import('./LoginAccountReviewScreen.mobile'))

export const LoginAccountReviewScreen: LoginAccountReviewScreenComponent = ({ navigation }) => {
  const loginBlockedScreen = useLoginAccountReviewScreen({ navigation })

  const Screen = loginBlockedScreen.isMobile ? LoginBlockedScreenMobile : LoginBlockedScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...loginBlockedScreen} />
    </Suspense>
  )
}
