import { AxiosError, AxiosResponse } from 'axios'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'
import { sanitizeObjectToNotNull } from 'src/utils'

import * as requests from './requests'
import {
  Attachment,
  AttachmentList,
  ClassificationTAB,
  Contact,
  ContactSerialized,
  CreateAttachmentParams,
  DeleteAttachmentParams,
  EcosystemTAB,
  GetAttachmentListParams,
  GetListsParams,
  GetNoteListParams,
  Note,
  ProductToUpdate,
  RegulatoryTAB,
  SummaryChartDataSerialized,
  TContract,
  TContractParams,
  TabDetail,
  WalletItem,
  WalletSummary
} from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_CONTACT_LIST_QUERY = 'getContactList'

export const KEY_CONTACT_ITEM_QUERY = 'getContactItem'

export const KEY_TAB_ITEM_QUERY = 'getTabItem'

export const KEY_CONTACT_LIST_BIRTHDAY_QUERY = 'getContactBirthday'

export const KEY_TAB_LIST_QUERY = 'getTabList'

export const KEY_NOTE_LIST_QUERY = 'getNoteList'

export const KEY_NOTE_ITEM_QUERY = 'getNoteItem'

export const KEY_ATTACHMENT_LIST_QUERY = 'getAttachmentList'

export const KEY_ECOSYSTEM_BY_TAB_NUMBER_QUERY = 'getEcosystemByTabNumber'

export const KEY_TAB_NOTE_LIST_QUERY = 'getTabNoteList'

export const KEY_SUMARY_BY_TAB_NUMBER_QUERY = 'getSumaryByTabNumber'

export const KEY_REGULATORY_BY_TAB_NUMBER_QUERY = 'getRegulatoryByTabNumber'

export const KEY_GET_WALLET_SUMMARY_QUERY = 'getWalletSumary'

export const KEY_GET_CONTRACTS_BY_TAB_NUMBER = 'useContractsByTabNumberQuery'

export const useGetContactItemlQuery = (contactId: string) =>
  useQuery<AxiosResponse<ResponseSuccess<ContactSerialized>>, AxiosError<ResponseError>>(
    [`${KEY_CONTACT_ITEM_QUERY}.${contactId}`],
    () => requests.getContactItem(contactId)
  )

export const useGetTabItemQuery = (tabNumber: string) =>
  useQuery<AxiosResponse<ResponseSuccess<TabDetail>>, AxiosError<ResponseError>>(
    [`${KEY_TAB_ITEM_QUERY}.${tabNumber}`],
    () => requests.getTabItem(tabNumber)
  )

export const useGetContactBirthdayItemQuery = (contactId: string) =>
  useQuery<AxiosResponse<ResponseSuccess<Contact>>, AxiosError<ResponseError>>(
    [`${KEY_CONTACT_LIST_BIRTHDAY_QUERY}.${contactId}`],
    () => requests.getContactBirthday(contactId)
  )

export const useEditContactBirthdayMutation = (contactId: string) => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError<ResponseError>, Contact>(
    (birthday) => requests.editContactBirthday(contactId, birthday),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`${KEY_CONTACT_LIST_BIRTHDAY_QUERY}.${contactId}`])
      }
    }
  )
}

export const useGetContactListInfiniteQuery = (params: GetListsParams) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<WalletItem[]>>, AxiosError<ResponseError>>(
    [KEY_CONTACT_LIST_QUERY, params],
    ({ pageParam }) => requests.getContactList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )

export const useGetTabListInfiniteQuery = (params: GetListsParams) => {
  const paramsParse = sanitizeObjectToNotNull(params)

  return useInfiniteQuery<AxiosResponse<ResponseSuccess<WalletItem[]>>, AxiosError<ResponseError>>(
    [KEY_TAB_LIST_QUERY, params],
    ({ pageParam }) => requests.getTabList({ ...paramsParse, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )
}

export const useGetNoteListInfiniteQuery = (params: GetNoteListParams) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<Note[]>>, AxiosError<ResponseError>>(
    [KEY_NOTE_LIST_QUERY, Object.values(params ?? {})],
    ({ pageParam }) => requests.getNoteList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )

export const useGetNoteItemQuery = (noteId: string, key: 'contacts' | 'stores') =>
  useQuery<AxiosResponse<ResponseSuccess<Note>>, AxiosError<ResponseError>>(
    [KEY_NOTE_ITEM_QUERY, noteId],
    () => requests.getNoteItem(noteId, key)
  )

export const useCreateNoteMutation = (key: 'contacts' | 'stores') => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<ResponseSuccess<Note>>, AxiosError<ResponseError>, Note>(
    (note: Note) => requests.createNote(note, key),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_NOTE_LIST_QUERY])

        queryClient.invalidateQueries([KEY_TAB_NOTE_LIST_QUERY])
      }
    }
  )
}

export const useEditNoteMutation = (key: 'contacts' | 'stores') => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<ResponseSuccess<Note>>, AxiosError<ResponseError>, Note>(
    (note: Note) => requests.editNote(note, key),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_NOTE_LIST_QUERY])

        queryClient.invalidateQueries([KEY_TAB_NOTE_LIST_QUERY])
      }
    }
  )
}

export const useGetRegulatoryByTabNumberQuery = (tabNumber: string) =>
  useQuery<AxiosResponse<ResponseSuccess<RegulatoryTAB>>, AxiosError<ResponseError>>(
    [`${KEY_REGULATORY_BY_TAB_NUMBER_QUERY}.${tabNumber}`],
    () => requests.getRegulatoryByTabNumber(tabNumber)
  )

export const useGetEcosystemByTabNumberQuery = (tabNumber: string) =>
  useQuery<AxiosResponse<ResponseSuccess<EcosystemTAB>>, AxiosError<ResponseError>>(
    [`${KEY_ECOSYSTEM_BY_TAB_NUMBER_QUERY}.${tabNumber}`],
    () => requests.getEcosystemByTabNumber(tabNumber)
  )

export const useFavoriteContactMutation = (contactId?: string) => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError<ResponseError>, Pick<ContactSerialized, 'id'>>(
    requests.favoriteContact,
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries([KEY_CONTACT_LIST_QUERY])

        queryClient.invalidateQueries([`${KEY_CONTACT_ITEM_QUERY}.${id}`])
      }
    }
  )
}

export const useUnfavoriteContactMutation = (contactId?: string) => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError<ResponseError>, Pick<ContactSerialized, 'id'>>(
    requests.unfavoriteContact,
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries([KEY_CONTACT_LIST_QUERY, KEY_CONTACT_LIST_QUERY])

        queryClient.invalidateQueries([`${KEY_CONTACT_ITEM_QUERY}.${id}`])
      }
    }
  )
}

export const useFavoriteTabMutation = (_?: string) => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError<ResponseError>, Partial<TabDetail>>(
    requests.favoriteTab,
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(`${KEY_TAB_ITEM_QUERY}.${id}`)

        queryClient.resetQueries(`${KEY_TAB_ITEM_QUERY}.${id}`)

        queryClient.invalidateQueries(KEY_TAB_LIST_QUERY)

        queryClient.resetQueries(KEY_TAB_LIST_QUERY)
      }
    }
  )
}

export const useUnfavoriteTabMutation = (_identifier?: string) => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError<ResponseError>, Partial<TabDetail>>(
    requests.unfavoriteTab,
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(`${KEY_TAB_ITEM_QUERY}.${id}`)

        queryClient.resetQueries(`${KEY_TAB_ITEM_QUERY}.${id}`)

        queryClient.invalidateQueries(KEY_TAB_LIST_QUERY)

        queryClient.resetQueries(KEY_TAB_LIST_QUERY)
      }
    }
  )
}

export const useGetSummaryByTabNumberQuery = (tabNumber: string) =>
  useQuery<AxiosResponse<ResponseSuccess<SummaryChartDataSerialized>>, AxiosError<ResponseError>>(
    [KEY_SUMARY_BY_TAB_NUMBER_QUERY, tabNumber],
    () => requests.getSummaryByTabNumber(tabNumber)
  )

export const useGetClassificationByTabNumberQuery = (tabNumber: string) =>
  useQuery<AxiosResponse<ResponseSuccess<ClassificationTAB>>, AxiosError<ResponseError>>(
    [`getClassificationByTabNumber.${tabNumber}`],
    () => requests.getClassificationByTabNumber(tabNumber)
  )

export const useGetTabNoteListInfiniteQuery = (params: GetNoteListParams) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<Note[]>>, AxiosError<ResponseError>>(
    [KEY_TAB_NOTE_LIST_QUERY, Object.values(params ?? {})],
    ({ pageParam }) => requests.getTabNoteList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )

export const useCreateContactMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<ResponseSuccess<Contact>>, AxiosError<ResponseError>, Contact>(
    requests.createContactItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_CONTACT_LIST_QUERY])
      }
    }
  )
}

export const useEditContactMutation = (contactId: string) => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<ResponseSuccess<Contact>>, AxiosError<ResponseError>, Contact>(
    requests.editContactItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`${KEY_CONTACT_ITEM_QUERY}.${contactId}`])

        queryClient.invalidateQueries([KEY_CONTACT_LIST_QUERY])
      }
    }
  )
}

export const useEditTabMutation = (tabNumber: string) => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<TabDetail>>,
    AxiosError<ResponseError>,
    TabDetail
  >(requests.editTabItem, {
    onSuccess: () => {
      queryClient.invalidateQueries([`${KEY_TAB_ITEM_QUERY}.${tabNumber}`])

      queryClient.invalidateQueries(KEY_TAB_LIST_QUERY)
    }
  })
}

export const useGetAttachmentListInfiniteQuery = (params: GetAttachmentListParams) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<AttachmentList[]>>, AxiosError<ResponseError>>(
    [KEY_ATTACHMENT_LIST_QUERY, Object.values(params ?? {})],
    ({ pageParam }) => requests.getAttachmentList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )

export const useDeleteAttachmentMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError<ResponseError>, DeleteAttachmentParams>(
    requests.deleteAttachment,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_ATTACHMENT_LIST_QUERY])
      }
    }
  )
}

export const useCreateAttachmentMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Attachment>>,
    AxiosError<ResponseError>,
    CreateAttachmentParams
  >(requests.createAttachment, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_ATTACHMENT_LIST_QUERY])
    }
  })
}

export const useDeleteContactMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<Contact, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<Contact, 'id'>
  >(requests.deleteContactItem, {
    onSuccess: () => {
      queryClient.invalidateQueries(KEY_CONTACT_LIST_QUERY)
    }
  })
}

export const useEditTabStatusMutation = (tabNumber: string, product: ProductToUpdate) => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError<ResponseError>, ProductToUpdate>(
    () => requests.editTabStatus(tabNumber, product),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`${KEY_ECOSYSTEM_BY_TAB_NUMBER_QUERY}.${tabNumber}`)
      }
    }
  )
}

export const useGetWalletSumaryQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<WalletSummary>>, AxiosError<ResponseError>>(
    [KEY_GET_WALLET_SUMMARY_QUERY],
    () => requests.getWalletSumary()
  )

export const useDeleteNoteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError<ResponseError>, DeleteAttachmentParams>(
    requests.deleteNote,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_NOTE_LIST_QUERY])
      }
    }
  )
}

export const useContractsByTabNumberQuery = ({ tabNumber, yearMonth }: TContractParams) =>
  useQuery<AxiosResponse<ResponseSuccess<TContract>>, AxiosError<ResponseError>>(
    [`${KEY_GET_CONTRACTS_BY_TAB_NUMBER}.${tabNumber}.${yearMonth}`],
    () => requests.getContractsByTabNumber({ tabNumber, yearMonth })
  )

export type TNoteParam = { id: string }

export const useDeleteNoteContact = () => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError<ResponseError>, TNoteParam>(
    requests.deleteContactNote,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_NOTE_LIST_QUERY])
      }
    }
  )
}

export const useDeleteNoteStore = () => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<ResponseSuccess<any>>, AxiosError<ResponseError>, TNoteParam>(
    requests.deleteStoreNote,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEY_TAB_NOTE_LIST_QUERY])
      }
    }
  )
}
