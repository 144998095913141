import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { PageNotFoundScreenComponent } from './PageNotFoundScreen.types'
import { usePageNotFoundScreen } from './usePageNotFoundScreen'

const PageNotFoundScreenDesktop = lazy(() => import('./PageNotFoundScreen.desktop'))

const PageNotFoundScreenMobile = lazy(() => import('./PageNotFoundScreen.mobile'))

export const PageNotFoundScreen: PageNotFoundScreenComponent = ({ navigation }) => {
  const props = usePageNotFoundScreen({ navigation })

  const Screen = props.isMobile ? PageNotFoundScreenMobile : PageNotFoundScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
