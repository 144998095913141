import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ContactScreenComponent } from './ContactScreen.types'
import { useContactScreen } from './useContactScreen'

const ContactScreenDesktop = lazy(() => import('./ContactScreen.desktop'))

const ContactScreenMobile = lazy(() => import('./ContactScreen.mobile'))

export const ContactScreen: ContactScreenComponent = ({ navigation, route }) => {
  const contactScreen = useContactScreen({ navigation, route })

  const Screen = contactScreen.isMobile ? ContactScreenMobile : ContactScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...contactScreen} />
    </Suspense>
  )
}
