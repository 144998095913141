import { UsePolicies } from './Policies.types'

export const usePolicies: UsePolicies = ({ opportunityDetails, isMobile, isLoading }) => {
  const updatedAt =
    opportunityDetails?.production?.date_ref ??
    opportunityDetails?.spf?.date_ref ??
    opportunityDetails?.insurance_auto?.date_ref

  return {
    opportunityDetails,
    isLoading,
    isMobile,
    updatedAt
  }
}
