import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { TabEditScreenComponent } from './TabEditScreen.types'
import { useTabEditScreen } from './useTabEditScreen'

const TabEditScreenDesktop = lazy(() => import('./TabEditScreen.desktop'))

const TabEditScreenMobile = lazy(() => import('./TabEditScreen.mobile'))

export const TabEditScreen: TabEditScreenComponent = ({ navigation, route }) => {
  const tabEditsScreen = useTabEditScreen({ navigation, route })

  const Screen = tabEditsScreen.isMobile ? TabEditScreenMobile : TabEditScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...tabEditsScreen} />
    </Suspense>
  )
}
