import { useCallback, useEffect, useState } from 'react'

import NetInfo from '@react-native-community/netinfo'
import { useBreakpointValue } from 'native-base'

import { UsePageNoConnectionScreen } from './PageNoConnectionScreen.types'

export const usePageNoConnectionScreen: UsePageNoConnectionScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [hasConnection, setHasConnection] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const goToTryAgain = useCallback(async () => {
    if (hasConnection && navigation.canGoBack()) {
      return navigation.pop()
    } else {
      setIsLoading(true)

      const time = setTimeout(() => {
        setIsLoading(false)

        clearTimeout(time)
      }, 300)
    }
  }, [navigation, hasConnection])

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      setHasConnection(!!(state.isConnected && state.isInternetReachable))
    })

    return unsubscribe
  }, [])

  return { isMobile, goToTryAgain, isLoading }
}
