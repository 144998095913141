import { useEffect, useState } from 'react'

import { DateData } from 'atoms/Calendar/types'
import { useGetActivitiesByPeriodQuery } from 'integration/resources/activities'
import { useBreakpointValue } from 'native-base'

import { UseViewTypeSheet } from './types'

export const useViewTypeSheet: UseViewTypeSheet = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [datesWithActivities, setDatesWithActivities] = useState<string[]>([])

  const [{ month, year }, setCurrentDate] = useState<Partial<DateData>>({
    month: undefined,
    year: undefined
  })

  const { data, isLoading: isLoadingQuery, refetch } = useGetActivitiesByPeriodQuery()

  const handleMonthChange = ({ month, year }: DateData) => {
    setIsLoading(true)

    // @ts-ignore
    refetch({ month, year }).finally(() => {
      setIsLoading(false)

      setCurrentDate({ month, year })
    })
  }

  const [isLoading, setIsLoading] = useState(false)

  const getActivitiesByPeriod = () => {
    setIsLoading(true)

    // @ts-ignore
    refetch({ month, year }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    if (data?.data.data && data?.data.data.length) {
      const sanitize = data?.data.data.flatMap((item) => item.year_month)

      setDatesWithActivities(sanitize)
    }
  }, [data?.data.data])

  return {
    isLoading: isLoadingQuery || isLoading,
    isMobile,
    handleMonthChange,
    getActivitiesByPeriod,
    datesWithActivities
  }
}
