import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { LockscreenScreenComponent } from './LockscreenScreen.types'
import { useLockscreenScreen } from './useLockscreenScreen'

const LockscreenScreenDesktop = lazy(() => import('./LockscreenScreen.desktop'))

const LockscreenScreenMobile = lazy(() => import('./LockscreenScreen.mobile'))

export const LockscreenScreen: LockscreenScreenComponent = ({ navigation }) => {
  const lockscreenScreen = useLockscreenScreen({ navigation })

  const Screen = lockscreenScreen.isMobile ? LockscreenScreenMobile : LockscreenScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...lockscreenScreen} />
    </Suspense>
  )
}
