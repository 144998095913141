import { FunctionComponent, useMemo } from 'react'

import Icons from 'atoms/Icons'
import StackedBarChart from 'atoms/StackedBarChart'
import { BlocksRvStatementData } from 'integration/resources/variable-revenue/types'
import { VStack, Heading, Text, HStack, Divider, Box, Center } from 'native-base'
import { formatDateStringToMask } from 'src/utils'
import { currencyFormat, currencyFormatChart, toFixedNoRounding } from 'src/utils/currencyFormat'

type BlocksSummaryDetailCardProps = BlocksRvStatementData

const currencyProps = {
  needsLabel: true,
  needsShortcut: true,
  numberOfDecimal: 1
}

export const BlocksSummaryDetailCard: FunctionComponent<BlocksSummaryDetailCardProps> = ({
  title,
  realized,
  projected,
  goal,
  goal_2,
  rv_realized,
  rv_projected,
  accelerator,
  weight,
  percentage_realized,
  percentage_projected,
  percentage_accelerator,
  active_base,
  loyalty,
  charge_goal,
  processed_at
}) => {
  const isPlusAccounts = title === `+Contas`

  const isCharge = title === `Cobrança`

  const isProduction = title === `Produção`

  const hasExtras = useMemo(
    () => active_base ?? loyalty ?? charge_goal,
    [active_base, loyalty, charge_goal]
  )

  return (
    <VStack
      bg={{ base: 'white', lg: 'gray.50' }}
      borderRadius={{ base: '10px', lg: '20px' }}
      p={{ base: 4, lg: 6 }}
      space={{ base: 3, lg: 6 }}>
      <Heading
        fontSize={{ base: '14px', lg: '18px' }}
        fontWeight={700}
        lineHeight={{ base: '18px', lg: '24px' }}>
        {title}
      </Heading>

      <HStack space={2} alignItems="center">
        <Text
          fontSize={{ base: '12px', lg: '16px' }}
          fontWeight={400}
          lineHeight={{ base: '16px', lg: '24px' }}
          color="gray.700">
          Realizado:
        </Text>
        <Text
          fontSize={{ base: '14px', lg: '16px' }}
          fontWeight={700}
          lineHeight={{ base: '16px', lg: '24px' }}
          color="gray.700">
          {isPlusAccounts || isCharge
            ? toFixedNoRounding(realized ?? 0, 0)
            : `R$ ${currencyFormatChart(
                String(realized),
                currencyProps.needsLabel,
                !currencyProps.needsShortcut,
                currencyProps.numberOfDecimal
              )}`}
        </Text>
        <Text
          fontSize={{ base: '14px', lg: '16px' }}
          fontWeight={400}
          lineHeight={{ base: '16px', lg: '16px' }}
          color="gray.300">
          {percentage_realized}%
        </Text>
      </HStack>

      <HStack space={2} alignItems="center">
        <Text
          fontSize={{ base: '12px', lg: '16px' }}
          fontWeight={400}
          lineHeight={{ base: '16px', lg: '24px' }}
          color="gray.700">
          Projetado:
        </Text>
        <Text
          fontSize={{ base: '14px', lg: '16px' }}
          fontWeight={700}
          lineHeight={{ base: '16px', lg: '24px' }}
          color="gray.700">
          {isPlusAccounts || isCharge
            ? toFixedNoRounding(projected ?? 0, 0)
            : `R$ ${currencyFormatChart(
                String(projected),
                currencyProps.needsLabel,
                !currencyProps.needsShortcut,
                currencyProps.numberOfDecimal
              )}`}
        </Text>
        <Text
          fontSize={{ base: '14px', lg: '16px' }}
          fontWeight={400}
          lineHeight={{ base: '16px', lg: '16px' }}
          color="gray.300">
          {percentage_projected}%
        </Text>
      </HStack>
      <HStack space={2} alignItems="center">
        <Text
          fontSize={{ base: '12px', lg: '16px' }}
          fontWeight={400}
          lineHeight={{ base: '16px', lg: '24px' }}
          color="gray.700">
          Meta:
        </Text>
        <Text
          fontSize={{ base: '14px', lg: '16px' }}
          fontWeight={700}
          lineHeight={{ base: '16px', lg: '24px' }}
          color="gray.700">
          {isPlusAccounts || isCharge
            ? toFixedNoRounding(goal ?? 0, 0)
            : `R$ ${currencyFormatChart(
                String(goal),
                currencyProps.needsLabel,
                !currencyProps.needsShortcut,
                currencyProps.numberOfDecimal
              )}`}
        </Text>
      </HStack>

      {isCharge && (
        <HStack space={2} alignItems="center">
          <Text
            fontSize={{ base: '12px', lg: '16px' }}
            fontWeight={400}
            lineHeight={{ base: '16px', lg: '24px' }}
            color="gray.700">
            Meta 2:
          </Text>
          <Text
            fontSize={{ base: '14px', lg: '16px' }}
            fontWeight={700}
            lineHeight={{ base: '16px', lg: '24px' }}
            color="gray.700">
            {toFixedNoRounding(goal_2 ?? 0, 0)}
          </Text>
        </HStack>
      )}

      <VStack flex={1} space={2}>
        <StackedBarChart
          expectedValue={percentage_projected}
          currentValue={percentage_realized}
          currentIsStriped={false}
          expectedIsStriped
          withoutTooltip
          isProduction={isProduction}
          maxValue={isProduction ? 200 : 150}
        />

        <HStack alignItems="center" justifyContent="space-between">
          <HStack space={4}>
            <HStack alignItems="center" space={1}>
              <Icons.SummaryStatus
                color={Number(percentage_realized) >= 100 ? 'secondary.500' : 'error.500'}
                size={{ base: '10px', lg: '16px' }}
              />
              <Text
                fontSize={{ base: '10px', lg: '14px' }}
                fontWeight={600}
                lineHeight={{ base: '14px', lg: '20px' }}
                color="gray.700">
                Realizado (%)
              </Text>
            </HStack>

            <HStack alignItems="center" space={1}>
              <Icons.StrippedCircle
                positive={Number(percentage_projected) >= 100}
                size={{ base: '12px', lg: '18px' }}
              />

              <Text
                fontSize={{ base: '10px', lg: '14px' }}
                fontWeight={600}
                lineHeight={{ base: '14px', lg: '20px' }}
                color="gray.700">
                Projetado (%)
              </Text>
            </HStack>
          </HStack>

          <Text
            fontSize={{ base: '10px', lg: '14px' }}
            fontWeight={600}
            lineHeight={{ base: '14px', lg: '20px' }}
            color="gray.400">
            Peso: {weight}%
          </Text>
        </HStack>
      </VStack>

      <Box>
        <Divider my={{ base: 1, lg: 0 }} bg="gray.100" opacity={0.6} />
      </Box>

      <VStack space={3}>
        <Box flexDir={{ base: 'column', lg: 'row' }}>
          <HStack space={2} mb={{ base: 3, lg: 0 }}>
            <Text
              fontSize={{ base: '12px', lg: '16px' }}
              fontWeight={400}
              lineHeight={{ base: '16px', lg: '24px' }}
              color="gray.700">
              RV Realizado:
            </Text>
            <Text
              fontSize={{ base: '14px', lg: '16px' }}
              fontWeight={700}
              lineHeight={{ base: '16px', lg: '24px' }}
              color="gray.700">
              {`R$ ${currencyFormat(toFixedNoRounding(rv_realized ?? 0, 2))}`}
            </Text>
          </HStack>

          <Divider h={6} w="1px" color="gray.100" mx={2} display={{ base: 'none', lg: 'flex' }} />

          <HStack space={2}>
            <Text
              fontSize={{ base: '12px', lg: '16px' }}
              fontWeight={400}
              lineHeight={{ base: '16px', lg: '24px' }}
              color="gray.700">
              RV Projetado:
            </Text>
            <Text
              fontSize={{ base: '14px', lg: '16px' }}
              fontWeight={700}
              lineHeight={{ base: '16px', lg: '24px' }}
              color="gray.700">
              {`R$ ${currencyFormat(toFixedNoRounding(rv_projected ?? 0, 2))}`}
            </Text>
          </HStack>
        </Box>

        {accelerator !== undefined && (
          <HStack space={2}>
            <Text
              fontSize={{ base: '12px', lg: '16px' }}
              fontWeight={400}
              lineHeight={{ base: '16px', lg: '24px' }}
              color="gray.700">
              Acelerador:
            </Text>
            <Text
              fontSize={{ base: '14px', lg: '16px' }}
              fontWeight={700}
              lineHeight={{ base: '16px', lg: '24px' }}>
              {accelerator > 0 ? `+${accelerator}` : accelerator} p.p.
            </Text>
            <Text
              fontSize={{ base: '14px', lg: '16px' }}
              fontWeight={400}
              lineHeight={{ base: '16px', lg: '24px' }}
              color="gray.300">
              {percentage_accelerator}%
            </Text>
          </HStack>
        )}

        {!!hasExtras && (
          <HStack space={2} flexWrap="wrap">
            {!!active_base && (
              <Center
                py={1}
                px={2}
                borderRadius="40px"
                borderWidth="1px"
                borderColor="secondary.800"
                bgColor="secondary.50">
                <Heading fontSize="12px" fontWeight={700} color="tertiary.800">
                  {`BASE ATIVA (${active_base > 0 ? `+${active_base}` : active_base} P.P.)`}
                </Heading>
              </Center>
            )}

            {!!loyalty && (
              <Center
                py={1}
                px={2}
                borderRadius="40px"
                borderWidth="1px"
                borderColor="secondary.800"
                bgColor="secondary.50">
                <Heading fontSize="12px" fontWeight={700} color="tertiary.800">
                  {`FIDELIZAÇÃO (${loyalty > 0 ? `+${loyalty}` : loyalty} P.P.)`}
                </Heading>
              </Center>
            )}

            {!!charge_goal && (
              <Center
                py={1}
                px={2}
                minW="84px"
                borderRadius="40px"
                borderWidth="1px"
                borderColor="secondary.800"
                bgColor="secondary.50">
                <Heading fontSize="12px" fontWeight={700} color="tertiary.800">
                  META {charge_goal}
                </Heading>
              </Center>
            )}
          </HStack>
        )}

        <Text
          fontSize={{ base: '10px', lg: '14px' }}
          fontWeight={600}
          lineHeight={{ base: '14px', lg: '20px' }}
          color="gray.200">
          Dados até:{' '}
          {processed_at ? formatDateStringToMask(processed_at, "dd'/'MM'/'yyyy") : 'Sem data'}
        </Text>
      </VStack>
    </VStack>
  )
}
