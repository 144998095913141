import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'
import { useBreakpointValue } from 'native-base'

import { OpportunitiesRelatedByStoreProps } from './OpportunitiesRelatedByStore.types'

const OpportunitiesRelatedByStoreMobile = lazy(() => import('./OpportunitiesRelatedByStore.mobile'))

const OpportunitiesRelatedByStoreDesktop = lazy(
  () => import('./OpportunitiesRelatedByStore.desktop')
)

export const OpportunitiesRelatedByStore: FunctionComponent<OpportunitiesRelatedByStoreProps> = (
  props
) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const Component = isMobile
    ? OpportunitiesRelatedByStoreMobile
    : OpportunitiesRelatedByStoreDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Component {...props} />
    </Suspense>
  )
}
