import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Ellipse, Path } from 'react-native-svg'

export const CategoryUserIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 21 21', ...props }}>
    <Ellipse cx={11.381} cy={6.09595} rx={4.59161} ry={4.73267} fill="#FEECEF" />
    <Path
      d="M19.176 19.5995L2.90247 19.386L5.37979 15.2001L9.65107 14.0469L15.161 14.6449L19.176 19.5995Z"
      fill="#FEECEF"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.98867 1.2341C9.63574 0.966076 10.3293 0.828125 11.0297 0.828125C11.73 0.828125 12.4236 0.966076 13.0706 1.2341C13.7177 1.50213 14.3056 1.89498 14.8009 2.39022C15.2961 2.88547 15.689 3.47341 15.957 4.12048C16.225 4.76755 16.363 5.46108 16.363 6.16146C16.363 6.86184 16.225 7.55537 15.957 8.20244C15.689 8.84951 15.2961 9.43745 14.8009 9.93269C14.3056 10.4279 13.7177 10.8208 13.0706 11.0888C12.4236 11.3568 11.73 11.4948 11.0297 11.4948C10.3293 11.4948 9.63574 11.3568 8.98867 11.0888C8.3416 10.8208 7.75366 10.4279 7.25842 9.93269C6.76317 9.43745 6.37032 8.84951 6.10229 8.20244C5.83427 7.55537 5.69632 6.86184 5.69632 6.16146C5.69632 5.46108 5.83427 4.76755 6.10229 4.12048C6.37032 3.47341 6.76317 2.88547 7.25842 2.39022C7.75366 1.89498 8.3416 1.50213 8.98867 1.2341ZM11.0297 2.49479C10.5481 2.49479 10.0713 2.58963 9.62648 2.7739C9.18162 2.95817 8.77741 3.22825 8.43693 3.56873C8.09645 3.90921 7.82636 4.31343 7.64209 4.75829C7.45783 5.20315 7.36299 5.67994 7.36299 6.16146C7.36299 6.64297 7.45783 7.11977 7.64209 7.56463C7.82636 8.00949 8.09645 8.4137 8.43693 8.75418C8.77741 9.09467 9.18162 9.36475 9.62648 9.54902C10.0713 9.73328 10.5481 9.82812 11.0297 9.82812C11.5112 9.82812 11.988 9.73328 12.4328 9.54902C12.8777 9.36475 13.2819 9.09467 13.6224 8.75418C13.9629 8.4137 14.2329 8.00949 14.4172 7.56463C14.6015 7.11977 14.6963 6.64297 14.6963 6.16146C14.6963 5.67994 14.6015 5.20315 14.4172 4.75829C14.2329 4.31343 13.9629 3.90922 13.6224 3.56873C13.2819 3.22825 12.8777 2.95817 12.4328 2.7739C11.988 2.58963 11.5112 2.49479 11.0297 2.49479Z"
      fill="#F3123C"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.34873 14.6275C7.00977 13.4518 8.99465 12.8204 11.0297 12.8204C13.0647 12.8204 15.0495 13.4518 16.7106 14.6275C18.3716 15.8031 19.627 17.4652 20.3036 19.3844C20.3935 19.6394 20.3541 19.9222 20.1978 20.1429C20.0416 20.3636 19.788 20.4948 19.5177 20.4948H2.54165C2.27126 20.4948 2.01767 20.3636 1.86146 20.1429C1.70524 19.9222 1.66583 19.6394 1.75573 19.3844C2.43232 17.4652 3.68769 15.8031 5.34873 14.6275ZM11.0297 14.4871C9.33957 14.4871 7.69111 15.0114 6.3116 15.9878C5.26389 16.7294 4.41051 17.704 3.81445 18.8281H18.2449C17.6488 17.704 16.7954 16.7294 15.7477 15.9878C14.3682 15.0114 12.7197 14.4871 11.0297 14.4871Z"
      fill="#F3123C"
    />
  </Icon>
)
