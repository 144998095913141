import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'

import { RevenueCalculatorProps } from './RevenueCalculator.types'
import { useRevenueCalculator } from './useRevenueCalculator'

const RevenueCalculatorDesktop = lazy(() => import('./RevenueCalculator.desktop'))

export const RevenueCalculator: FunctionComponent<RevenueCalculatorProps> = (props) => {
  const revenueCalculator = useRevenueCalculator(props)

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <RevenueCalculatorDesktop {...revenueCalculator} />
    </Suspense>
  )
}
