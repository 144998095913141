import { AxiosResponse } from 'axios'
import { differenceInDays, formatRelative, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { ResponseSuccess } from 'src/integration/types'

import { Content, ContentSerialized } from './types'

export const getKnowledgeBases: (
  response: AxiosResponse<ResponseSuccess<Content[]>>
) => AxiosResponse<ResponseSuccess<ContentSerialized[]>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: response.data.data.map((item) => ({
      ...item,
      lastUpdateFormatted: formatRelative(parseISO(item?.last_update), new Date(), {
        locale: ptBR
      })
    }))
  }
})

export const getKnowledge: (
  response: AxiosResponse<ResponseSuccess<ContentSerialized>>
) => AxiosResponse<ResponseSuccess<ContentSerialized>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: (response.data.data = {
      ...response.data.data,
      lastUpdateFormatted: differenceInDays(new Date(), parseISO(response.data.data.updated_at))
    })
  }
})
