import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { TabRelatedScreenComponent } from './TabRelatedScreen.types'
import { useTabRelatedScreen } from './useTabRelatedScreen'

const TabRelatedScreenDesktop = lazy(() => import('./TabRelatedScreen.desktop'))

const TabRelatedScreenMobile = lazy(() => import('./TabRelatedScreen.mobile'))

export const TabRelatedScreen: TabRelatedScreenComponent = ({ route }) => {
  const tabRelatedScreen = useTabRelatedScreen({ route })

  const Screen = tabRelatedScreen.isMobile ? TabRelatedScreenMobile : TabRelatedScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...tabRelatedScreen} />
    </Suspense>
  )
}
