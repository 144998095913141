import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ManageNotificationsScreenComponent } from './ManageNotificationsScreen.types'
import { useManageNotificationsScreen } from './useManageNotificationsScreen'

const ManageNotificationsDesktop = lazy(() => import('./ManageNotificationsScreen.desktop'))

const ManageNotificationsMobile = lazy(() => import('./ManageNotificationsScreen.mobile'))

export const ManageNotificationsScreen: ManageNotificationsScreenComponent = ({ navigation }) => {
  const manageNotificationsScreen = useManageNotificationsScreen({ navigation })

  const Screen = manageNotificationsScreen.isMobile
    ? ManageNotificationsMobile
    : ManageNotificationsDesktop

  return (
    <>
      <Suspense fallback={<LoadingTurbo />}>
        <Screen {...manageNotificationsScreen} />
      </Suspense>
    </>
  )
}
