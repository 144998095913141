import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path, Rect } from 'react-native-svg'

type FilterIconProps = IIconProps & {
  vertical?: boolean
}

export const FilterIcon: FunctionComponent<FilterIconProps> = ({ vertical, ...props }) =>
  vertical ? (
    <Icon {...{ viewBox: '0 0 40 40', ...props }}>
      <Rect width={40} height={40} rx={20} fill="#F5F5F5" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.086 29.08a1 1 0 0 1-1.015-.985l-.16-11.08a1 1 0 1 1 2-.03l.16 11.08a1 1 0 0 1-.985 1.015Z"
        fill="#C2C2C2"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.73 24.421a1 1 0 0 1 0 1.414l-2.952 2.952a1 1 0 0 1-1.414 0l-2.952-2.952a1 1 0 0 1 1.414-1.414l2.245 2.245 2.245-2.245a1 1 0 0 1 1.414 0Z"
        fill="#C2C2C2"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.955 11a1 1 0 0 1 .997 1.004l-.04 11a1 1 0 0 1-2-.008l.04-11A1 1 0 0 1 16.955 11Z"
        fill="#404040"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.293 15.66a1 1 0 0 1 0-1.415l2.952-2.952a1 1 0 0 1 1.414 0l2.952 2.952a1 1 0 0 1-1.414 1.414l-2.245-2.245-2.245 2.245a1 1 0 0 1-1.414 0Z"
        fill="#404040"
      />
    </Icon>
  ) : (
    <Icon {...{ viewBox: '0 0 18 18', ...props }}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.879 3.129A3 3 0 1 1 5.12 7.37 3 3 0 0 1 .88 3.13ZM3 3.75a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM7.757 10.578a3 3 0 1 1 2.296 5.542 3 3 0 0 1-2.296-5.542Zm1.149 1.272a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
        fill="currentColor"
      />

      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 5.25a.75.75 0 0 1 .75-.75h11.7a.75.75 0 0 1 0 1.5H5.25a.75.75 0 0 1-.75-.75ZM.111 13.35a.75.75 0 0 1 .75-.75h5.795a.75.75 0 0 1 0 1.5H.86a.75.75 0 0 1-.75-.75ZM10.405 13.35a.75.75 0 0 1 .75-.75h5.795a.75.75 0 0 1 0 1.5h-5.794a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </Icon>
  )
