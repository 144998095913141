import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const EditTextIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 6C0.75 5.58579 1.08579 5.25 1.5 5.25H11.4C11.8142 5.25 12.15 5.58579 12.15 6C12.15 6.41421 11.8142 6.75 11.4 6.75H1.5C1.08579 6.75 0.75 6.41421 0.75 6Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 12.3C0.75 11.8858 1.08579 11.55 1.5 11.55H15.9C16.3142 11.55 16.65 11.8858 16.65 12.3C16.65 12.7142 16.3142 13.05 15.9 13.05H1.5C1.08579 13.05 0.75 12.7142 0.75 12.3Z"
      fill="currentColor"
    />
  </Icon>
)
