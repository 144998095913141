import { RecoveryCalculatorFormFields } from './RecoveryCalculator.types'

export const formsDefaultValues = {
  targetAccount: 0,
  weightProduction: 40,
  weightSPF: 20,
  weightSA: 10,
  weightCharge: 10,
  weightWebmotors: 10,
  weightMoreAccounts: 10,
  weightTotal: 100,
  targetProduction: 0,
  targetSPF: 0,
  targetSA: 0,
  targetCharge: 0,
  targetWebmotors: 0,
  targetMoreAccounts: 0,
  targetTotal: 0,
  goalProduction: 0,
  goalSPF: 0,
  goalSA: 0,
  goalCharge: 0,
  goalWebmotors: 0,
  goalMoreAccounts: 0,
  realizedProduction: 0,
  realizedSPF: 0,
  realizedSA: 0,
  realizedCharge: 0,
  realizedWebmotors: 0,
  realizedMoreAccounts: 0,
  percentAchievedProduction: 0,
  percentAchievedSPF: 0,
  percentAchievedSA: 0,
  percentAchievedCharge: 0,
  percentAchievedWebmotors: 0,
  percentAchievedMoreAccounts: 0,
  goalOneProduction: 0,
  goalOneSPF: 0,
  goalOneSA: 0,
  goalOneCharge: 0,
  goalOneWebmotors: 0,
  goalOneMoreAccounts: 0,
  realizedOneProduction: 0,
  realizedOneSPF: 0,
  realizedOneSA: 0,
  realizedOneCharge: 0,
  realizedOneWebmotors: 0,
  realizedOneMoreAccounts: 0,
  percentAchievedOneProduction: 0,
  percentAchievedOneSPF: 0,
  percentAchievedOneSA: 0,
  percentAchievedOneCharge: 0,
  percentAchievedOneWebmotors: 0,
  percentAchievedOneMoreAccounts: 0,
  goalTwoProduction: 0,
  goalTwoSPF: 0,
  goalTwoSA: 0,
  goalTwoCharge: 0,
  goalTwoWebmotors: 0,
  goalTwoMoreAccounts: 0,
  realizedTwoProduction: 0,
  realizedTwoSPF: 0,
  realizedTwoSA: 0,
  realizedTwoCharge: 0,
  realizedTwoWebmotors: 0,
  realizedTwoMoreAccounts: 0,
  percentAchievedTwoProduction: 0,
  percentAchievedTwoSPF: 0,
  percentAchievedTwoSA: 0,
  percentAchievedTwoCharge: 0,
  percentAchievedTwoWebmotors: 0,
  percentAchievedTwoMoreAccounts: 0,
  earningsProduction: 0,
  earningsSPF: 0,
  earningsSA: 0,
  earningsCharge: 0,
  earningsWebmotors: 0,
  earningsMoreAccounts: 0,
  earningsTotal: 0
}

export const weightFields: (keyof RecoveryCalculatorFormFields)[] = [
  'weightProduction',
  'weightSPF',
  'weightSA',
  'weightCharge',
  'weightWebmotors',
  'weightMoreAccounts'
]

export const targetFields: (keyof RecoveryCalculatorFormFields)[] = [
  'targetProduction',
  'targetSPF',
  'targetSA',
  'targetCharge',
  'targetWebmotors',
  'targetMoreAccounts',
  'targetTotal'
]

export const goalOneFields: (keyof RecoveryCalculatorFormFields)[] = [
  'goalOneProduction',
  'goalOneSPF',
  'goalOneSA',
  'goalOneCharge',
  'goalOneWebmotors',
  'goalOneMoreAccounts'
]

export const realizedOneFields: (keyof RecoveryCalculatorFormFields)[] = [
  'realizedOneProduction',
  'realizedOneSPF',
  'realizedOneSA',
  'realizedOneCharge',
  'realizedOneWebmotors',
  'realizedOneMoreAccounts'
]

export const percentAchievedOneFields: (keyof RecoveryCalculatorFormFields)[] = [
  'percentAchievedOneProduction',
  'percentAchievedOneSPF',
  'percentAchievedOneSA',
  'percentAchievedOneCharge',
  'percentAchievedOneWebmotors',
  'percentAchievedOneMoreAccounts'
]

export const goalTwoFields: (keyof RecoveryCalculatorFormFields)[] = [
  'goalTwoProduction',
  'goalTwoSPF',
  'goalTwoSA',
  'goalTwoCharge',
  'goalTwoWebmotors',
  'goalTwoMoreAccounts'
]

export const realizedTwoFields: (keyof RecoveryCalculatorFormFields)[] = [
  'realizedTwoProduction',
  'realizedTwoSPF',
  'realizedTwoSA',
  'realizedTwoCharge',
  'realizedTwoWebmotors',
  'realizedTwoMoreAccounts'
]

export const percentAchievedTwoFields: (keyof RecoveryCalculatorFormFields)[] = [
  'percentAchievedTwoProduction',
  'percentAchievedTwoSPF',
  'percentAchievedTwoSA',
  'percentAchievedTwoCharge',
  'percentAchievedTwoWebmotors',
  'percentAchievedTwoMoreAccounts'
]

export const goalFields: (keyof RecoveryCalculatorFormFields)[] = [
  'goalProduction',
  'goalSPF',
  'goalSA',
  'goalCharge',
  'goalWebmotors',
  'goalMoreAccounts'
]

export const realizedFields: (keyof RecoveryCalculatorFormFields)[] = [
  'realizedProduction',
  'realizedSPF',
  'realizedSA',
  'realizedCharge',
  'realizedWebmotors',
  'realizedMoreAccounts'
]

export const percentAchievedFields: (keyof RecoveryCalculatorFormFields)[] = [
  'percentAchievedProduction',
  'percentAchievedSPF',
  'percentAchievedSA',
  'percentAchievedCharge',
  'percentAchievedWebmotors',
  'percentAchievedMoreAccounts'
]

export const earningsFields: (keyof RecoveryCalculatorFormFields)[] = [
  'earningsProduction',
  'earningsSPF',
  'earningsSA',
  'earningsCharge',
  'earningsWebmotors',
  'earningsMoreAccounts',
  'earningsTotal'
]
