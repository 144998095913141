import { FunctionComponent, memo, useState } from 'react'

import { Icons } from 'atoms'
import { LinearGradient } from 'expo-linear-gradient'
import { ContentSerialized } from 'integration/resources/knowledgeBase'
import {
  Badge,
  Circle,
  Heading,
  HStack,
  IconButton,
  Text,
  useBreakpointValue,
  useTheme,
  VStack
} from 'native-base'
import { ImageBackground } from 'react-native'

type HeroContentHighProps = ContentSerialized & {
  handleGoToHomeContent(): void
}

export const HeroContentHigh: FunctionComponent<HeroContentHighProps> = memo(
  ({
    title,
    subtitle,
    estimated_reading_time,
    lastUpdateFormatted,
    category,
    cover_image_url,
    handleGoToHomeContent
  }) => {
    const theme = useTheme()

    const isMobile = useBreakpointValue({ base: true, lg: false })

    const [isError, setIsError] = useState(false)

    const readingTimeInMinutes = estimated_reading_time && (estimated_reading_time / 60).toFixed(0)

    return (
      <LinearGradient
        colors={[theme.colors['header-gradient']['100'], theme.colors['header-gradient']['200']]}>
        <ImageBackground
          style={{ height: isMobile ? 300 : 588 }}
          onError={({ nativeEvent: { error } }) => (error ? setIsError(true) : setIsError(false))}
          source={cover_image_url === undefined || isError ? {} : { uri: cover_image_url }}>
          <LinearGradient
            colors={['#0d0d0d0d', '#00000028', theme.colors['header-gradient']['200']]}
            style={{ flex: 1, paddingTop: 12 }}>
            <VStack safeAreaTop mt={isMobile ? 8 : 'auto'}>
              <HStack justifyContent="flex-end" mr={5} zIndex={2}>
                {isMobile && (
                  <IconButton
                    size={10}
                    bg="#ABABAB"
                    rounded="full"
                    icon={<Icons.Close color="white" size={4} />}
                    onPress={handleGoToHomeContent}
                    _pressed={{ color: 'white', bg: 'transparent' }}
                  />
                )}
              </HStack>
              <HStack w="full" mt={{ base: 6, lg: 162 }} px={{ base: 4, lg: 8 }}>
                <Badge
                  bg="tertiary.800"
                  borderRadius="full"
                  color="white"
                  padding={0}
                  borderWidth={0}
                  _text={{
                    color: 'white',
                    fontFamily: 'heading',
                    fontSize: '12px',
                    lineHeight: '16px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    paddingX: 4,
                    paddingY: 1
                  }}>
                  {category?.name}
                </Badge>
              </HStack>

              <VStack justifyContent="flex-end" px={{ base: 4, lg: 8 }} pb={8} mt={4}>
                <VStack w="full">
                  <Heading
                    fontSize={{ base: '24px', lg: '32px' }}
                    lineHeight={{ base: '28px', lg: '40px' }}
                    fontWeight="bold"
                    color="white"
                    mb={subtitle ? 2 : 4}>
                    {title}
                  </Heading>
                  {subtitle && (
                    <Text
                      fontSize={{ base: '16px', lg: '18px' }}
                      lineHeight="24px"
                      fontWeight="normal"
                      color="white"
                      mb={4}>
                      {subtitle}
                    </Text>
                  )}

                  <HStack justifyContent="space-between" alignItems="center">
                    <HStack space={2} alignItems="center">
                      <Circle size="24px" bg="primary.500">
                        <Icons.FlameSantander color="white" size={3} />
                      </Circle>
                      <Text
                        color="white"
                        fontSize={{ base: '16px', lg: '18px' }}
                        fontWeight="normal"
                        lineHeight="24px">
                        Santander
                      </Text>
                    </HStack>
                    <HStack space={2} alignItems="center">
                      <Text
                        color="white"
                        fontSize={{ base: '10px', lg: '16px' }}
                        fontWeight="normal"
                        lineHeight={{ base: '20px', lg: '22px' }}>
                        {!!lastUpdateFormatted &&
                          `Há ${lastUpdateFormatted} ${
                            Number(lastUpdateFormatted) > 1 ? 'dias' : 'dia'
                          }`}
                      </Text>

                      {estimated_reading_time && (
                        <HStack space={1} alignItems="center">
                          <Icons.Watch color="white" />

                          <Text color="white" fontSize="10px" fontWeight="normal" lineHeight="20px">
                            {readingTimeInMinutes} min
                          </Text>
                        </HStack>
                      )}
                    </HStack>
                  </HStack>
                </VStack>
              </VStack>
            </VStack>
          </LinearGradient>
        </ImageBackground>
      </LinearGradient>
    )
  }
)
