import { FunctionComponent, useMemo } from 'react'

import { Box, ScrollView, useTheme } from 'native-base'
import { Dimensions, Platform } from 'react-native'
import { getNumberYearAndMonth, percentageCalculate } from 'src/utils'
import { currencyFormatChart } from 'src/utils/currencyFormat'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryTheme } from 'victory-native'

export type ChartData = {
  value: number
  year_month: string
}[]

export type MultipleBarsChartProps = {
  colors: string[]
  tickValues?: string[]
  chartsData: ChartData[]
  onClickChart?(monthYear: string): void
  type?: string
}

export const MultipleBarsChart: FunctionComponent<MultipleBarsChartProps> = ({
  chartsData,
  colors,
  tickValues,
  onClickChart,
  type
}) => {
  const higherValue = useMemo(
    () =>
      chartsData
        .map((chartData) => chartData.sort(({ value: a }, { value: b }) => b - a)[0].value)
        .sort((a, b) => b - a)[0],
    [chartsData]
  )

  const isMobile = Platform.OS !== 'web'

  const theme = useTheme()

  // we use these constants to remedy the limitations of the charts library in relation to responsiveness

  const phoneHeight = Dimensions.get('window').height

  const phoneWidth = Dimensions.get('window').width

  const lowWidthPhone = phoneWidth < 700
  //

  const lengthData = chartsData.length

  const needsLabel = {
    yes: true,
    no: false
  }

  const tickValuesAxis =
    type === 'percentage'
      ? [0, 50, 100]
      : type === 'currency'
      ? [0, higherValue * 0.2, higherValue * 0.4, higherValue * 0.6, higherValue * 0.8, higherValue]
      : [0, Math.round(higherValue / 2), higherValue]

  const content = (
    <VictoryChart
      theme={VictoryTheme.material}
      width={
        isMobile
          ? lowWidthPhone
            ? lengthData < 4
              ? lengthData < 3
                ? phoneWidth * 1.8
                : phoneWidth * 2
              : phoneWidth * 2.4
            : lengthData < 4
            ? lengthData < 3
              ? phoneWidth * 1.2
              : phoneWidth * 1.4
            : phoneWidth * 1.8
          : phoneWidth - 315
      }
      height={isMobile ? phoneHeight * 0.77 : undefined}
      domainPadding={{ x: 40 }}
      domain={{ x: [1, 13] }}>
      <VictoryAxis
        tickValues={tickValues}
        tickFormat={(t) => String(t).split('-')[0]}
        orientation="bottom"
        style={{
          tickLabels: {
            fontSize: 14,
            padding: 10,
            fill: theme.colors.gray[400]
          },
          ticks: { size: 0 },
          axis: { stroke: 0 },
          grid: { stroke: 0 }
        }}
        standalone={false}
      />

      <VictoryAxis
        dependentAxis
        tickValues={tickValuesAxis}
        tickFormat={(t) =>
          t && higherValue > 1 && type === 'currency'
            ? currencyFormatChart(String(t), needsLabel.yes, needsLabel.no)
            : type === 'percentage'
            ? `${parseInt(t, 10)}%`
            : parseInt(t, 10)
        }
        style={{
          tickLabels: {
            fontSize: 10,
            fill: theme.colors.gray[400],
            fontWeight: 600
          },
          ticks: { size: 0 },
          axis: { stroke: 0 },
          grid: {
            stroke: theme.colors.gray[100],
            strokeWidth: 1
          }
        }}
        standalone={false}
      />
      <VictoryGroup offset={lengthData > 2 ? 24 : 32}>
        {chartsData.length > 0 &&
          chartsData.map((chart, index) => (
            <VictoryBar
              key={String(Math.random())}
              data={chart}
              x="year_month"
              y="value"
              name={colors[index]}
              barWidth={18}
              cornerRadius={{
                top: ({ datum }) => {
                  const percentage = percentageCalculate(higherValue ?? 0, datum.value ?? 0)

                  if (percentage > 13) return 9

                  if (percentage > 9) return 9

                  if (percentage > 5) return 7

                  if (percentage > 3) return 5

                  return 2
                },
                bottom: ({ datum }) => {
                  const percentage = percentageCalculate(higherValue ?? 0, datum.value ?? 0)

                  if (percentage > 13) return 9

                  if (percentage > 9) return 9

                  if (percentage > 5) return 7

                  if (percentage > 3) return 5

                  return 2
                }
              }}
              style={{
                data: { fill: colors[index] },
                //@ts-ignore
                labels: {
                  fontWeight: 700,
                  //@ts-ignore
                  fill: colors[index]
                }
              }}
              labels={({ datum }) =>
                datum.value && higherValue > 1 && type === 'currency'
                  ? currencyFormatChart(String(datum.value), needsLabel.yes, needsLabel.no)
                  : type === 'percentage'
                  ? `${datum.value}%`
                  : datum.value
              }
              events={[
                {
                  target: 'data',
                  eventHandlers: {
                    onPressOut: () => [
                      {
                        target: 'data',

                        mutation: (props) =>
                          onClickChart?.(getNumberYearAndMonth(props.datum.year_month).monthNumber)
                      }
                    ],
                    onClick: () => [
                      {
                        target: 'data',
                        mutation: (props) =>
                          onClickChart?.(getNumberYearAndMonth(props.datum.year_month).monthNumber)
                      }
                    ]
                  }
                }
              ]}
            />
          ))}
      </VictoryGroup>
    </VictoryChart>
  )

  if (isMobile)
    return (
      <Box mt={-11} ml={-4} zIndex={1}>
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          {content}
        </ScrollView>
      </Box>
    )

  return content
}
