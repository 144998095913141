import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

type TisNewVersionProps = {
  open: boolean
  is_required: boolean
}

const isNewVersionAtom = atom<TisNewVersionProps>({
  open: false,
  is_required: false
})

export const useIsNewVersionAtom = () => useAtom(isNewVersionAtom)

export const useIsNewVersionAtomValue = () => useAtomValue(isNewVersionAtom)

export const useSetisNewVersionAtom = () => useSetAtom(isNewVersionAtom)
