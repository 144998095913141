import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'

import { PointsArcProgressProps } from './PointsArcProgress.types'
import { usePointsArcProgress } from './usePointsArcProgress'

const PointsArcProgressDesktop = lazy(() => import('./PointsArcProgress.desktop'))

const PointsArcProgressMobile = lazy(() => import('./PointsArcProgress.mobile'))

export const PointsArcProgress: FunctionComponent<PointsArcProgressProps> = (props) => {
  const pointsArcProgress = usePointsArcProgress(props)

  const Component = pointsArcProgress.isMobile ? PointsArcProgressMobile : PointsArcProgressDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Component {...pointsArcProgress} />
    </Suspense>
  )
}
