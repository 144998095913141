import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { SimulatorScreenComponent } from './SimulatorScreen.types'
import { useSimulatorScreen } from './useSimulatorScreen'

const SimulatorScreenDesktop = lazy(() => import('./SimulatorScreen.desktop'))

const SimulatorScreenMobile = lazy(() => import('./SimulatorScreen.mobile'))

export const SimulatorScreen: SimulatorScreenComponent = ({ navigation, route }) => {
  const entryScreen = useSimulatorScreen({ navigation, route })

  const Screen = entryScreen.isMobile ? SimulatorScreenMobile : SimulatorScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...entryScreen} />
    </Suspense>
  )
}
