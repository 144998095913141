import { FunctionComponent, Suspense, lazy, memo, useCallback, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingTurbo } from 'atoms'
import { EvaluationItem } from 'integration/resources/opportunities'
import { useBreakpointValue } from 'native-base'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard } from 'react-native'
import fieldsValidation from 'src/constants/fields-validation'
import * as Yup from 'yup'

import { BottomSheetEvaluationOpportunityProps } from './types'

const schema = Yup.object().shape({
  score: Yup.string().required('*Selecione uma nota'),
  comment: Yup.string()
    .when('score', {
      is: (score: number) => score <= 2,
      then: Yup.string().required(fieldsValidation.common.required)
    })
    .max(400, 'O comentário pode ter no máximo 400 caracteres'),
  insight: Yup.string().when('score', {
    is: (score: number) => score <= 2,
    then: Yup.string().required(fieldsValidation.common.required)
  })
})

const BottomSheetEvaluationOpportunityMobile = lazy(
  () => import('./BottomSheetEvaluationOpportunity.mobile')
)

const BottomSheetEvaluationOpportunityDesktop = lazy(
  () => import('./BottomSheetEvaluationOpportunity.desktop')
)

export const BottomSheetEvaluationOpportunity: FunctionComponent<BottomSheetEvaluationOpportunityProps> =
  memo(({ isOpen, handleClose, onSubmit, isLoading }) => {
    const { control, handleSubmit, reset } = useForm<EvaluationItem>({
      resolver: yupResolver(schema)
    })

    const submit = handleSubmit(
      useCallback<SubmitHandler<EvaluationItem>>(
        async (formData) => {
          Keyboard.dismiss()

          onSubmit(formData)

          reset({ comment: '' })
        },
        [onSubmit, reset]
      )
    )

    const onClose = () => {
      handleClose()

      reset({ comment: '' })
    }

    useEffect(() => {
      if (!isOpen) {
        reset({ comment: '' })
      }
    }, [isOpen, reset])

    const isMobile = useBreakpointValue({ base: true, lg: false })

    const Component = isMobile
      ? BottomSheetEvaluationOpportunityMobile
      : BottomSheetEvaluationOpportunityDesktop

    return (
      <Suspense fallback={<LoadingTurbo />}>
        <Component {...{ isOpen, isLoading, control, submit, onClose }} />
      </Suspense>
    )
  })
