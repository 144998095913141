// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { memo } from 'react'

import { HStack, Heading, Text, VStack } from 'native-base'

export const TermsContent = memo(() => {
  return (
    <>
      <Heading fontSize="18px" color="gray.700" mb={4}>
        Termo de Compromisso de Confidencialidade Portal Mais Turbo
      </Heading>
      <Text fontSize="16px" color="gray.500">
        Declaro estar ciente de que, em virtude das minhas responsabilidades que exercerei como
        funcionário da FINANCEIRA terei acesso ao PORTAL MAIS TURBO para gerenciar e acompanhar
        tarefas, com informações de natureza confidencial (“Informações Confidenciais”).
        {'\n'}
      </Text>
      <VStack w="95%" mt={6} space={4}>
        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            1.
          </Text>

          <Text fontSize="16px" color="gray.500" mr={1}>
            INFORMAÇÕES CONFIDENCIAIS são todos e quaisquer dados e informações de qualquer
            natureza, escritas ou não, tais como dados pessoais de funcionários, prestadores de
            serviços, clientes e fornecedores, informações cadastrais, financeiras, operacionais,
            econômicas, técnicas, mercadológicas e jurídicas, bem como contratos e documentos
            produzidos e/ou recebidos em conexão com as operações, inclusive todas as suas minutas,
            peças, defesas, estratégias, apresentações, conteúdo de discussões, apresentações, ou
            qualquer outra informação ou documentos recebidos, acessados e/ou produzidos em relação
            às minhas atividades junto a FINANCEIRA, informações sobre planos comerciais, planos de
            marketing, de atividade comercial, de estratégias de negócio, de produtos ou negociações
            concluídas ou não, sejam relativas à FINANCEIRA e demais empresas do Grupo Santander,
            seus controladores, controladas, coligadas, afiliadas, fornecedores, clientes e
            parcerias comerciais.
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            2.
          </Text>

          <Text fontSize="16px" color="gray.500" mr={1}>
            Declaro que a obrigação de guarda e de sigilo e confidencialidade que assumo por meio
            deste Termo, estendem-se também a qualquer sistema ou programa de computador, segredo de
            negócio ou know-how, documento, croqui, desenho, vídeos, reproduções, tabelas, gráficos,
            documentos financeiros, demonstrações financeiras, documentos contábeis, requerimentos
            escritos e qualquer outra forma de comunicação ou documentação, escrita ou eletrônica,
            relacionados às INFORMAÇÕES CONFIDENCIAIS.
          </Text>
        </HStack>

        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            3.
          </Text>
          <Text fontSize="16px" color="gray.500" mr={1}>
            Mediante a assinatura deste Termo de Confidencialidade, concordo em:
            {'\n'}
            (I) manter sigilo absoluto sobre todas e quaisquer INFORMAÇÃO CONFIDENCIAL ou
            conhecimento técnico, administrativo ou comercial relativos à FINANCEIRA e demais
            empresas do Grupo Santander, seus clientes, funcionários e fornecedores.
            {'\n'}
            {'\n'}
            (II) não utilizar as INFORMAÇÕES CONFIDENCIAIS em benefício próprio ou de terceiros para
            quaisquer fins diversos daqueles que forem determinados;
            {'\n'}
            {'\n'}
            (III) qualquer sigla e/ou senha fornecida pela FINANCEIRA, necessária ao cumprimento das
            responsabilidades laborais no PORTAL MAIS TURBO, possuem caráter pessoal e
            intransferível, não podendo, em nenhuma circunstância, ser divulgada, fornecida, cedida
            ou utilizada para obtenção de vantagens pessoais e/ou de terceiros;
            {'\n'}
            {'\n'}
            (IV) não divulgar, publicar, anunciar ou repassar qualquer INFORMAÇÃO CONFIDENCIAL ou
            qualquer outro fato ou informação relativa à FINANCEIRA e empresas do Grupo Santander,
            seus clientes e fornecedores, de que tenha tido conhecimento.
          </Text>
        </HStack>

        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            4.
          </Text>
          <Text fontSize="16px" color="gray.500" mr={1}>
            Reconheço que o uso inadequado e violação de quaisquer das obrigações mencionadas neste
            instrumento sujeitar-me-á à aplicação das penalidades cabíveis administrativas, cíveis e
            criminais, nos termos da Lei, obrigando-me, também, a isentar e/ou indenizar a
            FINANCEIRA e demais empresas do Grupo Santander, de todo e qualquer dano, perda,
            prejuízo ou responsabilidade que a ele venha a ser imputado quanto a demandas, ações,
            danos, perdas, custas e despesas que porventura possa sofrer como resultado de violação
            do compromisso por mim assumido neste Termo.
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            5.
          </Text>
          <Text fontSize="16px" color="gray.500" mr={1}>
            Caso seja obrigado a revelar qualquer INFORMAÇÃO CONFIDENCIAL por determinação legal de
            autoridade competente, imediatamente notificarei a FINANCEIRA, mesmo após o término do
            contrato de trabalho, e concordo em divulgar somente a informação que for expressamente
            requerida
          </Text>
        </HStack>

        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            6.
          </Text>
          <Text fontSize="16px" color="gray.500" mr={1}>
            Reconheço que as obrigações previstas neste TERMO, em razão de sua natureza,
            permanecerão em vigor por prazo INDETERMINADO, mesmo após o término ou rescisão do
            contrato
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="16px" color="gray.500" mr={1}>
            7.
          </Text>
          <Text fontSize="16px" color="gray.500" mr={1}>
            Concordo com a eleição do Foro da Comarca de São Paulo - SP, para dirimir qualquer
            dúvida ou controvérsia oriunda deste Termo.
          </Text>
        </HStack>
      </VStack>
    </>
  )
})
