import { AxiosResponse } from 'axios'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { ResponseSuccess } from 'src/integration/types'
import { currencyFormat } from 'src/utils/currencyFormat'

import {
  OpportunitySerialized,
  Opportunity,
  OpportunitiesResponse,
  OpportunitiesByBusinessResponse
} from './types'

const formatRelatedDate = (date: string) =>
  format(parseISO(date), 'dd/MM/yyyy', {
    locale: ptBR
  })

const opportunityItemSerializer = (opportunity: Opportunity): OpportunitySerialized => ({
  ...opportunity,
  dueDate: opportunity.due_date && formatRelatedDate(opportunity.due_date),
  isFinished: opportunity.is_finished,
  earningPotential: currencyFormat(String(opportunity.earning_potential)),
  isHighlighted: opportunity.is_highlighted,
  highlightedByUserStdCode: opportunity.highlighted_by_user_std_code
})

export const getOpportunityList: (
  response: AxiosResponse<ResponseSuccess<OpportunitiesResponse>>
) => AxiosResponse<ResponseSuccess<OpportunitiesResponse>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: {
      ...response.data.data,
      items: response.data.data.items.map((opportunity) => opportunityItemSerializer(opportunity)),
      highlights: response.data.data.highlights && {
        ...response.data.data.highlights,
        items: response.data.data.highlights.items?.length
          ? response.data.data.highlights.items?.map((opportunity) =>
              opportunityItemSerializer(opportunity)
            )
          : []
      }
    }
  }
})

export const getOpportunityListByBusiness: (
  response: AxiosResponse<ResponseSuccess<OpportunitiesByBusinessResponse>>
) => AxiosResponse<ResponseSuccess<OpportunitiesByBusinessResponse>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: {
      ...response.data.data,
      items: response.data.data.items.map((item) => ({
        ...item,
        business_block_items: item.business_block_items?.map((opportunity) =>
          opportunityItemSerializer(opportunity)
        )
      }))
    }
  }
})

export const getOpportunityItem: (
  response: AxiosResponse<ResponseSuccess<Opportunity>>
) => AxiosResponse<ResponseSuccess<OpportunitySerialized>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: opportunityItemSerializer(response.data.data)
  }
})
