import { useEffect, useMemo } from 'react'

import { useGetHirePlusUrlQuery } from 'integration/resources/hirePlus'
import { useBreakpointValue } from 'native-base'

import { UseHirePlusScreen } from './HirePlusScreen.types'

export const useHirePlusScreen: UseHirePlusScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { data: hirePlusData, isLoading } = useGetHirePlusUrlQuery()

  const url = hirePlusData?.data?.data?.url

  const session_token = hirePlusData?.data?.data?.session_token

  const iFrameUrl = useMemo(
    () => (url && session_token ? `${url}?session_token=${session_token}` : ''),
    [url, session_token]
  )

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const iframe = document.getElementById('hireplus-iframe')

      if (!iframe) {
        console.error('Iframe element not found')

        return
      }

      if (event?.data?.action === 'expandirIframe') {
        iframe.style.position = 'fixed'

        iframe.style.top = '0'

        iframe.style.left = '0'

        iframe.style.width = '100vw'

        iframe.style.height = '100vh'

        iframe.style.border = 'none'

        iframe.style.zIndex = '9999'
      } else if (event?.data?.action === 'reduzirIframe') {
        iframe.style.position = 'static'

        iframe.style.width = '100%'

        iframe.style.height = '96%'

        iframe.style.zIndex = 'auto'
      } else {
        console.warn('Unrecognized action:', event?.data?.action)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isMobile,
    iFrameUrl,
    isLoading
  }
}
