import { useCallback, useState } from 'react'

import { useBreakpointValue } from 'native-base'
import { useSendAuthCode } from 'src/hooks/useSendAuthCode'
import { useAuthMutation } from 'src/integration/resources/auth'
import { useSetAuthAtom } from 'src/store/auth'
import { usePreAuthAtom } from 'src/store/preauth'
import { useSetTokenAtom } from 'src/store/token'
import { LogEventUserId, LogEventUserProperties } from 'src/utils/logEvents'
import { returnDescriptionSegment } from 'src/utils/users'

export const useLoginAuthCodeScreen = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [preAuthAtom, setPreAuthAtom] = usePreAuthAtom()

  const [errorCode, setErrorCode] = useState<string | undefined>()

  const authMutation = useAuthMutation()

  const setTokenAuthAtom = useSetTokenAtom()

  const setAuthAtom = useSetAuthAtom()

  const {
    slt,
    handleSendAuthCodeEmail,
    handleSendAuthCodeSms,
    isLoadingAuthSendCodeEmail,
    isLoadingAuthSendCodeSms
  } = useSendAuthCode()

  const submit = useCallback(
    ({ code }: { code: string }) => {
      setErrorCode(undefined)

      authMutation.mutate(
        { code, slt, ...preAuthAtom },
        {
          onError: () => setErrorCode('Código de autenticação inválido.'),
          onSuccess: (responseData) => {
            setPreAuthAtom(null)

            /**
             * NOTE: RootStackNavigator is listening for authAtom.
             * Once authAtom receives a value, the RootStackNavigator will handle the transition to either the AcceptTerms screen or the Onboarding screen.
             */
            setAuthAtom(responseData.data.data)

            setTokenAuthAtom(responseData.data.data.auth.token)

            LogEventUserId(`${responseData.data.data.user?.std_code}`)

            LogEventUserProperties({
              business_code: returnDescriptionSegment(responseData.data.data.user?.business_code),
              std_code: `${responseData.data.data.user?.std_code}`,
              occupation: `${responseData.data.data.user?.occupation}`
            })
          }
        }
      )
    },
    [authMutation, preAuthAtom, setAuthAtom, setPreAuthAtom, slt, setTokenAuthAtom]
  )

  return {
    errorCode,
    isSubmitting: authMutation.isLoading,
    submit,
    handleSendAuthCodeEmail,
    handleSendAuthCodeSms,
    isLoadingAuthSendCodeEmail,
    isLoadingAuthSendCodeSms,
    isMobile
  }
}
