import { FunctionComponent, useEffect, useState } from 'react'

import { Cards, Illustrations } from 'atoms'
import Constants from 'expo-constants'
import { ContentSerialized } from 'integration/resources/knowledgeBase'
import { getKnowledgeBases } from 'integration/resources/knowledgeBase/requests'
import { Box, VStack, Center, FlatList, Spinner, Text } from 'native-base'
import { InputSearch } from 'organisms/InputSearch/InputSearch'
import { Modal, Platform } from 'react-native'

type SearchContentProps = {
  modalVisible: boolean
  setModalVisible: (_: boolean) => void
  onPressContentCard(content: ContentSerialized): void
}

export const SearchContent: FunctionComponent<SearchContentProps> = ({
  modalVisible,
  setModalVisible,
  onPressContentCard
}) => {
  const [title, setTitle] = useState<string>()

  const [filterContentList, setFilterContentList] = useState<ContentSerialized[]>([])

  const [filterContentIsLoading, setFilterContentIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetch = () => {
      setFilterContentIsLoading(true)

      getKnowledgeBases({ title, per_page: 100 })
        .then((list) => setFilterContentList(list.data.data))
        .finally(() => setFilterContentIsLoading(false))
    }

    if (title) {
      fetch()
    } else {
      setFilterContentList([])
    }
  }, [title])

  return (
    <Modal
      presentationStyle="fullScreen"
      animationType="slide"
      visible={modalVisible}
      onRequestClose={() => setModalVisible(!modalVisible)}>
      <Box
        px={4}
        pt={Platform.OS === 'ios' ? `${Constants.statusBarHeight + 20}px` : 8}
        pb="100px"
        bg="background.base"
        h="full">
        <VStack>
          <Box>
            <InputSearch
              applyFilter={setTitle}
              cancel
              handleCancel={() => setModalVisible(!modalVisible)}
            />
          </Box>

          <Box mt={8}>
            {filterContentIsLoading && (
              <Center>
                <Spinner />
              </Center>
            )}
            {!filterContentIsLoading && !!filterContentList.length && (
              <FlatList
                data={filterContentList}
                keyExtractor={(item) => `${item.id}`}
                renderItem={({ item }) => (
                  <Box mb={2} key={item.id}>
                    <Cards.Content
                      {...item}
                      onPress={() => [setModalVisible(!modalVisible), onPressContentCard(item)]}
                    />
                  </Box>
                )}
                showsVerticalScrollIndicator={false}
              />
            )}

            {!title && (
              <Center mt="53px">
                <Illustrations.Search />
              </Center>
            )}

            {!filterContentIsLoading && title && !filterContentList.length && (
              <Center>
                <Text
                  fontSize="16px"
                  lineHeight="24px"
                  fontWeight="normal"
                  color="gray.500"
                  mb={16}>
                  Nenhum resultado encontrado.
                </Text>

                <Illustrations.SearchNotFound />
              </Center>
            )}
          </Box>
        </VStack>
      </Box>
    </Modal>
  )
}
