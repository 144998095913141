import { FunctionComponent } from 'react'

import { Cards } from 'atoms'
import { ContentSerialized } from 'integration/resources/knowledgeBase'
import { Box, Heading, VStack } from 'native-base'

type AllContentsProps = {
  allContentIsLoading: boolean
  allContent: ContentSerialized[]
  onPressContentCard(content: ContentSerialized): void
}

export const AllContents: FunctionComponent<AllContentsProps> = ({
  allContentIsLoading,
  allContent,

  onPressContentCard
}) => {
  return (
    <Box>
      {!allContentIsLoading && !!allContent.length && (
        <Box>
          <Heading fontSize="24px" lineHeight="28px" fontWeight="bold" color="gray.700" mt={8}>
            Todos os conteúdos
          </Heading>
          <VStack pt={4} mb={10} position="relative">
            {allContent.map((item) => (
              <Box w="full" mb={2} key={item.id}>
                <Cards.Content {...item} onPress={() => onPressContentCard(item)} />
              </Box>
            ))}
          </VStack>
        </Box>
      )}
    </Box>
  )
}
