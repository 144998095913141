import {
  ConfirmAccountEmailData,
  ConfirmAccountEmailPayload,
  ConfirmAccountPhoneNumberData,
  ConfirmAccountPhoneNumberPayload,
  CreateAccountData,
  CreateAccountPayload
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const createAccount = async (data: CreateAccountPayload) => {
  const response = await client.post<ResponseSuccess<CreateAccountData>>('v1/users', data)

  return response
}

export const confirmAccountPhoneNumber = ({ id, slt, ...data }: ConfirmAccountPhoneNumberPayload) =>
  client.patch<ResponseSuccess<ConfirmAccountPhoneNumberData>>(
    `v1/users/${id}/confirm-phone-number`,
    data,
    {
      headers: {
        'x-slt': slt
      }
    }
  )

export const confirmAccountEmail = ({ id, confirm_token, ...data }: ConfirmAccountEmailPayload) =>
  client.patch<ResponseSuccess<ConfirmAccountEmailData>>(
    `v1/users/${id}/confirm-email?confirm_token=${confirm_token}`,
    data
  )

export const sendConfirmAccountEmail = ({ id }: Pick<ConfirmAccountEmailPayload, 'id'>) =>
  client.patch<ResponseSuccess<ConfirmAccountEmailData>>(`v1/users/${id}/send-email-confirmation`)
