import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import { DeviceSerialized } from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const useGetDevicesQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<DeviceSerialized[]>>, AxiosError<ResponseError>>(
    'getDevices',
    requests.getDevices
  )

export const useDeleteDeviceMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError<ResponseError>, string>(requests.deleteDevice, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getDevices'])
    }
  })
}
