import { AxiosResponse } from 'axios'
import { ResponseSuccess } from 'integration/types'

import { SimulationSerialized } from './types'

export const getSimulationOptions: (
  response: AxiosResponse<ResponseSuccess<SimulationSerialized>>
) => AxiosResponse<ResponseSuccess<SimulationSerialized>> = (response) => {
  const trackMarks: number[] = []

  if (response.data.data.simulations.length > 0)
    response.data.data.simulations.map((simulation, index, size) => {
      if (index > 0 && index < size.length - 1) trackMarks.push(simulation.points)
    })

  const maxValue =
    response.data.data.simulations[response.data.data.simulations.length - 1].points ?? 100

  const minValue = response.data.data.simulations[0].points ?? 100

  const simulationActive =
    response.data.data.simulations.filter(
      (simulation, index) => simulation.points === response.data.data.active_simulation
    )[0] ?? {}

  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        active_simulation_item: simulationActive,
        maxValue,
        minValue,
        trackMarks
      })
    }
  }
}
