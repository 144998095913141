import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { G, Path, Defs, ClipPath, Rect } from 'react-native-svg'

export const PanelsIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <G clipPath="url(#clip0_12000_38526)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.77793 1.7793C1.69509 1.7793 1.62793 1.84646 1.62793 1.9293V8.22933C1.62793 8.31217 1.69509 8.37933 1.77793 8.37933H5.37793C5.46077 8.37933 5.52793 8.31217 5.52793 8.22933V1.9293C5.52793 1.84646 5.46077 1.7793 5.37793 1.7793H1.77793ZM0.12793 1.9293C0.12793 1.01803 0.86666 0.279297 1.77793 0.279297H5.37793C6.2892 0.279297 7.02793 1.01803 7.02793 1.9293V8.22933C7.02793 9.1406 6.2892 9.87934 5.37793 9.87934H1.77793C0.86666 9.87934 0.12793 9.1406 0.12793 8.22933V1.9293Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.77793 14.3809C1.69509 14.3809 1.62793 14.448 1.62793 14.5309V16.3309C1.62793 16.4137 1.69509 16.4809 1.77793 16.4809H5.37793C5.46077 16.4809 5.52793 16.4137 5.52793 16.3309V14.5309C5.52793 14.448 5.46077 14.3809 5.37793 14.3809H1.77793ZM0.12793 14.5309C0.12793 13.6196 0.86666 12.8809 1.77793 12.8809H5.37793C6.2892 12.8809 7.02793 13.6196 7.02793 14.5309V16.3309C7.02793 17.2421 6.2892 17.9809 5.37793 17.9809H1.77793C0.86666 17.9809 0.12793 17.2421 0.12793 16.3309V14.5309Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5777 9.88087C12.4949 9.88087 12.4277 9.94802 12.4277 10.0309V16.3309C12.4277 16.4137 12.4949 16.4809 12.5777 16.4809H16.1777C16.2606 16.4809 16.3277 16.4137 16.3277 16.3309V10.0309C16.3277 9.94802 16.2606 9.88087 16.1777 9.88087H12.5777ZM10.9277 10.0309C10.9277 9.11959 11.6665 8.38086 12.5777 8.38086H16.1777C17.089 8.38086 17.8277 9.11959 17.8277 10.0309V16.3309C17.8277 17.2422 17.089 17.9809 16.1777 17.9809H12.5777C11.6665 17.9809 10.9277 17.2422 10.9277 16.3309V10.0309Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5777 1.7793C12.4949 1.7793 12.4277 1.84646 12.4277 1.9293V3.72931C12.4277 3.81215 12.4949 3.87931 12.5777 3.87931H16.1777C16.2606 3.87931 16.3277 3.81215 16.3277 3.72931V1.9293C16.3277 1.84646 16.2606 1.7793 16.1777 1.7793H12.5777ZM10.9277 1.9293C10.9277 1.01803 11.6665 0.279297 12.5777 0.279297H16.1777C17.089 0.279297 17.8277 1.01803 17.8277 1.9293V3.72931C17.8277 4.64058 17.089 5.37932 16.1777 5.37932H12.5777C11.6665 5.37932 10.9277 4.64058 10.9277 3.72931V1.9293Z"
        fill="currentColor"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_12000_38526">
        <Rect width={18} height={18.0001} fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)
