import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import { UserProfile } from './types'
import { ResponseError, ResponseSuccess } from '../../types'

const KEY_GET_PROFILE = 'getProfile'

export const useGetProfileQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<UserProfile>>, AxiosError<ResponseError>>(
    KEY_GET_PROFILE,
    requests.getProfile,
    { enabled: true }
  )

export const useUpdateProfileMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse,
    AxiosError,
    Pick<UserProfile, 'profile_image_file_name' | 'phone_number_cell'>
  >(requests.updateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_GET_PROFILE])
    }
  })
}
