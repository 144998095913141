import { FunctionComponent, useMemo } from 'react'

import { Box, useTheme } from 'native-base'
import { Dimensions, Platform } from 'react-native'
import { getNumberYearAndMonth, percentageCalculate } from 'src/utils'
import { currencyFormatChart, toFixedNoRounding } from 'src/utils/currencyFormat'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryTheme } from 'victory-native'

export type ChartData = {
  value: number
  year_month: string
}[]

export type MultipleBarsChartProps = {
  colors: string[]
  chartsData: ChartData[]
  tickValues?: string[]
  horizontalLabels?: boolean
  onClickChart?(monthYear: string): void
  type?: 'percentage' | 'currency'
}

export const MultipleBarsChart: FunctionComponent<MultipleBarsChartProps> = ({
  chartsData,
  colors,
  horizontalLabels,
  tickValues,
  onClickChart,
  type
}) => {
  const theme = useTheme()

  const lengthData = chartsData.length

  const isMobile = Platform.OS !== 'web'

  // we use this constant to remedy the limitations of the charts library in relation to responsiveness
  const phoneWidth = Dimensions.get('window').width
  //

  const needsLabel = {
    yes: true,
    no: false
  }

  const needsShortcut = {
    yes: true,
    no: false
  }

  const higherValue = useMemo(
    () =>
      chartsData
        .map((chartData) => chartData.sort(({ value: a }, { value: b }) => b - a)[0].value)
        .sort((a, b) => b - a)[0],
    [chartsData]
  )

  const tickValuesAxis =
    type === 'percentage'
      ? [0, 50, 100]
      : type === 'currency'
      ? [0, Number(toFixedNoRounding(higherValue * 0.5, 1)), higherValue]
      : []

  const chartMobileProps = isMobile
    ? {
        theme: VictoryTheme.material,
        width: horizontalLabels ? phoneWidth * 0.9 : phoneWidth
      }
    : {}

  return (
    <Box mt={isMobile ? -12 : 0} ml={horizontalLabels ? 0 : isMobile ? -8 : 0}>
      <VictoryChart
        height={210}
        domain={{ x: [1, 3] }}
        domainPadding={{ x: 32 }}
        {...chartMobileProps}>
        {horizontalLabels && (
          <VictoryAxis
            dependentAxis
            tickValues={tickValuesAxis}
            tickFormat={(t) =>
              t && type === 'currency'
                ? currencyFormatChart(String(t), needsLabel.yes, needsLabel.no)
                : type === 'percentage'
                ? `${t}%`
                : t
            }
            style={{
              tickLabels: {
                fontSize: 12,
                fill: theme.colors.gray[500],
                padding: -0
              },
              axisLabel: {
                stroke: 0
              },
              ticks: { size: 8, stroke: 0 },
              axis: { stroke: 0 },
              grid: {
                //@ts-ignore
                strokeWidth: ({ tickValue }) => (tickValue === 0 ? 1 : 0),
                stroke: theme.colors.gray[100]
              }
            }}
            standalone={false}
          />
        )}
        <VictoryAxis
          tickValues={tickValues}
          tickFormat={(t) => String(t).split('-')[0]}
          orientation="bottom"
          style={{
            tickLabels: {
              fontSize: 14,
              padding: 10,
              fill: theme.colors.gray[500]
            },
            ticks: { size: 0 },
            axis: { stroke: 0 },
            grid: { stroke: 0 }
          }}
          standalone={false}
        />
        <VictoryGroup offset={lengthData < 4 ? (lengthData < 3 ? 40 : 34) : 26}>
          {chartsData.length > 0 &&
            chartsData.map((chart, index) => (
              <VictoryBar
                key={String(Math.random())}
                data={chart}
                x="year_month"
                y="value"
                name={String(colors[index])}
                barWidth={lengthData < 4 ? (lengthData < 3 ? 28 : 24) : 22}
                domainPadding={{ x: horizontalLabels ? 34 : 0 }}
                cornerRadius={{
                  top: ({ datum }) => {
                    const percentage = percentageCalculate(higherValue ?? 0, datum.value ?? 0)

                    // 4 charts
                    if (lengthData === 4) {
                      if (percentage > 13) return 11

                      if (percentage > 8) return 8

                      if (percentage > 3) return 4
                    }

                    // 3 charts
                    if (lengthData === 3) {
                      if (percentage > 13) return 12

                      if (percentage > 8) return 8

                      if (percentage > 5) return 4
                    }

                    // 2 charts
                    if (lengthData === 2) {
                      if (percentage > 13) return 14

                      if (percentage > 9) return 10

                      if (percentage > 5) return 6

                      if (percentage > 3) return 4
                    }

                    return 2
                  },
                  bottom: ({ datum }) => {
                    const percentage = percentageCalculate(higherValue ?? 0, datum.value ?? 0)

                    // 4 charts
                    if (lengthData === 4) {
                      if (percentage > 13) return 11

                      if (percentage > 8) return 8

                      if (percentage > 3) return 4
                    }

                    // 3 charts
                    if (lengthData === 3) {
                      if (percentage > 13) return 12

                      if (percentage > 8) return 8

                      if (percentage > 5) return 4
                    }

                    // 2 charts
                    if (lengthData === 2) {
                      if (percentage > 13) return 14

                      if (percentage > 9) return 10

                      if (percentage > 5) return 6

                      if (percentage > 3) return 4
                    }

                    return 2
                  }
                }}
                style={{
                  data: { fill: colors[index] },
                  //@ts-ignore
                  labels: {
                    fontSize: 12,
                    fontWeight: 500,
                    //@ts-ignore
                    fill: String(colors[index])
                  }
                }}
                labels={({ datum }) =>
                  type === 'currency'
                    ? currencyFormatChart(datum.value, needsLabel.no, needsShortcut.yes)
                    : type === 'percentage'
                    ? `${datum.value}%`
                    : datum.value
                }
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onPressOut: () => [
                        {
                          target: 'data',

                          mutation: (props) =>
                            onClickChart?.(
                              getNumberYearAndMonth(props.datum.year_month).monthNumber
                            )
                        }
                      ]
                    }
                  }
                ]}
              />
            ))}
        </VictoryGroup>
      </VictoryChart>
    </Box>
  )
}
