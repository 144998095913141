import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path, Rect, Defs, Stop, LinearGradient } from 'react-native-svg'

export const EcosystemTurboIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 32 32', ...props }}>
    <Rect width={32} height={32} rx={16} fill="url(#a)" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.117 15.244a4.085 4.085 0 0 0-.547-1.873l-2.975-5.13A4.09 4.09 0 0 1 16.125 7L16 7.214a4.088 4.088 0 0 0 0 4.104l2.38 4.105a4.09 4.09 0 0 1 0 4.104l-.124.214a4.087 4.087 0 0 0-.471-1.24l-3.57-6.156a4.092 4.092 0 0 1-.47-1.24l-.125.214a4.09 4.09 0 0 0-.007 4.09v.001L16 19.527a4.09 4.09 0 0 1 0 4.104l-.124.215a4.09 4.09 0 0 0-.47-1.24l-2.976-5.131a4.088 4.088 0 0 1-.547-2.23c-3.185.828-5.383 2.587-5.383 4.624C6.5 22.702 10.753 25 16 25s9.5-2.297 9.5-5.13c0-2.037-2.198-3.796-5.383-4.625Z"
      fill="#fff"
    />
    <Defs>
      <LinearGradient
        id="a"
        x1={11.947}
        y1={-3.626}
        x2={19.752}
        y2={19.91}
        gradientUnits="userSpaceOnUse">
        <Stop stopColor="#F3123C" />
        <Stop offset={1} stopColor="#C00" />
        <Stop offset={1} stopColor="#FF393C" />
      </LinearGradient>
    </Defs>
  </Icon>
)
