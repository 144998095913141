import { Suspense, lazy } from 'react'

import { RootStackScreenComponent } from 'src/navigation'

import { useAcceptTermsScreen } from './useAcceptTermsScreen'

const AcceptTermsScreenMobile = lazy(() => import('./AcceptTermsScreen.mobile'))

const AcceptTermsScreenDesktop = lazy(() => import('./AcceptTermsScreen.desktop'))

export const AcceptTermsScreen: RootStackScreenComponent<'AcceptTerms'> = ({ navigation }) => {
  const props = useAcceptTermsScreen({ navigation })

  const Screen = props.isMobile ? AcceptTermsScreenMobile : AcceptTermsScreenDesktop

  return (
    <Suspense>
      <Screen {...props} />
    </Suspense>
  )
}
