import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ShareIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 18 19', ...props }}>
    <Path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.427.915a.75.75 0 0 1 0 1.06L3.612 8.79a4.65 4.65 0 0 0 .172 6.74 4.859 4.859 0 0 0 6.61-.369l.011-.01 5.193-5.194a2.403 2.403 0 0 0-.3-3.65 2.515 2.515 0 0 0-3.201.363l-.012.012L9.34 9.426a1.95 1.95 0 0 0 0 2.757.75.75 0 1 1-1.06 1.06 3.45 3.45 0 0 1 0-4.878l2.739-2.74a4.015 4.015 0 0 1 5.138-.549 3.903 3.903 0 0 1 .502 5.941l-5.188 5.188a6.359 6.359 0 0 1-8.68.449 6.15 6.15 0 0 1-.24-8.926L9.368.915a.75.75 0 0 1 1.06 0Z"
      clipRule="evenodd"
    />
  </Icon>
)
