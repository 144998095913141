import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const CalendarIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 25 25', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.9248 1.75C6.9248 1.19772 6.47709 0.75 5.9248 0.75C5.37252 0.75 4.9248 1.19772 4.9248 1.75V3.1499H3.525C1.64723 3.1499 0.125 4.67213 0.125 6.5499V20.9499C0.125 22.8277 1.64723 24.3499 3.525 24.3499H20.325C22.2028 24.3499 23.725 22.8277 23.725 20.9499V6.5499C23.725 4.67213 22.2028 3.1499 20.325 3.1499H18.9248V1.75C18.9248 1.19772 18.4771 0.75 17.9248 0.75C17.3725 0.75 16.9248 1.19772 16.9248 1.75V3.1499H6.9248V1.75ZM21.725 10.35V6.5499C21.725 5.7767 21.0982 5.1499 20.325 5.1499H18.9248V6.55C18.9248 7.10228 18.4771 7.55 17.9248 7.55C17.3725 7.55 16.9248 7.10228 16.9248 6.55V5.1499H6.9248V6.55C6.9248 7.10228 6.47709 7.55 5.9248 7.55C5.37252 7.55 4.9248 7.10228 4.9248 6.55V5.1499H3.525C2.7518 5.1499 2.125 5.7767 2.125 6.5499V10.35H21.725ZM2.125 12.35H21.725V20.9499C21.725 21.7231 21.0982 22.3499 20.325 22.3499H3.525C2.7518 22.3499 2.125 21.7231 2.125 20.9499V12.35Z"
      fill="currentColor"
    />
  </Icon>
)
