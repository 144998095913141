import { useEffect, useRef } from 'react'

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Subscription } from 'expo-modules-core'
import * as Notifications from 'expo-notifications'
import { KEY_NOT_READ_NOTIFICATIONS_QUERY } from 'integration/resources/notifications'
import { RootStackParamList } from 'navigation/types'
import { useQueryClient } from 'react-query'
import { LogEvent } from 'src/utils/logEvents'

export enum NOTIFICATION_TYPES {
  WALLET = 'WALLET',
  CONTENT = 'CONTENT',
  ORGANIZATION = 'ORGANIZATION',
  OPPORTUNITY = 'OPPORTUNITY',
  COMMUNICATION = 'COMMUNICATION',
  BIRTHDATE = 'BIRTHDATE'
}

type TDataNotification = {
  type: string
  notification_id: string
  extras?: any
}

type TParams = { id: string; extras?: any }

export const NotificationComponent = () => {
  const responseListener = useRef<Subscription>()

  const responseListenerReceived = useRef<Subscription>()

  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const queryClient = useQueryClient()

  useEffect(() => {
    // when user received a push notification, so we invalidate list amount not read
    responseListenerReceived.current = Notifications.addNotificationReceivedListener(() => {
      queryClient.invalidateQueries(KEY_NOT_READ_NOTIFICATIONS_QUERY)
    })

    responseListener.current = Notifications.addNotificationResponseReceivedListener(
      (response: any) => {
        // when user tap on notification
        const data = response.notification.request.content.data as TDataNotification

        if (data.notification_id) {
          let params: TParams = { id: data.notification_id }

          if (data.extras) {
            params = { ...params, extras: data.extras }
          }

          LogEvent('notificacao_push', {
            item_id: data.notification_id
          })

          navigation.navigate('NotificationDetail', params)

          return
        }

        LogEvent('notificacao_clicou_push', {
          item_id: data.notification_id
        })

        navigation.navigate('Notification')
      }
    )

    return () => {
      if (responseListener.current)
        Notifications.removeNotificationSubscription(responseListener.current)

      if (responseListenerReceived.current)
        Notifications.removeNotificationSubscription(responseListenerReceived.current)
    }
  }, [navigation, queryClient])

  return <></>
}
