import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { NoteDetailsScreenComponent } from './NoteDetailsScreen.types'
import { useNoteDetailsScreen } from './useNoteDetailsScreen'

const NoteDetailsScreenDesktop = lazy(() => import('./NoteDetailsScreen.desktop'))

const NoteDetailsScreenMobile = lazy(() => import('./NoteDetailsScreen.mobile'))

export const NoteDetailsScreen: NoteDetailsScreenComponent = ({ navigation, route }) => {
  const noteDetailsScreen = useNoteDetailsScreen({ navigation, route })

  const Screen = noteDetailsScreen.isMobile ? NoteDetailsScreenMobile : NoteDetailsScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...noteDetailsScreen} />
    </Suspense>
  )
}
