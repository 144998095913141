import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { HomeContentScreenComponent } from './HomeContentScreen.types'
import { useHomeContentScreen } from './useHomeContentScreen'

const HomeContentScreenDesktop = lazy(() => import('./HomeContentScreen.desktop'))

const HomeContentScreenMobile = lazy(() => import('./HomeContentScreen.mobile'))

export const HomeContentScreen: HomeContentScreenComponent = ({ navigation }) => {
  const HomeContentScreen = useHomeContentScreen({ navigation })

  const Screen = HomeContentScreen.isMobile ? HomeContentScreenMobile : HomeContentScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...HomeContentScreen} />
    </Suspense>
  )
}
