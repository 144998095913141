import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { format, lastDayOfYear } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import {
  useGetSimulationOptionsQuery,
  useSendSimulationMutation
} from 'integration/resources/simulator'
import { BottomSheetModal } from 'molecules'
import { useBreakpointValue, useDisclose, useToast } from 'native-base'
import { Toast } from 'organisms'
import { currencyFormat } from 'src/utils/currencyFormat'
import { LogEvent } from 'src/utils/logEvents'

import { UseSimulatorScreen } from './SimulatorScreen.types'

export const useSimulatorScreen: UseSimulatorScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [moves, setMoves] = useState(0)

  const toast = useToast()

  const { mutateAsync, isLoading: sendSimulationIsLoading } = useSendSimulationMutation()

  const { data: simulationOptions, isLoading, isFetching, isError } = useGetSimulationOptionsQuery()

  const showMessageNoOpportunities = !isLoading && isError

  const includeSheetRef = useRef<BottomSheetModal>(null)

  const { isOpen: isOpenModalInclude, onOpen, onClose } = useDisclose(false)

  const openIncludeSheet = useCallback(() => {
    // eslint-disable-next-line @babel/no-unused-expressions
    isMobile ? includeSheetRef.current?.present() : onOpen()
  }, [isMobile, onOpen])

  const closeIncludeSheet = useCallback(async () => {
    LogEvent('simulador_recusou_oportunidades_simulada', {
      item_id: ''
    })

    if (isMobile) {
      includeSheetRef.current?.close()

      includeSheetRef.current?.dismiss()
    } else {
      onClose()
    }
  }, [isMobile, onClose])

  const warningSheetRef = useRef<BottomSheetModal>(null)

  const openWarningSheet = useCallback(() => {
    warningSheetRef.current?.present()
  }, [])

  const closeWarningSheet = useCallback(() => {
    warningSheetRef.current?.close()

    warningSheetRef.current?.dismiss()
  }, [])

  const maxSheetRef = useRef<BottomSheetModal>(null)

  const openMaxSheet = useCallback(() => {
    maxSheetRef.current?.present()
  }, [])

  const closeMaxSheet = useCallback(() => {
    maxSheetRef.current?.close()

    maxSheetRef.current?.dismiss()
  }, [])

  const simulatorData = simulationOptions?.data.data

  const simulationActive = simulatorData?.active_simulation_item?.value
    ? simulatorData.active_simulation_item
    : {
        value: 0,
        opportunities: '0',
        points: 100
      }

  const counterOpportunities = {
    totalPoints: simulationActive.points ?? 100,
    total: simulatorData?.total_count ?? 0,
    current: simulatorData?.finished_count ?? 0
  }

  useEffect(() => {
    if (isFetching || simulationActive?.points !== simulatorData?.maxValue) {
      closeMaxSheet()
    } else {
      isMobile && openMaxSheet()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationActive?.points, simulatorData?.maxValue, isFetching])

  const initialValue = currencyFormat(simulationActive?.value?.toFixed(2))

  const initialOpportunities = simulationActive?.opportunities

  const sliderItem = {
    sliderValue: simulationActive?.points ?? 100,
    minSliderValue: simulatorData?.minValue ?? 100,
    maxSliderValue: simulatorData?.maxValue ?? 100,
    trackMarks: simulatorData?.trackMarks ?? [],
    step: 10
  }

  const [sliderValue, setSliderValue] = useState(sliderItem.sliderValue)

  const [simulationValue, setSimulationValue] = useState(currencyFormat('000'))

  const [opportunities, setOpportunities] = useState('0')

  const changeOpportunity = (sliderValue: number) => {
    const opportunities =
      simulatorData?.active_simulation === sliderValue
        ? '0'
        : simulatorData?.simulations.find((simulation) => simulation.points === sliderValue)
            ?.opportunities

    setOpportunities(opportunities ?? '0')
  }

  const handleUpdateSliderValue = async (value: number) => {
    if (!isMobile && sliderValue > 140 && sliderValue === sliderItem.sliderValue) {
      openMaxSheet()

      return false
    }

    setSliderValue(value)

    changeOpportunity(value)
  }

  const handleSlideComplete = (value: number) => {
    return value !== simulationActive?.points && !!simulatorData
      ? setSimulationValue(
          currencyFormat(
            simulatorData.simulations
              .filter((simulation, index) => simulation.points === value)[0]
              .value.toFixed(2)
          )
        )
      : setSimulationValue(currencyFormat('000'))
  }

  const handleAccept = (value: number) => {
    mutateAsync(
      { simulation: value },
      {
        onError: () => {
          closeIncludeSheet()

          toast.show({
            render: () => (
              <Toast type="error" text="Não foi possível completar a ação" placement="top-left" />
            ),
            placement: 'top-left'
          })

          handleGoBack()
        },
        onSuccess: async (response) => {
          closeIncludeSheet()

          LogEvent('simulador_confirmou_oportunidades_simulada', {
            item_id: response?.data?.data?.id || ''
          })

          toast.show({
            render: () => (
              <Toast
                type="success"
                text={`Você adicionou mais ${opportunities} oportunidades  na sua carteira 💵 💰`}
                placement="top-left"
              />
            ),
            placement: 'top-left'
          })

          setTimeout(() => {
            handleGoHome()
          }, 1000)
        }
      }
    )
  }

  const handleGoBack = () => {
    closeMaxSheet()

    navigation.goBack()
  }

  const handleGoHome = useCallback(async () => {
    LogEvent('simulador_saiu_da_tela_simulador', {
      item_id: ''
    })

    maxSheetRef.current?.close()

    navigation.navigate('Tab', { screen: 'Home', params: { refetch: true } })
  }, [navigation])

  const isDisabledButton = useMemo(
    () => simulationActive.points === sliderValue,
    [simulationActive.points, sliderValue]
  )

  const lastDate = format(lastDayOfYear(new Date()), "EEEEEE. dd 'de' MMMM 'de' yyyy ", {
    locale: ptBR
  })

  const lastDayOfYearFormated = lastDate.charAt(0).toUpperCase() + lastDate.slice(1)

  const maxMoves = useMemo(
    () => sliderValue > 140 && sliderValue === sliderItem.sliderValue,
    [sliderValue, sliderItem.sliderValue]
  )

  return {
    isMobile,
    simulationOptionsIsLoading: isLoading || isFetching,
    sendSimulationIsLoading,
    simulationValue,
    sliderItem,
    sliderValue,
    includeSheetRef,
    closeIncludeSheet,
    openIncludeSheet,
    warningSheetRef,
    maxSheetRef,
    handleUpdateSliderValue,
    handleSlideComplete,
    handleAccept,
    handleGoHome,
    setMoves: (value) => [setMoves(value), value === 1 ? openWarningSheet() : closeWarningSheet()],
    moves,
    initialValue,
    initialOpportunities,
    opportunities,
    isDisabledButton,
    isOpenModalInclude,
    lastDayOfYearFormated,
    counterOpportunities,
    showMessageNoOpportunities,
    maxMoves
  }
}
