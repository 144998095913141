import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const SuccessIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      d="M18.6032 6.3299C18.7056 6.22545 18.8278 6.14247 18.9626 6.08582C19.0975 6.02918 19.2422 6 19.3885 6C19.5347 6 19.6795 6.02918 19.8144 6.08582C19.9492 6.14247 20.0714 6.22545 20.1737 6.3299C20.6027 6.7634 20.6087 7.4639 20.1887 7.9049L11.3192 18.3899C11.2185 18.5005 11.0963 18.5894 10.9601 18.6511C10.8239 18.7128 10.6765 18.7461 10.5269 18.7489C10.3774 18.7517 10.2289 18.724 10.0904 18.6674C9.95197 18.6108 9.82652 18.5266 9.72174 18.4199L4.32474 12.9509C4.11659 12.7386 4 12.4532 4 12.1559C4 11.8586 4.11659 11.5732 4.32474 11.3609C4.42711 11.2565 4.54929 11.1735 4.68412 11.1168C4.81896 11.0602 4.96374 11.031 5.10999 11.031C5.25624 11.031 5.40102 11.0602 5.53586 11.1168C5.67069 11.1735 5.79287 11.2565 5.89524 11.3609L10.4732 16.0004L18.5732 6.3629C18.5826 6.35131 18.5926 6.34029 18.6032 6.3299Z"
      fill="currentColor"
    />
  </Icon>
)
