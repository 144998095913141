import { FunctionComponent } from 'react'

import { useGetProductionHistoryMonthsQuery } from 'integration/resources/production'
import { Select, Skeleton, VStack } from 'native-base'

type TSelectMonthsProps = {
  value?: string
  onSelect(value: string): void
}

export const SelectMonthsProductionHistory: FunctionComponent<TSelectMonthsProps> = ({
  value,
  onSelect
}) => {
  const { data, isLoading } = useGetProductionHistoryMonthsQuery({
    isMobile: false
  })

  return (
    <VStack testID="divDropdown">
      {isLoading ? (
        <Skeleton h={10} maxW="204px" isLoaded={!isLoading} rounded="full" />
      ) : (
        <Select
          maxW="190px"
          h={10}
          selectedValue={value}
          placeholder="Selecione"
          onValueChange={onSelect}
          borderWidth={0}>
          {data?.data.map((item) => (
            <Select.Item
              fontSize="16px"
              color="gray.700"
              value={String(item.value)}
              label={item.label}
              key={item.value}
            />
          ))}
        </Select>
      )}
    </VStack>
  )
}
