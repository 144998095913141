import { useGetContactItemlQuery } from 'integration/resources/wallet'
import { useBreakpointValue } from 'native-base'

import { UseContactDetailsScreen } from './ContactDetailsScreen.types'

export const useContactDetailsScreen: UseContactDetailsScreen = ({ route, navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { contactId } = route.params

  const { data, isLoading } = useGetContactItemlQuery(contactId)

  const contact = data?.data.data

  return {
    isLoading,
    isMobile,
    contact
  }
}
