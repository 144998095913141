import * as React from 'react'

import Svg, { Path } from 'react-native-svg'

const DataOrganizationEmptyIllustration = () => (
  <Svg width={251} height={250} viewBox="0 0 251 250" fill="none">
    <Path
      d="M60.247 115.269 60 159.022l56.431 28.837 74.082-9.446-.497-44.499-73.585 9.198-56.183-27.843Z"
      fill="#FAFAFA"
    />
    <Path
      d="m115.905 143.232 24.866-3.222v-38.506l-76.118 9.975v5.427l51.252 26.326Z"
      fill="#DBDBDB"
    />
    <Path
      d="m115.905 143.232 24.866-3.222v-38.506l-76.118 9.975v5.427l51.252 26.326Z"
      stroke="#737373"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="m115.905 143.232 47.975-6.218v-24.087l-76.119 9.975v5.874l28.144 14.456Z"
      fill="#DBDBDB"
    />
    <Path
      d="m115.905 143.232 47.975-6.218v-24.087l-76.119 9.975v5.874l28.144 14.456Z"
      stroke="#737373"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="m115.905 143.232 70.585-9.15v-9.731l-76.119 9.975v6.064l5.534 2.842Z" fill="#DBDBDB" />
    <Path
      d="m115.905 143.232 70.585-9.15v-9.731l-76.119 9.975v6.064l5.534 2.842Z"
      stroke="#737373"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M140.771 95.352v6.125l-76.118 9.975c5.568-3.326 6.192-3.893 7.813-4.085l37.05-4.94 6.864-8.312a5.198 5.198 0 0 1 3.301-1.829l15.2-2.066a5.181 5.181 0 0 1 5.89 5.132Z"
      fill="#F3123C"
    />
    <Path
      d="M140.771 95.352v6.125l-76.118 9.975c5.568-3.326 6.192-3.893 7.813-4.085l37.05-4.94 6.864-8.312a5.198 5.198 0 0 1 3.301-1.829l15.2-2.066a5.181 5.181 0 0 1 5.89 5.132Z"
      stroke="#737373"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M163.875 106.777v6.15l-76.125 9.975c5.569-3.326 6.192-3.893 7.814-4.085l37.061-4.94 6.864-8.312a5.192 5.192 0 0 1 3.301-1.829l15.2-2.066a5.167 5.167 0 0 1 5.885 5.107Z"
      fill="#F3123C"
    />
    <Path
      d="M163.875 106.777v6.15l-76.125 9.975c5.569-3.326 6.192-3.893 7.814-4.085l37.061-4.94 6.864-8.312a5.192 5.192 0 0 1 3.301-1.829l15.2-2.066a5.167 5.167 0 0 1 5.885 5.107Z"
      stroke="#737373"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M186.49 118.2v6.125l-76.115 9.977c5.569-3.326 6.192-3.893 7.814-4.085l37.05-4.916 6.863-8.313a5.2 5.2 0 0 1 3.302-1.828l15.2-2.067a5.163 5.163 0 0 1 4.105 1.215 5.168 5.168 0 0 1 1.781 3.892Z"
      fill="#F3123C"
    />
    <Path
      d="M186.49 118.2v6.125l-76.115 9.977c5.569-3.326 6.192-3.893 7.814-4.085l37.05-4.916 6.863-8.313a5.2 5.2 0 0 1 3.302-1.828l15.2-2.067a5.17 5.17 0 0 1 5.886 5.107ZM115.905 143.232v44.413l74.385-9.643V133.59l-74.385 9.642Z"
      stroke="#737373"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="m60.282 114.661 55.623 28.571v44.437l-55.623-28.572v-44.436Z"
      stroke="#737373"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M155.282 164.322a3.088 3.088 0 1 0 0-6.175 3.088 3.088 0 0 0 0 6.175Z"
      fill="#F3123C"
    />
    <Path
      d="M155.282 164.322a3.088 3.088 0 1 0 0-6.175 3.088 3.088 0 0 0 0 6.175Z"
      stroke="#737373"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M100.515 66.187c11.294 0 20.449-9.155 20.449-20.448 0-11.294-9.155-20.45-20.449-20.45-11.293 0-20.449 9.156-20.449 20.45 0 11.293 9.156 20.448 20.449 20.448Z"
      fill="#FAFAFA"
      stroke="#737373"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M92.042 35.543a1.019 1.019 0 0 0-.953.657l-1.611 4.229a2.04 2.04 0 0 0-.015 1.412 2.805 2.805 0 0 0 5.445-.945 2.804 2.804 0 0 0 5.607 0 2.803 2.803 0 1 0 5.607 0 2.808 2.808 0 0 0 2.326 2.76 2.805 2.805 0 0 0 3.119-1.815 2.03 2.03 0 0 0-.016-1.412l-1.61-4.23a1.023 1.023 0 0 0-.953-.656H92.042ZM93.335 46.758h7.136v6.117h-7.136v-6.117ZM102.51 55.934v-8.666a.509.509 0 0 1 .51-.51h4.078a.512.512 0 0 1 .51.51v8.666"
      stroke="#F3123C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M109.647 43.606v11.308a1.017 1.017 0 0 1-1.02 1.02H92.315a1.02 1.02 0 0 1-1.02-1.02V43.581"
      stroke="#F3123C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M27.199 123.686c11.293 0 20.448-9.155 20.448-20.449 0-11.293-9.155-20.448-20.448-20.448-11.294 0-20.449 9.155-20.449 20.448 0 11.294 9.155 20.449 20.449 20.449Z"
      fill="#FAFAFA"
      stroke="#737373"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M18.682 92.536a1.019 1.019 0 0 0-.953.657l-1.611 4.229a2.04 2.04 0 0 0-.015 1.412 2.805 2.805 0 0 0 5.444-.945 2.804 2.804 0 0 0 5.608 0 2.804 2.804 0 1 0 5.607 0 2.804 2.804 0 0 0 5.445.945 2.033 2.033 0 0 0-.016-1.412l-1.61-4.229a1.018 1.018 0 0 0-.953-.657H18.682ZM19.974 103.751h7.137v6.117h-7.137v-6.117ZM29.15 112.927v-8.666a.508.508 0 0 1 .51-.51h4.078a.509.509 0 0 1 .51.51v8.666"
      stroke="#F3123C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M36.287 100.599v11.309a1.018 1.018 0 0 1-1.02 1.019H18.955a1.017 1.017 0 0 1-1.02-1.019v-11.334"
      stroke="#F3123C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M27.199 222.486c11.293 0 20.448-9.155 20.448-20.449 0-11.293-9.155-20.448-20.448-20.448-11.294 0-20.449 9.155-20.449 20.448 0 11.294 9.155 20.449 20.449 20.449ZM223.801 157.886c11.294 0 20.449-9.155 20.449-20.449 0-11.293-9.155-20.448-20.449-20.448-11.293 0-20.448 9.155-20.448 20.448 0 11.294 9.155 20.449 20.448 20.449Z"
      fill="#FAFAFA"
      stroke="#737373"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M215.328 127.242a1.019 1.019 0 0 0-.952.657l-1.612 4.229a2.035 2.035 0 0 0-.014 1.412 2.801 2.801 0 0 0 1.229 1.475 2.797 2.797 0 0 0 3.555-.617 2.802 2.802 0 0 0 .66-1.804 2.803 2.803 0 1 0 5.607 0 2.804 2.804 0 0 0 5.608 0 2.8 2.8 0 0 0 2.325 2.761c.65.113 1.32-.007 1.89-.34a2.801 2.801 0 0 0 1.229-1.475 2.035 2.035 0 0 0-.015-1.412l-1.611-4.229a1.023 1.023 0 0 0-.952-.657h-16.947ZM216.621 138.457h7.136v6.117h-7.136v-6.117ZM225.797 147.633v-8.666a.508.508 0 0 1 .509-.51h4.078a.508.508 0 0 1 .51.51v8.666"
      stroke="#F3123C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M232.933 135.304v11.309a1.019 1.019 0 0 1-1.019 1.02h-16.313a1.021 1.021 0 0 1-1.019-1.02V135.28"
      stroke="#F3123C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.89 82.765c0-.345.28-.625.625-.625h123.286c.345 0 .625.28.625.625v34.224a.625.625 0 0 1-1.25 0v-33.6H100.515a.625.625 0 0 1-.625-.624ZM27.199 123.037c.345 0 .625.28.625.625v57.927a.625.625 0 0 1-1.25 0v-57.927c0-.345.28-.625.625-.625ZM155.282 181.914c.346 0 .625.279.625.625v19.498a.624.624 0 0 1-.625.625H47.647a.624.624 0 1 1 0-1.25h107.01v-18.873c0-.346.28-.625.625-.625Z"
      fill="#737373"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.515 65.539c.345 0 .625.28.625.625v37.358a.625.625 0 1 1-1.25 0V66.164c0-.346.28-.625.625-.625Z"
      fill="#737373"
    />
    <Path
      d="m190.29 209.186-.024 16.079M198.318 217.237l-16.079-.023M44.75 48.66a2.969 2.969 0 1 0 0-5.938 2.969 2.969 0 0 0 0 5.938Z"
      stroke="#737373"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M18.726 191.842a1.02 1.02 0 0 0-.953.657l-1.612 4.229a2.039 2.039 0 0 0-.014 1.412 2.805 2.805 0 0 0 5.444-.945 2.804 2.804 0 0 0 5.608 0 2.804 2.804 0 0 0 5.607 0 2.803 2.803 0 0 0 2.325 2.76 2.804 2.804 0 0 0 3.12-1.815 2.032 2.032 0 0 0-.016-1.412l-1.61-4.229a1.02 1.02 0 0 0-.953-.657H18.726ZM20.018 203.057h7.137v6.117h-7.137v-6.117ZM29.194 212.233v-8.666a.51.51 0 0 1 .51-.51h4.078a.512.512 0 0 1 .51.51v8.666"
      stroke="#F3123C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M36.33 199.905v11.308a1.018 1.018 0 0 1-1.019 1.02H19a1.02 1.02 0 0 1-1.02-1.02V199.88"
      stroke="#F3123C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export const DataOrganizationEmpty = React.memo(DataOrganizationEmptyIllustration)
