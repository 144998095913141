import { AxiosResponse } from 'axios'
import { ResponseSuccess } from 'src/integration/types'

import { Store } from './types'
import { ProductionStoresList } from '../production'

export const getStoreList: (
  response: AxiosResponse<ResponseSuccess<ProductionStoresList>>
) => AxiosResponse<ResponseSuccess<Store[]>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: response.data.data.items
  }
})
