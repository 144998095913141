import { useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useBreakpointValue } from 'native-base'
import { useForm } from 'react-hook-form'
import fieldsValidation from 'src/constants/fields-validation'
import { useAuthAtomValue, useSetAuthAtom } from 'src/store/auth'
import * as Yup from 'yup'

import { TFormData, UseRemoveAccountConfirmScreen } from './RemoveAccountConfirmScreen.types'

const schema = Yup.object().shape({
  email: Yup.string()
    .email(fieldsValidation.email.invalid)
    .oneOf([Yup.ref('email'), null], 'E-mail não confere com o atual')
    .required(fieldsValidation.common.required)
})

export const useRemoveAccountConfirmScreen: UseRemoveAccountConfirmScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const authAtomValue = useAuthAtomValue()

  let timeout: NodeJS.Timeout

  const [showActionSheetConfirmation, setShowActionSheetConfirmation] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const setAuthAtom = useSetAuthAtom()

  const {
    control,
    handleSubmit: submit,
    formState,
    reset,
    setValue
  } = useForm<TFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const { isValid } = formState

  const isDisabled = useMemo(() => !isValid, [isValid])

  const handleSubmit = submit(() => setShowActionSheetConfirmation(true))

  const handleConfirmRemoveAccount = () => {
    setIsLoading(true)

    timeout = setTimeout(() => {
      setIsLoading(false)

      reset()

      setShowActionSheetConfirmation(false)

      setAuthAtom(undefined)
    }, 500)
  }

  const handleCancelRemoveAccount = () => setShowActionSheetConfirmation(false)

  useEffect(() => {
    return () => {
      if (timeout) clearTimeout(timeout)

      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (authAtomValue?.user.email) {
      setValue('email', authAtomValue?.user.email)
    }
  }, [authAtomValue?.user.email, setValue])

  return {
    isMobile,
    control,
    handleSubmit,
    isLoading,
    isDisabled,
    handleConfirmRemoveAccount,
    handleCancelRemoveAccount,
    showActionSheetConfirmation
  }
}
