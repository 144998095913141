export const APP_VERSION_PLATFORM_ANDROID_TYPE = 1

export const APP_VERSION_PLATFORM_IOS_TYPE = 2

export const APP_VERSION_PLATFORM_BOTH_TYPE = 3

export const APP_VERSION_PLATFORM_TYPE_SELECT_OPTIONS = [
  {
    id: APP_VERSION_PLATFORM_ANDROID_TYPE,
    name: 'android'
  },
  {
    id: APP_VERSION_PLATFORM_IOS_TYPE,
    name: 'ios'
  },
  {
    id: APP_VERSION_PLATFORM_BOTH_TYPE,
    name: 'ambos'
  }
]

export default {
  APP_VERSION_PLATFORM_TYPE_SELECT_OPTIONS
}
