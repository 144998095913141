import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const AllIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 21 20', ...props }}>
    <Path
      d="M20.2111 1.68229C20.0445 1.31563 19.8111 0.998958 19.5195 0.732292C19.2195 0.465625 18.8778 0.265625 18.4945 0.140625C18.1111 0.015625 17.7195 -0.0260417 17.3195 0.015625C14.0028 0.365625 11.7445 1.21563 10.6195 2.50729C9.49447 1.21563 7.23613 0.365625 3.91947 0.015625C3.51947 -0.0260417 3.1278 0.015625 2.74447 0.140625C2.36113 0.265625 2.01947 0.465625 1.71947 0.732292C1.41947 0.998958 1.18613 1.32396 1.0278 1.68229C0.869466 2.04896 0.786133 2.43229 0.786133 2.83229V12.924C0.786133 13.6323 1.04447 14.3156 1.51947 14.8406C1.99447 15.3656 2.6528 15.6906 3.36113 15.7573C4.86947 15.8906 9.78613 16.5156 9.78613 18.8323C9.78613 19.2906 10.1611 19.6656 10.6195 19.6656C11.0778 19.6656 11.4528 19.2906 11.4528 18.8323C11.4528 16.5156 16.3695 15.8906 17.8778 15.7573C18.5861 15.6906 19.2361 15.3656 19.7195 14.8406C20.2028 14.3156 20.4611 13.6323 20.4528 12.924V2.83229C20.4528 2.43229 20.3695 2.04896 20.2111 1.68229ZM9.78613 15.9156C8.44447 14.974 6.3278 14.349 3.51113 14.099C3.21947 14.074 2.9528 13.9406 2.7528 13.724C2.5528 13.5073 2.44447 13.224 2.4528 12.9323V2.83229C2.4528 2.67396 2.48613 2.50729 2.5528 2.35729C2.61947 2.20729 2.71947 2.07396 2.83613 1.96562C2.96113 1.85729 3.1028 1.77396 3.2528 1.72396C3.36947 1.68229 3.48613 1.66563 3.61113 1.66563C3.6528 1.66563 3.69447 1.66563 3.73613 1.66563C5.5528 1.85729 9.7778 2.54063 9.7778 4.69896V15.9073L9.78613 15.9156ZM18.7861 12.9323C18.7861 13.224 18.6778 13.5073 18.4861 13.724C18.2861 13.9406 18.0195 14.074 17.7278 14.099C14.9111 14.3573 12.7945 14.974 11.4528 15.9156V4.70729C11.4528 2.54896 15.6778 1.86563 17.4945 1.67396C17.5361 1.67396 17.5778 1.67396 17.6195 1.67396C17.7445 1.67396 17.8611 1.69062 17.9778 1.73229C18.1361 1.78229 18.2778 1.86563 18.3945 1.97396C18.5195 2.08229 18.6111 2.21563 18.6778 2.36562C18.7445 2.51562 18.7778 2.68229 18.7778 2.84063V12.9323H18.7861Z"
      fill="currentColor"
    />
  </Icon>
)
