import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { HomeScreenComponent } from './HomeScreen.types'
import { useHomeScreen } from './useHomeScreen'

const HomeScreenDesktop = lazy(() => import('./HomeScreen.desktop'))

const HomeScreenMobile = lazy(() => import('./HomeScreen.mobile'))

export const HomeScreen: HomeScreenComponent = ({ navigation, route }) => {
  const props = useHomeScreen({ navigation, route })

  const Screen = props.isMobile ? HomeScreenMobile : HomeScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
