import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const AttachmentIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.903 0.316424C13.2935 0.707 13.2934 1.34016 12.9029 1.73064L3.81646 10.8146C3.22075 11.4102 2.75265 12.1209 2.44073 12.9034C2.12881 13.6859 1.97961 14.5237 2.00224 15.3658C2.02487 16.2078 2.21885 17.0364 2.57234 17.801C2.92339 18.5604 3.42445 19.2407 4.04529 19.8012C5.30199 20.8467 6.90767 21.3785 8.54038 21.2897C10.1789 21.2006 11.7226 20.4929 12.8596 19.3097L12.8735 19.2955L19.7974 12.3716C19.7975 12.3716 19.7974 12.3717 19.7974 12.3716C20.1268 12.0422 20.3804 11.6447 20.5404 11.2072C20.7004 10.7696 20.763 10.3024 20.7238 9.83816C20.6846 9.37391 20.5446 8.9238 20.3135 8.51923C20.085 8.11902 19.7727 7.77292 19.3982 7.50451C18.7449 7.07417 17.9615 6.8857 17.1836 6.97202C16.4007 7.05889 15.6734 7.41857 15.1291 7.98799L15.1133 8.00414L11.4535 11.664C11.4534 11.6641 11.4535 11.664 11.4535 11.664C10.9661 12.1516 10.6922 12.8129 10.6922 13.5022C10.6922 14.1917 10.966 14.8529 11.4535 15.3404C11.8439 15.731 11.8438 16.3642 11.4532 16.7547C11.0627 17.1451 10.4295 17.145 10.039 16.7544C9.17666 15.8918 8.69221 14.722 8.69221 13.5022C8.69221 12.2825 9.17666 11.1127 10.039 10.25L13.6916 6.5975C14.5595 5.69346 15.7172 5.12247 16.963 4.98422C18.2128 4.84554 19.4715 5.1512 20.5185 5.84761C20.5269 5.85318 20.5351 5.85888 20.5433 5.8647C21.1606 6.30269 21.6749 6.8701 22.0502 7.52733C22.4256 8.18456 22.653 8.91578 22.7167 9.66996C22.7803 10.4241 22.6787 11.1831 22.4188 11.894C22.1588 12.6048 21.747 13.2504 21.2118 13.7857L14.2945 20.703C12.8072 22.247 10.79 23.1703 8.64903 23.2867C6.50464 23.4034 4.39588 22.702 2.74863 21.3241C2.7397 21.3166 2.73089 21.309 2.72222 21.3012C1.89318 20.5569 1.22449 19.6516 0.756966 18.6403C0.289441 17.6291 0.032886 16.5332 0.0029592 15.4195C-0.0269676 14.3058 0.170362 13.1977 0.582903 12.1628C0.995445 11.1279 1.61455 10.1879 2.40242 9.40025L11.4888 0.316237C11.8794 -0.0742353 12.5126 -0.0741518 12.903 0.316424Z"
      fill="currentColor"
    />
  </Icon>
)
