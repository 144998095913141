import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const CalendarAttentionIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <G clipPath="url(#clip0_13909_22665)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99996 7.35039C9.41417 7.35039 9.74996 7.68618 9.74996 8.10039V11.7004C9.74996 12.1146 9.41417 12.4504 8.99996 12.4504C8.58575 12.4504 8.24996 12.1146 8.24996 11.7004V8.10039C8.24996 7.68618 8.58575 7.35039 8.99996 7.35039Z"
        fill="currentColor"
      />
      <Path
        d="M8.99996 17.1004C8.76127 17.1004 8.53235 17.0056 8.36357 16.8368C8.19479 16.668 8.09996 16.4391 8.09996 16.2004C8.09996 15.9617 8.19479 15.7328 8.36357 15.564C8.53235 15.3952 8.76127 15.3004 8.99996 15.3004"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99996 16.0504C8.96018 16.0504 8.92203 16.0662 8.8939 16.0943C8.86577 16.1225 8.84996 16.1606 8.84996 16.2004C8.84996 16.2402 8.86577 16.2783 8.8939 16.3065C8.92203 16.3346 8.96018 16.3504 8.99996 16.3504V17.8504C8.56236 17.8504 8.14267 17.6766 7.83324 17.3671C7.52381 17.0577 7.34997 16.638 7.34997 16.2004C7.34997 15.7628 7.52381 15.3431 7.83324 15.0337C8.14267 14.7242 8.56236 14.5504 8.99996 14.5504V16.0504Z"
        fill="currentColor"
      />
      <Path
        d="M8.99996 17.1004C9.23866 17.1004 9.46757 17.0056 9.63635 16.8368C9.80514 16.668 9.89996 16.4391 9.89996 16.2004C9.89996 15.9617 9.80514 15.7328 9.63635 15.564C9.46757 15.3952 9.23866 15.3004 8.99996 15.3004"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99996 14.5504C9.43757 14.5504 9.85725 14.7242 10.1667 15.0337C10.4761 15.3431 10.65 15.7628 10.65 16.2004C10.65 16.638 10.4761 17.0577 10.1667 17.3671C9.85725 17.6766 9.43757 17.8504 8.99996 17.8504V16.3504C9.03975 16.3504 9.0779 16.3346 9.10603 16.3065C9.13416 16.2783 9.14996 16.2402 9.14996 16.2004C9.14996 16.1606 9.13416 16.1225 9.10603 16.0943C9.0779 16.0662 9.03975 16.0504 8.99996 16.0504V14.5504Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.633269 0.633664C0.942703 0.324229 1.36239 0.150391 1.79999 0.150391H16.1999C16.6375 0.150391 17.0572 0.324229 17.3667 0.633664C17.6761 0.943101 17.8499 1.36278 17.8499 1.80039V12.6004C17.8499 13.038 17.6761 13.4577 17.3667 13.7671C17.0572 14.0766 16.6375 14.2504 16.1999 14.2504H13.4999C13.0857 14.2504 12.7499 13.9146 12.7499 13.5004C12.7499 13.0862 13.0857 12.7504 13.4999 12.7504H16.1999C16.2397 12.7504 16.2779 12.7346 16.306 12.7065C16.3341 12.6783 16.3499 12.6402 16.3499 12.6004V1.80039C16.3499 1.76061 16.3341 1.72245 16.306 1.69432C16.2779 1.66619 16.2397 1.65039 16.1999 1.65039H1.79999C1.76021 1.65039 1.72206 1.66619 1.69393 1.69432C1.66579 1.72245 1.64999 1.76061 1.64999 1.80039V12.6004C1.64999 12.6402 1.66579 12.6783 1.69393 12.7065C1.72206 12.7346 1.76021 12.7504 1.79999 12.7504H4.49998C4.91419 12.7504 5.24998 13.0862 5.24998 13.5004C5.24998 13.9146 4.91419 14.2504 4.49998 14.2504H1.79999C1.36239 14.2504 0.942703 14.0766 0.633269 13.7671C0.323836 13.4577 0.149998 13.038 0.149998 12.6004V1.80039C0.149998 1.36278 0.323836 0.943099 0.633269 0.633664Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.149998 4.50039C0.149998 4.08618 0.485783 3.75039 0.899995 3.75039H17.0999C17.5141 3.75039 17.8499 4.08618 17.8499 4.50039C17.8499 4.9146 17.5141 5.25039 17.0999 5.25039H0.899995C0.485783 5.25039 0.149998 4.9146 0.149998 4.50039Z"
        fill="currentColor"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_13909_22665">
        <Rect width={17.9999} height={18} fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)
