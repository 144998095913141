import { useCallback } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useBreakpointValue } from 'native-base'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard } from 'react-native'
import { MaskService } from 'react-native-masked-text'
import { validateCPF } from 'src/utils/validators'
import * as Yup from 'yup'

import { UseCreateAccountCPFScreen, TFormData } from './CPFScreen.types'

const schema = Yup.object().shape({
  cpf: Yup.string()
    .test('valid_cpf', 'CPF inválido', (value) => {
      if (!value) return false

      const isValid = validateCPF(value)

      return isValid
    })
    .required('Digite seu CPF')
})

export const useCreateAccountCPFScreen: UseCreateAccountCPFScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<TFormData>({
    resolver: yupResolver(schema)
  })

  const onSubmit = handleSubmit(
    useCallback<SubmitHandler<TFormData>>(
      (data) => {
        Keyboard.dismiss()

        navigation.navigate('CreateAccountPhoneEmail', {
          cpf: MaskService.toRawValue('cpf', data.cpf)
        })
      },
      [navigation]
    )
  )

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  return { control, onSubmit, isMobile, handleGoBack, isValid }
}
