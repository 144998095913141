import { RootStackScreenComponent } from 'navigation/types'
import { AuthCodeScreen } from 'src/components/templates'
import { AuthCodeScreenTemplate } from 'templates/AuthCodeScreenTemplate/AuthCodeScreenTemplateDesktop'

import { useLoginAuthCodeScreen } from './useLoginAuthCodeScreen'

export const LoginAuthCodeScreen: RootStackScreenComponent<'LoginAuthCode'> = ({ navigation }) => {
  const props = useLoginAuthCodeScreen()

  const Template = props.isMobile ? AuthCodeScreen : AuthCodeScreenTemplate

  return <Template {...props} />
}
