import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const WalletsManagementIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 19', ...props }}>
    <Path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.2 12.702a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v4.05a.75.75 0 0 1-.75.75H4.95a.75.75 0 0 1-.75-.75v-4.05Zm1.5.75v2.55h2.55v-2.55H5.7Z"
      clipRule="evenodd"
    />
    <Path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.225 8.652a.75.75 0 0 1 .75-.75h4.05a.75.75 0 0 1 .75.75v4.05a.75.75 0 0 1-.75.75h-4.05a.75.75 0 0 1-.75-.75v-4.05Zm1.5.75v2.55h2.55v-2.55h-2.55Z"
      clipRule="evenodd"
    />
    <Path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.25 12.702a.75.75 0 0 1 .75-.75h4.05a.75.75 0 0 1 .75.75v4.05a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v-4.05Zm1.5.75v2.55h2.55v-2.55H9.75Z"
      clipRule="evenodd"
    />
    <Path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.584 1.548a.75.75 0 0 1 .832 0l7.29 4.86a.75.75 0 0 1 .334.624v8.1a2.37 2.37 0 0 1-2.37 2.37H3.33a2.37 2.37 0 0 1-2.37-2.37v-8.1a.75.75 0 0 1 .334-.624l7.29-4.86ZM2.46 7.433v7.699a.87.87 0 0 0 .87.87h11.34a.87.87 0 0 0 .87-.87V7.433L9 3.073l-6.54 4.36Z"
      clipRule="evenodd"
    />
  </Icon>
)
