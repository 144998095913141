import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const CarIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 19', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.6969 12.1578C3.17512 11.6796 3.82372 11.4109 4.50002 11.4109C5.17633 11.4109 5.82493 11.6796 6.30315 12.1578C6.78136 12.636 7.05002 13.2846 7.05002 13.9609C7.05002 14.2958 6.98407 14.6274 6.85592 14.9368C6.72777 15.2462 6.53994 15.5273 6.30315 15.7641C6.06636 16.0008 5.78525 16.1887 5.47587 16.3168C5.16648 16.445 4.83489 16.5109 4.50002 16.5109C4.16516 16.5109 3.83356 16.445 3.52418 16.3168C3.2148 16.1887 2.93369 16.0008 2.6969 15.7641C2.46011 15.5273 2.27228 15.2462 2.14413 14.9368C2.01598 14.6274 1.95002 14.2958 1.95002 13.9609C1.95002 13.2846 2.21868 12.636 2.6969 12.1578ZM4.50002 12.9109C4.22155 12.9109 3.95448 13.0216 3.75756 13.2185C3.56065 13.4154 3.45002 13.6825 3.45002 13.9609C3.45002 14.0988 3.47718 14.2354 3.52995 14.3628C3.58272 14.4901 3.66006 14.6059 3.75756 14.7034C3.85507 14.8009 3.97082 14.8782 4.09821 14.931C4.2256 14.9838 4.36213 15.0109 4.50002 15.0109C4.63791 15.0109 4.77445 14.9838 4.90184 14.931C5.02923 14.8782 5.14498 14.8009 5.24249 14.7034C5.33999 14.6059 5.41733 14.4901 5.4701 14.3628C5.52286 14.2354 5.55002 14.0988 5.55002 13.9609C5.55002 13.6825 5.4394 13.4154 5.24249 13.2185C5.04557 13.0216 4.7785 12.9109 4.50002 12.9109Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6969 12.1578C12.1751 11.6796 12.8237 11.4109 13.5 11.4109C14.1763 11.4109 14.8249 11.6796 15.3031 12.1578C15.7814 12.636 16.05 13.2846 16.05 13.9609C16.05 14.6372 15.7814 15.2858 15.3031 15.7641C14.8249 16.2423 14.1763 16.5109 13.5 16.5109C12.8237 16.5109 12.1751 16.2423 11.6969 15.7641C11.2187 15.2858 10.95 14.6372 10.95 13.9609C10.95 13.2846 11.2187 12.636 11.6969 12.1578ZM13.5 12.9109C13.2215 12.9109 12.9545 13.0216 12.7576 13.2185C12.5606 13.4154 12.45 13.6825 12.45 13.9609C12.45 14.2394 12.5606 14.5065 12.7576 14.7034C12.9545 14.9003 13.2215 15.0109 13.5 15.0109C13.7785 15.0109 14.0456 14.9003 14.2425 14.7034C14.4394 14.5065 14.55 14.2394 14.55 13.9609C14.55 13.6825 14.4394 13.4154 14.2425 13.2185C14.0456 13.0216 13.7785 12.9109 13.5 12.9109Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.55002 13.9609C5.55002 13.5467 5.88581 13.2109 6.30002 13.2109H11.7C12.1142 13.2109 12.45 13.5467 12.45 13.9609C12.45 14.3752 12.1142 14.7109 11.7 14.7109H6.30002C5.88581 14.7109 5.55002 14.3752 5.55002 13.9609Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.31212 4.38513C3.54123 4.27058 3.79387 4.21094 4.05002 4.21094H11.0772C11.4128 4.21094 11.7405 4.31328 12.0164 4.50432C12.2924 4.69536 12.5035 4.966 12.6216 5.28014L13.5699 7.81094H16.2C16.6376 7.81094 17.0573 7.98478 17.3668 8.29421C17.6762 8.60365 17.85 9.02333 17.85 9.46094V13.0609C17.85 13.4985 17.6762 13.9182 17.3668 14.2277C17.0573 14.5371 16.6376 14.7109 16.2 14.7109H15.3C14.8858 14.7109 14.55 14.3752 14.55 13.9609C14.55 13.5467 14.8858 13.2109 15.3 13.2109H16.2C16.2398 13.2109 16.278 13.1951 16.3061 13.167C16.3342 13.1389 16.35 13.1007 16.35 13.0609V9.46094C16.35 9.42115 16.3342 9.383 16.3061 9.35487C16.278 9.32674 16.2398 9.31094 16.2 9.31094H13.05C12.7373 9.31094 12.4574 9.11692 12.3477 8.8241L11.2176 5.80814C11.2176 5.80803 11.2177 5.80824 11.2176 5.80814C11.2069 5.77972 11.1876 5.75491 11.1626 5.73761C11.1375 5.72024 11.1077 5.71094 11.0772 5.71094H4.05002C4.02674 5.71094 4.00377 5.71636 3.98294 5.72677C3.96214 5.73718 3.94403 5.75228 3.93007 5.77088C3.93005 5.7709 3.93008 5.77086 3.93007 5.77088L1.68007 8.77148C1.66059 8.79744 1.65002 8.82908 1.65002 8.86154V13.0609C1.65002 13.1007 1.66583 13.1389 1.69396 13.167C1.72209 13.1951 1.76024 13.2109 1.80002 13.2109H2.70002C3.11424 13.2109 3.45002 13.5467 3.45002 13.9609C3.45002 14.3752 3.11424 14.7109 2.70002 14.7109H1.80002C1.36242 14.7109 0.942734 14.5371 0.633298 14.2277C0.323862 13.9182 0.150024 13.4985 0.150024 13.0609V8.86154C0.150024 8.50455 0.265802 8.15719 0.479981 7.87159C0.479996 7.87158 0.479967 7.87161 0.479981 7.87159L2.72998 4.871C2.88367 4.66607 3.08301 4.49969 3.31212 4.38513Z"
      fill="currentColor"
    />
  </Icon>
)
