import { useCallback, useEffect } from 'react'

import { useLinkTo } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import {
  KEY_NOT_READ_NOTIFICATIONS_QUERY,
  TNotificationType,
  useGetDetailNotificationQuery
} from 'integration/resources/notifications'
import { useBreakpointValue } from 'native-base'
import { Platform } from 'react-native'
import { useQueryClient } from 'react-query'
import { useSetAuthAtom } from 'src/store/auth'
import { useSetTokenAtom } from 'src/store/token'

import { UseNotificationDetailScreen } from './NotificationDetailScreen.types'

export const useNotificationDetailScreen: UseNotificationDetailScreen = ({ navigation, route }) => {
  const queryClient = useQueryClient()

  const { id } = route?.params

  const linkTo = useLinkTo()

  const { data, isLoading } = useGetDetailNotificationQuery({ id })

  const notification = data?.data.data

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  const setAuthAtom = useSetAuthAtom()

  const setAuthToken = useSetTokenAtom()

  const logout = useCallback(() => {
    setAuthAtom(null)

    setAuthToken(null)

    queryClient.removeQueries()
  }, [queryClient, setAuthAtom, setAuthToken])

  const hiddenButton =
    (notification?.notification_type_enum === 'COMMUNICATION' &&
      !notification?.extras?.contentId) ||
    (!isMobile && notification?.notification_type_enum === 'APP_VERSION')

  const selectTypeNotification = (type: TNotificationType = 'WALLET') => {
    const object = {
      WALLET: {
        title: 'Carteira',
        titleButton: 'Ver carteira',
        handleAction: () => navigation.navigate('Tab', { screen: 'Wallet', params: { tabId: 1 } })
      },
      CONTENT: {
        title: 'Conteúdos',
        titleButton: 'Ver conteúdo',
        handleAction: () => {
          if (notification?.extras?.contentId)
            navigation.navigate('Content', { id: notification?.extras?.contentId })
        }
      },
      ORGANIZATION: {
        title: 'Minha organização',
        titleButton: 'Ver tarefas',
        handleAction: () =>
          navigation.navigate('Tab', { screen: 'Organization', params: { tabId: 1 } })
      },
      OPPORTUNITY: {
        title: 'Minha organização',
        titleButton: notification?.extras?.contentId ? 'Ver oportunidade' : 'Ver oportunidades',
        handleAction: () => {
          if (notification?.extras?.contentId) {
            navigation.navigate('OrganizationOpportunity', {
              id: notification.extras.contentId
            })

            return
          }

          return isMobile
            ? navigation.navigate('Tab', { screen: 'Organization', params: { tabId: 2 } })
            : linkTo('/minha-organizacao/oportunidades/5')
        }
      },
      ACTIVITY: {
        title: 'Minha organização',
        titleButton: notification?.extras?.contentId ? 'Ver tarefa' : 'Ver tarefas',
        handleAction: () => {
          if (notification?.extras?.contentId) {
            navigation.navigate('OrganizationActivity', {
              id: notification?.extras.contentId
            })

            return
          }

          return isMobile
            ? navigation.navigate('Tab', { screen: 'Organization', params: { tabId: 2 } })
            : linkTo('/minha-organizacao/tarefas')
        }
      },
      COMMUNICATION: {
        title: 'Comunicado institucional',
        titleButton: 'Ver comunicado',
        handleAction: () => {
          if (notification?.extras?.contentId) {
            return navigation.navigate('Content', { id: notification?.extras?.contentId })
          }

          return navigation.navigate('HomeContent')
        }
      },
      BIRTHDATE: {
        title: 'Aniversários',
        titleButton: 'Ver contato',
        //@ts-ignore
        handleAction: () => {
          if (notification?.extras?.contactId) {
            navigation.navigate('WalletContact', { contactId: notification.extras?.contactId })
          }
        }
      },
      APP_VERSION: {
        title: 'Comunicado de versão',
        titleButton: 'Baixar agora!',
        titleOptionalButton: 'Lembrar depois',
        handleAction: () => {
          let url = ''

          if (Platform.OS === 'android') {
            url =
              'https://play.google.com/store/apps/details?id=com.webmotors.portalmaisturbo&hl=pt-br'
          } else if (Platform.OS === 'ios')
            url = 'https://apps.apple.com/br/app/portal-mais-turbo/id6443906405'

          logout()

          return url ? Linking.openURL(url) : ''
        },
        handleActionOptional: () => {
          return isMobile ? navigation.navigate('Tab', { screen: 'Notification' }) : linkTo('/home')
        }
      },
      SURVEY: {
        title: 'Pesquisas',
        titleButton: 'Ver pesquisa',
        handleAction: () => {
          if (notification?.extras?.surveyId)
            navigation.navigate('SurveyDetail', { id: notification?.extras?.surveyId })
        }
      },
      DEFAULT: {
        title: 'Notificação não configurada',
        titleButton: 'Ver detalhes',
        handleAction: () => {}
      }
    }

    return type in object ? object[type] : object['DEFAULT']
  }

  useEffect(() => {
    queryClient.invalidateQueries(KEY_NOT_READ_NOTIFICATIONS_QUERY)
  }, [queryClient])

  return {
    isMobile,
    isLoading,
    handleGoBack,
    notification,
    selectTypeNotification,
    hiddenButton
  }
}
