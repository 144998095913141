import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ResetPasswordEmailSentSuccessfullyScreenComponent } from './ResetPasswordEmailSentSuccessfullyScreen.types'
import { useResetPasswordEmailSentSuccessfullyScreen } from './useResetPasswordEmailSentSuccessfullyScreen'

const ResetPasswordEmailSentSuccessfullyScreenDesktop = lazy(
  () => import('./ResetPasswordEmailSentSuccessfullyScreen.desktop')
)

const ResetPasswordEmailSentSuccessfullyScreenMobile = lazy(
  () => import('./ResetPasswordEmailSentSuccessfullyScreen.mobile')
)

export const ResetPasswordEmailSentSuccessfullyScreen: ResetPasswordEmailSentSuccessfullyScreenComponent =
  ({ navigation, route }) => {
    const props = useResetPasswordEmailSentSuccessfullyScreen({ navigation, route })

    const Screen = props.isMobile
      ? ResetPasswordEmailSentSuccessfullyScreenMobile
      : ResetPasswordEmailSentSuccessfullyScreenDesktop

    return (
      <Suspense fallback={<LoadingTurbo />}>
        <Screen {...props} />
      </Suspense>
    )
  }
