// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const MoneyShieldIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 20 20', ...props }}>
    <G clipPath="url(#clip0_14047_199202)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99935 1.83073C2.68993 1.83073 2.39318 1.95365 2.17439 2.17244C1.9556 2.39123 1.83268 2.68798 1.83268 2.9974V6.4974C1.83244 9.01449 2.59415 11.4728 4.0176 13.5487C5.44068 15.6242 7.45854 17.2203 9.80581 18.1273C9.93562 18.1767 10.0791 18.1767 10.2089 18.1273C12.551 17.2239 14.5648 15.6325 15.9851 13.5626C17.4057 11.4923 18.166 9.04026 18.166 6.5294V2.9974C18.166 2.68798 18.0431 2.39123 17.8243 2.17244C17.6055 1.95365 17.3088 1.83073 16.9993 1.83073H2.99935ZM0.99588 0.993927C1.52723 0.462574 2.2479 0.164062 2.99935 0.164062H16.9993C17.7508 0.164062 18.4715 0.462574 19.0028 0.993927C19.5342 1.52528 19.8327 2.24595 19.8327 2.9974V6.5294C19.8327 9.37689 18.9704 12.1576 17.3594 14.5056C15.7483 16.8535 13.464 18.6585 10.8072 19.6829L10.805 19.6838C10.2914 19.8802 9.72334 19.8802 9.20973 19.6838L9.2071 19.6828C6.54515 18.6546 4.25678 16.8447 2.64303 14.4913C1.0293 12.1378 0.165758 9.35098 0.166016 6.4974V2.9974C0.166016 2.24595 0.464527 1.52528 0.99588 0.993927Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99935 11.6041C10.4596 11.6041 10.8327 11.9772 10.8327 12.4374V13.8854C10.8327 14.3456 10.4596 14.7187 9.99935 14.7187C9.53911 14.7187 9.16602 14.3456 9.16602 13.8854V12.4374C9.16602 11.9772 9.53911 11.6041 9.99935 11.6041Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99935 3.65406C10.4596 3.65406 10.8327 4.02716 10.8327 4.4874V5.9354C10.8327 6.39563 10.4596 6.76873 9.99935 6.76873C9.53911 6.76873 9.16602 6.39563 9.16602 5.9354V4.4874C9.16602 4.02716 9.53911 3.65406 9.99935 3.65406Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.16602 7.3874C7.16602 6.1878 8.07888 5.10406 9.33935 5.10406H10.5051C10.8434 5.10639 11.177 5.18396 11.4816 5.3311C11.7863 5.47825 12.0544 5.69131 12.2665 5.95486C12.5551 6.31337 12.4984 6.83796 12.1399 7.12655C11.7814 7.41514 11.2568 7.35845 10.9682 6.99994C10.911 6.92893 10.8388 6.87152 10.7567 6.83188C10.6753 6.79256 10.5863 6.77168 10.4959 6.77073H9.33935C9.11982 6.77073 8.83268 6.983 8.83268 7.3874C8.83268 7.72303 9.0467 7.94543 9.24448 7.99275L11.0098 8.41212L11.011 8.41241C12.1197 8.67332 12.8327 9.7066 12.8327 10.8114C12.8327 12.1039 11.8488 13.2687 10.4993 13.2687H9.49935C8.63973 13.2687 7.91372 12.7833 7.51515 12.1061C7.28173 11.7094 7.41405 11.1986 7.81069 10.9652C8.20734 10.7318 8.71812 10.8641 8.95155 11.2607C9.08498 11.4875 9.29497 11.6021 9.49935 11.6021H10.4993C10.8059 11.6021 11.166 11.3109 11.166 10.8114C11.166 10.3886 10.8995 10.0982 10.6289 10.0347L10.6267 10.0342L8.85875 9.61417C7.83259 9.36937 7.16602 8.41163 7.16602 7.3874Z"
        fill="currentColor"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_14047_199202">
        <Rect width={20} height={20} fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)
