import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { OnboardingScreenComponent } from './OnboardingScreen.types'
import { useOnboardingScreen } from './useOnboardingScreen'

const OnboardingScreenDesktop = lazy(() => import('./OnboardingScreen.desktop'))

const OnboardingScreenMobile = lazy(() => import('./OnboardingScreen.mobile'))

export const OnboardingScreen: OnboardingScreenComponent = ({ navigation, route }) => {
  const onboardingScreen = useOnboardingScreen({ navigation, route })

  const Screen = onboardingScreen.isMobile ? OnboardingScreenMobile : OnboardingScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...onboardingScreen} />
    </Suspense>
  )
}
