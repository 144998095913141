import { FunctionComponent, Suspense, lazy } from 'react'

import { Skeleton } from 'native-base'

import { useXRay } from './useXRay'
import { XRayProps } from './XRay.types'

const XRayDesktop = lazy(() => import('./XRay.desktop'))

const XRayMobile = lazy(() => import('./XRay.mobile'))

export const XRay: FunctionComponent<XRayProps> = (props) => {
  const xRay = useXRay(props)

  const Component = xRay.isMobile ? XRayMobile : XRayDesktop

  return (
    <Suspense fallback={<Skeleton minHeight="104px" mb={4} borderRadius="20px" />}>
      <Component {...xRay} />
    </Suspense>
  )
}
