import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const ProductIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 21 20', ...props }}>
    <G clipPath="url(#clip0_3568_12373)">
      <Path
        d="M19.6226 10.7076L12.2086 3.29356C12.1157 3.20039 12.0053 3.12647 11.8838 3.07602C11.7624 3.02557 11.6321 2.99958 11.5006 2.99956H5.50056C5.32552 2.99939 5.15127 3.02294 4.98256 3.06956L2.20856 0.293556C2.11571 0.20058 2.00546 0.126802 1.88411 0.0764337C1.76275 0.0260652 1.63266 9.31088e-05 1.50126 2.50222e-07C1.2359 -0.000187286 0.98133 0.105048 0.793556 0.292556C0.605783 0.480064 0.500188 0.734485 0.5 0.999849C0.499813 1.26521 0.605048 1.51978 0.792556 1.70756L3.56856 4.48356C3.52351 4.65186 3.50065 4.82533 3.50056 4.99956V10.9996C3.50032 11.131 3.52599 11.2611 3.57609 11.3826C3.6262 11.5041 3.69975 11.6145 3.79256 11.7076L11.2086 19.1216C11.7711 19.684 12.5341 19.9999 13.3296 19.9999C14.1251 19.9999 14.888 19.684 15.4506 19.1216L19.6226 14.9516C19.9013 14.673 20.1225 14.3422 20.2734 13.9781C20.4243 13.6139 20.5019 13.2237 20.5019 12.8296C20.5019 12.4354 20.4243 12.0452 20.2734 11.6811C20.1225 11.317 19.9013 10.9862 19.6226 10.7076ZM8.87656 9.98356C8.57989 9.98356 8.28988 9.89558 8.0432 9.73076C7.79653 9.56594 7.60427 9.33167 7.49074 9.05758C7.37721 8.78349 7.3475 8.48189 7.40538 8.19092C7.46326 7.89995 7.60612 7.63268 7.8159 7.4229C8.02568 7.21312 8.29295 7.07026 8.58392 7.01238C8.87489 6.9545 9.17649 6.98421 9.45058 7.09774C9.72467 7.21127 9.95894 7.40353 10.1238 7.6502C10.2886 7.89687 10.3766 8.18688 10.3766 8.48356C10.3766 8.88138 10.2185 9.26291 9.93722 9.54422C9.65591 9.82552 9.27438 9.98356 8.87656 9.98356Z"
        fill="#FEECEF"
      />
    </G>
    <G clipPath="url(#clip1_3568_12373)">
      <Path
        d="M19.3354 10.6688L11.9354 3.24375C11.7771 3.08542 11.5688 3.00208 11.3438 3.00208H5.35208C5.15208 2.99375 4.96042 3.03542 4.77708 3.09375L1.92708 0.24375C1.60208 -0.08125 1.06875 -0.08125 0.74375 0.24375C0.41875 0.56875 0.41875 1.10208 0.74375 1.42708L3.60208 4.28542C3.55208 4.46042 3.51042 4.63542 3.51042 4.82708V10.8271C3.50208 11.0521 3.58542 11.2604 3.74375 11.4187L11.1438 18.8438C11.6771 19.3771 12.3854 19.6771 13.1438 19.6771C13.9021 19.6771 14.6104 19.3854 15.1438 18.8521L19.3188 14.6854C19.8521 14.1521 20.1521 13.4438 20.1521 12.6854C20.1521 11.9271 19.8604 11.2188 19.3271 10.6854L19.3354 10.6688ZM18.1521 13.4938L13.9771 17.6604C13.5354 18.1021 12.7688 18.1021 12.3271 17.6604L5.16875 10.4771V5.85208L7.74375 8.41875C7.91042 8.58542 8.11875 8.66042 8.33542 8.66042C8.55208 8.66042 8.76042 8.57708 8.92708 8.41875C9.25208 8.09375 9.25208 7.56875 8.92708 7.24375L6.34375 4.66042H11.0021L18.1604 11.8521C18.3771 12.0688 18.5021 12.3604 18.5021 12.6771C18.5021 12.9938 18.3771 13.2854 18.1604 13.5021L18.1521 13.4938Z"
        fill="#F3123C"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_3568_12373">
        <Rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </ClipPath>
      <ClipPath id="clip1_3568_12373">
        <Rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </ClipPath>
    </Defs>
  </Icon>
)
