import { useCallback, useRef } from 'react'

import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { Cards } from 'atoms'
import { ActivitiesItem } from 'integration/resources/newOpportunities'
import { Box } from 'native-base'
import { formatDateStringToMask } from 'src/utils'

import { UseRelatedTasks } from './RelatedTasks.types'

export const useRelatedTasks: UseRelatedTasks = ({
  opportunityDetails,
  isLoading,
  isMobile,
  handleOpenActivitiesListModal,
  activitiesListModalIsOpen,
  handleCloseActivitiesListModal,
  handleGoToCreateActivity,
  handleGoToActivity
}) => {
  const renderActivityCard = useCallback(
    (activity: ActivitiesItem, changeBgColor?: boolean) => (
      <Cards.OrganizationCard
        expandedDetail
        bgColor={changeBgColor ? 'gray.50' : 'white'}
        isLoadingFavorite={false}
        topText={activity?.title}
        midText="Minha tarefa"
        bottomText={
          activity?.schedule_date_start && activity?.schedule_date_end
            ? `${formatDateStringToMask(
                activity?.schedule_date_start,
                'dd/MM/yyyy'
              )} até ${formatDateStringToMask(activity?.schedule_date_end, 'dd/MM/yyyy')}`
            : activity.schedule_date
            ? formatDateStringToMask(activity?.schedule_date, 'dd/MM/yyyy')
            : undefined
        }
        hour={formatDateStringToMask(activity?.schedule_date, 'HH:mm')}
        active={activity?.is_finished}
        icon="Check"
        onPress={() => handleGoToActivity(activity?.id)}
        // onCheckPress={(callback, currentCheck) =>
        //   handleComplete(callback, currentCheck, activity.id)
        // }
      />
    ),
    [handleGoToActivity]
  )

  const data = opportunityDetails?.activities

  const renderItem = useCallback(
    ({ item, changeBgColor }: { item: ActivitiesItem; changeBgColor?: boolean }) => {
      return (
        <Box key={`key-${item.title}`} mb={4} px={2}>
          {renderActivityCard(item, changeBgColor)}
        </Box>
      )
    },
    [renderActivityCard]
  )

  const relatedSheetRef = useRef<BottomSheetModal>(null)

  const openRelatedSheet = useCallback(() => {
    relatedSheetRef.current?.present()
  }, [])

  return {
    opportunityDetails,
    isLoading,
    isMobile,
    handleOpenActivitiesListModal,
    activitiesListModalIsOpen,
    handleCloseActivitiesListModal,
    handleGoToCreateActivity,
    data,
    openRelatedSheet,
    renderItem,
    relatedSheetRef,
    renderActivityCard
  }
}
