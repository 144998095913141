import React from 'react'

import { Document, Font } from '@react-pdf/renderer'
import { TWalletsManagementReport } from 'integration/resources/walletsManagement'
import { capitalizeText } from 'src/utils'

import { AssignedStoresPage } from './AssignedStores'
import { CoverPage } from './CoverPage'
import { UnassignedStoresPage } from './UnassignedStores'
import { correctDate } from './utils'
//@ts-ignore
import OpenSansBold from '../../../../../assets/openSans.bold.ttf'
//@ts-ignore
import OpenSansRegular from '../../../../../assets/openSans.regular.ttf'

Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: OpenSansRegular,
      fontWeight: 'normal'
    },
    {
      src: OpenSansBold,
      fontWeight: 'bold'
    }
  ]
})

export const ReportPDF: React.FC<TWalletsManagementReport> = ({
  header,
  assignedStores,
  unassignedStores
}) => {
  return (
    <Document>
      <CoverPage
        referenceMonth={correctDate(String(header?.yearMonth))}
        mainLeader={capitalizeText(header?.leaderName ?? '')}
        periodStartDate={header?.startedAt}
        periodEndDate={header?.finishedAt}
      />

      {assignedStores &&
        Object.entries(assignedStores).map(([specialist, stores], index) => (
          <AssignedStoresPage
            key={index}
            specialistName={capitalizeText(specialist)}
            header={{ ...header }}
            referenceMonth={correctDate(String(header?.yearMonth))}
            stores={stores}
            storeCount={stores.length}
          />
        ))}

      {unassignedStores && unassignedStores.length > 0 && (
        <UnassignedStoresPage
          header={{ ...header }}
          unassignedStores={unassignedStores}
          referenceMonth={correctDate(String(header?.yearMonth))}
        />
      )}
    </Document>
  )
}
