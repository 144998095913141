import { FunctionComponent } from 'react'

import { Path, Svg } from 'react-native-svg'
import { resize } from 'src/utils'

type MP4IconProps = {
  size?: number
}

export const MP4Icon: FunctionComponent<MP4IconProps> = ({ size = 35 }) => {
  const { height, width } = resize({ height: 35, size, width: 27 })

  return (
    <Svg {...{ viewBox: '0 0 27 35', width, height }}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5047 0.333374H3.99984C3.11578 0.333374 2.26794 0.684563 1.64281 1.30968C1.01769 1.93481 0.666504 2.78265 0.666504 3.66671V30.3334C0.666504 31.2174 1.01769 32.0653 1.64281 32.6904C2.26794 33.3155 3.11578 33.6667 3.99984 33.6667H23.9998C24.8839 33.6667 25.7317 33.3155 26.3569 32.6904C26.982 32.0653 27.3332 31.2174 27.3332 30.3334V11.1618C27.3332 10.6314 27.1225 10.1227 26.7474 9.74759L17.919 0.91916C17.5439 0.544087 17.0352 0.333374 16.5047 0.333374ZM15.6665 12.0001V3.66675L23.9998 12.0001H15.6665Z"
        fill="#6881A0"
      />
      <Path
        d="M3.71442 26.7301C3.71442 26.5449 3.77808 26.3901 3.90539 26.2657C4.0356 26.1412 4.20777 26.079 4.42189 26.079C4.63601 26.079 4.80672 26.1412 4.93404 26.2657C5.06425 26.3901 5.12935 26.5449 5.12935 26.7301C5.12935 26.9153 5.06425 27.0701 4.93404 27.1945C4.80672 27.3189 4.63601 27.3811 4.42189 27.3811C4.20777 27.3811 4.0356 27.3189 3.90539 27.1945C3.77808 27.0701 3.71442 26.9153 3.71442 26.7301ZM7.17119 21.0139H8.27362L9.89689 25.6537L11.5201 21.0139H12.6226L10.3396 27.3334H9.45418L7.17119 21.0139ZM6.57657 21.0139H7.67466L7.87432 25.5365V27.3334H6.57657V21.0139ZM12.1191 21.0139H13.2215V27.3334H11.9195V25.5365L12.1191 21.0139ZM17.0906 25.0808H15.4804V24.0651H17.0906C17.3395 24.0651 17.542 24.0246 17.6983 23.9436C17.8545 23.8597 17.9688 23.744 18.0412 23.5964C18.1135 23.4488 18.1497 23.2824 18.1497 23.0973C18.1497 22.9092 18.1135 22.7341 18.0412 22.5721C17.9688 22.4101 17.8545 22.2798 17.6983 22.1815C17.542 22.0831 17.3395 22.0339 17.0906 22.0339H15.9318V27.3334H14.6297V21.0139H17.0906C17.5854 21.0139 18.0093 21.1036 18.3623 21.283C18.7182 21.4595 18.9902 21.704 19.1783 22.0165C19.3664 22.329 19.4604 22.6864 19.4604 23.0886C19.4604 23.4966 19.3664 23.8496 19.1783 24.1476C18.9902 24.4456 18.7182 24.6757 18.3623 24.8377C18.0093 24.9998 17.5854 25.0808 17.0906 25.0808ZM24.9224 24.9723V25.9532H20.3695L20.3087 25.1936L22.9432 21.0139H23.9372L22.8608 22.8151L21.55 24.9723H24.9224ZM24.2063 21.0139V27.3334H22.9563V21.0139H24.2063Z"
        fill="white"
      />
    </Svg>
  )
}
