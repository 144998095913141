import { AxiosError, AxiosResponse } from 'axios'
import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import {
  Opportunity,
  GetListParams,
  OpportunitiesByBusinessResponse,
  OpportunitiesResponse,
  OpportunitiesSummary,
  EvaluationItem,
  OpportunitySerialized,
  ActionsOffers
} from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_OPPORTUNITY_LIST_QUERY = 'getOpportunityList'

export const KEY_OPPORTUNITY_LIST_BY_BUSINESS_QUERY = 'getOpportunityListByBusiness'

export const KEY_OPPORTUNITY_ITEM_QUERY = 'getOpportunityItem'

export const KEY_OPPORTUNITIES_SUMMARY_QUERY = 'getOpportunitySummary'

export const KEY_ACTIONS_QUERY = 'getActionsFilters'

export const KEY_OFFERS_QUERY = 'getOfferFilters'

export const KEY_RATING_DEALERS_QUERY = 'getRatingDealersFilters'

export const useGetOpportunityListInfiniteQuery = (
  params: GetListParams,
  enabled?: boolean,
  onSuccess?: (
    data: InfiniteData<AxiosResponse<ResponseSuccess<OpportunitiesResponse>, any>>
  ) => void
) =>
  useInfiniteQuery<
    AxiosResponse<ResponseSuccess<OpportunitiesResponse>>,
    AxiosError<ResponseError>
  >(
    [KEY_OPPORTUNITY_LIST_QUERY, params],
    ({ pageParam }) => requests.getOpportunityList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false,
      onSuccess,
      enabled: enabled ?? true
    }
  )

export const useGetOpportunityListByBusinessInfiniteQuery = (params: GetListParams) =>
  useInfiniteQuery<
    AxiosResponse<ResponseSuccess<OpportunitiesByBusinessResponse>>,
    AxiosError<ResponseError>
  >(
    [KEY_OPPORTUNITY_LIST_BY_BUSINESS_QUERY, params],
    ({ pageParam }) => requests.getOpportunityListByBusiness({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false,
      enabled: true
    }
  )

export const useGetOpportunityItemQuery = (
  id: string,
  onSuccess?: (data: AxiosResponse<ResponseSuccess<Opportunity>>) => void
) =>
  useQuery<AxiosResponse<ResponseSuccess<OpportunitySerialized>>, AxiosError<ResponseError>>(
    [`${KEY_OPPORTUNITY_ITEM_QUERY}.${id}`],
    () => requests.getOpportunityItem(id),
    {
      onSuccess(data) {
        onSuccess?.(data)
      }
    }
  )

export const useGetOpportunityQuery = (id?: string) =>
  useQuery<AxiosResponse<ResponseSuccess<OpportunitySerialized>>, AxiosError<ResponseError>>(
    [`${KEY_OPPORTUNITY_ITEM_QUERY}.${id}.details`],
    () => requests.getOpportunityItem(id),
    { enabled: false }
  )

export const useGetOpportunitiesSummaryQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<OpportunitiesSummary[]>>, AxiosError<ResponseError>>(
    [KEY_OPPORTUNITIES_SUMMARY_QUERY],
    () => requests.getOpportunitiesSummary()
  )

export const useSetOpportunityHighlightedMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<Opportunity, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<Opportunity, 'id'>
  >(requests.setOpportunityHighlighted, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        KEY_OPPORTUNITY_LIST_QUERY,
        { per_page: 10, get_highlighted_view: true }
      ])

      queryClient.invalidateQueries([`${KEY_OPPORTUNITY_ITEM_QUERY}.${data.data.data.id}`])
    }
  })
}

export const useSetOpportunityUnHighlightedMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<Opportunity, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<Opportunity, 'id'>
  >(requests.setOpportunityUnHighlighted, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        KEY_OPPORTUNITY_LIST_QUERY,
        { per_page: 10, get_highlighted_view: true }
      ])

      queryClient.invalidateQueries([`${KEY_OPPORTUNITY_ITEM_QUERY}.${data.data.data.id}`])
    }
  })
}

export const useSetOpportunityRatesMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<Opportunity, 'opportunity_id'>>>,
    AxiosError<ResponseError>,
    EvaluationItem
  >(requests.setOpportunityRates, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        KEY_OPPORTUNITY_LIST_QUERY,
        { per_page: 10, get_highlighted_view: true }
      ])

      queryClient.invalidateQueries([
        `${KEY_OPPORTUNITY_ITEM_QUERY}.${data.data.data.opportunity_id}`
      ])
    }
  })
}

export const useFinishedOpportunityMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<Opportunity, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<Opportunity, 'id'>
  >(requests.finishedOpportunity, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        KEY_OPPORTUNITY_LIST_QUERY,
        { per_page: 10, get_highlighted_view: true }
      ])

      queryClient.invalidateQueries(KEY_OPPORTUNITY_LIST_BY_BUSINESS_QUERY)
    }
  })
}

export const useUnFinishedOpportunityMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<Opportunity, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<Opportunity, 'id'>
  >(requests.unFinishedOpportunity, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        KEY_OPPORTUNITY_LIST_QUERY,
        { per_page: 10, get_highlighted_view: true }
      ])

      queryClient.invalidateQueries(KEY_OPPORTUNITY_LIST_BY_BUSINESS_QUERY)
    }
  })
}

export const useGetActionsQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<ActionsOffers[]>>, AxiosError<ResponseError>>(
    [KEY_ACTIONS_QUERY],
    () => requests.getListActions()
  )

export const useGetOffersQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<ActionsOffers[]>>, AxiosError<ResponseError>>(
    [KEY_OFFERS_QUERY],
    () => requests.getListOffers()
  )

export const useGetRatingDealersQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<ActionsOffers[]>>, AxiosError<ResponseError>>(
    [KEY_RATING_DEALERS_QUERY],
    () => requests.getListRatingDealers()
  )
