import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const FlameSantanderIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.201 10.4138C17.1653 9.5946 16.9351 8.78189 16.5091 8.04727L12.7515 1.56689C12.465 1.07259 12.2671 0.543133 12.157 0L12.0002 0.270851C11.0699 1.8748 11.0699 3.85091 12 5.45487L15.0061 10.6392C15.9362 12.2433 15.9362 14.2196 15.0061 15.8235L14.8491 16.0944C14.7392 15.5513 14.5411 15.0218 14.2546 14.5276L9.74545 6.75107C9.45906 6.25692 9.26099 5.72731 9.1511 5.18433L8.99409 5.45518C8.06719 7.05373 8.06432 9.02189 8.98483 10.623L8.98467 10.6231L12.0002 15.8235C12.9301 17.4275 12.9301 19.4038 12.0002 21.0079L11.843 21.2787C11.7331 20.7356 11.5352 20.2061 11.2486 19.7118L7.49106 13.2314C6.98712 12.3623 6.75679 11.3838 6.79896 10.4146C2.77654 11.461 0 13.683 0 16.2557C0 19.8346 5.37275 22.7359 12.0002 22.7359C18.6274 22.7359 24 19.8346 24 16.2557C24 13.6828 21.2238 11.4603 17.201 10.4138Z"
      fill="currentColor"
    />
  </Icon>
)
