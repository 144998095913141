import { memo } from 'react'

import Svg, { Circle, Path } from 'react-native-svg'

const PitstopIcon = () => (
  <Svg width={18} height={18} fill="none" viewBox="0 0 18 18">
    <Path
      d="M6 4.45833C6 4.2052 6.21037 4 6.46987 4C6.72937 4 6.93973 4.2052 6.93973 4.45833V14.5417C6.93973 14.7948 6.72937 15 6.46987 15C6.21037 15 6 14.7948 6 14.5417V4.45833Z"
      fill="#F5F5F5"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.15052 10.1429H6V5.00006H8.63616C9.21006 5.00006 9.69829 5.35784 9.87923 5.8572H12.0473C12.7005 5.8572 13.1254 6.52776 12.8333 7.09767L12.151 8.42863L12.8333 9.75959C13.1254 10.3295 12.7005 11.0001 12.0473 11.0001H10.3936C9.8197 11.0001 9.33146 10.6423 9.15052 10.1429ZM6.87872 5.8572H8.63616C8.87881 5.8572 9.07552 6.04908 9.07552 6.28578V9.28578H6.87872V5.8572ZM9.95423 9.71435C9.95423 9.95104 10.1509 10.1429 10.3936 10.1429H12.0473L11.1686 8.42863L12.0473 6.71435H9.95423V9.71435Z"
      fill="#F5F5F5"
    />
    <Circle cx="9" cy="9" r="8.5" stroke="#F5F5F5" />
  </Svg>
)

export const Pitstop = memo(PitstopIcon)
