import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ContactEditScreenComponent } from './ContactEditScreen.types'
import { useContactEditScreen } from './useContactEditScreen'

const ContactEditScreenDesktop = lazy(() => import('./ContactEditScreen.desktop'))

const ContactEditScreenMobile = lazy(() => import('./ContactEditScreen.mobile'))

export const ContactEditScreen: ContactEditScreenComponent = ({ navigation, route }) => {
  const contactEditScreen = useContactEditScreen({ navigation, route })

  const Screen = contactEditScreen.isMobile ? ContactEditScreenMobile : ContactEditScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...contactEditScreen} />
    </Suspense>
  )
}
