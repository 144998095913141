import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Mask, Rect, Path, G } from 'react-native-svg'

type SummaryStatusIconProps = IIconProps & { projected?: boolean; dashedCurrentColor?: string }

export const SummaryStatusIcon: FunctionComponent<SummaryStatusIconProps> = ({
  projected,
  dashedCurrentColor,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 11 11', ...props }}>
    {projected ? (
      <>
        <Rect x={0.545} y={0.5} width={10} height={10} rx={5} fill="currentColor" />
        <Mask id="a" x={0} y={0} width={10} height={10}>
          <Rect x={0.545} y={0.5} width={10} height={10} rx={5} fill="#fff" />
        </Mask>
        <G
          mask="url(#a)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill={dashedCurrentColor ?? 'currentColor'}>
          <Path d="M-30.1 23.392 17.74-9.49l-.132.828-47.841 32.883.131-.828ZM-27.942 25.471 19.899-7.412l-.131.828L-28.074 26.3l.132-.828ZM-25.786 27.55 22.055-5.334l-.131.828-47.842 32.884.132-.828ZM-23.63 29.629 24.212-3.254l-.132.827-47.842 32.884.132-.828ZM-21.474 31.71 26.368-1.171l-.132.828-47.841 32.883.131-.828ZM-19.32 33.79 28.522.907l-.131.828-47.842 32.883.131-.828ZM-17.163 35.869 30.679 2.986l-.132.828-47.842 32.883.132-.828Z" />
        </G>
      </>
    ) : (
      <Rect width={11} height={11} rx={5} fill="currentColor" />
    )}
  </Icon>
)
