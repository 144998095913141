import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const GiftIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      d="M22.59 5.77893C21.95 5.14893 21.1 4.79893 20.2 4.79893H13.8L16.2 1.59893C16.53 1.15893 16.44 0.528932 16 0.198932C15.56 -0.131068 14.93 -0.0410682 14.6 0.398932L11.8 4.12893L9 0.398932C8.67 -0.0410682 8.04 -0.131068 7.6 0.198932C7.16 0.528932 7.07 1.15893 7.4 1.59893L9.8 4.79893H3.4C2.5 4.79893 1.65 5.14893 1.01 5.77893C0.37 6.41893 0 7.25893 0 8.16893V11.8089C0 12.7089 0.37 13.5589 1.01 14.1889C1.07 14.2489 1.14 14.2889 1.2 14.3489V20.2389C1.2 21.1389 1.57 21.9889 2.21 22.6189C2.85 23.2489 3.7 23.5989 4.6 23.5989H19C19.9 23.5989 20.75 23.2489 21.39 22.6189C22.03 21.9889 22.39 21.1389 22.4 20.2289V14.3489C22.46 14.2989 22.53 14.2489 22.59 14.1889C23.23 13.5589 23.59 12.7089 23.6 11.7989V8.15893C23.6 7.25893 23.23 6.40893 22.59 5.77893ZM20.2 6.79893C20.57 6.79893 20.92 6.93893 21.18 7.19893C21.44 7.45893 21.59 7.80893 21.6 8.16893V11.7889C21.6 12.1589 21.45 12.5089 21.18 12.7689C20.91 13.0289 20.57 13.1689 20.2 13.1689H12.78V6.79893H20.2ZM2 8.17893C2 7.80893 2.15 7.45893 2.42 7.19893C2.68 6.93893 3.03 6.79893 3.4 6.79893H10.77V13.1689H3.4C3.03 13.1689 2.68 13.0289 2.42 12.7689C2.16 12.5089 2.01 12.1589 2 11.7989V8.17893ZM3.61 21.1889C3.35 20.9289 3.2 20.5789 3.19 20.2189V15.1489C3.26 15.1489 3.32 15.1689 3.39 15.1689H10.78V21.5989H4.59C4.22 21.5989 3.87 21.4589 3.61 21.1989V21.1889ZM20.39 20.2089C20.39 20.5789 20.24 20.9289 19.97 21.1889C19.7 21.4489 19.36 21.5889 18.99 21.5889H12.79V15.1589H20.19C20.26 15.1589 20.32 15.1389 20.39 15.1389V20.1989V20.2089Z"
      fill="currentColor"
    />
  </Icon>
)
