import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { SurveyDetailScreenComponent } from './SurveyDetailScreen.types'
import { useSurveyDetailScreen } from './useSurveyDetailScreen'

const SurveyDetailScreenDesktop = lazy(() => import('./SurveyDetailScreen.desktop'))

const SurveyDetailScreenMobile = lazy(() => import('./SurveyDetailScreen.mobile'))

export const SurveyDetailScreen: SurveyDetailScreenComponent = ({ navigation, route }) => {
  const props = useSurveyDetailScreen({ navigation, route })

  const Screen = props.isMobile ? SurveyDetailScreenMobile : SurveyDetailScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
