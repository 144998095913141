import { useMemo } from 'react'

import { useGetTabItemQuery } from 'integration/resources/wallet'
import { useBreakpointValue } from 'native-base'

import { UseTabLocationScreen } from './TabLocationScreen.types'

export const useTabLocationScreen: UseTabLocationScreen = ({ route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { tabNumber } = route.params

  const { data, isLoading } = useGetTabItemQuery(tabNumber)

  const tabDetail = useMemo(() => data?.data.data, [data?.data.data])

  return {
    isLoading,
    isMobile,
    tabDetail
  }
}
