import { FunctionComponent } from 'react'

import { SummaryItem, OpportunityType } from 'integration/resources/newOpportunities'
import { VStack, Heading, Text, Divider, HStack } from 'native-base'
import { TouchableOpacity } from 'react-native'
import { ListType } from 'src/screens/OrganizationScreen/OrganizationScreen.types'
import { formatDateStringToMask } from 'src/utils'
import { currencyFormatChart } from 'src/utils/currencyFormat'

const renderValue = (value: string) => {
  const containsGoal = value.includes('/')

  const [achieved, goal] = value.split('/')

  return (
    <Text
      fontSize={{ base: '12px', lg: '14px' }}
      fontWeight={{ base: 700, lg: 600 }}
      lineHeight={{ base: '16px', lg: '20px' }}
      color="gray.500">
      {achieved}
      {containsGoal ? '/' : ''}
      {containsGoal ? <Text color="error.500" children={goal} /> : ''}
    </Text>
  )
}

const returnListType = (id?: OpportunityType) => {
  switch (id) {
    case OpportunityType.ACTIVE_BASE:
      return ListType.ActiveBase

    case OpportunityType.INSURANCE:
      return ListType.Insurance

    case OpportunityType.CHARGE:
      return ListType.Charge

    default:
      return ListType.ODC
  }
}

type OpportunitiesCardProps = {
  handleGoToOpportunitiesList(listType?: ListType): void
  opportunity: SummaryItem
}

export const OpportunitiesCard: FunctionComponent<OpportunitiesCardProps> = ({
  opportunity,
  handleGoToOpportunitiesList
}) => {
  const { id, title, type, value, descriptions, updatedAt } = opportunity

  const listType = returnListType(id)

  return (
    <TouchableOpacity
      onPress={() => handleGoToOpportunitiesList(listType)}
      key={`opportunities-${id}`}>
      <VStack
        justifyContent="space-between"
        borderRadius="20px"
        h={{ base: '221px', lg: '314px' }}
        minW={{ base: '320px', lg: '354px' }}
        bg="white"
        py={{ base: '14px', lg: 6 }}
        px={{ base: 4, lg: 6 }}
        key={title}>
        <VStack space={{ base: 2, lg: 4 }}>
          <VStack>
            <Text
              fontSize={{ base: '14px', lg: '16px' }}
              fontWeight={400}
              color="gray.500"
              mb={{ base: 1, lg: 2 }}>
              {title}
            </Text>

            <Heading fontSize={{ base: '24px', lg: '36px' }} fontWeight={700} color="gray.700">
              {currencyFormatChart(String(value), true)}
            </Heading>

            <Text
              fontSize={{ base: '12px', lg: '14px' }}
              fontWeight={{ base: 700, lg: 600 }}
              lineHeight={{ base: '16px', lg: '20px' }}
              color="gray.200">
              {type}
            </Text>
          </VStack>

          <Divider bgColor="gray.50" h="1px" w="100%" />

          <VStack space={{ base: 1, lg: 2 }}>
            {descriptions.map((description) => (
              <HStack justifyContent="space-between" key={`desc-${description.title}`}>
                <Text
                  fontSize={{ base: '12px', lg: '14px' }}
                  fontWeight={{ base: 700, lg: 600 }}
                  lineHeight={{ base: '16px', lg: '20px' }}
                  color="gray.700">
                  {description.title}
                </Text>

                {renderValue(String(description.value))}
              </HStack>
            ))}
          </VStack>
        </VStack>

        <Text
          fontSize={{ base: '10px', lg: '14px' }}
          fontWeight={600}
          lineHeight={{ base: '14px', lg: '20px' }}
          color="gray.200">
          {updatedAt
            ? `Data de Ref. ${formatDateStringToMask(updatedAt, 'dd/MM/yyyy')}`
            : 'Sem dados'}
        </Text>
      </VStack>
    </TouchableOpacity>
  )
}
