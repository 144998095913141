import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path, ClipPath, G, Defs } from 'react-native-svg'

export const InfoIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 20 20', ...props }}>
    <G fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <Path d="M.167 10C.167 4.568 4.57.165 10 .165c5.431 0 9.834 4.403 9.834 9.833 0 5.431-4.403 9.834-9.834 9.834-5.43 0-9.833-4.403-9.833-9.834ZM10 1.832a8.167 8.167 0 1 0 0 16.333 8.167 8.167 0 0 0 0-16.333Z" />
      <Path d="M10 10.166c.46 0 .834.373.834.833v4a.833.833 0 1 1-1.667 0v-4c0-.46.373-.833.833-.833ZM10 6.518a.083.083 0 0 0 0-.166v-1.5a1.583 1.583 0 0 0 0 3.166v-1.5Z" />
      <Path d="M10 4.852a1.583 1.583 0 1 1 0 3.166v-1.5a.083.083 0 0 1 0-.166v-1.5Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h20v20H0z" />
      </ClipPath>
    </Defs>
  </Icon>
)
