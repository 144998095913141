import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import { Nps } from './types'
import { ResponseError, ResponseSuccess } from '../../types'

const KEY_GET_NPS = 'getNps'

export const useGetNpsQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<Nps>>, AxiosError<ResponseError>>(
    KEY_GET_NPS,
    requests.getNps,
    {
      enabled: true
    }
  )

export const useUpdateNpsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError>(requests.updateNps, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_GET_NPS])
    }
  })
}
