import { FunctionComponent } from 'react'

import { LoadingTurbo } from 'atoms'
import { EcosystemTAB } from 'integration/resources/wallet'
import { UnconnectedMessage } from 'molecules'
import { Center, VStack } from 'native-base'
import { TabEcosystem } from 'organisms'

import { useTabComponent } from './hook'
import { TabCommonProps } from './types'

export const Ecosystem: FunctionComponent<TabCommonProps> = ({ tabNumber }) => {
  const { ecosystemData, isLoading } = useTabComponent({
    tabNumber: tabNumber ?? ''
  })

  if (!tabNumber) return null

  return isLoading ? (
    <Center h={360} w="full" position="absolute" zIndex={9}>
      <LoadingTurbo size={100} />
    </Center>
  ) : ecosystemData?.data.data ? (
    <VStack mt={10} safeAreaBottom mb={10}>
      <TabEcosystem data={ecosystemData?.data.data ?? ({} as EcosystemTAB)} tabNumber={tabNumber} />
    </VStack>
  ) : (
    <UnconnectedMessage />
  )
}
