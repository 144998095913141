export const returnDescriptionSegment = (business_code: 1 | 2 | 3) => {
  switch (business_code) {
    case 3:
      return 'SIM'

    case 2:
      return 'Veículos'

    default:
      return 'Ambos'
  }
}
