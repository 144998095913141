import { FunctionComponent } from 'react'

import { Path, Svg } from 'react-native-svg'
import { resize } from 'src/utils'

type ZIPIconProps = {
  size?: number
}

export const ZIPIcon: FunctionComponent<ZIPIconProps> = ({ size = 35 }) => {
  const { height, width } = resize({ height: 35, size, width: 27 })

  return (
    <Svg {...{ viewBox: '0 0 27 35', width, height }}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0062 0.734375H3.5013C2.61725 0.734375 1.7694 1.08556 1.14428 1.71069C0.519158 2.33581 0.167969 3.18365 0.167969 4.06771V30.7344C0.167969 31.6184 0.519158 32.4663 1.14428 33.0914C1.7694 33.7165 2.61725 34.0677 3.5013 34.0677H23.5013C24.3854 34.0677 25.2332 33.7165 25.8583 33.0914C26.4834 32.4663 26.8346 31.6184 26.8346 30.7344V11.5628C26.8346 11.0324 26.6239 10.5237 26.2488 10.1486L17.4204 1.32016C17.0453 0.945088 16.5366 0.734375 16.0062 0.734375ZM15.168 12.4011V4.06775L23.5013 12.4011H15.168Z"
        fill="#40C24C"
      />
      <Path
        d="M5.65946 27.1311C5.65946 26.9459 5.72312 26.7911 5.85043 26.6667C5.98064 26.5422 6.15281 26.48 6.36693 26.48C6.58105 26.48 6.75176 26.5422 6.87908 26.6667C7.00929 26.7911 7.07439 26.9459 7.07439 27.1311C7.07439 27.3163 7.00929 27.4711 6.87908 27.5955C6.75176 27.7199 6.58105 27.7821 6.36693 27.7821C6.15281 27.7821 5.98064 27.7199 5.85043 27.5955C5.72312 27.4711 5.65946 27.3163 5.65946 27.1311ZM13.0919 26.7187V27.7344H8.61276V26.7187H13.0919ZM13.0268 22.1398L9.19002 27.7344H8.27422V26.9878L12.1414 21.4149H13.0268V22.1398ZM12.5798 21.4149V22.4349H8.27856V21.4149H12.5798ZM15.5721 21.4149V27.7344H14.2744V21.4149H15.5721ZM19.5194 25.4818H17.9091V24.4661H19.5194C19.7682 24.4661 19.9707 24.4256 20.127 24.3446C20.2832 24.2607 20.3975 24.145 20.4699 23.9974C20.5422 23.8498 20.5784 23.6834 20.5784 23.4983C20.5784 23.3102 20.5422 23.1351 20.4699 22.9731C20.3975 22.8111 20.2832 22.6808 20.127 22.5825C19.9707 22.4841 19.7682 22.4349 19.5194 22.4349H18.3605V27.7344H17.0584V21.4149H19.5194C20.0141 21.4149 20.438 21.5046 20.7911 21.684C21.147 21.8605 21.419 22.105 21.607 22.4175C21.7951 22.73 21.8891 23.0874 21.8891 23.4896C21.8891 23.8976 21.7951 24.2506 21.607 24.5486C21.419 24.8466 21.147 25.0767 20.7911 25.2387C20.438 25.4008 20.0141 25.4818 19.5194 25.4818Z"
        fill="white"
      />
    </Svg>
  )
}
