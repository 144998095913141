import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Storage } from 'src/utils'

import * as requests from './requests'
import { HighlightedFeatures, HighlightedFeaturesItem, Menu } from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_HIGHLITGHTED_FEATURES_QUERY = 'getHighlightedFeatures'

export const useGetMenusQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<Menu[]>>, AxiosError<ResponseError>>(
    'getMenus',
    requests.getMenus,
    { enabled: false }
  )

export const useGetHighlightedFeaturesQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<HighlightedFeatures>>, AxiosError<ResponseError>>(
    [KEY_HIGHLITGHTED_FEATURES_QUERY],
    () => requests.getHighlightedFeatures()
  )

export const useRemoveHighlightedFeatureMutation = (storageKey: string) => {
  const queryClient = useQueryClient()

  return useMutation<
    AxiosResponse<ResponseSuccess<Pick<HighlightedFeaturesItem, 'id'>>>,
    AxiosError<ResponseError>,
    Pick<HighlightedFeaturesItem, 'id'>
  >(requests.removeHighlightedFeature, {
    onSuccess: () => {
      Storage.removeItem(storageKey)

      queryClient.invalidateQueries(KEY_HIGHLITGHTED_FEATURES_QUERY)
    }
  })
}
