import { Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'
import { useBreakpointValue } from 'native-base'

import { OpportunityOrderByScreenComponent } from './OrderByFilterScreen.types'
import { useOpportunityOrderByFilter } from './useOpportunityOrderByFilter'

const OpportunityOrderByFilterMobile = lazy(() => import('./OrderByFilterScreen.mobile'))

export const OpportunityOrderByFilterScreen: OpportunityOrderByScreenComponent = ({
  navigation,
  route
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const opportunityOrderByFilter = useOpportunityOrderByFilter({ navigation, route })

  if (!isMobile) return <></>

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <OpportunityOrderByFilterMobile {...opportunityOrderByFilter} />
    </Suspense>
  )
}
