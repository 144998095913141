import { memo } from 'react'

import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

const DoneIllustration = ({ width = 251, height = 250 }) => (
  <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
    <G clipPath="url(#clip0_8462_170133)">
      <Path
        d="M152.42 153.63L168.28 173.58L88.88 219.63L7.62 117.33L62.41 85.6401L82.9 104.4L103.68 92.3601L103.77 92.3801L116.76 108.73L152.42 153.63Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M32.29 125.26L67.19 105.06"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M43.18 139.02L97.47 107.6"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M62.41 85.6401L66.32 83.3801L66.35 83.3901"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M82.9 104.4L62.41 85.6401L62.4 85.6201L66.32 83.3501L66.34 83.3901L82.9 104.4Z"
        fill="#404040"
      />
      <Path
        d="M82.9 104.4L62.41 85.6401L62.4 85.6201L66.32 83.3501L66.34 83.3901L82.9 104.4Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M66.35 83.3901L66.32 83.3501"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M103.68 92.3601L82.9 104.4L66.34 83.3901L103.68 92.3601Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M130.34 161.23C132.27 175.38 121.64 185.04 105.77 185.04C89.9 185.04 72.7 171.15 72.7 156.86C72.7 142.57 82.6 132.14 98.47 132.65C112.54 133.1 128.46 147.42 130.35 161.23H130.34Z"
        fill="#F3123C"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M85.19 164.86L108.91 171.19L108.06 147.16"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M159.03 142.56C158.92 142.73 158.81 142.9 158.69 143.07C158.57 143.24 158.45 143.41 158.32 143.58C158.07 143.9 157.81 144.22 157.54 144.53C157.47 144.6 157.41 144.67 157.34 144.74C156.85 145.28 156.34 145.79 155.79 146.27C147.69 153.39 134.67 152.83 124.7 147.07C113.5 140.62 106.27 127.62 110.79 116.51C110.95 116.12 111.12 115.73 111.31 115.35C112.58 112.73 114.46 110.47 116.76 108.73L117.78 109.75C115.42 114.35 120.59 122.32 124.42 125.91C124.34 125.84 124.26 125.76 124.18 125.69C125.56 129.5 128.29 132.67 131.86 134.6C136.58 137.32 144.55 138.61 147.12 134.34L156.55 118.2C155.03 115.44 153.9 113.09 152.86 110.65C152.87 110.65 152.87 110.64 152.88 110.64H152.89C153.92 113.08 155.06 115.44 156.57 118.2V118.22L148.16 132.66C148.67 133.24 149.18 133.81 149.72 134.37C149.72 134.37 149.727 134.377 149.74 134.39C150.26 134.95 150.81 135.51 151.36 136.05C151.73 136.42 152.1 136.78 152.48 137.13C152.86 137.49 153.24 137.85 153.63 138.18C154.01 138.54 154.4 138.88 154.79 139.2C156.15 140.39 157.57 141.51 159.03 142.56Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M118.07 110.05L117.78 109.75"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M116.76 108.73L116.12 108.1"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M103.74 92.3301L103.69 92.3601"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M159.03 142.58L153.77 151.7C153.34 152.38 152.89 153.03 152.42 153.63C147.03 160.47 138.43 162.6 129.98 161.16C129.59 161.11 129.21 161.03 128.83 160.95C128.33 160.85 127.83 160.73 127.34 160.6C126.85 160.48 126.36 160.34 125.87 160.19C125.47 160.07 125.07 159.94 124.67 159.79C124.46 159.72 124.24 159.64 124.03 159.56C122.91 159.15 121.8 158.67 120.73 158.13C120.43 157.98 120.13 157.83 119.84 157.67C119.51 157.5 119.2 157.32 118.88 157.14C117.38 156.28 115.98 155.32 114.68 154.29C113.9 153.67 113.17 153.03 112.46 152.36C111.76 151.69 111.09 150.99 110.46 150.27C110.04 149.8 109.65 149.32 109.26 148.83C108.84 148.29 108.44 147.73 108.06 147.17C106.06 144.18 104.7 140.95 104.07 137.71C103.95 137.11 103.86 136.52 103.8 135.92C103.78 135.8 103.76 135.67 103.75 135.55V135.45C103.69 134.85 103.67 134.24 103.66 133.64V133.32C103.7 130.16 104.49 127.08 106.1 124.3L111.31 115.35C111.12 115.73 110.95 116.12 110.79 116.51C106.27 127.62 113.5 140.62 124.7 147.07C134.67 152.83 147.69 153.39 155.79 146.27C156.34 145.79 156.85 145.28 157.34 144.74V144.76C157.42 144.69 157.48 144.61 157.54 144.53C157.82 144.23 158.07 143.91 158.32 143.58C158.45 143.41 158.57 143.24 158.69 143.07C158.81 142.91 158.93 142.75 159.04 142.58H159.03Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M159.05 142.57L159.03 142.58"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M103.65 133.32C103.65 134.06 103.68 134.8 103.75 135.55"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M111.47 115.08L111.31 115.35"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M191.96 69.86L186.2 79.81C185.66 80.76 184.96 81.59 184.15 82.3C183.98 82.45 183.81 82.59 183.63 82.73C183.55 82.79 183.47 82.85 183.39 82.9C183.19 83.05 183 83.19 182.79 83.31C180.7 84.66 178.07 85.41 175.23 85.55C175.07 85.56 174.91 85.56 174.76 85.56C170.4 85.55 166.11 84.4 162.34 82.22C158.33 79.9 155.06 76.53 152.86 72.45C150.77 68.34 150.38 63.94 152.46 60.34L158.29 50.23C156.9 52.65 156.67 55.37 157.19 58.02C157.22 58.22 157.26 58.43 157.32 58.63C157.91 61.26 159.2 63.75 160.99 65.97C161.23 66.29 161.49 66.59 161.75 66.89C165.65 71.43 170.94 74.17 176.09 75.1C176.36 75.15 176.64 75.2 176.91 75.24C179.81 75.7 182.71 75.58 185.37 74.72C185.54 74.67 185.71 74.61 185.88 74.55C187.5 73.99 188.95 73.17 190.13 72.07C190.32 71.91 190.5 71.74 190.66 71.55C190.93 71.27 191.17 70.99 191.39 70.69C191.5 70.56 191.59 70.43 191.69 70.29C191.78 70.15 191.87 70 191.96 69.86Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M160.98 65.9699C159.19 63.7499 157.9 61.2599 157.31 58.6299C157.83 60.8099 158.84 62.9199 160.11 64.7799C160.39 65.1899 160.68 65.5899 160.98 65.9699V65.9699Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M162.34 82.22C166.11 84.4 170.4 85.55 174.76 85.56C174.6 85.57 174.43 85.57 174.27 85.57C164.78 85.57 156.38 79.42 152.86 72.45C155.06 76.53 158.33 79.9 162.34 82.22V82.22Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M185.36 74.72C182.7 75.58 179.8 75.7 176.9 75.24C179.9 75.67 182.82 75.5 185.36 74.72Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M213.09 109.58C205.79 124.67 191.38 141.78 179.66 151.16H179.65C175.57 150.43 171.57 149.3 167.72 147.75C167.72 147.76 167.71 147.76 167.71 147.77H167.7C163.06 145.43 158.72 142.55 154.79 139.2C154.4 138.87 154.01 138.53 153.63 138.18C153.24 137.85 152.86 137.49 152.48 137.13C152.1 136.78 151.73 136.42 151.36 136.05C150.81 135.51 150.26 134.95 149.74 134.39C149.74 134.39 149.733 134.383 149.72 134.37C149.18 133.81 148.67 133.24 148.16 132.66L156.56 118.22V118.2C155.06 115.44 153.92 113.08 152.89 110.64C151.23 106.79 150.33 103.96 150.33 103.96C145.43 98.1899 135.05 84.9699 131.15 76.0499C131.03 75.7599 130.9 75.4599 130.79 75.1699C130.7 74.9499 130.61 74.7299 130.55 74.5099C130.48 74.3599 130.43 74.2099 130.38 74.0599C130.27 73.7599 130.18 73.4699 130.1 73.1899C130.1 73.1699 130.08 73.1499 130.08 73.1399C130.02 72.9299 129.97 72.7299 129.92 72.5299C129.65 71.3899 129.63 70.3599 129.84 69.4899C129.87 69.3599 129.91 69.2299 129.95 69.1099C129.99 68.9899 130.04 68.8699 130.09 68.7499C130.16 68.5799 130.24 68.4199 130.34 68.2799C130.43 68.1299 130.53 67.9899 130.64 67.8599C130.72 67.7699 130.8 67.6899 130.89 67.6099C132.28 66.3199 134.75 66.3499 137.62 68.5599C139.3 69.8599 141.52 71.7299 144.17 73.8799C144.19 73.8699 144.21 73.8599 144.23 73.8499C145.73 75.0699 147.45 76.4299 149.6 78.0399H149.61C149.6 78.0599 149.59 78.0799 149.58 78.0999C156.03 82.9199 163.99 88.0699 172.47 90.8599L172.51 90.7899L175.52 85.6099C178.13 85.5499 180.65 84.7399 182.79 83.2999C183 83.1799 183.19 83.0399 183.39 82.8899C183.47 82.8399 183.55 82.7799 183.63 82.7199C183.81 82.5799 183.98 82.4399 184.15 82.2899C184.46 82.0399 184.76 81.7599 185.04 81.4699C192.01 87.5799 189.92 85.4799 205.41 101.58L213.09 109.57V109.58Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M129.9 72.58C129.61 71.42 129.61 70.36 129.84 69.49C129.63 70.36 129.65 71.39 129.92 72.53C129.97 72.73 130.02 72.93 130.08 73.14C130.02 72.96 129.96 72.77 129.9 72.58V72.58Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M152.83 72.4901C152.72 72.6701 152.34 73.3401 151.75 74.3801C151.66 74.5101 151.58 74.65 151.5 74.8C151.33 75.09 151.14 75.41 150.95 75.75C150.85 75.92 150.74 76.0901 150.64 76.2701C150.61 76.3101 150.59 76.3501 150.56 76.4001C150.38 76.7301 150.18 77.07 149.97 77.43C149.95 77.4401 149.94 77.4501 149.94 77.4701C149.83 77.6501 149.72 77.8401 149.6 78.0401C147.45 76.4301 145.73 75.07 144.23 73.85C145.21 73.3 151.82 70.05 151.82 70.05C152.09 70.89 152.42 71.7101 152.83 72.4901Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M149.59 78.04C149.71 77.84 149.82 77.65 149.93 77.47C149.83 77.66 149.72 77.85 149.6 78.05H149.59V78.04Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M152.84 72.51C152.84 72.51 152.45 73.19 151.75 74.38C152.34 73.34 152.72 72.67 152.83 72.49C152.83 72.5 152.84 72.5 152.84 72.51Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M152.89 110.64H152.88C152.88 110.64 152.87 110.65 152.86 110.65H152.85C152.85 110.65 152.81 110.67 152.79 110.68C152.6 110.76 152.42 110.84 152.22 110.91C152.08 110.96 151.94 111 151.8 111.04C151.31 111.18 150.77 111.25 150.19 111.25C150.05 111.26 149.92 111.25 149.78 111.24H149.74C149.62 111.23 149.49 111.22 149.36 111.2C149.31 111.2 149.26 111.19 149.2 111.18H149.17C150.35 115.02 149.81 117.5 148.24 118.74C148.01 118.91 147.76 119.06 147.49 119.19C147.36 119.25 147.23 119.3 147.09 119.35C146.26 119.64 145.29 119.71 144.21 119.57H144.19C145.1 122.49 144.52 124.55 142.99 125.62C142.99 125.62 142.99 125.61 142.98 125.62C142.82 125.73 142.66 125.83 142.48 125.92C142.39 125.96 142.29 126 142.2 126.04C142.1 126.08 142 126.12 141.89 126.15C140.52 126.6 138.72 126.52 136.7 125.83C136.62 125.8 136.53 125.78 136.44 125.74C136.35 125.71 136.26 125.68 136.18 125.64C136.18 125.64 136.17 125.64 136.16 125.64C136.47 127.44 135.66 128.64 134.23 129.18C132.95 129.65 131.17 129.59 129.28 128.93C129.1 128.87 128.93 128.8 128.75 128.73C128.6 128.67 128.44 128.6 128.29 128.53C127.98 128.39 127.68 128.24 127.37 128.07C127.36 128.08 127.36 128.07 127.36 128.07C126.59 127.63 125.83 127.11 125.08 126.49C124.98 126.41 124.88 126.32 124.77 126.23C124.67 126.13 124.57 126.04 124.46 125.94C124.45 125.94 124.43 125.93 124.42 125.91C120.59 122.32 115.42 114.35 117.78 109.75C118.01 109.29 118.31 108.87 118.7 108.49C119.27 107.94 120.01 107.58 120.8 107.48C120.72 107.16 120.64 106.83 120.58 106.51C120.02 103.6 120.49 100.78 123.33 99.07C123.85 98.75 124.43 98.54 125.03 98.45C124.13 93.39 126.79 89.8901 130.7 88.7401C130.74 87.4601 131.11 86.2101 131.78 85.1101C132.44 84.0101 133.38 83.1 134.5 82.48C133.18 80.44 132.05 78.29 131.15 76.05C135.05 84.97 145.43 98.19 150.33 103.96C150.33 103.96 151.23 106.79 152.89 110.64Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M136.17 125.65C136.48 127.45 135.66 128.65 134.22 129.18C135.65 128.64 136.46 127.44 136.15 125.64C136.15 125.64 136.15 125.65 136.17 125.65Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M144.21 119.57C145.27 122.96 144.31 125.2 142.2 126.04C144.3 125.18 145.24 122.95 144.19 119.57H144.21V119.57Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M149.2 111.18C150.39 115.04 149.83 117.52 148.24 118.74C149.81 117.5 150.35 115.02 149.17 111.18H149.2Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M149.35 111.2C149.64 111.24 149.91 111.25 150.18 111.25C150.03 111.26 149.88 111.25 149.73 111.24C149.61 111.23 149.48 111.22 149.35 111.2V111.2Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M152.22 110.91C152.44 110.83 152.64 110.74 152.85 110.65C152.83 110.67 152.81 110.67 152.79 110.68C152.71 110.72 152.63 110.76 152.55 110.79C152.45 110.84 152.35 110.88 152.24 110.91H152.22V110.91Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M152.85 110.64V110.65H152.84L152.85 110.64Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M179.65 151.16C175.54 150.54 171.53 149.39 167.71 147.77C167.71 147.76 167.72 147.76 167.72 147.75C171.57 149.3 175.57 150.43 179.65 151.16Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M191.96 69.86C191.87 70 191.78 70.15 191.69 70.29C191.59 70.42 191.49 70.56 191.39 70.69C191.17 70.99 190.93 71.27 190.66 71.55C190.49 71.73 190.32 71.9 190.13 72.07C189.4 72.73 188.54 73.32 187.54 73.83C187 74.1 186.45 74.34 185.88 74.55C185.71 74.61 185.54 74.67 185.37 74.72C182.71 75.58 179.81 75.7 176.91 75.24C176.64 75.2 176.36 75.15 176.09 75.1C170.71 74.04 165.41 70.99 161.75 66.89C161.49 66.59 161.23 66.29 160.99 65.97C159.2 63.75 157.91 61.26 157.32 58.63C157.26 58.43 157.22 58.22 157.19 58.02C157.08 57.43 157 56.83 156.97 56.23C156.56 48.91 162.47 44.46 170.22 44.46C179.37 44.46 189.03 50.71 192.31 59.09C193.85 63 193.79 66.85 191.96 69.86Z"
        fill="#404040"
      />
      <Path
        d="M191.96 69.86C191.87 70 191.78 70.15 191.69 70.29C191.59 70.42 191.49 70.56 191.39 70.69C191.17 70.99 190.93 71.27 190.66 71.55C190.49 71.73 190.32 71.9 190.13 72.07C189.4 72.73 188.54 73.32 187.54 73.83C187 74.1 186.45 74.34 185.88 74.55C185.71 74.61 185.54 74.67 185.37 74.72C182.71 75.58 179.81 75.7 176.91 75.24C176.64 75.2 176.36 75.15 176.09 75.1C170.71 74.04 165.41 70.99 161.75 66.89C161.49 66.59 161.23 66.29 160.99 65.97C159.2 63.75 157.91 61.26 157.32 58.63C157.26 58.43 157.22 58.22 157.19 58.02C157.08 57.43 157 56.83 156.97 56.23C156.56 48.91 162.47 44.46 170.22 44.46C179.37 44.46 189.03 50.71 192.31 59.09C193.85 63 193.79 66.85 191.96 69.86V69.86Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M179.67 151.16C173.78 155.81 169.11 157.94 167.17 156.33C165.73 155.13 166.01 152.04 167.7 147.77C167.7 147.76 167.71 147.76 167.71 147.75C171.56 149.3 175.56 150.43 179.64 151.16H179.67Z"
        fill="#404040"
      />
      <Path
        d="M179.67 151.16C173.78 155.81 169.11 157.94 167.17 156.33C165.73 155.13 166.01 152.04 167.7 147.77C167.7 147.76 167.71 147.76 167.71 147.75C171.56 149.3 175.56 150.43 179.64 151.16H179.67Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M213.09 109.58L205.41 101.59C214.49 95.4901 218.31 98.1701 213.09 109.58Z"
        fill="#404040"
      />
      <Path
        d="M213.09 109.58L205.41 101.59C214.49 95.4901 218.31 98.1701 213.09 109.58Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M157.34 144.74V144.76C157.42 144.69 157.48 144.61 157.54 144.53"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M229.76 125.62C229.76 126.25 229.57 126.86 229.23 127.38C228.88 127.9 228.39 128.31 227.81 128.55C227.23 128.79 226.6 128.85 225.98 128.73C225.36 128.61 224.8 128.31 224.36 127.86C223.92 127.42 223.62 126.85 223.49 126.24C223.37 125.63 223.43 124.99 223.67 124.41C223.91 123.83 224.31 123.34 224.84 122.99C225.36 122.64 225.97 122.46 226.6 122.46C227.44 122.46 228.25 122.79 228.84 123.39C229.43 123.98 229.77 124.79 229.77 125.63L229.76 125.62Z"
        fill="#404040"
      />
      <Path
        d="M229.76 125.62C229.76 126.25 229.57 126.86 229.23 127.38C228.88 127.9 228.39 128.31 227.81 128.55C227.23 128.79 226.6 128.85 225.98 128.73C225.36 128.61 224.8 128.31 224.36 127.86C223.92 127.42 223.62 126.85 223.49 126.24C223.37 125.63 223.43 124.99 223.67 124.41C223.91 123.83 224.31 123.34 224.84 122.99C225.36 122.64 225.97 122.46 226.6 122.46C227.44 122.46 228.25 122.79 228.84 123.39C229.43 123.98 229.77 124.79 229.77 125.63L229.76 125.62Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M175.53 85.55L172.49 90.8C172.49 90.8 172.47 90.8 172.46 90.8C163.99 88.01 156.04 82.87 149.6 78.06H149.59C149.71 77.86 149.82 77.67 149.93 77.49C149.93 77.47 149.94 77.46 149.96 77.45C150.17 77.09 150.37 76.75 150.55 76.42C150.58 76.37 150.6 76.33 150.63 76.29C150.73 76.11 150.84 75.94 150.94 75.77C151.14 75.43 151.32 75.11 151.49 74.82C151.57 74.67 151.65 74.53 151.74 74.4C152.33 73.36 152.71 72.69 152.82 72.51C152.83 72.49 152.84 72.48 152.85 72.47C155.05 76.55 158.32 79.92 162.33 82.24C166.1 84.42 170.39 85.57 174.75 85.58C174.9 85.58 175.06 85.58 175.22 85.57C175.32 85.58 175.43 85.57 175.53 85.57V85.55Z"
        fill="#F3123C"
      />
      <Path
        d="M175.53 85.55L172.49 90.8C172.49 90.8 172.47 90.8 172.46 90.8C163.99 88.01 156.04 82.87 149.6 78.06H149.59C149.71 77.86 149.82 77.67 149.93 77.49C149.93 77.47 149.94 77.46 149.96 77.45C150.17 77.09 150.37 76.75 150.55 76.42C150.58 76.37 150.6 76.33 150.63 76.29C150.73 76.11 150.84 75.94 150.94 75.77C151.14 75.43 151.32 75.11 151.49 74.82C151.57 74.67 151.65 74.53 151.74 74.4C152.33 73.36 152.71 72.69 152.82 72.51C152.83 72.49 152.84 72.48 152.85 72.47C155.05 76.55 158.32 79.92 162.33 82.24C166.1 84.42 170.39 85.57 174.75 85.58C174.9 85.58 175.06 85.58 175.22 85.57C175.32 85.58 175.43 85.57 175.53 85.57V85.55Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M156.54 118.2L147.11 134.34C144.54 138.61 136.57 137.32 131.85 134.6C128.28 132.67 125.55 129.5 124.17 125.69C124.25 125.76 124.33 125.84 124.41 125.91C124.42 125.93 124.44 125.94 124.45 125.94C124.56 126.05 124.66 126.15 124.76 126.23C124.86 126.33 124.95 126.42 125.04 126.49C125.79 127.12 126.57 127.65 127.35 128.07C127.35 128.07 127.35 128.08 127.36 128.07C127.66 128.25 127.97 128.4 128.28 128.53C128.43 128.6 128.59 128.67 128.74 128.73C128.92 128.8 129.09 128.87 129.27 128.93C131.16 129.59 132.94 129.65 134.22 129.18C135.65 128.64 136.46 127.44 136.15 125.64C136.15 125.64 136.15 125.65 136.17 125.65C136.25 125.68 136.34 125.71 136.43 125.74C136.52 125.78 136.61 125.8 136.69 125.83C138.71 126.52 140.51 126.6 141.88 126.15C141.99 126.12 142.09 126.08 142.19 126.04C142.28 126 142.38 125.96 142.47 125.92C142.65 125.83 142.81 125.73 142.97 125.62C142.98 125.61 142.98 125.62 142.98 125.62C144.51 124.55 145.09 122.49 144.18 119.57H144.2C145.28 119.71 146.25 119.64 147.08 119.35C147.22 119.3 147.35 119.25 147.48 119.19C147.75 119.06 148 118.91 148.23 118.74C149.8 117.5 150.34 115.02 149.16 111.18H149.19C149.25 111.19 149.3 111.2 149.35 111.2C149.48 111.22 149.61 111.23 149.73 111.24H149.77C149.91 111.26 150.04 111.26 150.18 111.25C150.76 111.25 151.3 111.18 151.79 111.04C151.93 111 152.07 110.96 152.21 110.91C152.41 110.84 152.59 110.76 152.78 110.68C152.8 110.67 152.82 110.66 152.84 110.65H152.85C153.89 113.09 155.02 115.44 156.54 118.2Z"
        fill="#F3123C"
      />
      <Path
        d="M156.54 118.2L147.11 134.34C144.54 138.61 136.57 137.32 131.85 134.6C128.28 132.67 125.55 129.5 124.17 125.69C124.25 125.76 124.33 125.84 124.41 125.91C124.42 125.93 124.44 125.94 124.45 125.94C124.56 126.05 124.66 126.15 124.76 126.23C124.86 126.33 124.95 126.42 125.04 126.49C125.79 127.12 126.57 127.65 127.35 128.07C127.35 128.07 127.35 128.08 127.36 128.07C127.66 128.25 127.97 128.4 128.28 128.53C128.43 128.6 128.59 128.67 128.74 128.73C128.92 128.8 129.09 128.87 129.27 128.93C131.16 129.59 132.94 129.65 134.22 129.18C135.65 128.64 136.46 127.44 136.15 125.64C136.15 125.64 136.15 125.65 136.17 125.65C136.25 125.68 136.34 125.71 136.43 125.74C136.52 125.78 136.61 125.8 136.69 125.83C138.71 126.52 140.51 126.6 141.88 126.15C141.99 126.12 142.09 126.08 142.19 126.04C142.28 126 142.38 125.96 142.47 125.92C142.65 125.83 142.81 125.73 142.97 125.62C142.98 125.61 142.98 125.62 142.98 125.62C144.51 124.55 145.09 122.49 144.18 119.57H144.2C145.28 119.71 146.25 119.64 147.08 119.35C147.22 119.3 147.35 119.25 147.48 119.19C147.75 119.06 148 118.91 148.23 118.74C149.8 117.5 150.34 115.02 149.16 111.18H149.19C149.25 111.19 149.3 111.2 149.35 111.2C149.48 111.22 149.61 111.23 149.73 111.24H149.77C149.91 111.26 150.04 111.26 150.18 111.25C150.76 111.25 151.3 111.18 151.79 111.04C151.93 111 152.07 110.96 152.21 110.91C152.41 110.84 152.59 110.76 152.78 110.68C152.8 110.67 152.82 110.66 152.84 110.65H152.85C153.89 113.09 155.02 115.44 156.54 118.2V118.2Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M156.56 118.22C159.46 123.41 163.24 128.43 167.58 130.27"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M130.78 75.1699C130.5 74.4799 130.27 73.8099 130.09 73.1899"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M130.09 68.75C130.16 68.58 130.24 68.42 130.34 68.28C130.43 68.13 130.53 67.99 130.64 67.86C130.72 67.77 130.8 67.68 130.89 67.61C132.28 66.29 134.76 66.3 137.65 68.52C139.95 70.29 141.83 71.9 144.23 73.85"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M181.68 92.75C178.54 92.46 175.45 91.8 172.47 90.79"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M124.98 98.05C125.99 104.9 132.91 112.56 134.88 114.45C137.39 117.07 140.65 118.85 144.21 119.55"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M130.72 88.6899C130.48 95.4399 137.88 104.07 140.31 106.32C143.64 109.39 146.69 110.86 149.17 111.18"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M151.8 111.04C151.95 111.01 152.1 110.96 152.24 110.91C152.35 110.88 152.45 110.84 152.55 110.79C152.63 110.76 152.71 110.72 152.79 110.68C152.81 110.68 152.83 110.68 152.85 110.65H152.86"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M120.82 107.45C122.17 112.51 126.3 117.92 127.98 119.74C130.24 122.3 133.04 124.31 136.18 125.65"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M214.52 98.8999L243.89 124.25"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M167.2 156.36L194.82 180.51"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M168.52 200.75C169.12 200.75 169.71 200.93 170.21 201.26C170.71 201.59 171.1 202.07 171.33 202.63C171.56 203.19 171.62 203.8 171.5 204.39C171.38 204.98 171.09 205.52 170.67 205.95C170.24 206.38 169.7 206.67 169.11 206.79C168.52 206.91 167.91 206.85 167.35 206.62C166.79 206.39 166.32 206 165.98 205.5C165.65 205 165.47 204.41 165.47 203.81C165.47 203 165.79 202.23 166.36 201.66C166.93 201.09 167.71 200.77 168.51 200.76L168.52 200.75Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M101.47 29.6201L101.45 50.2201"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M111.77 39.9502L91.14 39.9302"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M219.6 63.5801L221.7 67.8101L226.35 68.4901L222.97 71.7701L223.77 76.4301L219.59 74.2301L215.43 76.4301L216.21 71.7701L212.83 68.4901L217.51 67.8101L219.59 63.5801H219.6Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_8462_170133">
        <Rect width={237.51} height={191.25} fill="white" transform="translate(7 29)" />
      </ClipPath>
    </Defs>
  </Svg>
)

export const Done = memo(DoneIllustration)
