import { forwardRef, useImperativeHandle } from 'react'

import { Icons } from 'atoms'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import * as DocumentPicker from 'expo-document-picker'
import { DIRECTORY_TYPE, EXTENSION_TYPE, useUploadMutation } from 'integration/resources/upload'
import { Center, Box, Heading, HStack, Text, VStack, Button, Skeleton } from 'native-base'
import { Platform, Pressable } from 'react-native'
import { ACCEPTED_FILE_EXTENSION } from 'src/constants/file'
import {
  formatNameAttachments,
  formatNameExtensionAttachments,
  formatSizeAttachments,
  selectIcon
} from 'src/utils'

import { AttachmentsProps } from './types'

export type PickAttachmentHandle = {
  handlePickAttachment(): void
}

export const AttachmentsField = forwardRef<PickAttachmentHandle, AttachmentsProps>(
  ({ attachments, handleChangeAttachment }, ref) => {
    const { mutateAsync: uploadMutationAsync, isLoading } = useUploadMutation()

    const bottomSheetContext = useBottomSheetContext()

    const handlePickAttachment = async () => {
      const result = await DocumentPicker.getDocumentAsync({ copyToCacheDirectory: true })

      if (result.type !== 'cancel') {
        const { uri, name, size, mimeType } = result

        const extensionFile =
          Platform.OS === 'web'
            ? name.split('.').pop() ?? formatNameExtensionAttachments(uri)
            : mimeType?.split('/')[1] ?? formatNameExtensionAttachments(uri)

        const isValidFile = ACCEPTED_FILE_EXTENSION.includes(extensionFile)

        if (isValidFile) {
          const fileName = size ? `${name} &size: ${(size / 1000).toFixed(0)}` : name

          const response = await fetch(uri)

          const file = await response?.blob()

          if (file?.type && file?.type.split('/').length > 0) {
            const uploadResponse = await uploadMutationAsync({
              directory_type: DIRECTORY_TYPE.ACTIVITY_ATTACHMENTS,
              extension_type: extensionFile.toUpperCase() as keyof typeof EXTENSION_TYPE,
              files: [file]
            })

            const attachment = {
              file_name: uploadResponse[0].name,
              name: fileName,
              url: uploadResponse[0].url,
              created_at: new Date().toISOString()
            }

            const newList = attachments ? [...attachments, attachment] : [attachment]

            handleChangeAttachment(newList)

            return newList
          }
        } else {
          bottomSheetContext.open({
            title: 'Arquivo não compatível!',
            description: `Esta extensão de arquivo nao é suportada.\n Por favor anexe um dos formatos abaixo: \n.CSV, .DOC, .DOCX, .JPEG, .JPG, .PNG, .MP4, .MP3, .PDF, .PPT, .PPTX, .XLS, .XLSX, .XLSM, .ZIP`
          })
        }
      }
    }

    useImperativeHandle(ref, () => ({
      handlePickAttachment
    }))

    const handleDeleteAttachment = (attachmentName: string) => {
      const newList = attachments.filter((attachment) => attachment.name !== attachmentName)

      handleChangeAttachment(newList)
    }

    return (
      <VStack flex={1}>
        {attachments &&
          attachments.length > 0 &&
          attachments.map(({ url, name, created_at }) => {
            const extension = formatNameExtensionAttachments(url)

            const size = Number(formatSizeAttachments(name))

            const createdDate = format(new Date(created_at), "dd'/'MM'/'yyyy", {
              locale: ptBR
            })

            const fileName = formatNameAttachments(name)

            const IconComponent = Icons[selectIcon(extension)]

            const LIMIT_KB = 1000000

            const sizeFile = size < LIMIT_KB ? size + 'KB' : size + 'MB'

            return (
              <Pressable key={name}>
                <HStack
                  mt={{ lg: 4 }}
                  borderStyle="dashed"
                  backgroundColor="white"
                  borderWidth={1}
                  borderRadius="20px"
                  p={4}
                  borderColor="gray.200"
                  height="70px"
                  justifyContent="space-between"
                  alignItems="center"
                  flex={1}
                  mb={{ base: 4, lg: 0 }}>
                  <HStack alignItems="center" paddingRight="10px">
                    <IconComponent />
                  </HStack>
                  <VStack space={2} flexGrow={1}>
                    <Heading
                      numberOfLines={1}
                      isTruncated
                      maxW="75%"
                      fontSize="14px"
                      lineHeight="18px"
                      fontWeight="bold"
                      color="gray.700">
                      {fileName}
                    </Heading>
                    <HStack alignItems="center">
                      <Text
                        mr={2}
                        fontSize="12px"
                        lineHeight="16px"
                        fontWeight="normal"
                        color="gray.500">
                        .{extension} {sizeFile}
                      </Text>
                      <Text fontSize="12px" lineHeight="16px" fontWeight="normal" color="gray.500">
                        {createdDate}
                      </Text>
                    </HStack>
                  </VStack>
                  <Button
                    onPress={() => handleDeleteAttachment(name)}
                    variant="ghost"
                    p={2}
                    _pressed={{
                      bg: 'transparent',
                      opacity: 0.3
                    }}>
                    <Icons.Close size={3} color="gray.700" />
                  </Button>
                </HStack>
              </Pressable>
            )
          })}

        {isLoading && (
          <HStack
            w="100%"
            h="70px"
            p={4}
            borderWidth="1"
            space={8}
            rounded="md"
            borderColor="coolGray.200"
            borderStyle="dashed">
            <HStack space={1} flex={5}>
              <Box flex={1}>
                <Skeleton w={8} rounded="sm" />
              </Box>
              <VStack space={2} flex={4}>
                <Skeleton w="50%" h={6} rounded="sm" />
                <Skeleton w="25%" h={2} rounded="sm" />
              </VStack>
              <Center flex={1}>
                <Skeleton size={6} rounded="full" />
              </Center>
            </HStack>
          </HStack>
        )}
      </VStack>
    )
  }
)
