// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const WarningIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 20 20', ...props }}>
    <G clipPath="url(#clip0_14047_199193)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0008 5.66552C10.4611 5.66552 10.8342 6.03862 10.8342 6.49886V10.4989C10.8342 10.9591 10.4611 11.3322 10.0008 11.3322C9.5406 11.3322 9.1675 10.9591 9.1675 10.4989V6.49886C9.1675 6.03862 9.5406 5.66552 10.0008 5.66552Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6676 14.6509C10.7722 14.5116 10.8342 14.3385 10.8342 14.1509C10.8342 13.6906 10.4611 13.3175 10.0008 13.3175C9.64721 13.3175 9.30808 13.458 9.05803 13.708C8.80798 13.9581 8.6675 14.2972 8.6675 14.6509C8.6675 15.0045 8.80798 15.3436 9.05803 15.5937C9.30807 15.8437 9.64721 15.9842 10.0008 15.9842C10.4611 15.9842 10.8342 15.6111 10.8342 15.1509C10.8342 14.9633 10.7722 14.7901 10.6676 14.6509Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.1675 14.1509C9.1675 13.6906 9.5406 13.3175 10.0008 13.3175C10.3545 13.3175 10.6936 13.458 10.9436 13.708C11.1937 13.9581 11.3342 14.2972 11.3342 14.6509C11.3342 15.0045 11.1937 15.3436 10.9436 15.5937C10.6936 15.8437 10.3545 15.9842 10.0008 15.9842C9.5406 15.9842 9.1675 15.6111 9.1675 15.1509C9.1675 14.9633 9.22949 14.7901 9.33411 14.6509C9.22949 14.5116 9.1675 14.3385 9.1675 14.1509Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63525 0.538511C9.04859 0.293402 9.52028 0.164062 10.0008 0.164062C10.4814 0.164062 10.9531 0.293402 11.3664 0.538511C11.7787 0.782974 12.1177 1.13361 12.3482 1.55378L19.3529 14.1211C19.6758 14.7078 19.8402 15.3686 19.8299 16.0382C19.8196 16.7079 19.6349 17.3632 19.2941 17.9398C18.9533 18.5163 18.4681 18.994 17.8864 19.3259C17.3047 19.6577 16.6465 19.8322 15.9768 19.8322C15.9768 19.8322 15.9768 19.8322 15.9768 19.8322H4.02083C3.35188 19.8321 2.6944 19.6577 2.11339 19.3261C1.53238 18.9946 1.0478 18.5174 0.707422 17.9415C0.367045 17.3656 0.182622 16.7109 0.172331 16.0421C0.16204 15.3732 0.326237 14.7132 0.648736 14.1271L0.650841 14.1233L7.65339 1.55392C7.88387 1.13368 8.22295 0.783 8.63525 0.538511ZM10.0008 1.83073C9.81943 1.83073 9.64137 1.87955 9.48534 1.97208C9.32931 2.06461 9.20105 2.19742 9.11404 2.35659L9.11084 2.36244L2.10893 14.9306C2.10861 14.9312 2.10829 14.9318 2.10797 14.9323C1.92574 15.2642 1.83298 15.6378 1.8388 16.0164C1.84464 16.3957 1.94921 16.7669 2.14221 17.0934C2.33521 17.42 2.60998 17.6906 2.93943 17.8786C3.26884 18.0666 3.64156 18.1655 4.02083 18.1655C4.0208 18.1655 4.02087 18.1655 4.02083 18.1655H15.9768C16.3569 18.1655 16.7304 18.0665 17.0605 17.8782C17.3906 17.6899 17.666 17.4188 17.8594 17.0916C18.0528 16.7645 18.1575 16.3925 18.1634 16.0125C18.1692 15.6332 18.0763 15.2589 17.8937 14.9264C17.8934 14.9258 17.8931 14.9252 17.8927 14.9246L10.8876 2.35661C10.8006 2.19744 10.6724 2.06461 10.5163 1.97208C10.3603 1.87955 10.1822 1.83073 10.0008 1.83073Z"
        fill="currentColor"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_14047_199193">
        <Rect width={20} height={20} fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)
