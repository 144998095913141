import { memo } from 'react'

import { Box, HStack, Skeleton, VStack } from 'native-base'

export const SkeletonHome = memo(() => {
  return (
    <>
      <HStack space={1} mb={4} flex={5}>
        <Skeleton w="50%" h={6} rounded="sm" />
      </HStack>
      <HStack
        w="100%"
        h="70px"
        p={4}
        mb={6}
        borderWidth="1"
        space={8}
        rounded="md"
        borderColor="coolGray.200"
        borderStyle="dashed">
        <HStack space={1} flex={5}>
          <Box flex={1}>
            <Skeleton w={8} rounded="sm" />
          </Box>
          <VStack space={2} flex={4}>
            <Skeleton w="50%" h={6} rounded="sm" />
            <Skeleton w="25%" h={2} rounded="sm" />
          </VStack>
        </HStack>
      </HStack>

      <HStack space={1} mb={4} flex={5}>
        <Skeleton w="50%" h={6} rounded="sm" />
      </HStack>
      <HStack
        w="100%"
        h="70px"
        p={4}
        mb={6}
        borderWidth="1"
        space={8}
        rounded="md"
        borderColor="coolGray.200"
        borderStyle="dashed">
        <HStack space={1} flex={5}>
          <Box flex={1}>
            <Skeleton w={8} rounded="sm" />
          </Box>
          <VStack space={2} flex={4}>
            <Skeleton w="100%" h={6} rounded="sm" />
            <Skeleton w="50%" h={2} rounded="sm" />
          </VStack>
        </HStack>
      </HStack>

      <HStack space={1} mb={4} flex={5}>
        <Skeleton w="50%" h={6} rounded="sm" />
      </HStack>
      <HStack
        w="100%"
        h="70px"
        p={4}
        mb={6}
        borderWidth="1"
        space={8}
        rounded="md"
        borderColor="coolGray.200"
        borderStyle="dashed">
        <HStack space={1} flex={5}>
          <Box flex={1}>
            <Skeleton w={8} rounded="sm" />
          </Box>
          <VStack space={2} flex={4}>
            <Skeleton w="50%" h={6} rounded="sm" />
            <Skeleton w="25%" h={2} rounded="sm" />
          </VStack>
        </HStack>
      </HStack>
    </>
  )
})
