import { TNoteParam } from './hooks'
import * as serializers from './serializers'
import {
  Contact,
  GetListsParams,
  ContactSerialized,
  GetNoteListParams,
  GetTabListParams,
  WalletItem,
  Note,
  ClassificationTAB,
  RegulatoryTAB,
  EcosystemTAB,
  TabDetail,
  GetAttachmentListParams,
  Attachment,
  DeleteAttachmentParams,
  CreateAttachmentParams,
  AttachmentList,
  ProductToUpdate,
  WalletSummary,
  TContract,
  SummaryChartDataSerialized,
  TContractParams
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'
import { ProductionStoresList } from '../production'

export const getContactItem = async (id: string) =>
  serializers.getContactItem(
    await client.get<ResponseSuccess<ContactSerialized>>(`v1/contacts/${id}`)
  )

export const getTabItem = async (tabNumber: string) =>
  await client.get<ResponseSuccess<TabDetail>>(`v1/stores/${tabNumber}`)

export const getContactList = async (params: GetListsParams) =>
  serializers.getContactList(
    await client.get<ResponseSuccess<WalletItem[]>>('v1/contacts', {
      params: {
        ...params,
        per_page: params?.per_page ?? 10,
        page: params?.page ?? 1
      }
    })
  )

export const getTabList = async (params: GetTabListParams) => {
  const response = await client.get<ResponseSuccess<ProductionStoresList>>('v1/stores', {
    params: {
      ...params,
      per_page: params?.per_page ?? 10,
      page: params?.page ?? 1
    }
  })

  return serializers.getTabList(response)
}

export const getContactBirthday = async (contactId: string) =>
  serializers.getContactBirthdayItem(
    await client.get<ResponseSuccess<Contact>>(`v1/contacts/${contactId}`)
  )

export const editContactBirthday = async (contactId: string, birthday: Contact) =>
  await client.patch<ResponseSuccess<Contact>>(`v1/contacts/${contactId}`, birthday)

export const getNoteList = async (params: GetNoteListParams) => {
  const response = await client.get<ResponseSuccess<Note[]>>(`v1/contacts/${params.id}/notes`, {
    params: {
      ...params,
      per_page: params?.per_page ?? 3,
      page: params?.page ?? 1
    }
  })

  return serializers.getNoteList(response)
}

export const getNoteItem = async (id: string, key: 'contacts' | 'stores') =>
  serializers.getNoteItem(await client.get<ResponseSuccess<Note>>(`v1/${key}/notes/${id}`))

export const createNote = async (note: Note, key: 'contacts' | 'stores') =>
  await client.post<ResponseSuccess<Note>>(`v1/${key}/notes`, {
    ...note
  })

export const editNote = async (note: Note, key: 'contacts' | 'stores') =>
  await client.patch<ResponseSuccess<Note>>(`v1/${key}/notes/${note.id}`, {
    ...note
  })

export const getRegulatoryByTabNumber = async (tabNumber: string) =>
  await client.get<ResponseSuccess<RegulatoryTAB>>(`v1/stores/${tabNumber}/regulatory`)

export const getEcosystemByTabNumber = async (tabNumber: string) =>
  await client.get<ResponseSuccess<EcosystemTAB>>(`v1/stores/${tabNumber}/ecosystem`)

export const favoriteContact = async ({ id }: Pick<ContactSerialized, 'id'>) => {
  return await client.post(`v1/contacts/${id}/favorite`)
}

export const unfavoriteContact = async ({ id }: Pick<ContactSerialized, 'id'>) =>
  await client.delete(`v1/contacts/${id}/favorite`)

export const favoriteTab = async ({ identifier }: Pick<TabDetail, 'identifier'>) => {
  return await client.post(`v1/stores/${identifier}/favorite`)
}

export const unfavoriteTab = async ({ identifier }: Pick<TabDetail, 'identifier'>) =>
  await client.delete(`v1/stores/${identifier}/favorite`)

export const getSummaryByTabNumber = async (tabNumber: string) => {
  const response = await client.get<ResponseSuccess<SummaryChartDataSerialized>>(
    `v1/stores/${tabNumber}/summary`
  )

  return serializers.getSummaryChart(response)
}

export const getClassificationByTabNumber = async (tabNumber: string) =>
  await client.get<ResponseSuccess<ClassificationTAB>>(`v1/stores/${tabNumber}/classification`)

export const getTabNoteList = async (params: GetNoteListParams) => {
  const response = await client.get<ResponseSuccess<Note[]>>(`v1/stores/${params.id}/notes`, {
    params: {
      ...params,
      per_page: params?.per_page ?? 10,
      page: params?.page ?? 1
    }
  })

  return serializers.getNoteList(response)
}

export const createContactItem = async (contact: Contact) =>
  await client.post<ResponseSuccess<Contact>>('v1/contacts', {
    ...contact
  })

export const editContactItem = async (contact: Contact) =>
  await client.patch<ResponseSuccess<Contact>>(`v1/contacts/${contact.id}`, {
    ...contact
  })

export const editTabItem = async (tab: TabDetail) =>
  await client.patch<ResponseSuccess<TabDetail>>(`v1/stores/${tab?.tab_number}`, {
    ...tab
  })

export const getAttachmentList = (params: GetAttachmentListParams) =>
  client.get<ResponseSuccess<AttachmentList[]>>(`v1/${params.type}/${params.id}/attachments`, {
    params: {
      ...params,
      per_page: params?.per_page ?? 5,
      page: params?.page ?? 1
    }
  })

export const createAttachment = async ({ type, attachment, id }: CreateAttachmentParams) =>
  await client.post<ResponseSuccess<Attachment>>(`v1/${type}/${id}/attachments`, {
    ...attachment
  })

export const deleteAttachment = async ({ type, id, attachmentId }: DeleteAttachmentParams) =>
  await client.delete(`v1/${type}/${id}/attachments/${attachmentId}`)

export const deleteContactItem = async ({ id }: Pick<Contact, 'id'>) =>
  await client.delete<ResponseSuccess<Pick<Contact, 'id'>>>(`v1/contacts/${id}`)

export const editTabStatus = async (tabNumber: string, product: ProductToUpdate) =>
  await client.patch<ResponseSuccess<null>>(`v1/stores/${tabNumber}/product`, {
    ...product
  })

export const getWalletSumary = async () =>
  await client.get<ResponseSuccess<WalletSummary>>('v1/wallets/summary')

export const deleteNote = async ({ id }: Pick<Note, 'id'>) =>
  await client.delete<ResponseSuccess<Pick<Note, 'id'>>>(`v1/notes/${id}`)

export const getContractsByTabNumber = async ({ tabNumber, yearMonth }: TContractParams) =>
  await client.get<ResponseSuccess<TContract>>(`v1/stores/${tabNumber}/contracts/summary`, {
    params: { year_month: yearMonth }
  })

export const deleteContactNote = async ({ id }: TNoteParam) =>
  await client.delete<ResponseSuccess<any>>(`v1/contacts/notes/${id}`)

export const deleteStoreNote = async ({ id }: TNoteParam) =>
  await client.delete<ResponseSuccess<any>>(`v1/stores/notes/${id}`)
