type Resize = (_: { base?: 'height' | 'width'; height: number; size: number; width: number }) => {
  height: number
  width: number
}

const resize: Resize = ({ base, height, size, width }) => {
  let newHeight = size

  let newWidth = size

  if (height > width || base === 'height') {
    newHeight = size

    newWidth = (size * width) / height
  } else if (height < width || base === 'width') {
    newHeight = (size * height) / width

    newWidth = size
  }

  return {
    height: newHeight,
    width: newWidth
  }
}

export default resize
