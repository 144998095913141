import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'

import { ListFiles, LoadingTurbo } from 'atoms'
import { PickAttachmentHandle } from 'atoms/ListFiles/ListFiles'
import { AttachmentList, useGetAttachmentListInfiniteQuery } from 'integration/resources/wallet'
import { ButtonAttach } from 'molecules'
import { Center, VStack, useBreakpointValue } from 'native-base'
import { Dimensions } from 'react-native'
import { CurrentViewTypesRef } from 'src/components/templates/ContactScreenTemplate/ContactScreenTemplate'

import { DocumentsDesktop } from './desktop'
import { TabCommonProps } from './types'

const attachmentsMergeDataInfiniteQuery = (
  data: ReturnType<typeof useGetAttachmentListInfiniteQuery>['data']
) =>
  data?.pages.reduce<AttachmentList[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const DocumentsMobile = forwardRef<CurrentViewTypesRef, TabCommonProps>(
  ({ tabNumber }, ref) => {
    const listFilesRef = useRef<PickAttachmentHandle>(null)

    const handlePickAttachment = () => listFilesRef.current?.handlePickAttachment()

    const {
      data: allAttachmentsData,
      isFetchingNextPage: allAttachmentsIsFetchingNextPage,
      hasNextPage: allAttachmentsHasNextPage,
      fetchNextPage: allAttachmentsFetchNextPage,
      isFetching: allAttachmentsisFetching,
      isLoading
    } = useGetAttachmentListInfiniteQuery({
      id: tabNumber ?? '',
      type: 'stores'
    })

    const allAttachments = useMemo(
      () => attachmentsMergeDataInfiniteQuery(allAttachmentsData),
      [allAttachmentsData]
    )

    const onScrollEndDragAllAttachmentsList = useCallback(() => {
      if (!allAttachmentsIsFetchingNextPage && allAttachmentsHasNextPage) {
        allAttachmentsFetchNextPage()
      }
    }, [allAttachmentsIsFetchingNextPage, allAttachmentsHasNextPage, allAttachmentsFetchNextPage])

    useImperativeHandle(ref, () => ({
      onScrollEndDragContent: onScrollEndDragAllAttachmentsList
    }))

    const screenHeight = Dimensions.get('screen').height

    return (
      <VStack minH={370}>
        {isLoading ? (
          <Center h={360} w="full" position="absolute" zIndex={9}>
            <LoadingTurbo size={100} />
          </Center>
        ) : (
          <>
            {tabNumber ? (
              <ListFiles
                attachment={{
                  allAttachmentsIsFetchingNextPage,
                  allAttachmentsIsLoading: allAttachmentsisFetching,
                  allAttachments
                }}
                type="stores"
                id={tabNumber}
                ref={listFilesRef}
              />
            ) : null}

            <VStack pb={screenHeight > 844 ? 4 : 10} mb={12}>
              <ButtonAttach
                title="Adicione um documento"
                handlePickAttachment={handlePickAttachment}
              />
            </VStack>
          </>
        )}
      </VStack>
    )
  }
)

export const Documents = forwardRef<CurrentViewTypesRef, TabCommonProps>((props, ref) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  if (!isMobile) return <DocumentsDesktop {...props} ref={ref} />

  return <DocumentsMobile {...props} ref={ref} />
})
