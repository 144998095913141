import { FunctionComponent, Suspense, lazy } from 'react'

import { Skeleton } from 'native-base'

import { TabInfosProps } from './TabInfos.types'
import { useTabInfos } from './useTabInfos'

const TabInfosDesktop = lazy(() => import('./TabInfos.desktop'))

const TabInfosMobile = lazy(() => import('./TabInfos.mobile'))

export const TabInfos: FunctionComponent<TabInfosProps> = (props) => {
  const tabInfos = useTabInfos(props)

  const Component = tabInfos.isMobile ? TabInfosMobile : TabInfosDesktop

  return (
    <Suspense fallback={<Skeleton minHeight="104px" mb={4} borderRadius="20px" />}>
      <Component {...tabInfos} />
    </Suspense>
  )
}
