import { FunctionComponent, lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'
import { useBreakpointValue } from 'native-base'

type TDataChart = {
  chartData: {
    last_updated_at?: string
    market_share: number
    production: number
    year_month: string
  }[]
  higherValue: number
  processedAt: string
  isGoodsAndServices: boolean
  isShortFormat?: boolean
}

const TabSummaryMobile = lazy(() => import('./TabSummaryChart.mobile'))

const TabSummaryDesktop = lazy(() => import('./TabSummaryChart.desktop'))

export const TabSummaryChart: FunctionComponent<TDataChart> = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const Component = isMobile ? TabSummaryMobile : TabSummaryDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Component {...props} />
    </Suspense>
  )
}
