import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { PhoneEmailScreenComponent } from './PhoneEmailScreen.types'
import { useCreateAccountPhoneEmailScreen } from './useCreateAccountPhoneEmailScreen'

const PhoneEmailScreenDesktop = lazy(() => import('./PhoneEmailScreen.desktop'))

const PhoneEmailScreenMobile = lazy(() => import('./PhoneEmailScreen.mobile'))

export const CreateAccountPhoneEmailScreen: PhoneEmailScreenComponent = ({ navigation, route }) => {
  const phoneEmailScreen = useCreateAccountPhoneEmailScreen({ navigation, route })

  const Screen = phoneEmailScreen.isMobile ? PhoneEmailScreenMobile : PhoneEmailScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...phoneEmailScreen} />
    </Suspense>
  )
}
