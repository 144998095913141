import { FunctionComponent, memo } from 'react'

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Icons, Illustrations } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { Center, Heading, HStack, ScrollView, Text, VStack } from 'native-base'
import { RootStackParamList } from 'navigation/types'
import { TouchableOpacity } from 'react-native'
import { formatDateStringToMask } from 'src/utils'

import { useOpportunityContainerList } from './useOpportunityContainerList'

type OpportunityContainerListProps = {
  tabNumber: string
}

export const OpportunityContainerList: FunctionComponent<OpportunityContainerListProps> = memo(
  ({ tabNumber }) => {
    const { isLoading, opportunities, onScrollEndDragOpportunityList } =
      useOpportunityContainerList({
        store_tab_numbers: [Number(tabNumber)]
      })

    const activitySkeleton = isLoading && !opportunities.length

    const noResults = !opportunities.length && !isLoading

    const contentPreview = new Array<number>(5).fill(0)

    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        onScrollEndDrag={onScrollEndDragOpportunityList}>
        {activitySkeleton &&
          contentPreview.map((_, i) => <SkeletonCard key={`key-skeleton-${i}`} />)}
        {opportunities.map(({ title, store, due_date, earningPotential, id }) => (
          <VStack space={4} mb={2} key={id}>
            <VStack bg="white" borderRadius="20px" p={4}>
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate('OrganizationOpportunity', {
                    id: String(id)
                  })
                }>
                <HStack space={2} alignItems="center">
                  <Center
                    bg="white"
                    borderWidth={1}
                    borderColor="gray.50"
                    borderRadius="full"
                    mr={2}
                    h={12}
                    w={12}>
                    <Icons.Dollar color="gray.700" size={24} />
                  </Center>
                  <VStack flex={1}>
                    <Heading
                      fontSize="14px"
                      lineHeight="18px"
                      fontWeight="bold"
                      color="gray.700"
                      noOfLines={1}
                      mb={1}>
                      {title}
                    </Heading>
                    {store?.name_fantasy && (
                      <Text
                        fontSize="12px"
                        lineHeight="16px"
                        fontWeight="normal"
                        color="gray.400"
                        mb={1}>
                        TAB {store?.tab_number} • {formatDateStringToMask(due_date, 'dd/MM/yyyy')}
                      </Text>
                    )}

                    <Text fontSize="12px" lineHeight="16px" fontWeight="normal" color="gray.400">
                      R$ {earningPotential}
                    </Text>
                  </VStack>
                </HStack>
              </TouchableOpacity>
            </VStack>
          </VStack>
        ))}

        {noResults && (
          <Center>
            <Illustrations.RelatedOpportunitiesEmpty />
            <Text fontSize="16px" lineHeight="24px" color="gray.500" textAlign="center" mt={6}>
              Ainda não há oportunidades relacionadas para este TAB.
            </Text>
          </Center>
        )}
      </ScrollView>
    )
  }
)
