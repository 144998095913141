import { FunctionComponent } from 'react'

import { Cards } from 'atoms'
import { ContentSerialized } from 'integration/resources/knowledgeBase'
import { Box, Center, FlatList, HStack, Skeleton, Spinner, VStack } from 'native-base'

type HighlightedContentsProps = {
  highlightedIsFetchingNextPage: boolean
  highlightedIsLoading?: boolean
  highlightedList: ContentSerialized[]
  onEndReachedHighlightedList(): void
  onPressContentCard(content: ContentSerialized): void
  title?: string
}

export const HighlightedContents: FunctionComponent<HighlightedContentsProps> = ({
  highlightedIsFetchingNextPage,
  highlightedIsLoading = false,
  highlightedList,
  onEndReachedHighlightedList,
  onPressContentCard,
  title
}) => {
  return (
    <>
      <FlatList
        display={{ base: 'flex', lg: 'none' }}
        contentContainerStyle={{
          paddingTop: highlightedList.length ? 16 : 0
        }}
        data={highlightedList}
        horizontal
        keyExtractor={(item) => `${item.id}`}
        ListHeaderComponent={
          <>
            {highlightedIsLoading && (
              <HStack space={4} pl={4} pt={8}>
                {new Array(5).fill(1).map((i) => (
                  <Skeleton
                    h="190px"
                    rounded="24px"
                    width="300px"
                    key={`key-skeleton-${i}-${Math.random()}`}
                  />
                ))}
              </HStack>
            )}
          </>
        }
        ListFooterComponent={
          highlightedIsFetchingNextPage ? (
            <Center flex={1} mx={2}>
              <Spinner />
            </Center>
          ) : null
        }
        onEndReached={onEndReachedHighlightedList}
        onEndReachedThreshold={0.1}
        renderItem={({ item, index }) =>
          !highlightedIsLoading ? (
            <Box w="300px" mr={4} pl={index === 0 ? 4 : 0} key={item.id}>
              <Cards.HighlightedContent {...item} onPress={() => onPressContentCard(item)} />
            </Box>
          ) : (
            <></>
          )
        }
        showsHorizontalScrollIndicator={false}
      />

      <HStack display={{ base: 'none', lg: 'flex' }}>
        {!highlightedIsLoading && highlightedList.length > 0 && (
          <Box flex={3} h={{ lg: '433px' }} mr={4} pl={4}>
            <Cards.HighlightedContent
              {...highlightedList[0]}
              onPress={() => onPressContentCard(highlightedList[0])}
            />
          </Box>
        )}

        <VStack justifyContent="space-between" flex={2}>
          {highlightedList.slice(1).map((item) => (
            <Box h={{ lg: '200px' }} mr={4} pl={4} key={item.id}>
              <Cards.HighlightedContent {...item} onPress={() => onPressContentCard(item)} />
            </Box>
          ))}
        </VStack>
      </HStack>
    </>
  )
}
