import Svg, { Defs, Path, SvgProps, LinearGradient, Stop } from 'react-native-svg'

export const LogoTabBar = (props: SvgProps) => {
  return (
    <Svg width={109} height={46} viewBox="0 0 109 46" fill="none">
      <Path
        d="M38.5729 17.5563C38.5729 18.3318 38.7943 18.8811 39.237 19.2042C39.6798 19.5273 40.3018 19.6889 41.103 19.6889C41.4615 19.6889 41.841 19.6351 42.2416 19.5273C42.6422 19.4196 42.9901 19.2904 43.2853 19.1396C43.4329 19.3119 43.5594 19.5058 43.6648 19.7212C43.7913 19.9366 43.8546 20.1951 43.8546 20.4967C43.8546 21.0568 43.5699 21.5199 43.0006 21.8861C42.4524 22.2523 41.6091 22.4354 40.4705 22.4354C38.8892 22.4354 37.6241 22.08 36.6753 21.3691C35.7476 20.6367 35.2837 19.4412 35.2837 17.7825V2.69286C35.4313 2.64978 35.6421 2.59593 35.9162 2.5313C36.1903 2.46668 36.4855 2.43437 36.8018 2.43437C37.4132 2.43437 37.856 2.55284 38.1301 2.7898C38.4253 3.02675 38.5729 3.42527 38.5729 3.98534V7.18421H43.475C43.5594 7.31346 43.6332 7.49656 43.6964 7.73351C43.7808 7.97047 43.8229 8.21819 43.8229 8.47669C43.8229 9.40296 43.4329 9.8661 42.6528 9.8661H38.5729V17.5563Z"
        fill="#F3123C"
      />
      <Path
        d="M47.1121 6.89341C47.2597 6.85032 47.4706 6.80724 47.7447 6.76416C48.0188 6.69953 48.314 6.66722 48.6302 6.66722C49.2417 6.66722 49.6845 6.7857 49.9586 7.02265C50.2537 7.25961 50.4013 7.66889 50.4013 8.2505V16.2315C50.4013 17.4809 50.7176 18.3749 51.3501 18.9134C52.0038 19.4304 52.8999 19.6889 54.0384 19.6889C54.7764 19.6889 55.3984 19.6135 55.9044 19.4627C56.4104 19.3119 56.811 19.1504 57.1062 18.978V6.89341C57.2749 6.85032 57.4858 6.80724 57.7388 6.76416C58.0129 6.69953 58.308 6.66722 58.6243 6.66722C59.2358 6.66722 59.6891 6.7857 59.9843 7.02265C60.2795 7.25961 60.427 7.66889 60.427 8.2505V19.1396C60.427 19.6135 60.3533 20.0013 60.2057 20.3028C60.0581 20.6044 59.7629 20.8952 59.3201 21.1753C58.7719 21.4984 58.0445 21.7892 57.1379 22.0477C56.2523 22.3062 55.2086 22.4354 54.0068 22.4354C51.8351 22.4354 50.1378 21.9507 48.9149 20.9814C47.713 19.9905 47.1121 18.4287 47.1121 16.2962V6.89341Z"
        fill="#F3123C"
      />
      <Path
        d="M68.0679 21.9184C67.9203 21.9831 67.7095 22.0369 67.4354 22.08C67.1823 22.1446 66.8977 22.1769 66.5814 22.1769C65.97 22.1769 65.5167 22.0584 65.2215 21.8215C64.9263 21.5845 64.7787 21.186 64.7787 20.626V10.0277C64.7787 9.48913 64.8841 9.0583 65.095 8.73518C65.3058 8.39052 65.6432 8.0674 66.107 7.76583C66.7185 7.37808 67.4986 7.05496 68.4474 6.79647C69.3962 6.53797 70.4399 6.40873 71.5785 6.40873C73.4339 6.40873 74.3616 6.94726 74.3616 8.02432C74.3616 8.28282 74.3195 8.53054 74.2351 8.7675C74.1508 8.98291 74.0559 9.17678 73.9505 9.34911C73.7397 9.30603 73.4761 9.26294 73.1598 9.21986C72.8436 9.17678 72.5062 9.15524 72.1478 9.15524C71.3044 9.15524 70.5348 9.25217 69.839 9.44604C69.1432 9.61837 68.5528 9.84456 68.0679 10.1246V21.9184Z"
        fill="#F3123C"
      />
      <Path
        d="M84.1549 6.40873C85.1458 6.40873 86.0736 6.58106 86.938 6.92572C87.8025 7.24884 88.551 7.74429 89.1835 8.41206C89.8161 9.0583 90.3116 9.88764 90.67 10.9001C91.0284 11.891 91.2076 13.0542 91.2076 14.3898C91.2076 15.7469 91.0179 16.9316 90.6384 17.9441C90.2799 18.9565 89.7633 19.7966 89.0886 20.4644C88.4139 21.1106 87.6022 21.6061 86.6534 21.9507C85.7257 22.2739 84.6925 22.4354 83.5539 22.4354C82.4154 22.4354 81.4033 22.2954 80.5178 22.0154C79.6533 21.7353 78.947 21.4122 78.3988 21.046C77.956 20.7444 77.6397 20.4429 77.45 20.1413C77.2602 19.8397 77.1653 19.452 77.1653 18.978V1.01264C77.3129 0.969562 77.5238 0.915709 77.7979 0.851085C78.072 0.786462 78.3671 0.75415 78.6834 0.75415C79.2949 0.75415 79.7376 0.872627 80.0117 1.10958C80.3069 1.34653 80.4545 1.74505 80.4545 2.30512V7.4104C80.9184 7.1519 81.456 6.92572 82.0675 6.73185C82.6789 6.51643 83.3747 6.40873 84.1549 6.40873ZM83.6172 9.12292C82.9425 9.12292 82.3416 9.2414 81.8145 9.47836C81.2874 9.69377 80.834 9.95226 80.4545 10.2538V18.8488C80.7497 19.0642 81.1608 19.2581 81.688 19.4304C82.2151 19.6027 82.8265 19.6889 83.5223 19.6889C84.8085 19.6889 85.8522 19.2581 86.6534 18.3964C87.4546 17.5348 87.8552 16.1992 87.8552 14.3898C87.8552 12.5588 87.4546 11.2232 86.6534 10.3831C85.8732 9.54298 84.8612 9.12292 83.6172 9.12292Z"
        fill="#F3123C"
      />
      <Path
        d="M109 14.4221C109 15.6499 108.821 16.7593 108.462 17.7502C108.104 18.7411 107.598 19.5812 106.944 20.2705C106.312 20.9598 105.532 21.4984 104.604 21.8861C103.676 22.2523 102.643 22.4354 101.504 22.4354C100.366 22.4354 99.3327 22.2523 98.405 21.8861C97.4773 21.4984 96.6866 20.9598 96.033 20.2705C95.4004 19.5812 94.9049 18.7411 94.5465 17.7502C94.1881 16.7593 94.0088 15.6499 94.0088 14.4221C94.0088 13.1942 94.1881 12.0848 94.5465 11.0939C94.9049 10.1031 95.411 9.26294 96.0646 8.57362C96.7182 7.8843 97.5089 7.35654 98.4366 6.99034C99.3643 6.6026 100.387 6.40873 101.504 6.40873C102.622 6.40873 103.644 6.6026 104.572 6.99034C105.5 7.35654 106.291 7.89507 106.944 8.60593C107.598 9.29525 108.104 10.1354 108.462 11.1263C108.821 12.0956 109 13.1942 109 14.4221ZM101.504 9.12292C100.239 9.12292 99.2378 9.58606 98.4999 10.5123C97.7619 11.4386 97.3929 12.7419 97.3929 14.4221C97.3929 16.1023 97.7514 17.4055 98.4682 18.3318C99.1851 19.2365 100.197 19.6889 101.504 19.6889C102.812 19.6889 103.824 19.2365 104.541 18.3318C105.257 17.4055 105.616 16.1023 105.616 14.4221C105.616 12.7634 105.247 11.4709 104.509 10.5446C103.792 9.59683 102.791 9.12292 101.504 9.12292Z"
        fill="#F3123C"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8835 11.4297C18.8442 10.5899 18.5915 9.75678 18.1239 9.0037L13.9987 2.36043C13.6842 1.8537 13.4669 1.31094 13.3461 0.75415L13.1739 1.03181C12.1527 2.67608 12.1527 4.70186 13.1737 6.34613L16.4738 11.6608C17.4949 13.3052 17.4949 15.3312 16.4738 16.9754L16.3014 17.2531C16.1808 16.6963 15.9634 16.1535 15.6488 15.647L10.6987 7.67491C10.3842 7.16835 10.1668 6.62542 10.0462 6.0688L9.8738 6.34646C8.85625 7.98518 8.85309 10.0028 9.86363 11.6441L9.86346 11.6443L13.1739 16.9754C14.1948 18.6197 14.1948 20.6456 13.1739 22.2901L13.0014 22.5677C12.8807 22.0109 12.6635 21.4682 12.3489 20.9615L8.22376 14.3182C7.67053 13.4272 7.41767 12.4241 7.46396 11.4306C3.04812 12.5032 0 14.7811 0 17.4184C0 21.0873 5.89826 24.0615 13.1739 24.0615C20.4494 24.0615 26.3474 21.0873 26.3474 17.4184C26.3474 14.7809 23.2997 12.5026 18.8835 11.4297Z"
        fill="#F3123C"
      />
      <Path
        d="M1.66167 37.9452C2.39932 37.9452 2.93757 38.0963 3.27642 38.3987C3.61527 38.701 3.7847 39.1246 3.7847 39.6694C3.7847 39.917 3.7456 40.1529 3.6674 40.3771C3.59181 40.5986 3.4667 40.7954 3.29206 40.9674C3.11742 41.1369 2.88674 41.2711 2.60002 41.3701C2.31591 41.4692 1.96533 41.5187 1.54829 41.5187H0.938355V43.6613H0V37.9452H1.66167ZM1.59911 38.7232H0.938355V40.7368H1.45054C1.74508 40.7368 1.99531 40.7029 2.20122 40.6351C2.40714 40.5647 2.56353 40.4539 2.6704 40.3028C2.77727 40.1516 2.8307 39.9522 2.8307 39.7046C2.8307 39.3735 2.73035 39.1272 2.52965 38.9656C2.33155 38.804 2.02137 38.7232 1.59911 38.7232Z"
        fill="#2E2E2E"
      />
      <Path
        d="M14.0049 40.7954C14.0049 41.2359 13.9489 41.6373 13.8368 41.9996C13.7273 42.3593 13.5618 42.6695 13.3403 42.9302C13.1187 43.1882 12.8411 43.3876 12.5075 43.5284C12.1739 43.6691 11.7829 43.7395 11.3345 43.7395C10.8784 43.7395 10.4822 43.6691 10.146 43.5284C9.81232 43.3876 9.53473 43.1869 9.31317 42.9263C9.09422 42.6656 8.93001 42.3541 8.82053 41.9918C8.71106 41.6295 8.65632 41.2281 8.65632 40.7876C8.65632 40.1985 8.75276 39.685 8.94565 39.2471C9.14114 38.8066 9.43698 38.4652 9.83318 38.2227C10.232 37.9777 10.735 37.8552 11.3424 37.8552C11.9367 37.8552 12.4306 37.9764 12.8242 38.2188C13.2178 38.4612 13.5123 38.8027 13.7078 39.2432C13.9059 39.6811 14.0049 40.1985 14.0049 40.7954ZM9.64159 40.7954C9.64159 41.2359 9.70154 41.6178 9.82145 41.941C9.94135 42.2616 10.1251 42.5092 10.3727 42.6838C10.623 42.8559 10.9436 42.9419 11.3345 42.9419C11.7281 42.9419 12.0487 42.8559 12.2964 42.6838C12.544 42.5092 12.7264 42.2616 12.8437 41.941C12.961 41.6178 13.0197 41.2359 13.0197 40.7954C13.0197 40.1255 12.8854 39.6016 12.617 39.2237C12.3511 38.8431 11.9262 38.6528 11.3424 38.6528C10.9514 38.6528 10.6295 38.7388 10.3766 38.9109C10.1264 39.0829 9.94135 39.3292 9.82145 39.6498C9.70154 39.9678 9.64159 40.3497 9.64159 40.7954Z"
        fill="#2E2E2E"
      />
      <Path
        d="M20.8432 37.9452C21.3358 37.9452 21.7425 38.0051 22.0631 38.125C22.3863 38.2449 22.6261 38.4274 22.7825 38.6724C22.9415 38.9174 23.021 39.2289 23.021 39.6068C23.021 39.8883 22.9688 40.1281 22.8646 40.3262C22.7603 40.5243 22.6235 40.6885 22.454 40.8189C22.2846 40.9492 22.1035 41.0535 21.9106 41.1316L23.5332 43.6613H22.4736L21.0934 41.3701H20.1746V43.6613H19.2363V37.9452H20.8432ZM20.7806 38.7271H20.1746V40.596H20.8237C21.2589 40.596 21.5743 40.5152 21.7698 40.3536C21.9679 40.192 22.067 39.9535 22.067 39.6381C22.067 39.3071 21.9614 39.0725 21.7503 38.9343C21.5418 38.7962 21.2185 38.7271 20.7806 38.7271Z"
        fill="#2E2E2E"
      />
      <Path
        d="M30.3362 43.6613H29.394V38.7428H27.7206V37.9452H32.0057V38.7428H30.3362V43.6613Z"
        fill="#2E2E2E"
      />
      <Path
        d="M40.4275 43.6613L39.8683 42.0739H37.6828L37.1237 43.6613H36.1345L38.2614 37.9217H39.3014L41.4244 43.6613H40.4275ZM39.6259 41.2685L39.0825 39.7046C39.0616 39.6368 39.0316 39.5417 38.9925 39.4191C38.9534 39.294 38.9144 39.1676 38.8753 39.0399C38.8362 38.9096 38.8036 38.8001 38.7775 38.7115C38.7514 38.8183 38.7189 38.9382 38.6798 39.0712C38.6433 39.2015 38.6068 39.324 38.5703 39.4387C38.5364 39.5534 38.5103 39.642 38.4921 39.7046L37.9447 41.2685H39.6259Z"
        fill="#2E2E2E"
      />
      <Path d="M46.1827 43.6613V37.9452H47.121V42.8637H49.549V43.6613H46.1827Z" fill="#2E2E2E" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.5589 38.0071C58.5589 37.686 58.2986 37.4258 57.9776 37.4258C57.6565 37.4258 57.3963 37.686 57.3963 38.0071V40.2159H55.1877C54.8666 40.2159 54.6064 40.4761 54.6064 40.7972C54.6064 41.1182 54.8666 41.3785 55.1877 41.3785H57.3963V43.5875C57.3963 43.9085 57.6565 44.1688 57.9776 44.1688C58.2986 44.1688 58.5589 43.9085 58.5589 43.5875V41.3785H60.7681C61.0891 41.3785 61.3494 41.1182 61.3494 40.7972C61.3494 40.4761 61.0891 40.2159 60.7681 40.2159H58.5589V38.0071Z"
        fill="url(#paint0_linear_11758_44840)"
      />
      <Path
        d="M69.022 43.6613H68.0798V38.7428H66.4064V37.9452H70.6915V38.7428H69.022V43.6613Z"
        fill="#2E2E2E"
      />
      <Path
        d="M80.0594 37.9452V41.6438C80.0594 42.0426 79.9747 42.3997 79.8053 42.7151C79.6385 43.0305 79.3856 43.2807 79.0468 43.4658C78.7079 43.6483 78.2818 43.7395 77.7683 43.7395C77.0358 43.7395 76.478 43.5479 76.0949 43.1647C75.7143 42.779 75.5241 42.2668 75.5241 41.6282V37.9452H76.4624V41.5461C76.4624 42.0231 76.5745 42.375 76.7987 42.6017C77.0228 42.8285 77.3578 42.9419 77.8035 42.9419C78.111 42.9419 78.3613 42.8885 78.5542 42.7816C78.7496 42.6721 78.893 42.5131 78.9842 42.3046C79.0781 42.0935 79.125 41.8393 79.125 41.5422V37.9452H80.0594Z"
        fill="#2E2E2E"
      />
      <Path
        d="M87.1323 37.9452C87.6249 37.9452 88.0315 38.0051 88.3521 38.125C88.6754 38.2449 88.9152 38.4274 89.0716 38.6724C89.2305 38.9174 89.31 39.2289 89.31 39.6068C89.31 39.8883 89.2579 40.1281 89.1537 40.3262C89.0494 40.5243 88.9126 40.6885 88.7431 40.8189C88.5737 40.9492 88.3925 41.0535 88.1997 41.1316L89.8222 43.6613H88.7627L87.3825 41.3701H86.4637V43.6613H85.5254V37.9452H87.1323ZM87.0697 38.7271H86.4637V40.596H87.1127C87.548 40.596 87.8634 40.5152 88.0589 40.3536C88.257 40.192 88.3561 39.9535 88.3561 39.6381C88.3561 39.3071 88.2505 39.0725 88.0394 38.9343C87.8308 38.7962 87.5076 38.7271 87.0697 38.7271Z"
        fill="#2E2E2E"
      />
      <Path
        d="M94.643 37.9452H96.3438C97.0815 37.9452 97.6379 38.052 98.0133 38.2658C98.3886 38.4795 98.5763 38.8483 98.5763 39.3722C98.5763 39.5912 98.5372 39.7893 98.459 39.9665C98.3834 40.1412 98.2726 40.2858 98.1267 40.4005C97.9807 40.5126 97.8009 40.5882 97.5871 40.6273V40.6664C97.8087 40.7055 98.0055 40.7746 98.1775 40.8736C98.3521 40.9726 98.489 41.1147 98.588 41.2998C98.6897 41.4848 98.7405 41.7246 98.7405 42.0192C98.7405 42.3685 98.6571 42.6656 98.4903 42.9106C98.3261 43.1556 98.0902 43.342 97.7826 43.4697C97.4776 43.5974 97.1153 43.6613 96.6957 43.6613H94.643V37.9452ZM95.5814 40.3028H96.4806C96.9055 40.3028 97.2001 40.2337 97.3643 40.0955C97.5285 39.9574 97.6106 39.7554 97.6106 39.4895C97.6106 39.2184 97.5128 39.023 97.3173 38.9031C97.1245 38.7832 96.8169 38.7232 96.3946 38.7232H95.5814V40.3028ZM95.5814 41.0613V42.8754H96.5706C97.0085 42.8754 97.316 42.7907 97.4933 42.6213C97.6705 42.4519 97.7592 42.2225 97.7592 41.9332C97.7592 41.7559 97.7188 41.6021 97.6379 41.4718C97.5598 41.3415 97.4307 41.2411 97.2509 41.1707C97.071 41.0978 96.8286 41.0613 96.5237 41.0613H95.5814Z"
        fill="#2E2E2E"
      />
      <Path
        d="M109 40.7954C109 41.2359 108.944 41.6373 108.832 41.9996C108.722 42.3593 108.557 42.6695 108.335 42.9302C108.114 43.1882 107.836 43.3876 107.502 43.5284C107.169 43.6691 106.778 43.7395 106.329 43.7395C105.873 43.7395 105.477 43.6691 105.141 43.5284C104.807 43.3876 104.53 43.1869 104.308 42.9263C104.089 42.6656 103.925 42.3541 103.815 41.9918C103.706 41.6295 103.651 41.2281 103.651 40.7876C103.651 40.1985 103.748 39.685 103.941 39.2471C104.136 38.8066 104.432 38.4652 104.828 38.2227C105.227 37.9777 105.73 37.8552 106.337 37.8552C106.932 37.8552 107.426 37.9764 107.819 38.2188C108.213 38.4612 108.507 38.8027 108.703 39.2432C108.901 39.6811 109 40.1985 109 40.7954ZM104.637 40.7954C104.637 41.2359 104.696 41.6178 104.816 41.941C104.936 42.2616 105.12 42.5092 105.368 42.6838C105.618 42.8559 105.938 42.9419 106.329 42.9419C106.723 42.9419 107.044 42.8559 107.291 42.6838C107.539 42.5092 107.721 42.2616 107.839 41.941C107.956 41.6178 108.015 41.2359 108.015 40.7954C108.015 40.1255 107.88 39.6016 107.612 39.2237C107.346 38.8431 106.921 38.6528 106.337 38.6528C105.946 38.6528 105.624 38.7388 105.372 38.9109C105.121 39.0829 104.936 39.3292 104.816 39.6498C104.696 39.9678 104.637 40.3497 104.637 40.7954Z"
        fill="#2E2E2E"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_11758_44840"
          x1="57.1238"
          y1="36.6618"
          x2="58.7685"
          y2="41.6211"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#F3123C" />
          <Stop offset="1" stopColor="#CC0000" />
          <Stop offset="1" stopColor="#FF393C" />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}
