import { FunctionComponent, useEffect, useMemo, useState } from 'react'

import { Icons, LoadingTurbo } from 'atoms'
import { Dropdown } from 'atoms/web/Dropdown'
import { GetListSubsegments } from 'integration/resources/store'
import { Button, Center, HStack, Menu, ScrollView, Text } from 'native-base'
import { useBottomSheetStatusTab } from 'organisms/BottomSheetStatusTab/hook'
import { useBottomSheetSubsegment } from 'organisms/BottomSheetSubSegment/hook'

export interface TabFilterFields {
  subSegment_name?: string
  subsegment_code?: number
  status?: string
  is_favorite?: number | boolean | undefined
}

interface Filters {
  subsegment: {
    label: string
    selected: number | undefined
  }
  subsegment_name: {
    selected: string | undefined
  }
  status: {
    label: string
    selected: string | undefined
  }
  favorites: {
    label: string
    selected: number | boolean | undefined
  }
}

interface WalletTabFiltersProps {
  initialValues: TabFilterFields
  onChange(filters: Filters): void
}

const INITIAL_STATE = {
  subsegment: {
    label: 'Subsegmento',
    selected: undefined
  },
  subsegment_name: {
    selected: undefined
  },
  status: {
    label: 'Status',
    selected: undefined
  },
  favorites: {
    label: 'Marcados como favorito',
    selected: undefined
  }
}

export const WalletTabFilters: FunctionComponent<WalletTabFiltersProps> = ({
  onChange,
  initialValues
}) => {
  const { subsegments, isLoadingSubsegments } = useBottomSheetSubsegment()

  const { statusTab, isLoadingStatusTab } = useBottomSheetStatusTab()

  const DIRTY_STATE = useMemo(
    () => ({
      subsegment: {
        ...INITIAL_STATE.subsegment,
        selected: initialValues?.subsegment_code
      },
      subsegment_name: {
        ...INITIAL_STATE.subsegment_name,
        selected: initialValues?.subSegment_name
      },
      status: {
        ...INITIAL_STATE.status,
        selected: initialValues?.status
      },
      favorites: {
        ...INITIAL_STATE.favorites,
        selected: initialValues?.is_favorite
      }
    }),
    [initialValues]
  )

  const [filters, setFilters] = useState<Filters>(DIRTY_STATE)

  const isDisabled =
    !filters.favorites.selected && !filters.subsegment.selected && !filters.status.selected

  const setNewFilterState = (filterName: keyof Filters, state: object) => {
    setFilters((oldState) => {
      const newstate = {
        ...oldState,
        [filterName]: {
          // @ts-ignore
          ...oldState[filterName],
          ...state
        }
      }

      onChange(newstate)

      return newstate
    })
  }

  useEffect(() => {
    setFilters(DIRTY_STATE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  return (
    <HStack mt={6} mb={4} space={2}>
      <Dropdown
        label={filters.subsegment_name.selected ?? filters.subsegment.label}
        active={!!filters.subsegment.selected}
        // w={400}
        renderItems={
          <>
            <ScrollView style={{ maxHeight: 480 }}>
              {subsegments?.data.data?.map((subsegment: GetListSubsegments) => (
                <Menu.Item
                  key={subsegment.code}
                  onPress={() => [
                    setNewFilterState('subsegment', { selected: subsegment.code }),
                    setNewFilterState('subsegment_name', { selected: subsegment.name })
                  ]}
                  bgColor={
                    Number(filters.subsegment.selected) === subsegment.code ? 'gray.70' : 'white'
                  }>
                  <HStack py={2}>
                    <Text fontSize="16px" lineHeight="24px" color="gray.500" mr={4}>
                      {subsegment.name}
                    </Text>
                  </HStack>
                </Menu.Item>
              ))}

              {isLoadingSubsegments && (
                <Center mt={2}>
                  <LoadingTurbo size={48} />
                </Center>
              )}
            </ScrollView>
          </>
        }
      />

      <Dropdown
        label={filters.status.selected ?? filters.status.label}
        active={!!filters.status.selected}
        // w={300}
        renderItems={
          <HStack flexWrap="wrap">
            <ScrollView style={{ maxHeight: 480 }}>
              {statusTab?.data.data?.map((status: string) => (
                <Menu.Item
                  key={status}
                  onPress={() => setNewFilterState('status', { selected: status })}
                  bgColor={filters.status.selected === status ? 'gray.50' : 'white'}>
                  <HStack py={2}>
                    <Text fontSize="16px" lineHeight="24px" color="gray.500" mr={4}>
                      {status}
                    </Text>
                  </HStack>
                </Menu.Item>
              ))}

              {isLoadingStatusTab && (
                <Center mt={2}>
                  <LoadingTurbo size={48} />
                </Center>
              )}
            </ScrollView>
          </HStack>
        }
      />

      <Button
        px={6}
        _text={{
          fontSize: '16px'
        }}
        h={10}
        colorScheme={filters.favorites.selected ? 'primary-light' : 'white'}
        onPress={() => setNewFilterState('favorites', { selected: !filters.favorites.selected })}
        size="sm">
        {filters.favorites.label}
      </Button>

      <Button
        px={6}
        h={10}
        _text={{
          fontSize: '14px',
          color: 'gray.700'
        }}
        isDisabled={isDisabled}
        _disabled={{
          _text: {
            color: 'gray.300'
          }
        }}
        onPress={() => [setFilters(INITIAL_STATE), onChange(INITIAL_STATE)]}
        bgColor="background.light"
        colorScheme="gray"
        rightIcon={<Icons.Trash color={isDisabled ? 'gray.300' : 'gray.700'} />}
        size="sm">
        Limpar
      </Button>
    </HStack>
  )
}
