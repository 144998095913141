import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { SummaryDetailScreenComponent } from './SummaryDetailScreen.types'
import { useSummaryDetailScreen } from './useSummaryDetailScreen'

const SummaryDetailScreenDesktop = lazy(() => import('./SummaryDetailScreen.desktop'))

const SummaryDetailScreenMobile = lazy(() => import('./SummaryDetailScreen.mobile'))

export const SummaryDetailScreen: SummaryDetailScreenComponent = ({ navigation }) => {
  const summaryDetailScreen = useSummaryDetailScreen({ navigation })

  const Screen = summaryDetailScreen.isMobile
    ? SummaryDetailScreenMobile
    : SummaryDetailScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...summaryDetailScreen} />
    </Suspense>
  )
}
