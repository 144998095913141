import { FunctionComponent, PropsWithChildren, forwardRef } from 'react'

import { NavigationContainer as NavigationContainerReact } from '@react-navigation/native'
import { Text, useBreakpointValue } from 'native-base'
import { useAuthAtomValue } from 'src/store/auth'
import { useTokenAtomValue } from 'src/store/token'

export const RootNavigationContainer = forwardRef<FunctionComponent<PropsWithChildren>, any>(
  ({ children, ...props }, ref) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true })

    const authToken = useTokenAtomValue()

    const authAtom = useAuthAtomValue()

    if (isDesktop && authAtom === undefined && authToken === undefined) {
      return <Text>Carregando...</Text>
    }

    return (
      <NavigationContainerReact ref={ref} {...props}>
        {children}
      </NavigationContainerReact>
    )
  }
)
