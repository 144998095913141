import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ResetPasswordSuccessfullyScreenComponent } from './ResetPasswordSuccessfullyScreen.types'
import { useResetPasswordSuccessfullyScreen } from './useResetPasswordSuccessfullyScreen'

const ResetPasswordSuccessfullyScreenDesktop = lazy(
  () => import('./ResetPasswordSuccessfullyScreen.desktop')
)

const ResetPasswordSuccessfullyScreenMobile = lazy(
  () => import('./ResetPasswordSuccessfullyScreen.mobile')
)

export const ResetPasswordSuccessfullyScreen: ResetPasswordSuccessfullyScreenComponent = ({
  navigation
}) => {
  const props = useResetPasswordSuccessfullyScreen({ navigation })

  const Screen = props.isMobile
    ? ResetPasswordSuccessfullyScreenMobile
    : ResetPasswordSuccessfullyScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
