import Analytics from '@react-native-firebase/analytics'
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics'
import { Platform } from 'react-native'

export const LogEvent = (name: string, params?: { [key: string]: any }) => {
  // @ts-ignore
  const { item_id } = params

  if (Platform.OS === 'web') {
    import('./firebase-analytics-web').then((module: any) => {
      logEvent(module.default, 'select_content', {
        content_type: name.toLowerCase(),
        item_id: String(item_id)
      })
    })
  } else {
    Analytics().logSelectContent({
      content_type: name.toLowerCase(),
      item_id: String(item_id)
    })
  }
}

export const LogEventUserId = (id: string | null) => {
  if (Platform.OS !== 'web') {
    Analytics().setUserId(`${id}`)
  } else {
    import('./firebase-analytics-web').then((module) => {
      setUserId(module.default, id)
    })
  }
}

export const LogEventUserProperties = (params: { [key: string]: any }) => {
  if (Platform.OS !== 'web') {
    Analytics().setUserProperties(params)
  } else {
    import('./firebase-analytics-web').then((module) => {
      setUserProperties(module.default, params)
    })
  }
}

export const LogEventScreen = (params: { [key: string]: any }) => {
  if (Platform.OS !== 'web') {
    Analytics().logScreenView(params)
  } else {
    import('./firebase-analytics-web').then((module) => {
      logEvent(module.default, 'screen_view' as never, {
        ...params
      })
    })
  }
}
