import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { FirstLoginPasswordScreenComponent } from './FirstLoginPasswordScreen.types'
import { useFirstLoginPasswordScreen } from './useFirstLoginPasswordScreen'

const ScreenDesktop = lazy(() => import('./FirstLoginPasswordScreen.desktop'))

const ScreenMobile = lazy(() => import('./FirstLoginPasswordScreen.mobile'))

export const FirstLoginPasswordScreen: FirstLoginPasswordScreenComponent = ({
  navigation,
  route
}) => {
  const props = useFirstLoginPasswordScreen({
    navigation,
    route
  })

  const Screen = props.isMobile ? ScreenMobile : ScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
