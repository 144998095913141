import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { LeadsApnefScreenComponent } from './LeadsApnefScreen.types'
import { useLeadsApnefScreen } from './useLeadsApnefScreen'

const LeadsApnefScreenDesktop = lazy(() => import('./LeadsApnefScreen.desktop'))

const LeadsApnefScreenMobile = lazy(() => import('./LeadsApnefScreen.mobile'))

export const LeadsApnefScreen: LeadsApnefScreenComponent = ({ navigation, route }) => {
  const props = useLeadsApnefScreen({ navigation, route })

  const Screen = props.isMobile ? LeadsApnefScreenMobile : LeadsApnefScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
