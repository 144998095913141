import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { CPFScreenComponent } from './CPFScreen.types'
import { useCreateAccountCPFScreen } from './useCreateAccountCPFScreen'

const CPFScreenDesktop = lazy(() => import('./CPFScreen.desktop'))

const CPFScreenMobile = lazy(() => import('./CPFScreen.mobile'))

export const CPFScreen: CPFScreenComponent = ({ navigation }) => {
  const cpfScreen = useCreateAccountCPFScreen({ navigation })

  const Screen = cpfScreen.isMobile ? CPFScreenMobile : CPFScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...cpfScreen} />
    </Suspense>
  )
}
