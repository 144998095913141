import { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import Illustrations from 'atoms/Illustrations'
import axios from 'axios'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import * as DocumentPicker from 'expo-document-picker'
import * as Linking from 'expo-linking'
import { DIRECTORY_TYPE, EXTENSION_TYPE, useUploadMutation } from 'integration/resources/upload'
import {
  AttachmentList,
  useCreateAttachmentMutation,
  useDeleteAttachmentMutation,
  Attachment
} from 'integration/resources/wallet'
import { BottomSheetModal, CustomBottomSheet } from 'molecules'
import {
  Center,
  Box,
  Heading,
  HStack,
  Text,
  VStack,
  Button,
  Spinner,
  Skeleton,
  useToast,
  IconButton
} from 'native-base'
import { Toast } from 'organisms'
import { Dimensions, Platform, Pressable } from 'react-native'
import { ACCEPTED_FILE_EXTENSION } from 'src/constants/file'
import {
  formatNameAttachments,
  formatNameExtensionAttachments,
  formatSizeAttachments,
  selectIcon
} from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

import Icons from '../Icons'

type TAttachments = {
  attachment: {
    allAttachmentsIsFetchingNextPage: boolean
    allAttachmentsIsLoading: boolean
    allAttachments: AttachmentList[]
  }
  type: 'contacts' | 'stores'
  id: string
}

export type PickAttachmentHandle = {
  handlePickAttachment(): void
}

export const ListFiles = forwardRef<PickAttachmentHandle, TAttachments>(
  ({ attachment, type, id }, ref) => {
    const bottomSheetConfirmDeleteRef = useRef<BottomSheetModal>(null)

    const bottomSheetDownloadRef = useRef<BottomSheetModal>(null)

    const [itemSelected, setSelectedItem] = useState<Attachment>()

    const [disableDownload, setDisableDownload] = useState(false)

    const { mutateAsync: uploadMutationAsync, isLoading: uploadAttachmentIsLoading } =
      useUploadMutation()

    const { mutateAsync: deleteAttachmentMutation, isLoading: deleteAttachmentIsLoading } =
      useDeleteAttachmentMutation()

    const { mutateAsync: createAttachmentMutation, isLoading: createAttachmentIsLoading } =
      useCreateAttachmentMutation()

    const bottomSheetContext = useBottomSheetContext()

    const isLoading = uploadAttachmentIsLoading || createAttachmentIsLoading

    const toast = useToast()

    const isMobile = Platform.OS !== 'web'

    const handlePickAttachment = async () => {
      const result = await DocumentPicker.getDocumentAsync({ copyToCacheDirectory: true })

      if (result.type !== 'cancel') {
        const { uri, name, size, mimeType } = result

        const extensionFile = !isMobile
          ? name.split('.').pop() ?? formatNameExtensionAttachments(uri)
          : mimeType?.split('/')[1] ?? formatNameExtensionAttachments(uri)

        const isValidFile = ACCEPTED_FILE_EXTENSION.includes(extensionFile)

        if (isValidFile) {
          const LIMIT_KB = 1000000

          const sizeFormated = size
            ? `&size: ${
                size < LIMIT_KB
                  ? (size / 1000).toFixed(0) + 'KB'
                  : (size / 1000000).toFixed(0) + 'MB'
              }`
            : ''

          let nameSanitize = name.substring(0, name.lastIndexOf('.'))

          const finalName = `.${extensionFile}${sizeFormated}`

          nameSanitize = nameSanitize.slice(0, 80 - finalName.length)

          const fileName = `${nameSanitize}${finalName}`

          const response = await fetch(uri)

          const file = await response?.blob()

          if (file?.type && file?.type.split('/').length > 0) {
            const uploadResponse = await uploadMutationAsync({
              directory_type:
                type === 'contacts'
                  ? DIRECTORY_TYPE.CONTACT_ATTACHMENT
                  : DIRECTORY_TYPE.STORE_ATTACHMENT,
              extension_type: extensionFile.toUpperCase() as keyof typeof EXTENSION_TYPE,
              files: [file]
            })

            const attachment = {
              file_name: uploadResponse[0].name,
              name: fileName
            }

            await createAttachmentMutation({ attachment, id, type })
              .then((result) => {
                if (result.data.data.id) {
                  if (type === 'stores') {
                    LogEvent('carteira_tabs_arquivos', {
                      item_id: result.data.data.id
                    })
                  } else {
                    LogEvent('carteira_contatos_arquivos', {
                      item_id: result.data.data.id
                    })
                  }

                  toast.show({
                    render: () => <Toast type="success" text="Arquivo adicionado com sucesso 🎉" />,
                    duration: 1500
                  })
                } else {
                  bottomSheetContext.open({
                    title: 'Algo inesperado aconteceu!',
                    description: `Não foi possível adicionar o arquivo. Tente novamente mais tarde.`
                  })
                }
              })
              .catch(() => {
                bottomSheetContext.open({
                  title: 'Algo inesperado aconteceu!',
                  description: `Não foi possível adicionar o arquivo. Tente novamente mais tarde.`
                })
              })
          }
        } else {
          bottomSheetContext.open({
            title: 'Arquivo não compatível!',
            description: `Esta extensão de arquivo nao é suportada.\n Por favor anexe um dos formatos abaixo: \n.CSV, .DOC, .DOCX, .JPEG, .JPG, .PNG, .MP4, .MP3, .PDF, .PPT, .PPTX, .XLS, .XLSX, .XLSM, .ZIP`
          })
        }
      }
    }

    useImperativeHandle(ref, () => ({
      handlePickAttachment
    }))

    const createElementToDownload = (blob: any, name: string) => {
      const newUrlFile = window.URL.createObjectURL(blob)

      const element = document.createElement('a')

      element.setAttribute('href', newUrlFile)

      element.setAttribute('download', name)

      element.style.display = 'none'

      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    }

    const donwloadFile = async (file: { name: string; url: string }) => {
      if (!file) return

      setDisableDownload(true)

      const { url, name } = file

      try {
        const axiosConfig = {
          responseType: 'blob'
        }

        // @ts-ignore
        const { data: blob } = await axios.get(url, axiosConfig)

        createElementToDownload(blob, name)

        setDisableDownload(false)
      } catch (err) {
        setDisableDownload(false)

        console.log(err)
      }
    }

    const handleDownloadAttachment = async (remoteUrl: string) => {
      if (isMobile) {
        try {
          bottomSheetDownloadRef.current?.present()

          setTimeout(async () => {
            await Linking.openURL(remoteUrl)
          }, 1500)
        } catch {
          bottomSheetContext.open({
            title: 'Algo inesperado aconteceu!',
            description: `Não foi possível baixar o arquivo. Tente novamente mais tarde.`
          })
        }
      }
    }

    const handleDeleteAttachment = (attachment: Attachment) => {
      setSelectedItem(attachment)

      bottomSheetConfirmDeleteRef.current?.present()
    }

    const deleteAttachment = () => {
      bottomSheetConfirmDeleteRef.current?.close()

      deleteAttachmentMutation(
        { id, attachmentId: `${itemSelected!.id}`, type },
        {
          onSuccess: () => {
            setSelectedItem(undefined)

            toast.show({
              render: () => <Toast type="success" text="Arquivo removido com sucesso 🎉" />,
              duration: 3000
            })
          },
          onError: () => {
            bottomSheetContext.open({
              title: 'Algo inesperado aconteceu!',
              description: `Não foi possível remover o arquivo. Tente novamente mais tarde.`
            })

            toast.show({
              render: () => <Toast type="error" text="Não foi possível completar a ação" />,
              duration: 3000
            })
          }
        }
      )
    }

    const heightScreen = Dimensions.get('screen').height

    const heightModal = 250

    const percent = (heightModal * 100) / heightScreen

    return (
      <>
        <VStack flex={1} mt={10} mb={6}>
          {attachment?.allAttachments.length > 0 &&
            attachment.allAttachments.map((attachment) => {
              const extension = formatNameExtensionAttachments(attachment.attachment_url)

              const size = formatSizeAttachments(attachment.name)

              const createdDate = format(new Date(attachment.created_at), "dd'/'MM'/'yyyy", {
                locale: ptBR
              })

              const fileName = formatNameAttachments(attachment.name)

              const IconComponent = Icons[selectIcon(extension)]

              return deleteAttachmentIsLoading && itemSelected?.id === attachment.id ? (
                <HStack
                  w="100%"
                  h="70px"
                  p={4}
                  borderWidth="1"
                  space={8}
                  rounded="md"
                  borderColor="coolGray.200"
                  borderStyle="dashed"
                  key={attachment.id}>
                  <HStack space={1} flex={5}>
                    <Box mr={2}>
                      <Skeleton w={8} rounded="sm" />
                    </Box>
                    <VStack space={2} flex={4}>
                      <Skeleton w="50%" h={6} rounded="sm" />
                      <Skeleton w="25%" h={2} rounded="sm" />
                    </VStack>
                    <Center flex={1} alignItems="flex-end">
                      <Skeleton size={6} rounded="full" />
                    </Center>
                  </HStack>
                </HStack>
              ) : (
                <Pressable
                  key={attachment.id}
                  onPress={() =>
                    !isMobile && !disableDownload
                      ? donwloadFile({ name: attachment.file_name, url: attachment.attachment_url })
                      : handleDownloadAttachment(attachment.attachment_url)
                  }>
                  <HStack
                    borderStyle="dashed"
                    backgroundColor="white"
                    borderWidth={1}
                    borderRadius="20px"
                    p={4}
                    mb={2}
                    borderColor="gray.200"
                    height="70px"
                    justifyContent="space-between"
                    alignItems="center"
                    flex={1}>
                    <HStack alignItems="center" paddingRight="10px">
                      <IconComponent />
                    </HStack>
                    <VStack space={2} flexGrow={1}>
                      <Heading
                        isTruncated
                        maxW="75%"
                        fontSize="14px"
                        lineHeight="18px"
                        fontWeight="bold"
                        color="gray.700">
                        {fileName}
                      </Heading>
                      <HStack alignItems="center">
                        <Text
                          mr={2}
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="normal"
                          color="gray.500">
                          .{extension} {size}
                        </Text>
                        <Text
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="normal"
                          color="gray.500">
                          {createdDate}
                        </Text>
                      </HStack>
                    </VStack>

                    {!isMobile && (
                      <IconButton
                        onPress={() =>
                          donwloadFile({
                            name: attachment.file_name,
                            url: attachment.url ?? attachment.attachment_url
                          })
                        }
                        rounded="full"
                        colorScheme="white"
                        variant="ghost"
                        icon={<Icons.Downloading size={5} color="gray.700" />}
                      />
                    )}

                    <Button
                      onPress={() => handleDeleteAttachment(attachment)}
                      variant="ghost"
                      colorScheme="white"
                      rounded="full"
                      px={isMobile ? 0 : 4}
                      p={2}>
                      <Icons.Close size={isMobile ? 3 : 4} color="gray.700" />
                    </Button>
                  </HStack>
                </Pressable>
              )
            })}

          {attachment?.allAttachments.length === 0 && !isLoading && (
            <Center flexGrow={1} mt={-10}>
              <Text fontSize="16px" lineHeight="24px" color="gray.500">
                Nenhum documento ainda
              </Text>
            </Center>
          )}

          {attachment.allAttachmentsIsFetchingNextPage ? (
            <Center flex={1} mx={2} mb={10}>
              <Spinner />
            </Center>
          ) : null}

          {isLoading && (
            <HStack
              w="100%"
              h="70px"
              p={4}
              borderWidth="1"
              space={8}
              rounded="md"
              borderColor="coolGray.200"
              borderStyle="dashed">
              <HStack space={1} flex={5}>
                <Box flex={1}>
                  <Skeleton w={8} rounded="sm" />
                </Box>
                <VStack space={2} flex={4}>
                  <Skeleton w="50%" h={6} rounded="sm" />
                  <Skeleton w="25%" h={2} rounded="sm" />
                </VStack>
                <Center flex={1}>
                  <Skeleton size={6} rounded="full" />
                </Center>
              </HStack>
            </HStack>
          )}
        </VStack>
        <CustomBottomSheet
          handleIndicatorStyle={{ display: 'none' }}
          enableTapToClose={false}
          ref={bottomSheetConfirmDeleteRef}
          snapPoints={[`${percent.toFixed(2)}%`]}>
          <VStack alignItems="center" p={isMobile ? 6 : 0}>
            {!isMobile && <Illustrations.DeleteItem />}

            <Heading
              color="gray.700"
              fontSize="24px"
              lineHeight="28px"
              maxW="80%"
              textAlign="center">
              Deseja mesmo excluir este arquivo?
            </Heading>

            <Text
              color="gray.500"
              fontSize="12px"
              lineHeight="16px"
              mt={4}
              flexWrap="wrap"
              textAlign="center">
              Você está excluindo o arquivo {itemSelected?.name.split('&')[0]}
            </Text>
            <Text color="gray.500" fontSize="12px" lineHeight="16px">
              Esta ação não poderá ser desfeita.
            </Text>

            <HStack w="full" my={6}>
              <Button
                flex={1}
                variant="outline"
                colorScheme="gray"
                mr={2}
                onPress={() => {
                  bottomSheetConfirmDeleteRef.current?.close()
                }}
                borderWidth="1px"
                borderColor="gray.700">
                Cancelar
              </Button>
              <Button flex={1} colorScheme="gray" ml={2} onPress={deleteAttachment}>
                Confirmar
              </Button>
            </HStack>
          </VStack>
        </CustomBottomSheet>

        <CustomBottomSheet
          handleIndicatorStyle={{ display: 'none' }}
          onClose={() => {}}
          ref={bottomSheetDownloadRef}
          snapPoints={['24%', '27%']}>
          <VStack alignItems="center" px={4}>
            <Heading
              color="gray.700"
              fontSize="24px"
              lineHeight="28px"
              maxW="97%"
              textAlign="center">
              O arquivo está sendo baixado.
            </Heading>

            <Text
              color="gray.500"
              fontSize="12px"
              lineHeight="16px"
              mt={4}
              flexWrap="wrap"
              textAlign="center">
              Aguarde enquanto o download é processado.
            </Text>

            <HStack w="full" mt={6}>
              <Button
                flex={1}
                colorScheme="gray"
                onPress={() => {
                  bottomSheetDownloadRef.current?.close()
                }}>
                Ok
              </Button>
            </HStack>
          </VStack>
        </CustomBottomSheet>
      </>
    )
  }
)
