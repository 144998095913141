import { AnswerSurveyItem, Survey, SurveyDetail, TParams } from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const getAllSurveys = (params?: TParams) =>
  client.get<ResponseSuccess<Survey[]>>('v1/surveys', {
    params: { ...params, page: params?.currentPage ?? 1 }
  })

export const getSurvey = async (id: string) =>
  await client.get<ResponseSuccess<SurveyDetail>>(`v1/surveys/${id}`)

export const answerSurvey = async ({ id, questions }: AnswerSurveyItem) =>
  await client.post<ResponseSuccess<Pick<Survey, 'id'>[]>>(`v1/surveys/answers`, {
    survey: { id, questions }
  })
