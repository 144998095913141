import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const EmailIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 16 15', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.35363 1.66758C1.83391 1.66758 1.41259 2.0889 1.41259 2.60862V12.2879C1.41259 12.8076 1.83391 13.2289 2.35363 13.2289H13.6461C14.1658 13.2289 14.5871 12.8076 14.5871 12.2879V2.60862C14.5871 2.0889 14.1658 1.66758 13.6461 1.66758H2.35363ZM0.068252 2.60862C0.068252 1.34644 1.09145 0.323242 2.35363 0.323242H13.6461C14.9083 0.323242 15.9315 1.34644 15.9315 2.60862V12.2879C15.9315 13.55 14.9083 14.5732 13.6461 14.5732H2.35363C1.09145 14.5732 0.068252 13.55 0.068252 12.2879V2.60862Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.226711 2.17513C0.46612 1.89142 0.890196 1.8555 1.17391 2.09491L7.39283 7.34267C7.56261 7.48598 7.77768 7.56464 7.99986 7.56464C8.22203 7.56464 8.43704 7.48603 8.60682 7.34272L14.8258 2.09491C15.1095 1.8555 15.5336 1.89142 15.773 2.17513C16.0124 2.45884 15.9765 2.88292 15.6928 3.12233L9.47392 8.37004C9.4739 8.37006 9.47394 8.37003 9.47392 8.37004C9.0616 8.71803 8.5394 8.90898 7.99986 8.90898C7.46029 8.90898 6.93812 8.71807 6.52579 8.37004L0.306932 3.12233C0.0232175 2.88292 -0.0126989 2.45884 0.226711 2.17513Z"
      fill="currentColor"
    />
  </Icon>
)
