import { FunctionComponent, useState } from 'react'

import { Icons } from 'atoms'
import axios from 'axios'
import * as Linking from 'expo-linking'
import { Attachment } from 'integration/resources/knowledgeBase'
import { Heading, HStack, VStack } from 'native-base'
import { StyleSheet, TouchableOpacity } from 'react-native'
import {
  formatDateStringToMask,
  formatNameAttachments,
  formatNameExtensionAttachments,
  formatSizeAttachments,
  selectIcon
} from 'src/utils'

type AttachmentFilesProps = {
  attachments: Attachment[]
  isDesktop?: boolean
}

export const AttachmentFiles: FunctionComponent<AttachmentFilesProps> = ({
  attachments,
  isDesktop
}) => {
  const [disabledDownload, setDisableDownload] = useState(false)

  if (!attachments.length) return <></>

  const createElementToDownload = (blob: any, name: string) => {
    const newUrlFile = window.URL.createObjectURL(blob)

    const element = document.createElement('a')

    element.setAttribute('href', newUrlFile)

    element.setAttribute('download', name)

    element.style.display = 'none'

    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }

  const donwloadFile = async (file: { name: string; url: string }) => {
    if (!file) return

    setDisableDownload(true)

    const { url, name } = file

    try {
      const axiosConfig = {
        responseType: 'blob'
      }

      // @ts-ignore
      const { data: blob } = await axios.get(url, axiosConfig)

      createElementToDownload(blob, name)

      setDisableDownload(false)
    } catch (err) {
      setDisableDownload(false)

      console.log(err)
    }
  }

  return (
    <VStack mt={4} space={4}>
      {attachments.map(({ fileName, url, uploaded_at, name, created_at }: Attachment) => {
        const extension = formatNameExtensionAttachments(url)

        const size = Number(formatSizeAttachments(name ? name : fileName))

        const IconComponent = Icons[selectIcon(extension)]

        const uploadedAt = formatDateStringToMask(
          (uploaded_at ? uploaded_at : created_at) ?? '',
          "dd'/'MM'/'yyyy"
        )

        const LIMIT_KB = 1000000

        const sizeFile = size ? (size < LIMIT_KB ? size + 'KB' : size + 'MB') : ''

        const content = () => (
          <HStack justifyContent="space-between" alignItems="center" flex={1}>
            <HStack space={2} alignItems="center">
              <IconComponent />
              <VStack space={1} width="85%">
                <Heading
                  fontSize="14px"
                  lineHeight="18px"
                  fontWeight="bold"
                  color="gray.700"
                  isTruncated>
                  {formatNameAttachments(name ? name : fileName)}{' '}
                </Heading>
                <Heading fontSize="12px" lineHeight="16px" fontWeight={400} color="gray.500">
                  .{extension} {sizeFile} {'    '} {uploadedAt}
                </Heading>
              </VStack>
            </HStack>
            <Icons.Downloading size={5} color="gray.700" />
          </HStack>
        )

        return (
          <TouchableOpacity
            style={styles.attachButton}
            disabled={disabledDownload}
            key={fileName}
            onPress={() =>
              isDesktop ? donwloadFile({ name: fileName, url }) : Linking.openURL(url)
            }>
            {content()}
          </TouchableOpacity>
        )
      })}
    </VStack>
  )
}

const styles = StyleSheet.create({
  attachButton: {
    backgroundColor: 'white',
    borderRadius: 20,
    height: 70,
    padding: 16,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: '#C2C2C2'
  }
})
