import { createIcon } from 'native-base'
import { Path, G, Defs, ClipPath, Rect } from 'react-native-svg'

export const CloseTabIcon = createIcon({
  viewBox: '0 0 24 24',
  path: [
    <>
      <G clipPath="url(#clip0_9904_18078)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 12C24 18.6274 18.6274 24 12 24C5.37259 24 0 18.6274 0 12C0 5.37259 5.37259 0 12 0C18.6274 0 24 5.37259 24 12ZM7.66229 7.66229C8.16439 7.16019 8.97847 7.16019 9.48057 7.66229L12 10.1817L14.5194 7.66229C15.0215 7.16019 15.8356 7.16019 16.3377 7.66229C16.8398 8.16439 16.8398 8.97847 16.3377 9.48057L13.8183 12L16.3377 14.5194C16.8398 15.0215 16.8398 15.8356 16.3377 16.3377C15.8356 16.8398 15.0215 16.8398 14.5194 16.3377L12 13.8183L9.48057 16.3377C8.97847 16.8398 8.16439 16.8398 7.66229 16.3377C7.16019 15.8356 7.16019 15.0215 7.66229 14.5194L10.1817 12L7.66229 9.48057C7.16019 8.97847 7.16019 8.16439 7.66229 7.66229Z"
          fill="currentColor"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_9904_18078">
          <Rect width="24" height="24" fill="white" />
        </ClipPath>
      </Defs>
    </>
  ]
})
