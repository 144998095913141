import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ContentByCategoryComponent } from './ContentByCategory.types'
import { useContentByCategoryScreen } from './useContentByCategoryScreen'

const ContentByCategoryScreenDesktop = lazy(() => import('./ContentByCategoryScreen.desktop'))

const ContentByCategoryScreenMobile = lazy(() => import('./ContentByCategoryScreen.mobile'))

export const ContentByCategoryScreen: ContentByCategoryComponent = ({ navigation, route }) => {
  const props = useContentByCategoryScreen({ navigation, route })

  const Screen = props.isMobile ? ContentByCategoryScreenMobile : ContentByCategoryScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
