import { FunctionComponent } from 'react'

import { Heading } from 'native-base'

type TCalendarTitle = {
  title: string
}

export const CalendarTitle: FunctionComponent<TCalendarTitle> = ({ title }) => {
  return (
    <Heading fontSize="lg" textAlign="center" mt={3}>
      {title}
    </Heading>
  )
}
