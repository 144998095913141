import { AxiosResponse } from 'axios'
import { ResponseSuccess } from 'integration/types'
import { ProposalFunnelProps } from 'organisms/TabPanels/constants'
import { getShortMonthAndYear } from 'src/utils'

import {
  ContractsAmountDataSerialized,
  CreditInsuranceDataSerialized,
  Fpd10DataSerialized,
  MarketShareDataSerialized,
  OpenedAccountQuantityDataSerialized,
  Over30M3DataSerialized,
  PotentialDataSerialized,
  ProductionDataSerialized,
  ProposalFunnelSerialized,
  SantanderAutoInsuranceDataSerialized,
  SantanderAutoTypeDataSerialized,
  SpreadPercentageDataSerialized,
  WebmotorsDataSerialized
} from './types'

export const getProposalFunnel: (
  response: AxiosResponse<ResponseSuccess<ProposalFunnelSerialized>>
) => AxiosResponse<ResponseSuccess<ProposalFunnelSerialized>> = (response) => {
  const higherValue = [
    response.data.data.contracts_quantity,
    response.data.data.effective_quantity,
    response.data.data.approved_quantity,
    response.data.data.proposal_quantity,
    response.data.data.simulation_quantity
  ].sort((a, b) => b - a)[0]

  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        higherValue,
        dataSerialized: [
          {
            value: response.data.data.contracts_quantity ?? 0,
            type: ProposalFunnelProps.CONTRACTS_QUANTITY
          },
          {
            value: response.data.data.effective_quantity ?? 0,
            type: ProposalFunnelProps.EFFECTIVE_QUANTITY
          },
          {
            value: response.data.data.approved_quantity ?? 0,
            type: ProposalFunnelProps.APPROVED_QUANTITY
          },
          {
            value: response.data.data.proposal_quantity ?? 0,
            type: ProposalFunnelProps.PROPOSAL_QUANTITY
          },
          {
            value: response.data.data.simulation_quantity ?? 0,
            type: ProposalFunnelProps.SIMULATION_QUANTITY
          }
        ]
      })
    }
  }
}

export const getProduction: (
  response: AxiosResponse<ResponseSuccess<ProductionDataSerialized>>
) => AxiosResponse<ResponseSuccess<ProductionDataSerialized>> = (response) => {
  let higherValue = 0

  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        production: response.data.data.months.map((monthItem) => {
          if (higherValue < Number(monthItem.production)) higherValue = Number(monthItem.production)

          return {
            value: Number(monthItem.production) ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        higherValue
      })
    }
  }
}

export const getPotential: (
  response: AxiosResponse<ResponseSuccess<PotentialDataSerialized>>
) => AxiosResponse<ResponseSuccess<PotentialDataSerialized>> = (response) => {
  let higherValue = 0

  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        potential: response.data.data.months.map((monthItem) => {
          if (higherValue < monthItem.potential) higherValue = Number(monthItem.potential)

          return {
            value: Number(monthItem.potential ?? 0),
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        higherValue
      })
    }
  }
}

export const getMarketShare: (
  response: AxiosResponse<ResponseSuccess<MarketShareDataSerialized>>
) => AxiosResponse<ResponseSuccess<MarketShareDataSerialized>> = (response) => {
  let higherValueShare = 0

  let higherValueVisualization = 0

  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        actual_share: response.data.data.months.map((monthItem) => {
          if (higherValueShare < monthItem.actual_share) higherValueShare = monthItem.actual_share

          return {
            value: monthItem.actual_share ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        market_visualization: response.data.data.months.map((monthItem) => {
          if (higherValueVisualization < monthItem.market_visualization)
            higherValueVisualization = monthItem.market_visualization

          return {
            value: monthItem.market_visualization ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        higherValueShare,
        higherValueVisualization
      })
    }
  }
}

export const getSantanderAutoInsurance: (
  response: AxiosResponse<ResponseSuccess<SantanderAutoInsuranceDataSerialized>>
) => AxiosResponse<ResponseSuccess<SantanderAutoInsuranceDataSerialized>> = (response) => {
  let higherValue = 0

  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        ip_auto_insurance_qty: response.data.data.months.map((monthItem) => {
          if (higherValue < monthItem.ip_auto_insurance_qty)
            higherValue = monthItem.ip_auto_insurance_qty

          return {
            value: monthItem.ip_auto_insurance_qty ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        higherValue
      })
    }
  }
}

export const getOpenedAccountQuantity: (
  response: AxiosResponse<ResponseSuccess<OpenedAccountQuantityDataSerialized>>
) => AxiosResponse<ResponseSuccess<OpenedAccountQuantityDataSerialized>> = (response) => {
  let higherValue = 0

  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        accounts_opened_quantity: response.data.data.months.map((monthItem) => {
          if (higherValue < monthItem.accounts_opened_quantity)
            higherValue = monthItem.accounts_opened_quantity

          return {
            value: monthItem.accounts_opened_quantity ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        higherValue
      })
    }
  }
}

export const getWebmotors: (
  response: AxiosResponse<ResponseSuccess<WebmotorsDataSerialized>>
) => AxiosResponse<ResponseSuccess<WebmotorsDataSerialized>> = (response) => {
  let higherValue = 0

  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        web_realized_value: response.data.data.months.map((monthItem) => {
          if (higherValue < Number(monthItem.web_realized_value))
            higherValue = Number(monthItem.web_realized_value)

          return {
            value: Number(monthItem.web_realized_value) ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        higherValue
      })
    }
  }
}

export const getFpd10: (
  response: AxiosResponse<ResponseSuccess<Fpd10DataSerialized>>
) => AxiosResponse<ResponseSuccess<Fpd10DataSerialized>> = (response) => {
  let higherValue = 0

  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        fpd10_percentage: response.data.data.months.map((monthItem) => {
          if (higherValue < Number(monthItem.fpd10_percentage))
            higherValue = Number(monthItem.fpd10_percentage)

          return {
            value: Number(monthItem.fpd10_percentage) ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        higherValue
      })
    }
  }
}

export const getOver30M3: (
  response: AxiosResponse<ResponseSuccess<Over30M3DataSerialized>>
) => AxiosResponse<ResponseSuccess<Over30M3DataSerialized>> = (response) => {
  let higherValue = 0

  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        over30_m3_calculated: response.data.data.months.map((monthItem) => {
          if (higherValue < Number(monthItem.over30_m3_calculated))
            higherValue = Number(monthItem.over30_m3_calculated)

          return {
            value: Number(monthItem.over30_m3_calculated) ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        higherValue
      })
    }
  }
}

export const getContractsAmount: (
  response: AxiosResponse<ResponseSuccess<ContractsAmountDataSerialized>>
) => AxiosResponse<ResponseSuccess<ContractsAmountDataSerialized>> = (response) => {
  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        contracts_quantity: response.data.data.months.map((monthItem) => {
          return {
            value: monthItem.contracts_quantity ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        insurance_contracts_quantity: response.data.data.months.map((monthItem) => {
          return {
            value: monthItem.insurance_contracts_quantity ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        insurance_auto_contracts_quantity: response.data.data.months.map((monthItem) => {
          return {
            value: monthItem.insurance_auto_contracts_quantity ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        })
      })
    }
  }
}

export const getSpreadPercentage: (
  response: AxiosResponse<ResponseSuccess<SpreadPercentageDataSerialized>>
) => AxiosResponse<ResponseSuccess<SpreadPercentageDataSerialized>> = (response) => {
  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        net_spread: response.data.data.months.map((monthItem) => {
          return {
            value: monthItem.net_spread ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        gross_spread: response.data.data.months.map((monthItem) => {
          return {
            value: monthItem.gross_spread ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        })
      })
    }
  }
}

export const getCreditInsurance: (
  response: AxiosResponse<ResponseSuccess<CreditInsuranceDataSerialized>>
) => AxiosResponse<ResponseSuccess<CreditInsuranceDataSerialized>> = (response) => {
  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        ip_insurance_spf_qty: response.data.data.months.map((monthItem) => {
          return {
            value: monthItem.ip_insurance_spf_qty ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        ip_insurance_spf_volume: response.data.data.months.map((monthItem) => {
          return {
            value: monthItem.ip_insurance_spf_volume ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        })
      })
    }
  }
}

export const getSantanderAutoType: (
  response: AxiosResponse<ResponseSuccess<SantanderAutoTypeDataSerialized>>
) => AxiosResponse<ResponseSuccess<SantanderAutoTypeDataSerialized>> = (response) => {
  return {
    ...response,
    data: {
      ...response.data,
      data: (response.data.data = {
        ...response.data.data,
        complete_insurance: response.data.data.months.map((monthItem) => {
          return {
            value: monthItem.complete_insurance ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        fire_theft_insurance: response.data.data.months.map((monthItem) => {
          return {
            value: Number(monthItem.fire_theft_insurance) ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        third_party_insurance: response.data.data.months.map((monthItem) => {
          return {
            value: monthItem.third_party_insurance ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        }),
        loose_insurance: response.data.data.months.map((monthItem) => {
          return {
            value: monthItem.loose_insurance ?? 0,
            year_month: getShortMonthAndYear(String(monthItem.year_month))
          }
        })
      })
    }
  }
}
