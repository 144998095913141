import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { OpportunityDetailsScreenComponent } from './OpportunityDetailsScreen.types'
import { useOpportunityDetailsScreen } from './useOpportunityDetailsScreen'

const OpportunityDetailsScreenDesktop = lazy(() => import('./OpportunityDetailsScreen.desktop'))

const OpportunityDetailsScreenMobile = lazy(() => import('./OpportunityDetailsScreen.mobile'))

export const OpportunityDetailsScreen: OpportunityDetailsScreenComponent = ({
  navigation,
  route
}) => {
  const props = useOpportunityDetailsScreen({ navigation, route })

  const Screen = props.isMobile ? OpportunityDetailsScreenMobile : OpportunityDetailsScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
