import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ContractListScreenComponent } from './ContractListScreen.types'
import { useContractListScreen } from './useContractListScreen'

const ContractListScreenDesktop = lazy(() => import('./ContractListScreen.desktop'))

const ContractListScreenMobile = lazy(() => import('./ContractListScreen.mobile'))

export const ContractListScreen: ContractListScreenComponent = ({ navigation, route }) => {
  const props = useContractListScreen({ navigation, route })

  const Screen = props.isMobile ? ContractListScreenMobile : ContractListScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
