import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ChartIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 19', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.900024 0.611328C1.31424 0.611328 1.65002 0.947115 1.65002 1.36133V16.8113H17.1C17.5142 16.8113 17.85 17.1471 17.85 17.5613C17.85 17.9755 17.5142 18.3113 17.1 18.3113H0.900024C0.485811 18.3113 0.150024 17.9755 0.150024 17.5613V1.36133C0.150024 0.947115 0.485811 0.611328 0.900024 0.611328Z"
      fill="currentColor"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4469 4.21322C13.6637 4.1978 13.8766 4.27726 14.0304 4.431L17.6304 8.031C17.9232 8.32389 17.9232 8.79877 17.6304 9.09166C17.3375 9.38455 16.8626 9.38455 16.5697 9.09166L13.5812 6.10314L8.70002 12.6113C8.56957 12.7853 8.37006 12.894 8.15319 12.9094C7.93632 12.9249 7.72343 12.8454 7.56969 12.6917L3.96969 9.09166C3.6768 8.79877 3.6768 8.32389 3.96969 8.031C4.26259 7.73811 4.73746 7.73811 5.03035 8.031L8.01888 11.0195L12.9 4.51133C13.0305 4.3374 13.23 4.22863 13.4469 4.21322Z"
      fill="currentColor"
    />
  </Icon>
)
