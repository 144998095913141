import {
  PostFileParams,
  PresignedUrlResponse,
  UploadFilePayload,
  UploadFileResponse
} from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const presignedUrlFile = async (params: PostFileParams) =>
  await client.post<ResponseSuccess<PresignedUrlResponse>>('v1/files/presignedurl', {
    ...params
  })

export const uploadFile = async ({ directory_type, extension_type, files }: UploadFilePayload) => {
  const response = await Promise.all(
    files.map(async (file) => {
      const {
        data: {
          data: { url, file_name }
        }
      } = await presignedUrlFile({
        directory_type,
        extension_type
      })

      const OPTIONS = {
        method: 'PUT',
        headers: {
          'Content-Type': extension_type
        }
      }

      const res = await fetch(url, {
        ...OPTIONS,
        body: file
      })

      return {
        name: file_name,
        status: res.status,
        url
      } as UploadFileResponse
    })
  )

  return response
}
