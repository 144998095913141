import { FunctionComponent, Suspense, lazy } from 'react'

import { Skeleton } from 'native-base'

import { ActiveOffersProps } from './ActiveOffers.types'
import { useActiveOffers } from './useActiveOffers'

const ActiveOffersDesktop = lazy(() => import('./ActiveOffers.desktop'))

const ActiveOffersMobile = lazy(() => import('./ActiveOffers.mobile'))

export const ActiveOffers: FunctionComponent<ActiveOffersProps> = (props) => {
  const activeOffers = useActiveOffers(props)

  const Component = activeOffers.isMobile ? ActiveOffersMobile : ActiveOffersDesktop

  return (
    <Suspense fallback={<Skeleton minHeight="104px" mb={4} borderRadius="20px" />}>
      <Component {...activeOffers} />
    </Suspense>
  )
}
