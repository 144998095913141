import { FunctionComponent } from 'react'

import DateTimePicker, {
  AndroidNativeProps,
  DateTimePickerAndroid
} from '@react-native-community/datetimepicker'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Box, Input, Pressable } from 'native-base'
import { Platform } from 'react-native'

import Icons from './Icons'

type TInputDateTimePickerProps = {
  mode: AndroidNativeProps['mode']
  value: Date
  onChange(value: string): void
}

export const InputDateTimePicker: FunctionComponent<TInputDateTimePickerProps> = ({
  mode,
  value,
  onChange
}) => {
  const handleShowPicker = () => {
    if (Platform.OS === 'android') {
      DateTimePickerAndroid.open({
        value,
        onChange: (event) => {
          if (event.type === 'set') {
            const date = new Date(event.nativeEvent.timestamp as number)

            onChange(date.toISOString())
          }
        },
        mode,
        is24Hour: true
      })
    }
  }

  return (
    <Box position="relative">
      <Pressable bg="transparent" zIndex={99} onPress={handleShowPicker}>
        <Input
          bg="white"
          isDisabled
          _disabled={{
            opacity: 1
          }}
          leftElement={
            mode === 'date' ? (
              <Icons.Calendar
                color="gray.700"
                style={{ position: 'absolute', zIndex: 1 }}
                mt={2}
                ml={6}
                size="18px"
              />
            ) : (
              <Icons.Clock
                color="gray.700"
                style={{ position: 'absolute', zIndex: 1 }}
                mt={2}
                ml={6}
                size="18px"
              />
            )
          }
          pl={12}
          value={format(
            parseISO(value.toISOString()),
            mode === 'time' ? 'HH:mm' : "dd 'de' MMMM yyyy",
            {
              locale: ptBR
            }
          )}
        />
      </Pressable>

      {Platform.OS === 'ios' ? (
        <DateTimePicker
          value={value}
          mode={mode}
          style={{
            position: 'absolute',
            top: 0,
            right: mode === 'time' ? '60%' : '50%',
            left: 0,
            bottom: 0,
            zIndex: 99,
            height: '100%',
            opacity: 0.02
          }}
          onChange={(event) => {
            if (event.type === 'set') {
              const parse = new Date(Number(event.nativeEvent!.timestamp))

              onChange(parse.toISOString())
            }
          }}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}
