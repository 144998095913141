import { FunctionComponent } from 'react'

import { ITheme, useToken } from 'native-base'
import { Leaves } from 'native-base/lib/typescript/theme/base/types'
import { Path, Svg } from 'react-native-svg'
import { resize } from 'src/utils'

type LogoTurboIconProps = {
  color?: Leaves<ITheme['colors']>
  size?: number
  small?: boolean
}

export const LogoTurboIcon: FunctionComponent<LogoTurboIconProps> = ({
  size = 225,
  color = 'white',
  small = false
}) => {
  const { height, width } = resize({ height: 48, size, width: 225 })

  const [colorPick] = useToken('colors', [color])

  return small ? (
    <Svg {...{ viewBox: '0 0 55 48', width, height }}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.89 21.986a10.371 10.371 0 0 0-1.565-4.997L28.829 3.308A10.48 10.48 0 0 1 27.485 0l-.354.572c-2.103 3.386-2.103 7.558 0 10.944l6.796 10.946c2.103 3.386 2.103 7.558 0 10.945l-.355.572a10.467 10.467 0 0 0-1.344-3.308L22.033 14.253a10.478 10.478 0 0 1-1.344-3.308l-.355.572c-2.095 3.375-2.102 7.53-.02 10.91l6.817 10.98c2.102 3.386 2.102 7.558 0 10.945l-.356.572a10.467 10.467 0 0 0-1.343-3.308l-8.496-13.682a10.334 10.334 0 0 1-1.565-5.947C6.277 24.197 0 28.887 0 34.32 0 41.875 12.147 48 27.13 48c14.984 0 27.13-6.125 27.13-13.681 0-5.432-6.276-10.124-15.37-12.333Z"
        fill="#ffffff"
      />
    </Svg>
  ) : (
    <Svg {...{ viewBox: '0 0 225 48', width, height }}>
      <Path
        d="M79.6997 34.603C79.6997 36.2 80.1556 37.3313 81.0675 37.9967C81.9793 38.6622 83.2603 38.9949 84.9103 38.9949C85.6485 38.9949 86.4301 38.884 87.2551 38.6622C88.0802 38.4404 88.7966 38.1742 89.4046 37.8637C89.7085 38.2186 89.969 38.6178 90.1862 39.0614C90.4467 39.5051 90.577 40.0374 90.577 40.6585C90.577 41.8119 89.9908 42.7657 88.8183 43.5199C87.6894 44.2741 85.9525 44.6512 83.6077 44.6512C80.351 44.6512 77.7457 43.9192 75.7917 42.4552C73.8811 40.9469 72.9258 38.4847 72.9258 35.0688V3.99265C73.2297 3.90392 73.664 3.79302 74.2285 3.65993C74.7929 3.52684 75.4009 3.4603 76.0522 3.4603C77.3114 3.4603 78.2233 3.70429 78.7878 4.19228C79.3957 4.68027 79.6997 5.50099 79.6997 6.65442V13.2423H89.7954C89.969 13.5085 90.121 13.8856 90.2513 14.3735C90.425 14.8615 90.5118 15.3717 90.5118 15.9041C90.5118 17.8117 89.7085 18.7655 88.1019 18.7655H79.6997V34.603Z"
        fill={colorPick}
      />
      <Path
        d="M97.2857 12.6434C97.5897 12.5547 98.0239 12.4659 98.5884 12.3772C99.1529 12.2441 99.7608 12.1776 100.412 12.1776C101.671 12.1776 102.583 12.4216 103.148 12.9096C103.756 13.3976 104.06 14.2405 104.06 15.4383V31.8747C104.06 34.4477 104.711 36.2888 106.014 37.3978C107.36 38.4625 109.205 38.9949 111.55 38.9949C113.07 38.9949 114.351 38.8396 115.393 38.5291C116.435 38.2186 117.26 37.8858 117.868 37.5309V12.6434C118.215 12.5547 118.649 12.4659 119.171 12.3772C119.735 12.2441 120.343 12.1776 120.994 12.1776C122.254 12.1776 123.187 12.4216 123.795 12.9096C124.403 13.3976 124.707 14.2405 124.707 15.4383V37.8637C124.707 38.8396 124.555 39.6382 124.251 40.2592C123.947 40.8803 123.339 41.4792 122.427 42.0559C121.298 42.7214 119.8 43.3203 117.933 43.8526C116.109 44.385 113.96 44.6512 111.485 44.6512C107.012 44.6512 103.517 43.653 100.998 41.6567C98.5232 39.616 97.2857 36.3997 97.2857 32.0078V12.6434Z"
        fill={colorPick}
      />
      <Path
        d="M140.443 43.5865C140.139 43.7195 139.705 43.8304 139.14 43.9192C138.619 44.0523 138.033 44.1188 137.381 44.1188C136.122 44.1188 135.189 43.8748 134.581 43.3868C133.973 42.8988 133.669 42.0781 133.669 40.9247V19.0982C133.669 17.9891 133.886 17.1019 134.32 16.4364C134.754 15.7266 135.449 15.0612 136.404 14.4401C137.664 13.6416 139.27 12.9761 141.224 12.4438C143.178 11.9114 145.328 11.6452 147.673 11.6452C151.494 11.6452 153.404 12.7543 153.404 14.9724C153.404 15.5048 153.317 16.015 153.144 16.503C152.97 16.9466 152.775 17.3459 152.558 17.7008C152.123 17.612 151.581 17.5233 150.929 17.4346C150.278 17.3459 149.583 17.3015 148.845 17.3015C147.108 17.3015 145.523 17.5011 144.09 17.9004C142.657 18.2553 141.441 18.7211 140.443 19.2978V43.5865Z"
        fill={colorPick}
      />
      <Path
        d="M173.573 11.6452C175.614 11.6452 177.524 12.0001 179.305 12.7099C181.085 13.3754 182.626 14.3957 183.929 15.771C185.232 17.1019 186.252 18.8098 186.99 20.8949C187.728 22.9356 188.098 25.3312 188.098 28.0817C188.098 30.8765 187.707 33.3165 186.925 35.4015C186.187 37.4866 185.123 39.2167 183.734 40.592C182.344 41.9228 180.672 42.9432 178.718 43.653C176.808 44.3184 174.68 44.6512 172.335 44.6512C169.99 44.6512 167.906 44.3628 166.082 43.7861C164.302 43.2094 162.847 42.5439 161.718 41.7898C160.807 41.1687 160.155 40.5476 159.764 39.9265C159.374 39.3054 159.178 38.5069 159.178 37.5309V0.532351C159.482 0.443626 159.916 0.332719 160.481 0.19963C161.045 0.0665433 161.653 0 162.305 0C163.564 0 164.476 0.243995 165.04 0.731985C165.648 1.21997 165.952 2.04069 165.952 3.19412V13.7081C166.907 13.1757 168.015 12.7099 169.274 12.3107C170.533 11.867 171.966 11.6452 173.573 11.6452ZM172.466 17.2349C171.076 17.2349 169.838 17.4789 168.753 17.9669C167.667 18.4106 166.734 18.9429 165.952 19.564V37.2648C166.56 37.7084 167.407 38.1076 168.492 38.4625C169.578 38.8175 170.837 38.9949 172.27 38.9949C174.919 38.9949 177.068 38.1076 178.718 36.3331C180.368 34.5586 181.193 31.8081 181.193 28.0817C181.193 24.3108 180.368 21.5603 178.718 19.8302C177.112 18.1 175.027 17.2349 172.466 17.2349Z"
        fill={colorPick}
      />
      <Path
        d="M224.74 28.1482C224.74 30.6769 224.371 32.9616 223.632 35.0022C222.894 37.0429 221.852 38.7731 220.506 40.1927C219.203 41.6123 217.597 42.7214 215.686 43.5199C213.776 44.2741 211.648 44.6512 209.303 44.6512C206.958 44.6512 204.831 44.2741 202.92 43.5199C201.009 42.7214 199.381 41.6123 198.035 40.1927C196.732 38.7731 195.712 37.0429 194.974 35.0022C194.236 32.9616 193.866 30.6769 193.866 28.1482C193.866 25.6195 194.236 23.3348 194.974 21.2941C195.712 19.2535 196.754 17.5233 198.1 16.1037C199.446 14.6841 201.075 13.5972 202.985 12.843C204.896 12.0445 207.002 11.6452 209.303 11.6452C211.604 11.6452 213.71 12.0445 215.621 12.843C217.532 13.5972 219.16 14.7063 220.506 16.1702C221.852 17.5898 222.894 19.32 223.632 21.3607C224.371 23.357 224.74 25.6195 224.74 28.1482ZM209.303 17.2349C206.698 17.2349 204.635 18.1887 203.115 20.0963C201.596 22.0039 200.836 24.6879 200.836 28.1482C200.836 31.6085 201.574 34.2924 203.05 36.2C204.527 38.0633 206.611 38.9949 209.303 38.9949C211.995 38.9949 214.08 38.0633 215.556 36.2C217.032 34.2924 217.77 31.6085 217.77 28.1482C217.77 24.7323 217.011 22.0705 215.491 20.1629C214.014 18.2109 211.952 17.2349 209.303 17.2349Z"
        fill={colorPick}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.151 21.9857C39.0701 20.2561 38.5498 18.5403 37.5866 16.9894L29.0912 3.30803C28.4434 2.26445 27.9959 1.14666 27.7471 0L27.3925 0.57182C25.2893 3.95808 25.2893 8.13006 27.3921 11.5163L34.1885 22.4615C36.2913 25.8481 36.2913 30.0204 34.1885 33.4067L33.8335 33.9785C33.585 32.8318 33.1372 31.714 32.4894 30.6708L22.2949 14.2529C21.6474 13.2096 21.1996 12.0915 20.9512 10.9452L20.5962 11.517C18.5006 14.8918 18.4941 19.047 20.5752 22.4272L20.5749 22.4276L27.3925 33.4067C29.495 36.7929 29.495 40.9652 27.3925 44.3518L27.0372 44.9237C26.7887 43.777 26.3413 42.6592 25.6934 41.6156L17.198 27.9342C16.0587 26.0993 15.5379 24.0336 15.6333 21.9874C6.53911 24.1964 0.261719 28.8875 0.261719 34.319C0.261719 41.8748 12.4088 48 27.3925 48C42.3759 48 54.5226 41.8748 54.5226 34.319C54.5226 28.8872 48.2459 24.1951 39.151 21.9857Z"
        fill={colorPick}
      />
    </Svg>
  )
}
