import { FunctionComponent } from 'react'

import { Text, VStack, Heading, Box } from 'native-base'
import { Platform } from 'react-native'

type TTabRegulatoryProps = {
  title: string
  updatedAt?: string
  value: string
  ml?: number
  mr?: number
}

export const TabRegulatory: FunctionComponent<TTabRegulatoryProps> = ({
  title,
  updatedAt,
  value,
  ml,
  mr
}) => {
  const isMobile = Platform.OS !== 'web'

  return (
    <Box width={isMobile ? '1/2' : 'auto'} flex={isMobile ? 0 : 1}>
      <VStack
        {...{ ml, mr }}
        p={isMobile ? 4 : 6}
        mb={4}
        bg="white"
        borderRadius="20px"
        flexBasis={isMobile ? 0 : 'auto'}
        flexGrow={1}
        minH={isMobile ? '124px' : '152px'}
        justifyContent="space-between">
        <Text
          fontSize={isMobile ? '12px' : 16}
          lineHeight="16px"
          color="gray.500"
          fontWeight={isMobile ? 'bold' : 'normal'}
          w="85%"
          mb="12px">
          {title}
        </Text>

        <VStack space={3}>
          <Heading
            color={title === 'Rating Dealer' ? 'tabs-yellow.50' : 'gray.700'}
            fontSize={isMobile ? '16px' : 24}
            textTransform="capitalize">
            {value}
          </Heading>
          {updatedAt && (
            <Text fontSize={isMobile ? '10px' : 14} fontWeight="600" color="gray.200">
              Referência {updatedAt}
            </Text>
          )}
        </VStack>
      </VStack>
    </Box>
  )
}
