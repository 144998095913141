import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { OpenWalletsPeriodScreenComponent } from './OpenWalletsPeriodScreen.types'
import { useOpenWalletsPeriodScreen } from './useOpenWalletsPeriodScreen'

const OpenWalletsPeriodScreenDesktop = lazy(() => import('./OpenWalletsPeriodScreen.desktop'))

export const OpenWalletsPeriodScreen: OpenWalletsPeriodScreenComponent = ({
  navigation,
  route
}) => {
  const props = useOpenWalletsPeriodScreen({ navigation, route })

  const Screen = OpenWalletsPeriodScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
