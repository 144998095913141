import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ResetPasswordSendEmailScreenComponent } from './ResetPasswordSendEmailScreen.types'
import { useResetPasswordSendEmailScreen } from './useResetPasswordSendEmailScreen'

const ResetPasswordSendEmailScreenDesktop = lazy(
  () => import('./ResetPasswordSendEmailScreen.desktop')
)

const ResetPasswordSendEmailScreenMobile = lazy(
  () => import('./ResetPasswordSendEmailScreen.mobile')
)

export const ResetPasswordSendEmailScreen: ResetPasswordSendEmailScreenComponent = ({
  navigation
}) => {
  const props = useResetPasswordSendEmailScreen({ navigation })

  const Screen = props.isMobile
    ? ResetPasswordSendEmailScreenMobile
    : ResetPasswordSendEmailScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
