import { Heading } from 'native-base'
import { formatDateStringToMask } from 'src/utils'

import { ItemProps, UseActiveOffers } from './ActiveOffers.types'

export const useActiveOffers: UseActiveOffers = ({
  opportunityDetails,
  isLoading,
  handleOpenApnef,
  handleOpenLeads,
  isMobile
}) => {
  const blocks: ItemProps[] = [
    {
      type: 0,
      handleGoTo: handleOpenLeads,
      quantity: opportunityDetails?.accelerators?.leads.length ?? 0
    },
    {
      type: 1,
      handleGoTo: handleOpenApnef,
      quantity: opportunityDetails?.accelerators?.apnfes.length ?? 0
    }
  ]

  const sliderDots = []

  const emptyLeads = !opportunityDetails?.accelerators?.leads.length

  const emptyApnef = !opportunityDetails?.accelerators?.apnfes.length

  if (!emptyLeads) {
    sliderDots.push({
      type: 0,
      handleGoTo: handleOpenLeads,
      quantity: opportunityDetails?.accelerators?.leads.length ?? 0
    })
  }

  if (!emptyApnef) {
    sliderDots.push({
      type: 1,
      handleGoTo: handleOpenApnef,
      quantity: opportunityDetails?.accelerators?.apnfes.length ?? 0
    })
  }

  const renderTitle = (title: string) => (
    <Heading fontSize="16px" fontWeight={400} lineHeight="24px" color="gray.700">
      {title}
    </Heading>
  )

  const refDate = `Data de Ref.  ${
    opportunityDetails?.created_opportunity_at
      ? formatDateStringToMask(opportunityDetails?.created_opportunity_at, "dd'/'MM'/'yyyy")
      : 'Sem data'
  }`

  return {
    opportunityDetails,
    isLoading,
    handleOpenApnef,
    handleOpenLeads,
    isMobile,
    blocks,
    sliderDots,
    renderTitle,
    refDate
  }
}
