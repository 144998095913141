import { forwardRef } from 'react'

import Icons from 'atoms/Icons'
import { IInputProps, Input, useDisclose } from 'native-base'
import { StyleSheet, TextInput, TouchableOpacity } from 'react-native'

export const InputPassword = forwardRef<TextInput, IInputProps>((props, ref) => {
  const { isOpen, onToggle } = useDisclose(false)

  return (
    <Input
      // @ts-ignore
      ref={ref}
      autoCapitalize="none"
      placeholder="Senha"
      rightElement={
        <TouchableOpacity style={styles.buttonIcon} onPress={onToggle}>
          <Icons.Eye closed={!isOpen} color="gray.700" size={6} />
        </TouchableOpacity>
      }
      leftElement={<Icons.PadLock color="gray.700" size={6} />}
      type={isOpen ? 'text' : 'password'}
      {...props}
    />
  )
})

const styles = StyleSheet.create({
  buttonIcon: {
    shadowColor: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 24,
    borderRadius: 30,
    outlineWidth: 0,
    height: '100%'
  }
})
