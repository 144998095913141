import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { DashboardDetailScreenComponent } from './DashboardDetailScreen.types'
import { useDashboardDetailScreen } from './useDashboardDetailScreen'

const DashboardDetailScreenDesktop = lazy(() => import('./DashboardDetailScreen.desktop'))

const DashboardDetailScreenMobile = lazy(() => import('./DashboardDetailScreen.mobile'))

export const DashboardDetailScreen: DashboardDetailScreenComponent = ({ navigation, route }) => {
  const props = useDashboardDetailScreen({ navigation, route })

  const Screen = props.isMobile ? DashboardDetailScreenMobile : DashboardDetailScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
