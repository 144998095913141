import { memo } from 'react'

import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

const NoNotificationsIllustration = () => (
  <Svg width={251} height={251} fill="none">
    <G clipPath="url(#a)">
      <Path d="M96.6 164.41v48.33h59.78v-48.33H96.6Z" fill="#F3123C" />
      <Path
        d="M96.6 164.44v48.28H40.15a3.317 3.317 0 0 1-2.36-.97c-.3-.31-.55-.68-.72-1.08-.16-.41-.25-.84-.25-1.27v-41.64c0-.44.09-.87.25-1.27.17-.41.42-.77.72-1.08a3.317 3.317 0 0 1 2.36-.97H96.6ZM216.16 167.76v41.64c0 .43-.09.86-.25 1.27-.17.4-.42.77-.72 1.08a3.317 3.317 0 0 1-2.36.97h-56.45v-48.28h56.45a3.317 3.317 0 0 1 2.36.97c.3.31.55.67.72 1.08.16.4.25.83.25 1.27Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M156.38 164.44H96.6v48.28h59.78v-48.28Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M75.76 88.22c-2.18 0-3.96 1.77-3.96 3.96v41.64c0 .44.08.87.25 1.27.17.4.41.77.72 1.08.31.31.68.55 1.08.72.4.17.84.25 1.27.25h41.96l9.36 18.95 9.36-18.95h41.97c.44 0 .87-.08 1.27-.25.4-.17.77-.41 1.08-.72.31-.31.55-.68.72-1.08.17-.4.25-.84.25-1.27V91.54c0-.44-.08-.87-.25-1.27-.17-.4-.41-.77-.72-1.08-.31-.31-.68-.55-1.08-.72-.4-.17-.84-.25-1.27-.25H75.76Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M146.81 116.72h-40.66v-8.46h40.66v8.46Z" fill="#2E2E2E" />
      <Path
        d="M141.49 198.43a.583.583 0 0 0 .57-.62c0-.11-.05-.21-.11-.29a4.661 4.661 0 0 1-1.04-2.93v-1.73c.02-1.17-.39-2.31-1.16-3.19a4.785 4.785 0 0 0-3-1.6c-.64-.06-1.29 0-1.9.21-.61.2-1.17.53-1.65.96-.48.43-.86.96-1.12 1.55-.26.59-.39 1.23-.39 1.87v1.92c0 1.07-.36 2.1-1.04 2.93-.06.09-.1.19-.11.29 0 .1.01.21.06.3a.583.583 0 0 0 .51.32h10.37l.01.01Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M134.82 200.74c.08.33.28.62.54.83.27.21.6.32.94.32.34 0 .67-.11.94-.32.27-.21.46-.5.54-.83M129.39 193.82h-12.67c.01-1.32.29-2.62.81-3.83.56-1.13 2.93-1.91 5.89-3.01.8-.3.67-2.39.32-2.78-.57-.61-1-1.34-1.26-2.13-.27-.79-.36-1.63-.28-2.46-.05-.53 0-1.06.17-1.57.16-.5.43-.97.78-1.37.35-.4.78-.72 1.26-.94.48-.22 1-.35 1.53-.36.53.01 1.05.13 1.53.36.48.22.91.54 1.26.94.35.4.62.86.78 1.37.16.5.22 1.04.17 1.57.08.83-.01 1.67-.28 2.46s-.7 1.51-1.26 2.13c-.35.39-.49 2.48.31 2.78.96.35 1.85.68 2.65.99M177.32 199.8v-23.14M174.23 180.26l3.09-3.6 3.08 3.6M177.32 187.46h7.2M188.63 187.46h9.26M195.68 185.35c-.53-3.52-2.33-6.12-4.48-6.12-6.97 0-2.29 16.46-9.26 16.46a3.491 3.491 0 0 1-2.71-1.56M58.39 193.82l-1.76 4.54 4.56-1.8c1.87 1.24 4.05 1.91 6.29 1.92 5.99.07 10.89-4.32 10.95-9.79.06-5.46-4.75-9.94-10.74-10-8.62-.08-13.87 8.54-9.31 15.12l.01.01Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M91.33 82.98c1.03 3.86.9 7.94-.39 11.73-1.29 3.78-3.67 7.1-6.84 9.54a20.21 20.21 0 0 1-10.98 4.13c-3.99.26-7.96-.67-11.43-2.67a20.226 20.226 0 0 1-5.09-30.84c2.64-3 6.1-5.16 9.96-6.19 2.56-.69 5.24-.86 7.87-.51s5.17 1.21 7.47 2.54 4.31 3.1 5.93 5.2c1.62 2.11 2.8 4.51 3.49 7.07h.01Z"
        fill="#404040"
      />
      <Path
        d="M91.33 82.98c1.03 3.86.9 7.94-.39 11.73-1.29 3.78-3.67 7.1-6.84 9.54a20.21 20.21 0 0 1-10.98 4.13c-3.99.26-7.96-.67-11.43-2.67a20.226 20.226 0 0 1-5.09-30.84c2.64-3 6.1-5.16 9.96-6.19 2.56-.69 5.24-.86 7.87-.51s5.17 1.21 7.47 2.54 4.31 3.1 5.93 5.2c1.62 2.11 2.8 4.51 3.49 7.07h.01Z"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M71.81 76.21v14.46"
        stroke="#fff"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M72.97 96.63c0 .31-.12.6-.34.82-.22.22-.51.34-.82.34-.31 0-.6-.12-.82-.34a1.15 1.15 0 0 1-.34-.82c0-.31.12-.6.34-.82.22-.22.51-.34.82-.34.31 0 .6.12.82.34.22.22.34.51.34.82Z"
        stroke="#fff"
        strokeWidth={0.94}
      />
      <Path
        d="M240.41 177.97c.14.52.12 1.07-.05 1.58-.17.51-.49.95-.92 1.28-.43.33-.94.52-1.48.55-.54.04-1.07-.09-1.54-.36-.46-.27-.84-.67-1.08-1.15a2.7 2.7 0 0 1-.26-1.56c.07-.53.3-1.03.65-1.44.35-.4.82-.69 1.34-.83.7-.18 1.44-.09 2.06.28.62.36 1.08.95 1.26 1.65h.02ZM211.99 52.38v17.74M220.86 61.26h-17.74M25.97 116.72 9.01 103.87M93.92 57.09l-8.24-19.62M159.04 57.09l8.27-19.62M227.01 116.72l16.96-12.85M6.47 192.44h3.06c.38 0 .75-.07 1.1-.22.35-.14.67-.36.93-.62.27-.27.48-.58.62-.93.14-.35.22-.72.22-1.1v-3.06M12.41 186.5v3.06c0 .38.07.75.22 1.1.14.35.36.67.62.93.27.27.58.48.93.62.35.14.72.22 1.1.22h3.06M18.32 192.44h-3.06a2.868 2.868 0 0 0-2.87 2.87"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.41 198.36v-3.06a2.868 2.868 0 0 0-2.87-2.87H6.47"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(6 37)" d="M0 0h238.44v176.19H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export const NoNotifications = memo(NoNotificationsIllustration)
