import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { PageErrorOccurredScreenComponent } from './PageErrorOccurredScreen.types'
import { usePageErrorOccurredScreen } from './usePageErrorOccurredScreen'

const PageErrorOccurredScreenDesktop = lazy(() => import('./PageErrorOccurredScreen.desktop'))

const PageErrorOccurredScreenMobile = lazy(() => import('./PageErrorOccurredScreen.mobile'))

export const PageErrorOccurredScreen: PageErrorOccurredScreenComponent = ({
  navigation,
  route
}) => {
  const props = usePageErrorOccurredScreen({ navigation, route })

  const Screen = props.isMobile ? PageErrorOccurredScreenMobile : PageErrorOccurredScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
