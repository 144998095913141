import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'

import { ListFiles, LoadingTurbo } from 'atoms'
import { PickAttachmentHandle } from 'atoms/ListFiles/ListFiles'
import { AttachmentList, useGetAttachmentListInfiniteQuery } from 'integration/resources/wallet'
import { ButtonAttach } from 'molecules'
import { Box, Center, VStack, useBreakpointValue } from 'native-base'
import { Dimensions } from 'react-native'
import { CurrentViewTypesRef } from 'src/components/templates/ContactScreenTemplate/ContactScreenTemplate'

import { Documents as DocumentsDesktop } from './desktop'
import { TabCommonProps } from './types'

const attachmentsMergeDataInfiniteQuery = (
  data: ReturnType<typeof useGetAttachmentListInfiniteQuery>['data']
) =>
  data?.pages.reduce<AttachmentList[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const DocumentsMobile = forwardRef<CurrentViewTypesRef, TabCommonProps>(
  ({ contactId }, ref) => {
    const listFilesRef = useRef<PickAttachmentHandle>(null)

    const handlePickAttachment = () => listFilesRef.current?.handlePickAttachment()

    const {
      data: allAttachmentsData,
      isFetchingNextPage: allAttachmentsIsFetchingNextPage,
      hasNextPage: allAttachmentsHasNextPage,
      fetchNextPage: allAttachmentsFetchNextPage,
      isFetching: allAttachmentsisFetching,
      isLoading
    } = useGetAttachmentListInfiniteQuery({
      id: contactId ?? '',
      type: 'contacts'
    })

    const allAttachments = useMemo(
      () => attachmentsMergeDataInfiniteQuery(allAttachmentsData),
      [allAttachmentsData]
    )

    const onScrollEndDragAllAttachmentsList = useCallback(() => {
      if (!allAttachmentsIsFetchingNextPage && allAttachmentsHasNextPage) {
        allAttachmentsFetchNextPage()
      }
    }, [allAttachmentsIsFetchingNextPage, allAttachmentsHasNextPage, allAttachmentsFetchNextPage])

    useImperativeHandle(ref, () => ({
      onScrollEndDragContent: onScrollEndDragAllAttachmentsList
    }))

    const screenHeight = Dimensions.get('screen').height

    return isLoading ? (
      <Center h={360} w="full" position="absolute" zIndex={9}>
        <LoadingTurbo size={100} />
      </Center>
    ) : (
      <>
        <Box flex={1}>
          <ListFiles
            attachment={{
              allAttachmentsIsFetchingNextPage,
              allAttachmentsIsLoading: allAttachmentsisFetching,
              allAttachments
            }}
            type="contacts"
            id={contactId ?? ''}
            ref={listFilesRef}
          />
        </Box>
        <VStack pb={screenHeight > 844 ? 4 : 10}>
          <ButtonAttach title="Adicione um documento" handlePickAttachment={handlePickAttachment} />
        </VStack>
      </>
    )
  }
)

export const Documents = forwardRef<CurrentViewTypesRef, TabCommonProps>((props, ref) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  if (!isMobile) return <DocumentsDesktop {...props} ref={ref} />

  return <DocumentsMobile {...props} ref={ref} />
})
