import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const BookOpenIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Path
      d="M17.4824 1.51406C17.3324 1.18406 17.1224 0.899062 16.8599 0.659062C16.5899 0.419062 16.2824 0.239062 15.9374 0.126562C15.5924 0.0140625 15.2399 -0.0234375 14.8799 0.0140625C11.895 0.329062 9.86246 1.09406 8.84996 2.25656C7.83747 1.09406 5.80498 0.329062 2.81999 0.0140625C2.45999 -0.0234375 2.10749 0.0140625 1.76249 0.126562C1.41749 0.239062 1.11 0.419062 0.839997 0.659062C0.569998 0.899062 0.359998 1.19156 0.217499 1.51406C0.0749997 1.84406 0 2.18906 0 2.54906V11.6316C0 12.2691 0.232499 12.8841 0.659997 13.3566C1.0875 13.8291 1.67999 14.1216 2.31749 14.1816C3.67498 14.3016 8.09997 14.8641 8.09997 16.9491C8.09997 17.3616 8.43747 17.6991 8.84996 17.6991C9.26246 17.6991 9.59996 17.3616 9.59996 16.9491C9.59996 14.8641 14.0249 14.3016 15.3824 14.1816C16.0199 14.1216 16.6049 13.8291 17.0399 13.3566C17.4749 12.8841 17.7074 12.2691 17.6999 11.6316V2.54906C17.6999 2.18906 17.6249 1.84406 17.4824 1.51406ZM8.09997 14.3241C6.89247 13.4766 4.98748 12.9141 2.45249 12.6891C2.18999 12.6666 1.94999 12.5466 1.76999 12.3516C1.58999 12.1566 1.49249 11.9016 1.49999 11.6391V2.54906C1.49999 2.40656 1.52999 2.25656 1.58999 2.12156C1.64999 1.98656 1.73999 1.86656 1.84499 1.76906C1.95749 1.67156 2.08499 1.59656 2.21999 1.55156C2.32499 1.51406 2.42999 1.49906 2.54249 1.49906C2.57999 1.49906 2.61749 1.49906 2.65499 1.49906C4.28998 1.67156 8.09247 2.28656 8.09247 4.22906V14.3166L8.09997 14.3241ZM16.1999 11.6391C16.1999 11.9016 16.1024 12.1566 15.9299 12.3516C15.7499 12.5466 15.5099 12.6666 15.2474 12.6891C12.7124 12.9216 10.8075 13.4766 9.59996 14.3241V4.23656C9.59996 2.29406 13.4024 1.67906 15.0374 1.50656C15.0749 1.50656 15.1124 1.50656 15.1499 1.50656C15.2624 1.50656 15.3674 1.52156 15.4724 1.55906C15.6149 1.60406 15.7424 1.67906 15.8474 1.77656C15.9599 1.87406 16.0424 1.99406 16.1024 2.12906C16.1624 2.26406 16.1924 2.41406 16.1924 2.55656V11.6391H16.1999Z"
      fill="currentColor"
    />
  </Icon>
)
