import { FunctionComponent, PropsWithChildren } from 'react'

import { CustomStatusBar } from 'atoms'
import { Box, Button, Heading, Text, VStack } from 'native-base'

type ErrorLayoutProps = {
  description?: string
  handleActionButton?: () => void
  nameActionButton?: string
  title?: string
}

export const ErrorLayout: FunctionComponent<PropsWithChildren<ErrorLayoutProps>> = ({
  children,
  description,
  handleActionButton,
  nameActionButton,
  title
}) => {
  return (
    <VStack flex={1} justifyContent="center" alignItems="center" space={16} padding={4}>
      <CustomStatusBar />
      <Box>{children}</Box>
      <VStack justifyContent="flex-start" space={8}>
        <VStack space={2} paddingLeft={1} paddingRight={1}>
          <Heading color="gray.700" fontSize="24px" lineHeight="28px" textAlign="center">
            {title}
          </Heading>
          <Text color="gray.500" fontSize="16px" lineHeight="24px" textAlign="center">
            {description}
          </Text>
        </VStack>
        <Button
          background="gray.700"
          onPress={handleActionButton}
          w="full"
          _stack={{ flex: 1, justifyContent: 'center' }}
          variant="solid">
          {nameActionButton}
        </Button>
      </VStack>
    </VStack>
  )
}
