import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

type TAuthCodeProps = {
  slt: string
  user_id: string
  phone_number_cell: string
  email: string
}

const authCodeAtom = atom<TAuthCodeProps | undefined>(undefined)

export const useAuthCodeAtom = () => useAtom(authCodeAtom)

export const useAuthCodeAtomValue = () => useAtomValue(authCodeAtom)

export const useSetAuthCodeAtom = () => useSetAtom(authCodeAtom)
