import { lazy, Suspense } from 'react'

import { Center, Spinner } from 'native-base'

import { ProductionScreenComponent } from './ProductionScreen.types'
import { useProductionScreen } from './useProductionScreen'

const ProductionScreenDesktop = lazy(() => import('./ProductionScreen.desktop'))

const ProductionScreenMobile = lazy(() => import('./ProductionScreen.mobile'))

export const ProductionScreen: ProductionScreenComponent = ({ navigation, route }) => {
  const productionScreen = useProductionScreen({ navigation, route })

  const Screen = productionScreen.isMobile ? ProductionScreenMobile : ProductionScreenDesktop

  return (
    <Suspense
      fallback={
        <Center flex={1}>
          <Spinner />
        </Center>
      }>
      <Screen {...productionScreen} />
    </Suspense>
  )
}
