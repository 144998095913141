import { FunctionComponent, useState } from 'react'

import { BottomTabBarProps } from '@react-navigation/bottom-tabs/src/types'
import { PulseButton, Icons, Illustrations } from 'atoms'
import { Actionsheet, Badge, Box, Center, Heading, HStack, Text, useTheme } from 'native-base'
import { useNotifications } from 'src/hooks/useNotifications'

export const CustomTabBarNavigator: FunctionComponent<BottomTabBarProps> = ({
  state,
  descriptors,
  navigation
}) => {
  const theme = useTheme()

  const routeIndex = navigation.getState().index

  const currentRoute = navigation.getState().routeNames[routeIndex]

  const routes = ['Home', 'Organization', 'Notification', 'MoreModules']

  const [isOpen, setIsOpen] = useState(false)

  const { hasNotifications, amountToRead } = useNotifications()

  const onPressModules = (routeName: string) => {
    setIsOpen(false)

    navigation.navigate(routeName)
  }

  return (
    <>
      <HStack
        w="full"
        bg="white"
        borderTopWidth="0.5px"
        borderTopColor="gray.50"
        py={2}
        position="absolute"
        bottom={0}
        safeAreaBottom>
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key]

          const isFocused = state.index === index

          const focusMoreButton = state.index > 3

          const handleOnOpen = () => {
            setIsOpen(true)
          }

          const onPress = () => {
            navigation.navigate(route.name)
          }

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key
            })
          }

          const switchButton = (routeName: string) => {
            switch (routeName) {
              case 'Home':
                return (
                  <>
                    <Icons.FlameSantander
                      color={isFocused ? theme.colors.primary[500] : theme.colors.gray[700]}
                      size={5}
                      my={2}
                    />
                    <Text
                      color={isFocused ? theme.colors.primary[500] : theme.colors.gray[700]}
                      fontSize="12px"
                      fontWeight={isFocused ? 'bold' : 'normal'}
                      lineHeight="18px">
                      Início
                    </Text>
                  </>
                )

              case 'Organization':
                return (
                  <>
                    <Icons.Calendar
                      color={isFocused ? theme.colors.primary[500] : theme.colors.gray[700]}
                      size={5}
                      my={2}
                    />
                    <Text
                      color={isFocused ? theme.colors.primary[500] : theme.colors.gray[700]}
                      fontSize="12px"
                      fontWeight={isFocused ? 'bold' : 'normal'}
                      lineHeight="18px">
                      Organização
                    </Text>
                  </>
                )

              case 'Notification':
                return (
                  <>
                    <Center position="relative">
                      <Icons.Bell
                        color={isFocused ? theme.colors.primary[500] : theme.colors.gray[700]}
                        size={5}
                        my={2}
                      />
                      {hasNotifications && (
                        <Badge
                          bg="primary.500"
                          borderWidth="2px"
                          borderColor="white"
                          rounded="full"
                          pt={0.8}
                          pb={0.8}
                          pr={1}
                          pl={1}
                          position="absolute"
                          left={2}
                          top={-1}>
                          <Heading fontSize="10px" fontWeight="medium" color="white">
                            {amountToRead}
                          </Heading>
                        </Badge>
                      )}
                    </Center>
                    <Text
                      color={isFocused ? theme.colors.primary[500] : theme.colors.gray[700]}
                      fontSize="12px"
                      fontWeight={isFocused ? 'bold' : 'normal'}
                      lineHeight="18px">
                      Notificação
                    </Text>
                  </>
                )

              case 'MoreModules':
                return (
                  <>
                    <Icons.More
                      color={
                        isFocused || focusMoreButton
                          ? theme.colors.primary[500]
                          : theme.colors.gray[700]
                      }
                      size={5}
                      my={2}
                    />
                    <Text
                      color={
                        isFocused || focusMoreButton
                          ? theme.colors.primary[500]
                          : theme.colors.gray[700]
                      }
                      fontSize="12px"
                      fontWeight={isFocused || focusMoreButton ? 'bold' : 'normal'}
                      lineHeight="18px">
                      Mais
                    </Text>
                  </>
                )
            }
          }

          if (routes.includes(route.name)) {
            return (
              <PulseButton
                key={route.name}
                accessibilityState={isFocused ? { selected: true } : {}}
                accessibilityLabel={options.tabBarAccessibilityLabel}
                testID={options.tabBarTestID}
                bg="white"
                mr={1}
                rounded="none"
                onPress={route.name === 'MoreModules' ? handleOnOpen : onPress}
                onLongPress={onLongPress}
                bgEffectPulse={theme.colors.primary[300]}
                flex={1}>
                <Center>{switchButton(route.name)}</Center>
              </PulseButton>
            )
          }
        })}
      </HStack>

      <Actionsheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Actionsheet.Content _dragIndicator={{ my: '16px', bg: 'gray.400' }} bg="white">
          <Box
            bg="white"
            w="full"
            px={4}
            pb={5}
            justifyContent="center"
            borderBottomWidth="1px"
            borderBottomColor="gray.50">
            <Illustrations.LogoTabBar />
          </Box>
          <Actionsheet.Item
            bg="white"
            pt={5}
            _pressed={{ bg: 'gray.50' }}
            onPress={() => onPressModules('Profile')}
            _stack={{ alignItems: 'center' }}
            leftIcon={
              <Center size="34px" bg="background.base" borderRadius="full">
                <Icons.User
                  size="18px"
                  color={
                    currentRoute === 'Profile' ? theme.colors.primary[500] : theme.colors.gray[700]
                  }
                />
              </Center>
            }>
            Meu perfil
          </Actionsheet.Item>
          <Actionsheet.Item
            bg="white"
            onPress={() => onPressModules('Wallet')}
            _pressed={{ bg: 'gray.50' }}
            _stack={{ alignItems: 'center' }}
            leftIcon={
              <Center size="34px" bg="background.base" borderRadius="full">
                <Icons.Suitcase
                  size="18px"
                  color={
                    currentRoute === 'Wallet' ? theme.colors.primary[500] : theme.colors.gray[700]
                  }
                />
              </Center>
            }>
            Carteira
          </Actionsheet.Item>
          <Actionsheet.Item
            bg="white"
            onPress={() => onPressModules('Chat')}
            _pressed={{ bg: 'gray.50' }}
            _stack={{ alignItems: 'center' }}
            leftIcon={
              <Center size="34px" bg="background.base" borderRadius="full">
                <Icons.Chat
                  size="18px"
                  color={
                    currentRoute === 'Chat' ? theme.colors.primary[500] : theme.colors.gray[700]
                  }
                />
              </Center>
            }>
            Atendimento
          </Actionsheet.Item>
          <Actionsheet.Item
            bg="white"
            onPress={() => onPressModules('HomeContent')}
            _pressed={{ bg: 'gray.50' }}
            _stack={{ alignItems: 'center' }}
            leftIcon={
              <Center size="34px" bg="background.base" borderRadius="full">
                <Icons.BookOpen
                  size="18px"
                  color={
                    currentRoute === 'HomeContent'
                      ? theme.colors.primary[500]
                      : theme.colors.gray[700]
                  }
                />
              </Center>
            }>
            Conteúdos
          </Actionsheet.Item>
          <Actionsheet.Item
            bg="white"
            onPress={() => onPressModules('Survey')}
            _pressed={{ bg: 'gray.50' }}
            _stack={{ alignItems: 'center' }}
            leftIcon={
              <Center size="34px" bg="background.base" borderRadius="full">
                <Icons.Survey
                  size="18px"
                  color={
                    currentRoute === 'Survey' ? theme.colors.primary[500] : theme.colors.gray[700]
                  }
                />
              </Center>
            }>
            Pesquisas
          </Actionsheet.Item>
        </Actionsheet.Content>
      </Actionsheet>
    </>
  )
}
