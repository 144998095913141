import { Illustrations } from 'atoms'
import { Box, HStack, Text } from 'native-base'

export const ContentEmptyState = () => {
  return (
    <Box alignItems="center" background="background.base" px={4} h="500px">
      <HStack justifyContent="center" mt={-10}>
        <Illustrations.ContentEmptyState />
      </HStack>
      <Text fontSize="16px" lineHeight="24px" color="gray.500" mt={10} textAlign="center">
        Ainda não possuímos conteúdos para você.{'\n'}Volte em breve, logo teremos novidades.
      </Text>
    </Box>
  )
}
