import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const SurveyIcon: FunctionComponent<IIconProps & { completed?: boolean }> = ({
  completed = false,
  ...props
}) =>
  completed ? (
    <Icon {...{ viewBox: '0 0 24 24', ...props }}>
      <Path
        d="M15 4H13C13 3.20435 12.6839 2.44129 12.1213 1.87868C11.5587 1.31607 10.7956 1 10 1C9.20435 1 8.44129 1.31607 7.87868 1.87868C7.31607 2.44129 7 3.20435 7 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V17C3 17.5304 3.21071 18.0391 3.58579 18.4142C3.96086 18.7893 4.46957 19 5 19H15C15.5304 19 16.0391 18.7893 16.4142 18.4142C16.7893 18.0391 17 17.5304 17 17V6C17 5.46957 16.7893 4.96086 16.4142 4.58579C16.0391 4.21071 15.5304 4 15 4Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.75 12L9.25 14.5L13.25 8.5"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  ) : (
    <Icon {...{ viewBox: '0 0 19 18', ...props }}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.06049 1.15892C7.70749 0.511919 8.58501 0.148438 9.50001 0.148438C10.415 0.148438 11.2925 0.511918 11.9395 1.15892C12.4067 1.62612 12.7261 2.21352 12.8675 2.84844H14C14.6763 2.84844 15.3249 3.1171 15.8031 3.59531C16.2814 4.07353 16.55 4.72214 16.55 5.39844V15.2984C16.55 15.9747 16.2814 16.6233 15.8031 17.1016C15.3249 17.5798 14.6763 17.8484 14 17.8484H5.00001C4.32371 17.8484 3.67511 17.5798 3.19689 17.1016C2.71867 16.6233 2.45001 15.9747 2.45001 15.2984V5.39844C2.45001 4.72214 2.71867 4.07353 3.19689 3.59531C3.67511 3.1171 4.32371 2.84844 5.00001 2.84844H6.13251C6.27393 2.21352 6.59329 1.62612 7.06049 1.15892ZM9.50001 1.64844C8.98284 1.64844 8.48685 1.85388 8.12115 2.21958C7.75546 2.58528 7.55001 3.08127 7.55001 3.59844C7.55001 4.01265 7.21423 4.34844 6.80001 4.34844H5.00001C4.72153 4.34844 4.45446 4.45906 4.25755 4.65597C4.06064 4.85289 3.95001 5.11996 3.95001 5.39844V15.2984C3.95001 15.5769 4.06064 15.844 4.25755 16.0409C4.45446 16.2378 4.72153 16.3484 5.00001 16.3484H14C14.2785 16.3484 14.5456 16.2378 14.7425 16.0409C14.9394 15.844 15.05 15.5769 15.05 15.2984V5.39844C15.05 5.11996 14.9394 4.85289 14.7425 4.65597C14.5456 4.45906 14.2785 4.34844 14 4.34844H12.2C11.7858 4.34844 11.45 4.01265 11.45 3.59844C11.45 3.08127 11.2446 2.58527 10.8789 2.21958C10.5132 1.85388 10.0172 1.64844 9.50001 1.64844Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.05001 8.09844C6.05001 7.68422 6.3858 7.34844 6.80001 7.34844H12.2C12.6142 7.34844 12.95 7.68422 12.95 8.09844C12.95 8.51265 12.6142 8.84844 12.2 8.84844H6.80001C6.3858 8.84844 6.05001 8.51265 6.05001 8.09844Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.05001 12.5984C6.05001 12.1842 6.3858 11.8484 6.80001 11.8484H9.50001C9.91423 11.8484 10.25 12.1842 10.25 12.5984C10.25 13.0126 9.91423 13.3484 9.50001 13.3484H6.80001C6.3858 13.3484 6.05001 13.0126 6.05001 12.5984Z"
        fill="currentColor"
      />
    </Icon>
  )
