import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { Cards, LoadingTurbo } from 'atoms'
import SkeletonCard from 'atoms/Cards/SkeletonCard'
import { LinearGradient } from 'expo-linear-gradient'
import { ButtonFilter, UnconnectedMessage } from 'molecules'
import { Box, VStack, Text, Heading, HStack, Center, ZStack, useTheme } from 'native-base'
import { useWindowDimensions } from 'react-native'

import { useCharge } from './hook'
import { ListTypesRef, ListTypesView } from '../../../OrganizationScreen.types'

export const Charge = forwardRef<ListTypesRef, ListTypesView>(
  ({ navigation, route, handleSelect, isLoading }, ref) => {
    const { width, height } = useWindowDimensions()

    const theme = useTheme()

    const [search, setSearch] = useState('')

    const {
      opportunitiesList,
      isLoading: localIsLoading,
      isMobile,
      fetchMore,
      isFetchingNextPage,
      refetch,
      refreshing,
      handleGoToDetails,
      isEmpty,
      handleGoToFilters,
      activeFilterCount,
      totalItems
    } = useCharge({ search, navigation, route })

    const hasFilter = !!activeFilterCount && activeFilterCount > 0

    const skeletonArray = new Array(6).fill(0)

    useImperativeHandle(ref, () => ({
      fetchMore,
      refetch,
      refreshing,
      search: (value: string) => {
        setSearch(value)
      },
      clearSearch: () => setSearch('')
    }))

    useEffect(() => {
      navigation.addListener('focus', () => {
        refetch()
      })
    }, [navigation, refetch])

    return (
      <VStack mt={10} mx={{ base: 4, lg: 0 }}>
        <Box zIndex={0}>
          {localIsLoading && (
            <VStack py={4} w={width - 32}>
              {skeletonArray.map((_, i) => (
                <SkeletonCard key={`key-skeleton-${i}`} />
              ))}
            </VStack>
          )}

          {!isMobile && !localIsLoading && (
            <HStack justifyContent="space-between" mb={8}>
              <VStack>
                <Heading fontWeight="bold" lineHeight="38px" fontSize="24px">
                  Cobrança
                </Heading>
                <Text color="gray.400" fontSize="16px" fontWeight="normal">
                  {totalItems} {`Oportunidade${Number(totalItems) === 1 ? '' : 's'}`}
                </Text>
              </VStack>

              <HStack space={6} position="relative">
                {/* TEMPORARILY DISABLED BY CUSTOMER’S DECISION */}
                {/* <DropdownOrderBy navigation={navigation} route={route} /> */}

                <ButtonFilter
                  hasFilter={hasFilter}
                  activeFilterCount={activeFilterCount}
                  onPress={() => handleGoToFilters()}
                />
              </HStack>
            </HStack>
          )}
          {isEmpty && !localIsLoading && <UnconnectedMessage />}

          {!isEmpty && isMobile && (
            <HStack justifyContent="space-between" mb={4}>
              <Heading fontSize="24px" fontWeight={700} lineHeight="28px" color="gray.700">
                Cobrança
              </Heading>

              <Center bgColor="white" px={4} borderRadius="8px">
                <Heading fontSize="12px" fontWeight={700} color="gray.400">
                  {totalItems} {`OPORTUNIDADE${Number(totalItems) > 1 ? 'S' : ''}`}
                </Heading>
              </Center>
            </HStack>
          )}

          {!isEmpty &&
            opportunitiesList?.map((opportunity, index) => (
              <Cards.OpportunitiesList
                handleGoToDetails={handleGoToDetails}
                opportunity={opportunity}
                isMobile={isMobile}
              />
            ))}

          {isFetchingNextPage && (
            <Center mt={6}>
              <LoadingTurbo size={48} />
            </Center>
          )}

          {refreshing && !isLoading && (
            <>
              <LinearGradient
                style={{
                  position: 'absolute',
                  height: 800,
                  zIndex: 4,
                  flex: 1,
                  width
                }}
                // @ts-ignore
                colors={['#ffffff05', theme.colors.background.light, '#ffffff05']}
              />

              <ZStack
                position="absolute"
                w={width - 32}
                h={0}
                alignItems="center"
                justifyContent="center"
                top={height / 5}
                zIndex={8}>
                <Center>
                  <LoadingTurbo size={48} />
                </Center>
              </ZStack>
            </>
          )}
        </Box>
      </VStack>
    )
  }
)
