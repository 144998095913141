import { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Store } from 'integration/resources/store'
import { KEY_CONTACT_LIST_QUERY } from 'integration/resources/wallet'
import { useBreakpointValue } from 'native-base'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'

import { contactFilterFormSchema } from './components/Form/schema'
import { ContactFilterFormFields } from './components/Form/types'
import { UseContactFilterScreen } from './ContactFilterScreen.types'

export const useContactFilterScreen: UseContactFilterScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const queryClient = useQueryClient()

  const { control, formState, reset, handleSubmit, watch, getValues, setValue } =
    useForm<ContactFilterFormFields>({
      resolver: yupResolver(contactFilterFormSchema),
      mode: 'onChange',
      defaultValues: {
        is_favorite: false
      }
    })

  const handleSubmitForm = (values: ContactFilterFormFields) => {
    queryClient.invalidateQueries(KEY_CONTACT_LIST_QUERY).then(() => {
      navigation.navigate('Tab', {
        screen: 'Wallet',
        params: {
          ...values,
          tabId: 1
        }
      })
    })
  }

  const handleGoBack = () => {
    queryClient.invalidateQueries(KEY_CONTACT_LIST_QUERY)

    navigation.navigate('Tab', {
      screen: 'Wallet',
      params: {
        ...getValues(),
        tabId: 1
      }
    })
  }

  const [openStores, setOpenStores] = useState(false)

  const handleSelectStore = (store: Store) => {
    setValue('search', store.name_fantasy ?? '')

    setValue('store_tab_number', store.tab_number ?? '')

    setOpenStores(false)
  }

  const [openJobs, setOpenJobs] = useState(false)

  const handleSelectJob = () => setOpenJobs(false)

  const onChangeSelectJob = (job: string) => setValue('job_title', job ?? '')

  const formFields = watch()

  let dirtySize = 0

  if (formFields.is_favorite) dirtySize++

  if (formFields.search) dirtySize++

  if (formFields.job_title) dirtySize++

  useEffect(() => {
    reset(route.params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResetForm = () => {
    const resetedValues = {
      is_favorite: false,
      store_tab_number: undefined,
      job_title: undefined,
      search: undefined
    }

    navigation.setParams(resetedValues)

    reset(resetedValues)
  }

  return {
    isMobile,
    control,
    dirtySize,
    getValues,
    formState,
    handleSubmit,
    handleGoBack,
    handleSubmitForm,
    handleResetForm,
    openStores,
    setOpenStores,
    handleSelectStore,
    openJobs,
    setOpenJobs,
    handleSelectJob,
    onChangeSelectJob,
    setValue
  }
}
