import { useMemo } from 'react'

import { useGetTabItemQuery } from 'integration/resources/wallet'
import { useBreakpointValue } from 'native-base'

import { UseTabDetailsScreen } from './TabDetailsScreen.types'

export const useTabDetailsScreen: UseTabDetailsScreen = ({ route }) => {
  const { tabNumber } = route.params

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { data, isLoading } = useGetTabItemQuery(tabNumber)

  const tabDetail = useMemo(() => data?.data.data, [data?.data.data])

  return {
    isLoading,
    isMobile,
    tabDetail
  }
}
