import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ConcludeManagementScreenComponent } from './ConcludeManagementScreen.types'
import { useConcludeManagementScreen } from './useConcludeManagementScreen'

const ConcludeManagementScreenDesktop = lazy(() => import('./ConcludeManagementScreen.desktop'))

export const ConcludeManagementScreen: ConcludeManagementScreenComponent = ({
  navigation,
  route
}) => {
  const props = useConcludeManagementScreen({ navigation, route })

  const Screen = ConcludeManagementScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
