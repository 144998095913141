import { forwardRef, useEffect, useState } from 'react'

import { CustomBottomSheet, BottomSheetModal } from 'molecules'
import { VStack, Heading, HStack, Button } from 'native-base'

type TBottomSheetNoteTypes = {
  handleAddedNoteType(method?: string): void
  initialValue?: string
}

const NOTE_TYPES = ['INOVAÇÃO', 'NEGOCIAÇÃO', 'LEMBRETE', 'PLANO DE AÇÃO', 'NOTA']

export const BottomSheetNoteTypes = forwardRef<BottomSheetModal, TBottomSheetNoteTypes>(
  ({ handleAddedNoteType, initialValue }, ref) => {
    const [noteType, setNoteType] = useState<string | undefined>(initialValue)

    useEffect(() => {
      setNoteType(initialValue)
    }, [initialValue])

    const handleAddedPress = () => {
      handleAddedNoteType(noteType ?? initialValue)
    }

    return (
      <CustomBottomSheet ref={ref} snapPoints={['35%']}>
        <VStack p={4} justifyContent="center" pb={10}>
          <VStack alignItems="center">
            <Heading
              maxW="90%"
              fontSize="18px"
              lineHeight="24px"
              textAlign="center"
              color="gray.700">
              Selecione o tipo
            </Heading>
          </VStack>
          <HStack flexWrap="wrap" maxW="full" mt={6}>
            {NOTE_TYPES.map((type) => (
              <Button
                key={type}
                h="24px"
                p={0}
                px={4}
                mb={4}
                mr={3}
                onPress={() => setNoteType(type)}
                _pressed={{
                  bg: 'gray.200'
                }}
                {...(noteType !== type
                  ? {
                      bg: 'gray.200'
                    }
                  : { bg: 'tertiary.800' })}
                _text={{
                  color: 'white',
                  fontSize: '12px',
                  lineHeight: '16px'
                }}>
                {type}
              </Button>
            ))}
          </HStack>

          <HStack mt={4} w="full">
            <Button w="full" colorScheme="gray" isDisabled={!noteType} onPress={handleAddedPress}>
              Adicionar
            </Button>
          </HStack>
        </VStack>
      </CustomBottomSheet>
    )
  }
)
