import { FunctionComponent, memo } from 'react'

import { Actionsheet, Heading, ScrollView, VStack } from 'native-base'

type CustomActionSheet = {
  title: string
  isOpen: boolean
  setIsOpen: (i: boolean) => void
  children?: JSX.Element | JSX.Element[]
  backgroundColor?: string
}

export const ActionSheet: FunctionComponent<CustomActionSheet> = memo(
  ({ title, isOpen, setIsOpen, children, backgroundColor = 'background.base' }) => {
    return (
      <Actionsheet isOpen={isOpen} onClose={() => setIsOpen(false)} p={0} m={0}>
        <Actionsheet.Content p={0} pt={2} m={0} bg={backgroundColor}>
          <Heading
            pt={4}
            pb={6}
            fontSize="18px"
            lineHeight="24px"
            textAlign="center"
            fontWeight={700}>
            {title}
          </Heading>

          <ScrollView w="full" px={4}>
            <VStack space={2}>{children}</VStack>
          </ScrollView>
        </Actionsheet.Content>
      </Actionsheet>
    )
  }
)
