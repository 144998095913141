import { memo } from 'react'

import Svg, { G, Defs, Rect, Path, ClipPath } from 'react-native-svg'

const AcceleratorsEmptyIllustration = () => (
  <Svg width={191} height={151} viewBox="0 0 191 151" fill="none">
    <Path
      d="M67.415 149.892C85.7076 149.892 100.537 135.062 100.537 116.77C100.537 98.4769 85.7076 83.6484 67.415 83.6484C49.1224 83.6484 34.2935 98.4769 34.2935 116.77C34.2935 135.062 49.1224 149.892 67.415 149.892Z"
      fill="#F3123C"
    />
    <Path d="M53.3116 70.1016L37.4314 83.6185L35.522 80.3102L53.3116 70.1016Z" fill="#DBDBDB" />
    <Path
      d="M71.9328 24.6333C73.9479 24.6333 75.5815 22.9998 75.5815 20.9846C75.5815 18.9695 73.9479 17.3359 71.9328 17.3359C69.9178 17.3359 68.2842 18.9695 68.2842 20.9846C68.2842 22.9998 69.9178 24.6333 71.9328 24.6333Z"
      fill="#F3123C"
    />
    <Path
      d="M145.53 41.0652C147.201 41.0652 148.555 39.711 148.555 38.0404C148.555 36.3699 147.201 35.0156 145.53 35.0156C143.86 35.0156 142.505 36.3699 142.505 38.0404C142.505 39.711 143.86 41.0652 145.53 41.0652Z"
      fill="#F3123C"
    />
    <G clipPath="url(#clip0_15062_32665)">
      <Path
        d="M140.352 91.2591L107.912 110.079L101.102 114.029C99.4316 96.9491 85.0316 83.5991 67.5116 83.5991C57.9516 83.5991 49.3216 87.5791 43.1816 93.9591L35.6216 80.8791L43.2016 53.0891L99.3316 20.5391L111.492 41.5091L140.352 91.2591Z"
        fill="#FAFAFA"
      />
      <Path
        d="M162.622 53.4478L147.242 119.278L107.912 110.078L140.352 91.2578L111.492 41.5078L162.622 53.4478Z"
        fill="#FAFAFA"
      />
    </G>
    <Path
      d="M118.969 133.543C119.982 133.543 120.803 132.722 120.803 131.709C120.803 130.696 119.982 129.875 118.969 129.875C117.956 129.875 117.135 130.696 117.135 131.709C117.135 132.722 117.956 133.543 118.969 133.543Z"
      stroke="#737373"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M100.404 113.805L140.256 90.6846L99.2318 19.9609L43.1029 52.5153L35.522 80.3058L43.4054 93.9363"
      stroke="#737373"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M43.1029 52.5156L53.3116 70.0973L35.522 80.3061"
      stroke="#737373"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M53.3116 70.0938L37.4314 83.611L35.522 80.3027L53.3116 70.0938Z"
      stroke="#737373"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M68.9839 45.5417L71.6117 24.6328"
      stroke="#737373"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M71.9333 24.6333C73.9484 24.6333 75.582 22.9998 75.582 20.9846C75.582 18.9695 73.9484 17.3359 71.9333 17.3359C69.9183 17.3359 68.2847 18.9695 68.2847 20.9846C68.2847 22.9998 69.9183 24.6333 71.9333 24.6333Z"
      stroke="#F3123C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M107.834 109.517L147.137 118.705L162.526 52.8776L111.388 40.9297"
      stroke="#737373"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M129.839 51.1363L143.262 40.0391"
      stroke="#737373"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M145.53 41.0574C147.201 41.0574 148.555 39.7032 148.555 38.0326C148.555 36.3621 147.201 35.0078 145.53 35.0078C143.86 35.0078 142.505 36.3621 142.505 38.0326C142.505 39.7032 143.86 41.0574 145.53 41.0574Z"
      stroke="#F3123C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M67.415 149.892C85.7076 149.892 100.537 135.062 100.537 116.77C100.537 98.4769 85.7076 83.6484 67.415 83.6484C49.1224 83.6484 34.2935 98.4769 34.2935 116.77C34.2935 135.062 49.1224 149.892 67.415 149.892Z"
      stroke="#737373"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M71.8007 100.344L63.0288 133.201"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M83.8432 121.163L50.9863 112.391"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M108.401 1.1875L115.282 13.0409"
      stroke="#737373"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M117.759 3.66406L105.905 10.5644"
      stroke="#737373"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16.5038 79.8132L1.09619 76.2969"
      stroke="#737373"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M21.8917 97.5312L11.1348 109.12"
      stroke="#737373"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M170.561 88.1328L182.036 99.041"
      stroke="#737373"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M174.984 70.1524L190.146 65.6719"
      stroke="#737373"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Defs>
      <ClipPath id="clip0_15062_32665">
        <Rect width={127} height={98.74} fill="white" transform="translate(35.6216 20.5391)" />
      </ClipPath>
    </Defs>
  </Svg>
)

export const AcceleratorsEmpty = memo(AcceleratorsEmptyIllustration)
