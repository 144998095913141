import { useMemo } from 'react'

import { ActivitiesType, useGetActivityTypesInfiniteQuery } from 'integration/resources/activities'

import { UseBottomSheetActivities } from './types'

const mergeDataInfiniteQuery = (
  data: ReturnType<typeof useGetActivityTypesInfiniteQuery>['data']
) =>
  data?.pages.reduce<ActivitiesType[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const useBottomSheetActivities: UseBottomSheetActivities = () => {
  const { data, isLoading } = useGetActivityTypesInfiniteQuery({})

  const activities = useMemo(() => mergeDataInfiniteQuery(data), [data])

  return {
    isLoading,
    activities
  }
}
