import { FunctionComponent } from 'react'

import { PolicyOrContractInfo } from 'integration/resources/newOpportunities'
import { VStack, Text, HStack, Box } from 'native-base'
import { formatDateStringToMask } from 'src/utils'
import { changeToCurrency } from 'src/utils/currencyFormat'

type PoliciesCardProps = {
  info?: PolicyOrContractInfo
  title: string
  isMobile?: boolean
}

const calculatePercentage = (value: number, goal: number) => {
  return Math.min((value / goal) * 100, 100)
}

const getValueOrDefault = (value: number | undefined, defaultValue: number) => {
  return value ?? defaultValue
}

export const PoliciesCard: FunctionComponent<PoliciesCardProps> = ({ info, title, isMobile }) => {
  const quantity = getValueOrDefault(info?.quantity, 0)

  const goal = getValueOrDefault(info?.goal, 0)

  const average = getValueOrDefault(info?.average, 0)

  const value = getValueOrDefault(info?.value, 0)

  const isGreaterEqual = quantity >= average

  const achievedPercentage = calculatePercentage(quantity, goal)

  const averagePercentage = calculatePercentage(average, goal)

  return isMobile ? (
    <VStack>
      <HStack justifyContent="space-between">
        <Text fontSize="14px" fontWeight={700} lineHeight="16px" color="gray.700">
          {title}
        </Text>

        <Text fontSize="14px" fontWeight={700} lineHeight="16px" color="gray.700">
          <Text color={isGreaterEqual ? 'secondary.500' : 'error.500'}>{quantity}</Text>/{goal}
        </Text>
      </HStack>

      <Box position="relative" h="50px" mt={2}>
        <Box position="absolute" top={0} borderRadius="20px" h="30px" w="100%" bgColor="gray.100" />

        <Box
          top={0}
          position="absolute"
          borderRadius="20px"
          h="30px"
          w={`${achievedPercentage}%`}
          bgColor={isGreaterEqual ? 'secondary.500' : 'error.500'}
        />

        <VStack top={0} position="absolute" space={1} w={`${averagePercentage}%`} h="100%">
          <Box h="30px" borderRightWidth="4px" borderColor="white" mr={1} bgColor="transparent" />
          <Text
            fontSize="16px"
            fontWeight={700}
            lineHeight="18px"
            color="gray.700"
            textAlign="right">
            M
          </Text>
        </VStack>
      </Box>

      <HStack space={1} alignItems="center">
        <Text fontSize="12px" fontWeight={400} lineHeight="16px" color="gray.700">
          Média:
        </Text>
        <Text fontSize="14px" fontWeight={700} lineHeight="16px" color="gray.700">
          {average}
        </Text>
        <Text fontSize="12px" fontWeight={400} lineHeight="16px" color="gray.400">
          ({changeToCurrency(value)})
        </Text>
      </HStack>
    </VStack>
  ) : (
    <VStack p={6} space={6} bgColor="white" borderRadius="20px" flex={1} minW="294px">
      <VStack>
        <HStack justifyContent="space-between">
          <Text fontSize="16px" fontWeight={700} lineHeight="24px" color="gray.700">
            {title}
          </Text>

          <Text fontSize="16px" fontWeight={700} lineHeight="24px" color="gray.700">
            <Text color={isGreaterEqual ? 'secondary.500' : 'error.500'}>{quantity}</Text>/{goal}
          </Text>
        </HStack>

        <Box position="relative" h="50px" mt={4}>
          <Box
            position="absolute"
            top={0}
            borderRadius="20px"
            h="30px"
            w="100%"
            bgColor="gray.100"
          />

          <Box
            top={0}
            position="absolute"
            borderRadius="20px"
            h="30px"
            w={`${achievedPercentage}%`}
            bgColor={isGreaterEqual ? 'secondary.500' : 'error.500'}
          />

          <VStack top={0} position="absolute" space={1} w={`${averagePercentage}%`} h="100%">
            <Box h="30px" borderRightWidth="4px" borderColor="white" mr={1} bgColor="transparent" />
            <Text
              fontSize="14px"
              fontWeight={700}
              lineHeight="16px"
              color="gray.700"
              textAlign="right">
              M
            </Text>
          </VStack>
        </Box>

        <HStack space={1}>
          <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.700">
            Média: {average}
          </Text>
          <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.400">
            ({changeToCurrency(value)})
          </Text>
        </HStack>
      </VStack>

      <Text fontSize="14px" fontWeight={600} lineHeight="20px" color="gray.200">
        Data de Ref.{' '}
        {info?.updated_at ? formatDateStringToMask(info.updated_at, "dd'/'MM'/'yyyy") : 'Sem data'}
      </Text>
    </VStack>
  )
}
