import { useCallback, useRef } from 'react'

import { Illustrations } from 'atoms'
import { USER_STATUS } from 'integration/resources/auth'
import { useOnboardingMutation } from 'integration/resources/onboarding'
import { AnimatedLottieViewProps } from 'lottie-react-native'
import { useToast, useBreakpointValue } from 'native-base'
import { RootStackScreenComponentProps } from 'navigation/index'
import { Toast } from 'organisms'
import { Dimensions } from 'react-native'
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue
} from 'react-native-reanimated'
import { useQueryClient } from 'react-query'
import { useAuthAtom } from 'src/store/auth'

import { UseOnboardingScreen } from './OnboardingScreen.types'

export type Slide = {
  id: number
  description?: string
  markedWord?: string
  markedWordMiddle?: string
  middleDescription?: string
  lastDescription?: string
  illustration: keyof typeof Illustrations
  animation: Pick<AnimatedLottieViewProps, 'source'>
  heightAnimation: number
  title: string
}

const { width: SCREEN_WIDTH } = Dimensions.get('window')

export const SLIDE_WIDTH = SCREEN_WIDTH

export const slides: Slide[] = [
  {
    id: 1,
    title: 'Um motor pronto para \nacelerar o seu crescimento.',
    description: 'Deixe o nosso Motor de Inteligência Comercial ',
    markedWord: '',
    lastDescription:
      'trabalhar pra você e receba oportunidades de negócio todo santo dia e cresça seus ganhos!',
    illustration: 'SuccessfulBusiness',
    animation: require('assets/onboarding_1.json'),
    heightAnimation: 350
  },
  {
    id: 2,
    title: 'Acompanhamento eficiente \ncom foco em resultados.',
    description:
      'Aumente a sua eficiência operacional, economize tempo e turbine seu crescimento com oportunidades e tarefas automatizadas.',
    markedWord: '',
    lastDescription: '',
    illustration: 'Contact',
    animation: require('assets/onboarding_2.json'),
    heightAnimation: 270
  },
  {
    id: 3,
    title: 'Visão analítica e seus \nganhos em tempo real.',
    description: 'Com o Simulador de Ganhos ',
    markedWord: '',
    middleDescription:
      'você calcula os resultados e acelera suas vendas na hora! Utilize nossos painéis analíticos ',
    markedWordMiddle: '',
    lastDescription: 'para medir a saúde da sua carteira.',
    illustration: 'WorkLifeBalance',
    animation: require('assets/onboarding_3.json'),
    heightAnimation: 320
  },
  {
    id: 4,
    title: 'Cursos e conteúdos para \nturbinar os negócios.',
    description:
      'Acesse nossos cursos e materiais de apoio cuidadosamente preparados para ajudar no seu crescimento profissional e aprendizagem.',
    illustration: 'OnlineLearning',
    animation: require('assets/onboarding_4.json'),
    heightAnimation: 300
  }
]

export type OnboardingProps = RootStackScreenComponentProps<'Onboarding'>

export type UseOnboardingPropsDetails = ({
  navigation,
  route
}: Pick<OnboardingProps, 'navigation' | 'route'>) => {
  finishOnboarding: () => void
  isLoading: boolean
}

export const useOnboardingScreen: UseOnboardingScreen = ({ navigation, route }) => {
  const queryClient = useQueryClient()

  const toast = useToast()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [authAtom, setAuthAtom] = useAuthAtom()

  const { mutate, isLoading } = useOnboardingMutation()

  const onExit = useCallback(() => {
    Promise.all([setAuthAtom(null)]).then(() => queryClient.invalidateQueries())
  }, [queryClient, setAuthAtom])

  const finishOnboarding = useCallback(() => {
    /**
     * NOTE: RootStackNavigator is listening for authAtom.
     * Once authAtom change its value, the RootStackNavigator will handle the transition to the Home screen.
     */

    mutate(undefined, {
      onSuccess: () =>
        setAuthAtom({
          ...authAtom!,
          user: { ...authAtom!.user, status: USER_STATUS.ACTIVE },
          waiting_onboarding: false
        }),
      onError: () => {
        onExit()

        toast.show({
          render: () => <Toast type="error" text="Ocorreu um erro, tente novamente." />,
          duration: 5000
        })
      }
    })
  }, [authAtom, mutate, onExit, setAuthAtom, toast])

  const refScrollView = useRef<Animated.ScrollView>(null)

  const scrollX = useSharedValue(0)

  const pagingScrollX = useSharedValue(0)

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event) => {
      scrollX.value = event.contentOffset.x
    },
    onEndDrag: (event) => {
      pagingScrollX.value = Math.floor(event.contentOffset.x)
    },
    onMomentumEnd: (event) => {
      pagingScrollX.value = Math.floor(event.contentOffset.x)
    }
  })

  const next = useCallback(() => {
    const x = scrollX.value + SLIDE_WIDTH

    const nextIndex =
      (Math.floor(scrollX.value) + Math.floor(SLIDE_WIDTH)) / Math.floor(SLIDE_WIDTH)

    const slide = slides[nextIndex]

    if (!slide) {
      finishOnboarding()

      return
    }

    refScrollView.current?.scrollTo({
      animated: true,
      x
    })
  }, [finishOnboarding, scrollX.value])

  const previous = useCallback(() => {
    const x = scrollX.value - SLIDE_WIDTH

    refScrollView.current?.scrollTo({
      animated: true,
      x
    })
  }, [scrollX.value])

  const animatedIconButton = useAnimatedStyle(() => {
    const opacity = interpolate(scrollX.value, [0, SLIDE_WIDTH], [0, 1], Extrapolation.CLAMP)

    return {
      opacity
    }
  })

  return {
    finishOnboarding,
    isLoading,
    isMobile,
    pagingScrollX,
    scrollHandler,
    refScrollView,
    scrollX,
    next,
    previous,
    animatedIconButton
  }
}
