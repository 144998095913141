import { FunctionComponent, memo } from 'react'

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import Icons from 'atoms/Icons'
import { Center, HStack, Text } from 'native-base'
import { RootStackParamList } from 'navigation/types'

type BreadcrumbProps = {
  pages: {
    id: number
    title: string
    link: any
    params: any
  }[]
}

export const Breadcrumb: FunctionComponent<BreadcrumbProps> = memo(({ pages }) => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  return (
    <HStack>
      {pages.map((item, index) => (
        <Center flexDirection="row" key={item.id}>
          <Text
            fontSize="14px"
            lineHeight="20px"
            letterSpacing={0.1}
            fontWeight={600}
            color={index !== pages.length - 1 ? 'gray.400' : 'gray.700'}
            onPress={() =>
              index !== pages.length - 1 && navigation.navigate(item.link, item.params)
            }>
            {item.title}
          </Text>

          {index !== pages.length - 1 && (
            <Icons.AccordionArrow direction="right" color="gray.400" ml={1} mt={1} size={5} />
          )}
        </Center>
      ))}
    </HStack>
  )
})
