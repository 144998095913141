import { createIcon } from 'native-base'
import { Path } from 'react-native-svg'

export const GearIcon = createIcon({
  viewBox: '0 0 18 18',
  path: [
    <Path
      key={Math.random()}
      d="M10.603 6.345c-1.162-.78-2.805-.615-3.787.375-.57.57-.878 1.32-.878 2.122a2.995 2.995 0 0 0 1.845 2.768c.367.15.757.232 1.147.232.196 0 .39-.022.585-.06a2.962 2.962 0 0 0 1.538-.817c.42-.42.705-.953.825-1.537a2.977 2.977 0 0 0-.172-1.733 2.967 2.967 0 0 0-1.103-1.343v-.007Zm-.195 2.79c-.06.293-.202.555-.412.765a1.503 1.503 0 0 1-1.635.33 1.482 1.482 0 0 1-.676-.555 1.492 1.492 0 0 1-.254-.833c0-.397.157-.78.442-1.057a1.52 1.52 0 0 1 1.058-.442c.3 0 .585.09.832.255.248.165.435.397.555.675.113.277.143.577.082.87l.008-.008Z"
      fill="currentColor"
    />,
    <Path
      key={Math.random()}
      d="m16.67 10.582-.765-.96v-1.56l.766-.96a2.001 2.001 0 0 0 .165-2.242l-.51-.878a1.989 1.989 0 0 0-.856-.795 2.055 2.055 0 0 0-1.162-.18l-1.215.188-1.342-.78-.443-1.147A1.991 1.991 0 0 0 9.448 0h-1.02c-.405 0-.788.12-1.125.345a1.931 1.931 0 0 0-.735.923l-.442 1.14-1.358.78L3.553 3c-.397-.06-.802 0-1.163.18-.36.172-.66.45-.862.795l-.51.877c-.195.353-.285.75-.262 1.155.03.405.18.78.427 1.096l.765.96v1.56l-.765.96a1.967 1.967 0 0 0-.427 1.095c-.03.404.06.802.27 1.154l.517.87c.203.345.495.623.863.796.36.172.764.232 1.162.18l1.215-.188 1.342.78.443 1.148c.15.375.397.69.735.922.338.225.743.315 1.125.345H9.44c.405 0 .788-.12 1.126-.345.33-.225.584-.547.734-.922l.443-1.148 1.342-.78 1.215.188c.398.06.795 0 1.163-.18.36-.173.66-.45.863-.795l.51-.878a2.001 2.001 0 0 0-.165-2.242Zm-2.1-.232.93 1.162a.5.5 0 0 1 .105.27.551.551 0 0 1-.067.286l-.51.877a.543.543 0 0 1-.21.203c-.09.045-.188.06-.285.045l-1.477-.226a.687.687 0 0 0-.488.09l-1.8 1.043a.718.718 0 0 0-.323.375l-.54 1.388a.544.544 0 0 1-.18.232.464.464 0 0 1-.277.082h-1.02a.522.522 0 0 1-.278-.082.524.524 0 0 1-.18-.225l-.54-1.387a.718.718 0 0 0-.322-.376l-1.8-1.042a.737.737 0 0 0-.375-.098c-.037 0-.075 0-.112.008l-1.47.225a.49.49 0 0 1-.502-.255l-.518-.87a.487.487 0 0 1 .037-.555l.93-1.162a.765.765 0 0 0 .165-.466V7.809a.733.733 0 0 0-.165-.465l-.93-1.17a.487.487 0 0 1-.038-.555l.51-.878a.46.46 0 0 1 .218-.195c.09-.045.188-.06.285-.045l1.478.225c.165.022.337 0 .487-.09l1.815-1.042a.718.718 0 0 0 .322-.376l.54-1.387a.474.474 0 0 1 .188-.233.59.59 0 0 1 .285-.082H9.47c.098 0 .195.03.278.09a.484.484 0 0 1 .18.225l.54 1.387c.06.158.172.293.322.375l1.8 1.043c.15.09.323.12.488.09l1.478-.225a.47.47 0 0 1 .285.045c.09.045.165.112.21.195l.51.877a.474.474 0 0 1 .067.285.449.449 0 0 1-.105.27l-.93 1.17a.765.765 0 0 0-.165.466v2.085c0 .172.06.337.165.464l-.023-.007Z"
      fill="currentColor"
    />
  ]
})
