import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const SuitcaseIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.59984 5.40088V5.25C3.59984 3.85761 4.15296 2.52226 5.13752 1.53769C6.12208 0.553123 7.45744 0 8.84982 0C10.2422 0 11.5775 0.553123 12.5621 1.53769C13.5467 2.52226 14.0998 3.85761 14.0998 5.25V5.40088H16.0499C16.9612 5.40088 17.6999 6.13961 17.6999 7.05088V16.0509C17.6999 16.9622 16.9612 17.7009 16.0499 17.7009H1.64999C0.738727 17.7009 0 16.9622 0 16.0509V7.05088C0 6.13961 0.738727 5.40088 1.64999 5.40088H3.59984ZM6.19818 2.59835C6.90144 1.89509 7.85526 1.5 8.84982 1.5C9.84437 1.5 10.7982 1.89509 11.5015 2.59835C12.2047 3.30161 12.5998 4.25544 12.5998 5.25V5.40088H5.09983V5.25C5.09983 4.25544 5.49492 3.30161 6.19818 2.59835ZM16.1999 7.05088V9.90088H1.49999V7.05088C1.49999 6.96804 1.56715 6.90088 1.64999 6.90088H16.0499C16.1328 6.90088 16.1999 6.96804 16.1999 7.05088ZM8.09982 11.4009V13.3509C8.09982 13.7651 8.4356 14.1009 8.84982 14.1009C9.26403 14.1009 9.59981 13.7651 9.59981 13.3509V11.4009H16.1999V16.0509C16.1999 16.1337 16.1328 16.2009 16.0499 16.2009H1.64999C1.56715 16.2009 1.49999 16.1337 1.49999 16.0509V11.4009H8.09982Z"
      fill="currentColor"
    />
  </Icon>
)
