import { FunctionComponent } from 'react'

import { OPPORTUNITY_BLOCK_TYPE_ENUM } from 'integration/resources/opportunities'
import { VStack, Heading, Text, Box, Divider, HStack } from 'native-base'
import { useWindowDimensions } from 'react-native'

type OpportunityCardProps = {
  title: number
  subtitle: string
  description?: string | number
  action?: string | number
  offer?: string | number
  isGoodsAndServices: boolean
}

export const selectDescRV = (rv: string) => {
  switch (rv) {
    case '0':
      return ''

    case '100':
      return 'Meta'

    default:
      return 'Simulado'
  }
}

export const OpportunityCard: FunctionComponent<OpportunityCardProps> = ({
  title,
  subtitle,
  description,
  action,
  offer,
  isGoodsAndServices
}) => {
  const { width: SCREEN_WIDTH } = useWindowDimensions()

  const widthCard = Math.floor((SCREEN_WIDTH - 48) / 2)

  return (
    <VStack w={`${widthCard}px`} py="14px" px={4} bg="white" borderRadius={20}>
      <Box>
        <Heading
          fontSize="24px"
          lineHeight="28px"
          fontWeight="bold"
          color="gray.700"
          numberOfLines={1}>
          +{title}
        </Heading>
        <Text
          fontSize="14px"
          lineHeight="18px"
          fontWeight="normal"
          mt={1}
          numberOfLines={1}
          textTransform="capitalize"
          color="gray.500">
          {/* @ts-ignore */}
          {OPPORTUNITY_BLOCK_TYPE_ENUM[subtitle] ?? subtitle}
        </Text>

        {!isGoodsAndServices && (
          <>
            <Divider my={3} bg="gray.50" />

            <VStack space={1} alignItems="center" w="100%" mb={3}>
              <HStack justifyContent="space-between" alignItems="center" w="100%">
                <Text fontSize="12px" fontWeight={700} lineHeight="16px" color="gray.700">
                  Com ação
                </Text>
                <Text fontSize="12px" fontWeight={400} lineHeight="16px" color="gray.500">
                  {action ?? 0}
                </Text>
              </HStack>
              <HStack justifyContent="space-between" alignItems="center" w="100%">
                <Text fontSize="12px" fontWeight={700} lineHeight="16px" color="gray.700">
                  Com Oferta
                </Text>
                <Text fontSize="12px" fontWeight={400} lineHeight="16px" color="gray.500">
                  {offer ?? 0}
                </Text>
              </HStack>
            </VStack>

            <Text
              fontSize="10px"
              lineHeight="22px"
              fontWeight="normal"
              color="gray.200"
              letterSpacing={0.3}
              numberOfLines={1}>
              {selectDescRV(String(description))} {description}% RV
            </Text>
          </>
        )}
      </Box>
    </VStack>
  )
}
