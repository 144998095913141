import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ChartScreenComponent } from './ChartScreen.types'
import { useChartScreen } from './useChartScreen'

const ChartScreenDesktop = lazy(() => import('./ChartScreen.desktop'))

const ChartScreenMobile = lazy(() => import('./ChartScreen.mobile'))

export const ChartScreen: ChartScreenComponent = ({ navigation, route }) => {
  const chartScreen = useChartScreen({ navigation, route })

  const Screen = chartScreen.isMobile ? ChartScreenMobile : ChartScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...chartScreen} />
    </Suspense>
  )
}
