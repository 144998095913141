import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path } from 'react-native-svg'

export const CalendarPeriodIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <G fill="currentColor" clipPath="url(#a)">
      <Path
        fillRule="evenodd"
        d="M.897 2.547A2.55 2.55 0 0 1 2.7 1.8h12.6a2.55 2.55 0 0 1 2.55 2.55v10.8a2.55 2.55 0 0 1-2.55 2.55.75.75 0 0 1 0-1.5 1.05 1.05 0 0 0 1.05-1.05V4.35A1.05 1.05 0 0 0 15.3 3.3H2.7a1.05 1.05 0 0 0-1.05 1.05v10.8A1.05 1.05 0 0 0 2.7 16.2a.75.75 0 0 1 0 1.5 2.55 2.55 0 0 1-2.55-2.55V4.35c0-.676.269-1.325.747-1.803Z"
        clipRule="evenodd"
      />
      <Path
        fillRule="evenodd"
        d="M4.5 0a.75.75 0 0 1 .75.75v1.8a.75.75 0 1 1-1.5 0V.75A.75.75 0 0 1 4.5 0ZM13.5 0a.75.75 0 0 1 .75.75v1.8a.75.75 0 1 1-1.5 0V.75A.75.75 0 0 1 13.5 0ZM.15 7.05A.75.75 0 0 1 .9 6.3h16.2a.75.75 0 0 1 0 1.5H.9a.75.75 0 0 1-.75-.75Z"
        clipRule="evenodd"
      />
      <Path d="M6.49 16.5c-.842 0-1.505-.273-1.99-.818l.573-.791c.412.4.845.6 1.3.6.297 0 .533-.064.709-.191a.65.65 0 0 0 .272-.555c0-.29-.118-.509-.354-.654-.23-.146-.645-.218-1.245-.218v-.891c.509 0 .87-.07 1.081-.21a.692.692 0 0 0 .328-.608c0-.219-.067-.385-.2-.5-.134-.122-.319-.182-.555-.182a1.3 1.3 0 0 0-.573.127c-.17.085-.348.215-.536.391l-.627-.764c.57-.49 1.166-.736 1.79-.736.595 0 1.067.14 1.419.418.351.273.527.658.527 1.155 0 .594-.324 1.024-.973 1.29v.037c.352.103.634.276.846.518.212.237.318.537.318.9 0 .346-.094.646-.282.9a1.766 1.766 0 0 1-.763.582c-.322.133-.676.2-1.064.2ZM11.431 16.5c-.412 0-.772-.115-1.082-.346-.303-.236-.539-.581-.709-1.036-.163-.454-.245-1.003-.245-1.645 0-.958.182-1.691.545-2.2.364-.515.86-.773 1.491-.773.624 0 1.118.258 1.482.773.364.509.546 1.242.546 2.2 0 .642-.085 1.19-.255 1.645-.164.455-.397.8-.7 1.036-.303.23-.66.346-1.073.346Zm0-.964c.267 0 .476-.154.628-.463.151-.315.227-.849.227-1.6 0-.74-.076-1.258-.227-1.555-.152-.303-.361-.454-.628-.454-.272 0-.485.148-.636.445-.146.297-.218.818-.218 1.564 0 .751.075 1.285.227 1.6.151.309.36.463.627.463Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h18v18H0z" />
      </ClipPath>
    </Defs>
  </Icon>
)
