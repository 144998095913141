import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { useWalletScreen } from './useWalletScreen'
import { WalletScreenComponent } from './WalletScreen.types'

const WalletScreenMobile = lazy(() => import('./WalletScreen.mobile'))

export const WalletScreen: WalletScreenComponent = ({ navigation, route }) => {
  const walletScreen = useWalletScreen({ navigation, route })

  const Screen = WalletScreenMobile

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...walletScreen} />
    </Suspense>
  )
}
