import { FunctionComponent, ReactElement, ReactNode } from 'react'

import Icons from 'atoms/Icons'
import { HStack, IconButton, VStack } from 'native-base'
import { InterfaceHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack'
import { StyleSheet } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

type TBaseAccordionCard = {
  head: ReactNode
  isOpen?: boolean
  onPress(): void
  initiaHeight?: number
  children(props: { isOpen: boolean }): ReactElement
  borderRadius?: number
  padding?: number
  style?: any
  styleHeader?: InterfaceHStackProps
}

export const BaseAccordionCard: FunctionComponent<TBaseAccordionCard> = ({
  head,
  children,
  isOpen,
  onPress,
  initiaHeight = 96,
  borderRadius = 20,
  style = {},
  styleHeader = {}
}) => {
  const height = useSharedValue(initiaHeight)

  const animatedStyle = useAnimatedStyle(() => {
    return {
      height: withTiming(isOpen ? height.value : initiaHeight, {
        duration: 300
      })
    }
  })

  return (
    <Animated.View style={[styles.container, animatedStyle, { borderRadius }, style]}>
      <HStack w="full" {...styleHeader} alignItems="center">
        {head}
        <IconButton
          w="34px"
          h="34px"
          mr={2}
          bg="gray.50"
          _hover={{
            bg: 'gray.100'
          }}
          _pressed={{
            bg: 'gray.100'
          }}
          borderRadius="full"
          variant="solid"
          onPress={onPress}
          icon={
            <Icons.AccordionArrow direction={isOpen ? 'up' : 'down'} color="gray.700" size="lg" />
          }
        />
      </HStack>
      {isOpen && (
        <VStack
          onLayout={(e) =>
            (height.value = isOpen ? initiaHeight + e.nativeEvent.layout.height : initiaHeight)
          }>
          {children({ isOpen })}
        </VStack>
      )}
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    marginVertical: 8
  }
})
