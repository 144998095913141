import { FunctionComponent } from 'react'

import { Icon, IIconProps, useTheme } from 'native-base'
import { G, Mask, Path, Rect } from 'react-native-svg'

const createStripes = (fillColor: string) => (
  <>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-30.0997 22.8922L17.742 -9.99101L17.6104 -9.16309L-30.2314 23.7201L-30.0997 22.8922Z"
      fill={fillColor}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-27.942 24.9713L19.8997 -7.91191L19.7681 -7.08398L-28.0737 25.7992L-27.942 24.9713Z"
      fill={fillColor}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-25.7848 27.0494L22.0569 -5.83379L21.9253 -5.00586L-25.9164 27.8773L-25.7848 27.0494Z"
      fill={fillColor}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-23.63 29.1285L24.2117 -3.75469L24.0801 -2.92676L-23.7617 29.9564L-23.63 29.1285Z"
      fill={fillColor}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-21.4738 31.2105L26.368 -1.67265L26.2363 -0.844727L-21.6054 32.0385L-21.4738 31.2105Z"
      fill={fillColor}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-19.319 33.2896L28.5228 0.406447L28.3911 1.23438L-19.4506 34.1176L-19.319 33.2896Z"
      fill={fillColor}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-17.1617 35.3687L30.68 2.48555L30.5483 3.31348L-17.2934 36.1967L-17.1617 35.3687Z"
      fill={fillColor}
    />
  </>
)

export const StrippedCircleIcon: FunctionComponent<IIconProps & { positive?: boolean }> = ({
  positive,
  ...props
}) => {
  const theme = useTheme()

  const fillColor = positive ? theme.colors.secondary[300] : theme.colors.error[500]

  const stripeColor = positive ? theme.colors.secondary[500] : theme.colors.primary[800]

  const maskId = `mask_${positive ? 'positive' : 'negative'}_circle`

  const viewBoxValue = positive ? 10 : 11

  return (
    <Icon viewBox={`0 0 ${11} ${viewBoxValue}`} {...props}>
      <Rect x={0.545898} width={10} height={10} rx={5} fill={fillColor} />
      <Mask id={maskId} maskUnits="userSpaceOnUse" x={0} y={0} width={11} height={10}>
        <Rect x={0.545898} width={10} height={10} rx={5} fill="white" />
      </Mask>
      <G mask={`url(#${maskId})`}>{createStripes(stripeColor)}</G>
    </Icon>
  )
}
