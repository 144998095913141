import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { EditProfileScreenComponent } from './EditProfileScreen.types'
import { useEditProfileScreen } from './useEditProfileScreen'

const EditProfileScreenDesktop = lazy(() => import('./EditProfileScreen.desktop'))

const EditProfileScreenMobile = lazy(() => import('./EditProfileScreen.mobile'))

export const EditProfileScreen: EditProfileScreenComponent = ({ navigation }) => {
  const editProfileScreen = useEditProfileScreen({ navigation })

  const Screen = editProfileScreen.isMobile ? EditProfileScreenMobile : EditProfileScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...editProfileScreen} />
    </Suspense>
  )
}
