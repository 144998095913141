import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { HStack, VStack, Text, Heading, Box } from 'native-base'

const formatDate = (date: Date) => {
  const dayDescribed = format(date, 'iiiiii', { locale: ptBR })

  const dayNumber = format(date, 'd', { locale: ptBR })

  const monthDescribed = format(date, 'MMM', { locale: ptBR })

  return `${dayDescribed.charAt(0).toUpperCase() + dayDescribed.slice(1)}., ${dayNumber} de ${
    monthDescribed.charAt(0).toUpperCase() + monthDescribed.slice(1)
  }`
}

export const CalendarHeader = ({
  startingDay,
  endingDay
}: {
  startingDay?: string
  endingDay?: string
}) => {
  const formatedStartingDay = startingDay ? formatDate(parseISO(startingDay)) : 'Definir data'

  const formatedEndindDay = endingDay ? formatDate(parseISO(endingDay)) : 'Definir data'

  return (
    <HStack
      py={2}
      mx={4}
      my={3}
      borderTopColor="gray.100"
      borderTopWidth="1px"
      borderBottomColor="gray.100"
      borderBottomWidth="1px"
      alignItems="center">
      <VStack flex={1}>
        <Heading fontSize="md" fontWeight="semibold">
          Início
        </Heading>
        <Text fontSize="sm" mt={1}>
          {formatedStartingDay}
        </Text>
      </VStack>
      <Box mx={4} w="1px" bg="gray.100" h="full" />
      <VStack justifyContent="flex-start" flex={1}>
        <Heading fontSize="md" fontWeight="semibold">
          Fim
        </Heading>
        <Text fontSize="sm" mt={1}>
          {formatedEndindDay}
        </Text>
      </VStack>
    </HStack>
  )
}
