import { FunctionComponent, memo } from 'react'

import { Center, Heading, Text } from 'native-base'

type EmptyStateProps = {
  description: string
  title: string
}

export const EmptyState: FunctionComponent<EmptyStateProps> = memo(({ description, title }) => (
  <Center flex={1}>
    <Heading>{title}</Heading>
    <Text>{description}</Text>
  </Center>
))
