import { memo } from 'react'

import Svg, { G, Path, Defs, ClipPath, Rect } from 'react-native-svg'

export const SocialMediaIllustration = memo(() => (
  <Svg width={251} height={250} viewBox="0 0 251 250" fill="none">
    <G clipPath="url(#clip0_8483_163420)">
      <Path
        d="M241.61 178.07L240.21 172.18C240.7 174.39 240.32 176.7 239.13 178.63C237.95 180.56 236.06 181.95 233.87 182.51L68.33 225.02C66.09 225.59 63.72 225.26 61.72 224.08C59.73 222.9 58.29 220.99 57.71 218.75L27.14 99.74C26.85 98.63 26.79 97.48 26.95 96.34C27.11 95.21 27.49 94.12 28.07 93.13C28.65 92.14 29.42 91.28 30.34 90.59C31.26 89.9 32.3 89.4 33.41 89.12L27.66 90.52C25.2 91.16 23.09 92.75 21.8 94.94C20.51 97.13 20.13 99.74 20.75 102.21L51.2 220.77C52.21 224.67 54.72 228.01 58.19 230.06C61.66 232.11 65.8 232.71 69.7 231.71L234.67 189.58C237.11 188.96 239.21 187.41 240.51 185.25C241.81 183.09 242.2 180.51 241.61 178.07Z"
        fill="#404040"
      />
      <Path
        d="M241.61 178.07L240.21 172.18C240.7 174.39 240.32 176.7 239.13 178.63C237.95 180.56 236.06 181.95 233.87 182.51L68.33 225.02C66.09 225.59 63.72 225.26 61.72 224.08C59.73 222.9 58.29 220.99 57.71 218.75L27.14 99.74C26.85 98.63 26.79 97.48 26.95 96.34C27.11 95.21 27.49 94.12 28.07 93.13C28.65 92.14 29.42 91.28 30.34 90.59C31.26 89.9 32.3 89.4 33.41 89.12L27.66 90.52C25.2 91.16 23.09 92.75 21.8 94.94C20.51 97.13 20.13 99.74 20.75 102.21L51.2 220.77C52.21 224.67 54.72 228.01 58.19 230.06C61.66 232.11 65.8 232.71 69.7 231.71L234.67 189.58C237.11 188.96 239.21 187.41 240.51 185.25C241.81 183.09 242.2 180.51 241.61 178.07V178.07Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M198.529 46.5703L33.3326 89.4469C28.5909 90.6776 25.7447 95.5191 26.9754 100.261L57.9615 219.645C59.1922 224.387 64.0337 227.233 68.7754 226.002L233.972 183.126C238.713 181.895 241.56 177.053 240.329 172.312L209.343 52.9275C208.112 48.1859 203.271 45.3397 198.529 46.5703Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M144.01 153.18L124.99 158.07L130.05 177.78L149.08 172.89L144.02 153.17L144.01 153.18Z"
        fill="#404040"
      />
      <Path
        d="M144.01 153.18L124.99 158.07L130.05 177.78L149.08 172.89L144.02 153.17L144.01 153.18Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M93.1899 166.24L74.1699 171.13L79.2299 190.84L98.2499 185.95L93.1899 166.24Z"
        fill="#404040"
      />
      <Path
        d="M93.1899 166.24L74.1699 171.13L79.2299 190.84L98.2499 185.95L93.1899 166.24Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M204.41 133.26L176.83 140.35L184.18 168.94L211.76 161.86L204.41 133.27V133.26Z"
        fill="#F3123C"
      />
      <Path
        d="M204.41 133.26L176.83 140.35L184.18 168.94L211.76 161.86L204.41 133.27V133.26Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M173.33 144.96L196.51 159.85L208.41 130.8"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M74.5501 152.92C85.4501 152.92 94.2901 144.08 94.2901 133.18C94.2901 122.28 85.4501 113.44 74.5501 113.44C63.6501 113.44 54.8101 122.28 54.8101 133.18C54.8101 144.08 63.6501 152.92 74.5501 152.92Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M87.7301 140.45C87.3801 139.04 86.7601 137.72 85.9001 136.55C85.0401 135.39 83.9501 134.4 82.7101 133.66C81.4701 132.92 80.0901 132.43 78.6501 132.22C77.2201 132.01 75.7601 132.09 74.3501 132.45C72.9501 132.81 71.6301 133.45 70.4701 134.32C69.3101 135.19 68.3401 136.29 67.6101 137.54C66.8801 138.79 66.4001 140.18 66.2101 141.61C66.0201 143.04 66.1101 144.51 66.4901 145.9"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M63.2199 130.95C64.1999 130.95 64.9999 130.15 64.9999 129.17C64.9999 128.19 64.1999 127.39 63.2199 127.39C62.2399 127.39 61.4399 128.19 61.4399 129.17C61.4399 130.15 62.2399 130.95 63.2199 130.95Z"
        fill="#404040"
      />
      <Path
        d="M63.2199 130.95C64.1999 130.95 64.9999 130.15 64.9999 129.17C64.9999 128.19 64.1999 127.39 63.2199 127.39C62.2399 127.39 61.4399 128.19 61.4399 129.17C61.4399 130.15 62.2399 130.95 63.2199 130.95Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M82.53 125.98C83.51 125.98 84.31 125.18 84.31 124.2C84.31 123.22 83.51 122.42 82.53 122.42C81.55 122.42 80.75 123.22 80.75 124.2C80.75 125.18 81.55 125.98 82.53 125.98Z"
        fill="#404040"
      />
      <Path
        d="M82.53 125.98C83.51 125.98 84.31 125.18 84.31 124.2C84.31 123.22 83.51 122.42 82.53 122.42C81.55 122.42 80.75 123.22 80.75 124.2C80.75 125.18 81.55 125.98 82.53 125.98Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M175.7 73.77C170.83 74.77 166.35 77.13 162.77 80.58C159.19 84.03 156.66 88.42 155.48 93.24C154.3 98.07 154.5 103.13 156.08 107.84C157.66 112.55 160.54 116.72 164.39 119.86C168.24 123 172.9 124.98 177.84 125.58C182.77 126.18 187.77 125.36 192.26 123.23C196.75 121.1 200.54 117.74 203.2 113.54C205.85 109.34 207.27 104.47 207.27 99.5C207.27 95.6 206.4 91.75 204.73 88.23C203.06 84.71 200.62 81.6 197.6 79.14C194.58 76.68 191.05 74.92 187.26 73.99C183.47 73.06 179.53 72.99 175.71 73.77H175.7Z"
        fill="#F3123C"
      />
      <Path
        d="M175.7 73.77C170.83 74.77 166.35 77.13 162.77 80.58C159.19 84.03 156.66 88.42 155.48 93.24C154.3 98.07 154.5 103.13 156.08 107.84C157.66 112.55 160.54 116.72 164.39 119.86C168.24 123 172.9 124.98 177.84 125.58C182.77 126.18 187.77 125.36 192.26 123.23C196.75 121.1 200.54 117.74 203.2 113.54C205.85 109.34 207.27 104.47 207.27 99.5C207.27 95.6 206.4 91.75 204.73 88.23C203.06 84.71 200.62 81.6 197.6 79.14C194.58 76.68 191.05 74.92 187.26 73.99C183.47 73.06 179.53 72.99 175.71 73.77H175.7Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M195.27 96.37C195.76 98.23 195.88 100.17 195.62 102.07C195.36 103.98 194.72 105.81 193.75 107.47C192.78 109.13 191.49 110.58 189.95 111.74C188.41 112.9 186.66 113.74 184.8 114.22C182.94 114.7 181 114.8 179.09 114.53C177.19 114.26 175.35 113.61 173.7 112.63C172.05 111.65 170.6 110.35 169.46 108.8C168.31 107.26 167.48 105.5 167.01 103.64"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M165.82 96.06C167.13 96.06 168.2 95 168.2 93.68C168.2 92.36 167.14 91.3 165.82 91.3C164.5 91.3 163.44 92.36 163.44 93.68C163.44 95 164.5 96.06 165.82 96.06Z"
        fill="#404040"
      />
      <Path
        d="M165.82 96.06C167.13 96.06 168.2 95 168.2 93.68C168.2 92.36 167.14 91.3 165.82 91.3C164.5 91.3 163.44 92.36 163.44 93.68C163.44 95 164.5 96.06 165.82 96.06Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M191.49 89.46C192.8 89.46 193.87 88.4 193.87 87.08C193.87 85.76 192.81 84.7 191.49 84.7C190.17 84.7 189.11 85.76 189.11 87.08C189.11 88.4 190.17 89.46 191.49 89.46Z"
        fill="#404040"
      />
      <Path
        d="M191.49 89.46C192.8 89.46 193.87 88.4 193.87 87.08C193.87 85.76 192.81 84.7 191.49 84.7C190.17 84.7 189.11 85.76 189.11 87.08C189.11 88.4 190.17 89.46 191.49 89.46Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M125.38 139.88C136.28 139.88 145.12 131.04 145.12 120.14C145.12 109.24 136.28 100.4 125.38 100.4C114.48 100.4 105.64 109.24 105.64 120.14C105.64 131.04 114.48 139.88 125.38 139.88V139.88Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M115.42 128.55L138.17 122.71"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M114.64 120.17C115.62 120.17 116.42 119.37 116.42 118.39C116.42 117.41 115.62 116.61 114.64 116.61C113.66 116.61 112.86 117.41 112.86 118.39C112.86 119.37 113.66 120.17 114.64 120.17Z"
        fill="#404040"
      />
      <Path
        d="M114.64 120.17C115.62 120.17 116.42 119.37 116.42 118.39C116.42 117.41 115.62 116.61 114.64 116.61C113.66 116.61 112.86 117.41 112.86 118.39C112.86 119.37 113.66 120.17 114.64 120.17Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M133.95 115.2C134.93 115.2 135.73 114.4 135.73 113.42C135.73 112.44 134.93 111.64 133.95 111.64C132.97 111.64 132.17 112.44 132.17 113.42C132.17 114.4 132.97 115.2 133.95 115.2Z"
        fill="#404040"
      />
      <Path
        d="M133.95 115.2C134.93 115.2 135.73 114.4 135.73 113.42C135.73 112.44 134.93 111.64 133.95 111.64C132.97 111.64 132.17 112.44 132.17 113.42C132.17 114.4 132.97 115.2 133.95 115.2V115.2Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M210.04 85.63C228.86 85.63 244.12 70.37 244.12 51.55C244.12 32.73 228.87 17.47 210.04 17.47C191.21 17.47 175.96 32.73 175.96 51.55C175.96 70.37 191.22 85.63 210.04 85.63Z"
        fill="#F5F5F5"
        stroke="#2E2E2E"
        strokeWidth={0.94}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M204.77 31.05L214.25 42.07L228.69 40.5L221.14 52.92L227.08 66.17L212.95 62.82L202.17 72.56L201.01 58.1L188.4 50.86L201.8 45.26L204.79 31.06L204.77 31.05Z"
        fill="#F3123C"
      />
      <Path
        d="M204.77 31.05L214.25 42.07L228.69 40.5L221.14 52.92L227.08 66.17L212.95 62.82L202.17 72.56L201.01 58.1L188.4 50.86L201.8 45.26L204.79 31.06L204.77 31.05Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M210.49 214.29L214.48 229.94"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M220.33 220.11L204.68 224.1"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M18.4297 55.73L24.7897 54.07"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M36.1201 51.15L42.5101 49.49"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M27.3599 40.55L28.9999 46.94"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M31.9399 58.27L33.5799 64.66"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M19.73 46.3L25.43 49.65"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M35.5 55.59L41.18 58.94"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M36.7899 41.89L33.4399 47.57"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M27.4999 57.65L24.1499 63.33"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10.4299 215.43C12.5299 215.43 14.2299 213.73 14.2299 211.63C14.2299 209.53 12.5299 207.83 10.4299 207.83C8.32988 207.83 6.62988 209.53 6.62988 211.63C6.62988 213.73 8.32988 215.43 10.4299 215.43V215.43Z"
        stroke="#2E2E2E"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_8483_163420">
        <Rect width={238.59} height={215.81} fill="white" transform="translate(6 17)" />
      </ClipPath>
    </Defs>
  </Svg>
))
