import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { TabFilterScreenComponent } from './TabFilterScreen.types'
import { useTabFilterScreen } from './useTabFilterScreen'

const TabFilterScreenDesktop = lazy(() => import('./TabFilterScreen.desktop'))

const TabFilterScreenMobile = lazy(() => import('./TabFilterScreen.mobile'))

export const TabFilterScreen: TabFilterScreenComponent = ({ navigation, route }) => {
  const tabFilterScreen = useTabFilterScreen({ navigation, route })

  const Screen = tabFilterScreen.isMobile ? TabFilterScreenMobile : TabFilterScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...tabFilterScreen} />
    </Suspense>
  )
}
