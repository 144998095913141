import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { CreateActivityScreenComponent } from './types'
import { useCreateActivity } from './useCreateActivity'

const ScreenDesktop = lazy(() => import('./desktop'))

const ScreenMobile = lazy(() => import('./mobile'))

export const CreateActivityScreen: CreateActivityScreenComponent = ({ navigation, route }) => {
  const editActivity = useCreateActivity({ navigation, route })

  const Screen = editActivity.isMobile ? ScreenMobile : ScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...editActivity} />
    </Suspense>
  )
}
