import { memo } from 'react'

import { HStack, Skeleton } from 'native-base'

export const SkeletonResumedChart = memo(() => {
  return (
    <HStack space={7} justifyContent="center" alignItems="flex-end">
      <Skeleton h="100%" w={8} borderRadius="full" />
      <Skeleton h="50%" w={8} borderRadius="full" />
      <Skeleton h="40%" w={8} borderRadius="full" />
      <Skeleton h="70%" w={8} borderRadius="full" />
      <Skeleton h="90%" w={8} borderRadius="full" />
      <Skeleton h="40%" w={8} borderRadius="full" />
    </HStack>
  )
})
