import { FunctionComponent } from 'react'

import { Cards } from 'atoms'
import { Box, FlatList, HStack, Pressable, Skeleton, useBreakpointValue } from 'native-base'
import { TouchableOpacity, useWindowDimensions } from 'react-native'

type TSummaryList = {
  handleGoToProductionChart(): void
  data: any[]
  isLoading?: boolean
}

export const OpportunityType: FunctionComponent<TSummaryList> = ({
  data = [],
  handleGoToProductionChart,
  isLoading = false
}) => {
  const { width: SCREEN_WIDTH } = useWindowDimensions()

  const widthCard = Math.floor((SCREEN_WIDTH - 48) / 2)

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  return (
    <HStack flexWrap="wrap">
      {isLoading && isDesktop && (
        <>
          <FlatList
            data={[1, 2, 3, 4, 5, 6]}
            keyExtractor={(item) => `${item}`}
            contentContainerStyle={{
              paddingVertical: 32
            }}
            numColumns={3}
            renderItem={({ item, index }) => (
              <Skeleton
                w="350px"
                mx={[1, 4].includes(index) ? '30px' : 0}
                mb="30px"
                h={294}
                borderRadius="20px"
              />
            )}
          />
        </>
      )}
      {!isDesktop ? (
        <>
          {data.map((item, index) => (
            <TouchableOpacity
              onPress={() =>
                item.subtitle.includes('Produção') ? handleGoToProductionChart() : {}
              }
              key={Math.random()}
              style={{
                paddingLeft: index % 2 !== 0 ? 16 : 0
              }}>
              <Box w={`${widthCard}px`}>
                <Cards.Summary {...item} key={Math.random()} />
              </Box>
            </TouchableOpacity>
          ))}
        </>
      ) : (
        <FlatList
          data={data}
          keyExtractor={(_, key) => key.toString()}
          contentContainerStyle={{
            paddingVertical: 32
          }}
          numColumns={3}
          renderItem={({ item, index }) => (
            <Pressable
              w={SCREEN_WIDTH < 1400 ? '320px' : '350px'}
              h="294px"
              bg="white"
              onPress={() =>
                item.subtitle.includes('Produção') ? handleGoToProductionChart() : {}
              }
              _hover={
                item.subtitle.includes('Produção')
                  ? {
                      style: {
                        shadowOffset: { width: 0, height: 3 },
                        shadowRadius: 16,
                        shadowColor: 'rgba(0, 0, 0, 0.08)',
                        transform: [
                          {
                            translateY: -2
                          }
                        ],
                        // @ts-ignore
                        transition: 'all 0.3s ease'
                      }
                    }
                  : {}
              }
              mx={[1, 4].includes(index) ? '30px' : 0}
              borderRadius="20px"
              mb="30px">
              <Cards.Summary {...item} />
            </Pressable>
          )}
        />
      )}
    </HStack>
  )
}
