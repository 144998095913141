import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const CheckConcludedIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 19 18', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.14996 2.70039C2.14996 2.12049 2.62006 1.65039 3.19995 1.65039H15.7999C16.3798 1.65039 16.8499 2.12049 16.8499 2.70039V15.3004C16.8499 15.8803 16.3798 16.3504 15.7999 16.3504H3.19995C2.62006 16.3504 2.14996 15.8803 2.14996 15.3004V2.70039ZM3.19995 0.150391C1.79163 0.150391 0.649963 1.29206 0.649963 2.70039V15.3004C0.649963 16.7087 1.79163 17.8504 3.19995 17.8504H15.7999C17.2082 17.8504 18.3499 16.7087 18.3499 15.3004V2.70039C18.3499 1.29206 17.2082 0.150391 15.7999 0.150391H3.19995ZM13.7359 5.7979C13.9554 5.44665 13.8487 4.98393 13.4974 4.7644C13.1462 4.54487 12.6834 4.65165 12.4639 5.0029L8.46361 11.4034L6.43027 9.37007C6.13738 9.07718 5.66251 9.07718 5.36961 9.37007C5.07672 9.66296 5.07672 10.1378 5.36961 10.4307L8.0696 13.1307C8.23122 13.2923 8.45771 13.3714 8.6848 13.3456C8.91189 13.3197 9.11479 13.1917 9.23593 12.9979L13.7359 5.7979Z"
      fill="currentColor"
    />
  </Icon>
)
