import { useCallback, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { Note, useCreateNoteMutation } from 'integration/resources/wallet'
import { useBreakpointValue, useToast } from 'native-base'
import { Toast } from 'organisms'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard } from 'react-native'
import fieldsValidation from 'src/constants/fields-validation'
import { LogEvent } from 'src/utils/logEvents'
import * as Yup from 'yup'

import { UseNoteCreateDetailsScreen } from './NoteCreateDetailsScreen.types'

const schema = Yup.object().shape({
  title: Yup.string()
    .required(fieldsValidation.common.required)
    .min(10, 'Título precisa ter no mínimo 10 caracteres')
    .max(60, 'Título pode ter no máximo 60 caracteres'),
  detail: Yup.string()
    .required(fieldsValidation.common.required)
    .max(400, 'Detalhe pode ter no máximo 400 caracteres'),
  type: Yup.string().required(fieldsValidation.common.required)
})

export const useNoteCreateDetailsScreen: UseNoteCreateDetailsScreen = ({
  navigation,
  route,
  onSuccessSubmit
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const bottomSheetContext = useBottomSheetContext()

  const { contactId, tabNumber } = route?.params

  const [openAddedNoteType, setOpenAddedNoteType] = useState(false)

  const { control, handleSubmit, setValue, formState } = useForm<Note>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      type: 'NEGOCIAÇÃO'
    }
  })

  const { isDirty, isValid } = formState

  const isDisabled = !isDirty || !isValid

  const { isLoading, mutate } = useCreateNoteMutation(contactId ? 'contacts' : 'stores')

  const handleGoToWalletContact = useCallback(() => {
    if (onSuccessSubmit) return onSuccessSubmit()

    if (contactId) navigation.navigate('WalletContact', { contactId })

    if (tabNumber) navigation.navigate('WalletTab', { tabNumber })
  }, [navigation, contactId, tabNumber, onSuccessSubmit])

  const handleCreateNote = useCallback(() => {
    bottomSheetContext.open({
      description: 'Não foi possível criar a nota, tente novamente!',
      title: 'Erro ao criar nota'
    })
  }, [bottomSheetContext])

  const toast = useToast()

  const submit = handleSubmit(
    useCallback<SubmitHandler<Note>>(
      async (formData) => {
        Keyboard.dismiss()

        const formatData = {
          ...formData,
          tab_number: tabNumber,
          contact_id: contactId
        }

        mutate(
          {
            ...formatData
          },
          {
            onError: () => {
              handleCreateNote()
            },

            onSuccess: ({ data: { data } }) => {
              LogEvent(`carteira_nota_sucesso`, {
                item_id: data.id
              })

              toast.show({
                render: () => <Toast type="success" text="Informações salvas com sucesso" />
              })

              handleGoToWalletContact()
            }
          }
        )
      },
      [tabNumber, contactId, mutate, handleCreateNote, toast, handleGoToWalletContact]
    )
  )

  const handleSetOpenAddedNoteType = () => setOpenAddedNoteType(!openAddedNoteType)

  const handleAddedNoteType = (type: string) => {
    setValue('type', type)

    handleSetOpenAddedNoteType()
  }

  const closeRightSheet = navigation.goBack

  useEffect(() => {
    if (contactId) {
      setValue('contact_id', contactId)
    }

    if (tabNumber) {
      setValue('tab_number', tabNumber)
    }
  }, [setValue, contactId, tabNumber])

  return {
    isMobile,
    isLoading,
    control,
    submit,
    handleAddedNoteType,
    openAddedNoteType,
    handleSetOpenAddedNoteType,
    isDisabled,
    closeRightSheet
  }
}
