import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { ProductionChartScreenComponent } from './ProductionChartScreen.types'
import { useProductionChartScreen } from './useProductionChartScreen'

const ProductionChartScreenDesktop = lazy(() => import('./ProductionChartScreen.desktop'))

const ProductionChartScreenMobile = lazy(() => import('./ProductionChartScreen.mobile'))

export const ProductionChartScreen: ProductionChartScreenComponent = ({ navigation, route }) => {
  const productionChartScreen = useProductionChartScreen({ navigation, route })

  const Screen = productionChartScreen.isMobile
    ? ProductionChartScreenMobile
    : ProductionChartScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...productionChartScreen} />
    </Suspense>
  )
}
