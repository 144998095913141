import { useMemo } from 'react'

import { UseXRay } from './XRay.types'

export const useXRay: UseXRay = ({ opportunityDetails, isMobile, isLoading }) => {
  const data = useMemo(
    () => opportunityDetails?.store_xray_serialized,
    [opportunityDetails?.store_xray_serialized]
  )

  const firstLineResume = data?.slice(0, 4)

  const secondLineResume = data?.slice(4, 8)

  const thirdLineResume = data?.slice(8, 12)

  const sliderDots = [0, 1, 2, 3]

  return {
    data,
    isLoading,
    opportunityDetails,
    isMobile,
    sliderDots,
    firstLineResume,
    secondLineResume,
    thirdLineResume
  }
}
