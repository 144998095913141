import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { AboutScreenComponent } from './AboutScreen.types'
import { useAboutScreen } from './useAboutScreen'

const AboutDesktop = lazy(() => import('./AboutScreen.desktop'))

const AboutMobile = lazy(() => import('./AboutScreen.mobile'))

export const AboutScreen: AboutScreenComponent = ({ navigation }) => {
  const aboutScreen = useAboutScreen({ navigation })

  const Screen = aboutScreen.isMobile ? AboutMobile : AboutDesktop

  return (
    <>
      <Suspense fallback={<LoadingTurbo />}>
        <Screen {...aboutScreen} />
      </Suspense>
    </>
  )
}
