import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { StaffListScreenComponent } from './StaffListScreen.types'
import { useStaffListScreen } from './useStaffListScreen'

const StaffListScreenDesktop = lazy(() => import('./StaffListScreen.desktop'))

export const StaffListScreen: StaffListScreenComponent = ({ navigation, route }) => {
  const props = useStaffListScreen({ navigation, route })

  const Screen = StaffListScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
