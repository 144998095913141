import { FunctionComponent, memo, useState } from 'react'

import { Icons } from 'atoms'
import { Camera as ExpoCamera, CameraCapturedPicture, CameraType } from 'expo-camera'
import { FlipType, manipulateAsync, SaveFormat } from 'expo-image-manipulator'
import { VStack, Heading, ScrollView, Box, IconButton, HStack, Button } from 'native-base'
import { Modal, useWindowDimensions } from 'react-native'

type CameraProps = {
  isOpen: boolean
  handleSaveImage(photo: CameraCapturedPicture): void
  onClose: () => void
}

let camera: ExpoCamera | null

export const Camera: FunctionComponent<CameraProps> = memo(
  ({ isOpen, onClose, handleSaveImage }) => {
    const { height: SCREEN_HEIGHT } = useWindowDimensions()

    const [type, setType] = useState(CameraType.front)

    const toggleCameraType = () => {
      setType((current) => (current === CameraType.back ? CameraType.front : CameraType.back))
    }

    const takePicture = async () => {
      if (!camera) return

      const options = { base64: true }

      let picture = await camera.takePictureAsync(options)

      picture = await manipulateAsync(picture.uri, [{ rotate: 180 }, { flip: FlipType.Vertical }], {
        compress: 1,
        format: SaveFormat.PNG
      })

      handleSaveImage(picture)
    }

    return (
      <Modal presentationStyle="fullScreen" animationType="slide" visible={isOpen}>
        <ScrollView
          bg="background.base"
          scrollEventThrottle={32}
          showsVerticalScrollIndicator={false}
          h={SCREEN_HEIGHT}>
          <Box safeArea py={8}>
            <VStack>
              <HStack alignItems="center" space={24} mb={12} mx={4}>
                <IconButton
                  size={10}
                  bg="white"
                  borderRadius="full"
                  icon={<Icons.Chevron direction="left" size={8} color="gray.700" />}
                  onPress={onClose}
                  zIndex={9}
                />

                <Heading fontSize="18px" lineHeight="24px" color="gray.900">
                  Capturar foto
                </Heading>
              </HStack>

              <VStack>
                <ExpoCamera
                  style={{ flex: 1, height: SCREEN_HEIGHT / 2 }}
                  ref={(r) => {
                    camera = r
                  }}
                  type={type}
                  ratio="1:1"
                />

                <IconButton
                  mt="4"
                  borderRadius="full"
                  onPress={toggleCameraType}
                  _pressed={{
                    bg: 'transparent'
                  }}
                  icon={<Icons.Rotate color="gray.700" size={8} />}
                />

                <Button
                  mx={4}
                  mb="115px"
                  mt={6}
                  backgroundColor="gray.700"
                  _pressed={{
                    backgroundColor: 'gray.500'
                  }}
                  _text={{ color: 'white' }}
                  onPress={takePicture}>
                  Salvar
                </Button>
              </VStack>
            </VStack>
          </Box>
        </ScrollView>
      </Modal>
    )
  }
)
