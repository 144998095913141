import { useCallback, useEffect, useMemo, useState } from 'react'

import * as ScreenOrientation from 'expo-screen-orientation'
import {
  KEY_PRODUCTION_STORES_LIST_QUERY,
  useGetProductionHistoryMonthsQuery,
  useGetProductionStoresListInfiniteQuery
} from 'integration/resources/production'
import { Store } from 'integration/resources/store'
import { useBreakpointValue } from 'native-base'
import { PanelsTypes } from 'organisms/TabPanels/constants'
import { Platform } from 'react-native'
import { useQueryClient } from 'react-query'
import { useAuthAtomValue } from 'src/store/auth'
import { useSetContractListScreenAtom } from 'src/store/screens/contractListScreen'
import { formatToReal, getMonthName } from 'src/utils'
import { LogEvent } from 'src/utils/logEvents'

import { UseProductionScreen } from './ProductionScreen.types'

const changeScreenOrientation = (orientation: ScreenOrientation.OrientationLock) => {
  return ScreenOrientation.lockAsync(orientation)
}

const mergeAllDataInfiniteQuery = (
  data: ReturnType<typeof useGetProductionStoresListInfiniteQuery>['data']
) =>
  data?.pages.reduce<Store[]>((previousValue, currentValue) => {
    return [...previousValue, ...currentValue.data.data.items] as Store[]
  }, [])

export const useProductionScreen: UseProductionScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [showSpinner, setShowSpinner] = useState(true)

  const authAtom = useAuthAtomValue()

  const isGoodsAndServices = useMemo(
    () => authAtom?.user.business_code === 3,
    [authAtom?.user.business_code]
  )

  const timeoutSpinner = setTimeout(() => {
    setShowSpinner(false)
  }, 1250)

  useEffect(() => {
    return () => {
      clearTimeout(timeoutSpinner)
    }
  }, [timeoutSpinner])

  const handleGoBack = useCallback(() => {
    if (Platform.OS !== 'web') {
      changeScreenOrientation(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT).then(() =>
        navigation.replace('ProductionChart')
      )
    } else {
      navigation.replace('ProductionChart')
    }
  }, [navigation])

  useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        if (e.data.action.type === 'GO_BACK' && Platform.OS !== 'web') {
          // Prevent default behavior of leaving the screen
          e.preventDefault()

          setShowSpinner(true)

          changeScreenOrientation(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT).then(() => {
            setTimeout(() => {
              navigation.replace('ProductionChart')
            }, 1250)
          })
        }
      }),
    [navigation]
  )

  const queryClient = useQueryClient()

  const [orderByDirectionAsc, setOrderByDirectionAsc] = useState<boolean>(false)

  const { data: productionHistoryMonthsData, isLoading: isLoadingProductionMonths } =
    useGetProductionHistoryMonthsQuery({
      isMobile: false
    })

  const { yearMonth } = route?.params

  const productionMonthYear = `${getMonthName(Number(yearMonth.slice(4)))} ${yearMonth.slice(0, 4)}`

  const { data, isLoading, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetProductionStoresListInfiniteQuery({
      year_month: yearMonth,
      order_by: 'production',
      order_by_direction: orderByDirectionAsc ? 'asc' : 'desc',
      per_page: 20
    })

  const totalProduction = formatToReal(data?.pages[0].data.data.total_production ?? 0)

  const totalStores = data?.pages[0].data.metadata.pagination.total ?? 0

  const onRefresh = () =>
    queryClient.invalidateQueries([`${KEY_PRODUCTION_STORES_LIST_QUERY}.${yearMonth}`])

  const handleOrderBy = () => {
    LogEvent(`producao_tab_ordenacao_${orderByDirectionAsc ? 'asc' : 'desc'}`, {
      item_id: ''
    })

    setOrderByDirectionAsc(!orderByDirectionAsc)

    queryClient.invalidateQueries(KEY_PRODUCTION_STORES_LIST_QUERY)
  }

  const onEndReached = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const storesPagination = data?.pages[0].data.metadata.pagination

  const storesList = useMemo(() => mergeAllDataInfiniteQuery(data), [data])

  const setContractListScreen = useSetContractListScreenAtom()

  const handleGoToTabDetailsScreen = useCallback(
    (tabNumber: string) => {
      LogEvent(`contratos_tab`, {
        item_id: tabNumber
      })

      setContractListScreen({
        title: 'Contratos da TAB',
        description: `Produção ${productionMonthYear} (R$)`
      })

      return navigation.navigate('ContractList', {
        tabNumber,
        yearMonth,
        type: PanelsTypes.PRODUCTION
      })
    },
    [navigation, productionMonthYear, yearMonth, setContractListScreen]
  )

  const handleSelectYearMonth = (yearMonth: string) => {
    LogEvent(`producao_tab`, {
      item_id: productionMonthYear.replace(' ', '_')
    })

    navigation.navigate('Production', {
      yearMonth
    })
  }

  return {
    isMobile,
    showSpinner,
    handleGoToTabDetailsScreen,
    handleOrderBy,
    handleGoBack,
    orderByDirectionAsc,
    storesList,
    isLoading,
    isFetching,
    storesPagination,
    onRefresh,
    onEndReached,
    totalProduction,
    productionMonthYear,
    isGoodsAndServices,
    totalStores,
    isLoadingProductionMonths,
    productionHistoryMonthsData: productionHistoryMonthsData?.data ?? [],
    currentYearMonth: yearMonth,
    handleSelectYearMonth
  }
}
