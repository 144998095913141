/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { TUsers, useGetOpportunityFiltersList } from 'integration/resources/newOpportunities'
import { useBreakpointValue } from 'native-base'
import { useForm } from 'react-hook-form'

import {
  OPPORTUNITY_FILTER_SCHEMAS,
  OPPORTUNITY_TYPES_OPTIONS,
  returnOpportunityType
} from './constants'
import {
  UseOpportunityFilterScreen,
  TFieldNames,
  TFieldConfig,
  TFormFields
} from './OpportunityFilterScreen.types'

const getValidUserStdCode = (userStdCode: any): number | '' => {
  if (userStdCode != null && !isNaN(Number(userStdCode))) {
    return Number(userStdCode)
  }

  return ''
}

export const useOpportunityFilterScreen: UseOpportunityFilterScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const currentFilters = useMemo(() => {
    return {
      order_by: route?.params?.order_by,
      order_by_direction: route?.params?.order_by_direction,
      listType: route?.params?.listType
    }
  }, [route?.params])

  const currentSchema = OPPORTUNITY_FILTER_SCHEMAS[currentFilters?.listType]

  const opportunityType = returnOpportunityType(currentFilters?.listType)

  const {
    data: filters,
    isLoading: isLoadingFilter,
    refetch: refetchFilter,
    isRefetching: isRefetchingFilter
  } = useGetOpportunityFiltersList(opportunityType, !!currentFilters?.listType && !!opportunityType)

  useEffect(() => {
    refetchFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityType])

  const closeRightSheet = useCallback(() => {
    navigation.goBack()
  }, [navigation])

  const { control, formState, reset, handleSubmit, watch } = useForm<TFormFields>({
    resolver: yupResolver(currentSchema),
    mode: 'onChange',
    defaultValues: {
      offer: route?.params?.offer ?? '',
      public_rating: route?.params?.public_rating ?? '',
      segment: route?.params?.segment ?? '',
      network: route?.params?.network ?? '',
      subsidiary: route?.params?.subsidiary ?? '',
      opportunity_type:
        OPPORTUNITY_TYPES_OPTIONS.find(
          (option) => option?.id.toString() === route?.params?.opportunity_type
        )?.name ?? '',
      user_std_code: getValidUserStdCode(route?.params?.user_std_code)
    }
  })

  const handleSubmitForm = (values: TFormFields) => {
    const filterValues = {
      offer: values.offer || undefined,
      public_rating: values.public_rating || undefined,
      segment: values.segment || undefined,
      network: values.network || undefined,
      subsidiary: values.subsidiary || undefined,
      opportunity_type:
        OPPORTUNITY_TYPES_OPTIONS.find(
          (option) => option?.name === values?.opportunity_type
        )?.id.toString() ?? undefined,
      user_std_code: values.user_std_code || undefined
    }

    return isMobile
      ? navigation.navigate('Tab', {
          screen: 'Organization',
          params: {
            ...filterValues,
            ...currentFilters
          }
        })
      : navigation.navigate('OrganizationOpportunityList', {
          ...filterValues,
          ...currentFilters
        })
  }

  const handleResetForm = () => {
    const cleanFilters = {
      offer: undefined,
      public_rating: undefined,
      segment: undefined,
      network: undefined,
      subsidiary: undefined,
      opportunity_type: undefined,
      user_std_code: undefined
    }

    navigation.setParams({ ...cleanFilters })

    reset(
      {
        offer: '',
        public_rating: '',
        segment: '',
        network: '',
        subsidiary: '',
        opportunity_type: '',
        user_std_code: ''
      },
      { keepDefaultValues: true }
    )
  }

  const fieldNames = Object.keys(currentSchema.fields) as TFieldNames[]

  const filtersList = useMemo(() => filters?.data?.data, [filters])

  const [subsidiariesList, setSubsidiariesList] = useState<string[]>()

  const subsidiaries = useMemo(() => subsidiariesList, [subsidiariesList])

  const networksList = filtersList?.networks.map((item) => item.network)

  const [usersList, setUsersList] = useState<TUsers[]>()

  const users = useMemo(() => usersList, [usersList])

  const formFields = watch()

  const allSubsidiaries = filtersList?.networks.reduce(
    //@ts-ignore
    (acc, actual) => acc.concat(actual['subsidiaries'] || []),
    []
  )

  useEffect(() => {
    if (filtersList?.users?.length) setUsersList(filtersList?.users)
  }, [filtersList?.users])

  useEffect(() => {
    setSubsidiariesList(
      filtersList?.networks.filter((item) => item.network === formFields.network)[0]
        ?.subsidiaries ?? allSubsidiaries
    )

    if (formFields.network)
      setUsersList(
        filtersList?.users?.filter((item) => item.network_name === formFields.network) ??
          filtersList?.users
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields.network, filtersList?.networks])

  useEffect(() => {
    if (formFields.subsidiary)
      setUsersList(
        filtersList?.users?.filter((item) => item.subsidiary_name === formFields.subsidiary) ??
          filtersList?.users
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields.subsidiary])

  const fieldConfig: TFieldConfig = {
    offer: {
      options: filtersList?.offers ?? [],
      label: 'Por tipo de Oferta'
    },
    public_rating: {
      options: filtersList?.public_ratings ?? [],
      label: 'Por Público'
    },
    segment: {
      options: filtersList?.segments ?? [],
      label: 'Por Segmento'
    },
    network: {
      options: networksList ?? [],
      label: 'Por Rede'
    },
    subsidiary: {
      options: subsidiaries ?? [],
      label: 'Por Filial'
    },
    opportunity_type: {
      options: OPPORTUNITY_TYPES_OPTIONS,
      label: 'Por Origem'
    },
    user_std_code: {
      options: users ?? [],
      label: 'Por Especialista'
    }
  }

  return {
    closeRightSheet,
    isMobile,
    control,
    handleSubmit,
    handleSubmitForm,
    handleResetForm,
    fieldNames,
    fieldConfig,
    isDirty: formState.isDirty,
    isLoading: isLoadingFilter || isRefetchingFilter
  }
}
