import { FunctionComponent } from 'react'

import { Box, useTheme } from 'native-base'
import Animated, {
  Extrapolate,
  interpolate,
  interpolateColor,
  useAnimatedStyle
} from 'react-native-reanimated'

type AnimatedSlideDotProps = {
  index: number
  scrollX: Animated.SharedValue<number>
  slideWidth: number
  color?: string
}

const AnimatedBox = Animated.createAnimatedComponent(Box)

export const AnimatedSlideDot: FunctionComponent<AnimatedSlideDotProps> = ({
  index,
  scrollX,
  slideWidth,
  color
}) => {
  const theme = useTheme()

  const activeColor = color ?? theme.colors.primary[500]

  const animatedStyle = useAnimatedStyle(() => ({
    backgroundColor: interpolateColor(
      scrollX.value,
      [(index - 1) * slideWidth, index * slideWidth, (index + 1) * slideWidth],
      [theme.colors.gray[300], activeColor, theme.colors.gray[300]]
    ),
    height: interpolate(
      scrollX.value,
      [(index - 1) * slideWidth, index * slideWidth, (index + 1) * slideWidth],
      [6, 6, 6],
      Extrapolate.CLAMP
    ),
    width: interpolate(
      scrollX.value,
      [(index - 1) * slideWidth, index * slideWidth, (index + 1) * slideWidth],
      [6, 16, 6],
      Extrapolate.CLAMP
    )
  }))

  return <AnimatedBox borderRadius="full" h={4} style={animatedStyle} />
}
