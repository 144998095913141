import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const CalendarPlusIcon: FunctionComponent<IIconProps & { opacity?: number }> = ({
  opacity,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 18 18', ...props }}>
    <G clipPath="url(#clip0_5325_47286)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.896899 2.69707C1.37511 2.21886 2.02371 1.9502 2.70001 1.9502H15.3C15.9763 1.9502 16.6249 2.21886 17.1031 2.69707C17.5813 3.17529 17.85 3.82389 17.85 4.5002V15.3002C17.85 15.9765 17.5813 16.6251 17.1031 17.1033C16.6249 17.5815 15.9763 17.8502 15.3 17.8502C14.8857 17.8502 14.55 17.5144 14.55 17.1002C14.55 16.686 14.8857 16.3502 15.3 16.3502C15.5784 16.3502 15.8455 16.2396 16.0424 16.0427C16.2393 15.8457 16.35 15.5787 16.35 15.3002V4.5002C16.35 4.22172 16.2393 3.95465 16.0424 3.75773C15.8455 3.56082 15.5784 3.4502 15.3 3.4502H2.70001C2.42154 3.4502 2.15447 3.56082 1.95755 3.75773C1.76064 3.95465 1.65002 4.22172 1.65002 4.5002V15.3002C1.65002 15.5787 1.76064 15.8457 1.95755 16.0427C2.15447 16.2396 2.42154 16.3502 2.70001 16.3502C3.11423 16.3502 3.45001 16.686 3.45001 17.1002C3.45001 17.5144 3.11423 17.8502 2.70001 17.8502C2.02371 17.8502 1.37511 17.5815 0.896899 17.1033C0.418683 16.6251 0.150024 15.9765 0.150024 15.3002V4.5002C0.150024 3.82389 0.418683 3.17529 0.896899 2.69707Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 0.150391C4.91421 0.150391 5.24999 0.486177 5.24999 0.900391V2.70039C5.24999 3.1146 4.91421 3.45039 4.5 3.45039C4.08578 3.45039 3.75 3.1146 3.75 2.70039V0.900391C3.75 0.486177 4.08578 0.150391 4.5 0.150391Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4999 0.150391C13.9141 0.150391 14.2499 0.486177 14.2499 0.900391V2.70039C14.2499 3.1146 13.9141 3.45039 13.4999 3.45039C13.0857 3.45039 12.7499 3.1146 12.7499 2.70039V0.900391C12.7499 0.486177 13.0857 0.150391 13.4999 0.150391Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.150024 7.2002C0.150024 6.78598 0.485809 6.4502 0.900021 6.4502H17.1C17.5142 6.4502 17.85 6.78598 17.85 7.2002C17.85 7.61441 17.5142 7.9502 17.1 7.9502H0.900021C0.485809 7.9502 0.150024 7.61441 0.150024 7.2002Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.09998 13.9502C5.09998 13.536 5.43576 13.2002 5.84997 13.2002H12.1499C12.5642 13.2002 12.8999 13.536 12.8999 13.9502C12.8999 14.3644 12.5642 14.7002 12.1499 14.7002H5.84997C5.43576 14.7002 5.09998 14.3644 5.09998 13.9502Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99994 10.0498C9.41415 10.0498 9.74993 10.3856 9.74993 10.7998V17.0998C9.74993 17.514 9.41415 17.8498 8.99994 17.8498C8.58572 17.8498 8.24994 17.514 8.24994 17.0998V10.7998C8.24994 10.3856 8.58572 10.0498 8.99994 10.0498Z"
        fill="currentColor"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_5325_47286">
        <Rect width="17.9999" height="18" fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)
