import * as serializers from './serializers'
import { SimulationResponseData, SimulationPayload, SimulationSerialized } from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'

export const getSimulationOptions = async () =>
  serializers.getSimulationOptions(
    await client.get<ResponseSuccess<SimulationSerialized>>(`v1/wallets/simulation`)
  )

export const sendSimulation = async (data: SimulationPayload) => {
  const response = await client.post<ResponseSuccess<SimulationResponseData>>(
    `v1/wallets/simulation`,
    data
  )

  return response
}
