import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Box, Button } from 'native-base'
import { Platform } from 'react-native'
import { LogEvent } from 'src/utils/logEvents'

export const ButtonRV: FunctionComponent = () => {
  const linkTo = useLinkTo()

  const isAndroid = Platform.OS === 'android'

  const handleGoToSimulatorPage = async () => {
    LogEvent('simulador_clicou_botao_rv', {
      item_id: ''
    })

    linkTo('/simulator')
  }

  return (
    <Box p={4} bg="white" position="absolute" w="full" bottom={isAndroid ? 17 : 9}>
      <Button
        colorScheme="gray"
        h="50px"
        _stack={{ flex: 1, justifyContent: 'center' }}
        onPress={handleGoToSimulatorPage}
        w="full"
        _text={{
          fontSize: '14px',
          fontWeight: 'bold',
          lineHeight: '20px'
        }}>
        Simular Ganho RV
      </Button>
    </Box>
  )
}
