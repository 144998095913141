import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'
import { useBreakpointValue } from 'native-base'
import { TItem } from 'src/screens/Wallet/Tab/TabScreen/TabScreen.types'

type TTabMoreFidelityProps = {
  rows: TItem[]
  pointsTarget: number
  pointsAchived: number
  fidelity_classification: string
  classification_color: string
}

const TabPanelsMobile = lazy(() => import('./TabMoreFidelity.mobile'))

const TabPanelsDesktop = lazy(() => import('./TabMoreFidelity.desktop'))

export const TabMoreFidelity: FunctionComponent<TTabMoreFidelityProps> = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const Component = isMobile ? TabPanelsMobile : TabPanelsDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Component {...props} />
    </Suspense>
  )
}
