import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'

import { BlocksArcProgressProps } from './BlocksArcProgress.types'
import { useBlocksArcProgress } from './useBlocksArcProgress'

const BlocksArcProgressDesktop = lazy(() => import('./BlocksArcProgress.desktop'))

const BlocksArcProgressMobile = lazy(() => import('./BlocksArcProgress.mobile'))

export const BlocksArcProgress: FunctionComponent<BlocksArcProgressProps> = (props) => {
  const blocksArcProgress = useBlocksArcProgress(props)

  const Component = blocksArcProgress.isMobile ? BlocksArcProgressMobile : BlocksArcProgressDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Component {...blocksArcProgress} />
    </Suspense>
  )
}
