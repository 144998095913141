import { FunctionComponent, PropsWithChildren } from 'react'

import { Modal } from 'native-base'
import { Dimensions } from 'react-native'

type TModalDashboardProps = {
  isOpen: boolean
  handleClose(): void
}

export const ModalDashboard: FunctionComponent<TModalDashboardProps & PropsWithChildren> = ({
  isOpen,
  handleClose,
  children
}) => {
  const { width: screenWidth, height: screenHeight } = Dimensions.get('window')

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Content
        minW={screenWidth}
        minH={screenHeight}
        borderWidth={0}
        bgColor="white"
        overflow="visible">
        <Modal.CloseButton bg="gray.30" borderRadius="full" borderColor="transparent" />
        <Modal.Body padding={0}>{children}</Modal.Body>
      </Modal.Content>
    </Modal>
  )
}
