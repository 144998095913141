import { memo } from 'react'

import Svg, { Path } from 'react-native-svg'

const ActivitiesEmptyIllustration = () => (
  <Svg width={251} height={251} viewBox="0 0 251 251" fill="none">
    <Path
      d="M90.065 218.518c22.981 0 41.61-18.63 41.61-41.61 0-22.981-18.629-41.61-41.61-41.61-22.98 0-41.61 18.629-41.61 41.61 0 22.98 18.63 41.61 41.61 41.61Z"
      fill="#FECFC4"
    />
    <Path
      d="m72.347 118.27-19.95 16.981L50 131.094l22.348-12.824ZM95.741 61.15a4.584 4.584 0 1 0 0-9.167 4.584 4.584 0 0 0 0 9.168ZM188.2 81.79a3.8 3.8 0 1 0 0-7.6 3.8 3.8 0 0 0 0 7.6Z"
      fill="#F3123C"
    />
    <Path
      d="M154.831 197.974a2.303 2.303 0 1 0 .002-4.606 2.303 2.303 0 0 0-.002 4.606ZM59.523 96.182l12.824 22.087L50 131.095"
      stroke="#595959"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="m72.347 118.269-19.95 16.982L50 131.094l22.348-12.825ZM92.036 87.418l3.301-26.267M95.741 61.15a4.584 4.584 0 1 0 0-9.167 4.584 4.584 0 0 0 0 9.168Z"
      stroke="#595959"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path d="m140.843 167.788 49.376 11.543 19.332-82.698-64.243-15.01" fill="#F5F5F5" />
    <Path
      d="m140.843 167.788 49.376 11.543 19.332-82.698-64.243-15.01M168.488 94.448l16.862-13.941M188.2 81.79a3.8 3.8 0 1 0 0-7.6 3.8 3.8 0 0 0 0 7.6ZM90.065 218.518c22.981 0 41.61-18.63 41.61-41.61 0-22.981-18.629-41.61-41.61-41.61-22.98 0-41.61 18.629-41.61 41.61 0 22.98 18.63 41.61 41.61 41.61ZM95.575 156.269l-11.02 41.278M110.704 182.418l-41.278-11.02"
      stroke="#595959"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.32 31.295a.468.468 0 0 1 .64.17l8.645 14.891a.469.469 0 0 1-.81.471l-8.645-14.891a.469.469 0 0 1 .17-.64Z"
      fill="#595959"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M153.716 34.576a.468.468 0 0 1-.169.641l-14.891 8.669a.469.469 0 0 1-.472-.81l14.891-8.67a.469.469 0 0 1 .641.17Z"
      fill="#595959"
    />
    <Path
      d="M26.106 130.477 6.75 126.059M32.875 152.731l-13.514 14.558M219.645 140.927l14.416 13.704M225.202 118.341l19.048-5.629"
      stroke="#595959"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="m131.509 173.179 50.065-29.046-51.538-88.849-70.513 40.898-9.524 34.912 9.904 17.124"
      fill="#F5F5F5"
    />
    <Path
      d="m131.509 173.179 50.065-29.046-51.538-88.849-70.513 40.898-9.524 34.912 9.904 17.124"
      stroke="#595959"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M89.057 220.15c24.11 0 43.656-19.545 43.656-43.656 0-24.11-19.546-43.656-43.656-43.656s-43.656 19.546-43.656 43.656c0 24.111 19.545 43.656 43.656 43.656Z"
      fill="#F5F5F5"
      stroke="#595959"
      strokeWidth={1.29}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="m111.834 157.513-24.22 32.875a3.797 3.797 0 0 1-5.428.721L66.28 178.392"
      stroke="#595959"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export const ActivitiesEmpty = memo(ActivitiesEmptyIllustration)
