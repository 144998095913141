import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { DeviceManagementScreenComponent } from './DeviceManagementScreen.types'
import { useDeviceManagementScreen } from './useDeviceManagementScreen'

const DeviceManagementScreenDesktop = lazy(() => import('./DeviceManagementScreen.desktop'))

const DeviceManagementScreenMobile = lazy(() => import('./DeviceManagementScreen.mobile'))

export const DeviceManagementScreen: DeviceManagementScreenComponent = () => {
  const deviceManagementScreen = useDeviceManagementScreen()

  const Screen = deviceManagementScreen.isMobile
    ? DeviceManagementScreenMobile
    : DeviceManagementScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...deviceManagementScreen} />
    </Suspense>
  )
}
