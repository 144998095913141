import { useCallback } from 'react'

import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import { useSetAuthAtom } from 'src/store/auth'
import { LogEventUserId } from 'src/utils/logEvents'

import { UseRefuseTermsScreen } from './RefuseTermsScreen.types'

export const useRefuseTermsScreen: UseRefuseTermsScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const setAuthAtom = useSetAuthAtom()

  const queryClient = useQueryClient()

  const onExit = useCallback(() => {
    LogEventUserId(null)

    Promise.all([setAuthAtom(null), queryClient.removeQueries()])
  }, [queryClient, setAuthAtom])

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  return {
    isMobile,
    onExit,
    handleGoBack
  }
}
