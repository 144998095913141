import { FunctionComponent, Suspense, lazy } from 'react'

import { Skeleton } from 'native-base'

import { RelatedTasksProps } from './RelatedTasks.types'
import { useRelatedTasks } from './useRelatedTasks'

const RelatedTasksDesktop = lazy(() => import('./RelatedTasks.desktop'))

const RelatedTasksMobile = lazy(() => import('./RelatedTasks.mobile'))

export const RelatedTasks: FunctionComponent<RelatedTasksProps> = (props) => {
  const relatedTasks = useRelatedTasks(props)

  const Component = relatedTasks.isMobile ? RelatedTasksMobile : RelatedTasksDesktop

  return (
    <Suspense fallback={<Skeleton minHeight="104px" mb={4} borderRadius="20px" />}>
      <Component {...relatedTasks} />
    </Suspense>
  )
}
