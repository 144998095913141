import * as serializers from './serializers'
import { GetListsParams, GetListSubsegments } from './types'
import client from '../../client'
import { ResponseSuccess } from '../../types'
import { ProductionStoresList } from '../production'

export const getStoreList = async (params: GetListsParams) => {
  const response = await client.get<ResponseSuccess<ProductionStoresList>>('v1/stores', {
    params: {
      ...params,
      per_page: params?.per_page ?? 10,
      page: params?.page ?? 1
    }
  })

  return serializers.getStoreList(response)
}

export const getListSubsegment = async () =>
  await client.get<ResponseSuccess<GetListSubsegments[]>>('v1/filters/store-subsegments')

export const getListStatusTab = async () =>
  await client.get<ResponseSuccess<string[]>>('v1/filters/store-status')
