import { FunctionComponent, Suspense, lazy } from 'react'

import { LoadingTurbo } from 'atoms'
import { useBreakpointValue } from 'native-base'

import { TabCommonProps } from './types'

const InformationsMobile = lazy(() => import('./mobile'))

const InformationsDesktop = lazy(() => import('./desktop'))

export const Informations: FunctionComponent<TabCommonProps> = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const Component = isMobile ? InformationsMobile : InformationsDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Component {...props} />
    </Suspense>
  )
}
