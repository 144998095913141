import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { RecoveryPasswordCreatePasswordScreenComponent } from './RecoveryPasswordCreatePasswordScreen.types'
import { useResetPasswordCreatePasswordScreen } from './useResetPasswordCreatePasswordScreen'

const ScreenDesktop = lazy(() => import('./RecoveryPasswordCreatePasswordScreen.desktop'))

const ScreenMobile = lazy(() => import('./RecoveryPasswordCreatePasswordScreen.mobile'))

export const ResetPasswordCreatePasswordScreen: RecoveryPasswordCreatePasswordScreenComponent = ({
  navigation,
  route
}) => {
  const props = useResetPasswordCreatePasswordScreen({
    navigation,
    route
  })

  const Screen = props.isMobile ? ScreenMobile : ScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...props} />
    </Suspense>
  )
}
