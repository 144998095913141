import { createIcon } from 'native-base'
import { Path } from 'react-native-svg'

export const ClipIcon = createIcon({
  viewBox: '0 0 24 24',
  path: [
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.903 0.292987C14.2935 0.683562 14.2934 1.31673 13.9029 1.7072L4.81646 10.7912C4.22075 11.3868 3.75265 12.0975 3.44073 12.88C3.12881 13.6624 2.97961 14.5003 3.00224 15.3423C3.02487 16.1844 3.21885 17.013 3.57234 17.7776C3.92339 18.5369 4.42445 19.2173 5.04529 19.7778C6.30199 20.8232 7.90767 21.3551 9.54038 21.2663C11.1789 21.1771 12.7226 20.4695 13.8596 19.2863L13.8735 19.2721L20.7974 12.3482C20.7975 12.3482 20.7974 12.3482 20.7974 12.3482C21.1268 12.0187 21.3804 11.6213 21.5404 11.1837C21.7004 10.7462 21.763 10.279 21.7238 9.81472C21.6846 9.35047 21.5446 8.90036 21.3135 8.4958C21.085 8.09559 20.7727 7.74948 20.3982 7.48107C19.7449 7.05074 18.9615 6.86227 18.1836 6.94858C17.4007 7.03546 16.6734 7.39513 16.1291 7.96455L16.1133 7.98071L12.4535 11.6406C12.4534 11.6406 12.4535 11.6406 12.4535 11.6406C11.9661 12.1282 11.6922 12.7894 11.6922 13.4788C11.6922 14.1682 11.966 14.8294 12.4535 15.317C12.8439 15.7076 12.8438 16.3407 12.4532 16.7312C12.0627 17.1217 11.4295 17.1216 11.039 16.731C10.1767 15.8684 9.69221 14.6986 9.69221 13.4788C9.69221 12.259 10.1767 11.0892 11.039 10.2266L14.6916 6.57406C15.5595 5.67002 16.7172 5.09903 17.963 4.96078C19.2128 4.8221 20.4715 5.12776 21.5185 5.82417C21.5269 5.82974 21.5351 5.83544 21.5433 5.84126C22.1606 6.27926 22.6749 6.84666 23.0502 7.50389C23.4256 8.16112 23.653 8.89234 23.7167 9.64653C23.7803 10.4007 23.6787 11.1597 23.4188 11.8705C23.1588 12.5814 22.747 13.227 22.2118 13.7622L15.2945 20.6795C13.8072 22.2235 11.79 23.1468 9.64903 23.2633C7.50464 23.38 5.39588 22.6785 3.74863 21.3006C3.7397 21.2932 3.73089 21.2855 3.72222 21.2777C2.89318 20.5335 2.22449 19.6281 1.75697 18.6169C1.28944 17.6056 1.03289 16.5097 1.00296 15.3961C0.973032 14.2824 1.17036 13.1743 1.5829 12.1394C1.99544 11.1045 2.61455 10.1645 3.40242 9.37681L12.4888 0.2928C12.8794 -0.0976728 13.5126 -0.0975893 13.903 0.292987Z"
      fill="currentColor"
    />
  ]
})
