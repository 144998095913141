import { useCallback, useState } from 'react'

import { useBreakpointValue } from 'native-base'
import { useBottomSheetContext } from 'src/contexts/BottomSheetContext'
import {
  usePreResetPasswordMutation,
  useResetPasswordMutation
} from 'src/integration/resources/resetPassword'
import { RootStackScreenComponentProps } from 'src/navigation'

export const useResetPasswordAuthCodeScreen = ({
  navigation,
  route
}: RootStackScreenComponentProps<'ResetPasswordAuthCode'>) => {
  const preResetPasswordMutation = usePreResetPasswordMutation()

  const resetPasswordMutation = useResetPasswordMutation()

  const bottomSheetContext = useBottomSheetContext()

  const [errorCode, setErrorCode] = useState<string | undefined>()

  const submit = useCallback(
    ({ code }: { code: string }) => {
      const { confirm_token, ...payload } = { ...route.params, code }

      setErrorCode(undefined)

      resetPasswordMutation.mutate(payload, {
        onError: () => setErrorCode('Código de autenticação inválido.'),
        onSuccess: () =>
          navigation.reset({
            index: 0,
            routes: [{ name: 'ResetPasswordSuccessfully' }]
          })
      })
    },
    [navigation, resetPasswordMutation, route.params]
  )

  const resendCode = useCallback(() => {
    const { slt, ...payload } = route.params

    preResetPasswordMutation.mutate(payload, {
      onError: () => {
        bottomSheetContext.open({
          description: `Erro ao reenviar SMS`,
          title: 'Não foi possível reenviar o SMS, tente novamente mais tarde'
        })
      },
      onSuccess: (responseData) => {
        navigation.setParams({
          ...route.params,
          slt: responseData.headers['x-slt']
        })

        bottomSheetContext.open({
          description: `Reenviamos o código de confirmação`,
          title: 'SMS enviado'
        })
      }
    })
  }, [bottomSheetContext, navigation, preResetPasswordMutation, route.params])

  const isMobile = useBreakpointValue({ base: true, lg: false })

  return {
    preResetPasswordMutation,
    resendCode,
    resetPasswordMutation,
    submit,
    errorCode,
    isMobile,
    navigation,
    route
  }
}
