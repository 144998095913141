import { useCallback, useState } from 'react'

import { useBottomSheetContext } from 'src/contexts/BottomSheetContext'
import { useSendAuthCode } from 'src/hooks/useSendAuthCode'
import {
  ConfirmAccountPhoneNumberPayload,
  useConfirmAccountPhoneNumberMutation,
  useSendConfirmAccountEmailMutation
} from 'src/integration/resources/createAccount'
import { RootStackScreenComponent } from 'src/navigation'
import { AuthCodeScreen } from 'templates'

export const CreateAccountAuthCodeScreen: RootStackScreenComponent<'CreateAccountAuthCode'> = ({
  navigation,
  route
}) => {
  const confirmAccountPhoneNumberMutation = useConfirmAccountPhoneNumberMutation()

  const sendConfirmEmailMutation = useSendConfirmAccountEmailMutation()

  const bottomSheetContext = useBottomSheetContext()

  const { handleSendAuthCodeSms, isLoadingAuthSendCodeSms } = useSendAuthCode()

  const [errorCode, setErrorCode] = useState<string | undefined>()

  const submit = useCallback(
    (formData: Pick<ConfirmAccountPhoneNumberPayload, 'code'>) => {
      setErrorCode(undefined)

      confirmAccountPhoneNumberMutation.mutate(
        {
          ...formData,
          ...route.params
        },
        {
          onError: () => setErrorCode('Código de autenticação inválido.'),
          onSuccess: () => {
            sendConfirmEmailMutation.mutate(
              {
                id: route.params.id
              },
              {
                onError: () => {
                  bottomSheetContext.open({
                    description: `Erro ao enviar e-mail de confirmação`,
                    title:
                      'Não foi possível enviar o e-mail de confirmação, tente novamente mais tarde'
                  })
                },
                onSuccess: () =>
                  navigation.reset({
                    index: 0,
                    routes: [
                      {
                        name: 'CreateAccountSendEmailConfirmation',
                        params: {
                          id: route.params.id,
                          email: route.params.email
                        }
                      }
                    ]
                  })
              }
            )
          }
        }
      )
    },
    [
      confirmAccountPhoneNumberMutation,
      sendConfirmEmailMutation,
      bottomSheetContext,
      navigation,
      route.params
    ]
  )

  return (
    <AuthCodeScreen
      isSubmitting={confirmAccountPhoneNumberMutation.isLoading}
      handleSendAuthCodeSms={handleSendAuthCodeSms}
      isLoadingAuthSendCodeSms={isLoadingAuthSendCodeSms}
      errorCode={errorCode}
      submit={submit}
    />
  )
}
