import { AxiosResponse } from 'axios'
import { ResponseSuccess } from 'src/integration/types'

import { ActivitiesType, ActivitiesResponse, ActivitiesByWeekResponse, Activity } from './types'

export const getActivityList: (
  response: AxiosResponse<ResponseSuccess<ActivitiesResponse>>
) => AxiosResponse<ResponseSuccess<ActivitiesResponse>> = (response) => response

export const getActivityListByWeek: (
  response: AxiosResponse<ResponseSuccess<ActivitiesByWeekResponse>>
) => AxiosResponse<ResponseSuccess<ActivitiesByWeekResponse>> = (response) => response

export const getActivityTypes: (
  response: AxiosResponse<ResponseSuccess<ActivitiesType[]>>
) => AxiosResponse<ResponseSuccess<ActivitiesType[]>> = (response) => response

export const getActivityItem: (
  response: AxiosResponse<ResponseSuccess<Activity>>
) => AxiosResponse<ResponseSuccess<Activity>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: {
      ...response.data.data,
      activity_plan_action_items: response.data.data.activity_plan_action_items.map((item) => ({
        ...item,
        id: item.name,
        value: item.name,
        checked: item.is_finished
      }))
    }
  }
})
