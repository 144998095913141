import { createIcon } from 'native-base'
import { Path } from 'react-native-svg'

export const ArrowDropDownIcon = createIcon({
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M6.45418 10.6317L10.4219 14.5584C11.0193 15.1497 11.9844 15.1497 12.5819 14.5584L16.5496 10.6317C17.5147 9.67649 16.8253 8.03906 15.4619 8.03906H7.52653C6.16312 8.03906 5.48907 9.67649 6.45418 10.6317Z"
      fill="currentColor"
    />
  ]
})
