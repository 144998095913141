import { AxiosResponse } from 'axios'
import { formatRelative, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { ResponseSuccess } from 'src/integration/types'

import { Device, DeviceSerialized } from './types'

export const getDevices: (
  response: AxiosResponse<ResponseSuccess<Device[]>>
) => AxiosResponse<ResponseSuccess<DeviceSerialized[]>> = (response) => ({
  ...response,
  data: {
    ...response.data,
    data: response.data.data.map((item) => ({
      ...item,
      lastAccessAtFormatted: formatRelative(parseISO(item.lastAccessAt), new Date(), {
        locale: ptBR
      })
    }))
  }
})
