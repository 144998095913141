import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { TabContactScreenComponent } from './TabContactScreen.types'
import { useTabContactScreen } from './useTabContactScreen'

const TabContactScreenDesktop = lazy(() => import('./TabContactScreen.desktop'))

const TabContactScreenMobile = lazy(() => import('./TabContactScreen.mobile'))

export const TabContactScreen: TabContactScreenComponent = ({ navigation, route }) => {
  const tabContactScreen = useTabContactScreen({ navigation, route })

  const Screen = tabContactScreen.isMobile ? TabContactScreenMobile : TabContactScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...tabContactScreen} />
    </Suspense>
  )
}
