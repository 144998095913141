import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ReadIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      d="M11.0657 1.24389C11.3385 0.932161 11.3069 0.458339 10.9952 0.185578C10.6835 -0.0871838 10.2096 -0.0555957 9.93688 0.256132L7.60355 2.9228C7.33078 3.23453 7.36237 3.70835 7.6741 3.98111C7.98583 4.25387 8.45965 4.22228 8.73241 3.91056L11.0657 1.24389Z"
      fill="currentColor"
    />
    <Path
      d="M14.9952 0.185578C15.3069 0.458339 15.3385 0.932161 15.0657 1.24389L9.2324 7.91056C9.0978 8.06438 8.90625 8.15661 8.70207 8.1659C8.49788 8.17519 8.29874 8.10075 8.15073 7.95978L6.53542 6.42139L5.2324 7.91055C5.0978 8.06437 4.90625 8.1566 4.70207 8.16589C4.49788 8.17519 4.29874 8.10074 4.15073 7.95977L0.650729 4.62644C0.350781 4.34077 0.339202 3.86604 0.624867 3.56609C0.910531 3.26615 1.38526 3.25457 1.68521 3.54023L4.6187 6.33403L5.44824 5.38598L4.65073 4.62645C4.35078 4.34078 4.3392 3.86605 4.62487 3.5661C4.91053 3.26615 5.38526 3.25458 5.68521 3.54024L8.6187 6.33404L13.9369 0.256132C14.2096 -0.0555957 14.6835 -0.0871838 14.9952 0.185578Z"
      fill="currentColor"
    />
  </Icon>
)
