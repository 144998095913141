import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { EntryScreenComponent } from './EntryScreen.types'
import { useEntryScreen } from './useEntryScreen'

const EntryScreenDesktop = lazy(() => import('./EntryScreen.desktop'))

const EntryScreenMobile = lazy(() => import('./EntryScreen.mobile'))

export const EntryScreen: EntryScreenComponent = ({ navigation }) => {
  const entryScreen = useEntryScreen({ navigation })

  const Screen = entryScreen.isMobile ? EntryScreenMobile : EntryScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...entryScreen} />
    </Suspense>
  )
}
