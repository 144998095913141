import { useState } from 'react'

import { useBreakpointValue } from 'native-base'

import { UseContactSearchScreen } from './ContactSearchScreen.types'

export const useContactSearchScreen: UseContactSearchScreen = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [title, setTitle] = useState('')

  return {
    isMobile,
    title,
    setTitle
  }
}
