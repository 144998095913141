import { memo } from 'react'

import Svg, { Path } from 'react-native-svg'

const WorldPlanetIcon = () => (
  <Svg width={14} height={14} fill="none" viewBox="0 0 14 14">
    <Path
      d="M7 1.167A5.84 5.84 0 0 0 1.167 7 5.84 5.84 0 0 0 7 12.833 5.84 5.84 0 0 0 12.833 7 5.84 5.84 0 0 0 7 1.167ZM2.333 7c0-.524.091-1.028.252-1.498l.915.915 1.167 1.166V8.75l1.166 1.167.584.583v1.127A4.672 4.672 0 0 1 2.333 7Zm8.36 2.843c-.381-.307-.959-.51-1.36-.51V8.75a1.167 1.167 0 0 0-1.166-1.167H5.833v-1.75A1.167 1.167 0 0 0 7 4.667v-.584h.583A1.167 1.167 0 0 0 8.75 2.917v-.24A4.671 4.671 0 0 1 11.667 7c0 1.03-.343 2.03-.974 2.843Z"
      fill="#fff"
    />
  </Svg>
)

export const WorldPlanet = memo(WorldPlanetIcon)
