import { memo } from 'react'

import { Cards } from 'atoms'
import { Box, Center, Heading, Skeleton, VStack, View } from 'native-base'
import { screenHookReturns } from 'src/screens/OpportunityDetailsScreen/OpportunityDetailsScreen.types'

export const RelatedContents = memo(
  ({ opportunityDetails, handleGoToContentScreen, isMobile, isLoading }: screenHookReturns) => (
    <VStack space={8}>
      {isLoading ? (
        <Skeleton h="20px" borderRadius="20px" w="1/3" />
      ) : (
        <Heading fontSize="18px" fontWeight={400} lineHeight="24px" color="gray.700">
          Conteúdo relacionado
        </Heading>
      )}

      {isLoading ? (
        <VStack space={4}>
          <Skeleton borderRadius="20px" h="100px" />
          <Skeleton borderRadius="20px" h="100px" />
          <Skeleton borderRadius="20px" h="100px" />
        </VStack>
      ) : opportunityDetails?.knowledges.length ? (
        // @ts-ignore
        <View style={{ gap: isMobile ? '8px' : '30px', flexWrap: 'wrap', flexDirection: 'row' }}>
          {opportunityDetails?.knowledges.map((item) => (
            <Box key={item.id} minW="354px" width={isMobile ? '100%' : undefined}>
              <Box w="full">
                {/* @ts-ignore */}
                <Cards.Content
                  {...item}
                  onPress={() => {
                    handleGoToContentScreen(item.id)
                  }}
                />
              </Box>
            </Box>
          ))}
        </View>
      ) : (
        <Center>
          <Heading
            fontSize={{ base: '16px', lg: '18px' }}
            fontWeight={400}
            lineHeight="24px"
            color="gray.700">
            Não existem conteúdos para esta oportunidade.
          </Heading>
        </Center>
      )}
    </VStack>
  )
)
