import { useCallback, useMemo } from 'react'

import { Store, useGetStoreListInfiniteQuery } from 'integration/resources/store'

import { UseBottomSheetStores } from './types'

const mergeDataInfiniteQuery = (data: ReturnType<typeof useGetStoreListInfiniteQuery>['data']) =>
  data?.pages.reduce<Store[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const useBottomSheetStores: UseBottomSheetStores = (per_page = 10, search) => {
  const {
    data: storesData,
    isLoading: storesIsLoading,
    isFetchingNextPage: storesIsFetchingNextPage,
    isFetching: storesFetching,
    hasNextPage: storesHasNextPage,
    fetchNextPage: storesFetchNextPage
  } = useGetStoreListInfiniteQuery({ per_page, search })

  const totalItens = storesData?.pages[0].data.metadata.pagination.total ?? 0

  const storeList = useMemo(() => mergeDataInfiniteQuery(storesData), [storesData])

  const onScrollEndDragStoreList = useCallback(() => {
    if (!storesIsFetchingNextPage && storesHasNextPage) {
      storesFetchNextPage()
    }
  }, [storesFetchNextPage, storesIsFetchingNextPage, storesHasNextPage])

  return {
    stores: storeList,
    isLoadingStores: storesIsLoading,
    storesFetching,
    onScrollEndDragStoreList,
    totalItens
  }
}
