import { lazy, Suspense } from 'react'

import { LoadingTurbo } from 'atoms'

import { LoginScreenComponent } from './LoginScreen.types'
import { useLoginScreen } from './useLoginScreen'

const LoginScreenDesktop = lazy(() => import('./LoginScreen.desktop'))

const LoginScreenMobile = lazy(() => import('./LoginScreen.mobile'))

export const LoginScreen: LoginScreenComponent = ({ navigation }) => {
  const loginScreen = useLoginScreen({ navigation })

  const Screen = loginScreen.isMobile ? LoginScreenMobile : LoginScreenDesktop

  return (
    <Suspense fallback={<LoadingTurbo />}>
      <Screen {...loginScreen} />
    </Suspense>
  )
}
